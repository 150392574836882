import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SuccessStep } from '@mopla/constants';
import { ArrowRightIcon } from '@mopla/ui';

import {
	Root,
	TextWithLinkWrapper,
	Title,
} from './VerifyReminderScreen.styles';

interface IVerifyScreenProps {
	setSuccessType: Dispatch<SetStateAction<string>>;
}

export const VerifyReminderScreen: React.FC<IVerifyScreenProps> = ({
	setSuccessType,
}) => {
	const { t } = useTranslation('verifyReminder');
	const navigate = useNavigate();

	const resendEmail = () => {
		if (
			new Date().valueOf() - Number(localStorage.getItem('emailSendTime')) <=
			60000
		) {
			setSuccessType(SuccessStep.registrationComplete);
		} else {
			setSuccessType(SuccessStep.verifyEmailResend);
		}
		return navigate(
			{ pathname: '/welcome', search: 'registration' },
			{ state: { prevPath: 'verifyBeforeStart' } }
		);
	};

	return (
		<Root>
			<Title marginBottom="24px" data-testid="registration-pleaseVerify-text">
				{t('text.title1')}
			</Title>
			<Title marginBottom="64px" bold>
				{t('text.title2')}
			</Title>
			<Title marginBottom="16px">{t('text.title3')}</Title>
			<TextWithLinkWrapper onClick={resendEmail}>
				<span>{t('text.resend_button')}</span>
				<ArrowRightIcon />
			</TextWithLinkWrapper>
		</Root>
	);
};
