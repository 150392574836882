import React from 'react';

export const ChevronRightIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.9496 12.3636L7.9999 17.3133L9.41411 18.7275L14.3639 13.7778L15.071 13.0707C15.4615 12.6802 15.4615 12.047 15.071 11.6565L14.3639 10.9494L9.41411 5.99963L7.99989 7.41384L12.9496 12.3636Z"
				fill="currentColor"
			/>
		</svg>
	);
};
