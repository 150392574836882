import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

import { PassengerBooking } from '@mopla/business-logic';
import {
	BookingStatus,
	ILeg,
	Itinerary,
	PaymentState,
	TransportType,
} from '@mopla/data-models';
import { colors } from '@mopla/mui';
import {
	checkTimeMightChange,
	checkTimeOverwritten,
	formatTime,
	getActualTime,
	getItineraryTransportTypes,
	isLBTItinerary,
	isMixedItinerary,
	useBreakpoints,
} from '@mopla/utils';

import {
	AttentionIcon,
	CheckmarkCircleIcon,
	FlexContainer,
	InfoBaseIcon,
	LegDetails,
	Text,
	TravelTypeSymbols,
} from '../../../';

import { CancelButton } from './CancelButton';
import { FailedStatusBlock } from './FailedStatusBlock';
import { LegMap } from './LegMap';
import { PassengerInfoBlock } from './PassengerInfoBlock';
import {
	AttentionWrapper,
	CheaperRidesWrapper,
	CheapestRideIcon,
	Content,
	LegsWrapper,
	Root,
	TimeWrapper,
	TitleWrapper,
	WarningBottom,
} from './RouteDetails.styles';

export interface IRouteDetailsProps {
	route: Itinerary;
	isTicket?: boolean;
	cancelBooking?: () => void;
	passengerInfo?: PassengerBooking;
	cheaperRidesBlock?: ReactNode;
	isCheapestRide?: boolean;
}

export const RouteDetails: FC<IRouteDetailsProps> = ({
	route,
	isTicket,
	cancelBooking,
	passengerInfo,
	cheaperRidesBlock,
	isCheapestRide,
}) => {
	const { t } = useTranslation([
		'searchResults',
		'routeDetails',
		'tickets',
		'booking',
	]);
	const { isMobile } = useBreakpoints();
	const timeMightChange = checkTimeMightChange(route);
	const isTimeOverwritten = checkTimeOverwritten(route);
	const isMixedRoute = isMixedItinerary(route);

	const walkBefore =
		route?.legs?.length && route.legs[0].mode === TransportType.WALK;
	const walkAfter =
		route?.legs?.[route?.legs?.length - 1].mode === TransportType.WALK;

	const legForStartTime = walkBefore ? route?.legs?.[1] : route?.legs?.[0];
	const startTime = formatTime(new Date(legForStartTime?.startDateTime || ''));
	const curentStartTime = getActualTime(legForStartTime, 'start');

	const legForEndTime = walkAfter
		? route?.legs?.[route?.legs?.length - 2]
		: route?.legs?.[route?.legs?.length - 1];
	const endTime = formatTime(new Date(legForEndTime?.endDateTime || ''));
	const curentEndTime = getActualTime(legForEndTime, 'end');
	const transportTypes = getItineraryTransportTypes(route);

	const isPaymentFailed =
		passengerInfo?.payment?.paymentState === PaymentState.FAILED;
	const isCancelledByDistributor =
		passengerInfo?.bookingStatus === BookingStatus.CANCELLED_BY_DISTRIBUTOR;
	const isCancelledByDriver =
		passengerInfo?.bookingStatus === BookingStatus.CANCELLED_NO_SHOW;
	const isCancelledByPassenger =
		passengerInfo?.bookingStatus === BookingStatus.CANCELLED_BY_PASSENGER;
	const isExecuting = passengerInfo?.bookingStatus === BookingStatus.EXECUTING;

	const isLBT = isLBTItinerary(route);

	const newPrice = passengerInfo?.payment.overwrittenPaymentAmount;
	const initialPrice = passengerInfo?.payment.paymentAmount;
	const isPriceReduction =
		!!newPrice && !!initialPrice && newPrice < initialPrice;

	return (
		<Root data-testid="savedTicket-routeDetails-block">
			{isCheapestRide && (
				<Content>
					<FlexContainer gap={8} style={{ flexWrap: 'nowrap' }}>
						<CheapestRideIcon />
						<Text variant="body1_semibold" color="mopla_secondary">
							{t('booking:text.isCheaperRide')}
						</Text>
					</FlexContainer>
					<Divider sx={{ my: 2 }} />
				</Content>
			)}
			<TitleWrapper>
				<Text variant="body2_semibold">
					{timeMightChange
						? t('common:text.estimated_timewindow')
						: t('common:text.travel_time')}
				</Text>
				<p></p>
				<TimeWrapper>
					<Text
						data-testid="route-duration-timeInterval-block"
						variant="subtitle1"
						color={timeMightChange ? 'mopla_black' : 'mopla_primary_dark'}
					>{`${curentStartTime} - ${curentEndTime}`}</Text>
					{timeMightChange && isTimeOverwritten && (
						<Text
							data-testid="route-duration-initialTimeInterval-block"
							variant="body3"
							color="mopla_dark_grey"
							sx={{ textDecoration: 'line-through' }}
						>{`${startTime} - ${endTime}`}</Text>
					)}
				</TimeWrapper>
				<TravelTypeSymbols transportTypes={transportTypes} />
			</TitleWrapper>
			<AttentionWrapper>
				{isMixedRoute && (
					<>
						<InfoBaseIcon />
						<Text
							variant="body2"
							color="mopla_dark_grey"
							data-testid="routeDetails-timesAreSubjectToChange-notice"
						>
							{t('common:text.attentionMixedRoutes')}
						</Text>
					</>
				)}
				{timeMightChange && (
					<>
						<InfoBaseIcon />
						<Text variant="body2" color="mopla_dark_grey">
							{t('common:text.attentionMessage')}
						</Text>
					</>
				)}
				{!timeMightChange && !isExecuting && (
					<>
						<CheckmarkCircleIcon color={colors.mopla_primary_dark} />
						<Text variant="body2" color="mopla_primary_dark">
							{t('common:text.time_fixed')}
						</Text>
					</>
				)}
				{isExecuting && (
					<>
						<CheckmarkCircleIcon color={colors.mopla_primary_dark} />
						<Text variant="body2" color="mopla_primary_dark">
							{t('common:text.checked_in_done')}
						</Text>
					</>
				)}
				{isPriceReduction && !isExecuting && (
					<>
						<InfoBaseIcon />
						<Text variant="body2" color="mopla_dark_grey">
							{t('common:text.ticket_cheaper')}
						</Text>
					</>
				)}
			</AttentionWrapper>
			<FailedStatusBlock
				isPaymentFailed={isPaymentFailed}
				isCancelledByDistributor={isCancelledByDistributor}
				isCancelledByDriver={isCancelledByDriver}
				isCancelledByPassenger={isCancelledByPassenger}
				cancellationReason={passengerInfo?.cancellationReason}
			/>
			{cheaperRidesBlock && (
				<CheaperRidesWrapper>{cheaperRidesBlock}</CheaperRidesWrapper>
			)}
			<LegsWrapper>
				{timeMightChange && (
					<AttentionWrapper>
						<InfoBaseIcon />
						<Text variant="body2" color="mopla_dark_grey">
							{t('common:text.exact_travel_time')}
						</Text>
					</AttentionWrapper>
				)}
				{route?.legs &&
					route.legs.map((leg: ILeg) => (
						<LegDetails
							key={leg.startDateTime}
							legInfo={leg}
							isBookedItinerary={isTicket}
							timeMightChange={timeMightChange}
							renderLegMap={(render) => (
								<LegMap leg={leg} isTicket={isTicket} render={render} />
							)}
						/>
					))}
			</LegsWrapper>
			{timeMightChange && (
				<WarningBottom>
					<AttentionIcon />
					<span data-testid="routeDetails-departureTimeCanBeChanged-notice">
						{t('routeDetails:text.time_can_change')}
					</span>
				</WarningBottom>
			)}
			{isTicket && isMobile && (
				<>
					{!isLBT && (
						<PassengerInfoBlock
							passengerInfo={passengerInfo}
							isLBTRide={false}
						/>
					)}
					<CancelButton
						transportTypes={transportTypes}
						passengerInfo={passengerInfo}
						onClick={() => cancelBooking && cancelBooking()}
					/>
				</>
			)}
		</Root>
	);
};
