import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexColumn, Text } from '@mopla/ui';
import { Checkbox } from '@mopla/ui/atoms/Checkbox/Checkbox';
import { FormFields } from '@mopla/ui/organisms/PersonalDetailsForm/FormFields';

import { ContentWrapper } from '../DTicketSubscription.styles';
import { IAnotherRecipientBlockProps } from '../types';

import { ContentBlock } from './ContentBlock';

export const SecondStep: React.FC = () => {
	const { t } = useTranslation(['dticket', 'validation']);

	return (
		<ContentBlock headerText={t('header.subscription_user')}>
			<FlexColumn className="space-between" style={{ gap: 8 }}>
				<Text variant="body3" color="mopla_dark_grey">
					{t('label.mandatory_fields')}
				</Text>
				<FormFields
					showAddressToggle={false}
					dateOfBirthRequired
					withPaddings={false}
				/>
			</FlexColumn>
		</ContentBlock>
	);
};

export const AnotherRecipientBlock: React.FC<IAnotherRecipientBlockProps> = (
	props
) => {
	const { showInvoiceRecipientForm, toggleInvoiceForm } = props;
	const { t } = useTranslation(['dticket', 'validation']);

	return (
		<ContentWrapper>
			<Checkbox
				name="another-invoice-recipient"
				checked={showInvoiceRecipientForm}
				onChange={toggleInvoiceForm}
				label={t('label.another_invoice_recipient')}
			/>
			{showInvoiceRecipientForm && (
				<FlexColumn className="space-between" style={{ gap: 8 }}>
					<Text variant="body3" color="mopla_dark_grey">
						{t('label.mandatory_fields')}
					</Text>
					<FormFields
						showCompanyField
						showDates={false}
						showAddressToggle={false}
						dateOfBirthRequired
						withPaddings={false}
					/>
				</FlexColumn>
			)}
		</ContentWrapper>
	);
};
