import { styled } from '@mui/material/styles';

export const ModalText = styled('span')<{ isWarn: boolean }>(
	({ theme, isWarn }) => ({
		...theme.fonts.body1_semibold,
		marginBottom: 24,
		color: isWarn ? theme.colors.mopla_red : theme.colors.mopla_black,
	})
);

export const Note = styled('div')(({ theme }) => ({
	...theme.fonts.body1,
	display: 'flex',
	flexDirection: 'column',
}));

export const NoteTitle = styled('span')(({ theme }) => ({
	...theme.fonts.body1_semibold,
}));

export const NoteText = styled('span')(({ theme }) => ({
	...theme.fonts.body1,
	marginBottom: 24,
}));

export const InfoBlock = styled('div')(({ theme }) => ({
	...theme.fonts.caption,
	display: 'flex',
	marginBottom: 24,
	color: theme.colors.mopla_dark_grey,
	'& svg': {
		marginRight: 8,
		'& > path': {
			fill: theme.colors.mopla_dark_grey,
		},
	},
}));
