import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'open' && prop !== 'variant' && prop !== 'rounded',
})<{ open: boolean; variant: string; rounded: boolean }>(
	({ theme, open, variant, rounded }) => ({
		background:
			(variant === 'secondary' && theme.colors.mopla_secondary) ||
			(variant === 'yellow' && theme.colors.driver_bright_yellow) ||
			(variant === 'light' && theme.colors.mopla_super_light_grey) ||
			(variant === 'white' && theme.colors.mopla_white) ||
			(variant === 'secondary_light' && theme.colors.mopla_secondary_light) ||
			(variant === 'light_blue' && theme.colors.mopla_super_light_blue) ||
			(variant === 'secondary_dark' && theme.colors.mopla_secondary_dark) ||
			theme.colors.mopla_primary,
		color:
			(variant === 'secondary' && theme.colors.mopla_white) ||
			(variant === 'yellow' && theme.colors.mopla_secondary) ||
			(variant === 'light' && theme.colors.mopla_secondary) ||
			(variant === 'white' && theme.colors.mopla_secondary) ||
			(variant === 'secondary_light' && theme.colors.mopla_secondary_dark) ||
			(variant === 'light_blue' && theme.colors.mopla_secondary_dark) ||
			(variant === 'secondary_dark' && theme.colors.mopla_white) ||
			theme.colors.mopla_secondary,
		borderRadius: rounded ? 8 : 0,
		display: 'grid',
		gridTemplateAreas: '"l r" "t t" "c c"',
		gridTemplateColumns: 'min-content min-content',
		justifyContent: 'space-between',
		width: '100%',
		height: open ? 'max-content' : '0px',
		overflow: 'hidden',
		'@media(max-width: 900px)': {
			transition: 'height .2s',
		},
		visibility: 'visible',
	})
);

export const TopButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'left',
})<{ left?: boolean }>(({ left }) => ({
	gridArea: left ? 'l' : 'r',
	gridColumnStart: left ? 1 : 2,
	color: 'inherit',
	height: 56,
}));

export const Title = styled('div')(({ theme }) => ({
	...theme.fonts.H6,
	wordBreak: 'break-word',
	gridArea: 't',
	padding: '8px 24px 24px 24px',
}));

export const ChildrenWrapper = styled('div')(() => ({
	gridArea: 'c',
	padding: '0 24px 27px 24px',
}));
