import { FC, ReactNode } from 'react';

import { Checkbox } from '../../atoms/Checkbox';

import { ContentWrapper } from './styled';

export interface ICheckboxDropdownProps {
	label: string;
	open: boolean;
	children: ReactNode;
	checkboxTestId?: string;
	onChange(): void;
}

export const CheckboxDropdown: FC<ICheckboxDropdownProps> = ({
	label,
	children,
	open,
	checkboxTestId,
	onChange,
}) => {
	return (
		<div>
			<Checkbox
				checkboxTestId={checkboxTestId}
				label={label}
				onChange={onChange}
				checked={open}
			/>
			{open && <ContentWrapper>{children}</ContentWrapper>}
		</div>
	);
};
