import React from 'react';

export const CheckboxNegative = () => {
	return (
		<svg
			width="45"
			height="46"
			viewBox="0 0 45 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.0255 41.1836C11.7374 41.1836 3.36736 32.8136 3.36736 22.5255C3.36736 12.2375 11.7374 3.86743 22.0255 3.86743C32.3136 3.86743 40.6836 12.2375 40.6836 22.5255C40.6836 32.8136 32.3136 41.1836 22.0255 41.1836Z"
				fill="#DD6060"
			/>
			<path
				d="M13.4122 32.0968C13.1135 32.0968 12.8148 31.9829 12.5868 31.755C12.1309 31.2991 12.1309 30.56 12.5868 30.1042L29.6044 13.0867C30.0603 12.6309 30.7994 12.6309 31.2552 13.0867C31.711 13.5426 31.711 14.2816 31.2552 14.7375L14.2376 31.755C14.0097 31.9828 13.7109 32.0968 13.4122 32.0968Z"
				fill="white"
			/>
			<path
				d="M30.4298 32.0968C30.131 32.0968 29.8323 31.9828 29.6044 31.755L12.5868 14.7375C12.1309 14.2816 12.1309 13.5426 12.5868 13.0867C13.0426 12.6309 13.7818 12.6309 14.2376 13.0867L31.2552 30.1042C31.711 30.56 31.711 31.2991 31.2552 31.755C31.0273 31.9828 30.7285 32.0968 30.4298 32.0968Z"
				fill="white"
			/>
		</svg>
	);
};
