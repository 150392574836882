import { Booking } from '@mopla/data-models';

export const calculateBookingExtraPassengers = (booking: Booking) => {
	return (
		booking.passengerList.filter((b) => !b.isMainPassenger).length +
		(booking.toddlerSeats || 0)
	);
};

export const getMainPassenger = (booking: Booking) => {
	return booking.passengerList.find((b) => b.isMainPassenger);
};
