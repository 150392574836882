import React from 'react';
import { noop } from 'rxjs';

import { EmptyStarIcon } from '../../icons/EmptyStar';
import { FilledStartIcon } from '../../icons/FilledStar';
import { LocationPointIcon } from '../../icons/LocationPoint';
import { LocationStopIcon } from '../../icons/LocationStop';

import { LocationText, Root, StarButton } from './AddressSuggestion.styles';

export interface AddressSuggestionProps {
	location: string;
	isFavourite?: boolean;
	onFavouriteClick: () => void;
	onSelect: () => void;
	disabled?: boolean;
	type?: 'address' | 'stop';
}

export const AddressSuggestion: React.FC<AddressSuggestionProps> = ({
	location,
	isFavourite = false,
	onFavouriteClick,
	onSelect,
	disabled = false,
	type = 'address',
}) => {
	return (
		<Root disabled={disabled} data-testid="search-locationForm-suggestion-card">
			{type === 'address' ? <LocationPointIcon /> : <LocationStopIcon />}
			<LocationText
				disabled={disabled}
				tabIndex={0}
				role="button"
				onClick={!disabled ? onSelect : noop}
				data-testid="search-locationForm-select-button"
			>
				{location}
			</LocationText>
			<StarButton
				type="button"
				onClick={onFavouriteClick}
				data-testid="search-locationForm-favourite-button"
				data-active={isFavourite}
			>
				{isFavourite ? <FilledStartIcon /> : <EmptyStarIcon />}
			</StarButton>
		</Root>
	);
};
