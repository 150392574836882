import { FC } from 'react';

export const SevenEuroVoucher: FC = () => {
	return (
		<svg
			width="573"
			height="463"
			viewBox="0 0 573 463"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M428.787 1H144.126C122.931 1 105.748 18.1825 105.748 39.3782V423.655C105.748 444.851 122.931 462.034 144.126 462.034H428.787C449.983 462.034 467.165 444.851 467.165 423.655V39.3782C467.165 18.1825 449.983 1 428.787 1Z"
				fill="#6FDB89"
				stroke="#6FDB89"
				strokeWidth="1.33333"
				strokeMiterlimit="10"
			/>
			<path
				d="M234.639 149.319C234.347 149.319 234.053 149.224 233.807 149.028C231.859 147.473 229.774 145.84 227.525 144.099C226.942 143.648 226.835 142.811 227.286 142.228C227.738 141.647 228.575 141.541 229.156 141.99C231.417 143.74 233.513 145.38 235.471 146.944C236.047 147.403 236.141 148.242 235.682 148.818C235.418 149.147 235.03 149.319 234.639 149.319Z"
				fill="black"
			/>
			<path
				d="M257.889 172.154C257.486 172.154 257.088 171.973 256.826 171.627C253.486 167.227 250.079 163.406 246.104 159.602C245.572 159.093 245.553 158.249 246.063 157.717C246.572 157.185 247.416 157.167 247.948 157.675C252.027 161.58 255.523 165.5 258.949 170.015C259.395 170.602 259.28 171.438 258.693 171.883C258.452 172.066 258.169 172.154 257.889 172.154Z"
				fill="black"
			/>
			<path
				d="M271.204 192.524C270.75 192.524 270.307 192.292 270.057 191.873C268.605 189.434 267.233 187.171 265.919 185.056C265.531 184.431 265.722 183.609 266.348 183.22C266.972 182.83 267.794 183.023 268.184 183.649C269.507 185.776 270.887 188.054 272.349 190.509C272.725 191.142 272.518 191.96 271.885 192.337C271.671 192.464 271.436 192.524 271.204 192.524Z"
				fill="black"
			/>
			<path
				d="M83.0019 111.791C82.373 111.791 81.8137 111.345 81.6927 110.704C81.5566 109.981 82.0325 109.283 82.7565 109.147C85.386 108.651 88.0605 108.209 90.7057 107.831C91.429 107.727 92.1106 108.233 92.2135 108.962C92.3176 109.691 91.8111 110.367 91.082 110.47C88.4765 110.843 85.8417 111.279 83.2499 111.768C83.1666 111.783 83.0839 111.791 83.0019 111.791Z"
				fill="black"
			/>
			<path
				d="M164.922 115.12C164.805 115.12 164.686 115.104 164.568 115.072C158.633 113.44 152.545 112.101 146.474 111.092C145.747 110.972 145.257 110.285 145.378 109.558C145.497 108.832 146.182 108.337 146.911 108.462C153.073 109.486 159.251 110.845 165.275 112.5C165.984 112.696 166.402 113.429 166.207 114.14C166.044 114.731 165.507 115.12 164.922 115.12ZM128.15 109.002C128.122 109.002 128.094 109.002 128.066 109C121.941 108.62 115.673 108.55 109.538 108.793C108.791 108.809 108.183 108.249 108.154 107.513C108.124 106.778 108.697 106.157 109.433 106.128C115.658 105.882 122.017 105.952 128.231 106.338C128.966 106.384 129.525 107.016 129.479 107.752C129.436 108.459 128.848 109.002 128.15 109.002Z"
				fill="black"
			/>
			<path
				d="M190.025 124.074C189.845 124.074 189.661 124.037 189.486 123.959C187.072 122.89 184.607 121.863 182.16 120.908C181.474 120.64 181.135 119.867 181.403 119.181C181.671 118.496 182.443 118.156 183.13 118.424C185.613 119.394 188.115 120.436 190.566 121.52C191.239 121.819 191.544 122.606 191.245 123.279C191.025 123.778 190.537 124.074 190.025 124.074Z"
				fill="black"
			/>
			<path
				d="M370.154 46.0973C369.696 46.0973 369.251 45.8616 369.003 45.4384C368.63 44.8036 368.842 43.9866 369.478 43.6142C371.786 42.2594 374.154 40.9397 376.512 39.6923C377.164 39.3486 377.97 39.5979 378.314 40.2483C378.658 40.8993 378.41 41.706 377.758 42.0504C375.434 43.2789 373.102 44.579 370.827 45.9137C370.615 46.038 370.383 46.0973 370.154 46.0973Z"
				fill="black"
			/>
			<path
				d="M389.782 36.1336C389.266 36.1336 388.775 35.8328 388.558 35.3296C388.265 34.6538 388.575 33.8687 389.251 33.5763C393.56 31.7098 397.995 29.9917 402.432 28.4683C403.128 28.2254 403.887 28.5998 404.126 29.2964C404.365 29.993 403.995 30.7515 403.298 30.9904C398.926 32.491 394.557 34.1844 390.311 36.0229C390.139 36.0978 389.959 36.1336 389.782 36.1336ZM416.305 27.0282C415.711 27.0282 415.169 26.6291 415.014 26.0282C414.83 25.3153 415.259 24.5881 415.972 24.4038C420.517 23.2313 425.165 22.2228 429.787 21.4071C430.511 21.2788 431.204 21.7638 431.332 22.4884C431.46 23.2137 430.976 23.9051 430.251 24.0334C425.697 24.8368 421.116 25.8302 416.639 26.9858C416.527 27.0145 416.415 27.0282 416.305 27.0282ZM443.921 22.1525C443.243 22.1525 442.663 21.6375 442.596 20.9487C442.524 20.2157 443.06 19.5633 443.793 19.4924C448.45 19.0379 453.197 18.7586 457.903 18.6616C458.605 18.6616 459.248 19.2313 459.264 19.9676C459.279 20.7039 458.694 21.3127 457.958 21.3283C453.32 21.4233 448.641 21.6987 444.052 22.146C444.008 22.1506 443.964 22.1525 443.921 22.1525Z"
				fill="black"
			/>
			<path
				d="M479.972 22.198C479.928 22.198 479.883 22.1961 479.838 22.1915C477.213 21.9291 474.549 21.7214 471.921 21.575C471.185 21.5339 470.622 20.9044 470.663 20.1694C470.704 19.4343 471.344 18.8836 472.069 18.9122C474.736 19.0613 477.439 19.2716 480.103 19.5379C480.836 19.6114 481.37 20.2644 481.297 20.9975C481.228 21.685 480.649 22.198 479.972 22.198Z"
				fill="black"
			/>
			<path
				d="M271.203 192.524C270.467 192.524 269.87 191.927 269.87 191.191C269.87 188.488 269.959 185.768 270.135 183.107C270.184 182.373 270.813 181.823 271.555 181.865C272.289 181.914 272.845 182.549 272.797 183.284C272.624 185.886 272.537 188.546 272.537 191.191C272.537 191.927 271.94 192.524 271.203 192.524Z"
				fill="black"
			/>
			<path
				d="M273.73 168.127C273.638 168.127 273.546 168.118 273.453 168.099C272.732 167.946 272.272 167.238 272.424 166.518C273.565 161.13 275.116 155.716 277.035 150.428C277.287 149.737 278.052 149.378 278.744 149.63C279.436 149.881 279.793 150.646 279.542 151.338C277.665 156.509 276.148 161.803 275.033 167.07C274.9 167.698 274.346 168.127 273.73 168.127ZM284.919 137.049C284.714 137.049 284.507 137.002 284.313 136.903C283.657 136.567 283.398 135.763 283.733 135.108C286.165 130.359 288.988 125.504 292.126 120.677C292.527 120.058 293.354 119.884 293.971 120.285C294.589 120.687 294.764 121.513 294.362 122.13C291.274 126.881 288.497 131.656 286.107 136.323C285.871 136.784 285.404 137.049 284.919 137.049ZM302.841 109.238C302.557 109.238 302.271 109.148 302.028 108.961C301.445 108.513 301.335 107.675 301.785 107.091C304.972 102.947 308.417 98.7341 312.317 94.2113C312.798 93.6527 313.64 93.5902 314.197 94.0719C314.755 94.5531 314.818 95.3949 314.337 95.9522C310.469 100.437 307.055 104.613 303.898 108.718C303.635 109.059 303.24 109.238 302.841 109.238Z"
				fill="black"
			/>
			<path
				d="M324.397 84.0607C324.073 84.0607 323.749 83.9435 323.492 83.7066C322.951 83.2066 322.918 82.3628 323.418 81.8224C325.229 79.8641 327.055 77.9129 328.889 75.967C329.395 75.4318 330.238 75.4071 330.774 75.9116C331.31 76.4168 331.335 77.2606 330.829 77.7964C329.001 79.7358 327.181 81.6805 325.376 83.6323C325.113 83.9168 324.756 84.0607 324.397 84.0607Z"
				fill="black"
			/>
			<path
				d="M56.6724 101.038L61.8107 111.45L73.3005 113.119L64.9864 121.223L66.9491 132.667L56.6724 127.264L46.3957 132.667L48.3584 121.223L40.0443 113.119L51.534 111.45L56.6724 101.038Z"
				fill="white"
			/>
			<path
				d="M68.7201 135.104L56.6726 128.77L44.625 135.104L46.9252 121.689L37.1791 112.188L50.6485 110.231L56.6726 98.0249L62.696 110.231L76.1654 112.188L66.4193 121.689L68.7201 135.104ZM42.9095 114.05L49.7904 120.758L48.1667 130.229L56.6726 125.757L65.1784 130.229L63.5534 120.758L70.4349 114.05L60.9252 112.668L56.6726 104.051L52.4193 112.668L42.9095 114.05Z"
				fill="black"
			/>
			<path
				d="M506.259 11.6958L511.398 22.1073L522.887 23.7768L514.573 31.881L516.536 43.3243L506.259 37.9215L495.983 43.3243L497.945 31.881L489.631 23.7768L501.121 22.1073L506.259 11.6958Z"
				fill="white"
			/>
			<path
				d="M494.212 45.762L496.512 32.3467L486.766 22.846L500.236 20.8883L506.259 8.68262L512.283 20.8883L525.753 22.846L516.006 32.3467L518.307 45.762L506.259 39.4281L494.212 45.762ZM506.259 36.415L514.765 40.887L513.141 31.4157L520.022 24.708L510.512 23.3258L506.259 14.7087L502.006 23.3258L492.497 24.708L499.378 31.4157L497.753 40.887L506.259 36.415Z"
				fill="black"
			/>
			<path
				d="M347.95 40.7219L353.088 51.1334L364.578 52.8029L356.264 60.9071L358.227 72.3504L347.95 66.9476L337.673 72.3504L339.636 60.9071L331.322 52.8029L342.811 51.1334L347.95 40.7219Z"
				fill="white"
			/>
			<path
				d="M359.997 74.788L347.95 68.454L335.902 74.788L338.203 61.3726L328.456 51.872L341.926 49.915L347.95 37.7092L353.973 49.915L367.443 51.872L357.696 61.3726L359.997 74.788ZM347.95 65.441L356.456 69.913L354.831 60.4416L361.713 53.734L352.202 52.3525L347.95 43.7353L343.697 52.3525L334.187 53.734L341.069 60.4416L339.444 69.913L347.95 65.441Z"
				fill="black"
			/>
			<path
				d="M208.949 116.361L214.087 126.773L225.577 128.442L217.263 136.546L219.226 147.99L208.949 142.587L198.672 147.99L200.635 136.546L192.321 128.442L203.811 126.773L208.949 116.361Z"
				fill="white"
			/>
			<path
				d="M220.997 150.427L208.949 144.093L196.902 150.427L199.203 137.012L189.456 127.511L202.925 125.554L208.949 113.348L214.973 125.554L228.442 127.511L218.696 137.012L220.997 150.427ZM195.186 129.373L202.067 136.081L200.443 145.552L208.949 141.08L217.455 145.552L215.83 136.081L222.712 129.373L213.202 127.991L208.949 119.374L204.696 127.991L195.186 129.373Z"
				fill="black"
			/>
			<path
				d="M403.313 367.338L569.831 289.484L549.851 248.396L542.746 251.851C533.081 256.551 523.683 252.526 518.982 242.861L487.863 177.687C483.163 168.021 487.188 156.375 496.853 151.675L503.958 148.22L482.13 106.552L277.075 204.109L5.80914 205.872V406.866L263.57 404.916L274.617 429.664L317.001 407.851L358.689 387.888L403.313 367.338Z"
				fill="white"
			/>
			<path
				d="M274.608 213.959C273.872 213.959 273.275 213.362 273.275 212.626V204.626C273.275 203.889 273.872 203.292 274.608 203.292C275.344 203.292 275.941 203.889 275.941 204.626V212.626C275.941 213.362 275.344 213.959 274.608 213.959Z"
				fill="black"
			/>
			<path
				d="M274.608 381.13C273.872 381.13 273.275 380.533 273.275 379.797V363.08C273.275 362.343 273.872 361.746 274.608 361.746C275.344 361.746 275.941 362.343 275.941 363.08V379.797C275.941 380.533 275.344 381.13 274.608 381.13ZM274.608 347.696C273.872 347.696 273.275 347.099 273.275 346.363V329.645C273.275 328.909 273.872 328.312 274.608 328.312C275.344 328.312 275.941 328.909 275.941 329.645V346.363C275.941 347.099 275.344 347.696 274.608 347.696ZM274.608 314.262C273.872 314.262 273.275 313.665 273.275 312.929V296.211C273.275 295.475 273.872 294.878 274.608 294.878C275.344 294.878 275.941 295.475 275.941 296.211V312.929C275.941 313.665 275.344 314.262 274.608 314.262ZM274.608 280.828C273.872 280.828 273.275 280.231 273.275 279.494V262.777C273.275 262.041 273.872 261.444 274.608 261.444C275.344 261.444 275.941 262.041 275.941 262.777V279.494C275.941 280.231 275.344 280.828 274.608 280.828ZM274.608 247.393C273.872 247.393 273.275 246.796 273.275 246.06V229.343C273.275 228.606 273.872 228.009 274.608 228.009C275.344 228.009 275.941 228.606 275.941 229.343V246.06C275.941 246.796 275.344 247.393 274.608 247.393Z"
				fill="black"
			/>
			<path
				d="M274.608 405.848C273.872 405.848 273.275 405.251 273.275 404.514V396.514C273.275 395.778 273.872 395.181 274.608 395.181C275.344 395.181 275.941 395.778 275.941 396.514V404.514C275.941 405.251 275.344 405.848 274.608 405.848Z"
				fill="black"
			/>
			<path
				d="M273.372 433.224L259.528 404.997L264.332 402.682L275.862 426.104L328.632 400.675L330.947 405.479L273.372 433.224Z"
				fill="black"
			/>
			<path
				d="M330.947 405.479L328.632 400.675L565.824 286.374L548.305 250.02L543.591 252.292C532.601 257.589 519.35 252.955 514.052 241.964L483.363 178.278C480.797 172.953 480.458 166.948 482.408 161.369C484.359 155.79 488.366 151.304 493.69 148.739L498.404 146.466L480.88 110.101L278.221 206.517L275.929 201.701L483.381 103.004L505.524 148.956L496.005 153.543C491.964 155.49 488.923 158.895 487.443 163.129C485.962 167.364 486.22 171.921 488.167 175.963L518.857 239.649C522.877 247.991 532.935 251.509 541.276 247.487L550.795 242.9L572.944 288.864L330.947 405.479Z"
				fill="black"
			/>
			<path
				d="M434.626 139.683C434.13 139.683 433.654 139.405 433.424 138.929L429.951 131.722C429.631 131.059 429.91 130.262 430.573 129.942C431.235 129.624 432.033 129.901 432.353 130.565L435.826 137.771C436.146 138.435 435.867 139.231 435.204 139.551C435.017 139.641 434.82 139.683 434.626 139.683Z"
				fill="black"
			/>
			<path
				d="M507.197 290.281C506.701 290.281 506.225 290.003 505.995 289.527L498.738 274.467C498.418 273.804 498.697 273.007 499.36 272.687C500.021 272.368 500.82 272.646 501.14 273.309L508.397 288.369C508.717 289.033 508.438 289.83 507.775 290.149C507.588 290.239 507.391 290.281 507.197 290.281ZM492.683 260.162C492.187 260.162 491.711 259.884 491.481 259.407L484.224 244.347C483.904 243.684 484.183 242.887 484.846 242.567C485.508 242.248 486.307 242.526 486.626 243.19L493.883 258.249C494.203 258.913 493.924 259.71 493.261 260.029C493.074 260.119 492.878 260.162 492.683 260.162ZM478.169 230.042C477.673 230.042 477.197 229.764 476.967 229.287L469.71 214.227C469.39 213.564 469.669 212.767 470.332 212.447C470.993 212.13 471.792 212.406 472.112 213.07L479.369 228.13C479.689 228.793 479.41 229.59 478.747 229.91C478.56 229.999 478.363 230.042 478.169 230.042ZM463.654 199.923C463.159 199.923 462.682 199.645 462.452 199.168L455.195 184.108C454.876 183.445 455.154 182.648 455.818 182.328C456.48 182.009 457.277 182.287 457.598 182.951L464.855 198.011C465.174 198.674 464.896 199.471 464.232 199.791C464.046 199.88 463.849 199.923 463.654 199.923ZM449.14 169.803C448.644 169.803 448.168 169.525 447.938 169.048L440.681 153.988C440.361 153.325 440.64 152.528 441.303 152.208C441.965 151.889 442.763 152.167 443.083 152.831L450.34 167.891C450.66 168.554 450.381 169.351 449.718 169.671C449.531 169.761 449.335 169.803 449.14 169.803Z"
				fill="black"
			/>
			<path
				d="M517.927 312.548C517.432 312.548 516.955 312.27 516.725 311.793L513.253 304.587C512.933 303.924 513.212 303.127 513.875 302.807C514.537 302.488 515.335 302.766 515.655 303.429L519.128 310.636C519.447 311.299 519.169 312.096 518.505 312.416C518.318 312.506 518.122 312.548 517.927 312.548Z"
				fill="black"
			/>
			<path
				d="M192.775 321.234H168.805C170.177 324.634 172.184 327.417 174.824 329.586C177.463 331.755 180.844 332.84 184.966 332.84C188.147 332.84 190.912 332.514 193.263 331.864C195.611 331.213 197.908 330.418 200.15 329.478L205.356 339.89C202.607 341.337 199.318 342.493 195.486 343.361C191.653 344.229 188.219 344.662 185.182 344.662C176.576 344.662 169.889 342.439 165.117 337.992C160.344 333.545 157.091 327.959 155.356 321.234H144.617L143.316 310.822H153.729C153.656 310.1 153.601 309.395 153.566 308.707C153.528 308.021 153.511 307.316 153.511 306.592C153.511 305.87 153.547 305.111 153.62 304.314C153.691 303.52 153.764 302.76 153.837 302.036H144.617L143.316 291.624H155.464C157.416 284.611 160.923 278.898 165.984 274.487C171.045 270.077 177.661 267.871 185.833 267.871C188.003 267.871 190.893 268.234 194.51 268.956C198.125 269.679 201.523 270.908 204.706 272.643L200.367 282.73C197.184 281.792 194.781 281.049 193.154 280.507C191.527 279.965 189.122 279.693 185.941 279.693C181.891 279.693 178.439 280.742 175.583 282.839C172.726 284.937 170.503 287.865 168.913 291.624H191.69L192.775 302.036H166.635C166.563 302.76 166.508 303.484 166.473 304.206C166.436 304.93 166.419 305.689 166.419 306.484C166.419 307.207 166.436 307.931 166.473 308.653C166.508 309.376 166.563 310.1 166.635 310.822H191.69L192.775 321.234Z"
				fill="black"
			/>
			<path
				d="M329.99 229.659L378.533 206.287C380.524 205.329 382.913 206.165 383.872 208.156C384.83 210.147 383.994 212.537 382.003 213.496L330.987 238.058"
				fill="#1B1B19"
			/>
			<path
				d="M330.022 214.845L369.931 195.631C371.921 194.672 374.311 195.509 375.27 197.5C376.228 199.49 375.392 201.88 373.401 202.839L330.233 223.622"
				fill="#1B1B19"
			/>
			<path
				d="M325.95 202.007L336.087 197.127C338.078 196.168 340.468 197.004 341.427 198.995C342.385 200.986 341.549 203.376 339.558 204.335L329.42 209.215"
				fill="#1B1B19"
			/>
			<path
				d="M404.688 291.363L364.888 310.525C362.897 311.483 360.508 310.647 359.549 308.656C358.591 306.665 359.427 304.275 361.418 303.317L401.217 284.155C403.208 283.196 405.598 284.033 406.556 286.024C407.515 288.015 406.679 290.404 404.688 291.363Z"
				fill="#EAC827"
			/>
			<path
				d="M412.646 272.733L363.823 296.24C361.832 297.198 359.442 296.362 358.484 294.371C357.525 292.38 358.362 289.99 360.353 289.031L409.176 265.525C411.167 264.566 413.557 265.403 414.515 267.394C415.474 269.385 414.637 271.775 412.646 272.733Z"
				fill="#EAC827"
			/>
			<path
				d="M394.561 266.642L345.092 290.46C343.101 291.418 340.711 290.582 339.752 288.591C338.794 286.6 339.63 284.21 341.621 283.252L391.091 259.434C393.082 258.475 395.472 259.312 396.43 261.303C397.389 263.294 396.552 265.683 394.561 266.642Z"
				fill="#EAC827"
			/>
			<path
				d="M381.108 258.321L336.6 279.75C334.609 280.708 332.219 279.872 331.261 277.881C330.302 275.89 331.139 273.5 333.129 272.542L377.638 251.113C379.629 250.154 382.019 250.991 382.977 252.982C383.936 254.973 383.099 257.362 381.108 258.321Z"
				fill="#C53127"
			/>
			<path
				d="M402.317 233.311L328.66 268.775C326.669 269.733 324.279 268.897 323.32 266.906C322.362 264.915 323.198 262.525 325.189 261.566L398.847 226.103C400.838 225.145 403.227 225.981 404.186 227.972C405.145 229.963 404.308 232.353 402.317 233.311Z"
				fill="#C53127"
			/>
			<path
				d="M329.899 244.5L389.883 215.621C391.874 214.662 394.264 215.499 395.223 217.49C396.181 219.48 395.345 221.87 393.354 222.829L329.322 253.657"
				fill="#C53127"
			/>
			<path
				d="M337.54 374.814C336.457 375.335 335.633 375.011 335.069 373.838L325.628 354.231C325.394 353.744 325.346 353.278 325.484 352.829C325.623 352.381 325.946 352.035 326.455 351.789L331.863 349.186C333.456 348.419 335.005 347.959 336.51 347.806C338.017 347.653 339.443 347.826 340.785 348.324C342.129 348.821 343.384 349.661 344.552 350.842C345.72 352.023 346.767 353.576 347.694 355.499C348.61 357.402 349.175 359.2 349.392 360.894C349.608 362.587 349.505 364.142 349.083 365.558C348.662 366.973 347.931 368.244 346.893 369.37C345.856 370.497 344.541 371.443 342.948 372.21L337.54 374.814ZM338.29 369.835L341.409 368.334C343.221 367.461 344.339 366.133 344.762 364.348C345.183 362.566 344.734 360.303 343.413 357.56C342.136 354.906 340.698 353.249 339.1 352.587C337.5 351.927 335.794 352.034 333.982 352.907L330.863 354.409L338.29 369.835Z"
				fill="#1B1B19"
			/>
			<path
				d="M355.68 356.687C354.718 357.124 353.913 356.666 353.263 355.317C352.965 354.698 352.82 354.127 352.828 353.606C352.835 353.086 353.116 352.691 353.669 352.425L363.348 347.765C363.9 347.499 364.382 347.526 364.794 347.844C365.207 348.164 365.563 348.633 365.861 349.252C366.521 350.623 366.342 351.553 365.325 352.043L355.68 356.687Z"
				fill="#1B1B19"
			/>
			<path
				d="M381.286 351.75C381.531 352.258 381.592 352.766 381.469 353.275C381.346 353.784 380.898 354.225 380.124 354.597C378.708 355.279 377.703 355 377.106 353.762L368.625 336.145L363.581 338.573C363.006 338.85 362.509 338.865 362.09 338.616C361.671 338.369 361.334 337.98 361.079 337.449C360.823 336.918 360.735 336.409 360.813 335.922C360.891 335.434 361.185 335.068 361.694 334.823L376.325 327.779C376.833 327.534 377.3 327.528 377.726 327.759C378.15 327.99 378.49 328.371 378.746 328.903C379.001 329.433 379.103 329.943 379.052 330.43C379.001 330.919 378.676 331.306 378.079 331.593L372.804 334.133L381.286 351.75Z"
				fill="#1B1B19"
			/>
			<path
				d="M382.946 326.593C382.627 325.93 382.591 325.363 382.84 324.889C383.088 324.415 383.533 324.023 384.175 323.713C384.772 323.426 385.333 323.334 385.859 323.435C386.384 323.537 386.806 323.918 387.126 324.581L396.598 344.255C396.843 344.764 396.917 345.285 396.822 345.822C396.726 346.36 396.28 346.819 395.484 347.202C394.732 347.564 394.115 347.67 393.634 347.521C393.153 347.371 392.748 346.953 392.418 346.268L382.946 326.593Z"
				fill="#1B1B19"
			/>
			<path
				d="M412.596 339.372C410.804 340.234 409.146 340.624 407.621 340.542C406.096 340.458 404.706 340.031 403.45 339.26C402.193 338.489 401.069 337.458 400.079 336.163C399.087 334.869 398.225 333.46 397.49 331.934C396.713 330.319 396.153 328.689 395.809 327.041C395.466 325.395 395.416 323.818 395.657 322.313C395.899 320.806 396.486 319.421 397.417 318.156C398.346 316.891 399.696 315.832 401.466 314.98C402.859 314.309 404.225 313.965 405.565 313.947C406.905 313.928 408.148 314.12 409.294 314.521C409.819 314.705 410.337 314.945 410.848 315.244C411.36 315.543 411.722 315.913 411.935 316.356C412.244 316.997 412.257 317.577 411.975 318.094C411.693 318.611 411.308 318.987 410.822 319.221C410.445 319.403 410.069 319.44 409.692 319.336C409.315 319.231 408.897 319.099 408.439 318.939C408.078 318.812 407.704 318.686 407.316 318.56C406.928 318.434 406.52 318.35 406.093 318.311C405.665 318.27 405.209 318.286 404.725 318.356C404.24 318.426 403.734 318.588 403.202 318.844C402.208 319.323 401.479 319.946 401.017 320.713C400.556 321.48 400.291 322.343 400.221 323.304C400.152 324.263 400.263 325.285 400.554 326.372C400.845 327.457 401.252 328.542 401.773 329.625C402.317 330.754 402.927 331.782 403.605 332.708C404.283 333.635 405.013 334.374 405.795 334.922C406.577 335.474 407.401 335.811 408.267 335.939C409.131 336.068 410.018 335.914 410.925 335.478C411.456 335.222 411.891 334.937 412.231 334.624C412.57 334.311 412.847 333.966 413.061 333.59C413.275 333.215 413.454 332.823 413.597 332.412C413.741 332.003 413.865 331.569 413.971 331.109C414.053 330.77 414.166 330.381 414.31 329.945C414.452 329.508 414.746 329.183 415.188 328.97C415.697 328.725 416.242 328.682 416.821 328.838C417.4 328.995 417.829 329.36 418.106 329.935C418.511 330.776 418.511 331.852 418.107 333.164C417.923 333.742 417.694 334.316 417.423 334.882C417.15 335.45 416.802 335.999 416.376 336.53C415.952 337.062 415.43 337.565 414.815 338.039C414.197 338.513 413.459 338.956 412.596 339.372Z"
				fill="#1B1B19"
			/>
			<path
				d="M415.093 311.116C414.774 310.453 414.738 309.885 414.987 309.411C415.235 308.937 415.68 308.545 416.322 308.236C416.919 307.949 417.48 307.857 418.006 307.957C418.531 308.059 418.953 308.441 419.272 309.104L423.154 317.166L426.452 304.83C426.567 304.393 426.717 303.981 426.898 303.594C427.079 303.208 427.38 302.912 427.8 302.71C428.11 302.561 428.393 302.485 428.651 302.483C428.91 302.483 429.181 302.522 429.466 302.602C429.761 302.706 430.024 302.845 430.258 303.018C430.491 303.193 430.683 303.434 430.832 303.743C430.949 303.987 431.01 304.285 431.016 304.635C431.022 304.986 430.972 305.338 430.869 305.688L428.517 314.298L440.393 321.821C440.741 322.035 441.006 322.33 441.186 322.705C441.453 323.259 441.494 323.824 441.31 324.403C441.125 324.983 440.68 325.442 439.972 325.783C439.507 326.007 439.062 326.072 438.635 325.977C438.209 325.884 437.771 325.684 437.327 325.382L426.918 318.786L425.838 322.74L428.745 328.778C428.99 329.286 429.064 329.808 428.969 330.345C428.873 330.882 428.427 331.342 427.631 331.725C426.879 332.087 426.262 332.193 425.781 332.044C425.3 331.894 424.895 331.475 424.565 330.79L415.093 311.116Z"
				fill="#1B1B19"
			/>
			<path
				d="M448.088 321.59C447.004 322.111 446.18 321.787 445.616 320.614L436.176 301.007C435.941 300.52 435.893 300.054 436.031 299.605C436.17 299.157 436.493 298.811 437.002 298.565L448.083 293.23C449.188 292.698 450.051 293.074 450.668 294.356C450.945 294.932 451.052 295.452 450.99 295.917C450.929 296.384 450.611 296.755 450.036 297.032L441.41 301.185L444.125 306.824L451.921 303.071C452.983 302.56 453.808 302.913 454.393 304.128C454.67 304.703 454.782 305.235 454.731 305.722C454.68 306.211 454.355 306.598 453.758 306.885L445.961 310.639L448.757 316.445L457.549 312.212C458.014 311.988 458.447 311.999 458.849 312.24C459.252 312.482 459.597 312.902 459.885 313.499C460.162 314.074 460.271 314.6 460.215 315.077C460.158 315.553 459.831 315.936 459.234 316.223L448.088 321.59Z"
				fill="#1B1B19"
			/>
			<path
				d="M473.646 307.282C473.891 307.79 473.952 308.299 473.828 308.808C473.706 309.317 473.258 309.757 472.484 310.13C471.068 310.812 470.063 310.533 469.466 309.294L460.985 291.678L455.941 294.106C455.366 294.383 454.869 294.397 454.451 294.149C454.031 293.901 453.694 293.513 453.439 292.981C453.183 292.451 453.095 291.942 453.173 291.454C453.251 290.967 453.545 290.601 454.054 290.355L468.685 283.311C469.193 283.067 469.66 283.061 470.086 283.292C470.51 283.523 470.85 283.904 471.106 284.435C471.361 284.966 471.463 285.476 471.412 285.963C471.361 286.451 471.036 286.838 470.439 287.126L465.164 289.665L473.646 307.282Z"
				fill="#1B1B19"
			/>
			<path
				d="M335.207 409.597H0V200.603H335.207V254.292H323.307C314.783 254.292 307.847 261.227 307.847 269.752V340.447C307.847 348.973 314.783 355.908 323.307 355.908H335.207V409.597ZM8.00002 401.597H327.207V363.908H323.307C310.371 363.908 299.847 353.384 299.847 340.447V269.752C299.847 256.816 310.371 246.292 323.307 246.292H327.207V208.603H8.00002V401.597Z"
				fill="black"
			/>
			<path
				d="M127.662 284.063L103.566 341.855H86.7657L111.15 285.887H81.2937V271.871H127.662V284.063Z"
				fill="black"
			/>
		</svg>
	);
};
