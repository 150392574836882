import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, User } from 'firebase/auth';

import { localUserManager } from '@mopla/business-logic';
import { Loader } from '@mopla/ui';

import { WelcomeStep } from '../pages/welcome/welcome';

const PrivateRoute: React.FC<{ children: ReactElement }> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [userData, setUserData] = useState<User | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const personalDetails = localStorage.getItem('personalDetails');
	const tryOurApp = location.search.includes(WelcomeStep.tryOurApp);
	const spenditDeeplink = location.pathname.includes('spendit');

	useEffect(() => {
		setLoading(true);
		// if we have a local user, we skip waiting for the refresh and continue
		// to reduce waiting time on unstable network conditions
		try {
			const localUser = localUserManager.get();
			if (localUser) {
				setUserData(localUser);
				setLoading(false);
			}
		} catch (err) {
			// fail silently in this case
		}

		getAuth().onIdTokenChanged((user) => {
			setUserData(user);
			setLoading(false);
			localUserManager.set(user);
		});
	}, []);

	useEffect(() => {
		if (
			(!loading && !userData) ||
			(userData && !userData.emailVerified) ||
			!personalDetails
		) {
			if (!spenditDeeplink) {
				navigate('/welcome', { replace: true });
			}
		}
	}, [
		loading,
		userData,
		navigate,
		personalDetails,
		spenditDeeplink,
		location.pathname,
		location.search,
	]);

	if (loading) {
		return <Loader delay={100} />;
	}

	return (!loading &&
		userData &&
		userData.emailVerified &&
		personalDetails &&
		!tryOurApp) ||
		spenditDeeplink
		? children
		: null;
};

export default PrivateRoute;
