import React, { MouseEventHandler } from 'react';
import { Browser } from '@capacitor/browser';
import i18n from 'i18next';

import { AppStoreDe } from '../../badges/app-store-de';
import { AppStoreEn } from '../../badges/app-store-en';
import googlePlayBadgeDe from '../../badges/google-play-badge-de.png';
import googlePlayBadgeEn from '../../badges/google-play-badge-en.png';

import { BadgesWrapper } from './BadgeComponent.styles';

const IOS_APP_LINK = 'https://apps.apple.com/us/app/mo-pla/id1645774207';
const ANDROID_APP_LINK =
	'https://play.google.com/store/apps/details?id=solutions.mopla.apps.passenger';

export const BadgeComponent = () => {
	const localeDE = i18n.language === 'de';

	const handleClick: (url: string) => MouseEventHandler<HTMLDivElement> =
		(url: string) => (e) => {
			e.preventDefault();
			Browser.open({ url });
		};

	return (
		<BadgesWrapper>
			<div onClick={handleClick(IOS_APP_LINK)}>
				{localeDE ? <AppStoreDe /> : <AppStoreEn />}
			</div>
			<div onClick={handleClick(ANDROID_APP_LINK)}>
				<img
					src={localeDE ? googlePlayBadgeDe : googlePlayBadgeEn}
					alt="Google badge"
				/>
			</div>
		</BadgesWrapper>
	);
};
