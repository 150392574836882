import React from 'react';

export const CameraIcon = () => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.5 9H19.5V19H5.5V9H8.5H9.17703L9.42848 8.37139L10.177 6.5H14.823L15.5715 8.37139L15.823 9H16.5Z"
				stroke="#1C308B"
				strokeWidth="2"
			/>
			<circle cx="12.5" cy="14" r="2" stroke="#1C308B" strokeWidth="2" />
		</svg>
	);
};
