/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardService {
	/**
	 * Returns dashboard information for distributor for current day
	 * Returns information about manual disposition, active drives, unassigned shifts, negative vehicle checks, negative driver checks
	 * @returns any A JSON object of integer values
	 * @throws ApiError
	 */
	public static getApiHome(): CancelablePromise<{
		/**
		 * Value of manual dispositions for the current day
		 */
		manualDisposition: number;
		/**
		 * Value of active drives for the current day (immutable)
		 */
		activeDrives: number;
		/**
		 * Value of unassigned shifts for current day + 2 weeks
		 */
		unassignedShifts: number;
		/**
		 * Value of negative vehicle checks for current day
		 */
		negativeVehicleChecks: number;
		/**
		 * Value of negative driver checks for current day
		 */
		negativeDriverChecks: number;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/home',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}
}
