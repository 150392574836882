/** The values are taken from the openapi, see cancelSubscription payload */
export enum ECancellationReasons {
	NoSubscriptionNeeded = 'NOT_NEEDED_ANYMORE',
	CantAfford = 'CANT_AFFORD',
	EmployerProvidedTicket = 'RECEIVING_THROUGH_EMPLOYER',
	UnawareOfSubscription = 'UNAWARE_OF_SUBSCRIPTION',
	UnhappyWithService = 'NOT_SATISFIED',
	BetterOfferElsewhere = 'RECEIVING_CHEAPER',
	OtherReason = 'OTHER_REASON',
}
