import { IWhoami } from '../lib/types';

export const defaultUser: IWhoami = {
	userId: '54aac555-9f20-47ed-b7bb-f42d6f1f8de8',
	email: 'new15@trash-mail.com',
	name: 'User, New',
	firstName: 'New',
	lastName: 'User',
	phone: '12345667890',
	dateOfBirth: '1997-12-12',
	address: {
		streetNumber: '1',
		street: 'test',
		zipCode: '1234',
		city: 'test',
		country: null,
	},
	alternateInvoiceAddress: null,
	privileges: [
		'CAN_CREATE_NEW_PAYMENT_INTENT_SETUP',
		'CAN_MAKE_BOOKING',
		'CAN_READ_BOOKINGS_OWN',
		'CAN_CANCEL_BOOKING_OWN',
		'CAN_ADD_PASSENGER_DETAILS',
		'CAN_VERIFY_EMAIL_OWN',
		'CAN_DELETE_ACCOUNT_OWN',
		'CAN_ADD_FAVOURITE_ADDRESS',
		'CAN_GET_ROUTE_PLAN',
		'CAN_REDEEM_VOUCHER',
		'CAN_GET_ALL_VOUCHERS',
		'CAN_REMOVE_VOUCHERS',
		'CAN_ADD_PUSH_TOKEN',
		'CAN_TRIGGER_NOTIFICATION_MANUALLY',
		'CAN_DO_PASSENGER_STUFF',
	],
	isBlacklisted: false,
	isDisabled: false,
	pleaseComplete: false,
	isStripeCustomerConnected: true,
	hasDefaultStripePaymentMethod: true,
	isInMoplaArea: false,
	metaData: {
		jobticketLastName: '15',
		jobticketFirstName: '15',
		awaitingJobTicketEmployeeDeletion: true,
	},
	isEligibleForJobticket: true,
	disabilityStatusValidTo: null,
	language: 'en',
};
