import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/capacitor';
import { BrowserTracing, init as sentryReactInit } from '@sentry/react';
import { AxiosError } from 'axios';

import {
	addLocationEffect,
	addMultipleEffects,
	addPushTokenEffect,
	bootstrapBusinessLogic,
	BusinessLayerContext,
	cancelSubscriptionEffect,
	checkBannedEmailEffect,
	deleteVoucherEffect,
	fetchActiveVouchersEffect,
	fetchDefaultPaymentMethodEffect,
	fetchSubscriptionOfferingEffect,
	fetchSubscriptionsEffect,
	fetchTicketsEffect,
	fetchUserEffect,
	initDatabase,
	initPassengerPushesEffect,
	inquiryInvoiceEffect,
	launchEntity,
	locationEntity,
	passengerBookingEntity,
	pauseSubscriptionEffect,
	redeemVoucherEffect,
	resumeSubscriptionEffect,
	savePassengerBookingEffect,
	setDefaultPaymentMethodEffect,
	setLaunchEffect,
	setUserLanguageEffect,
	subscriptionEntity,
	ticketEntity,
	userEntity,
	vouchersEntity,
} from '@mopla/business-logic';

import '@mopla/i18';

import passApi from './api/passApi';
import { App } from './app/app';
import { environment } from './environments/environment';

Sentry.init(
	{
		dsn: environment.sentryDns,
		environment: environment.env,
		integrations: [
			new BrowserTracing({
				tracingOrigins: [environment.baseUrl],
			}),
		],
		tracesSampler: (samplingContext) => {
			if (samplingContext.isPayment) {
				return 1;
			}
			return 0.2;
		},
		beforeSend(event, hint) {
			const error = hint.originalException as Error & AxiosError;
			// we don't want to collect auth errors and filter them here
			const isAuthError =
				error && error.response && error.response.status === 401;
			const isNetworkError = error && error.message === 'Network Error';
			if (isAuthError || isNetworkError) {
				return null;
			}
			return event;
		},
	},
	sentryReactInit
);

(function (w, d, i) {
	const windowRef = w as any;

	windowRef.dataLayer = windowRef.dataLayer || [];
	windowRef.dataLayer.push({
		'gtm.start': new Date().getTime(),
		event: 'gtm.js',
	});
	const f = d.getElementsByTagName('script')[0],
		j = d.createElement('script');

	j.async = true;
	j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i;
	f.parentNode?.insertBefore(j, f);
})(window, document, 'GTM-WFMZXZX9');

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

(async () => {
	const db = await initDatabase();

	const bl = await bootstrapBusinessLogic(
		passApi,
		[
			locationEntity,
			launchEntity,
			userEntity,
			passengerBookingEntity,
			ticketEntity,
			subscriptionEntity,
			vouchersEntity,
		],
		db
	);

	addMultipleEffects([
		addLocationEffect,
		setLaunchEffect,
		fetchDefaultPaymentMethodEffect,
		savePassengerBookingEffect,
		fetchTicketsEffect,
		fetchSubscriptionsEffect,
		fetchSubscriptionOfferingEffect,
		fetchUserEffect,
		setDefaultPaymentMethodEffect,
		initPassengerPushesEffect,
		addPushTokenEffect,
		setUserLanguageEffect,
		inquiryInvoiceEffect,
		pauseSubscriptionEffect,
		resumeSubscriptionEffect,
		cancelSubscriptionEffect,
		fetchActiveVouchersEffect,
		redeemVoucherEffect,
		deleteVoucherEffect,
		checkBannedEmailEffect,
	]);

	root.render(
		<BusinessLayerContext.Provider value={bl}>
			<App />
		</BusinessLayerContext.Provider>
	);
})();
