import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '../../atoms/Button/Button';
import { Text } from '../../atoms/Text/Text';
import { ArrowRightIcon } from '../../icons/ArrowRight';
import { Modal } from '../../molecules/Modal/Modal';

import { Description } from './EmailSentWarningMessage.styles';

interface IProps {
	open: boolean;
	onClose: VoidFunction;
	email?: string | null;
}

export const EmailSentWarningMessage: FC<IProps> = (props) => {
	const { t } = useTranslation(['registration']);
	const { open, onClose, email = '' } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			title={t('resendVerify.text.warning_title')}
			mainContent={
				<>
					<Text variant="body1">
						<Trans
							i18nKey="registration:resendVerify.text.warning_text_start"
							values={{ regEmail: email }}
							components={{ bold: <Text variant="body1" bold="bold" /> }}
						/>{' '}
						{t('resendVerify.text.warning_text_end')}
					</Text>
					<Description marginTop="24px" marginBottom="40px">
						{t('resendVerify.text.warning_description')}
					</Description>
				</>
			}
			footerComp={
				<Button
					data-testid="registrationForm-verify-email-warning-modal-submit-button"
					variant="primary"
					icon={<ArrowRightIcon />}
					type="button"
					onClick={onClose}
				>
					{t('resendVerify.text.warning_button')}
				</Button>
			}
		/>
	);
};
