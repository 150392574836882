import React from 'react';

export const LocationPointIcon = () => {
	return (
		<svg
			width="13"
			height="16"
			viewBox="0 0 13 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.1887 6.28575C11.1537 4.83322 10.6611 3.62199 9.71118 2.65214C8.76126 1.68228 7.57545 1.1792 6.15381 1.14295C4.73176 1.17879 3.54595 1.68185 2.59645 2.65214C1.64694 3.62242 1.15442 4.83365 1.11893 6.28575C1.11893 7.34518 1.5355 8.55641 2.36868 9.91937C3.20195 11.2825 4.46355 12.7914 6.15397 14.4464C7.84388 12.7915 9.10549 11.2826 9.93927 9.91937C10.7728 8.55637 11.1894 7.34515 11.189 6.28575H11.1887ZM6.15378 16C2.05137 12.1907 0 8.95252 0 6.28575C0.0465769 4.4998 0.646834 3.01785 1.8008 1.8394C2.95477 0.661091 4.40579 0.0479671 6.15385 0C7.90232 0.0475753 9.35317 0.660699 10.5069 1.8394C11.6605 3.01811 12.2607 4.50023 12.3077 6.28575C12.3077 8.95245 10.2565 12.1907 6.15391 16H6.15378ZM6.15378 8.00003C6.63154 7.98817 7.02778 7.82159 7.3426 7.50005C7.65741 7.17851 7.81476 6.77378 7.81476 6.28575C7.81476 5.79772 7.65742 5.39302 7.3426 5.07146C7.02778 4.74989 6.63157 4.58917 6.15378 4.58917C5.67599 4.58917 5.27979 4.74989 4.96497 5.07146C4.65015 5.39303 4.4928 5.79772 4.4928 6.28575C4.4928 6.77378 4.65015 7.17848 4.96497 7.50005C5.27979 7.82162 5.67599 7.98818 6.15378 8.00003ZM6.15378 9.14289C5.36121 9.11891 4.70274 8.83906 4.1784 8.30348C3.65407 7.7679 3.38007 7.09532 3.35661 6.28575C3.38008 5.47619 3.65406 4.8036 4.1784 4.26803C4.70274 3.73246 5.36121 3.45259 6.15378 3.42862C6.94636 3.4526 7.60483 3.73245 8.12916 4.26803C8.6535 4.8036 8.92749 5.47619 8.95096 6.28575C8.92748 7.09532 8.65351 7.7679 8.12916 8.30348C7.60483 8.83905 6.94636 9.11892 6.15378 9.14289Z"
				fill="currentColor"
			/>
		</svg>
	);
};
