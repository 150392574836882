import * as yup from 'yup';

import { passwordValidator } from '@mopla/ui';

const RegistrationValidationSchema = yup.object().shape({
	newEmail: yup.string().email('email.valid').required('email.required'),
	newPassword: passwordValidator(),
});

export default RegistrationValidationSchema;
