import { createContext, useContext } from 'react';

import { DiscountState } from '@mopla/data-models';

import { TPassengersFormPath } from './types';
import { useFlowSteps } from './useBookingFlowSteps';
import { IBookingFlowState, TDispatch, TSetState } from './useBookingFlowStore';

export interface IBookingContext {
	hasImpaired: boolean;
	hasImpairedCompanion: boolean;
	hasValidDisabledStatus: boolean;
	canBookForDisabled: boolean;
	isLBODTFlex: boolean;
	isLBTFlow: boolean;
	isMixedRoute: boolean;
	paymentAmount: {
		digitalPayment: number;
		hasPriceAccordingTarif?: boolean | null;
		notNeeded?: boolean;
	};
	setPriceInfo: (
		discountState: DiscountState | null,
		formType: TPassengersFormPath
	) => void;
	flowStepsMethods: ReturnType<typeof useFlowSteps>;
	state: IBookingFlowState;
	setState: TSetState;
	dispatch: TDispatch;
}

const ctx = createContext<IBookingContext>({} as IBookingContext);

export const BookingContextProvider = ctx.Provider;

export const useBookingContext = () => useContext(ctx);
