import { useCallback, useState } from 'react';

import { Itinerary } from '@mopla/data-models';

import { useBusinessLayer } from '../business-logic';
import { parseItinerarySearchParams } from '../mappers/parseItinerarySearchParams';

export const useCheaperRoutesSuggestions = (searchParams: URLSearchParams) => {
	const businessLayer = useBusinessLayer();
	const [data, setData] = useState<Itinerary[] | null>(null);
	const [isLoading, setLoading] = useState(false);

	const reset = useCallback(() => {
		setData(null);
	}, []);

	const load = async () => {
		const itinerarySearchParams = parseItinerarySearchParams(searchParams);

		try {
			setLoading(true);

			const result = await businessLayer.api.get(`/api/router/plan/cheaperTrips
					?fromPlaceLat=${itinerarySearchParams.fromPlaceLat}
					&fromPlaceLng=${itinerarySearchParams.fromPlaceLng}
					&fromPlaceLabel=${itinerarySearchParams.fromPlaceLabel}
					&fromPlaceZip=${itinerarySearchParams.fromPlaceZip}
					&fromPlaceRegion=${itinerarySearchParams.fromPlaceRegion}
					&toPlaceLat=${itinerarySearchParams.toPlaceLat}
					&toPlaceLng=${itinerarySearchParams.toPlaceLng}
					&toPlaceLabel=${itinerarySearchParams.toPlaceLabel}
					&toPlaceZip=${itinerarySearchParams.toPlaceZip}
					&toPlaceRegion=${itinerarySearchParams.toPlaceRegion}
					&datetime=${itinerarySearchParams.datetime}
					&arriveBy=${itinerarySearchParams.arriveBy}
					&specialNeeds=${itinerarySearchParams.specialNeeds}`);

			const preparedData = prepareData(result.itineraries);

			setData(preparedData);

			return preparedData.length > 0;
		} catch (e) {
			console.log(e);
			return false;
		} finally {
			setLoading(false);
		}
	};

	return { data, isLoading, reset, load };
};

function prepareData(itineraries: any[]): Itinerary[] {
	return itineraries.map((itinerary) => ({
		...itinerary,
		isCheaperSuggestion: true,
	}));
}
