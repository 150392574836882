import { FC } from 'react';

export const DigitalBonus: FC = () => {
	return (
		<svg
			width="574"
			height="463"
			viewBox="0 0 574 463"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M429.493 1.12881H144.833C123.637 1.12881 106.454 18.3113 106.454 39.507V423.784C106.454 444.98 123.637 462.162 144.833 462.162H429.493C450.689 462.162 467.871 444.98 467.871 423.784V39.507C467.871 18.3113 450.689 1.12881 429.493 1.12881Z"
				fill="#6FDB89"
				stroke="#6FDB89"
				strokeWidth="1.33333"
				strokeMiterlimit="10"
			/>
			<path
				d="M235.345 149.448C235.053 149.448 234.76 149.353 234.514 149.157C232.566 147.602 230.48 145.969 228.231 144.228C227.648 143.777 227.542 142.94 227.992 142.357C228.444 141.776 229.281 141.67 229.863 142.119C232.124 143.869 234.219 145.509 236.178 147.073C236.753 147.532 236.847 148.371 236.388 148.947C236.124 149.276 235.736 149.448 235.345 149.448Z"
				fill="black"
			/>
			<path
				d="M258.595 172.283C258.192 172.283 257.794 172.102 257.532 171.756C254.192 167.356 250.786 163.535 246.81 159.731C246.279 159.222 246.26 158.378 246.769 157.846C247.279 157.314 248.122 157.296 248.654 157.804C252.734 161.709 256.229 165.629 259.655 170.144C260.101 170.731 259.986 171.567 259.4 172.012C259.159 172.195 258.876 172.283 258.595 172.283Z"
				fill="black"
			/>
			<path
				d="M271.911 192.653C271.456 192.653 271.013 192.421 270.764 192.002C269.311 189.563 267.939 187.3 266.626 185.185C266.237 184.56 266.428 183.738 267.054 183.349C267.678 182.959 268.501 183.152 268.89 183.778C270.213 185.905 271.594 188.183 273.055 190.638C273.432 191.27 273.225 192.089 272.592 192.466C272.378 192.593 272.142 192.653 271.911 192.653Z"
				fill="black"
			/>
			<path
				d="M83.7082 111.92C83.0793 111.92 82.52 111.474 82.3989 110.833C82.2629 110.11 82.7388 109.412 83.4628 109.276C86.0923 108.78 88.7668 108.337 91.412 107.96C92.1353 107.856 92.8169 108.362 92.9198 109.091C93.0239 109.82 92.5174 110.496 91.7883 110.599C89.1828 110.972 86.548 111.408 83.9562 111.897C83.8729 111.912 83.7902 111.92 83.7082 111.92Z"
				fill="black"
			/>
			<path
				d="M165.628 115.249C165.511 115.249 165.393 115.233 165.274 115.201C159.339 113.569 153.251 112.23 147.18 111.221C146.454 111.1 145.963 110.414 146.084 109.687C146.204 108.961 146.888 108.466 147.618 108.591C153.779 109.615 159.957 110.974 165.981 112.629C166.691 112.824 167.109 113.558 166.913 114.268C166.751 114.86 166.213 115.249 165.628 115.249ZM128.856 109.131C128.828 109.131 128.8 109.13 128.772 109.128C122.647 108.749 116.379 108.679 110.245 108.921C109.497 108.938 108.889 108.378 108.86 107.642C108.831 106.906 109.404 106.286 110.139 106.257C116.364 106.011 122.723 106.081 128.937 106.467C129.672 106.513 130.231 107.145 130.185 107.88C130.142 108.587 129.555 109.131 128.856 109.131Z"
				fill="black"
			/>
			<path
				d="M190.732 124.203C190.551 124.203 190.368 124.166 190.193 124.088C187.778 123.019 185.313 121.992 182.866 121.037C182.18 120.769 181.842 119.996 182.109 119.31C182.377 118.625 183.15 118.285 183.836 118.553C186.32 119.523 188.821 120.565 191.272 121.649C191.945 121.947 192.25 122.735 191.952 123.408C191.731 123.906 191.243 124.203 190.732 124.203Z"
				fill="black"
			/>
			<path
				d="M370.86 46.2262C370.402 46.2262 369.957 45.9905 369.709 45.5673C369.336 44.9325 369.549 44.1155 370.184 43.7431C372.493 42.3883 374.86 41.0686 377.219 39.8212C377.87 39.4775 378.676 39.7268 379.021 40.3772C379.364 41.0282 379.116 41.8349 378.465 42.1793C376.14 43.4078 373.808 44.7079 371.533 46.0426C371.321 46.1669 371.089 46.2262 370.86 46.2262Z"
				fill="black"
			/>
			<path
				d="M390.488 36.2626C389.972 36.2626 389.482 35.9618 389.264 35.4586C388.971 34.7828 389.282 33.9976 389.958 33.7053C394.267 31.8388 398.702 30.1207 403.138 28.5972C403.834 28.3544 404.593 28.7287 404.833 29.4254C405.071 30.122 404.701 30.8804 404.004 31.1194C399.633 32.62 395.263 34.3134 391.017 36.1519C390.845 36.2268 390.665 36.2626 390.488 36.2626ZM417.011 27.1571C416.417 27.1571 415.875 26.758 415.721 26.1571C415.536 25.4442 415.965 24.717 416.678 24.5328C421.223 23.3602 425.871 22.3518 430.493 21.536C431.217 21.4078 431.911 21.8928 432.038 22.6174C432.166 23.3427 431.682 24.0341 430.957 24.1623C426.403 24.9657 421.823 25.9592 417.345 27.1148C417.233 27.1435 417.121 27.1571 417.011 27.1571ZM444.627 22.2815C443.949 22.2815 443.369 21.7665 443.302 21.0777C443.23 20.3446 443.767 19.6923 444.499 19.6213C449.157 19.1669 453.903 18.8876 458.609 18.7906C459.312 18.7906 459.954 19.3602 459.97 20.0966C459.985 20.8329 459.4 21.4416 458.664 21.4573C454.027 21.5523 449.347 21.8277 444.758 22.275C444.714 22.2795 444.67 22.2815 444.627 22.2815Z"
				fill="black"
			/>
			<path
				d="M480.678 22.327C480.634 22.327 480.589 22.3251 480.544 22.3205C477.919 22.0582 475.255 21.8505 472.627 21.704C471.892 21.663 471.329 21.0334 471.37 20.2984C471.411 19.5634 472.051 19.0126 472.775 19.0412C475.443 19.1903 478.146 19.4006 480.81 19.6669C481.542 19.7404 482.077 20.3934 482.004 21.1265C481.935 21.814 481.355 22.327 480.678 22.327Z"
				fill="black"
			/>
			<path
				d="M271.909 192.653C271.173 192.653 270.576 192.056 270.576 191.32C270.576 188.617 270.665 185.897 270.842 183.236C270.891 182.502 271.519 181.952 272.261 181.994C272.995 182.043 273.551 182.678 273.503 183.413C273.33 186.015 273.243 188.675 273.243 191.32C273.243 192.056 272.646 192.653 271.909 192.653Z"
				fill="black"
			/>
			<path
				d="M274.436 168.256C274.344 168.256 274.252 168.247 274.159 168.227C273.438 168.074 272.978 167.367 273.13 166.647C274.271 161.259 275.822 155.845 277.741 150.557C277.993 149.865 278.758 149.507 279.45 149.759C280.142 150.01 280.499 150.774 280.248 151.467C278.371 156.638 276.854 161.932 275.739 167.199C275.607 167.826 275.053 168.256 274.436 168.256ZM285.625 137.178C285.42 137.178 285.213 137.131 285.019 137.031C284.363 136.696 284.104 135.892 284.439 135.237C286.871 130.488 289.695 125.632 292.833 120.806C293.234 120.187 294.06 120.013 294.677 120.414C295.295 120.815 295.47 121.642 295.068 122.259C291.98 127.009 289.203 131.785 286.813 136.452C286.577 136.913 286.11 137.178 285.625 137.178ZM303.547 109.367C303.263 109.367 302.977 109.277 302.734 109.09C302.151 108.642 302.042 107.804 302.491 107.22C305.678 103.076 309.124 98.8629 313.023 94.3401C313.504 93.7815 314.346 93.719 314.904 94.2008C315.461 94.6819 315.524 95.5237 315.043 96.081C311.176 100.566 307.762 104.742 304.604 108.847C304.342 109.188 303.946 109.367 303.547 109.367Z"
				fill="black"
			/>
			<path
				d="M325.103 84.1897C324.779 84.1897 324.455 84.0725 324.198 83.8355C323.657 83.3355 323.624 82.4918 324.124 81.9514C325.935 79.9931 327.761 78.0419 329.596 76.0959C330.101 75.5608 330.945 75.536 331.48 76.0406C332.016 76.5458 332.041 77.3896 331.536 77.9254C329.708 79.8648 327.887 81.8095 326.083 83.7613C325.82 84.0458 325.462 84.1897 325.103 84.1897Z"
				fill="black"
			/>
			<path
				d="M57.3786 101.167L62.517 111.578L74.0067 113.248L65.6926 121.352L67.6553 132.795L57.3786 127.393L47.1019 132.795L49.0646 121.352L40.7505 113.248L52.2403 111.578L57.3786 101.167Z"
				fill="white"
			/>
			<path
				d="M69.4263 135.233L57.3788 128.899L45.3313 135.233L47.6314 121.818L37.8853 112.317L51.3547 110.36L57.3788 98.1539L63.4022 110.36L76.8716 112.317L67.1255 121.818L69.4263 135.233ZM43.6158 114.179L50.4966 120.887L48.8729 130.358L57.3788 125.886L65.8847 130.358L64.2597 120.887L71.1412 114.179L61.6314 112.797L57.3788 104.18L53.1255 112.797L43.6158 114.179Z"
				fill="black"
			/>
			<path
				d="M506.966 11.8246L512.104 22.2361L523.594 23.9056L515.28 32.0098L517.242 43.4531L506.966 38.0503L496.689 43.4531L498.652 32.0098L490.338 23.9056L501.827 22.2361L506.966 11.8246Z"
				fill="white"
			/>
			<path
				d="M494.918 45.8909L497.219 32.4755L487.472 22.9748L500.942 21.0172L506.965 8.81143L512.989 21.0172L526.459 22.9748L516.712 32.4755L519.013 45.8909L506.965 39.5569L494.918 45.8909ZM506.965 36.5439L515.471 41.0159L513.847 31.5445L520.728 24.8368L511.219 23.4547L506.965 14.8375L502.713 23.4547L493.203 24.8368L500.084 31.5445L498.459 41.0159L506.965 36.5439Z"
				fill="black"
			/>
			<path
				d="M348.656 40.851L353.794 51.2624L365.284 52.9319L356.97 61.0361L358.933 72.4794L348.656 67.0766L338.379 72.4794L340.342 61.0361L332.028 52.9319L343.518 51.2624L348.656 40.851Z"
				fill="white"
			/>
			<path
				d="M360.704 74.9169L348.656 68.5829L336.609 74.9169L338.909 61.5015L329.163 52.0009L342.632 50.0439L348.656 37.8381L354.679 50.0439L368.15 52.0009L358.403 61.5015L360.704 74.9169ZM348.656 65.5699L357.162 70.0419L355.537 60.5706L362.419 53.8629L352.909 52.4814L348.656 43.8642L344.403 52.4814L334.893 53.8629L341.775 60.5706L340.15 70.0419L348.656 65.5699Z"
				fill="black"
			/>
			<path
				d="M209.655 116.49L214.794 126.901L226.283 128.571L217.969 136.675L219.932 148.118L209.655 142.716L199.379 148.118L201.341 136.675L193.027 128.571L204.517 126.901L209.655 116.49Z"
				fill="white"
			/>
			<path
				d="M221.703 150.556L209.655 144.222L197.608 150.556L199.909 137.141L190.162 127.64L203.631 125.683L209.655 113.477L215.679 125.683L229.148 127.64L219.402 137.141L221.703 150.556ZM195.892 129.502L202.773 136.21L201.15 145.681L209.655 141.209L218.161 145.681L216.536 136.21L223.418 129.502L213.908 128.12L209.655 119.503L205.402 128.12L195.892 129.502Z"
				fill="black"
			/>
			<path
				d="M404.019 367.467L570.538 289.613L550.557 248.525L543.453 251.98C533.787 256.68 524.389 252.655 519.689 242.99L488.569 177.816C483.869 168.15 487.894 156.504 497.56 151.804L504.664 148.349L482.837 106.681L277.781 204.238L6.51538 206.001V406.994L264.276 405.044L275.323 429.793L317.707 407.98L359.395 388.017L404.019 367.467Z"
				fill="white"
			/>
			<path
				d="M275.314 214.088C274.578 214.088 273.981 213.491 273.981 212.755V204.755C273.981 204.018 274.578 203.421 275.314 203.421C276.051 203.421 276.648 204.018 276.648 204.755V212.755C276.648 213.491 276.051 214.088 275.314 214.088Z"
				fill="black"
			/>
			<path
				d="M275.314 381.259C274.578 381.259 273.981 380.662 273.981 379.926V363.209C273.981 362.472 274.578 361.875 275.314 361.875C276.051 361.875 276.648 362.472 276.648 363.209V379.926C276.648 380.662 276.051 381.259 275.314 381.259ZM275.314 347.825C274.578 347.825 273.981 347.228 273.981 346.492V329.774C273.981 329.038 274.578 328.441 275.314 328.441C276.051 328.441 276.648 329.038 276.648 329.774V346.492C276.648 347.228 276.051 347.825 275.314 347.825ZM275.314 314.391C274.578 314.391 273.981 313.794 273.981 313.058V296.34C273.981 295.604 274.578 295.007 275.314 295.007C276.051 295.007 276.648 295.604 276.648 296.34V313.058C276.648 313.794 276.051 314.391 275.314 314.391ZM275.314 280.957C274.578 280.957 273.981 280.36 273.981 279.623V262.906C273.981 262.169 274.578 261.572 275.314 261.572C276.051 261.572 276.648 262.169 276.648 262.906V279.623C276.648 280.36 276.051 280.957 275.314 280.957ZM275.314 247.522C274.578 247.522 273.981 246.925 273.981 246.189V229.472C273.981 228.735 274.578 228.138 275.314 228.138C276.051 228.138 276.648 228.735 276.648 229.472V246.189C276.648 246.925 276.051 247.522 275.314 247.522Z"
				fill="black"
			/>
			<path
				d="M275.314 405.977C274.578 405.977 273.981 405.38 273.981 404.643V396.643C273.981 395.907 274.578 395.31 275.314 395.31C276.051 395.31 276.648 395.907 276.648 396.643V404.643C276.648 405.38 276.051 405.977 275.314 405.977Z"
				fill="black"
			/>
			<path
				d="M274.079 433.353L260.234 405.126L265.039 402.811L276.568 426.233L329.338 400.804L331.653 405.608L274.079 433.353Z"
				fill="black"
			/>
			<path
				d="M331.653 405.608L329.338 400.804L566.53 286.503L549.012 250.149L544.297 252.421C533.307 257.718 520.056 253.084 514.758 242.093L484.069 178.406C481.503 173.082 481.164 167.077 483.114 161.498C485.065 155.919 489.072 151.433 494.396 148.867L499.111 146.595L481.586 110.229L278.927 206.646L276.635 201.83L484.087 103.133L506.23 149.085L496.711 153.672C492.67 155.619 489.629 159.024 488.149 163.258C486.668 167.492 486.926 172.05 488.874 176.091L519.563 239.778C523.583 248.12 533.641 251.638 541.982 247.616L551.501 243.029L573.65 288.992L331.653 405.608Z"
				fill="black"
			/>
			<path
				d="M435.332 139.812C434.836 139.812 434.36 139.534 434.13 139.058L430.657 131.851C430.338 131.188 430.616 130.391 431.28 130.071C431.941 129.753 432.739 130.03 433.06 130.694L436.532 137.9C436.852 138.563 436.573 139.36 435.91 139.68C435.723 139.77 435.527 139.812 435.332 139.812Z"
				fill="black"
			/>
			<path
				d="M507.903 290.41C507.407 290.41 506.931 290.132 506.701 289.656L499.444 274.596C499.124 273.933 499.403 273.136 500.066 272.816C500.728 272.497 501.527 272.775 501.846 273.438L509.103 288.498C509.423 289.162 509.144 289.959 508.481 290.278C508.294 290.368 508.097 290.41 507.903 290.41ZM493.389 260.291C492.894 260.291 492.417 260.013 492.187 259.536L484.93 244.476C484.61 243.813 484.889 243.016 485.553 242.696C486.215 242.377 487.013 242.655 487.332 243.319L494.59 258.378C494.909 259.042 494.631 259.839 493.967 260.158C493.78 260.248 493.584 260.291 493.389 260.291ZM478.875 230.171C478.379 230.171 477.903 229.893 477.673 229.416L470.416 214.356C470.096 213.693 470.375 212.896 471.038 212.576C471.7 212.259 472.499 212.535 472.818 213.199L480.075 228.259C480.395 228.922 480.116 229.719 479.453 230.039C479.266 230.128 479.069 230.171 478.875 230.171ZM464.36 200.052C463.865 200.052 463.388 199.774 463.159 199.297L455.902 184.237C455.582 183.574 455.86 182.777 456.524 182.457C457.187 182.138 457.984 182.416 458.304 183.08L465.561 198.14C465.881 198.803 465.602 199.6 464.939 199.92C464.752 200.009 464.555 200.052 464.36 200.052ZM449.846 169.932C449.35 169.932 448.874 169.654 448.644 169.177L441.387 154.117C441.068 153.454 441.346 152.657 442.01 152.337C442.672 152.018 443.469 152.296 443.79 152.96L451.047 168.02C451.366 168.683 451.088 169.48 450.424 169.8C450.237 169.89 450.041 169.932 449.846 169.932Z"
				fill="black"
			/>
			<path
				d="M518.633 312.677C518.138 312.677 517.661 312.399 517.431 311.922L513.959 304.716C513.639 304.052 513.918 303.255 514.581 302.936C515.243 302.617 516.041 302.895 516.361 303.558L519.834 310.765C520.153 311.428 519.875 312.225 519.211 312.545C519.025 312.634 518.828 312.677 518.633 312.677Z"
				fill="black"
			/>
			<path
				d="M50.0409 332.771H63.0455V293.757L56.8417 297.336L49.5637 286.837L68.1757 275.98H78.5559V332.771H87.7428L89.0546 343.509H51.2343L50.0409 332.771Z"
				fill="black"
			/>
			<path
				d="M101.461 309.505C101.461 298.61 104.403 290.04 110.291 283.794C116.176 277.552 123.534 274.429 132.362 274.429C141.27 274.429 148.607 277.453 154.375 283.496C160.141 289.542 163.025 298.132 163.025 309.267C163.025 320.244 160.061 328.953 154.137 335.395C148.21 341.838 140.912 345.059 132.243 345.059C123.493 345.059 116.176 341.939 110.291 335.694C104.403 329.451 101.461 320.721 101.461 309.505ZM115.659 309.148C115.659 316.545 117.309 322.173 120.611 326.03C123.911 329.889 127.829 331.817 132.362 331.817C136.736 331.817 140.614 329.908 143.995 326.089C147.375 322.272 149.066 316.824 149.066 309.744C149.066 302.427 147.414 296.9 144.114 293.16C140.813 289.423 136.855 287.553 132.243 287.553C127.788 287.553 123.911 289.403 120.611 293.101C117.309 296.799 115.659 302.149 115.659 309.148Z"
				fill="black"
			/>
			<path
				d="M223.481 321.599H199.511C200.883 324.999 202.89 327.781 205.53 329.951C208.169 332.12 211.55 333.205 215.672 333.205C218.853 333.205 221.618 332.879 223.969 332.229C226.318 331.578 228.614 330.783 230.857 329.843L236.062 340.255C233.314 341.702 230.024 342.858 226.193 343.725C222.359 344.593 218.926 345.027 215.889 345.027C207.282 345.027 200.596 342.804 195.823 338.357C191.051 333.91 187.797 328.324 186.062 321.599H175.323L174.022 311.187H184.435C184.362 310.465 184.307 309.76 184.272 309.072C184.234 308.386 184.217 307.681 184.217 306.957C184.217 306.235 184.253 305.475 184.326 304.679C184.397 303.884 184.47 303.125 184.543 302.401H175.323L174.022 291.989H186.17C188.122 284.976 191.629 279.263 196.691 274.852C201.751 270.442 208.367 268.236 216.54 268.236C218.709 268.236 221.599 268.599 225.216 269.321C228.831 270.044 232.229 271.273 235.412 273.008L231.073 283.095C227.89 282.156 225.487 281.414 223.861 280.872C222.234 280.33 219.829 280.058 216.648 280.058C212.597 280.058 209.145 281.107 206.29 283.203C203.432 285.302 201.209 288.23 199.619 291.989H222.396L223.481 302.401H197.342C197.269 303.125 197.214 303.849 197.179 304.571C197.142 305.294 197.125 306.054 197.125 306.849C197.125 307.572 197.142 308.296 197.179 309.018C197.214 309.741 197.269 310.465 197.342 311.187H222.396L223.481 321.599Z"
				fill="black"
			/>
			<path
				d="M330.696 229.788L379.239 206.416C381.23 205.458 383.62 206.294 384.578 208.285C385.537 210.276 384.7 212.666 382.709 213.624L331.693 238.187"
				fill="#1B1B19"
			/>
			<path
				d="M330.728 214.974L370.637 195.76C372.628 194.801 375.017 195.638 375.976 197.628C376.935 199.619 376.098 202.009 374.107 202.968L330.939 223.751"
				fill="#1B1B19"
			/>
			<path
				d="M326.656 202.136L336.794 197.255C338.785 196.297 341.174 197.133 342.133 199.124C343.091 201.115 342.255 203.505 340.264 204.464L330.127 209.344"
				fill="#1B1B19"
			/>
			<path
				d="M405.394 291.492L365.595 310.654C363.604 311.612 361.214 310.776 360.255 308.785C359.297 306.794 360.133 304.404 362.124 303.446L401.923 284.284C403.914 283.325 406.304 284.162 407.263 286.153C408.221 288.144 407.385 290.533 405.394 291.492Z"
				fill="#EAC827"
			/>
			<path
				d="M413.353 272.862L364.529 296.368C362.538 297.327 360.148 296.491 359.19 294.5C358.231 292.509 359.068 290.119 361.059 289.16L409.882 265.654C411.873 264.695 414.263 265.532 415.221 267.523C416.18 269.514 415.343 271.903 413.353 272.862Z"
				fill="#EAC827"
			/>
			<path
				d="M395.268 266.771L345.798 290.589C343.807 291.547 341.417 290.711 340.459 288.72C339.5 286.729 340.336 284.339 342.327 283.381L391.797 259.563C393.788 258.604 396.178 259.441 397.137 261.432C398.095 263.423 397.259 265.812 395.268 266.771Z"
				fill="#EAC827"
			/>
			<path
				d="M381.815 258.45L337.306 279.879C335.315 280.837 332.925 280.001 331.967 278.01C331.008 276.019 331.845 273.629 333.836 272.671L378.344 251.242C380.335 250.283 382.725 251.12 383.683 253.111C384.642 255.101 383.806 257.491 381.815 258.45Z"
				fill="#C53127"
			/>
			<path
				d="M403.023 233.44L329.366 268.903C327.375 269.862 324.985 269.026 324.027 267.035C323.068 265.044 323.905 262.654 325.895 261.695L399.553 226.232C401.544 225.274 403.934 226.11 404.892 228.101C405.851 230.092 405.014 232.482 403.023 233.44Z"
				fill="#C53127"
			/>
			<path
				d="M330.606 244.629L390.59 215.75C392.58 214.791 394.97 215.628 395.929 217.618C396.887 219.609 396.051 221.999 394.06 222.958L330.029 253.786"
				fill="#C53127"
			/>
			<path
				d="M338.247 374.942C337.163 375.464 336.339 375.139 335.775 373.967L326.335 354.359C326.1 353.873 326.052 353.407 326.19 352.958C326.329 352.509 326.652 352.163 327.161 351.918L332.569 349.314C334.162 348.548 335.711 348.088 337.217 347.935C338.724 347.781 340.149 347.955 341.491 348.452C342.835 348.95 344.09 349.789 345.258 350.97C346.426 352.151 347.474 353.704 348.4 355.628C349.316 357.531 349.881 359.329 350.098 361.023C350.314 362.716 350.212 364.271 349.789 365.687C349.368 367.102 348.638 368.373 347.599 369.499C346.562 370.626 345.247 371.572 343.654 372.339L338.247 374.942ZM338.996 369.964L342.115 368.462C343.928 367.59 345.046 366.262 345.469 364.477C345.89 362.695 345.441 360.432 344.12 357.689C342.842 355.035 341.404 353.378 339.806 352.716C338.206 352.056 336.5 352.163 334.688 353.036L331.569 354.537L338.996 369.964Z"
				fill="#1B1B19"
			/>
			<path
				d="M356.386 356.815C355.424 357.253 354.619 356.795 353.969 355.446C353.671 354.827 353.526 354.256 353.534 353.735C353.541 353.215 353.822 352.82 354.376 352.554L364.054 347.894C364.606 347.628 365.089 347.655 365.501 347.973C365.913 348.292 366.269 348.762 366.567 349.381C367.227 350.752 367.049 351.682 366.031 352.172L356.386 356.815Z"
				fill="#1B1B19"
			/>
			<path
				d="M381.992 351.879C382.237 352.387 382.298 352.895 382.175 353.404C382.053 353.913 381.604 354.354 380.83 354.726C379.414 355.408 378.409 355.129 377.813 353.891L369.331 336.274L364.287 338.702C363.712 338.979 363.215 338.994 362.797 338.745C362.377 338.498 362.041 338.109 361.785 337.578C361.53 337.047 361.441 336.538 361.519 336.051C361.598 335.563 361.891 335.197 362.4 334.952L377.031 327.908C377.539 327.663 378.006 327.657 378.432 327.888C378.856 328.119 379.196 328.5 379.452 329.032C379.707 329.562 379.809 330.072 379.758 330.559C379.707 331.048 379.382 331.435 378.785 331.722L373.51 334.262L381.992 351.879Z"
				fill="#1B1B19"
			/>
			<path
				d="M383.652 326.722C383.333 326.059 383.297 325.491 383.546 325.017C383.794 324.543 384.239 324.152 384.881 323.842C385.478 323.555 386.039 323.463 386.565 323.564C387.09 323.665 387.512 324.047 387.832 324.71L397.304 344.384C397.549 344.892 397.624 345.414 397.528 345.951C397.432 346.488 396.986 346.948 396.19 347.331C395.438 347.693 394.822 347.799 394.34 347.65C393.859 347.5 393.454 347.082 393.124 346.396L383.652 326.722Z"
				fill="#1B1B19"
			/>
			<path
				d="M413.302 339.501C411.511 340.363 409.853 340.753 408.327 340.671C406.803 340.587 405.412 340.16 404.156 339.389C402.899 338.618 401.775 337.587 400.785 336.292C399.793 334.998 398.931 333.589 398.196 332.063C397.419 330.448 396.859 328.818 396.515 327.17C396.172 325.524 396.122 323.947 396.364 322.442C396.606 320.935 397.192 319.55 398.123 318.285C399.052 317.02 400.402 315.961 402.172 315.109C403.565 314.438 404.932 314.093 406.271 314.076C407.611 314.057 408.854 314.248 410.001 314.65C410.525 314.834 411.043 315.074 411.554 315.373C412.066 315.671 412.428 316.042 412.642 316.485C412.95 317.126 412.963 317.706 412.681 318.223C412.4 318.74 412.014 319.116 411.528 319.35C411.151 319.531 410.775 319.569 410.398 319.465C410.022 319.36 409.604 319.228 409.145 319.067C408.784 318.941 408.41 318.815 408.022 318.689C407.634 318.563 407.227 318.478 406.799 318.439C406.372 318.399 405.915 318.415 405.431 318.485C404.946 318.554 404.44 318.717 403.909 318.973C402.914 319.452 402.185 320.075 401.723 320.842C401.262 321.609 400.997 322.472 400.927 323.432C400.858 324.392 400.969 325.414 401.26 326.501C401.551 327.586 401.958 328.671 402.48 329.754C403.023 330.883 403.633 331.911 404.311 332.837C404.989 333.764 405.719 334.503 406.501 335.051C407.283 335.602 408.107 335.94 408.973 336.068C409.838 336.197 410.724 336.043 411.631 335.607C412.162 335.351 412.597 335.066 412.937 334.753C413.276 334.44 413.553 334.095 413.767 333.719C413.981 333.344 414.161 332.952 414.303 332.541C414.447 332.132 414.571 331.698 414.678 331.238C414.759 330.899 414.872 330.51 415.016 330.074C415.159 329.636 415.452 329.312 415.894 329.099C416.404 328.853 416.948 328.81 417.527 328.967C418.106 329.124 418.536 329.489 418.812 330.064C419.217 330.905 419.217 331.981 418.813 333.293C418.629 333.871 418.4 334.445 418.129 335.011C417.856 335.579 417.508 336.128 417.082 336.659C416.658 337.19 416.136 337.694 415.521 338.168C414.904 338.641 414.165 339.085 413.302 339.501Z"
				fill="#1B1B19"
			/>
			<path
				d="M415.799 311.245C415.48 310.582 415.444 310.014 415.693 309.54C415.941 309.066 416.386 308.674 417.028 308.365C417.625 308.077 418.186 307.985 418.713 308.086C419.237 308.188 419.659 308.569 419.979 309.232L423.86 317.295L427.158 304.959C427.273 304.522 427.424 304.11 427.604 303.723C427.785 303.336 428.086 303.041 428.506 302.839C428.816 302.69 429.1 302.614 429.358 302.612C429.616 302.611 429.887 302.65 430.172 302.731C430.467 302.835 430.73 302.973 430.964 303.147C431.198 303.322 431.389 303.562 431.538 303.872C431.655 304.116 431.716 304.414 431.723 304.764C431.728 305.115 431.678 305.467 431.575 305.816L429.223 314.427L441.1 321.949C441.447 322.163 441.712 322.459 441.893 322.834C442.159 323.387 442.201 323.952 442.016 324.532C441.831 325.112 441.386 325.571 440.678 325.912C440.214 326.136 439.768 326.201 439.341 326.106C438.915 326.012 438.478 325.813 438.033 325.511L427.624 318.915L426.544 322.868L429.451 328.907C429.696 329.415 429.771 329.937 429.675 330.474C429.579 331.011 429.133 331.47 428.337 331.854C427.585 332.216 426.969 332.322 426.487 332.172C426.006 332.022 425.601 331.604 425.272 330.919L415.799 311.245Z"
				fill="#1B1B19"
			/>
			<path
				d="M448.794 321.719C447.71 322.24 446.886 321.915 446.322 320.743L436.882 301.136C436.648 300.649 436.6 300.183 436.738 299.734C436.876 299.286 437.199 298.939 437.708 298.694L448.789 293.359C449.895 292.827 450.757 293.203 451.375 294.485C451.652 295.06 451.759 295.581 451.697 296.046C451.635 296.513 451.317 296.884 450.742 297.161L442.116 301.314L444.831 306.953L452.628 303.2C453.689 302.688 454.514 303.041 455.099 304.257C455.376 304.832 455.488 305.364 455.437 305.851C455.386 306.34 455.061 306.727 454.464 307.014L446.668 310.768L449.463 316.574L458.255 312.341C458.72 312.117 459.153 312.128 459.555 312.369C459.958 312.611 460.304 313.031 460.591 313.628C460.868 314.203 460.977 314.729 460.921 315.205C460.864 315.682 460.538 316.064 459.94 316.352L448.794 321.719Z"
				fill="#1B1B19"
			/>
			<path
				d="M474.352 307.411C474.597 307.919 474.658 308.428 474.535 308.937C474.412 309.445 473.964 309.886 473.19 310.259C471.774 310.941 470.769 310.662 470.173 309.423L461.691 291.806L456.647 294.235C456.072 294.512 455.575 294.526 455.157 294.278C454.737 294.03 454.401 293.642 454.145 293.11C453.89 292.58 453.801 292.071 453.879 291.583C453.958 291.096 454.251 290.73 454.76 290.484L469.391 283.44C469.899 283.196 470.366 283.19 470.792 283.42C471.216 283.652 471.556 284.033 471.812 284.564C472.067 285.094 472.169 285.605 472.118 286.092C472.067 286.58 471.742 286.967 471.145 287.255L465.87 289.794L474.352 307.411Z"
				fill="#1B1B19"
			/>
			<path
				d="M335.913 409.726H0.706238V200.732H335.913V254.421H324.014C315.489 254.421 308.553 261.356 308.553 269.881V340.576C308.553 349.102 315.489 356.037 324.014 356.037H335.913V409.726ZM8.70626 401.726H327.913V364.037H324.014C311.077 364.037 300.553 353.513 300.553 340.576V269.881C300.553 256.945 311.077 246.421 324.014 246.421H327.913V208.732H8.70626V401.726Z"
				fill="black"
			/>
		</svg>
	);
};
