import React from 'react';

export const VisaIcon = () => {
	return (
		<svg
			width="38"
			height="24"
			viewBox="0 0 38 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.496094"
				y="0.496094"
				width="36.5078"
				height="23.0078"
				rx="5.45703"
				fill="white"
				stroke="#D9D9D9"
				strokeWidth="0.992188"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.3831 16.2572H9.11132L7.40776 10.1913C7.32691 9.91228 7.15522 9.6656 6.90268 9.54934C6.27243 9.25716 5.57794 9.02464 4.82031 8.90736V8.67383H8.47996C8.98505 8.67383 9.36386 9.02464 9.42699 9.43206L10.3109 13.8076L12.5816 8.67383H14.7902L11.3831 16.2572ZM16.0518 16.2572H13.9062L15.6729 8.67383H17.8184L16.0518 16.2572ZM20.5921 10.7757C20.6552 10.3673 21.034 10.1337 21.476 10.1337C22.1705 10.0751 22.927 10.1924 23.5583 10.4835L23.9371 8.85081C23.3058 8.61727 22.6113 8.5 21.981 8.5C19.8987 8.5 18.3834 9.55041 18.3834 11.0082C18.3834 12.1173 19.4567 12.6996 20.2144 13.0504C21.034 13.4002 21.3497 13.6338 21.2866 13.9836C21.2866 14.5082 20.6552 14.7418 20.025 14.7418C19.2673 14.7418 18.5097 14.5669 17.8163 14.2747L17.4375 15.9085C18.1951 16.1996 19.0148 16.3169 19.7724 16.3169C22.1073 16.3745 23.5583 15.3251 23.5583 13.75C23.5583 11.7665 20.5921 11.6502 20.5921 10.7757ZM31.0718 16.2572L29.3683 8.67383H27.5385C27.1596 8.67383 26.7808 8.90736 26.6546 9.25716L23.5 16.2572H25.7086L26.1495 15.1491H28.8632L29.1157 16.2572H31.0718ZM27.8552 10.7168L28.4854 13.5748H26.7188L27.8552 10.7168Z"
				fill="#172B85"
			/>
		</svg>
	);
};
