/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTimeValue } from '../models/DateTimeValue';
import type { DriverLicences } from '../models/DriverLicences';
import type { SpecialNeeds } from '../models/SpecialNeeds';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VehiclesService {
	/**
	 * Returns a list of vehicles.
	 * Returns all vehicles assigned to distributor, requires distributor role.
	 * @returns any A JSON array of vehicles
	 * @throws ApiError
	 */
	public static getApiVehicles(): CancelablePromise<
		Array<{
			/**
			 * unique identificator
			 */
			uuid?: string;
			/**
			 * description or external ID of vehicle
			 */
			name?: string;
			licencePlate?: string;
			/**
			 * maximum number of passengers
			 */
			capacity?: number;
			neededDriverLicences?: DriverLicences;
			specialNeeds?: SpecialNeeds;
			/**
			 * is this vehicle able to serve as LBODT
			 */
			lbodt?: boolean;
			/**
			 * is this vehicle able to serve as LBODTFLEX
			 */
			lbodtFlex?: boolean;
			/**
			 * determines, whether there is today any failed checked done (indoor or outdoor)
			 */
			hasFailedCheck?: boolean;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/vehicles',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Return a vehicle.
	 * Returns detail view for a specific vehicle. Is correctly returned only if belongs to given distributor (user)
	 * @param uuid uuid of object
	 * @returns any A JSON Object for specific vehicle.
	 * @throws ApiError
	 */
	public static getApiVehicles1(uuid: string): CancelablePromise<{
		/**
		 * unique identificator
		 */
		uuid?: string;
		/**
		 * description or external ID of vehicle
		 */
		name?: string;
		licencePlate?: string;
		/**
		 * maximum number of passengers
		 */
		capacity?: number;
		neededDriverLicences?: DriverLicences;
		specialNeeds?: SpecialNeeds;
		/**
		 * is this vehicle able to serve as LBODT
		 */
		lbodt?: boolean;
		/**
		 * is this vehicle able to serve as LBODTFLEX
		 */
		lbodtFlex?: boolean;
		/**
		 * determines, whether vehicle can be deleted
		 */
		canBeDeleted?: boolean;
		checks?: Array<{
			/**
			 * unique identificator
			 */
			uuid: string;
			insideCheck: boolean;
			insideCheckNotice?: string;
			outsideCheck: boolean;
			outsideCheckNotice?: string;
			date: DateTimeValue;
		}>;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/vehicles/{uuid}',
			path: {
				uuid: uuid,
			},
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}
}
