/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * payload specific for command 'saveDriverFiles'
 */
export type commandPayload__saveDriverFiles = {
	/**
	 * list of file uuids to be stored on driver
	 */
	fileIds: Array<string>;
	/**
	 * driver licence type
	 */
	fileType: commandPayload__saveDriverFiles.fileType;
};

export namespace commandPayload__saveDriverFiles {
	/**
	 * driver licence type
	 */
	export enum fileType {
		DRIVER_LICENSE = 'DRIVER_LICENSE',
		PASSENGER_TRANSPORT_LICENSE = 'PASSENGER_TRANSPORT_LICENSE',
	}
}
