import {
	LoaderWrapper,
	Root,
	SkeletonBlock,
	StopBlock,
	Title,
	TitleBlock,
} from './SearchResultSkeleton.styles';

export const SearchResultSkeleton = () => {
	return (
		<Root data-testid="search-results-loader-skeleton">
			<LoaderWrapper>
				<TitleBlock>
					<Title>
						<SkeletonBlock radius={5} width={150} height={20} />
						<SkeletonBlock radius={5} width={92} height={12} />
					</Title>
					<SkeletonBlock radius={16} width={32} height={32} />
				</TitleBlock>
				<StopBlock>
					<SkeletonBlock radius={5} width={45} height={16} />
					<SkeletonBlock radius={10} width={20} height={20} />
					<SkeletonBlock radius={5} width={160} height={16} />
				</StopBlock>
				<StopBlock>
					<SkeletonBlock radius={5} width={45} height={16} />
					<SkeletonBlock radius={10} width={20} height={20} />
					<SkeletonBlock radius={5} width={160} height={16} />
				</StopBlock>
			</LoaderWrapper>
		</Root>
	);
};
