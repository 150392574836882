export const prepareSearchParams = (
	params: Record<string, string>,
	searchParams?: URLSearchParams
) => {
	const _searchParams = searchParams || new URLSearchParams();

	Object.entries(params).forEach(([key, value]) => {
		_searchParams.append(key, value);
	});

	return _searchParams;
};

export const appendSearchParams = (
	url: string,
	params: Record<string, string>
): string => {
	try {
		const urlObj = new URL(url);

		prepareSearchParams(params, urlObj.searchParams);

		return urlObj.toString();
	} catch (e) {
		console.log('error appendSearchParams', e, url);
		return url;
	}
};
