import { styled } from '@mui/material/styles';

interface Props {
	marginTop?: string;
	marginBottom?: string;
	bold?: boolean;
	error?: boolean;
	success?: boolean;
	driverApp?: boolean;
	width?: string;
}

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'driverApp' && prop !== 'marginTop',
})<Props>(({ theme, driverApp, marginTop }) => ({
	backgroundColor: theme.colors.mopla_white,
	marginTop: marginTop || 0,
	padding: driverApp ? '24px 16px' : '',
	borderRadius: 8,
}));

export const PasswordDescription = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'bold' &&
		prop !== 'error' &&
		prop !== 'driverApp' &&
		prop !== 'marginBottom',
})<Props>(({ theme, bold, error, driverApp, marginBottom, width }) => ({
	width: width || '100%',
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontSize: driverApp ? '18px' : '12px',
	fontWeight: bold ? 700 : 400,
	lineHeight: '22px',
	display: 'flex',
	color: error
		? theme.colors.mopla_red
		: driverApp
		? theme.colors.mopla_secondary_dark
		: theme.colors.mopla_dark_grey,

	'&:not(:last-of-type)': {
		marginBottom: marginBottom || 0,
	},

	'& > svg': {
		path: {
			fill: theme.palette.secondary.main,
		},
	},
}));

export const Separator = styled('div')(({ theme }) => ({
	height: 0,
	width: '100%',
	border: `1px solid ${theme.colors.mopla_super_light_blue}`,
	marginBottom: 16,
}));

export const DriverCheckMarkWrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'success' && prop !== 'error',
})<Props>(({ theme, error, success }) => ({
	color: error
		? theme.colors.mopla_red
		: success
		? theme.palette.primary.main
		: theme.colors.mopla_dark_grey,
	marginRight: 8,
}));
