import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from '@mui/material';

import { DiscountState } from '@mopla/data-models';
import { Button, FlexColumn, ImpairedCardAnim, Modal } from '@mopla/ui';

import { useBookingContext } from './bookingContext';
import { ModalText, ModalTitle } from './BookingFlow.styles';

export const CompanionSuggest: FC = () => {
	const { t } = useTranslation('booking');
	const {
		setState,
		state,
		setPriceInfo,
		flowStepsMethods: { updateSteps, goToNextStep },
	} = useBookingContext();

	const onConfirm = useCallback(() => {
		setState('isCompanionSuggest', false);
		setPriceInfo(DiscountState.IMPAIRED_COMPANION, 'attendees2.0');
		updateSteps();
		goToNextStep();
	}, [goToNextStep]);

	const onCancel = useCallback(() => {
		setState('isCompanionSuggest', false);
		goToNextStep();
	}, [goToNextStep]);

	return (
		<Portal
			key={'CompanionSuggest_portal'}
			container={document.getElementById('portal')}
		>
			<Modal
				key={'CompanionSuggest_modal'}
				open={state.isCompanionSuggest}
				onClose={() => null}
				animationElement={<ImpairedCardAnim />}
				mainContent={
					<>
						<ModalTitle>{t('modal.companion_suggest.title')}</ModalTitle>
						<ModalText>{t('modal.companion_suggest.text')}</ModalText>
					</>
				}
				footerComp={
					<FlexColumn gap={8}>
						<Button variant="primary" type="button" onClick={onConfirm}>
							{t('modal.companion_suggest.confirm')}
						</Button>
						<Button variant="text" type="button" onClick={onCancel}>
							{t('modal.companion_suggest.cancel')}
						</Button>
					</FlexColumn>
				}
			/>
		</Portal>
	);
};
