import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
	marginTop?: string;
	marginBottom?: string;
	bold?: boolean;
	error?: boolean;
}

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'marginTop',
})<Props>(({ theme, marginTop }) => ({
	display: 'flex',
	flexDirection: 'column',
	marginTop: '24px',
	height: '100%',

	'& > div:first-of-type': {
		marginBottom: '24px',
	},

	'.MuiMobileStepper-dot': {
		'&:not(:last-of-type)': {
			marginRight: '8px',
		},
		backgroundColor: theme.palette.secondary.light,
	},

	'.MuiMobileStepper-dotActive': {
		backgroundColor: theme.palette.secondary.main,
	},

	'.MuiPaper-elevation': {
		alignSelf: 'center',
		marginTop: marginTop,
		marginBottom: '24px',
	},
}));

export const Title = styled(Typography)(({ theme }) => ({
	...theme.fonts.body1,
}));

export const Bold = styled('span')(() => ({
	fontWeight: '600',
}));

export const Description = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginTop' && prop !== 'marginBottom',
})<Props>(({ theme, marginTop, marginBottom }) => ({
	...theme.fonts.body3,
	color: theme.colors.mopla_dark_grey,
	marginTop: marginTop || 0,
	marginBottom: marginBottom || 0,
}));

export const TextWithLinkWrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'marginTop' && prop !== 'marginBottom',
})<Props>(({ theme, marginTop, marginBottom }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginTop: marginTop || 0,
	marginBottom: marginBottom || 0,

	'p:first-of-type': {
		...theme.fonts.body1,
		color: theme.colors.mopla_black,
	},

	'p:last-of-type': {
		...theme.fonts.body1_semibold,
		color: theme.colors.mopla_secondary,
	},
}));

export const SubmitLevel = styled('div')(() => ({
	marginBottom: '50px',
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
	width: '100%',
	backgroundColor: theme.colors.mopla_dark_grey,
	borderRadius: '8px',
	marginBottom: '24px',
}));

export const Label = styled('label')(({ theme }) => ({
	...theme.fonts.body1,
	color: theme.colors.mopla_black,

	a: {
		color: theme.colors.mopla_black,
	},
}));

export const CheckboxesWrapper = styled('div')(() => ({
	display: 'grid',
	gridGap: '16px',
}));

export const SuccessRoot = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
}));
