import { DEFAULT_DTICKET_NAME } from '@mopla/constants';
import { ESubscriptionType, TGermanyTicketType } from '@mopla/data-models';

export const addLeadingZeroNum = (num: number) => {
	return num < 10 ? '0' + num : num;
};

export const getDTicketType = (
	type = ESubscriptionType.GERMANYTICKET
): TGermanyTicketType => {
	switch (type) {
		case ESubscriptionType.MOPLA:
		case ESubscriptionType.SPENDIT:
			return 'Deutschland Jobticket';
		case ESubscriptionType.SCHOOL:
		case ESubscriptionType.UNIVERSITY:
			return 'Schüler-Deutschlandticket';
		default:
			return DEFAULT_DTICKET_NAME;
	}
};

export const getIsJobticketByType = (type: TGermanyTicketType) =>
	type !== DEFAULT_DTICKET_NAME;
