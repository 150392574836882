import { styled } from '@mui/material/styles';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'hidden',
})<{ hidden: boolean }>(({ theme, hidden }) => ({
	backgroundColor: theme.colors.mopla_white,
	width: '100%',
	height: '100%',
	display: 'grid',
	paddingTop: 'var(--mopla-safe-area-top)',
	gridTemplateRows: 'max-content 1fr max-content',
	visibility: hidden ? 'hidden' : 'visible',
	maxWidth: 600,
	margin: 'auto',
	boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
	overflow: 'hidden',
}));

export const HeaderBlock = styled('div')(() => ({
	padding: 24,
	boxSizing: 'border-box',
}));

export const ContentBlock = styled('div')(() => ({
	width: '100%',
	height: '100%',
}));

export const FooterBlock = styled('div')(() => ({
	width: '100%',
	height: '100%',
}));

export const SliderCard = styled('div', {
	shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ open }) => ({
	position: 'fixed',
	height: open ? 'calc(100vh - var(--mopla-safe-area-top))' : 0,
	width: '100%',
	bottom: 0,
	left: 0,
	borderRadius: '8px 8px 0 0',
	overflow: 'hidden',
	zIndex: 30,
	visibility: 'visible',
	maxWidth: 600,
	margin: 'auto',
	boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
	transform: open ? 'translateY(0%)' : 'translateY(100%)',
	transition: 'transform .2s',
}));

export const SliderBackDrop = styled('div', {
	shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
	position: 'fixed',
	height: open ? 40 : 0,
	width: '100%',
	bottom: open ? 'calc(100vh - 40px)' : 0,
	left: 0,
	transition: 'all .1s',
	visibility: 'visible',
	background: theme.colors.mopla_white,
}));

export const ScannerBlock = styled('div')(() => ({
	zIndex: 15,
}));

export const ScrollWrapper = styled('div')(() => ({
	display: 'grid',
	height: '100%',
	overflow: 'auto',
}));

export const ActionWrapper = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_secondary,
	borderRadius: '8px 8px 0 0',
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	gridGap: 16,
	boxSizing: 'border-box',
	padding: '16px 24px 50px 24px',
	width: '100%',
	height: '100%',
	button: {
		...theme.fonts.button,
		color: theme.colors.mopla_secondary,
		backgroundColor: theme.colors.mopla_white,

		':hover': {
			opacity: 0.7,
			backgroundColor: theme.colors.mopla_white,
			color: theme.palette.secondary.main,
		},
		':focus': {
			opacity: 1,
			backgroundColor: theme.colors.mopla_white,
		},
	},
}));
