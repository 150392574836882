import { FC } from 'react';
import { Checkbox as MuiCheckbox, FormControlLabelProps } from '@mui/material';

import { FormControlLabelStyled } from './styled';

export type TFormControlLabelForwardProps = Pick<
	FormControlLabelProps,
	'checked' | 'onChange' | 'onBlur' | 'label' | 'name' | 'disabled' | 'inputRef'
>;

export interface ICheckboxProps extends TFormControlLabelForwardProps {
	checkboxTestId?: string;
	className?: string;
}

export const Checkbox: FC<ICheckboxProps> = (props) => {
	const {
		checked,
		onChange,
		label,
		name,
		disabled,
		checkboxTestId,
		className,
		inputRef,
	} = props;

	return (
		<FormControlLabelStyled
			control={<MuiCheckbox color="secondary" data-testid={checkboxTestId} />}
			label={label}
			name={name}
			onChange={onChange}
			checked={checked}
			className={className}
			disabled={disabled}
			inputRef={inputRef}
		/>
	);
};
