import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { deleteVoucher, useBusinessLayer } from '@mopla/business-logic';
import { ISubscriptionOfferVoucher } from '@mopla/data-models';
import { FlexColumn, FlexContainer, Text, TrashIcon } from '@mopla/ui';

import { ContentBlock } from './steps/ContentBlock';
import { CleanVoucherCodeButton, Wrapper } from './DTicketSubscription.styles';

interface IVoucherBlockProps {
	subscriptionOfferVoucher: ISubscriptionOfferVoucher;
}

export const VoucherBlock: FC<IVoucherBlockProps> = ({
	subscriptionOfferVoucher,
}) => {
	const { t } = useTranslation(['dticket']);
	const { voucherShortDescription, voucherCode } = subscriptionOfferVoucher;
	const bl = useBusinessLayer();

	const deleteVoucherHandler = useCallback(() => {
		bl.dispatch(deleteVoucher({ voucherCode }));
	}, [bl, voucherCode]);

	return (
		<ContentBlock headerText={t('header.voucher_title')}>
			<Wrapper>
				<FlexColumn className="space-between" style={{ gap: 4 }}>
					<FlexContainer className="space-between">
						<Text variant="body1">{t('text.discount_code')}</Text>
						<Text variant="body1">{voucherCode}</Text>
					</FlexContainer>
					<Text variant="caption" color="mopla_grey">
						{voucherShortDescription}
					</Text>
					<div style={{ alignSelf: 'flex-start' }}>
						<CleanVoucherCodeButton
							type="button"
							onClick={deleteVoucherHandler}
						>
							{t('button.delete_voucher')}
							<TrashIcon width={24} height={24} />
						</CleanVoucherCodeButton>
					</div>
				</FlexColumn>
			</Wrapper>
		</ContentBlock>
	);
};
