/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * create new user push token if not exists - used in app DRIVER and PASSENGER
 */
export type commandPayload__createUserPushToken = {
	/**
	 * Token string generated from app DRIVER or PASSENGER
	 */
	pushToken?: string;
	/**
	 * App enum (PASSENGER or DRIVER allowed)
	 */
	appContext?: commandPayload__createUserPushToken.appContext;
};

export namespace commandPayload__createUserPushToken {
	/**
	 * App enum (PASSENGER or DRIVER allowed)
	 */
	export enum appContext {
		DRIVER = 'DRIVER',
		PASSENGER = 'PASSENGER',
	}
}
