/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Province {
	BW = 'BW',
	BY = 'BY',
	BE = 'BE',
	BB = 'BB',
	HB = 'HB',
	HH = 'HH',
	HE = 'HE',
	MV = 'MV',
	NI = 'NI',
	NW = 'NW',
	RP = 'RP',
	SL = 'SL',
	SN = 'SN',
	ST = 'ST',
	SH = 'SH',
	TH = 'TH',
}
