import { FC } from 'react';

export const ValidityWarning: FC = () => {
	return (
		<svg
			width="133"
			height="109"
			viewBox="0 0 133 109"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M63.9791 30.875C63.9791 28.1136 66.2177 25.875 68.9791 25.875H72.9791C75.7405 25.875 77.9791 28.1136 77.9791 30.875V72.875C77.9791 75.6364 75.7405 77.875 72.9791 77.875H68.9791C66.2177 77.875 63.9791 75.6364 63.9791 72.875V30.875ZM68.9791 28.875H72.9791C74.0837 28.875 74.9791 29.7704 74.9791 30.875V72.875C74.9791 73.9796 74.0837 74.875 72.9791 74.875H68.9791C67.8745 74.875 66.9791 73.9796 66.9791 72.875V30.875C66.9791 29.7704 67.8745 28.875 68.9791 28.875Z"
				fill="#1C308B"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M70.9791 97.875C74.8451 97.875 77.9791 94.741 77.9791 90.875C77.9791 87.009 74.8451 83.875 70.9791 83.875C67.1131 83.875 63.9791 87.009 63.9791 90.875C63.9791 94.741 67.1131 97.875 70.9791 97.875ZM70.9791 94.875C73.1882 94.875 74.9791 93.0841 74.9791 90.875C74.9791 88.6659 73.1882 86.875 70.9791 86.875C68.77 86.875 66.9791 88.6659 66.9791 90.875C66.9791 93.0841 68.77 94.875 70.9791 94.875Z"
				fill="#1C308B"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M30.6609 70.5741L31.4955 69.1285L31.489 69.1247L32.2316 67.8342C30.5546 68.7525 28.5695 67.7564 28.7617 65.8637L63.6848 5.375C67.1489 -0.624999 75.8092 -0.625 79.2733 5.375L130.802 94.625C134.266 100.625 129.936 108.125 123.008 108.125H19.9505C13.0223 108.125 8.69221 100.625 12.1563 94.625L22.1523 77.3114C21.7472 77.3155 21.3516 77.107 21.1346 76.7312C20.8143 76.1763 21.0044 75.4667 21.5593 75.1464L23.7842 73.8618L21.5593 72.5773C21.0044 72.2569 20.8143 71.5474 21.1346 70.9925C21.455 70.4376 22.1646 70.2474 22.7195 70.5678L24.9443 71.8523V69.0352C24.9443 68.3944 25.4637 67.875 26.1045 67.875C26.5895 67.875 27.0051 68.1727 27.1784 68.5953L28.7643 65.8391C28.7634 65.8473 28.7625 65.8555 28.7617 65.8637L27.1809 68.6016C27.2349 68.7355 27.2647 68.8819 27.2647 69.0352L27.2647 71.8524L29.4896 70.5678C29.8654 70.3508 30.3122 70.368 30.6609 70.5741ZM75.8092 7.375L127.338 96.625C129.262 99.9583 126.857 104.125 123.008 104.125H19.9505C16.1015 104.125 13.6959 99.9583 15.6204 96.625L23.4385 83.0837C23.4382 83.0864 23.4379 83.0891 23.4376 83.0918L24.1169 81.9152L24.1263 81.9206L25.4391 79.639C25.6275 79.7711 25.8569 79.8486 26.1045 79.8486C26.7452 79.8486 27.2647 79.3292 27.2647 78.6885V76.4663L27.5217 76.0197L29.4896 77.1559C30.0445 77.4762 30.7541 77.2861 31.0744 76.7312C31.3948 76.1763 31.2047 75.4668 30.6498 75.1464L28.6811 74.0098L28.9374 73.5659L30.6498 72.5773C31.2047 72.2569 31.3948 71.5473 31.0744 70.9924C30.9716 70.8144 30.8288 70.6739 30.6638 70.5758L31.4984 69.1301L31.4955 69.1285L67.1489 7.375C69.0734 4.04167 73.8847 4.04167 75.8092 7.375ZM28.9317 73.5692L28.4249 73.8618L28.6783 74.0081L28.9317 73.5692ZM27.5181 76.0176L27.2647 75.8713V76.4566L27.5181 76.0176ZM22.1558 77.3114L19.9732 81.0917C21.6499 80.1845 23.6396 81.1928 23.4385 83.0837L25.4307 79.633C25.1363 79.4226 24.9443 79.078 24.9443 78.6885V75.8713L22.7195 77.1558C22.5415 77.2586 22.3476 77.3088 22.1558 77.3114Z"
				fill="#1C308B"
			/>
			<path
				d="M19.1045 63.8486C18.4637 63.8486 17.9443 63.3292 17.9443 62.6885V59.8713L15.7195 61.1558C15.1646 61.4762 14.455 61.2861 14.1346 60.7312C13.8143 60.1763 14.0044 59.4667 14.5593 59.1464L16.7842 57.8618L14.5593 56.5773C14.0044 56.2569 13.8143 55.5474 14.1346 54.9925C14.455 54.4376 15.1646 54.2474 15.7195 54.5678L17.9443 55.8523V53.0352C17.9443 52.3944 18.4637 51.875 19.1045 51.875C19.7452 51.875 20.2647 52.3944 20.2647 53.0352L20.2647 55.8524L22.4896 54.5678C23.0445 54.2474 23.7541 54.4375 24.0744 54.9924C24.3948 55.5473 24.2047 56.2569 23.6498 56.5773L21.4249 57.8618L23.6498 59.1464C24.2047 59.4668 24.3948 60.1763 24.0744 60.7312C23.7541 61.2861 23.0445 61.4762 22.4896 61.1559L20.2647 59.8713V62.6885C20.2647 63.3292 19.7452 63.8486 19.1045 63.8486Z"
				fill="#1C308B"
			/>
			<path
				d="M4.94432 82.6885C4.94431 83.3292 5.46374 83.8486 6.10449 83.8486C6.74524 83.8486 7.26466 83.3292 7.26466 82.6885V79.8713L9.48962 81.1559C10.0445 81.4762 10.7541 81.2861 11.0745 80.7312C11.3948 80.1763 11.2047 79.4668 10.6498 79.1464L8.42486 77.8618L10.6498 76.5773C11.2047 76.2569 11.3948 75.5473 11.0744 74.9924C10.7541 74.4375 10.0445 74.2474 9.48962 74.5678L7.26466 75.8524V73.0352C7.26466 72.3944 6.74524 71.875 6.10449 71.875C5.46374 71.875 4.94432 72.3944 4.94432 73.0352L4.94432 75.8523L2.71947 74.5678C2.16457 74.2474 1.45502 74.4376 1.13464 74.9925C0.814271 75.5474 1.00439 76.2569 1.5593 76.5773L3.78416 77.8618L1.55929 79.1464C1.00438 79.4667 0.814258 80.1763 1.13463 80.7312C1.455 81.2861 2.16456 81.4762 2.71946 81.1558L4.94432 79.8713L4.94432 82.6885Z"
				fill="#1C308B"
			/>
		</svg>
	);
};
