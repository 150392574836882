/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TripType {
	WALK = 'WALK',
	LBT = 'LBT',
	LBODT = 'LBODT',
	LBODTDS = 'LBODTDS',
	POOLODT = 'POOLODT',
	PRODT = 'PRODT',
	OTHERS = 'OTHERS',
	EMPTY = 'EMPTY',
	SPECIAL_SCHOOL_TRAFFIC = 'SPECIAL_SCHOOL_TRAFFIC',
	BLOCKER = 'BLOCKER',
	UNCATEGORIZED = 'UNCATEGORIZED',
}
