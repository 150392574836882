import { useEffect } from 'react';
import H from '@here/maps-api-for-javascript';
import { Theme, useTheme } from '@mui/material';
import * as Sentry from '@sentry/capacitor';

import { ILatLng } from '@mopla/data-models';

const platform = new H.service.Platform({
	apikey: 'RJBeP8M9QCqTkJdm0SE-G4kKCoWrWx8am_idQ49z8AY',
});
const router = platform.getRoutingService({}, 8);

const departureIcon = `<svg width='24' height='24' viewBox='0 0 24 24' fill='none' id='departureIcon' xmlns='http://www.w3.org/2000/svg'>
<path d='M11.4815 13.8115L10.4916 13.953L11.4815 13.8115ZM12.2461 19.1637L11.2562 19.3051L12.2461 19.1637ZM5.06328 10.2751L4.7043 9.34178L5.06328 10.2751ZM17.7607 5.3915L17.4017 4.45815L17.7607 5.3915ZM19.053 6.68383L18.1197 6.32485L19.053 6.68383ZM17.4017 4.45815L4.7043 9.34178L5.42226 11.2085L18.1197 6.32484L17.4017 4.45815ZM15.1027 19.7402L19.9864 7.0428L18.1197 6.32485L13.2361 19.0223L15.1027 19.7402ZM13.2361 19.0223L12.4715 13.6701L10.4916 13.953L11.2562 19.3051L13.2361 19.0223ZM10.7744 11.9731L5.42225 11.2085L5.13941 13.1884L10.4916 13.953L10.7744 11.9731ZM12.4715 13.6701C12.3458 12.7902 11.6544 12.0988 10.7744 11.9731L10.4916 13.953L12.4715 13.6701ZM13.2361 19.0223V19.0223L11.2562 19.3051C11.5477 21.346 14.3627 21.6644 15.1027 19.7402L13.2361 19.0223ZM4.7043 9.34178C2.78009 10.0819 3.09849 12.8968 5.13941 13.1884L5.42225 11.2085L5.42226 11.2085L4.7043 9.34178ZM18.1197 6.32484L18.1197 6.32485L19.9864 7.0428C20.6085 5.42533 19.0192 3.83604 17.4017 4.45815L18.1197 6.32484Z' fill='currentColor'/>
</svg>`;

const arrivalIcon = `<svg width='24' height='24' viewBox='0 0 24 24' fill='none' id='arrivalIcon' xmlns='http://www.w3.org/2000/svg'>
<path d='M11.4815 13.8115L10.4916 13.953L11.4815 13.8115ZM12.2461 19.1637L11.2562 19.3051L12.2461 19.1637ZM5.06328 10.2751L4.7043 9.34178L5.06328 10.2751ZM17.7607 5.3915L17.4017 4.45815L17.7607 5.3915ZM19.053 6.68383L18.1197 6.32485L19.053 6.68383ZM17.4017 4.45815L4.7043 9.34178L5.42226 11.2085L18.1197 6.32484L17.4017 4.45815ZM15.1027 19.7402L19.9864 7.0428L18.1197 6.32485L13.2361 19.0223L15.1027 19.7402ZM13.2361 19.0223L12.4715 13.6701L10.4916 13.953L11.2562 19.3051L13.2361 19.0223ZM10.7744 11.9731L5.42225 11.2085L5.13941 13.1884L10.4916 13.953L10.7744 11.9731ZM12.4715 13.6701C12.3458 12.7902 11.6544 12.0988 10.7744 11.9731L10.4916 13.953L12.4715 13.6701ZM13.2361 19.0223V19.0223L11.2562 19.3051C11.5477 21.346 14.3627 21.6644 15.1027 19.7402L13.2361 19.0223ZM4.7043 9.34178C2.78009 10.0819 3.09849 12.8968 5.13941 13.1884L5.42225 11.2085L5.42226 11.2085L4.7043 9.34178ZM18.1197 6.32484L18.1197 6.32485L19.9864 7.0428C20.6085 5.42533 19.0192 3.83604 17.4017 4.45815L18.1197 6.32484Z' fill='currentColor'/>
</svg>`;

function addPolylineToMap(map: H.Map, legs: ILatLng[], theme: Theme) {
	const lineString = new H.geo.LineString();
	const group = new H.map.Group();
	const markers: H.map.DomMarker[] = [];

	legs.forEach((l, i, arr) => {
		lineString.pushPoint({ lat: l.lat, lng: l.lng });
		const marker = new H.map.DomMarker(
			{
				lat: l.lat,
				lng: l.lng,
			},
			{
				data: 'test',
				icon: new H.map.DomIcon(
					i !== arr.length - 1 ? departureIcon : arrivalIcon
				),
			}
		);

		markers.push(marker);
	});

	group.addObjects(markers);

	map.getViewModel().setLookAtData({
		bounds: group.getBoundingBox(),
	});
	map.addObjects(markers);

	map.addObject(
		new H.map.Polyline(lineString, {
			data: 'test',
			style: { lineWidth: 4, strokeColor: theme.colors.mopla_secondary },
		})
	);
}

const addRouteToMap = (map: H.Map, result: any, theme: Theme) => {
	const group = new H.map.Group();
	const markers: H.map.DomMarker[] = [];

	if (result.routes.length) {
		result.routes[0].sections.forEach((section: any, i: number, arr: any[]) => {
			const isLastItem = i === arr.length - 1;
			const linestring = H.geo.LineString.fromFlexiblePolyline(
				section.polyline
			);
			const routeLine = new H.map.Polyline(linestring, {
				data: 'test',
				style: { strokeColor: theme.colors.mopla_secondary, lineWidth: 4 },
			});

			if (!isLastItem) {
				const marker = new H.map.DomMarker(section.departure.place.location, {
					data: 'test',
					icon: new H.map.DomIcon(departureIcon),
				});

				markers.push(marker);
			} else {
				const startMarker = new H.map.DomMarker(
					section.departure.place.location,
					{
						data: 'test',
						icon: new H.map.DomIcon(departureIcon),
					}
				);
				const endMarker = new H.map.DomMarker(section.arrival.place.location, {
					data: 'test',
					icon: new H.map.DomIcon(arrivalIcon),
				});

				markers.push(startMarker);
				markers.push(endMarker);
			}

			map.addObjects([routeLine]);
			// eslint-disable-next-line
			// @ts-ignore
			map.getViewModel().setLookAtData({ bounds: routeLine.getBoundingBox() });
		});

		group.addObjects(markers);
		map.getViewModel().setLookAtData({
			bounds: group.getBoundingBox(),
		});
		map.addObjects(markers);
	}
};

function latLngToString(point: ILatLng): string {
	return `${point.lat},${point.lng}`;
}

export const useMap = (args: {
	waypoints: ILatLng[];
	ref: any;
	deps: any[];
	showRoute: boolean;
}) => {
	const { waypoints, ref, deps, showRoute } = args;
	const theme = useTheme();
	let map: H.Map;
	const fromPoint = waypoints[0];
	const toPoint = waypoints[waypoints.length - 1];

	const intermediateWaypoints = waypoints
		.slice(1, waypoints.length - 1)
		.map(latLngToString);

	const routingParameters = {
		routingMode: 'fast',
		transportMode: 'car',
		origin: latLngToString(fromPoint),
		via: new H.service.Url.MultiValueQueryParameter(intermediateWaypoints),
		destination: latLngToString(toPoint),
		return: 'polyline',
	};

	useEffect(() => {
		let resizeHandler: () => void;
		if (ref) {
			const defaultLayers = platform.createDefaultLayers();
			const latCenter = (+fromPoint.lat + +toPoint.lat) / 2;
			const longCenter = (+fromPoint.lng + +toPoint.lng) / 2;

			map = new H.Map(ref, defaultLayers.vector.normal.map, {
				center: { lat: latCenter, lng: longCenter },
				zoom: 5,
				pixelRatio: window.devicePixelRatio || 1,
				padding: { top: 30, left: 30, bottom: 30, right: 80 },
			});

			resizeHandler = () => map.getViewPort().resize();
			window.addEventListener('resize', resizeHandler);

			if (showRoute) {
				router.calculateRoute(
					routingParameters,
					(r) => addRouteToMap(map!, r, theme),
					function (error) {
						Sentry.captureException(error);
						console.error(error.message);
					}
				);
			} else {
				addPolylineToMap(map, waypoints, theme);
			}

			const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

			const ui = H.ui.UI.createDefault(map, {});
		}

		return () => {
			window.removeEventListener('resize', resizeHandler);
			if (map) map.dispose();
		};
	}, [ref, ...deps]);
};
