import { EntityDescriptor } from '../business-logic';
import { bookingSchema } from '../schemas/booking';

export const bookingEntity: EntityDescriptor = {
	booking: {
		migrationStrategies: {
			1: function (oldDoc: any) {
				// migration to rxdb 12
				return oldDoc;
			},
			2: function (oldDoc: any) {
				// remove isPaid and add paymentState instead
				return null;
			},
		},
		schema: bookingSchema,
	},
};
