/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateValue } from '../models/DateValue';
import type { Privileges } from '../models/Privileges';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommonsService {
	/**
	 * Details about the access token keeper.
	 * Returns details about the user identified by the access token in request header.
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiWhoami(): CancelablePromise<{
		/**
		 * mopla user ID, not firebase ID
		 */
		userId?: string;
		email?: string;
		/**
		 * a combination of firstName and lastName, legacy code
		 */
		name?: string;
		firstName?: string;
		lastName?: string;
		/**
		 * phone number
		 */
		phone?: string;
		dateOfBirth?: DateValue;
		address?: {
			street?: string;
			streetNumber?: string;
			zipCode?: string;
			city?: string;
			/**
			 * see enumerator CountryEnum
			 */
			country?: string;
		};
		blacklisted?: boolean;
		/**
		 * indicates, whether the user data set is completed. if true, user shall be asked for more details
		 */
		pleaseComplete?: boolean;
		/**
		 * indicates, whether the user was already connected with a stripe account
		 */
		isStripeCustomerConnected?: boolean;
		/**
		 * indicates, whether the user has already configured in stripe the default payment method
		 */
		hasDefaultStripePaymentMethod?: boolean;
		/**
		 * indicates, whether the driver needs to process with driver licence check (is shown only in case of driver user)
		 */
		isDriverLicenceCheckNeeded?: boolean;
		privileges?: Privileges;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/whoami',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * requests a reset password link via email (unsecured endpoint)
	 * an email will be sent to user that contains a reset password link incl redirect to proper app
	 * @param requestBody what app will open the link (redirect)
	 * @returns any OK
	 * @throws ApiError
	 */
	public static postApiResetPassword(requestBody?: {
		email: string;
		/**
		 * determines, what email link will be generated to redirect to app
		 */
		app: 'PASSENGER' | 'DRIVER' | 'DISTRIBUTOR';
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/reset-password',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				404: `(email/user) NOT Found`,
			},
		});
	}
}
