import { EntityDescriptor } from '../business-logic';
import { userSchema } from '../schemas/user';

/** TODO perhaps not used */
export const userEntity: EntityDescriptor = {
	user: {
		migrationStrategies: {
			1: function (oldDoc: any) {
				// migration to rxdb 12
				return oldDoc;
			},
		},
		schema: userSchema,
	},
};
