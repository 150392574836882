/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { BookingNotificationType } from '../../types';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DevService {
	/**
	 * ping endpoint responses pong
	 * to test connection of apps to an unsecured endpoint
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDevPing(): CancelablePromise<{
		/**
		 * fix value 'pong'
		 */
		result?: string;
		/**
		 * fix value false
		 */
		secured?: boolean;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/dev/ping',
		});
	}

	/**
	 * ping endpoint responses pong
	 * to test connection of apps to an unsecured endpoint
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDevPingSecured(): CancelablePromise<{
		/**
		 * fix value 'pong-secured'
		 */
		result?: string;
		/**
		 * fix value true
		 */
		secured?: boolean;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/dev/ping-secured',
		});
	}

	/**
	 * trigger manually sending of silent notification to given user
	 * trigger manually sending of silent notification to given user, API-Endpoint is used for dev purposes, before last live release, access should be secured only for admins
	 * @param requestBody provide id from user account of google authentication provider to create passenger account
	 * @returns any Sending notification was successful
	 * @throws ApiError
	 */
	public static postApiNotificationsSilent(requestBody: {
		/**
		 * user id
		 */
		userUuid: string;
		/**
		 * type of notification
		 */
		notificationType: BookingNotificationType;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/notifications/silent',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				404: `User not found`,
			},
		});
	}
}
