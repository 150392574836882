import React from 'react';

export const GeolocationIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="12"
				cy="12"
				r="6.875"
				stroke="currentColor"
				strokeWidth="1.25"
			/>
			<circle
				cx="12"
				cy="12"
				r="2.91875"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="1.6625"
			/>
			<rect x="11.375" y="3.25" width="1.25" height="2.5" fill="currentColor" />
			<rect
				x="11.375"
				y="18.25"
				width="1.25"
				height="2.5"
				fill="currentColor"
			/>
			<rect
				x="20.75"
				y="11.375"
				width="1.25"
				height="2.5"
				transform="rotate(90 20.75 11.375)"
				fill="currentColor"
			/>
			<rect
				x="5.75"
				y="11.375"
				width="1.25"
				height="2.5"
				transform="rotate(90 5.75 11.375)"
				fill="currentColor"
			/>
		</svg>
	);
};
