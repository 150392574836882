/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecurringManualBookingLeg } from '../models/RecurringManualBookingLeg';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RecurringManualBookingLegsService {
	/**
	 * Returns a list of recurringManualBookingLegs.
	 * Returns all recurringManualBookingLegs assigned to distributor, requires distributor role.
	 * @returns RecurringManualBookingLeg A JSON array of recurringManualBookingLegs
	 * @throws ApiError
	 */
	public static getApiRecurringManualBookingLegs(): CancelablePromise<
		Array<RecurringManualBookingLeg>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/recurringManualBookingLegs',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Returns the recurringManualBookingLeg
	 * Returns the specified recurringManualBookingLeg assigned to distributor, requires distributor role.
	 * @param uuid uuid of object
	 * @returns RecurringManualBookingLeg A JSON array of recurringManualBookingLegs
	 * @throws ApiError
	 */
	public static getApiRecurringManualBookingLegs1(
		uuid: string
	): CancelablePromise<RecurringManualBookingLeg> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/recurringManualBookingLegs/{uuid}',
			path: {
				uuid: uuid,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}
}
