import { Dispatch, useCallback, useReducer } from 'react';
import { PermissionState } from '@capacitor/core';

import { PassengerBooking } from '@mopla/business-logic';
import { IItinerarySearchParams, Itinerary } from '@mopla/data-models';

import { BookingFlowProps } from './types';

enum EActions {
	SetState = 'SetState',
	ToggleTariffsCheckbox = 'ToggleTariffsCheckbox',
}

interface IAction<T extends EActions, P> {
	type: T;
	payload: P;
}

const setStateAction = <T extends keyof IBookingFlowState>(
	key: T,
	value: IBookingFlowState[T]
): IAction<EActions.SetState, { key: T; value: IBookingFlowState[T] }> => {
	return {
		type: EActions.SetState,
		payload: {
			key,
			value,
		},
	};
};

export const toggleTariffsCheckbox = (): IAction<
	EActions.ToggleTariffsCheckbox,
	undefined
> => {
	return {
		type: EActions.ToggleTariffsCheckbox,
		payload: undefined,
	};
};

const reducer = (
	prevState: IBookingFlowState,
	action: TAction
): IBookingFlowState => {
	switch (action.type) {
		case EActions.SetState:
			return {
				...prevState,
				[action.payload.key]: action.payload.value,
			};

		case EActions.ToggleTariffsCheckbox:
			return {
				...prevState,
				isAcceptTariffs: !prevState.isAcceptTariffs,
			};

		default:
			return prevState;
	}
};

export const useBookingFlowStore = (props: IUseBookingFlowStoreProps) => {
	const [state, dispatch] = useReducer(reducer, {
		activeRide: props.activeRide,
		searchData: props.searchData,
		persistenceId: Date.now().toString(),
		showQR: false,
		loadingAddPaymentMethodScreen: false,
		forceInitiatePayment: false,
		booking: null,
		permissionsType: 'prompt',
		showErrorModal: false,
		isAcceptTariffs: false,
		isImpairedCardReminder: false,
		isCompanionSuggest: false,
		isImpairedPersonRequirement: false,
	});

	const setState = useCallback<TSetState>((...args) => {
		dispatch(setStateAction(...args));
	}, []);

	return { state, dispatch, setState };
};

export interface IBookingFlowState {
	activeRide: BookingFlowProps['activeRide'];
	persistenceId: string; //constant
	searchData: IItinerarySearchParams;
	showQR: boolean;
	loadingAddPaymentMethodScreen: boolean;
	forceInitiatePayment: boolean;
	booking: PassengerBooking | null;
	permissionsType: PermissionState;
	showErrorModal: boolean;
	isAcceptTariffs: boolean;
	isImpairedCardReminder: boolean;
	isCompanionSuggest: boolean;
	isImpairedPersonRequirement: boolean;
}

interface IUseBookingFlowStoreProps {
	searchData: IItinerarySearchParams;
	activeRide: Itinerary | null;
}

type TAction =
	| ReturnType<typeof setStateAction>
	| ReturnType<typeof toggleTariffsCheckbox>;

export type TSetState = (...args: Parameters<typeof setStateAction>) => void;

export type TDispatch = Dispatch<TAction>;
