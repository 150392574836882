import React from 'react';
import { useTranslation } from 'react-i18next';

import { PassengerBooking } from '@mopla/business-logic';
import { TransportType } from '@mopla/data-models';
import { isLBTItinerary } from '@mopla/utils';

import { FlexColumn, Text } from '../../../';

import { ButtonCancel } from './RouteDetails.styles';

interface CancelButtonProps {
	onClick: () => void;
	passengerInfo: PassengerBooking | undefined;
	transportTypes: (TransportType | undefined)[];
}

export const CancelButton: React.FC<CancelButtonProps> = ({
	onClick,
	passengerInfo,
	transportTypes,
}) => {
	const { t } = useTranslation('tickets');
	const isCancelledByDistributor =
		passengerInfo?.bookingStatus === 'CANCELLED_BY_DISTRIBUTOR';
	const isCancelledByDriver =
		passengerInfo?.bookingStatus === 'CANCELLED_NO_SHOW';
	const isCancelledTrip = isCancelledByDistributor || isCancelledByDriver;
	const isLBTRide = isLBTItinerary(passengerInfo?.itinerary);

	const showRemoveText =
		isCancelledTrip ||
		(!transportTypes.includes(TransportType.LBODT) &&
			!transportTypes.includes(TransportType.LBODTFLEX));

	return (
		<FlexColumn className="space-between" style={{ gap: 8 }}>
			{!isLBTRide && showRemoveText && (
				<Text variant="body3">{t('text.hide_booking')}</Text>
			)}
			<ButtonCancel
				variant="outlined"
				disabled={passengerInfo?.bookingStatus !== 'NEW'}
				onClick={onClick}
				data-testid="savedTicket-cancelBooking-button"
			>
				{showRemoveText ? t('button.remove_ride') : t('button.cancel_booking')}
			</ButtonCancel>
		</FlexColumn>
	);
};
