import React from 'react';

export const DriverBusIcon = () => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.587 15.5217H20.1523C19.7161 14.9409 19.0215 14.5652 18.2391 14.5652C17.4568 14.5652 16.7622 14.9409 16.3259 15.5217H8.67408C8.23781 14.9409 7.54321 14.5652 6.76087 14.5652C5.97853 14.5652 5.28393 14.9409 4.84766 15.5217H3.41304V12.1739H21.587L21.587 15.5217ZM4.4174 17.4348H3.41304C2.3565 17.4348 1.5 16.5783 1.5 15.5217V6.91304C1.5 5.8565 2.3565 5 3.41305 5H18.7545C19.3356 5 19.8853 5.26417 20.2483 5.71798L23.0808 9.2586C23.3522 9.59781 23.5 10.0193 23.5 10.4537V15.5217C23.5 16.5783 22.6435 17.4348 21.587 17.4348H20.5826C20.361 18.5263 19.396 19.3478 18.2391 19.3478C17.0822 19.3478 16.1172 18.5263 15.8957 17.4348H9.10434C8.88278 18.5263 7.91777 19.3478 6.76087 19.3478C5.60397 19.3478 4.63896 18.5263 4.4174 17.4348ZM21.587 11.2174L21.587 10.4537L18.7545 6.91304L3.41305 6.91304L3.41304 6.91304V11.2174L7.71739 11.2174V6.91306H8.67391V11.2174H15.3696V6.91306H16.3261V11.2174L21.587 11.2174Z"
				fill="currentColor"
			/>
		</svg>
	);
};
