import React, { CSSProperties } from 'react';

import { Container } from './FlexContainer.styles';

interface Props {
	children: React.ReactNode;
	className?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'stretch';
	style?: CSSProperties | undefined;
	gap?: CSSProperties['gap'];
}

export const FlexContainer: React.FC<Props> = ({
	children,
	className = 'stretch',
	style,
	gap,
}) => {
	return (
		<Container className={className} style={style} gap={gap}>
			{children}
		</Container>
	);
};
