import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';

import Button from '../../atoms/Button/Button';
import { ArrowRightIcon } from '../../icons/ArrowRight';

import {
	FirstMessage,
	Form,
	Image,
	Root,
	SecondMessage,
	Title,
} from './PermissionsModal.styles';

export interface PermissionsModalProps {
	title: string;
	firstMessage: string;
	secondMessage?: string;
	skipTitle: string;
	acceptTitle: string;
	deniedFallbackMessage1?: string;
	deniedFallbackMessage2?: string;
	deniedFallbackSkip?: string;
	animationElement?: JSX.Element;
	askPermissionsCallback: () => Promise<any>;
	checkPermissionsCallback: () => Promise<any>;
	onPermissionsGranted: (response: any) => void;
	onCancel: () => void;
	dataTestId?: string;
	acceptWithIcon?: boolean;
	cancelWithIcon?: boolean;
}

export const PermissionsModal: React.FC<PermissionsModalProps> = ({
	title,
	firstMessage,
	secondMessage,
	skipTitle,
	acceptTitle,
	deniedFallbackMessage1,
	deniedFallbackMessage2,
	deniedFallbackSkip,
	animationElement = null,
	askPermissionsCallback,
	checkPermissionsCallback,
	onPermissionsGranted,
	onCancel,
	dataTestId,
	acceptWithIcon = true,
	cancelWithIcon = true,
}) => {
	const [showModal, setShowModal] = useState<'' | 'request' | 'fallback'>('');
	const [rootNode] = React.useState(document.getElementById('portal'));
	const { t } = useTranslation();

	const processGeolocation = useCallback(async () => {
		const permissions = await checkPermissionsCallback();
		const permissionsValues = Object.values(permissions);
		if (
			!permissionsValues.includes('denied') &&
			!permissionsValues.includes('granted')
		) {
			setShowModal('request');
		}

		if (permissionsValues.includes('granted')) {
			try {
				askPermissionsCallback()
					.then((r) => {
						onPermissionsGranted(r);
					})
					.catch((err) => console.log(err));
			} catch (e) {
				console.log(e);
			}
		}

		if (
			permissionsValues.includes('denied') &&
			deniedFallbackMessage1 &&
			deniedFallbackMessage2 &&
			deniedFallbackSkip &&
			Capacitor.getPlatform() !== 'web'
		) {
			//currently the only defined fallback flow is for the apps not for the web
			setShowModal('fallback');
		}
	}, [
		askPermissionsCallback,
		checkPermissionsCallback,
		deniedFallbackMessage1,
		deniedFallbackMessage2,
		deniedFallbackSkip,
		onPermissionsGranted,
	]);

	const askPermissions = () => {
		askPermissionsCallback()
			.then((r) => {
				onPermissionsGranted(r);
			})
			.catch((err) => console.log(err));
		onCancel();
	};

	const goToSettings = useCallback(() => {
		NativeSettings.open({
			optionAndroid: AndroidSettings.ApplicationDetails,
			optionIOS: IOSSettings.App,
		});
		onCancel();
	}, [onCancel]);

	useEffect(() => {
		processGeolocation();
	}, [processGeolocation]);

	if (rootNode === null) {
		return null;
	}

	return ReactDOM.createPortal(
		showModal !== '' ? (
			<Root data-testid={dataTestId || 'permissions-modal'}>
				<Form>
					<Image>{animationElement}</Image>
					{showModal === 'request' && <Title>{title}</Title>}
					<FirstMessage>
						{showModal === 'request' ? firstMessage : deniedFallbackMessage1}
					</FirstMessage>
					<SecondMessage>
						{showModal === 'request' ? secondMessage : deniedFallbackMessage2}
					</SecondMessage>
					<Button
						id="cancelPermissionsModal"
						data-testid="permissions-modal-cancel-button"
						variant="text"
						color="secondary"
						onClick={onCancel}
						icon={cancelWithIcon ? <ArrowRightIcon /> : undefined}
					>
						{showModal === 'request' ? skipTitle : deniedFallbackSkip}
					</Button>
					<Button
						data-testid="permissions-modal-submit-button"
						variant="primary"
						onClick={showModal === 'request' ? askPermissions : goToSettings}
						icon={acceptWithIcon ? <ArrowRightIcon /> : undefined}
					>
						{showModal === 'request'
							? acceptTitle
							: t('permissions:fallback.title')}
					</Button>
				</Form>
			</Root>
		) : null,
		rootNode
	);
};

export default PermissionsModal;
