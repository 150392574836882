/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ZoneZipcode } from '../models/ZoneZipcode';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ZonesService {
	/**
	 * Returns a list of zones.
	 * Returns all zones assigned to distributor, requires distributor role.
	 * @returns any A JSON array of zones
	 * @throws ApiError
	 */
	public static getApiZones(): CancelablePromise<
		Array<{
			/**
			 * unique identification
			 */
			uuid?: string;
			/**
			 * description of zone
			 */
			label?: string;
			/**
			 * is this zones is service type of LBODTFLEX
			 */
			lbodtFlex?: boolean;
			/**
			 * comma-separated list of zone zipcodes
			 */
			zoneZipcodes?: string;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/zones',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Returns a zone.
	 * Returns detail view for a specific zone. Is correctly returned only if belongs to given distributor (user)
	 * @param uuid uuid of object
	 * @returns any A JSON Object for specific zone.
	 * @throws ApiError
	 */
	public static getApiZones1(uuid: string): CancelablePromise<{
		/**
		 * unique identification
		 */
		uuid?: string;
		/**
		 * description of zone
		 */
		label?: string;
		/**
		 * is this zones is service type of LBODTFLEX
		 */
		lbodtFlex?: boolean;
		/**
		 * Notice text for LBODT to be shown on passengers app for time tickets
		 */
		timeTicketNotice?: string;
		/**
		 * Notice text for LBODT to be shown on passengers app for reduced passengers without ticket
		 */
		reducedWithoutTicketNotice?: string;
		/**
		 * Notice text for LBODT to be shown on passengers app for reduced passengers with ticket
		 */
		reducedWithTicketNotice?: string;
		/**
		 * Array of zone zipcodes
		 */
		zoneZipcodes?: Array<ZoneZipcode>;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/zones/{uuid}',
			path: {
				uuid: uuid,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}
}
