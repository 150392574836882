import React from 'react';

export const ProfileIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="12" cy="9" r="4" stroke="currentColor" strokeWidth="2" />
			<path
				d="M18.5 20.7273C18.5 18.1165 15.5899 16 12 16C8.41015 16 5.5 18.1165 5.5 20.7273"
				stroke="currentColor"
				strokeWidth="2"
			/>
		</svg>
	);
};

export default ProfileIcon;
