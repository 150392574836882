import React from 'react';
import { useTranslation } from 'react-i18next';

import { Passenger } from '@mopla/data-models';

import { FlexContainer } from '../../atoms/FlexContainer/FlexContainer';
import { Text } from '../../atoms/Text/Text';

import { PassengerRow } from './PassengerRow';
import {
	LineWrapper,
	PassengersTable,
	Root,
} from './PassengerTicketInfo.styles';

export interface IPassengerTicketInfoProps {
	passengerList: Passenger[];
	paymentAmount: string;
	toddlerSeats?: number;
}

export const PassengerTicketInfo: React.FC<IPassengerTicketInfoProps> = ({
	passengerList,
	paymentAmount,
	toddlerSeats,
}) => {
	const { t } = useTranslation(['checkIn', 'booking']);

	const mainPassenger = passengerList.find(
		(passenger) => passenger.isMainPassenger
	);
	const attendees = passengerList.filter(
		(passenger) => !passenger.isMainPassenger
	);

	return (
		<Root>
			<PassengersTable>
				{mainPassenger && <PassengerRow passenger={mainPassenger} />}
				{attendees?.map((attendee, index) => (
					<PassengerRow
						key={`attendee_${index}`}
						passenger={attendee}
						index={index}
					/>
				))}
				{toddlerSeats &&
					[...Array(toddlerSeats)].map((_, index) => (
						<PassengerRow
							key={`toddler_${index}`}
							toddler
							index={(attendees?.length || 0) + index}
						/>
					))}
			</PassengersTable>
			{paymentAmount && (
				<>
					<LineWrapper />
					<FlexContainer className="space-between">
						<Text variant="body2_bold" color="mopla_secondary_dark">
							{t('text.in_total')}
						</Text>
						<Text variant="body2_bold" color="mopla_secondary_dark">
							{paymentAmount}
						</Text>
					</FlexContainer>
				</>
			)}
		</Root>
	);
};
