import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CounterInput, ICounterInputProps, Text } from '@mopla/ui';

type TAdditionalAttendeeCounterProps = Pick<
	ICounterInputProps,
	| 'onDecrease'
	| 'onIncrease'
	| 'increaseDisabled'
	| 'decreaseDisabled'
	| 'value'
>;

export const AdditionalAttendeeCounter: FC<TAdditionalAttendeeCounterProps> = ({
	onDecrease,
	onIncrease,
	increaseDisabled,
	decreaseDisabled,
	value,
}) => {
	const { t } = useTranslation('booking');

	return (
		<CounterInput
			onDecrease={onDecrease}
			onIncrease={onIncrease}
			value={value}
			increaseTestId="booking-targetPerson-addAttendee-button"
			counterTestId="booking-targetPerson-AttendeesCount-text"
			title={<Text variant="body1">{t('label.person')}</Text>}
			increaseDisabled={increaseDisabled}
			decreaseDisabled={decreaseDisabled}
		/>
	);
};
