import { FC, PropsWithChildren, ReactNode } from 'react';
import { Swiper as SwiperClass } from 'swiper/types';

import 'swiper/css';

import { Slide, SwiperWrapper } from './Carousel.styles';

interface IProps {
	slideList: ReactNode[];
	onActiveIndexChange?: (activeIndex: number) => void;
}

export const Carousel: FC<PropsWithChildren<IProps>> = (props) => {
	const { slideList, onActiveIndexChange } = props;

	const handleSlideChange = (swiper: SwiperClass) => {
		onActiveIndexChange?.(swiper.activeIndex + 1);
	};

	return (
		<SwiperWrapper
			spaceBetween={8}
			slidesPerView="auto"
			onSlideChange={handleSlideChange}
			centeredSlides
			simulateTouch
			rewind
		>
			{slideList.map((item, index) => (
				<Slide key={index}>{item}</Slide>
			))}
		</SwiperWrapper>
	);
};
