import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gap: '8px',
	alignItems: 'start',

	'& .label': {
		alignSelf: 'center',
	},
}));
