import { scheduledLegSchema } from './scheduledLeg';

export const scheduleDiffSchema = {
	title: 'last unseen changes of the schedule',
	version: 1,
	type: 'object',
	primaryKey: 'scheduledLegId',
	properties: {
		...scheduledLegSchema.properties,
		updateState: { type: 'string' },
	},
	required: ['scheduledLegId'],
};
