import React from 'react';

export const Plus = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			fill="none"
			viewBox="0 0 12 12"
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M7 1V0H5v5H0v2h5v5h2V7h5V5H7V1z"
				clipRule="evenodd"
			/>
		</svg>
	);
};
