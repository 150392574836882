import { CSSProperties } from 'react';
import { styled } from '@mui/material';

export const Container = styled('div', {
	shouldForwardProp: (prop) => prop !== 'gap',
})<{ gap?: CSSProperties['gap'] }>(({ gap }) => ({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
	gap,
	'&.flex-start': {
		justifyContent: 'flex-start',
	},
	'&.flex-end': {
		justifyContent: 'flex-end',
	},
	'&.center': {
		justifyContent: 'center',
	},
	'&.space-between': {
		justifyContent: 'space-between',
	},
}));

export const ColumnContainer = styled('div', {
	shouldForwardProp: (prop) => !['gap', 'sx'].includes(prop as string),
})<{ gap?: CSSProperties['gap'] }>(({ gap }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap,
	'&.flex-start': {
		alignItems: 'flex-start',
	},
	'&.flex-end': {
		alignItems: 'flex-end',
	},
	'&.center': {
		alignItems: 'center',
	},
	'&.space-between': {
		alignItems: 'space-between',
	},
}));
