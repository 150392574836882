import { ReactElement } from 'react';

import AuthHeader from '../../molecules/AuthHeader/AuthHeader';

import { MainContent, Root, ScrollWrapper } from './AuthLayout.styles';

interface AuthLayoutProps {
	namespace: string;
	title: string;
	showBack?: boolean;
	onBack?: () => void;
	showClose?: boolean;
	driverApp?: boolean;
	onClose?: () => void;
	mainContent: ReactElement;
}

export function AuthLayout({
	namespace,
	title,
	showBack,
	onBack,
	showClose,
	onClose,
	mainContent,
	driverApp,
}: AuthLayoutProps) {
	return (
		<Root driverApp={driverApp}>
			<AuthHeader
				namespace={namespace}
				title={title}
				showBack={showBack}
				onBack={onBack}
				showClose={showClose}
				onClose={onClose}
			/>
			<ScrollWrapper>
				<MainContent>{mainContent}</MainContent>
			</ScrollWrapper>
		</Root>
	);
}

export default AuthLayout;
