import React from 'react';
export const MicrosoftIcon = () => {
	return (
		<svg
			width="21"
			height="22"
			viewBox="0 0 21 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M10 1.54492H1V10.5449H10V1.54492Z" fill="#F25022" />
			<path d="M10 11.5449H1V20.5449H10V11.5449Z" fill="#00A4EF" />
			<path d="M20 1.54492H11V10.5449H20V1.54492Z" fill="#7FBA00" />
			<path d="M20 11.5449H11V20.5449H20V11.5449Z" fill="#FFB900" />
		</svg>
	);
};
