import memoizeOne from 'memoize-one';

import { DiscountState, Itinerary } from '@mopla/data-models';
import { checkHasLegWithoutPaymentInfo } from '@mopla/utils';

import {
	IFlowStep,
	IPassengerPaymentInfo,
	TFormContext,
	TPassengersFormPath,
} from './types';

export const MAX_ATTENDEES = 6;
export const MAX_TODDLERS = 4;
export const MAX_SEATS_TOTAL = 6;

export enum ECountUpdateType {
	increase = 'increase',
	decrease = 'decrease',
}

/** Just helps with typing */
export const isDiscountType = (types: DiscountState[], type: unknown) =>
	types.includes(type as DiscountState);

/** When you're on a passenger (main or attendee) step, helps to get the passenger's form path and price values */
export const getCurrentPassengerInfo = (
	currentStep: IFlowStep,
	formCtx: TFormContext
) => {
	const formType: TPassengersFormPath = currentStep.isAttendee
		? `attendees2.${currentStep.attendeeIndex}`
		: 'main';

	return {
		values: formCtx.getValues(formType),
		formType,
	};
};

export const findPassengerByDiscountType = (
	discount: DiscountState,
	formCtx: TFormContext
): TPassengersFormPath | null => {
	const attendees = formCtx.getValues('attendees2');
	const main = formCtx.getValues('main');

	const passengers = [main, ...attendees];

	for (let i = 0; i < passengers.length; i++) {
		if (isDiscountType([discount], passengers[i].discountState)) {
			return i === 0 ? 'main' : `attendees2.${i - 1}`;
		}
	}

	return null;
};

export const calculateBookingPrice = memoizeOne(
	(itinerary: Itinerary, passengers: IPassengerPaymentInfo[]) => {
		const digitalPayment = passengers
			.map(({ amountToPay }) => amountToPay)
			.reduce<number>((p, c) => (p || 0) + (c || 0), 0);

		const hasLegWithoutPaymentInfo = checkHasLegWithoutPaymentInfo(
			itinerary.legs
		);

		return {
			digitalPayment,
			hasLegWithoutPaymentInfo,
		};
	}
);

export const hasOnlyDisabledPassengers = memoizeOne(
	(passengers: IPassengerPaymentInfo[]) => {
		return passengers.every((passenger) =>
			isDiscountType(
				[DiscountState.IMPAIRED, DiscountState.IMPAIRED_COMPANION],
				passenger.discountState
			)
		);
	}
);

export const validateSeatsAmount = (formCtx: TFormContext) => {
	const [attendees, toddlers, bulkyLuggage] = formCtx.getValues([
		'attendees2',
		'toddlerSeats',
		'bulkyLuggage',
	]);

	const seatsCount = attendees.length + toddlers + Number(bulkyLuggage);

	return seatsCount + 1 <= MAX_SEATS_TOTAL;
};
