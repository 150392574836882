const euro = Intl.NumberFormat('de-DE', {
	style: 'currency',
	currency: 'EUR',
});

export const formatPrice = (price: number, showCurrency = true) => {
	if (!showCurrency) {
		return euro
			.formatToParts(price)
			.filter(({ type }) => type !== 'currency')
			.map(({ value }) => value)
			.join('')
			.trim();
	}

	return euro.format(price);
};
