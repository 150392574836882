import { IAlternateInvoiceAddress } from '@mopla/data-models';

import { IInvoiceRecipientFormValues } from './types';

export const getSavedAlternateInvoiceAddress = (
	alternateAddress: IAlternateInvoiceAddress | null
): IInvoiceRecipientFormValues | null => {
	if (!alternateAddress) return null;

	return {
		surname: alternateAddress.lastName || '',
		firstName: alternateAddress.firstName || '',
		street: alternateAddress.street || '',
		streetNumber: alternateAddress.streetNumber || '',
		company: alternateAddress.company || '',
		postCode: alternateAddress.zipCode || '',
		location: alternateAddress.city || '',
	};
};
