import * as React from 'react';
import { createTheme, ThemeOptions } from '@mui/material/styles';

import { colors } from './colors';
import { fonts } from './fonts';

export type CommonStylesType = {
	[K in keyof typeof fonts]: React.CSSProperties;
};
export type VariablesType = typeof colors;
export type TMoplaColors = keyof VariablesType;
export type TMoplaFonts = keyof typeof fonts;

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		primary: true;
		secondary: true;
		outlined: true;
		someMore: true;
	}
}

declare module '@mui/material/styles/createTheme' {
	export interface Theme {
		colors: VariablesType;
		fonts: CommonStylesType;
	}

	interface ThemeOptions {
		colors: VariablesType;
		fonts: CommonStylesType;
	}
}

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xs: false; // removes the initial breakpoints
		sm: false;
		md: false;
		lg: false;
		xl: false;
		mobile: true;
		tabletP: true; //Portrait
		tabletL: true; //Landscape
		desktop: true;
	}
}

const themeConfig: ThemeOptions = {
	breakpoints: {
		values: {
			mobile: 0,
			tabletP: 768,
			tabletL: 1024,
			desktop: 1440,
		},
	},
	palette: {
		primary: {
			main: '#6FDB89',
			dark: '#33AB50',
		},
		secondary: {
			main: '#1C308B',
			dark: '#0E1C5B',
			light: '#A4B1ED',
		},
		error: {
			main: '#DD6060',
		},
		info: {
			main: '#FFFFFF',
			dark: '#EDEDED',
			light: '#F2F2F2',
		},
	},
	components: {
		MuiButton: {
			variants: [
				{
					props: { variant: 'primary' },
					style: {
						backgroundColor: '#1C308B',
						color: '#fff',

						':hover, :active': {
							backgroundColor: '#0E1C5B',
						},

						':disabled': {
							backgroundColor: '#F2F2F2',
							color: '#6A6A6A',
						},
					},
				},
				{
					props: { variant: 'text' },
					style: {
						color: '#1C308B',
					},
				},
				{
					props: { variant: 'secondary' },
					style: {
						backgroundColor: '#6FDB89',
						color: '#1C308B',

						':hover, :active': {
							backgroundColor: '#33AB50',
							color: '#fff',
						},

						':disabled': {
							backgroundColor: '#F2F2F2',
							color: '#6A6A6A',
						},
					},
				},
				{
					props: { variant: 'outlined' },
					style: ({ theme }) => ({
						boxSizing: 'border-box',
						backgroundColor: 'transparent',
						color: theme.colors.mopla_secondary,
						border: `2px solid ${theme.colors.mopla_secondary}`,

						':hover, :active': {
							backgroundColor: theme.colors.mopla_secondary,
							color: theme.colors.mopla_white,
							border: `2px solid ${theme.colors.mopla_secondary}`,
						},

						':disabled': {
							color: theme.colors.mopla_grey,
							border: `2px solid ${theme.colors.mopla_grey}`,
						},
					}),
				},
			],
		},
		MuiRadio: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&, &.Mui-checked': {
						color: theme.colors.mopla_secondary,
					},
				}),
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&, &.Mui-checked': {
						color: theme.colors.mopla_secondary,
					},
					'&.Mui-disabled': {
						color: theme.colors.mopla_grey,
					},
				}),
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 1,
			},
			styleOverrides: {
				root: {
					borderRadius: 10,
				},
				/**
				 * This is not specified in our DS, although can possibly be in the future.
				 * For now, lets use elevation1 by default
				 * */
				elevation1: {
					boxShadow: '4px 4px 6px 0px rgba(0, 0, 0, 0.1)',
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: ({ theme }) => ({
					borderColor: theme.colors.mopla_grey,
				}),
				light: ({ theme }) => ({
					borderColor: theme.colors.mopla_light_grey,
				}),
			},
		},
		MuiSwipeableDrawer: {
			defaultProps: {
				disableSwipeToOpen: true,
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					'& .MuiFormControlLabel-label.Mui-disabled': {
						color: 'unset',
					},
				},
			},
		},
	},
	typography: {
		fontFamily: '"Palanquin", sans-serif',
		h1: {
			fontFamily: '"Poppins", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '38px',
			lineHeight: '57px',
		},
		h2: {
			fontFamily: '"Palanquin", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '32px',
			lineHeight: '58px',
		},
		h3: {
			fontFamily: '"Palanquin", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '24px',
			lineHeight: '24px',
		},
		h5: {
			fontFamily: '"Palanquin", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '32px',
			lineHeight: '40px',
		},
	},
	colors: { ...colors },
	fonts: { ...fonts },
};

export const theme = createTheme(themeConfig);
export type TTheme = typeof theme;
