/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingId } from '../models/BookingId';
import type { BookingStatus } from '../models/BookingStatus';
import type { CheckedInScheduledLegs } from '../models/CheckedInScheduledLegs';
import type { CurrencyCode } from '../models/CurrencyCode';
import type { DriverDetailView } from '../models/DriverDetailView';
import type { DriverListViewItem } from '../models/DriverListViewItem';
import type { PassengerDetails } from '../models/PassengerDetails';
import type { PaymentAmount } from '../models/PaymentAmount';
import type { PaymentForm } from '../models/PaymentForm';
import type { ScheduledBreak } from '../models/ScheduledBreak';
import type { ScheduledLeg } from '../models/ScheduledLeg';
import type { ScheduledVehicleCheck } from '../models/ScheduledVehicleCheck';
import type { SpecialNeeds } from '../models/SpecialNeeds';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DriversService {
	/**
	 * Returns a list of drivers.
	 * Returns all drivers assigned to ditributor, requires distributor role.
	 * @returns DriverListViewItem A JSON array of drivers
	 * @throws ApiError
	 */
	public static getApiDrivers(): CancelablePromise<Array<DriverListViewItem>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/drivers',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Return a driver.
	 * Returns detail view for a specific driver. Is correctly returned only if belongs to given distributor (user)
	 * @param uuid uuid of object
	 * @returns DriverDetailView A JSON Object for specific driver.
	 * @throws ApiError
	 */
	public static getApiDrivers1(
		uuid: string
	): CancelablePromise<DriverDetailView> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/drivers/{uuid}',
			path: {
				uuid: uuid,
			},
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Return the schedule of a driver.
	 * Returns schedule view for a specific driver.
	 * @returns any A JSON Object
	 * @throws ApiError
	 */
	public static getApiDriversSchedule(): CancelablePromise<
		Array<{
			/**
			 * type reference of obj to cast dynamically
			 */
			ref?: 'LEG' | 'BREAK' | 'VEHICLE_CHECK';
			obj?: ScheduledLeg | ScheduledBreak | ScheduledVehicleCheck;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/drivers/schedule',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Returns the requested list of bookings.
	 * Returns bookings for given id's in request body (not get params du to length limitation).
	 * @param requestBody provide ids for bookings of interest
	 * @returns any a JSON array of requested bookings
	 * @throws ApiError
	 */
	public static postApiDriversBookings(
		requestBody?: Array<BookingId>
	): CancelablePromise<
		Array<{
			bookingId?: BookingId;
			bookingState?: BookingStatus;
			/**
			 * list of passengers riding with that booking
			 */
			passengerList?: Array<PassengerDetails>;
			specialNeeds?: SpecialNeeds;
			paymentAmount?: PaymentAmount;
			currencyCode?: CurrencyCode;
			paymentForm?: PaymentForm;
			/**
			 * if booking is already paid by passenger
			 */
			isPaid?: boolean;
			/**
			 * list of scheduled leg ids of booking and if their checked in
			 */
			checkedInScheduledLegs?: Array<CheckedInScheduledLegs>;
		}>
	> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/drivers/bookings',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `At least one ID was not found`,
			},
		});
	}
}
