import React from 'react';

export const LunchIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.4787 10.7721C11.0804 10.5249 12.1293 9.73172 12.1171 8.31446C12.1111 7.62665 12.1147 6.69846 12.118 5.80404C12.1216 4.85547 12.1251 3.94489 12.117 3.39931C12.1171 2.98956 11.7984 2.7167 11.5079 2.7033C11.1448 2.68654 10.8882 2.9896 10.8883 3.38769C10.8883 3.42439 10.8884 3.53418 10.8886 3.69902C10.8895 4.58249 10.8923 7.04715 10.8883 8.31446L10.0691 8.31446L10.069 3.39925C10.069 2.98956 9.80623 2.69913 9.47612 2.7033C9.06349 2.70852 8.84022 2.98956 8.84022 3.39916C8.84022 3.41027 8.84028 3.44647 8.84037 3.50434C8.84133 4.08554 8.84586 6.85181 8.82982 8.31446H8.02107L8.02107 3.39926C8.02103 2.98955 7.75346 2.70326 7.40884 2.70329C7.0661 2.70332 6.79223 2.98955 6.79226 3.39923C6.79227 3.42162 6.79199 3.50493 6.79155 3.63606C6.78895 4.42024 6.78067 6.91453 6.79227 8.31446C6.81015 8.97835 7.13705 10.2078 8.43067 10.7721L8.43067 21.4217C8.43067 21.8741 8.79744 22.2409 9.24987 22.2409L9.65947 22.2409C10.1119 22.2409 10.4787 21.8741 10.4787 21.4217L10.4787 10.7721ZM15.7792 15.3038L15.7792 21.4215C15.7792 21.874 15.4124 22.2407 14.96 22.2407L14.8197 22.2407C14.3653 22.2407 13.9978 21.876 13.9999 21.4216C14.017 17.608 14.0059 11.9789 13.9981 8.02744C13.993 5.47987 13.9894 3.62963 13.9956 3.41283C14.0162 2.69014 14.7961 2.63455 15.0675 3.01276C15.339 3.39096 16.9662 7.19842 17.3544 10.1626C17.7426 13.1268 17.4444 14.3176 15.7792 15.3038Z"
				fill="currentColor"
			/>
		</svg>
	);
};
