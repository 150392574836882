import { initializeApp } from 'firebase/app';
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';

import { environment } from '../environments/environment';
import ViewEntrypoint from '../view/ViewEntrypoint';

import { withDBObservableDataContext } from './withDBObservableDataContext';

export const App = withDBObservableDataContext(() => {
	const app = initializeApp(environment.firebaseConfig);
	// initialization needed for iOS
	// TODO: maybe switch to native auth for better persistence
	initializeAuth(app, {
		persistence: indexedDBLocalPersistence,
	});

	return <ViewEntrypoint />;
});
