import React from 'react';

export const Minus = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="2"
			fill="none"
			viewBox="0 0 12 2"
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12 2H0V0h12v2z"
				clipRule="evenodd"
			></path>
		</svg>
	);
};
