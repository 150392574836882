import React from 'react';

export const InfoIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13.1111 15.8889C13.1111 16.5025 12.6136 17 12 17C11.3863 17 10.8889 16.5025 10.8889 15.8889C10.8889 15.2752 11.3863 14.7778 12 14.7778C12.6136 14.7778 13.1111 15.2752 13.1111 15.8889ZM11.8889 7C11.3366 7 10.8889 7.44771 10.8889 8V12.6667C10.8889 13.219 11.3366 13.6667 11.8889 13.6667H12.1111C12.6634 13.6667 13.1111 13.219 13.1111 12.6667V8C13.1111 7.44772 12.6634 7 12.1111 7H11.8889Z"
				fill="#1C308B"
			/>
		</svg>
	);
};
