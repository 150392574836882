import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SpecialNeeds } from '@mopla/data-models';

import { Text } from '../../atoms/Text/Text';
import { Plus } from '../../icons';

import {
	AdditionalNeedsWrapper,
	AdditionalNeedText,
} from './PassengerCheckCard.styles';

export interface IAdditionalNeedsProps {
	bulkyLuggage?: boolean;
	specialNeeds?: SpecialNeeds;
}

export const AdditionalNeeds: FC<IAdditionalNeedsProps> = ({
	bulkyLuggage,
	specialNeeds,
}) => {
	const { t } = useTranslation('checkIn');

	if (!bulkyLuggage && (!specialNeeds || specialNeeds === SpecialNeeds.NONE)) {
		return null;
	}

	const additionalNeeds = [
		bulkyLuggage && t('text.additional_bulky_luggage'),
		specialNeeds &&
			specialNeeds !== SpecialNeeds.NONE &&
			t(`specialNeeds:text.${specialNeeds}`),
	]
		.filter(Boolean)
		.map((text, index) => (
			<AdditionalNeedText
				key={index}
				variant="body2_bold"
				color="mopla_secondary_dark"
			>
				<Plus /> {text}
			</AdditionalNeedText>
		));

	return (
		<AdditionalNeedsWrapper className="space-between" gap={4}>
			<Text variant="body3" color="mopla_secondary_dark">
				{t('text.additional_needs')}
			</Text>
			{additionalNeeds}
		</AdditionalNeedsWrapper>
	);
};
