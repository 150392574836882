import React, { ComponentProps, CSSProperties } from 'react';

import { ColumnContainer } from './FlexContainer.styles';

interface Props {
	children: React.ReactNode;
	className?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'stretch'
		| string;
	gap?: CSSProperties['gap'];
	style?: CSSProperties | undefined;
	sx?: ComponentProps<typeof ColumnContainer>['sx'];
}

/** TODO make it possible to pass a {component} prop like in mui (see AdditionalNeedText)
 *  TODO MC-3940 fix className prop */
export const FlexColumn: React.FC<Props> = ({
	children,
	className = 'stretch',
	style,
	gap,
	sx,
}) => {
	return (
		<ColumnContainer className={className} style={style} gap={gap} sx={sx}>
			{children}
		</ColumnContainer>
	);
};
