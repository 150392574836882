import React, { lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { AppListener } from '../../app/appListener';
import { NotificationListener } from '../../app/notificationListener';
import { AccountRemoved } from '../pages/accountRemoved/AccountRemoved';
import { DTicket } from '../pages/dticket/DTicket';
import { SpenditPage } from '../pages/spendit/SpenditPage';
//import PushNotificationsScreen from '../organisms/PushNotifications/PushNotifications';
import { WelcomeScreen } from '../pages/welcome/WelcomeScreen';

import LazyRoute from './LazyRoute';
import PrivateRoute from './PrivateRoute';
import WelcomeRoute from './WelcomeRoute';
// plugin for dayjs for correct parsing of dates
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);

const HomeScreen = lazy(
	() => import(/*webpackChunkName: "Home"*/ '../pages/home/Home')
);

const AppRoutes: React.FunctionComponent = () => {
	return (
		<React.Suspense fallback={null}>
			<BrowserRouter>
				<AppListener />
				<NotificationListener />
				<Routes>
					<Route
						path="home/*"
						element={
							<PrivateRoute>
								<LazyRoute>
									<HomeScreen />
								</LazyRoute>
							</PrivateRoute>
						}
					/>
					<Route
						path="welcome"
						element={
							<WelcomeRoute>
								<WelcomeScreen />
							</WelcomeRoute>
						}
					/>
					<Route
						path="spendit"
						element={
							<PrivateRoute>
								<SpenditPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="account-deleted"
						element={
							<WelcomeRoute>
								<AccountRemoved />
							</WelcomeRoute>
						}
					/>
					<Route
						path="deutschlandticket"
						element={
							<WelcomeRoute>
								<DTicket />
							</WelcomeRoute>
						}
					/>

					{/*<Route path="*" element={<Navigate to="/home" />} />*/}

					<Route index element={<Navigate to="/home" replace={true} />} />
				</Routes>
			</BrowserRouter>
		</React.Suspense>
	);
};

export default AppRoutes;
