import React, { ReactNode, useState } from 'react';
import { Box } from '@mui/material';

import { TooltipBlueIcon, TooltipIcon } from '../../icons';

import { Root, TextWrapper } from './TextWithTooltip.styles';

export interface TextWithTooltipProps {
	text: ReactNode;
	tooltip: string;
}

export const TextWithTooltip: React.FC<TextWithTooltipProps> = ({
	text,
	tooltip,
}) => {
	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	return (
		<Root>
			<TextWrapper data-testid="priceCalculation-question">
				{text}
				<Box
					onClick={() => setShowTooltip((prev) => !prev)}
					data-testid="hint-icon"
					sx={{
						color: showTooltip
							? ({ colors }) => colors.mopla_secondary
							: ({ colors }) => colors.mopla_grey,
					}}
				>
					{showTooltip ? <TooltipBlueIcon /> : <TooltipIcon />}
				</Box>
			</TextWrapper>
			{showTooltip && <span>{tooltip}</span>}
		</Root>
	);
};

export default TextWithTooltip;
