import { ComponentProps, FC } from 'react';

export const CheckmarkCircleIcon: FC<ComponentProps<'svg'>> = ({
	className,
	color = 'currentColor',
}) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18 9.39309L16.5062 8L10.9156 13.2131L8.49376 10.9552L7 12.3483L10.2336 15.3639C10.6177 15.7222 11.2135 15.7222 11.5977 15.3639L18 9.39309Z"
				fill={color}
			/>
		</svg>
	);
};
