import * as Sentry from '@sentry/capacitor';
import {
	AxiosRequestConfig,
	AxiosRequestHeaders,
	AxiosResponse,
	Method,
} from 'axios';
import { getAuth } from 'firebase/auth';

import { localUserManager } from '@mopla/business-logic';
import { Api as ApiInterface } from '@mopla/data-models';

import { environment } from '../environments/environment';

import { axiosInstance } from './axiosConfig';

class Api implements ApiInterface {
	public baseURL: string;

	constructor(url: string) {
		this.baseURL = url;
	}

	private async request<Res = any>(
		path: string,
		postBody: any = {},
		method: Method = 'get'
	): Promise<Res> {
		const endpoint = this.baseURL + path;
		let token = await getAuth()?.currentUser?.getIdToken();

		try {
			if (!token) {
				const localUser = localUserManager.get();
				if (localUser) {
					/** TODO should be refactored
					 * stsTokenManager is not documented in the firebase docs. be careful
					 * */
					token = (localUser as any)?.stsTokenManager?.accessToken;
				}
			}
		} catch (e) {
			console.error('Error on parsing localUser: ', e);
		}

		Sentry.addBreadcrumb({
			category: 'api',
			data: {
				hasToken: !!token,
			},
		});
		const headers: AxiosRequestHeaders = {
			'Content-Type': 'application/json',
		};

		if (token) {
			headers['Authorization'] = `Bearer ${token}`;
		}

		const response = await axiosInstance.request<Res>({
			url: endpoint,
			data: postBody,
			method,
			headers,
		});

		return response.data;
	}

	public async makeRequest<Res = any>(
		path: string,
		config: AxiosRequestConfig
	): Promise<AxiosResponse<Res, any>> {
		const endpoint = this.baseURL + path;
		const token = await getAuth()?.currentUser?.getIdToken();
		const headers = config.headers ?? {};

		if (token) {
			headers['Authorization'] = `Bearer ${token}`;
		}

		const response = await axiosInstance.request<Res>({
			...config,
			url: endpoint,
			headers,
		});
		return response;
	}

	public get<Res = any>(path: string): Promise<Res> {
		return this.request<Res>(path, {}, 'get');
	}

	public post<Res = any>(path: string, postBody: any = {}): Promise<Res> {
		return this.request<Res>(path, postBody, 'post');
	}

	public put<Res = any>(path: string, postBody: any = {}): Promise<Res> {
		return this.request<Res>(path, postBody, 'put');
	}

	public patch<Res = any>(path: string, postBody: any = {}): Promise<Res> {
		return this.request<Res>(path, postBody, 'patch');
	}

	public delete<Res = any>(path: string, postBody: any = {}): Promise<Res> {
		return this.request<Res>(path, postBody, 'delete');
	}
}

const passApi = new Api(environment.baseUrl);

export default passApi;
