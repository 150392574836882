import { Context, createContext, useContext } from 'react';
import { Observable } from 'rxjs';

import { ISubscriptionDBValue } from './subscription';
import { ISubscriptionTicketsDBValue } from './subscriptionTickets';
import { TUserDBValue } from './user';

export type TUser$ = Observable<TUserDBValue>;
export type TSubscription$ = Observable<ISubscriptionDBValue>;
export type TSubscriptionTickets$ = Observable<ISubscriptionTicketsDBValue>;
/**
 * This context provides persistent Observables via React Context.
 * Observables will be filled with the required data.
 * Possible Observables sources are:
 *  * data, selected from RxDB
 *  * components state which needs to be global
 * All fields are optional, bcs the same type is reused for different projects - driver, passenger
 * When accessing this data in hooks, the runtime field presence check should be done
 * */
export type IDBObservableDataContext = {
	user$?: TUser$;
	subscription$?: Observable<ISubscriptionDBValue>;
	subscriptionTickets$?: Observable<ISubscriptionTicketsDBValue>;
};

const dbObservableDataContext = createContext<IDBObservableDataContext>(
	{} as IDBObservableDataContext
);

export const DBObservableDataContextProvider = dbObservableDataContext.Provider;

export const useDBObservableDataContext = () =>
	useContext(
		dbObservableDataContext as unknown as Context<
			Required<IDBObservableDataContext>
		>
	);
