import React from 'react';

interface LicenceIconProps {
	width?: number;
	height?: number;
}

export const LicenceIcon: React.FC<LicenceIconProps> = ({
	width = 24,
	height = 24,
}) => {
	return (
		<svg
			width="153"
			height="152"
			viewBox="0 0 153 152"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="9.5"
				y="32"
				width="134"
				height="88"
				rx="6"
				stroke="currentColor"
				strokeWidth="4"
			/>
			<path
				d="M9.5 51H143.5"
				stroke="currentColor"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M76.5 63L127.5 63"
				stroke="currentColor"
				strokeWidth="4"
				strokeLinecap="round"
			/>
			<path
				d="M76.5 73L116.5 73"
				stroke="currentColor"
				strokeWidth="4"
				strokeLinecap="round"
			/>
			<path
				d="M76.5 83L116.5 83"
				stroke="currentColor"
				strokeWidth="4"
				strokeLinecap="round"
			/>
			<rect
				x="19.5"
				y="61"
				width="47"
				height="51"
				rx="6"
				fill="#ECEEF8"
				stroke="currentColor"
				strokeWidth="4"
			/>
			<path
				d="M34.1209 94.3395C31.2057 96.2752 28.8765 99.1902 27.5 102.677L57.5 102.677C56.1235 99.1902 53.7943 96.2752 50.8791 94.3395C50.5572 94.1258 50.141 94.138 49.809 94.3356C47.6801 95.6024 45.1776 96.3324 42.5 96.3324C39.8224 96.3324 37.3199 95.6024 35.191 94.3356C34.859 94.138 34.4428 94.1258 34.1209 94.3395Z"
				fill="currentColor"
			/>
			<ellipse cx="42.5" cy="82.5" rx="10" ry="9.5" fill="currentColor" />
			<path
				d="M144.21 3.16017C144.21 2.51943 143.691 2 143.05 2C142.409 2 141.89 2.51943 141.89 3.16017L141.89 12.8135C141.89 13.4542 142.409 13.9736 143.05 13.9736C143.691 13.9736 144.21 13.4542 144.21 12.8135L144.21 3.16017Z"
				fill="currentColor"
			/>
			<path
				d="M139.665 4.69288C139.11 4.37251 138.4 4.56263 138.08 5.11753C137.76 5.67244 137.95 6.38199 138.505 6.70236L146.435 11.2809C146.99 11.6013 147.7 11.4112 148.02 10.8563C148.34 10.3014 148.15 9.59184 147.595 9.27146L139.665 4.69288Z"
				fill="currentColor"
			/>
			<path
				d="M147.595 6.70216C148.15 6.38178 148.34 5.67223 148.02 5.11733C147.7 4.56243 146.99 4.3723 146.435 4.69268L138.505 9.27125C137.95 9.59163 137.76 10.3012 138.08 10.8561C138.401 11.411 139.11 11.6011 139.665 11.2807L147.595 6.70216Z"
				fill="currentColor"
			/>
			<path
				d="M10.2102 127.16C10.2102 126.519 9.69078 126 9.05003 126C8.40929 126 7.88986 126.519 7.88986 127.16L7.88986 136.813C7.88986 137.454 8.40928 137.974 9.05003 137.974C9.69078 137.974 10.2102 137.454 10.2102 136.813L10.2102 127.16Z"
				fill="currentColor"
			/>
			<path
				d="M5.6649 128.693C5.10999 128.373 4.40044 128.563 4.08007 129.118C3.7597 129.672 3.94982 130.382 4.50472 130.702L12.435 135.281C12.99 135.601 13.6995 135.411 14.0199 134.856C14.3402 134.301 14.1501 133.592 13.5952 133.271L5.6649 128.693Z"
				fill="currentColor"
			/>
			<path
				d="M13.5954 130.702C14.1503 130.382 14.3404 129.672 14.02 129.117C13.6996 128.562 12.9901 128.372 12.4352 128.693L4.50485 133.271C3.94995 133.592 3.75983 134.301 4.0802 134.856C4.40057 135.411 5.11012 135.601 5.66503 135.281L13.5954 130.702Z"
				fill="currentColor"
			/>
			<path
				d="M27.2102 139.16C27.2102 138.519 26.6908 138 26.05 138C25.4093 138 24.8899 138.519 24.8899 139.16L24.8899 148.813C24.8899 149.454 25.4093 149.974 26.05 149.974C26.6908 149.974 27.2102 149.454 27.2102 148.813L27.2102 139.16Z"
				fill="currentColor"
			/>
			<path
				d="M22.6649 140.693C22.11 140.373 21.4004 140.563 21.0801 141.118C20.7597 141.672 20.9498 142.382 21.5047 142.702L29.435 147.281C29.99 147.601 30.6995 147.411 31.0199 146.856C31.3402 146.301 31.1501 145.592 30.5952 145.271L22.6649 140.693Z"
				fill="currentColor"
			/>
			<path
				d="M30.5954 142.702C31.1503 142.382 31.3404 141.672 31.02 141.117C30.6996 140.562 29.9901 140.372 29.4352 140.693L21.5049 145.271C20.9499 145.592 20.7598 146.301 21.0802 146.856C21.4006 147.411 22.1101 147.601 22.665 147.281L30.5954 142.702Z"
				fill="currentColor"
			/>
			<path
				d="M129.21 16.1602C129.21 15.5194 128.691 15 128.05 15C127.409 15 126.89 15.5194 126.89 16.1602L126.89 25.8135C126.89 26.4542 127.409 26.9736 128.05 26.9736C128.691 26.9736 129.21 26.4542 129.21 25.8135L129.21 16.1602Z"
				fill="currentColor"
			/>
			<path
				d="M124.665 17.6929C124.11 17.3725 123.4 17.5626 123.08 18.1175C122.76 18.6724 122.95 19.382 123.505 19.7024L131.435 24.2809C131.99 24.6013 132.7 24.4112 133.02 23.8563C133.34 23.3014 133.15 22.5918 132.595 22.2715L124.665 17.6929Z"
				fill="currentColor"
			/>
			<path
				d="M132.595 19.7022C133.15 19.3818 133.34 18.6722 133.02 18.1173C132.7 17.5624 131.99 17.3723 131.435 17.6927L123.505 22.2713C122.95 22.5916 122.76 23.3012 123.08 23.8561C123.401 24.411 124.11 24.6011 124.665 24.2807L132.595 19.7022Z"
				fill="currentColor"
			/>
		</svg>
	);
};
