import { FC, ReactElement } from 'react';
import { styled } from '@mui/material/styles';

import { IconMessage, Text } from '../../';

export interface IValueProps {
	className?: string;
	value: string;
	variant?: 'strike' | 'success' | 'error';
	icon?: ReactElement;
	caption?: string;
	overwritten?: boolean;
}

const Root = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',

	'& .value': {
		color: theme.colors.mopla_black,
	},

	'& .value.strike': {
		textDecoration: 'line-through',
	},

	'& .value.success': {
		color: theme.colors.mopla_primary_dark,
	},

	'& .value.error': {
		color: theme.colors.mopla_error_red,
		textDecoration: 'line-through',
	},

	'& .caption': {
		lineHeight: '20px',
	},

	'& .caption.overwritten': {
		textDecoration: 'line-through',
	},
}));

export const Value: FC<IValueProps> = ({
	className,
	value,
	variant,
	caption,
	overwritten,
	icon,
}) => {
	return (
		<Root className={className}>
			<IconMessage
				className={`value ${variant}`}
				icon={icon}
				message={value}
				variant="subtitle1"
			/>
			{caption && (
				<Text
					/** TODO this {caption} font differs from the figma layout */
					variant="body2"
					color="mopla_dark_grey"
					className={`caption${overwritten ? ' overwritten' : ''}`}
				>
					{caption}
				</Text>
			)}
		</Root>
	);
};
