export interface PushAction {
	type: PushActionTypes;
}

export enum PushActionTypes {
	InitPassengerPushes = '[Push] Init Passenger Pushes',
	AddPushToken = '[Push] Add Push Token',
}
export interface InitPassengerPushesAction extends PushAction {
	type: PushActionTypes.InitPassengerPushes;
}
export const initPassengerPushes = (): InitPassengerPushesAction => ({
	type: PushActionTypes.InitPassengerPushes,
});

export interface AddPushTokenAction extends PushAction {
	type: PushActionTypes.AddPushToken;
	payload: {
		token: string;
	};
}
export const addPushToken = (token: string): AddPushTokenAction => ({
	type: PushActionTypes.AddPushToken,
	payload: {
		token,
	},
});
