import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
	marginTop?: string;
	marginBottom?: string;
}
export const Description = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginTop' && prop !== 'marginBottom',
})<Props>(({ theme, marginTop, marginBottom }) => ({
	...theme.fonts.body3,
	color: theme.colors.mopla_dark_grey,
	marginTop: marginTop || 0,
	marginBottom: marginBottom || 0,
}));
