export const geosuggestResponse = {
	items: [
		{
			title: 'Elbstraße 4, 06385 Aken, Deutschland',
			id: 'here:af:streetsection:HFisJhdQvHd8AN0IzA4X3B:CgcIBCCk36hYEAEaATQ',
			resultType: 'houseNumber',
			houseNumberType: 'PA',
			address: {
				label: 'Elbstraße 4, 06385 Aken (Elbe), Deutschland',
			},
			position: {
				lat: 51.8561,
				lng: 12.04507,
			},
			access: [
				{
					lat: 51.8561,
					lng: 12.04513,
				},
			],
			distance: 590111,
			mapView: {
				west: 12.04479,
				south: 51.85471,
				east: 12.04528,
				north: 51.85679,
			},
			highlights: {
				title: [
					{
						start: 0,
						end: 3,
					},
					{
						start: 10,
						end: 11,
					},
					{
						start: 19,
						end: 23,
					},
					{
						start: 25,
						end: 36,
					},
				],
				address: {
					label: [
						{
							start: 0,
							end: 3,
						},
						{
							start: 10,
							end: 11,
						},
						{
							start: 19,
							end: 23,
						},
						{
							start: 32,
							end: 43,
						},
					],
				},
			},
		},
		{
			title: 'Aken, Sachsen-Anhalt, Deutschland',
			id: 'here:cm:namedplace:20194834',
			resultType: 'locality',
			localityType: 'city',
			address: {
				label: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
			},
			position: {
				lat: 51.85535,
				lng: 12.04464,
			},
			distance: 590112,
			mapView: {
				west: 11.95487,
				south: 51.79901,
				east: 12.1441,
				north: 51.8794,
			},
			highlights: {
				title: [
					{
						start: 0,
						end: 4,
					},
				],
				address: {
					label: [
						{
							start: 0,
							end: 4,
						},
					],
				},
			},
		},
		{
			title: 'Wolfen Gymnasium',
			id: 'here:pds:place:276u30ty-33582d6baa8d40b8ad2eb9101202d376',
			resultType: 'place',
			address: {
				label:
					'Wolfen Gymnasium, Reudener Straße, 06766 Bitterfeld-Wolfen, Deutschland',
			},
			position: {
				lat: 51.66948,
				lng: 12.24731,
			},
			access: [
				{
					lat: 51.6695,
					lng: 12.24731,
				},
			],
			distance: 570977,
			categories: [
				{
					id: '400-4100-0042',
					name: 'Haltestelle',
					primary: true,
				},
			],
			highlights: {
				title: [
					{
						start: 0,
						end: 6,
					},
				],
				address: {
					label: [
						{
							start: 0,
							end: 6,
						},
					],
				},
			},
		},
	],
};

/** "Address" point with street and houseNumber */
export const lookupResponse = {
	title: 'Elbstraße 4, 06385 Aken (Elbe), Deutschland',
	id: 'here:af:streetsection:HFisJhdQvHd8AN0IzA4X3B:CgcIBCCk36hYEAEaATQ',
	language: 'de',
	resultType: 'houseNumber',
	houseNumberType: 'PA',
	address: {
		label: 'Elbstraße 4, 06385 Aken (Elbe), Deutschland',
		countryCode: 'DEU',
		countryName: 'Deutschland',
		stateCode: 'ST',
		state: 'Sachsen-Anhalt',
		countyCode: 'ABI',
		county: 'Anhalt-Bitterfeld',
		city: 'Aken (Elbe)',
		district: 'Aken (Elbe)',
		street: 'Elbstraße',
		postalCode: '06385',
		houseNumber: '4',
	},
	position: {
		lat: 51.8561,
		lng: 12.04507,
	},
	access: [
		{
			lat: 51.8561,
			lng: 12.04513,
		},
	],
	mapView: {
		west: 12.04479,
		south: 51.85471,
		east: 12.04528,
		north: 51.85679,
	},
};

/** "Address" point without street and houseNumber */
export const lookupResponse2 = {
	title: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
	id: 'here:cm:namedplace:20194834',
	language: 'de',
	resultType: 'locality',
	localityType: 'city',
	address: {
		label: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
		countryCode: 'DEU',
		countryName: 'Deutschland',
		stateCode: 'ST',
		state: 'Sachsen-Anhalt',
		countyCode: 'ABI',
		county: 'Anhalt-Bitterfeld',
		city: 'Aken (Elbe)',
		postalCode: '06385',
	},
	position: {
		lat: 51.85535,
		lng: 12.04464,
	},
	mapView: {
		west: 11.95487,
		south: 51.79901,
		east: 12.1441,
		north: 51.8794,
	},
};

/** "stop" point without houseNumber */
export const lookupResponse3 = {
	title: 'Wolfen Gymnasium',
	id: 'here:pds:place:276u30ty-33582d6baa8d40b8ad2eb9101202d376',
	language: 'de',
	resultType: 'place',
	address: {
		label:
			'Wolfen Gymnasium, Reudener Straße, 06766 Bitterfeld-Wolfen, Deutschland',
		countryCode: 'DEU',
		countryName: 'Deutschland',
		stateCode: 'ST',
		state: 'Sachsen-Anhalt',
		countyCode: 'ABI',
		county: 'Anhalt-Bitterfeld',
		city: 'Bitterfeld-Wolfen',
		district: 'Wolfen',
		street: 'Reudener Straße',
		postalCode: '06766',
	},
	position: {
		lat: 51.66948,
		lng: 12.24731,
	},
	access: [
		{
			lat: 51.6695,
			lng: 12.24731,
		},
	],
	categories: [
		{
			id: '400-4100-0042',
			name: 'Haltestelle',
			primary: true,
		},
	],
};
