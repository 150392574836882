import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	height: 'calc(100% - var(--mopla-safe-area-top))',
	width: '100%',
	padding:
		'calc( 24px + var(--mopla-safe-area-top)) 24px calc(24px + var(--mopla-safe-area-bottom)) 24px',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	background: theme.colors.mopla_super_light_grey,
	boxSizing: 'border-box',
}));

export const HeaderBlock = styled('header')(() => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}));

export const Content = styled('main')(() => ({
	alignItems: 'center',
	width: '100%',
	overflow: 'auto',
}));

export const GreenDivider = styled(Divider)(({ theme }) => ({
	...theme.fonts.body3,
	textTransform: 'uppercase',
	width: '100%',
	color: theme.colors.mopla_dark_grey,
}));

export const SocialWrapper = styled('div')(() => ({
	margin: '16px 0',
	display: 'flex',
	justifyContent: 'center',
	'& > button:last-of-type': {
		marginRight: 0,
	},
}));

export const FlexibleWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
}));
export const FlexibleWrapperContent = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	'@media(min-width: 601px) ': {
		width: '330px',
	},
}));
