import { FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { Text } from '../../../';

import { PaymentFailedWrapper, RedBorder } from './RouteDetails.styles';

interface IProps {
	isPaymentFailed: boolean;
	isCancelledByDistributor: boolean;
	isCancelledByDriver: boolean;
	isCancelledByPassenger: boolean;
	cancellationReason?: string | null;
}

export const FailedStatusBlock: FC<IProps> = (props) => {
	const {
		isPaymentFailed,
		isCancelledByDistributor,
		isCancelledByDriver,
		isCancelledByPassenger,
	} = props;
	const { t } = useTranslation(['tickets']);

	const cancellationReasonText = getCancellationReasonText(
		t,
		props.cancellationReason
	);

	if (
		!isPaymentFailed &&
		!isCancelledByDistributor &&
		!isCancelledByDriver &&
		!isCancelledByPassenger
	)
		return null;

	if (isCancelledByPassenger)
		return (
			<PaymentFailedWrapper>
				<RedBorder>
					<Text variant="subtitle1" color="mopla_red">
						{t('title.cancelled_by_passenger')}
					</Text>
					<Text variant="body3">{t('text.cancelled_by_passenger')}</Text>
				</RedBorder>
			</PaymentFailedWrapper>
		);

	if (isPaymentFailed)
		return (
			<PaymentFailedWrapper>
				<RedBorder>
					<Text variant="subtitle1" color="mopla_red">
						{t('title.payment_failed')}
					</Text>
					<Text variant="body3">{t('text.payment_failed')}</Text>
				</RedBorder>
			</PaymentFailedWrapper>
		);

	if (isCancelledByDistributor) {
		return (
			<PaymentFailedWrapper>
				<RedBorder>
					<Text variant="subtitle1" color="mopla_red">
						{t('title.cancelled_by_distributor')}
					</Text>
					<Text variant="body3">
						{t('text.cancelled_by_distributor', {
							reason: cancellationReasonText,
							context: cancellationReasonText && 'reason',
						})}
					</Text>
				</RedBorder>
			</PaymentFailedWrapper>
		);
	}

	return (
		<PaymentFailedWrapper>
			<RedBorder>
				<Text variant="subtitle1" color="mopla_red">
					{t('title.cancelled_by_driver')}
				</Text>
				<Text variant="body3">{t('text.cancelled_by_driver')}</Text>
			</RedBorder>
		</PaymentFailedWrapper>
	);
};

function getCancellationReasonText(
	t: TFunction,
	cancellationReason?: string | null
) {
	if (!cancellationReason) {
		return null;
	}

	return t(`cancellation_reasons.${cancellationReason}`);
}
