import { Button, SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(SwipeableDrawer)(() => ({
	'.MuiDrawer-paper': {
		display: 'grid',
		gridAutoFlow: 'row',
		gridAutoRows: 'max-content',
		gridGap: 2,
		width: 250,

		padding: 12,
		boxSizing: 'border-box',
	},
}));

export const NavTitle = styled('span')(({ theme }) => ({
	...theme.fonts.body3,
	color: theme.colors.mopla_black,
	margin: 16,
}));

export const NavButton = styled(Button)(() => ({
	padding: 16,
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gridGap: 12,
	alignItems: 'center',
	justifyItems: 'start',
	textTransform: 'capitalize',
}));
