import { useEffect, useState } from 'react';

import { ILocationDecoded } from '@mopla/data-models';
import { getLocationCategory } from '@mopla/utils';

import { hereSearch } from '../services/here';

interface IResult {
	items: any[];
}

const getCurrentLocation = (query: string) => {
	return new Promise<ILocationDecoded[]>((resolve, reject) => {
		hereSearch.reverseGeocode(
			{
				at: query,
				result_types: ['place', 'address', 'category'],
			},
			(result: any) => {
				resolve(
					(result as IResult).items.map((suggestion: any) => {
						const {
							houseNumber = '',
							street,
							city,
							postalCode,
							stateCode,
							country,
						} = suggestion.address;

						return {
							id: suggestion.id,
							position: suggestion.position,
							title: `${street} ${houseNumber}, ${city}`,
							address: {
								postCode: postalCode,
								stateCode,
								street,
								houseNumber,
								city,
								country,
							},
							type: getLocationCategory(suggestion, 'address'),
						};
					})
				);
			},
			(err) => {
				reject(err);
			}
		);
	});
};

export const useConvertLatlong = (query: string) => {
	const [location, setLocation] = useState<ILocationDecoded | null>(null);

	useEffect(() => {
		if (query.length === 0) {
			setLocation(null);
		}
		if (query.length > 0) {
			getCurrentLocation(query).then((loc) => {
				setLocation(loc[0]);
			});
		}
	}, [query]);

	return location;
};
