import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { TextWithTooltip, TFormControlLabelForwardProps } from '@mopla/ui';

import { CheckboxStyled } from './styled';

export interface IBulkyLuggageCheckboxProps
	extends Omit<TFormControlLabelForwardProps, 'label'> {
	luggageDefinition?: string;
}

export const BulkyLuggageCheckbox = forwardRef<
	HTMLInputElement,
	IBulkyLuggageCheckboxProps
>(({ luggageDefinition, ...checkboxProps }, ref) => {
	const { t } = useTranslation('booking');

	const checkboxRendered = (
		<CheckboxStyled
			{...checkboxProps}
			label={t('text.bulkyLuggage')}
			inputRef={ref}
		/>
	);

	if (!luggageDefinition) {
		return <>{checkboxRendered}</>;
	}

	return (
		<TextWithTooltip text={checkboxRendered} tooltip={luggageDefinition} />
	);
});
