import { NormalizedScheduledLeg } from '@mopla/data-models';

import { EntityDescriptor } from '../business-logic';
import { scheduleDiffSchema } from '../schemas/scheduleDiffSchema';

export enum ScheduleDiffTypes {
	'NEW' = 'NEW',
	'NEW_BREAK' = 'NEW_BREAK',
	'DELETED' = 'DELETED',
}

export type ScheduleDiffEl = NormalizedScheduledLeg & {
	updateState: ScheduleDiffTypes;
};

export const scheduleDiffEntity: EntityDescriptor = {
	scheduleDiff: {
		migrationStrategies: {
			1: function (oldDoc: any) {
				// migration to rxdb 12
				return oldDoc;
			},
		},
		schema: scheduleDiffSchema,
	},
};
