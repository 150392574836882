export const bookingSchema = {
	title: 'booking schema',
	version: 2,
	primaryKey: 'bookingId',
	type: 'object',
	properties: {
		bookingId: {
			type: 'string',
			maxLength: 70,
			//example: 20228Z5F3J6S
		},
		isCheckedIn: {
			type: 'boolean',
		},
		bookingState: {
			type: 'string',
			// enum:  NEW | EXECUTING | FINISHED | CANCELLED_BY_DISTRIBUTOR | CANCELLED_BY_PASSENGER | CANCELLED_NO_SHOW
		},
		passengerList: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					firstName: { type: 'string' },
					lastName: { type: 'string' },
					phone: { type: 'string' },
					email: { type: 'string' },
					isMainPassenger: { type: 'boolean' },
					specialNeeds: {
						type: 'string',
						// NONE, WHEELCHAIR_TYPE_1, WHEELCHAIR_TYPE_2
					},
				},
			},
		},
		specialNeeds: {
			type: 'string',
			// NONE, WHEELCHAIR_TYPE_1, WHEELCHAIR_TYPE_2
		},
		paymentAmount: { type: 'number' },
		currencyCode: {
			type: 'string',
			// EUR
		},
		paymentForm: {
			type: 'string',
			// CASH, DIGITAL
		},
		paymentState: {
			type: 'string',
			// enum TO_PAY, INVOICE_SEND, PAID, FAILED
		},
	},
	required: ['bookingId'],
};
