import { useEffect, useState } from 'react';

import { IWhoamiMutable } from '@mopla/data-models';

import { useBusinessLayer } from '../business-logic';

/** TODO this has to be removed, bcs it goes outside of the rxjs and rxdb flows */
export const useLoginValidation = (
	shouldValidate: boolean
): {
	privileges: string[];
	isBlacklisted: boolean;
} => {
	const { api } = useBusinessLayer();
	const [whoAmI, setWhoAmI] = useState<IWhoamiMutable | null>(null);

	useEffect(() => {
		if (shouldValidate) {
			api.get('/api/whoami').then((res: IWhoamiMutable) => setWhoAmI(res));
		}
	}, [api, shouldValidate]);

	return {
		privileges: whoAmI?.privileges || [],
		isBlacklisted: whoAmI?.isBlacklisted || false,
	};
};
