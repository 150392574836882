export const usePersonalDetails = () => {
	const getPersonalDetails = () => localStorage.getItem('personalDetails');

	const clearPersonalDetails = () => localStorage.removeItem('personalDetails');

	return {
		personalDetails: getPersonalDetails(),
		clearPersonalDetails: () => clearPersonalDetails(),
	};
};
