import {
	ISubscriptionOffer,
	ISubscriptionOffering,
	ISubscriptionOfferVoucher,
} from '@mopla/data-models';

export const mapSubscriptionOffering = (
	rawData: any
): ISubscriptionOffering | null => {
	try {
		return {
			...rawData,
			id: '0',
			price: rawData.price / 100,
			fullPrice: rawData.fullPrice / 100,
			offers: rawData.offers.map((offer: any) => {
				let voucher: ISubscriptionOfferVoucher | undefined;

				/** Assume if there's a {voucherCode}, other voucher fields will also be present */
				if (offer.voucherCode) {
					voucher = {
						voucherCode: offer.voucherCode,
						voucherShortDescription: offer.voucherShortDescription,
						voucherLongDescription: offer.voucherLongDescription,
						voucherDeductionAmount: offer.voucherDeductionAmount / 100,
					};
				}

				const item: ISubscriptionOffer = {
					startDate: offer.startDate,
					subscriptionType: offer.subscriptionType,
					price: offer.price / 100,
					voucher,
				};

				return item;
			}),
		};
	} catch (e) {
		console.error(e);
		return null;
	}
};
