import React from 'react';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '../../icons/Cross';
import { LeftChevronIcon } from '../../icons/LeftChevron';

import { BackButton, CloseButton, Root, Title } from './AuthHeader.styles';

interface AuthHeaderProps {
	namespace: string;
	title: string;
	showBack?: boolean;
	onBack?: () => void;
	showClose?: boolean;
	onClose?: () => void;
}

export function AuthHeader({
	namespace,
	title,
	onBack,
	showBack,
	showClose,
	onClose,
}: AuthHeaderProps) {
	const { t } = useTranslation();
	return (
		<Root>
			{showBack && (
				<BackButton data-testid="authHeader-back-button" onClick={onBack}>
					<LeftChevronIcon />
				</BackButton>
			)}
			{showClose && (
				<CloseButton data-testid="authHeader-close-button" onClick={onClose}>
					<CrossIcon height={18} width={18} />
				</CloseButton>
			)}
			<Title variant="h3">{t(`${namespace}:${title}`)}</Title>
		</Root>
	);
}

export default AuthHeader;
