import { useCallback } from 'react';
import { useObservableEagerState } from 'observable-hooks';

import { fetchTickets as fetchTicketsAction } from '../actions/subscriptionActions';
import { useBusinessLayer } from '../business-logic';
import { useDBObservableDataContext } from '../contexts/dataContext';

export const useSubscriptionTickets = () => {
	const { subscriptionTickets$ } = useDBObservableDataContext();

	if (!subscriptionTickets$) {
		throw new Error(
			'No subscriptionTickets$ found in DBObservableDataContext. Refer to {withDBObservableDataContext}'
		);
	}

	return useObservableEagerState(subscriptionTickets$);
};

export const useSubscriptionTicketActions = () => {
	const { dispatch } = useBusinessLayer();

	const fetchTickets = useCallback(() => {
		dispatch(fetchTicketsAction());
	}, [dispatch]);

	return { fetchTickets };
};
