import React from 'react';

import { Notification } from '@mopla/business-logic';

import { AttentionIcon } from '../../icons/Attention';
import { CheckmarkCircleIcon } from '../../icons/CheckmarkCircle';
import { InfoIcon } from '../../icons/Info';

import { Root } from './NotificationManager.styles';

interface NotificationProps {
	notifications: Notification[];
	dataTestid?: string;
}

export const NotificationManager: React.FC<NotificationProps> = ({
	notifications,
	dataTestid = 'notification',
}) => {
	return (
		<>
			{notifications.map((n, i) => (
				<Root
					key={n.id}
					open={true}
					index={i}
					type={n.type}
					data-testid={dataTestid}
				>
					{n.type === 'INFO' && <InfoIcon />}
					{n.type === 'SUCCESS' && <CheckmarkCircleIcon />}
					{n.type === 'ERROR' && <AttentionIcon />}
					<span>{n.message}</span>
				</Root>
			))}
		</>
	);
};
