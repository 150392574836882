export const vouchersSchema = {
	title: 'vouchers schema',
	version: 0,
	primaryKey: 'voucherCode',
	type: 'object',
	properties: {
		voucherCode: {
			type: 'string',
			maxLength: 300,
		},
		description: {
			type: 'object',
			properties: {
				en: {
					type: 'string',
				},
				de: {
					type: 'string',
				},
			},
			required: ['en', 'de'],
			additionalProperties: true,
		},
	},
	required: ['voucherCode', 'description'],
};
