import { styled, Theme } from '@mui/material/styles';

import { TMoplaColors } from '@mopla/mui';

type TColors = TMoplaColors | string;

function prepareColor(color: string, theme: Theme) {
	if (Object.keys(theme.colors).includes(color as TMoplaColors)) {
		return theme.colors[color as TMoplaColors];
	}
	return color;
}

const excludedProps = ['solid', 'color', 'left', 'position', 'bgColor'];

export const Separator = styled('div', {
	shouldForwardProp: (prop) => !excludedProps.includes(prop as any),
})<{ solid?: boolean; color?: TColors; size?: number }>(
	({ theme, solid, color, size = 20 }) => ({
		background: color
			? prepareColor(color, theme)
			: theme.colors.mopla_secondary,
		display: 'grid',
		gridTemplateColumns: solid ? '1fr' : 'max-content 1fr max-content',
		height: size,
		width: '100%',
	})
);
export const Dash = styled('div', {
	shouldForwardProp: (prop) => !excludedProps.includes(prop as any),
})<{ color?: TColors }>(({ theme, color }) => ({
	height: 0,
	width: '100%',
	border: `1px dashed ${
		color ? prepareColor(color, theme) : theme.colors.mopla_secondary
	}`,
	alignSelf: 'center',
}));
export const SideCircle = styled('div', {
	shouldForwardProp: (prop) => !excludedProps.includes(prop as any),
})<{
	left?: boolean;
	color?: TColors;
	size?: number;
}>(({ theme, left, color, size = 20 }) => ({
	background: color ? prepareColor(color, theme) : theme.colors.mopla_secondary,
	height: size,
	width: size,
	borderRadius: '50%',
	margin: left ? `0 0 0 -${size / 2}px` : `0 -${size / 2}px 0 0`,
}));
export const Wrapper = styled('div', {
	shouldForwardProp: (prop) => !excludedProps.includes(prop as any),
})<{
	bgColor: TColors;
	position: 'top' | 'center' | 'bottom';
}>(({ theme, bgColor, position }) => ({
	padding: position === 'center' ? '16px 16px 24px' : 16,
	borderRadius:
		position === 'top'
			? '8px 8px 0 0'
			: position === 'bottom'
			? '0 0 8px 8px '
			: 0,
	background: bgColor ? prepareColor(bgColor, theme) : theme.colors.mopla_white,

	'&:last-of-type': {
		borderRadius: '0 0 8px 8px ',
	},
}));
export const TicketWrapper = styled('div')(() => ({
	display: 'grid',
	flexDirection: 'column',
	alignItems: 'flex-end',
	width: '100%',
	boxSizing: 'border-box',
	borderRadius: 8,
}));
