import { getI18n } from 'react-i18next';
import * as Sentry from '@sentry/capacitor';
import { AxiosError } from 'axios';
import { defer, exhaustMap, switchMap } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { IWhoamiMutable } from '@mopla/data-models';
import { Api } from '@mopla/data-models';
import { SUPPORTED_LNGS } from '@mopla/i18';

import {
	checkBannedEmailResult,
	fetchDefaultPaymentMethod,
	FetchDefaultPaymentMethodAction,
	fetchDefaultPaymentMethodResult,
	fetchUser,
	FetchUserAction,
	fetchUserResult,
	inquiryInvoiceResult,
	saveUserLanguageResult,
	SetDefaultPaymentMethodAction,
	setDefaultPaymentMethodResult,
	TCheckBannedEmailAction,
	TInquiryInvoiceAction,
	TSaveUserLanguageAction,
	UserActionTypes,
} from '../actions/userActions';
import { Effect } from '../business-logic';
import { ofType } from '../operators/ofType';

export const saveUserLanguage = (api: Api, language: string) => {
	return api.patch('/api/users/language', { language });
};

export const fetchDefaultPaymentMethodEffect: Effect<
	FetchDefaultPaymentMethodAction
> = (actions$, dependencies) =>
	actions$.pipe(
		ofType(UserActionTypes.FetchDefaultPaymentMethod),
		concatMap((action: FetchDefaultPaymentMethodAction) =>
			defer(async () => {
				try {
					const details = await dependencies.api.get(
						'/api/passengers/defaultPaymentMethod'
					);
					await dependencies.db.upsertLocal('defaultPaymentMethod', details);
				} catch (err) {
					const axiosEerr = err as AxiosError;
					if (axiosEerr.response && axiosEerr.response.status === 404) {
						const localDoc = await dependencies.db.getLocal(
							'defaultPaymentMethod'
						);
						if (localDoc) {
							await localDoc.remove();
						}
					} else {
						dependencies.Sentry.captureException(err);
					}
				}
				return fetchDefaultPaymentMethodResult();
			})
		)
	);

export const setDefaultPaymentMethodEffect: Effect = (actions$, dependencies) =>
	actions$.pipe(
		ofType(UserActionTypes.SetDefaultPaymentMethod),
		concatMap((action: SetDefaultPaymentMethodAction) =>
			defer(async () => {
				let span;
				try {
					const transaction = dependencies.Sentry.getCurrentHub()
						.getScope()
						?.getTransaction();
					span = transaction?.startChild({
						op: 'setDeaultPaymentMethod',
					});

					const { paymentMethodId, setupIntentId } = action.payload;

					if (paymentMethodId || setupIntentId) {
						await dependencies.api.post(
							'/api/command/setDefaultPaymentMethod',
							{ paymentMethodId, setupIntentId }
						);
					} else {
						// in case we didn't receive a callback, the payment method
						// may still have been saved, so let the backend check, if
						// any payment method attach can become the default
						await dependencies.api.post(
							'/api/command/setDefaultPaymentMethod',
							{}
						);
					}

					actions$.next(fetchUser());
					actions$.next(fetchDefaultPaymentMethod());

					span?.finish();
					return setDefaultPaymentMethodResult();
				} catch (error: any) {
					dependencies.Sentry.captureException(error);

					span?.finish();
					return setDefaultPaymentMethodResult({ error });
				}
			})
		)
	);

export const fetchUserEffect: Effect<FetchUserAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(UserActionTypes.FetchUser),
		switchMap(() =>
			defer(async () => {
				try {
					const i18n = getI18n();
					const user = await dependencies.api.get<IWhoamiMutable>(
						'/api/whoami'
					);

					/** Set system language from BE if was saved before. Otherwise, call API to save current lang */
					if (user.language && SUPPORTED_LNGS.includes(user.language)) {
						i18n
							.changeLanguage(user.language)
							.catch((err) => console.error(err));
					} else {
						user.language = i18n.language;

						await saveUserLanguage(dependencies.api, i18n.language);
					}

					await dependencies.db.upsertLocal('user', user);

					Sentry.setUser({
						id: user.userId,
					});

					// this is a quick fix to recover from a broken state in localStorage
					// after a user got a wrong entry when entering PersonalDetailsForm
					// TODO this can be removed in a future release as the problem will vanish
					// after some time
					if (!user.pleaseComplete) {
						localStorage.setItem('personalDetails', 'completed');
					}
				} catch (err) {
					dependencies.Sentry.captureException(err);
				}
				return fetchUserResult();
			})
		)
	);

export const setUserLanguageEffect: Effect = (actions$, dependencies) =>
	actions$.pipe(
		ofType(UserActionTypes.SaveUserLanguage),
		switchMap((action: TSaveUserLanguageAction) =>
			defer(async () => {
				try {
					await saveUserLanguage(dependencies.api, action.payload.language);

					actions$.next(fetchUser());
				} catch (err) {
					dependencies.Sentry.captureException(err);
				}

				return saveUserLanguageResult();
			})
		)
	);

export const inquiryInvoiceEffect: Effect = (actions$, dependencies) =>
	actions$.pipe(
		ofType(UserActionTypes.InquiryInvoice),
		exhaustMap((action: TInquiryInvoiceAction) =>
			defer(async () => {
				const { year, month, subscriptionId } = action.payload;
				try {
					await dependencies.api.post('/api/passengers/invoices', {
						subscriptionId,
						month,
						year,
					});

					return inquiryInvoiceResult();
				} catch (error: any) {
					dependencies.Sentry.captureException(error);

					return inquiryInvoiceResult({ error });
				}
			})
		)
	);

export const checkBannedEmailEffect: Effect = (actions$, dependencies) =>
	actions$.pipe(
		ofType(UserActionTypes.CheckBannedEmail),
		exhaustMap((action: TCheckBannedEmailAction) =>
			defer(async () => {
				const { email } = action.payload;
				try {
					const response = await dependencies.api.get(
						`/api/emails/check?email=${email}`
					);

					return checkBannedEmailResult({ isValid: response.isValid });
				} catch (error: any) {
					dependencies.Sentry.captureException(error);

					return checkBannedEmailResult({ error });
				}
			})
		)
	);
