import { styled } from '@mui/material/styles';

import { Button, CheckmarkCircleIcon } from '../../../';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'bottomIndent',
})(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gridAutoRows: 'max-content',
	boxSizing: 'border-box',
	width: '100%',
	overflow: 'auto',
	paddingTop: 16,
}));

export const Content = styled('div')(() => ({
	paddingLeft: 24,
	paddingRight: 24,
}));

export const CheaperRidesWrapper = styled(Content)(() => ({
	'&:not(:empty)': {
		marginTop: 16,
	},
}));

export const TitleWrapper = styled(Content)(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gridTemplateRows: 'max-content max-content',
	boxSizing: 'border-box',
	marginBottom: 16,
}));

export const TimeWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
}));

export const AttentionWrapper = styled(Content)(({ theme }) => ({
	color: theme.colors.mopla_dark_grey,
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gap: '16px 10px',
}));

export const LegsWrapper = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_white,
	borderRadius: 8,
	width: '100%',
	display: 'grid',
	gridTemplateColumns: '1fr',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gridGap: 48,
	margin: '24px 0',
	padding: '24px 0',
}));

export const WarningBottom = styled('div')(({ theme }) => ({
	...theme.fonts.caption,
	color: theme.colors.mopla_dark_grey,
	display: 'grid',
	gridGap: 10,
	gridTemplateColumns: 'max-content 1fr',
	padding: '0 56px 24px 26px',
	boxSizing: 'border-box',
}));

export const PassengersInfoWrapper = styled('div')(({ theme }) => ({
	boxSizing: 'border-box',
	padding: '16px 24px',
	width: '100%',
	height: '100%',
	backgroundColor: theme.colors.mopla_white,
	borderRadius: 8,

	[theme.breakpoints.up('tabletP')]: {
		height: 'max-content',
	},
}));

export const Title = styled('span')(({ theme }) => ({
	...theme.fonts.subtitle1,
	marginBottom: 16,
}));

export const PassengerInfo = styled('div')(({ theme }) => ({
	display: 'grid',

	'& > span:not(:last-of-type)': {
		marginBottom: 4,
	},

	'& > span': {
		...theme.fonts.body3,
		color: theme.colors.mopla_dark_grey,
	},

	'& > span:first-of-type': {
		...theme.fonts.body1,
		color: theme.colors.mopla_black,
	},
}));

export const ButtonCancel = styled(Button)(({ theme }) => ({
	margin: '32px 24px ',
	width: 'auto',
	height: 'max-content',

	[theme.breakpoints.up('tabletP')]: {
		margin: 0,
	},
}));

export const PaymentFailedWrapper = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_white,
	boxSizing: 'border-box',
	width: '100%',
	marginTop: 24,
	padding: '24px 0',
	borderRadius: 8,
	height: 'max-content',
}));
export const RedBorder = styled('div')(({ theme }) => ({
	position: 'relative',
	padding: '0 24px',
	display: 'grid',
	gridGap: 16,

	'&:before': {
		content: '" "',
		width: 4,
		height: '100%',
		backgroundColor: theme.colors.mopla_red,
		position: 'absolute',
		top: 0,
		left: 0,
		borderRadius: '0 8px 8px 0',
	},
}));

export const CheapestRideIcon = styled(CheckmarkCircleIcon)(({ theme }) => ({
	color: theme.colors.mopla_primary,
	width: 32,
	height: 32,
}));
