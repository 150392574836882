import React from 'react';

import { GoogleIcon } from '../../icons/Google';

import { SocialButton } from './SocialButton';

interface IProps {
	onClick: VoidFunction;
}
export const GoogleButton: React.FC<IProps> = (props) => {
	const { onClick } = props;

	const handleClick = () => {
		onClick();
	};
	return (
		<SocialButton name="google" onClick={handleClick} icon={<GoogleIcon />} />
	);
};
