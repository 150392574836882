import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IBooking } from '@mopla/data-models';
import { ArrivalIcon, DropdownIcon, PaymentBlock } from '@mopla/ui';
import { isMixedItinerary } from '@mopla/utils';

import {
	Content,
	Header,
	NonSelectableText,
	PaymentTextStyled,
} from './PaymentSummary.styled';
import { EllipseText, StationWrapper } from './TicketQR.styles';
import { preparePriceSummarySegments } from './utils';

export interface IPaymentSummaryProps {
	booking?: IBooking;
}

export const PaymentSummary: FC<IPaymentSummaryProps> = ({ booking }) => {
	const [isOpened, setIsOpened] = useState(true);
	const { t } = useTranslation();

	const toggleOpen = () => setIsOpened((p) => !p);

	if (!booking) {
		return null;
	}

	const isMixedRide = isMixedItinerary(booking.itinerary);
	const segments = preparePriceSummarySegments(booking, t);
	const shouldShowSegmentsDropdown = segments.length > 1;

	/**
	 * If the component is rendered for a ride with the only one leg, there will be only 1 price summary segment
	 * the payment status of this leg will be displayed.
	 * Otherwise, there will be a dropdown for all legs in the booking
	 * */
	const singleLegPaymentSegment = segments.length === 1 ? segments[0] : null;

	return (
		<>
			<Header onClick={shouldShowSegmentsDropdown ? toggleOpen : undefined}>
				{singleLegPaymentSegment && (
					<NonSelectableText
						variant="body1"
						color={
							singleLegPaymentSegment.isError ? 'mopla_red' : 'mopla_black'
						}
					>
						{singleLegPaymentSegment.paymentStatus}
					</NonSelectableText>
				)}
				<PaymentBlock
					entity={booking || null}
					render={(text) => (
						<NonSelectableText variant="body1_semibold" color="mopla_black">
							{text}
						</NonSelectableText>
					)}
					single={!isMixedRide}
				/>
				{shouldShowSegmentsDropdown && <DropdownIcon isOpened={isOpened} />}
			</Header>
			{isOpened && shouldShowSegmentsDropdown && (
				<Content>
					{segments.map((segment) => (
						<Fragment key={segment.destination}>
							<StationWrapper>
								<EllipseText variant="body2" color="mopla_black">
									{segment.type}
								</EllipseText>
								<ArrivalIcon />
								<EllipseText variant="body2" color="mopla_black">
									{segment.destination}
								</EllipseText>
							</StationWrapper>
							<PaymentTextStyled
								variant="caption_semibold"
								color={segment.isError ? 'mopla_red' : 'mopla_black'}
							>
								{segment.value}
							</PaymentTextStyled>
						</Fragment>
					))}
				</Content>
			)}
		</>
	);
};
