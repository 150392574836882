import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TGermanyTicketType } from '@mopla/data-models';
import { FlexColumn, Text } from '@mopla/ui';

import { DTicketTicketView } from '../../DTicketTicketView/DTicketTicketView';
import { ConfirmationWrapper } from '../DTicketSubscription.styles';
import { ISubscriptionFormValues } from '../types';

interface IProps {
	onSubmit: VoidFunction;
	dTicketType: TGermanyTicketType;
}

export const ConfirmationStep: React.FC<IProps> = (props) => {
	const { onSubmit, dTicketType } = props;
	const { t } = useTranslation(['dticket']);
	const { watch } = useFormContext<ISubscriptionFormValues>();
	const monthOfStart = watch('monthOfStart');

	return (
		<ConfirmationWrapper>
			<FlexColumn
				className="space-between"
				style={{ background: 'transparent' }}
			>
				<Text variant="body1" color="mopla_white">
					{t('text.dticket_stored', { dTicketType })}
				</Text>
				<Text variant="body1" color="mopla_white">
					{t('text.dticket_stored2')}
				</Text>
			</FlexColumn>
			<DTicketTicketView
				onSubmit={onSubmit}
				dTicketType={dTicketType}
				monthOfStart={monthOfStart}
			/>
		</ConfirmationWrapper>
	);
};
