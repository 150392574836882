import { memo, ReactNode } from 'react';

import { useBreakpoints } from '@mopla/utils';

export interface IResponsiveProps {
	aboveMobile?: boolean;
	mobile?: boolean;
	children: ReactNode;
}

export const Responsive = memo<IResponsiveProps>(
	({ aboveMobile, mobile, children }) => {
		const { isAboveMobile, isMobile } = useBreakpoints();

		const shouldRender = (aboveMobile && isAboveMobile) || (mobile && isMobile);

		if (shouldRender) {
			// eslint-disable-next-line react/jsx-no-useless-fragment
			return <>{children}</>;
		}

		return null;
	}
);
