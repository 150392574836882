import React, { useEffect, useState } from 'react';
import {
	Controller,
	FormProvider,
	UnpackNestedValue,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { IdTokenResult } from '@firebase/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { useLoginValidation } from '@mopla/business-logic';
import { ChangePasswordFields } from '@mopla/constants';

import { Button } from '../../atoms/Button/Button';
import { PasswordInput } from '../../atoms/PasswordInput/PasswordInput';
import { ArrowRightIcon, AttentionIcon } from '../../icons';
import { RightRideAnim } from '../../molecules/Animations';
import { BadgeComponent } from '../../molecules/BadgeComponent/BadgeComponent';
import { DropdownIcon } from '../../molecules/DropdownIcon/DropdownIcon';
import PasswordValidationConditions from '../../validation/PasswordValidationConditions';
import { LoginForm } from '../LoginForm/LoginForm';

import {
	AnimWrapper,
	BrowserDescription,
	BrowserTitle,
	ErrorWrapper,
	LoginBox,
	Root,
	SubmitLevel,
	Title,
} from './ChangePasswordForm.styles';
import ChangePasswordValidationSchema from './ChangePasswordValidation';

interface IFormInput {
	[ChangePasswordFields.password]: string;
}

interface ChangePasswordProps {
	showSuccess: boolean;
	onSuccessCallback: () => void;
	onSubmitCallback: () => void;
	onErrorCallback: () => void;
	isOnboarding?: boolean;
	driverApp?: boolean;
	verifyPasswordResetCode: any;
	confirmPasswordReset: any;
	getAuth: any;
}

export const ChangePasswordForm: React.FC<ChangePasswordProps> = ({
	onSuccessCallback,
	showSuccess,
	onSubmitCallback,
	onErrorCallback,
	isOnboarding,
	driverApp,
	verifyPasswordResetCode,
	confirmPasswordReset,
	getAuth,
}) => {
	const { t } = useTranslation([
		'changePassword',
		'validation',
		'registration',
	]);
	const [error, setError] = useState<boolean>(false);
	const [checkPasswordErrors, setCheckPasswordErrors] =
		useState<boolean>(false);
	const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);
	const [authError, setAuthError] = useState<boolean>(false);
	const [loginDone, setLoginDone] = useState<boolean>(false);
	const [showLogin, setShowLogin] = useState<boolean>(false);
	const location = useLocation();
	const navigate = useNavigate();

	const { isBlacklisted, privileges } = useLoginValidation(loginDone);

	useEffect(() => {
		if (
			loginDone &&
			!isBlacklisted &&
			privileges.includes('CAN_READ_DRIVER_APP')
		) {
			navigate({ pathname: '/home' });
		}
		if (
			loginDone &&
			(isBlacklisted || privileges.includes('CAN_READ_DRIVER_APP'))
		) {
			getAuth().signOut();
		}
	}, [isBlacklisted, privileges, loginDone, navigate]);

	const auth = getAuth();

	const onLogin = (
		data: UnpackNestedValue<{ email: string; password: string }>
	) => {
		const { email, password } = data;
		return signInWithEmailAndPassword(auth, email, password)
			.then(() => {
				const user = auth.currentUser;
				user
					?.getIdTokenResult()
					.then((res: IdTokenResult) =>
						localStorage.setItem('token', res.token)
					)
					.then(() => setLoginDone(true));
			})
			.catch(() => {
				setAuthError(true);
			});
	};

	const methods = useForm<IFormInput>({
		defaultValues: {
			[ChangePasswordFields.password]: '',
		},
		resolver: yupResolver(ChangePasswordValidationSchema),
		mode: 'onBlur',
	});
	const { formState, handleSubmit, watch } = methods;

	const onSubmit = (data: UnpackNestedValue<IFormInput>) => {
		if (error) {
			onErrorCallback();
		}
		const { password } = data;
		const auth = getAuth();
		const actionCode = new URLSearchParams(location.search).get('oobCode');
		verifyPasswordResetCode(auth, actionCode as string)
			.then(() => {
				confirmPasswordReset(auth, actionCode as string, password)
					.then(() => {
						setError(false);
						onSuccessCallback();
						methods.reset({
							[ChangePasswordFields.password]: '',
						});
					})
					.catch(() => {
						setError(true);
					});
			})
			.catch(() => {
				setError(true);
			});
	};

	if (showSuccess) {
		if (driverApp && !Capacitor.isNativePlatform()) {
			return (
				<>
					<BrowserTitle>
						{t('success_screen.text.description_driver')}
					</BrowserTitle>
					<AnimWrapper>
						<RightRideAnim />
					</AnimWrapper>
					<BrowserTitle>{t('text.easier')}</BrowserTitle>
					<BrowserDescription>{t('text.download_app')}</BrowserDescription>
					<BadgeComponent />
					<LoginBox onClick={() => setShowLogin((prev: boolean) => !prev)}>
						<span>{t('text.login_in_browser')}</span>
						<DropdownIcon isOpened={showLogin} />
					</LoginBox>
					{showLogin && (
						<LoginForm
							driverApp
							browser={!Capacitor.isNativePlatform()}
							onLogin={onLogin}
							authError={authError}
						/>
					)}
				</>
			);
		}
		return (
			<div data-testid="change-passwordForm-success-screen">
				<AnimWrapper>
					<RightRideAnim />
				</AnimWrapper>
				<Title>
					{t(
						isOnboarding
							? 'success_screen.text.description_driver'
							: 'success_screen.text.description'
					)}
				</Title>
				<SubmitLevel>
					<Button
						variant={driverApp ? 'secondary' : 'primary'}
						icon={<ArrowRightIcon />}
						data-testid="successScreen_button_toLogin"
						type="button"
						onClick={onSubmitCallback}
					>
						{t(
							isOnboarding
								? 'success_screen.button.to_the_login'
								: 'success_screen.button.to_login'
						)}
					</Button>
				</SubmitLevel>
			</div>
		);
	}

	const getButtonName = () => {
		if (error) return t('success_screen.button.to_login');
		if (isOnboarding) return t('button.set_password');
		return t('button.change_password');
	};

	return (
		<>
			<Title>{t(isOnboarding ? 'text.title_driver' : 'text.title')}</Title>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit((data) => onSubmit(data))}>
					<Root>
						<Controller
							name="password"
							render={({ field }) => (
								<PasswordInput
									{...field}
									onBlur={() => {
										field.onBlur();
										setCheckPasswordErrors(true);
									}}
									label={t('label.password')}
									ref={null}
									helperText={
										Boolean(formState.errors.password?.message) && (
											<>
												<AttentionIcon width={15} height={15} />
												<span>
													{t(
														`validation:${formState.errors.password?.message}`
													)}
												</span>
											</>
										)
									}
									error={Boolean(formState.errors.password?.message || error)}
									inputProps={{
										'data-testid': 'change-passwordForm-password-input',
									}}
								/>
							)}
						/>
						<ErrorWrapper error={error}>{t('text.expired')}</ErrorWrapper>
						<PasswordValidationConditions
							driverApp={driverApp}
							password={watch('password')}
							checkPasswordErrors={checkPasswordErrors}
							setCheckPasswordErrors={setCheckPasswordErrors}
							setPasswordIsValid={setPasswordIsValid}
						/>
						<SubmitLevel>
							<Button
								variant={driverApp ? 'secondary' : 'primary'}
								icon={<ArrowRightIcon />}
								data-testid="forgot-password-button"
								disabled={!passwordIsValid}
								type="submit"
							>
								{getButtonName()}
							</Button>
						</SubmitLevel>
					</Root>
				</form>
			</FormProvider>
		</>
	);
};
