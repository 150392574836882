import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomNavigation } from '@mui/material';

import { DTicketIcon, ProfileIcon, SearchIcon, TicketIcon } from '../../';

import { StyledBottomNavigationAction } from './PassengerBottomNavigation.styles';

const PassengerBottomNavigation = () => {
	const { t } = useTranslation('navigation');
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [value, setValue] = React.useState('/home/dticket');

	useEffect(() => {
		setValue(pathname);
	}, [pathname]);

	return (
		<BottomNavigation
			sx={{
				root: {
					color: 'green',
					'&$selected': {
						color: 'red',
					},
				},
				paddingBottom: 'var(--mopla-safe-area-bottom)',
			}}
			showLabels
			value={value}
			onChange={(event, newValue) => {
				navigate(newValue);
			}}
		>
			<StyledBottomNavigationAction
				value="/home/dticket"
				label={t('dticket')}
				icon={<DTicketIcon />}
			/>
			<StyledBottomNavigationAction
				value="/home/tickets"
				label={t('tickets')}
				icon={<TicketIcon />}
			/>
			<StyledBottomNavigationAction
				value="/home/search"
				label={t('search')}
				icon={<SearchIcon />}
			/>
			<StyledBottomNavigationAction
				value="/home/profile"
				label={t('profile')}
				icon={<ProfileIcon />}
			/>
		</BottomNavigation>
	);
};

export default PassengerBottomNavigation;
