import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: '1fr max-content',
	width: '100%',
	height: '100%',
	overflow: 'auto',
	zIndex: 1,
}));

export const ContentWrapper = styled('div')(() => ({
	overflow: 'auto',
}));
