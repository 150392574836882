import { FC } from 'react';

interface IClockMoneyIconProps {
	className?: string;
}

export const ClockMoneyIcon: FC<IClockMoneyIconProps> = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			className={className}
		>
			<path
				fill="#FFE66D"
				d="M10.6 21.94a.5.5 0 00-.01.06H8.8a.5.5 0 000-.06c.07-.1.18-.12.29-.12.4-.02.8-.02 1.2 0 .11 0 .22.02.3.12zM22 8h-.07a1.8 1.8 0 010-1.42H22V8z"
			></path>
			<path
				fill="#FFE66D"
				d="M21.93 6.58a5.3 5.3 0 00-10.46-.1c-.05.23-.07.42-.44.36a7.54 7.54 0 00-4.11.36A7.55 7.55 0 002 13.95a7.45 7.45 0 003 6.48 7.1 7.1 0 003.8 1.5h1.79l.3-.01a7.67 7.67 0 006.26-8.98c-.04-.32.07-.39.34-.42a5.25 5.25 0 004.43-4.51V6.58zm-6.47 8.37c.6 0 .63.05.51.64a6.47 6.47 0 01-5.32 5.14h-.08c-.25.02-.34-.07-.34-.25v-.11c0-.79.11-.73-.78-.74-.27 0-.43.09-.4.38.07.9-.1.8-.88.62a6.45 6.45 0 01-4.9-5.26c-.04-.27 0-.43.33-.41.86.04.74-.03.75-.85 0-.23-.07-.33-.31-.34-.88-.04-.9-.05-.66-.92a6.34 6.34 0 015.05-4.83c.57-.14.6-.1.6.49 0 .36.18.54.53.54h.11c.37 0 .55-.2.55-.57 0-.55.04-.56.57-.48.46.08.69.28.83.76a5.11 5.11 0 003.87 3.7c.24.05.4.17.42.42.01.09.04.17.06.26.13.6.12.62-.5.63-.35 0-.52.17-.52.52v.15c0 .34.17.51.51.51zm1.22-3.54a4.12 4.12 0 01.02-8.22c2.24 0 4.12 1.9 4.1 4.14a4.14 4.14 0 01-4.12 4.08z"
			></path>
			<path
				fill="#FFE66D"
				d="M9.04 12.9v-1.37c.02-.43.22-.65.59-.66.37 0 .58.18.59.56v1.59c0 .5.2.69.7.7.56.01 1.12-.03 1.7.04.34.03.51.24.53.54.02.31-.14.55-.47.61-.1.02-.2.04-.3.04H9.84c-.56 0-.78-.23-.79-.79V12.9zm9.03-6.75c-.23.02-.49.02-.6-.35-.05-.16-.25-.24-.43-.24-.24-.01-.47-.02-.7 0-.34.02-.53.23-.55.55 0 .3.17.5.48.57.27.07.54.04.8.05.9.03 1.54.6 1.7 1.51.14.72-.35 1.58-1.1 1.87-.23.1-.42.18-.4.48 0 .17-.12.23-.27.23h-.6c-.2 0-.31-.09-.31-.28 0-.24-.13-.33-.33-.42-.45-.22-.86-.5-1.04-1-.15-.4-.13-.66.4-.62.35.02.7-.06.86.38.05.12.22.16.36.16h.67c.36-.02.55-.21.56-.55 0-.36-.2-.56-.58-.57-.3-.01-.61-.02-.92-.06A1.76 1.76 0 0114.6 6.3c-.08-.74.4-1.54 1.1-1.8.23-.09.42-.17.4-.48-.02-.17.12-.26.29-.26h.56c.2 0 .33.08.32.3 0 .22.1.32.31.4.47.15.8.46 1.02.9.3.6.18.78-.53.78z"
			></path>
		</svg>
	);
};
