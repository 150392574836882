import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRightIcon, Button, Responsive } from '../..';

import {
	CTADesktopWrapper,
	CTAMobileWrapper,
	SubmitWrapper,
} from './PersonalDetailsForm.styles';

export interface IFormFooterProps {
	isUserProfile: boolean;
	onCancel(): void;
	isSubmitDisabled: boolean;
}

export const FormFooter: FC<IFormFooterProps> = ({
	isUserProfile,
	onCancel,
	isSubmitDisabled,
}) => {
	const { t } = useTranslation('profile');

	const cancelBtn = (
		<Button
			variant="text"
			color="secondary"
			type="button"
			id="skip-button"
			sx={{ alignSelf: 'flex-end', width: 'auto' }}
			onClick={onCancel}
		>
			{t('button.abort')}
		</Button>
	);

	const submitBtn = (
		<Button
			variant="primary"
			data-testid="personal-detailsForm-submit-button"
			disabled={isSubmitDisabled}
			type="submit"
		>
			{t('button.save')}
		</Button>
	);

	if (isUserProfile) {
		return (
			<>
				<Responsive aboveMobile>
					<CTADesktopWrapper>
						{cancelBtn}
						{submitBtn}
					</CTADesktopWrapper>
				</Responsive>

				<Responsive mobile>
					<CTAMobileWrapper>
						{cancelBtn}
						<SubmitWrapper>{submitBtn}</SubmitWrapper>
					</CTAMobileWrapper>
				</Responsive>
			</>
		);
	}

	return (
		<Button
			variant="primary"
			icon={<ArrowRightIcon />}
			data-testid="personal-detailsForm-submit-button"
			disabled={isSubmitDisabled}
			type="submit"
		>
			{t('common:button.here_we_go')}
		</Button>
	);
};
