import { ComponentType, FC, useMemo } from 'react';

import {
	DBObservableDataContextProvider,
	IDBObservableDataContext,
	useSubscription$,
	useSubscriptionTickets$,
	useUser$,
} from '@mopla/business-logic';
import { wrapDisplayName } from '@mopla/utils';

export const withDBObservableDataContext = (
	WrappedComponent: ComponentType
) => {
	const Wrapper: FC = () => {
		const user$ = useUser$();
		const subscription$ = useSubscription$();
		const subscriptionTickets$ = useSubscriptionTickets$();

		const ctx = useMemo<IDBObservableDataContext>(
			() => ({ user$, subscription$, subscriptionTickets$ }),
			[user$, subscription$, subscriptionTickets$]
		);

		return (
			<DBObservableDataContextProvider value={ctx}>
				<WrappedComponent />
			</DBObservableDataContextProvider>
		);
	};

	Wrapper.displayName = wrapDisplayName(
		WrappedComponent,
		'withDBObservableData'
	);

	return Wrapper;
};
