/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ManualTripType {
	EMPTY = 'EMPTY',
	SPECIAL_SCHOOL_TRAFFIC = 'SPECIAL_SCHOOL_TRAFFIC',
	BLOCKER = 'BLOCKER',
	UNCATEGORIZED = 'UNCATEGORIZED',
}
