import React from 'react';
export const AppleIcon = () => {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_49_2870)">
				<path
					d="M21.2806 18.9696C20.9328 19.7731 20.5211 20.5128 20.0441 21.1928C19.3938 22.1199 18.8614 22.7616 18.4511 23.118C17.8151 23.7029 17.1336 24.0024 16.4039 24.0195C15.88 24.0195 15.2483 23.8704 14.5129 23.568C13.775 23.267 13.097 23.118 12.477 23.118C11.8268 23.118 11.1294 23.267 10.3835 23.568C9.63644 23.8704 9.03463 24.028 8.5745 24.0436C7.87472 24.0734 7.17722 23.7653 6.48099 23.118C6.03662 22.7304 5.48081 22.0659 4.81496 21.1247C4.10057 20.1195 3.51323 18.9539 3.0531 17.6251C2.56032 16.1898 2.31329 14.7999 2.31329 13.4543C2.31329 11.9129 2.64636 10.5835 3.31348 9.46944C3.83778 8.5746 4.53528 7.86872 5.40826 7.35052C6.28124 6.83233 7.2245 6.56826 8.2403 6.55137C8.79611 6.55137 9.52499 6.7233 10.4308 7.06119C11.334 7.40021 11.9139 7.57214 12.1682 7.57214C12.3583 7.57214 13.0026 7.37111 14.0948 6.97033C15.1276 6.59865 15.9993 6.44475 16.7134 6.50537C18.6485 6.66154 20.1023 7.42435 21.0691 8.79863C19.3385 9.84722 18.4824 11.3159 18.4994 13.2C18.515 14.6676 19.0474 15.8888 20.0937 16.8585C20.5679 17.3085 21.0975 17.6563 21.6867 17.9034C21.5589 18.2739 21.424 18.6288 21.2806 18.9696ZM16.8426 1.50572C16.8426 2.65599 16.4224 3.72998 15.5847 4.72406C14.5739 5.90583 13.3513 6.58871 12.0254 6.48095C12.0085 6.34296 11.9987 6.19772 11.9987 6.0451C11.9987 4.94085 12.4794 3.75909 13.3331 2.79283C13.7593 2.3036 14.3013 1.89681 14.9586 1.57231C15.6146 1.25265 16.235 1.07587 16.8185 1.04559C16.8355 1.19937 16.8426 1.35315 16.8426 1.50571V1.50572Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_49_2870">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(0 0.545593)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
