import React, { ReactElement } from 'react';

import { CrossIcon, LeftChevronIcon } from '../../icons';

import { ChildrenWrapper, Root, Title, TopButton } from './SearchHeader.styles';

export interface SearchHeaderProps {
	open: boolean;
	showBack?: boolean;
	showClose?: boolean;
	onBack?: () => void;
	onClose?: () => void;
	variant?:
		| 'secondary'
		| 'primary'
		| 'yellow'
		| 'secondary_light'
		| 'light'
		| 'white'
		| 'light_blue'
		| 'secondary_dark';
	title?: string;
	children?: ReactElement | null;
}

export const SearchHeader: React.FC<SearchHeaderProps> = ({
	open,
	showBack = false,
	showClose = false,
	onBack,
	onClose,
	variant = 'primary',
	title,
	children,
}) => {
	return (
		<Root variant={variant} open={open} rounded={!!title || !!children}>
			{showBack ? (
				<TopButton
					type="button"
					key="back"
					left
					variant="text"
					onClick={onBack}
					data-testid="drawer-back-button"
					id="backButton"
				>
					<LeftChevronIcon />
				</TopButton>
			) : null}
			{showClose ? (
				<TopButton
					type="button"
					key="close"
					variant="text"
					onClick={onClose}
					data-testid="drawer-close-button"
					id="closeButton"
				>
					<CrossIcon />
				</TopButton>
			) : null}
			{title ? <Title data-testid="drawer-header-text">{title}</Title> : null}
			{children ? <ChildrenWrapper>{children}</ChildrenWrapper> : null}
		</Root>
	);
};

export default SearchHeader;
