import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/capacitor';
import {
	Elements,
	PaymentElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
	ArrowRightIcon,
	Button,
	CheckmarkIcon,
	ErrorModal,
	IconMessage,
	InfoBaseIcon,
	SearchHeader,
} from '../../';

import { ActionWrapper, Root, ScrollWrapper } from './Payment.styles';

interface Props {
	clientSecret: string;
	onClose: (paymentMethodId?: string) => void;
	stripeRedirectUrl: string;
	stripePulicKey: string;
	Sentry?: typeof Sentry;
	_error?: string;
}

export const PaymentWrapper: FC<Props> = (props) => {
	const { clientSecret, stripePulicKey } = props;
	const stripePromise = loadStripe(stripePulicKey);
	return clientSecret ? (
		<Elements stripe={stripePromise} options={{ clientSecret }}>
			<Payment {...props} />
		</Elements>
	) : (
		<></>
	);
};

const Payment: FC<Props> = (props) => {
	const { onClose, stripeRedirectUrl, Sentry, _error } = props;
	const stripe = useStripe();
	const elements = useElements();
	const { t } = useTranslation(['payment']);
	const [error, setError] = useState<string | undefined>();
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		setError(_error);
	}, [_error]);

	const initiatePayment = async () => {
		try {
			if (stripe && elements) {
				setIsSaving(true);
				const { error, setupIntent } = await stripe.confirmSetup({
					elements,
					confirmParams: {
						return_url: stripeRedirectUrl,
					},
					// Uncomment below if you only want redirect for redirect-based payments
					redirect: 'if_required',
				});
				setIsSaving(false);
				if (error) {
					if (error.type === 'validation_error') {
						return;
					} else if (error.type === 'card_error') {
						setError(error.message);
					} else {
						setError('generic');
					}
				} else if (setupIntent?.payment_method) {
					onClose(setupIntent?.payment_method as string);
				} else {
					Sentry?.captureException(
						new Error(
							'neither error nor payment method was returned from stripe'
						)
					);
				}
			}
		} catch (err) {
			Sentry?.captureException(err);
			console.error(err);
		}
	};

	return (
		<>
			{error && (
				<ErrorModal
					title={t('error.title')}
					infoText={t('error.generic')}
					additionalInfo={
						<IconMessage
							icon={<InfoBaseIcon />}
							message={
								error === 'generic' ? t('error.generic_subtitle') : error
							}
						/>
					}
					submitTitle={t('delete_modal.button.close')}
					submitIcon={<ArrowRightIcon />}
					onSubmit={() => onClose()}
				/>
			)}
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: 1200,
				}}
				id="payment-wrapper"
			>
				<Root hidden={false}>
					<SearchHeader
						open={true}
						showBack={true}
						showClose={false}
						onBack={() => onClose()}
						onClose={() => onClose()}
						title="Bezahlmethode hinterlegen"
						variant="secondary"
					/>
					<ScrollWrapper>
						<div style={{ padding: '1rem' }}>
							<PaymentElement options={{}} />
						</div>
					</ScrollWrapper>
					<ActionWrapper>
						<Button
							type="button"
							icon={isSaving ? undefined : <CheckmarkIcon />}
							onClick={() => {
								initiatePayment();
							}}
							variant="outlined"
							disabled={isSaving}
							color="info"
							data-testid="booking-nextStep-button"
						>
							{isSaving ? t('button.is_saving') : t('button.save')}
						</Button>
					</ActionWrapper>
				</Root>
			</div>
		</>
	);
};
