import { FC, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Itinerary } from '@mopla/data-models';

import { FlexColumn } from '../../atoms/FlexContainer/FlexColumn';
import { Text } from '../../atoms/Text/Text';
import { SearchResultSkeleton } from '../../molecules/SearchResultSkeleton/SearchResultSkeleton';
import { RouteSuggestion } from '../RouteSuggestion/RouteSuggestion';
import { SearchSuggestionsList } from '../SearchSuggestionsList/SearchSuggestionsList';

import styles from './styles.module.scss';

interface ICheaperRidesSuggestProps {
	rideDetailsBlock: ReactElement;
	actualRide: Itinerary;
	cheaperRides: Itinerary[] | null;
	loadCheaperRides(): void;
	onRideClick(itinerary: Itinerary): void;
	isLoading: boolean;
}

export const CheaperRidesSuggest: FC<ICheaperRidesSuggestProps> = (props) => {
	const {
		cheaperRides,
		actualRide,
		rideDetailsBlock,
		onRideClick,
		loadCheaperRides,
		isLoading,
	} = props;

	const { t } = useTranslation('searchResults');

	useEffect(() => {
		if (!cheaperRides) {
			loadCheaperRides();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isEmptyData = cheaperRides?.length === 0;
	const hasData = (cheaperRides?.length || 0) > 0;

	return (
		<div className={styles['root']} data-testid="searchResult-cheaper-suggest">
			{rideDetailsBlock}
			{cheaperRides && (
				<FlexColumn gap={32}>
					<div>
						<div className={styles['group_title']}>
							<Text variant="body2_bold" color="mopla_primary_dark">
								{t('text.actual_ride')}:
							</Text>
						</div>
						<RouteSuggestion
							onDetailsClick={() => onRideClick(actualRide)}
							details={actualRide}
							showRideDate={false}
							collapsible
							initiallyCollapsed={hasData}
						/>
					</div>
					<div>
						<div className={styles['group_title']}>
							<Text variant="body2_bold">{t('text.cheaper_rides')}:</Text>
						</div>
						{hasData && (
							<SearchSuggestionsList
								routes={cheaperRides}
								onRouteClick={onRideClick}
								showRideDate={false}
							/>
						)}
						{isEmptyData && (
							<div className={styles['wrapper']}>
								<Text variant="body3" color="mopla_dark_grey">
									{t('noResults.no_cheaper_rides')}
								</Text>
							</div>
						)}
					</div>
				</FlexColumn>
			)}
			{isLoading && <SearchResultSkeleton />}
		</div>
	);
};
