import { useEffect, useState } from 'react';
import { RxDocument } from 'rxdb';

import { IActiveVoucher, TActiveVouchersList } from '@mopla/data-models';

import { VoucherActionTypes } from '../actions/voucherActions';
import { useBusinessLayer } from '../business-logic';

export const useActiveVouchers = () => {
	const { db, watchActions } = useBusinessLayer();
	const [isLoading, setIsLoading] = useState(true);
	const [activeVouchers, setActiveVouchers] =
		useState<TActiveVouchersList | null>(null);

	useEffect(() => {
		const sub = db['vouchers']
			.find()
			.$.subscribe((val: Array<RxDocument<IActiveVoucher>>) => {
				if (val.length) {
					setActiveVouchers(val.map((doc) => doc.toJSON()));
				} else {
					setActiveVouchers(null);
				}
			});

		return () => {
			sub.unsubscribe();
		};
	}, [db]);

	useEffect(() => {
		let isActive = true;

		watchActions({
			types: [
				VoucherActionTypes.FetchVouchers,
				VoucherActionTypes.FetchVouchersResult,
			],
			callback: (action) => {
				switch (action.type) {
					case VoucherActionTypes.FetchVouchers:
						setIsLoading(true);
						break;
					case VoucherActionTypes.FetchVouchersResult:
						setIsLoading(false);
						break;
				}
			},
			count: () => isActive,
		});

		return () => {
			isActive = false;
		};
	}, [watchActions]);

	return {
		activeVouchers,
		isLoading,
	};
};
