import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
	BusinessLayerContext,
	saveUserLanguageAction,
} from '@mopla/business-logic';
import { useBreakpoints } from '@mopla/utils';

import { Button } from '../../atoms/Button/Button';
import { CheckmarkIcon } from '../../icons';
import {
	Footer,
	MainWithFooterTemplate,
} from '../../molecules/MainWithFooterTemplate';
import { ProfileGenericButton } from '../../molecules/ProfileGenericButton/ProfileGenericButton';

import {
	ContentWrapper,
	LanguageAvatar,
	StepTitle,
} from './ProfileManager.styles';

export interface LanguageSelectScreenProps {
	onSubmit: () => void;
	app: 'DRIVER' | 'PASSENGER';
}

export const LanguageSelectScreen: React.FC<LanguageSelectScreenProps> = ({
	app,
	onSubmit,
}) => {
	const { t } = useTranslation(['profile', 'common']);
	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
	const isDriver = app === 'DRIVER';
	const businessLayer = useContext(BusinessLayerContext);
	const { isMobile } = useBreakpoints();

	const applyLanguage = () => {
		i18n
			.changeLanguage(selectedLanguage)
			.then(() => {
				businessLayer.dispatch(
					saveUserLanguageAction({ language: selectedLanguage })
				);
			})
			.catch((err) => console.error(err));
		onSubmit();
	};

	return (
		<MainWithFooterTemplate
			content={
				<ContentWrapper>
					<StepTitle>{t('title.language')}</StepTitle>
					<ProfileGenericButton
						onClick={() => setSelectedLanguage('de')}
						mainIcon={
							<LanguageAvatar
								sx={{ bgcolor: isDriver ? '#ECEEF8' : '#FFFFFF' }}
								children="DE"
							/>
						}
						label={t('common:language.de')}
						helperIcon={<CheckmarkIcon />}
						showChevron={selectedLanguage === 'de'}
						variant={isDriver ? 'white' : 'grey'}
					/>
					<ProfileGenericButton
						onClick={() => setSelectedLanguage('en')}
						mainIcon={
							<LanguageAvatar
								sx={{ bgcolor: isDriver ? '#ECEEF8' : '#FFFFFF' }}
								children="ENG"
							/>
						}
						label={t('common:language.en')}
						helperIcon={<CheckmarkIcon />}
						showChevron={selectedLanguage === 'en'}
						variant={isDriver ? 'white' : 'grey'}
					/>
					{/*<ProfileGenericButton
				onClick={() => setSelectedLanguage('fr')}
				mainIcon={
					<LanguageAvatar
						sx={{ bgcolor: isDriver ? '#ECEEF8' : '#FFFFFF' }}
						children="FR"
					/>
				}
				label={t('common:language.fr')}
				helperIcon={<CheckmarkIcon />}
				showChevron={selectedLanguage === 'fr'}
				variant={isDriver ? 'white' : 'grey'}
			/>*/}
					{
						<ProfileGenericButton
							onClick={() => setSelectedLanguage('pl_PL')}
							mainIcon={
								<LanguageAvatar
									sx={{ bgcolor: isDriver ? '#ECEEF8' : '#FFFFFF' }}
									children="PL"
								/>
							}
							label={t('common:language.pl')}
							helperIcon={<CheckmarkIcon />}
							showChevron={selectedLanguage === 'pl_PL'}
							variant={isDriver ? 'white' : 'grey'}
						/>
					}
					<ProfileGenericButton
						onClick={() => setSelectedLanguage('cs_CZ')}
						mainIcon={
							<LanguageAvatar
								sx={{ bgcolor: isDriver ? '#ECEEF8' : '#FFFFFF' }}
								children="CS"
							/>
						}
						label={t('common:language.cs')}
						helperIcon={<CheckmarkIcon />}
						showChevron={selectedLanguage === 'cs_CZ'}
						variant={isDriver ? 'white' : 'grey'}
					/>
					<ProfileGenericButton
						onClick={() => setSelectedLanguage('uk_UA')}
						mainIcon={
							<LanguageAvatar
								sx={{ bgcolor: isDriver ? '#ECEEF8' : '#FFFFFF' }}
								children="UA"
							/>
						}
						label={t('common:language.uk')}
						helperIcon={<CheckmarkIcon />}
						showChevron={selectedLanguage === 'uk_UA'}
						variant={isDriver ? 'white' : 'grey'}
					/>
					{/*<ProfileGenericButton*/}
					{/*	onClick={() => setSelectedLanguage('tr')}*/}
					{/*	mainIcon={*/}
					{/*		<LanguageAvatar*/}
					{/*			sx={{ bgcolor: isDriver ? '#ECEEF8' : '#FFFFFF' }}*/}
					{/*			children="TR"*/}
					{/*		/>*/}
					{/*	}*/}
					{/*	label={t('common:language.tr')}*/}
					{/*	helperIcon={<CheckmarkIcon />}*/}
					{/*	showChevron={selectedLanguage === 'tr'}*/}
					{/*	variant={isDriver ? 'white' : 'grey'}*/}
					{/*/>*/}
				</ContentWrapper>
			}
			footer={
				selectedLanguage !== i18n.language && (
					<Footer background={isMobile ? 'mopla_primary' : undefined}>
						<Button onClick={applyLanguage} variant="primary">
							{t('button.confirm_language')}
						</Button>
					</Footer>
				)
			}
		/>
	);
};
