import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConvertLatlong } from '@mopla/business-logic';
import { ILeg, TransportType } from '@mopla/data-models';
import { formatTime } from '@mopla/utils';

import { HomeIcon, StationIcon } from '../../icons';
import { DropdownIcon } from '../../molecules/DropdownIcon/DropdownIcon';
import { PaymentBlock, PriceBlock } from '../../molecules/PriceBlock';
import { TravelTypeSymbols } from '../../molecules/TravelTypeSymbols/TravelTypeSymbols';
import {
	StationName,
	StationTime,
	StationWrapper,
	WalkWrapper,
} from '../RouteSuggestion/RouteSuggestion.styles';

import {
	AddressWalkWrapper,
	AgencyName,
	HeaderWrapper,
	HomeAddressWrapper,
	IntermediateStopsWrapper,
	MainContentWrapper,
	MapBlockWrapper,
	PriceWrapper,
	Root,
	SubTitle,
	Title,
	TitleWrapper,
} from './LegDetails.styles';

interface LegDetailsProps {
	legInfo: ILeg;
	isBookedItinerary?: boolean;
	timeMightChange: boolean;
	renderLegMap?(render: (c: ReactElement) => ReactElement): ReactElement;
}

export const LegDetails: FC<LegDetailsProps> = ({
	legInfo,
	isBookedItinerary,
	timeMightChange,
	renderLegMap,
}) => {
	const [openStops, setOpenStops] = useState<boolean>(false);
	const { t } = useTranslation('searchResults');
	const {
		distance,
		duration,
		endDateTime,
		from,
		mode,
		route,
		startDateTime,
		to,
		intermediateStops = [],
	} = legInfo;

	const departureLocation = useConvertLatlong(`${from?.lat},${from?.lng}`);

	const walk = mode === TransportType.WALK;
	const lbodt = mode === TransportType.LBODT;
	const lbodtflex = mode === TransportType.LBODTFLEX;
	const lbt = mode === TransportType.LBT;

	const getTitle = () => {
		if (walk) return t('routeDetails.footpath');
		if (lbodtflex) return `${t('routeDetails.anruf_bus')} ${route || ''}`;
		if (lbodt) return `${t('routeDetails.ruf_bus')} ${route || ''}`;
		return `${t('routeDetails.line_bus')} ${route || ''}`;
	};

	const getDateTime = (date: string) =>
		timeMightChange ? '' : formatTime(new Date(date));

	const walkBeforeDistance = Math.round((distance || 0) / 100) / 10;
	const walkBeforeDuration = Math.round((duration || 0) / 60);
	const walkBeforeString = `${t(
		'common:text.around'
	)} ${walkBeforeDuration} ${t('common:text.minutes')} ${t(
		'common:text.toWalk'
	)} (${walkBeforeDistance} ${t('common:text.km')})`;

	const homeAddress = true;

	const AddressWalk = () => (
		<AddressWalkWrapper homeAddress={homeAddress}>
			<span>{from?.name || departureLocation?.title}</span>
			<span>{walkBeforeString}</span>
		</AddressWalkWrapper>
	);

	return (
		<Root greenLine={lbodt || lbt || lbodtflex}>
			<HeaderWrapper data-testid="search-results-legDetails-header">
				<TravelTypeSymbols
					transportTypes={mode}
					variant={walk ? 'midGrey' : undefined}
					large
				/>
				<TitleWrapper>
					<Title>{getTitle()}</Title>
					{(lbt || lbodt || lbodtflex) && <SubTitle>{to?.name}</SubTitle>}
				</TitleWrapper>
			</HeaderWrapper>
			<MainContentWrapper>
				{(lbt || lbodt || lbodtflex) && (
					<div data-testid="search-results-legDetails-busRoute-block">
						<StationWrapper data-testid="route-firstStation-block">
							<StationTime>{getDateTime(startDateTime || '')}</StationTime>
							<StationIcon />
							<StationName>{from?.name}</StationName>
						</StationWrapper>
						{Boolean(intermediateStops.length) && (
							<IntermediateStopsWrapper
								data-testid="search-results-intermediateStops-dropdown"
								onClick={() => setOpenStops((prev) => !prev)}
							>
								<span>{`${t('common:text.stops', {
									count: intermediateStops.length,
								})}`}</span>
								<DropdownIcon isOpened={openStops} />
							</IntermediateStopsWrapper>
						)}
						{!openStops && (
							<AgencyName variant="caption" color="mopla_dark_grey">
								{legInfo.agencyName}
							</AgencyName>
						)}
						{openStops && (
							<>
								<AgencyName variant="caption" color="mopla_dark_grey">
									{legInfo.agencyName}
								</AgencyName>
								{intermediateStops.map((stop) => (
									<StationWrapper
										data-testid="search-results-intermediateStop-item"
										key={stop.arrival}
										intermediateStop
										marginTop="8px"
									>
										<StationTime>{getDateTime(stop.arrival || '')}</StationTime>
										<StationIcon />
										<StationName>{stop?.name}</StationName>
									</StationWrapper>
								))}
							</>
						)}
						<StationWrapper
							marginTop="24px"
							data-testid="route-lastStation-block"
						>
							<StationTime>{getDateTime(endDateTime || '')}</StationTime>
							<StationIcon />
							<StationName>{to?.name}</StationName>
						</StationWrapper>
						<PriceWrapper>
							{isBookedItinerary ? (
								<PaymentBlock entity={legInfo} single />
							) : (
								<PriceBlock legs={legInfo} />
							)}
						</PriceWrapper>
					</div>
				)}
				{walk && (
					<WalkWrapper data-testid="leg-details-for-walk">
						<span>{getDateTime(startDateTime || '')}</span>
						{homeAddress ? (
							<HomeAddressWrapper>
								<HomeIcon />
								{AddressWalk()}
							</HomeAddressWrapper>
						) : (
							AddressWalk()
						)}
					</WalkWrapper>
				)}
				{renderLegMap &&
					renderLegMap((c) => <MapBlockWrapper>{c}</MapBlockWrapper>)}
			</MainContentWrapper>
		</Root>
	);
};
