import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const Title = styled(Typography)(({ theme }) => ({
	...theme.fonts.body1,
	marginBottom: '24px',
}));

export const Footer = styled('footer')(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const TextFooter = styled(Typography)(({ theme }) => ({
	...theme.fonts.body1,
}));

export const TextFooterLink = styled(Typography)(({ theme }) => ({
	...theme.fonts.body1_semibold,
	color: theme.colors.mopla_secondary,
}));

export const Description = styled(Typography)(({ theme }) => ({
	...theme.fonts.body2,
	color: theme.colors.mopla_dark_grey,
	marginTop: '24px',
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
	marginTop: '40px',
	backgroundColor: theme.colors.mopla_dark_grey,
	width: '100%',
	alignSelf: 'center',
}));

export const SubmitLevel = styled('div')(() => ({
	position: 'absolute',
	bottom: '50px',
	width: 'calc(100% - 48px)',
}));
