/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * state of presence
 */
export enum PresenceStateEnum {
	PRESENT_VALID = 'PRESENT_VALID',
	PRESENT_INVALID = 'PRESENT_INVALID',
	ABSENT = 'ABSENT',
}
