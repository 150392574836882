import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('header')(({ theme }) => ({
	position: 'sticky',
	top: 0,
	zIndex: 999,
	padding: '56px 24px 24px 24px',
	backgroundColor: theme.colors.mopla_secondary,
	borderRadius: '8px',
}));

export const Title = styled(Typography)(({ theme }) => ({
	color: theme.colors.mopla_white,
}));

export const BackButton = styled('button')(({ theme }) => ({
	position: 'absolute',
	top: 14,
	left: 16,
	backgroundColor: 'transparent',
	border: 'none',
	cursor: 'pointer',
	padding: 0,
	color: theme.colors.mopla_white,
}));

export const CloseButton = styled('button')(({ theme }) => ({
	position: 'absolute',
	top: 14,
	right: 14,
	backgroundColor: 'transparent',
	border: 'none',
	cursor: 'pointer',
	padding: 0,
	color: theme.colors.mopla_white,
}));
