type TAppType = 'PASSENGER' | 'DRIVER';

export const useResetPassword = (app: TAppType, baseUrl: string) => {
	return (email: string) => {
		return fetch(`${baseUrl}/api/reset-password`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			mode: 'cors',
			body: JSON.stringify({ email, app }),
		});
	};
};
