import { AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * api instance, which takes care of authentication errors and token refresh
 */
export interface Api {
	get<Res = any>(path: string): Promise<Res>;
	post<Res = any>(path: string, body: any): Promise<Res>;
	put<Res = any>(path: string, body: any): Promise<Res>;
	patch<Res = any>(path: string, body: any): Promise<Res>;
	delete<Res = any>(path: string): Promise<Res>;
	makeRequest<Res = any>(
		path: string,
		config: AxiosRequestConfig
	): Promise<AxiosResponse<Res, any>>;
}

export type TransportModes =
	| 'WALK'
	| 'LBT'
	| 'LBODT'
	| 'LBODTFLEX'
	| 'LBODTDS'
	| 'POOLODT'
	| 'PRODT'
	| 'OTHERS';

export type BookingNotificationType =
	| 'TRIP_IMMUTABLE'
	| 'BREAK_IMMUTABLE'
	| 'CHECK_IN_PASSENGER'
	| 'CHECK_IN_PASSENGER_NO_SHOW'
	| 'CHECK_IN_PASSENGER_PAYMENT_SUCCEEDED'
	| 'CHECK_IN_PASSENGER_PAYMENT_FAILED'
	| 'BOOKING_CANCELED_BY_DISTRIBUTOR'
	| 'BOOKING_CANCELED_BY_PASSENGER';

export type DTicketNotificationType =
	| 'TICKET_RECEIVED'
	| 'SUBSCRIPTION_PAUSE_STARTED'
	| 'SUBSCRIPTION_STILL_PAUSED'
	| 'SUBSCRIPTION_WILL_RESUME'
	| 'SUBSCRIPTION_PAYMENT_FAILED';

export type AllNotificationType =
	| BookingNotificationType
	| DTicketNotificationType;
