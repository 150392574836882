import React from 'react';

export const SwapLocationsIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.2941 15.79L14.5041 16.59V12C14.5041 11.7348 14.3987 11.4804 14.2112 11.2929C14.0237 11.1054 13.7693 11 13.5041 11C13.2389 11 12.9845 11.1054 12.797 11.2929C12.6094 11.4804 12.5041 11.7348 12.5041 12V16.59L11.7141 15.79C11.6209 15.6968 11.5102 15.6228 11.3883 15.5723C11.2665 15.5219 11.136 15.4959 11.0041 15.4959C10.8722 15.4959 10.7417 15.5219 10.6198 15.5723C10.498 15.6228 10.3873 15.6968 10.2941 15.79C10.2009 15.8832 10.1269 15.9939 10.0764 16.1158C10.026 16.2376 10 16.3681 10 16.5C10 16.6319 10.026 16.7624 10.0764 16.8842C10.1269 17.0061 10.2009 17.1168 10.2941 17.21L12.7941 19.71C12.8892 19.801 13.0013 19.8724 13.1241 19.92C13.2438 19.9729 13.3732 20.0002 13.5041 20.0002C13.635 20.0002 13.7644 19.9729 13.8841 19.92C14.0068 19.8724 14.119 19.801 14.2141 19.71L16.7141 17.21C16.9024 17.0217 17.0082 16.7663 17.0082 16.5C17.0082 16.2337 16.9024 15.9783 16.7141 15.79C16.5258 15.6017 16.2704 15.4959 16.0041 15.4959C15.7378 15.4959 15.4824 15.6017 15.2941 15.79Z"
				fill="currentColor"
			/>
			<path
				d="M7.71409 7.21L8.50409 6.41L8.50409 11C8.50409 11.2652 8.60944 11.5196 8.79698 11.7071C8.98452 11.8946 9.23887 12 9.50409 12C9.7693 12 10.0237 11.8946 10.2112 11.7071C10.3987 11.5196 10.5041 11.2652 10.5041 11L10.5041 6.41L11.2941 7.21C11.3873 7.30324 11.498 7.3772 11.6198 7.42766C11.7417 7.47812 11.8722 7.50409 12.0041 7.50409C12.1359 7.50409 12.2665 7.47812 12.3883 7.42766C12.5102 7.3772 12.6208 7.30324 12.7141 7.21C12.8073 7.11676 12.8813 7.00607 12.9317 6.88425C12.9822 6.76243 13.0082 6.63186 13.0082 6.5C13.0082 6.36814 12.9822 6.23757 12.9317 6.11575C12.8813 5.99393 12.8073 5.88324 12.7141 5.79L10.2141 3.29C10.119 3.19896 10.0068 3.12759 9.88409 3.08C9.76439 3.02709 9.63496 2.99977 9.50409 2.99977C9.37322 2.99977 9.24379 3.02709 9.12409 3.08C9.00134 3.12759 8.88919 3.19896 8.79409 3.29L6.29409 5.79C6.10578 5.97831 6 6.2337 6 6.5C6 6.7663 6.10578 7.02169 6.29409 7.21C6.48239 7.3983 6.73779 7.50409 7.00409 7.50409C7.27039 7.50409 7.52578 7.3983 7.71409 7.21Z"
				fill="currentColor"
			/>
		</svg>
	);
};
