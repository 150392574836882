import { TFunction } from 'react-i18next';
import memoizeOne from 'memoize-one';

import { PassengerBooking } from '@mopla/business-logic';
import {
	IItineraryPayment,
	PaymentForm,
	PaymentState,
	TransportType,
} from '@mopla/data-models';
import { formatPrice, getActualPrice } from '@mopla/utils';

interface ISegmentPrice {
	type: string;
	destination: string;
	value: string;
	paymentStatus: string;
	isError?: boolean;
}

export const preparePriceSummarySegments = memoizeOne(
	(ticket: PassengerBooking, t: TFunction) => {
		return (
			(ticket.itinerary?.legs
				.map((leg) => {
					if (leg.mode === TransportType.WALK || !leg.payment) {
						return;
					}

					const formattedPrice = getPriceSegmentSummary(leg.payment, t);

					if (!formattedPrice) {
						return;
					}

					const segment: ISegmentPrice = {
						type: t(`common:travelTypeName.${leg.mode}`),
						destination: leg.to.name,
						value: formattedPrice,
						paymentStatus: getPaymentStatus(leg.payment, t),
						isError: leg.payment.paymentState === PaymentState.FAILED,
					};

					return segment;
				})
				.filter(Boolean) as ISegmentPrice[]) || []
		);
	}
);

export function getPaymentStatus(
	payment: IItineraryPayment | null,
	t: TFunction
) {
	if (!payment) {
		return '';
	}

	if (payment.paymentForm === PaymentForm.NOT_NEEDED) {
		return t('booking:payment.status_summary.NOT_NEEDED');
	}

	return t(`booking:payment.status_summary.${payment.paymentState}`, {
		context: payment.paymentForm,
	});
}

function getPriceSegmentSummary(price: IItineraryPayment, t: TFunction) {
	/** There should be either paymentInformationAvailable+paymentState or paymentInformationAvailable===false  */

	if (!price.paymentInformationAvailable) {
		return t('booking:button.price_according_to_tariff');
	}

	const formattedPrice = formatPrice(getActualPrice(price) / 100);

	if (price.paymentState) {
		const paymentStatus = getPaymentStatus(price, t);
		return `${paymentStatus}\n(${formattedPrice})`;
	}

	/** Probably not the real case */
	return formattedPrice;
}
