export const launchSchema = {
	title: 'launch schema',
	version: 0,
	primaryKey: 'id',
	type: 'object',
	properties: {
		wasLaunched: {
			type: 'boolean',
		},
	},
	required: ['id'],
};
