import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Passenger } from '@mopla/data-models';

import { FlexContainer } from '../../atoms/FlexContainer/FlexContainer';
import { Text } from '../../atoms/Text/Text';

export type IPassengerRowProps = {
	index?: number;
} & (
	| {
			passenger: Passenger;
			toddler?: never | false;
	  }
	| {
			passenger?: never;
			toddler: true;
	  }
);

export const PassengerRow: FC<IPassengerRowProps> = ({
	passenger,
	index = 0,
	toddler,
}) => {
	const { t } = useTranslation('booking');

	const getRowTitle = () => {
		if (passenger?.isMainPassenger) {
			return `${passenger.firstName} ${passenger.lastName}`;
		}

		return t('text.attendee_order', { count: index + 1 });
	};

	const getDiscountState = () => {
		if (toddler) {
			return t('text.toddlers_tariff');
		}

		return t(`text.${passenger.discountState}`);
	};

	return (
		<FlexContainer className="space-between" key={`row-${index}`}>
			<Text variant="body2_bold" color="mopla_secondary_dark">
				{getRowTitle()}
			</Text>
			<Text variant="caption" color="mopla_secondary_dark">
				{getDiscountState()}
			</Text>
		</FlexContainer>
	);
};
