import React from 'react';

export const PaypalIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 16 16"
			width={24}
			height={24}
		>
			<path
				fill="#253B80"
				d="M12.9 3.84c.2.06.37.14.54.23.2-1.25 0-2.1-.67-2.86C12.02.36 10.69 0 8.97 0H4a.71.71 0 00-.7.6L1.23 13.73c-.04.26.16.5.42.5h3.07l.77-4.89-.03.15a.7.7 0 01.7-.6h1.46c2.87 0 5.11-1.17 5.77-4.53l.05-.3a3.55 3.55 0 00-.54-.22z"
			></path>
			<path
				fill="#222D65"
				d="M12.75 3.8a5.16 5.16 0 00-.63-.15 8.1 8.1 0 00-1.29-.1h-3.9a.62.62 0 00-.61.53l-.83 5.25-.03.16a.7.7 0 01.7-.6h1.46c2.87 0 5.11-1.17 5.77-4.53l.05-.3a3.5 3.5 0 00-.69-.27z"
			></path>
			<path
				fill="#179BD7"
				d="M13.44 4.07a8.8 8.8 0 01-.05.29c-.66 3.36-2.9 4.53-5.77 4.53H6.16a.7.7 0 00-.7.6l-.74 4.74-.21 1.34a.37.37 0 00.36.43h2.6a.62.62 0 00.6-.53l.03-.13.49-3.09.03-.17a.62.62 0 01.62-.52h.38c2.51 0 4.47-1.02 5.05-3.97.24-1.23.11-2.26-.52-2.98a2.48 2.48 0 00-.71-.55z"
			></path>
		</svg>
	);
};
