import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from '@mui/material';

import { DiscountState } from '@mopla/data-models';
import { Button, FlexColumn, ImpairedCardAnim, Modal } from '@mopla/ui';

import { useBookingContext } from './bookingContext';
import { ModalText, ModalTitle } from './BookingFlow.styles';

export const ImpairedPersonSuggest: FC = () => {
	const { t } = useTranslation('booking');
	const {
		setState,
		setPriceInfo,
		flowStepsMethods: { updateSteps, goToNextStep },
		state,
	} = useBookingContext();

	const onConfirm = useCallback(() => {
		setState('isImpairedPersonRequirement', false);
		setPriceInfo(DiscountState.IMPAIRED, 'attendees2.0');
		updateSteps();
		goToNextStep();
	}, [goToNextStep, setPriceInfo, setState, updateSteps]);

	const onCancel = useCallback(() => {
		setPriceInfo(null, 'main');
		setState('isImpairedPersonRequirement', false);
	}, [setPriceInfo, setState]);

	return (
		<Portal
			key={'ImpairedPersonSuggest_portal'}
			container={document.getElementById('portal')}
		>
			<Modal
				key={'ImpairedPersonSuggest_modal'}
				open={state.isImpairedPersonRequirement}
				onClose={() => null}
				animationElement={<ImpairedCardAnim />}
				mainContent={
					<>
						<ModalTitle>{t('modal.impaired_required.title')}</ModalTitle>
						<ModalText>{t('modal.impaired_required.text')}</ModalText>
					</>
				}
				footerComp={
					<FlexColumn gap={8}>
						<Button variant="primary" type="button" onClick={onConfirm}>
							{t('modal.impaired_required.confirm')}
						</Button>
						<Button variant="text" type="button" onClick={onCancel}>
							{t('modal.impaired_required.cancel')}
						</Button>
					</FlexColumn>
				}
			/>
		</Portal>
	);
};
