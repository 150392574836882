import React, { createContext, useContext, useState } from 'react';

export type Notification = {
	id: number;
	type: 'ERROR' | 'INFO' | 'SUCCESS';
	message: string;
};

interface NotificationsContext {
	notifications: Notification[];
	setNotification: (n: Omit<Notification, 'id'>) => void;
}
export const NotificationsContext = createContext<NotificationsContext>({
	notifications: [],
	setNotification: () => undefined,
});

export const NotificationsProvider: React.FC<
	React.HTMLAttributes<HTMLDivElement>
> = ({ children }) => {
	const [notifications, setNotifications] = useState<Notification[]>([]);

	const setNotification = (notification: Omit<Notification, 'id'>) => {
		const newNotification: Notification = {
			...notification,
			id: Date.now(),
		};
		setNotifications([...notifications, newNotification]);

		setTimeout(() => {
			setNotifications((prev) =>
				prev.filter((n) => n.id !== newNotification.id)
			);
		}, 5000);
	};

	const contextValue = {
		notifications: notifications,
		setNotification: setNotification,
	};

	return (
		<NotificationsContext.Provider value={contextValue}>
			{children}
		</NotificationsContext.Provider>
	);
};

export const useNotification = () => {
	const store = useContext(NotificationsContext);
	const hookData = [store.notifications, store.setNotification] as const;
	return hookData;
};
