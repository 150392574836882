import { ISubscriptionTicket, TSubscription } from '@mopla/data-models';

import { useSubscription, useSubscriptionActions } from './useSubscription';
import {
	useSubscriptionTicketActions,
	useSubscriptionTickets,
} from './useSubscriptionTickets';

interface DTicketSubscriptionResult {
	isTicketsLoading: boolean;
	isSubscriptionLoading: boolean;
	isSubscriptionPauseLoading: boolean;
	isSubscriptionResumeLoading: boolean;
	isSubscriptionCancelLoading: boolean;
	subscription: TSubscription;
	tickets: ISubscriptionTicket[];
	refetchSubscription(): void;
	refetchTickets(): void;
}

export const useDTicketSubscription = (): DTicketSubscriptionResult => {
	const {
		isSubscriptionPauseLoading,
		isSubscriptionResumeLoading,
		isSubscriptionCancelLoading,
		isLoading: isSubscriptionLoading,
		subscriptionData: subscription,
	} = useSubscription();
	const { fetchSubscriptions: refetchSubscription } = useSubscriptionActions();

	const { isLoading: isTicketsLoading, subscriptionTicketsData: tickets } =
		useSubscriptionTickets();
	const { fetchTickets: refetchTickets } = useSubscriptionTicketActions();

	return {
		isTicketsLoading,
		isSubscriptionLoading,
		isSubscriptionPauseLoading,
		isSubscriptionResumeLoading,
		isSubscriptionCancelLoading,
		tickets,
		subscription,
		refetchSubscription,
		refetchTickets,
	};
};
