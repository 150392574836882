import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ILatLng, ILeg } from '@mopla/data-models';
import { checkIsLBODTFlexLeg } from '@mopla/utils';

import { RouteMap, Text } from '../../../';

export interface ILegMapProps {
	leg: ILeg;
	isTicket?: boolean;
	render(c: ReactElement): ReactElement;
}

export const LegMap: FC<ILegMapProps> = ({ leg, isTicket, render }) => {
	const isLBODTFlexLeg = checkIsLBODTFlexLeg(leg);
	const { t } = useTranslation('booking');

	const isStartEndDateFix = leg.endDateTimeFix && leg.startDateTimeFix;
	const shouldShowMap = isLBODTFlexLeg && (isStartEndDateFix || !isTicket);

	if (!shouldShowMap) {
		return null;
	}

	const legWaypoints: ILatLng[] = [
		{ lat: +leg.from.lat, lng: +leg.from.lng },
		{ lat: +leg.to.lat, lng: +leg.to.lng },
	];

	return render(
		<>
			<Text variant="subtitle1">{t('text.map')}</Text>
			<RouteMap waypoints={legWaypoints} showRoute={false} />
		</>
	);
};
