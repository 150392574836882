import dayjs from 'dayjs';

export const getPausedMonthsList = (start: string, end: string) => {
	const pauseStart = dayjs(start, 'YYYY-MM-DD');
	const pauseEnd = dayjs(end, 'YYYY-MM-DD');
	const currentMonth = dayjs().startOf('month');

	/* display the current month as the first available to resume a subscription, in case the pause started before the current month  */
	const firstMonthToResume =
		pauseStart.diff(currentMonth, 'month') > 0 ? pauseStart : currentMonth;

	const scheduledPauseMonths = [firstMonthToResume];

	const differenceInMonths = pauseEnd.diff(firstMonthToResume, 'months');
	if (differenceInMonths === 0) return scheduledPauseMonths;
	if (differenceInMonths === 1) {
		scheduledPauseMonths.push(
			firstMonthToResume.add(1, 'month').startOf('month')
		);
	} else {
		scheduledPauseMonths.push(
			firstMonthToResume.add(1, 'month').startOf('month'),
			firstMonthToResume.add(2, 'month').startOf('month')
		);
	}
	return scheduledPauseMonths;
};
