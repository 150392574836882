import React from 'react';

export const CurrentlocationIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.4814 13.8115L10.4915 13.953L11.4814 13.8115ZM10.6329 12.963L10.7743 11.9731L10.6329 12.963ZM12.246 19.1637L11.2561 19.3051L12.246 19.1637ZM19.053 6.68383L18.1196 6.32485L19.053 6.68383ZM17.4017 4.45815L4.70424 9.34178L5.4222 11.2085L18.1196 6.32484L17.4017 4.45815ZM15.1027 19.7402L19.9863 7.0428L18.1196 6.32485L13.236 19.0223L15.1027 19.7402ZM13.236 19.0223L12.4714 13.6701L10.4915 13.953L11.2561 19.3051L13.236 19.0223ZM10.7743 11.9731L5.42219 11.2085L5.13935 13.1884L10.4915 13.953L10.7743 11.9731ZM12.4714 13.6701C12.3457 12.7902 11.6543 12.0988 10.7743 11.9731L10.4915 13.953L12.4714 13.6701ZM13.236 19.0223V19.0223L11.2561 19.3051C11.5477 21.346 14.3626 21.6644 15.1027 19.7402L13.236 19.0223ZM4.70424 9.34178C2.78003 10.0819 3.09842 12.8968 5.13935 13.1884L5.42219 11.2085L5.4222 11.2085L4.70424 9.34178ZM18.1196 6.32484L18.1196 6.32485L19.9863 7.0428C20.6084 5.42533 19.0191 3.83604 17.4017 4.45815L18.1196 6.32484Z"
				fill="currentColor"
			/>
		</svg>
	);
};
