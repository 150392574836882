import React from 'react';
export const FacebookIcon = () => {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_49_2868)">
				<path
					d="M24 12.5456C24 5.92159 18.624 0.545593 12 0.545593C5.376 0.545593 0 5.92159 0 12.5456C0 18.3536 4.128 23.1896 9.6 24.3056V16.1456H7.2V12.5456H9.6V9.54559C9.6 7.22959 11.484 5.34559 13.8 5.34559H16.8V8.94559H14.4C13.74 8.94559 13.2 9.48559 13.2 10.1456V12.5456H16.8V16.1456H13.2V24.4856C19.26 23.8856 24 18.7736 24 12.5456Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_49_2868">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(0 0.545593)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
