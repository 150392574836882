import React from 'react';
import { styled } from '@mui/material/styles';

import { Button } from '../../atoms/Button/Button';

type ISocialButtonProps = {
	name: 'google' | 'apple' | 'facebook' | 'microsoft';
	onClick: VoidFunction;
	icon: React.ReactNode;
};

export const Social = styled(Button)(({ theme }) => ({
	width: 40,
	marginRight: 16,
	background: theme.colors.mopla_white,
	border: `2px solid ${theme.colors.mopla_white}`,
	'&:disabled': {
		border: `2px solid ${theme.colors.mopla_mid_grey}`,
	},
}));

export const SocialButton: React.FC<ISocialButtonProps> = (props) => {
	const { icon, name, onClick } = props;

	return (
		<Social
			data-testid={`login-with-${name}-button`}
			variant="outlined"
			onClick={onClick}
			// TODO: remove 'disabled' when social reg will be available
			disabled
		>
			{icon}
		</Social>
	);
};
