import React from 'react';

export const SpecialNeedsIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19 8C19 9.10457 18.1046 10 17 10C15.8954 10 15 9.10457 15 8C15 6.89543 15.8954 6 17 6C18.1046 6 19 6.89543 19 8ZM21 8C21 10.2091 19.2091 12 17 12C15.1362 12 13.5701 10.7252 13.126 9H3V7H13.126C13.5701 5.27477 15.1362 4 17 4C19.2091 4 21 5.79086 21 8ZM10.874 15C10.4299 13.2748 8.86384 12 7 12C4.79086 12 3 13.7909 3 16C3 18.2091 4.79086 20 7 20C8.86384 20 10.4299 18.7252 10.874 17L21 17V15L10.874 15ZM5 16C5 14.8954 5.89543 14 7 14C8.10457 14 9 14.8954 9 16C9 17.1046 8.10457 18 7 18C5.89543 18 5 17.1046 5 16Z"
				fill="#1C308B"
			/>
		</svg>
	);
};
