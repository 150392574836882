import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

export const ModalText = styled('span')(({ theme }) => ({
	...theme.fonts.body1,
	marginBottom: 24,
	color: theme.colors.mopla_black,
}));

export const ModalTitle = styled('span')(({ theme }) => ({
	...theme.fonts.H6,
	marginTop: 40,
	marginBottom: 24,
}));

export const ItemWrapper = styled('div', {
	shouldForwardProp: (prop) =>
		!['marginTop', 'marginBottom', 'disabled'].includes(prop as any),
})<{ marginTop?: string; marginBottom?: string; disabled?: boolean }>(
	({ marginTop, marginBottom, disabled }) => ({
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr',
		alignItems: 'center',
		gridGap: 10,
		marginTop: marginTop || 0,
		marginBottom: marginBottom || 0,

		label: {
			marginBottom: '1px',
			opacity: disabled ? 0.4 : 1,
		},
	})
);

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
	padding: 0,
	color: theme.palette.secondary.main,
}));
