import React, { MouseEventHandler } from 'react';
import { Trans } from 'react-i18next';
import { Browser } from '@capacitor/browser';

import { LinkText } from './TextWithLink.styles';

interface IProps {
	i18nKey: string;
	url: string;
	values?: Record<string, string>;
}

export const TextWithLink: React.FC<IProps> = (props) => {
	const { i18nKey, values, url } = props;
	const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		Browser.open({ url });
	};
	return (
		<Trans
			i18nKey={i18nKey}
			values={values}
			components={{
				a: <LinkText onClick={handleClick} />,
			}}
		/>
	);
};
