import { DeepReadonlyObject } from 'rxdb';

import { DateValue } from '../api';

export interface IAlternateInvoiceAddress {
	city?: string;
	company?: string;
	country?: string;
	firstName?: string;
	lastName?: string;
	street?: string;
	streetNumber?: string;
	zipCode?: string;
}

interface MetadataField {
	jobticketLastName: string;
	jobticketFirstName: string;
	awaitingJobTicketEmployeeDeletion: boolean;
}

export interface IWhoamiMutable {
	/**
	 * mopla user ID, not firebase ID
	 */
	userId?: string;
	email?: string;
	name?: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
	dateOfBirth?: DateValue;
	address?: {
		street?: string;
		streetNumber?: string;
		zipCode?: string;
		city?: string;
		country?: string | null;
	};
	alternateInvoiceAddress: IAlternateInvoiceAddress | null;
	isInMoplaArea: boolean;
	isEligibleForJobticket: boolean;
	isBlacklisted?: boolean;
	//indicates, wether the user data set is completed. if true, user shall be asked for more details
	pleaseComplete?: boolean;
	isStripeCustomerConnected: boolean;
	hasDefaultStripePaymentMethod: boolean;
	isDriverLicenceCheckNeeded?: boolean;
	//permissions due to roles of user (see PrivilegeEnum for list of possible strings)
	privileges: string[];
	metaData: MetadataField;
	language: string | null;
	isDisabled: boolean;
	disabilityStatusValidTo?: string | null;
}

export type IWhoami = DeepReadonlyObject<IWhoamiMutable>;
