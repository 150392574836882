import { styled } from '@mui/material/styles';

import Button from '@mopla/ui/atoms/Button/Button';

export const Root = styled('div')(({ theme }) => ({
	height: 'calc(100% - var(--mopla-safe-area-top))',
	width: '100%',
	maxWidth: 600,
	margin: 'auto',
	padding:
		'calc( 24px + var(--mopla-safe-area-top)) 24px calc(24px + var(--mopla-safe-area-bottom)) 24px',
	boxSizing: 'border-box',
	display: 'grid',
	gridGap: 24,
	gridTemplateRows: 'max-content max-content 1fr max-content',
	'span:first-of-type': {
		marginTop: 40,
		marginBottom: 8,
	},
	backgroundColor: theme.colors.mopla_secondary,
	borderRadius: '8px 8px 0 0',
	overflow: 'auto',
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
	marginTop: 12,
	background: theme.colors.mopla_white,
	color: theme.colors.mopla_secondary,
}));
