/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimeframePriceDefinition } from '../models/TimeframePriceDefinition';
import type { ZoneTimeframe } from '../models/ZoneTimeframe';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimeframesService {
	/**
	 * Returns a list of lbodtflex zones with list of timeframes.
	 * Returns all lbodtflex zones with list of timeframes assigned to distributor, requires distributor role.
	 * @returns any A JSON array of lbodtflex zones with list of timeframes
	 * @throws ApiError
	 */
	public static getApiTimeframes(): CancelablePromise<
		Array<{
			/**
			 * unique identification
			 */
			uuid?: string;
			/**
			 * description of zone
			 */
			label?: string;
			/**
			 * Array of timeframes
			 */
			timeframes?: Array<ZoneTimeframe>;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/timeframes',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Returns a timeframe.
	 * Returns detail view for a specific timeframe. Is correctly returned only if belongs to given distributor (user)
	 * @param uuid uuid of object
	 * @returns any A JSON array of lbodtflex zones with list of timeframes
	 * @throws ApiError
	 */
	public static getApiTimeframes1(uuid: string): CancelablePromise<
		ZoneTimeframe & {
			noDiscount?: TimeframePriceDefinition;
			nonDiscountedTicketPresent?: TimeframePriceDefinition;
			personalDiscountAvailable?: TimeframePriceDefinition;
			discountedTicketPresent?: TimeframePriceDefinition;
		}
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/timeframes/{uuid}',
			path: {
				uuid: uuid,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}
}
