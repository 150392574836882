import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
	SubscriptionActionTypes,
	useBusinessLayer,
	useRedeemVoucherForm,
	VoucherActionTypes,
} from '@mopla/business-logic';
import { AttentionIcon, Button, Input, Loader, Text } from '@mopla/ui';

import { ContentBlock } from './steps/ContentBlock';
import { Wrapper } from './DTicketSubscription.styles';

export const VoucherForm: FC = () => {
	const { t } = useTranslation(['dticket']);
	const bl = useBusinessLayer();
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * If the form is successfully submitted AND is still being rendered,
	 * it means that the voucher is added to the account, but currently is not valid (for the month user has picked).
	 * Otherwise, VoucherForm component shouldn't be rendered.
	 * */
	const onSuccessHandler = useCallback(() => {
		bl.watchActions({
			types: SubscriptionActionTypes.FetchSubscriptionOfferingResult,
			callback: () => {
				setError(t('validation:voucher.months_mismatch'));
			},
		});
	}, [bl, t]);

	const { validationError, onSubmit, isSubmitDisabled, control } =
		useRedeemVoucherForm({ onRedeemSuccess: onSuccessHandler });

	useEffect(() => {
		let isActive = true;

		/** Show loading indicator from the start (which is the RedeemVoucher action)
		 * till the end (which is the FetchSubscriptionOfferingResult) */
		bl.watchActions({
			types: [
				VoucherActionTypes.RedeemVoucher,
				VoucherActionTypes.RedeemVoucherResult,
				SubscriptionActionTypes.FetchSubscriptionOfferingResult,
			],
			callback: (action) => {
				switch (action.type) {
					case VoucherActionTypes.RedeemVoucher:
						setIsLoading(true);
						break;
					case VoucherActionTypes.RedeemVoucherResult:
						if (action.payload?.error) {
							setIsLoading(false);
						}
						break;
					case SubscriptionActionTypes.FetchSubscriptionOfferingResult:
						setIsLoading(false);
						break;
				}
			},
			count: () => isActive,
		});

		return () => {
			isActive = false;
		};
	}, [bl]);

	const isError = Boolean(validationError || error);

	return (
		<ContentBlock headerText={t('header.voucher_title')}>
			<Wrapper as="form" onSubmit={onSubmit}>
				<Text variant="body1">{t('text.enter_code')}</Text>
				<Controller
					name="code"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							ref={null}
							label={`${t('label.enter_code')}`}
							helperText={
								isError && (
									<>
										<AttentionIcon width={18} height={18} />
										<Text variant="caption">{validationError || error}</Text>
									</>
								)
							}
							error={isError}
						/>
					)}
				/>
				<Button
					type="submit"
					variant="outlined"
					color="info"
					disabled={isSubmitDisabled}
				>
					{isLoading ? <Loader style={{ height: 24 }} /> : t('button.redeem')}
				</Button>
			</Wrapper>
		</ContentBlock>
	);
};
