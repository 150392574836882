import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	margin: '16px 0 32px',
	backgroundColor: theme.colors.mopla_white,
	padding: 16,
	borderRadius: 8,
}));

export const PassengersTable = styled('div')(() => ({
	display: 'grid',
	gridGap: 8,
	gridTemplateColumns: '1fr',
	alignItems: 'center',
	// TODO: remove paddingRight when price is shown
	// paddingRight: 60,
	// TODO: return when price is shown
	// gridTemplateColumns: '/* 2fr 1fr */ 1fr max-content',
}));

export const LineWrapper = styled('div')(({ theme }) => ({
	marginTop: 8,
	marginBottom: 12,
	border: `1px solid ${theme.colors.mopla_mid_grey}`,
}));
