import { FC, ReactElement } from 'react';

import { MainWithFooterTemplate, SearchHeader } from '@mopla/ui';
import SideOverlay from '@mopla/ui/organisms/SideOverlay/SideOverlay';
import { useBreakpoints } from '@mopla/utils';

import { DrawerRoot } from './DTicketSubscription.styles';

interface IProps {
	open: boolean;
	onClose: VoidFunction;
	onBack?: VoidFunction;
	title: string;
	isConfirmationStep: boolean;
	content: ReactElement;
	footer: ReactElement;
}

export const DTicketSubscriptionWrapper: FC<IProps> = (props) => {
	const { open, onClose, onBack, title, isConfirmationStep, content, footer } =
		props;
	const { isAboveMobile } = useBreakpoints();

	const child = <MainWithFooterTemplate content={content} footer={footer} />;

	if (isAboveMobile) {
		return (
			<SideOverlay
				open={open}
				title={title}
				onOpen={() => undefined}
				onBack={onBack}
				onClose={onClose}
				widthPercentage={50}
			>
				{child}
			</SideOverlay>
		);
	}

	return (
		<DrawerRoot
			anchor="bottom"
			open={open}
			onClose={onClose}
			isConfirmationStep={isConfirmationStep}
		>
			<SearchHeader
				variant="secondary"
				title={title}
				onBack={onBack}
				open
				showBack={!isConfirmationStep}
				showClose={isConfirmationStep}
				onClose={onClose}
			/>
			{child}
		</DrawerRoot>
	);
};
