import React from 'react';
import { IconButton } from '@mui/material';

import { LogoIcon, MenuIcon, ProfileCircleIcon } from '../../';
import { Responsive } from '../../molecules/Responsive';

import { RootDesktop, RootMobile } from './passengerHeader.styles';

interface HeaderProps {
	onMenuClick?: () => void;
	onProfileClick?: () => void;
	variant:
		| 'mopla_white'
		| 'mopla_secondary'
		| 'mopla_primary'
		| 'mopla_secondary_dark';
}

const PassengerHeader: React.FC<HeaderProps> = ({
	onMenuClick,
	onProfileClick,
	variant,
}) => {
	return (
		<>
			<Responsive aboveMobile>
				<RootDesktop>
					<LogoIcon variant={variant} />
					<IconButton onClick={onProfileClick}>
						<ProfileCircleIcon />
					</IconButton>
				</RootDesktop>
			</Responsive>
			<Responsive mobile>
				<RootMobile variant={variant}>
					<IconButton onClick={onMenuClick}>
						<MenuIcon />
					</IconButton>
					<LogoIcon variant={variant} />
				</RootMobile>
			</Responsive>
		</>
	);
};

export default PassengerHeader;
