import React from 'react';

export const WalkIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13 7C14.1046 7 15 6.10457 15 5C15 3.89543 14.1046 3 13 3C11.8954 3 11 3.89543 11 5C11 6.10457 11.8954 7 13 7ZM11.8356 8.01361C11.8899 8.00455 11.9449 8 12 8H12.7476H14C14.3147 8 14.6111 8.14819 14.8 8.4L16.087 10.116L17.7425 10.5299C18.2783 10.6638 18.6041 11.2067 18.4701 11.7425C18.3362 12.2783 17.7932 12.6041 17.2574 12.4701L15.2574 11.9701C15.0346 11.9144 14.8378 11.7837 14.7 11.6L14.3886 11.1848L13.4677 14.2546L14.6402 15.2318C14.8032 15.3676 14.9187 15.5517 14.9702 15.7575L15.9702 19.7575C16.1041 20.2933 15.7784 20.8362 15.2426 20.9701C14.7068 21.1041 14.1638 20.7783 14.0299 20.2425L14.0299 20.2425L13.1091 16.5592L10.3599 14.2682L10.3598 14.2682C10.0723 14.0286 9.94266 13.6484 10.0239 13.2831L10.7023 10.2301L9.66667 10.4027L8.8944 11.9472C8.64741 12.4412 8.04674 12.6414 7.55276 12.3944C7.05878 12.1474 6.85856 11.5468 7.10555 11.0528L8.10555 9.05279C8.24863 8.76663 8.51999 8.5662 8.83558 8.51361L11.8356 8.01361ZM11.9673 16.9095C12.0789 17.0025 12.1683 17.1181 12.2301 17.2479L10.9231 20.3846C10.7107 20.8944 10.1252 21.1355 9.61541 20.9231C9.10561 20.7107 8.86453 20.1252 9.07695 19.6154L10.6588 15.819L11.9673 16.9095Z"
				fill="currentColor"
			/>
		</svg>
	);
};
