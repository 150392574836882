import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'grid',
	placeContent: 'center',
	placeItems: 'center',
	gridGap: 32,
	width: '100%',
	height: '100%',
	background: theme.colors.mopla_white,
}));
