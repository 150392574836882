import React, { FC } from 'react';

import { NamedLocation } from '@mopla/data-models';

import { HomeIcon, StationIcon } from '../../icons';

export interface IRidePointIconProps {
	pointType?: NamedLocation.type;
}

export const RidePointIcon: FC<IRidePointIconProps> = ({ pointType }) => {
	if (pointType === NamedLocation.type.STOP) {
		return <StationIcon />;
	}

	return <HomeIcon />;
};
