import { FC } from 'react';

export const PriceStringSeparator: FC = () => {
	return (
		<>
			{' '}
			+
			<br />
		</>
	);
};
