import { styled } from '@mui/material/styles';

export const Root = styled('div')<{ disabled: boolean }>(
	({ theme, disabled }) => {
		return {
			display: 'grid',
			gridTemplateColumns: 'max-content minmax(100px, 1fr) max-content',
			alignItems: 'center',
			borderBottom: `1px solid ${theme.colors.mopla_mid_grey}`,
			gridGap: 8,
			width: '100%',
			height: '100%',
			padding: '16px 0px',
			boxSizing: 'border-box',
			color: disabled ? theme.colors.mopla_grey : theme.colors.mopla_black,
		};
	}
);

export const LocationText = styled('span')<{ disabled: boolean }>(
	({ theme, disabled }) => ({
		...theme.fonts.body1,
		color: disabled ? theme.colors.mopla_grey : theme.colors.mopla_black,
		cursor: 'pointer',
		'&:focus-visible': {
			outline: `2px solid ${theme.colors.mopla_secondary}`,
		},
	})
);

export const StarButton = styled('button')(({ theme }) => ({
	color: theme.colors.mopla_primary,
	background: 'transparent',
	height: 24,
	width: 24,
	display: 'grid',
	placeContent: 'center',
	border: 'none',
	outline: 'none',
	cursor: 'pointer',
	'&:focus-visible': {
		outline: `2px solid ${theme.colors.mopla_secondary}`,
	},
}));
