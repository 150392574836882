import React from 'react';

export const VoucherIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M4 10l.83-.83a4 4 0 01-.83 6.3V17h16v-1.54a4 4 0 01-.83-6.29L20 10l-.83-.83a4 4 0 01.83-.63V7H4v1.54a4 4 0 01.83.63L4 10zm-2 0a2 2 0 110 4v3c0 1.1.9 2 2 2h16a2 2 0 002-2v-3a2 2 0 010-4V7a2 2 0 00-2-2H4a2 2 0 00-2 2v3z"
			clipRule="evenodd"
		></path>
		<path stroke="currentColor" strokeLinecap="round" d="M17 9l-9 6"></path>
		<circle
			cx="10"
			cy="10"
			r="1.5"
			fill="#D9D9D9"
			stroke="currentColor"
		></circle>
		<circle
			cx="15"
			cy="14"
			r="1.5"
			fill="#D9D9D9"
			stroke="currentColor"
		></circle>
	</svg>
);
