import { styled } from '@mui/material/styles';

import { Text } from '@mopla/ui';

export const Header = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content',
	boxSizing: 'border-box',
	gap: 24,
	alignItems: 'center',
}));

export const Content = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '60% 1fr',
	boxSizing: 'border-box',
	gap: 8,
	marginTop: 8,
	alignItems: 'center',
}));

export const NonSelectableText = styled(Text)(() => ({
	userSelect: 'none',
}));

export const PaymentTextStyled = styled(NonSelectableText)(() => ({
	textAlign: 'right',
}));
