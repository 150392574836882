import { IInvoiceRecipientFormValues, ISubscriptionFormValues } from './types';

export const defaultValues: ISubscriptionFormValues = {
	location: '',
	firstName: '',
	monthOfStart: '',
	phoneNumber: '',
	street: '',
	streetNumber: '',
	surname: '',
	postCode: '',
	year: '',
	month: '',
	day: '',
};

export const invoiceRecipientDefaultValues: IInvoiceRecipientFormValues = {
	location: '',
	firstName: '',
	company: '',
	street: '',
	streetNumber: '',
	surname: '',
	postCode: '',
};

export const MONTH_SELECTION_STEP = 'month';
export const PERSONAL_DATA_STEP = 'personalData';
export const PAYMENT_METHOD_STEP = 'payment';
export const SUMMARY_STEP = 'summary';
export const CONFIRMATION_STEP = 'confirmation';

export const BASIC_TICKET_COST = 49;
