import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useUser } from '@mopla/business-logic';
import { useBreakpoints } from '@mopla/utils';

import { PriceForm } from './PriceForm';
import { EFlowSteps, IFlowStep, TBookingFormState } from './types';

export interface IUseFlowStepsProps {
	isLBTFlow: boolean;
	isLBODTFlex: boolean;
}

const indexToAttendeeMap: EFlowSteps[] = [
	EFlowSteps.PriceCalcPassenger1,
	EFlowSteps.PriceCalcPassenger2,
	EFlowSteps.PriceCalcPassenger3,
	EFlowSteps.PriceCalcPassenger4,
	EFlowSteps.PriceCalcPassenger5,
	EFlowSteps.PriceCalcPassenger6,
];

export const useFlowSteps = ({
	isLBTFlow,
	isLBODTFlex,
}: IUseFlowStepsProps) => {
	const { userData } = useUser();
	const { isMobile } = useBreakpoints();
	const { getValues, watch } = useFormContext<TBookingFormState>();
	const buyFor = watch('buyFor');

	const stepsRef = useRef<IFlowStep[]>([
		{
			step: isLBTFlow ? EFlowSteps.Summary : EFlowSteps.SelectPassengers,
			index: 0,
		},
	]);

	const [currentStep, setCurrentStep] = useState<EFlowSteps>(
		stepsRef.current[0].step
	);
	const [stepsCount, setStepsCount] = useState(1);

	const buildSteps = (): IFlowStep[] => {
		const attendees = getValues('attendees2');

		const _steps: Omit<IFlowStep, 'index'>[] = [
			{ step: isLBTFlow ? EFlowSteps.Summary : EFlowSteps.SelectPassengers },
		];

		if (isLBODTFlex) {
			_steps.push({
				step: EFlowSteps.PriceCalcPassengerMain,
				layout: <PriceForm />,
			});

			if (attendees.length > 0) {
				for (let i = 0; i < attendees.length; i++) {
					_steps.push({
						step: indexToAttendeeMap[i],
						layout: <PriceForm />,
						attendeeIndex: i,
						isAttendee: true,
					});
				}
			}
		}

		/**
		 * This condition changes when user add payment method.
		 * Therefore, if he is on this step, we should anyway add this step to the array,
		 * in order to avoid a bug.
		 * */
		if (
			currentStep === EFlowSteps.AddPaymentMethod ||
			(isLBODTFlex &&
				!userData?.hasDefaultStripePaymentMethod &&
				!userData?.privileges.includes('CAN_PAY_BOOKING_IN_VEHICLE'))
		) {
			_steps.push({ step: EFlowSteps.AddPaymentMethod });
		}

		if (!isLBTFlow) {
			_steps.push({ step: EFlowSteps.Summary });
		}

		if (isMobile) {
			_steps.push({ step: EFlowSteps.Thanks });
		}

		/**
		 * EStepEnum.Thanks shouldn't be shown in the desktop title or in the dots.
		 * */
		const _stepsCount = _steps.filter(
			({ step }) => ![EFlowSteps.Thanks].includes(step)
		).length;

		setStepsCount(_stepsCount);

		return _steps.map((step, index) => ({ ...step, index } as IFlowStep));
	};

	const updateSteps = () => {
		stepsRef.current = buildSteps();
	};

	useEffect(() => {
		updateSteps();
	}, [isMobile, isLBTFlow, isLBODTFlex, userData, currentStep, buyFor]);

	const getCurrentStepIndex = () =>
		stepsRef.current.findIndex(({ step }) => step === currentStep);

	const goToNextStep = () => {
		const currentIndex = getCurrentStepIndex();

		if (currentIndex !== -1 && currentIndex < stepsRef.current.length - 1) {
			setCurrentStep(stepsRef.current[currentIndex + 1].step);
		}
	};

	const goToPreviousStep = () => {
		const currentIndex = getCurrentStepIndex();
		if (currentIndex > 0) {
			setCurrentStep(stepsRef.current[currentIndex - 1].step);
			return true;
		}
		return false;
	};

	const currentStepIndex = getCurrentStepIndex();
	const currentStepObject = stepsRef.current[currentStepIndex];

	return {
		goToNextStep,
		goToPreviousStep,
		setCurrentStep,
		updateSteps,
		currentStep: currentStepObject,
		stepsCount,
		stepsRef,
	};
};
