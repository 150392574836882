import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { ECancellationReasons } from '@mopla/data-models';

type TInterceptor = () => unknown | Promise<unknown>;

export interface ICancelSubscriptionHandlerPayload {
	cancellationReason: ECancellationReasons;
	cancellationReasonDescription?: string;
}

export interface IProfileManagerContext {
	backInterceptor?: TInterceptor | null;
	setBackInterceptor(interceptor?: TInterceptor | null): void;

	proceedInterceptor?: TInterceptor | null;
	setProceedInterceptor(interceptor?: TInterceptor | null): void;

	isProceedDisabled?: boolean;
	setProceedDisabled: Dispatch<SetStateAction<boolean>>;

	proceedText?: string | null;
	setProceedText: Dispatch<SetStateAction<string | null>>;

	showErrorModal: boolean;
	setShowErrorModal(shouldShow: boolean): void;

	cancelSubscriptionHandler(
		payload: ICancelSubscriptionHandlerPayload
	): Promise<void>;
}

export const ProfileManagerContext = createContext<IProfileManagerContext>(
	{} as IProfileManagerContext
);

export const useProfileManagerContext = () => useContext(ProfileManagerContext);
