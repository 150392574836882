import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const Title = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginBottom',
})<{ marginBottom?: string }>(({ theme, marginBottom }) => ({
	...theme.fonts.body1,
	marginBottom: marginBottom || 0,
}));
