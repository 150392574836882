/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExportService {
	/**
	 * get csv export for the distributor
	 * request csv export for given distributor user context
	 * @param year year number
	 * @param month month number (1-12)
	 * @returns binary OK
	 * @throws ApiError
	 */
	public static getApiExportCsv(
		year: number,
		month: number
	): CancelablePromise<Blob> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/export/csv/{year}/{month}',
			path: {
				year: year,
				month: month,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}
}
