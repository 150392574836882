export enum WelcomeStep {
	login = 'login',
	registration = 'registration',
	verifyEmail = 'verifyEmail',
	forgotPassword = 'forgotPassword',
	resetPassword = 'resetPassword',
	verifyBeforeStart = 'verifyBeforeStart',
	personalDetails = 'personalDetails',
	tryOurApp = 'tryOurApp',
}
