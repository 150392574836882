import { styled } from '@mui/material/styles';

import { ClockMoneyIcon } from '../../icons';

export const ContentStyled = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center',
	gap: 4,
	flexWrap: 'nowrap',
	textAlign: 'left',
}));

export const ClockMoneyIconStyled = styled(ClockMoneyIcon)(() => ({
	display: 'block',
	flexShrink: 0,
}));
