import React from 'react';

export const ScanIconBig = () => {
	return (
		<svg
			width="219"
			height="222"
			viewBox="0 0 219 222"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="45.3164"
				y="42.7266"
				width="89.0864"
				height="157.116"
				rx="7"
				transform="rotate(-15 45.3164 42.7266)"
				stroke="#0E1C5B"
				strokeWidth="4"
			/>
			<path
				d="M64.542 64.4062L125.56 48.0566"
				stroke="#0E1C5B"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M95.7588 168.395L153.648 152.883"
				stroke="#0E1C5B"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M89.7695 45.9102L106.98 41.2987"
				stroke="#0E1C5B"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<circle
				cx="83.5111"
				cy="47.5867"
				r="1.61975"
				transform="rotate(-15 83.5111 47.5867)"
				fill="#0E1C5B"
			/>
			<rect
				x="118.921"
				y="167.219"
				width="12.958"
				height="4.85926"
				rx="2.42963"
				transform="rotate(-15 118.921 167.219)"
				fill="#0E1C5B"
			/>
			<path
				d="M81.6698 78.2634L70.1192 81.3584C69.5858 81.5013 69.2692 82.0496 69.4121 82.5831L72.5071 94.1337"
				stroke="#0E1C5B"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M136.52 139.026L148.07 135.931C148.604 135.788 148.92 135.239 148.777 134.706L145.682 123.155"
				stroke="#0E1C5B"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M45.3463 23.1999L29.6053 27.4177C25.3376 28.5613 22.8049 32.948 23.9485 37.2157L28.1663 52.9567"
				stroke="#0E1C5B"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M170.498 196.999L186.239 192.781C190.507 191.638 193.04 187.251 191.896 182.984L187.678 167.243"
				stroke="#0E1C5B"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M84.6638 139.507L87.7588 151.058C87.9017 151.591 88.45 151.908 88.9835 151.765L100.534 148.67"
				stroke="#0E1C5B"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M133.525 77.7821L130.43 66.2316C130.287 65.6981 129.738 65.3815 129.205 65.5244L117.654 68.6194"
				stroke="#0E1C5B"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<rect
				x="84.0695"
				y="102.133"
				width="44.9728"
				height="25.5358"
				rx="2"
				transform="rotate(-15 84.0695 102.133)"
				fill="#ECEEF8"
				stroke="#0E1C5B"
				strokeWidth="2"
			/>
			<path
				d="M109.248 106.086L120.2 103.151"
				stroke="#0E1C5B"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M110.925 112.344L121.877 109.409"
				stroke="#0E1C5B"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<ellipse
				cx="100.49"
				cy="110.948"
				rx="6.47901"
				ry="5.66913"
				transform="rotate(-15 100.49 110.948)"
				fill="#0E1C5B"
			/>
		</svg>
	);
};
