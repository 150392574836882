export const ticketsSchema = {
	title: 'ticket schema',
	version: 0,
	primaryKey: 'id',
	type: 'object',
	properties: {
		id: {
			type: 'string',
			maxLength: 300,
		},
		ticketId: {
			type: ['string', 'null'],
		},
		validFrom: {
			type: 'string',
		},
		validTo: {
			type: 'string',
		},
		type: {
			type: 'string',
		},
		firstName: {
			type: 'string',
		},
		lastName: {
			type: 'string',
		},
	},
};
