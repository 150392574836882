import { TLocationSuggestType } from '@mopla/data-models';

interface IMaybeWithCategory {
	categories?: Array<{
		id: string;
		name?: string;
		primary?: true;
	}>;
}

const STOP_CATEGORY_ID = '400-4100-0042';

export function getLocationCategory<T extends IMaybeWithCategory>(
	obj: T,
	defaultValue: TLocationSuggestType
): TLocationSuggestType;
export function getLocationCategory<T extends IMaybeWithCategory>(
	obj: T,
	defaultValue?: null
): TLocationSuggestType | null;
export function getLocationCategory<T extends IMaybeWithCategory>(
	obj: T,
	defaultValue: TLocationSuggestType | null = null
): TLocationSuggestType | null {
	let category: TLocationSuggestType | null = defaultValue;

	const mainCategory = obj.categories?.find((cat: any) => cat.primary);

	if (mainCategory && mainCategory.id === STOP_CATEGORY_ID) {
		category = 'stop';
	}

	return category;
}
