import { FC, useRef } from 'react';

import { useMap } from '@mopla/business-logic';
import { ILatLng } from '@mopla/data-models';

import { Map, MapWrapper } from './RouteMap.styles';

interface IRouteMapProps {
	showRoute: boolean;
	waypoints: ILatLng[];
}

export const RouteMap: FC<IRouteMapProps> = (props) => {
	const { showRoute, waypoints } = props;
	const mapRef = useRef<null | HTMLDivElement>(null);

	useMap({
		waypoints,
		ref: mapRef.current,
		deps: [],
		showRoute,
	});

	return (
		<MapWrapper
			data-testid="route-card-map"
			onTouchStart={(e) => e.stopPropagation()}
			onTouchMove={(e) => e.stopPropagation()}
			onTouchEnd={(e) => e.stopPropagation()}
			onMouseDown={(e) => e.stopPropagation()}
			onMouseMoveCapture={(e) => e.stopPropagation()}
			onMouseUp={(e) => e.stopPropagation()}
			onMouseLeave={(e) => e.stopPropagation()}
		>
			<Map ref={mapRef} />
		</MapWrapper>
	);
};
