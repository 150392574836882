import React from 'react';

export const CheckmarkIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.8839 6.77124L18.7049 8.59227L10.0684 17.2288L5.29492 12.4553L7.11595 10.6342L10.0684 13.5857L16.8839 6.77124Z"
				fill="currentColor"
			/>
		</svg>
	);
};
