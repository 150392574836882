import { useCallback, useContext, useEffect, useState } from 'react';
import { MangoQuery, RxDocument } from 'rxdb';

import { ILocationSuggestStored } from '@mopla/data-models';

import { addLocation } from '../actions/locationActions';
import { BusinessLayerContext } from '../business-logic';

export const useLocations = (query?: MangoQuery) => {
	const businessLayer = useContext(BusinessLayerContext);

	const [locations, setLocations] = useState<ILocationSuggestStored[]>([]);

	useEffect(() => {
		const liveQuery = businessLayer.db['location'].find(query).$;

		const subscription = liveQuery.subscribe((resultSet: RxDocument[]) => {
			setLocations(resultSet.map((r) => r.toJSON() as ILocationSuggestStored));
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [query, businessLayer.db]);

	const add = useCallback(
		(item: ILocationSuggestStored) => {
			if (businessLayer.dispatch) {
				businessLayer.dispatch(addLocation(item));
			}
		},
		[businessLayer.dispatch]
	);

	return {
		locations: locations,
		addLocation: add,
	};
};
