import { styled } from '@mui/material/styles';

export const Text = styled('div')(({ theme }) => ({
	...theme.fonts.body2,
	color: theme.colors.mopla_secondary_dark,
	marginBottom: 32,
}));

export const ModalTitle = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: 32,

	'& > span': {
		...theme.fonts.H6,
		color: theme.colors.mopla_secondary_dark,
	},
}));

export const SuccessModalContent = styled('div')(({ theme }) => ({
	'& > span:first-of-type': {
		...theme.fonts.body1_semibold,
		color: theme.colors.mopla_secondary_dark,
	},
}));

export const UploadedDate = styled('div')(({ theme }) => ({
	color: theme.palette.primary.dark,
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateColumns: 'max-content',
	padding: 16,
	borderRadius: 8,
	backgroundColor: theme.colors.mopla_white,
	marginTop: 24,
	marginBottom: 24,

	'& > span': {
		...theme.fonts.body2_semibold,
		color: theme.colors.mopla_secondary_dark,
		marginLeft: 4,
	},
}));
