import { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box,Portal } from '@mui/material';

import { Button, Checkbox,ImpairedCardAnim, Modal } from '@mopla/ui';
import { localStorageManager } from '@mopla/utils';

import { useBookingContext } from './bookingContext';
import { ModalText, ModalTitle } from './BookingFlow.styles';

export const impairedIDReminderManager = localStorageManager<boolean>(
	'BookingFlow__impaired_id_reminder'
);

export const ImpairedCardReminder: FC = () => {
	const { t } = useTranslation('booking');
	const [notShowAgain, setNotShowAgain] = useState(false);

	const { state, setState } = useBookingContext();

	const onConfirm = useCallback(() => {
		setState('isImpairedCardReminder', false);

		if (notShowAgain) {
			impairedIDReminderManager.set(true);
		}
	}, [notShowAgain]);

	const toggleNotShownAgain = useCallback(() => setNotShowAgain((p) => !p), []);

	return (
		<Portal
			key={'ImpairedCardReminder_portal'}
			container={document.getElementById('portal')}
		>
			<Modal
				key={'ImpairedCardReminder_modal'}
				open={state.isImpairedCardReminder}
				onClose={() => null}
				animationElement={<ImpairedCardAnim />}
				mainContent={
					<>
						<ModalTitle>{t('modal.impaired_card_reminder.title')}</ModalTitle>
						<ModalText>
							<Trans
								i18nKey="booking:modal.impaired_card_reminder.text"
								components={{ b: <strong /> }}
							/>
						</ModalText>
						<Box mb={3}>
							<Checkbox
								checked={notShowAgain}
								onChange={toggleNotShownAgain}
								label={t('modal.impaired_card_reminder.not_show_again')}
							/>
						</Box>
					</>
				}
				footerComp={
					<Button variant="primary" type="button" onClick={onConfirm}>
						{t('modal.impaired_card_reminder.ok')}
					</Button>
				}
			/>
		</Portal>
	);
};
