import React, { ReactElement, useEffect, useState } from 'react';

import {
	ContentBlock,
	FooterBlock,
	HeaderBlock,
	Root,
	ScannerBlock,
	SliderBackDrop,
	SliderCard,
} from './DriverHome.styles';

/* eslint-disable-next-line */
export interface DriverHomeProps {
	headerComponent: ReactElement;
	contentComponent: ReactElement;
	footerComponent: ReactElement;
	sliderContent: ReactElement;
	scanner: ReactElement;
	sliderIsOpen: boolean;
	withTransparency: boolean;
}

export const DriverHomeLayout: React.FC<DriverHomeProps> = ({
	headerComponent,
	contentComponent,
	footerComponent,
	sliderContent,
	sliderIsOpen,
	withTransparency,
	scanner,
}) => {
	const [layoutIsHidden, setLayoutIsHidden] = useState(false);

	useEffect(() => {
		let tId: NodeJS.Timeout | null = null;

		if (sliderIsOpen === true && withTransparency) {
			if (!tId) {
				tId = setTimeout(() => {
					setLayoutIsHidden(true);
				}, 300);
			}
		}

		if (sliderIsOpen === false) {
			setLayoutIsHidden(false);
		}

		return () => {
			if (tId) {
				clearTimeout(tId);
			}
		};
	}, [sliderIsOpen, withTransparency]);

	return (
		<Root hidden={layoutIsHidden}>
			<HeaderBlock>{headerComponent}</HeaderBlock>
			<ContentBlock>{contentComponent}</ContentBlock>
			<FooterBlock>{footerComponent}</FooterBlock>
			<SliderCard open={sliderIsOpen}>{sliderContent}</SliderCard>
			<SliderBackDrop open={layoutIsHidden} />
			<ScannerBlock>{layoutIsHidden && scanner}</ScannerBlock>
		</Root>
	);
};

export default DriverHomeLayout;
