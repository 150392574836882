import { FC } from 'react';

export const PausedSubscription: FC = () => {
	return (
		<svg
			width="350"
			height="314"
			viewBox="0 0 350 314"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M215.836 93.1079C215.658 93.1079 215.478 93.0606 215.315 92.961C214.844 92.6734 214.695 92.0577 214.983 91.5865C219.676 83.9102 218.3 78.3579 216.856 74.8492L216.424 73.812C214.719 69.7491 211.544 62.1861 211.728 56.7959C211.938 50.6411 218.522 46.4224 218.803 46.2456C219.267 45.9532 219.886 46.0909 220.181 46.5581C220.475 47.0244 220.336 47.6421 219.87 47.937C219.81 47.9751 213.9 51.7749 213.726 56.8643C213.558 61.816 216.739 69.3955 218.267 73.0381L218.706 74.0879C219.633 76.3418 222.453 83.1997 216.69 92.6294C216.502 92.938 216.173 93.1079 215.836 93.1079Z"
				fill="#2C4194"
			/>
			<path
				d="M240.837 117.201C240.659 117.201 240.479 117.153 240.316 117.054C239.845 116.766 239.696 116.15 239.984 115.679C244.677 108.002 243.301 102.45 241.856 98.9417L241.421 97.8973C239.717 93.8358 236.545 86.2752 236.729 80.8885C236.939 74.7342 243.523 70.515 243.804 70.3382C244.268 70.0462 244.887 70.1834 245.182 70.6507C245.476 71.117 245.337 71.7347 244.871 72.0296C244.811 72.0677 238.901 75.868 238.727 80.9568C238.559 85.9056 241.737 93.4822 243.265 97.1238L243.706 98.181C244.634 100.434 247.456 107.291 241.691 116.722C241.503 117.031 241.174 117.201 240.837 117.201Z"
				fill="#2C4194"
			/>
			<path
				d="M306.179 239.692C298.067 239.692 290.933 237.292 288.329 233.186L287.886 232.485L292.097 213.761L294.68 217.834C295.962 219.857 302.389 221.718 309.535 220.025C315.958 218.506 327.021 213.116 328.941 194.08C330.641 177.257 320.52 169.698 314.335 166.69C307.063 163.152 300.853 163.607 300.021 164.406L296.281 170.989L296.336 163.342L296.337 148.695L296.598 148.235C299.089 143.848 309.051 144.314 317.604 147.039C325.815 149.656 352.336 160.884 348.844 195.476C347.565 208.136 343.528 218.416 336.846 226.03C331.359 232.282 324.106 236.616 315.869 238.564C312.632 239.329 309.331 239.692 306.179 239.692ZM292.175 231.643C295.011 234.698 304.351 237.177 314.949 234.671C324.865 232.326 341.937 224.065 344.863 195.074C348.054 163.477 323.876 153.236 316.39 150.85C307.812 148.117 301.755 148.896 300.337 149.939L300.336 160.075C303.436 159.445 307.733 160.055 311.482 161.25C323.096 164.952 334.825 175.626 332.922 194.482C332.172 201.912 328.48 219.655 310.456 223.918C304.445 225.339 298.094 224.69 294.22 222.55L292.175 231.643Z"
				fill="#2C4194"
			/>
			<path
				d="M170.596 281.305C162.605 276.705 159.384 263.906 159.25 263.363L159.228 263.262L152.986 230.638L156.916 229.886L163.146 262.452C163.35 263.24 166.291 274.211 172.592 277.838L170.596 281.305Z"
				fill="#2C4194"
			/>
			<path
				d="M274.073 281.305L272.077 277.838C278.379 274.21 281.319 263.238 281.522 262.452L292.191 206.683C294.824 192.92 296.159 178.814 296.159 164.758V134.963H148.509V157.143H144.509V130.963H300.159V164.758C300.159 179.065 298.801 193.424 296.121 207.435L285.418 263.363C285.285 263.906 282.063 276.705 274.073 281.305Z"
				fill="#2C4194"
			/>
			<path
				d="M300.807 313.85H143.861C134.998 313.85 126.829 309.449 122.008 302.078L121.893 301.878L110.078 278.259H334.591L322.661 302.078C317.839 309.449 309.669 313.85 300.807 313.85ZM125.418 299.983C129.505 306.164 136.391 309.85 143.861 309.85H300.807C308.276 309.85 315.163 306.164 319.252 299.983L328.118 282.259H116.551L125.418 299.983Z"
				fill="#2C4194"
			/>
			<path
				d="M298.268 145.553H145.021V147.553H298.268V145.553Z"
				fill="#2C4194"
			/>
			<path
				d="M189.955 120.213C189.777 120.213 189.598 120.165 189.434 120.066C188.963 119.778 188.814 119.163 189.102 118.691C193.794 111.015 192.418 105.463 190.975 101.955L190.538 100.909C188.835 96.8471 185.663 89.287 185.848 83.9008C186.058 77.7465 192.641 73.5273 192.921 73.3505C193.386 73.058 194.004 73.1962 194.299 73.663C194.594 74.1293 194.454 74.747 193.988 75.0419C193.929 75.08 188.019 78.8803 187.846 83.9692C187.678 88.9174 190.855 96.4941 192.383 100.135L192.824 101.194C193.751 103.447 196.572 110.305 190.809 119.734C190.621 120.043 190.292 120.213 189.955 120.213Z"
				fill="#2C4194"
			/>
			<path
				d="M253.792 132.192H251.792V166.981H253.792V132.192Z"
				fill="#2C4194"
			/>
			<path
				d="M267.413 205.853H238.141V180.012L251.911 165.009H253.643L267.413 180.012V205.853ZM240.141 203.853H265.413V180.791L252.777 167.023L240.141 180.791V203.853Z"
				fill="#2C4194"
			/>
			<path
				d="M145.564 256.305L0 234.488L35.1445 0L71.29 5.41699L70.1592 12.9609C69.1904 19.4302 73.665 25.4829 80.1348 26.4526L129.942 33.9175C133.077 34.3892 136.206 33.6089 138.753 31.7246C141.302 29.8408 142.964 27.0771 143.434 23.9434L144.563 16.3994L180.71 21.8169L164.09 132.705L160.135 132.112L176.161 25.1802L147.927 20.9482L147.389 24.5361C146.761 28.7271 144.539 32.4219 141.132 34.9414C137.724 37.4604 133.544 38.502 129.349 37.8735L79.541 30.4087C70.8906 29.1118 64.9072 21.0186 66.2041 12.3682L66.7412 8.78027L38.5078 4.54883L4.54883 231.125L142.201 251.756L145.5 229.75L149.455 230.343L145.564 256.305Z"
				fill="#2C4194"
			/>
			<path
				d="M36.7998 44.0431C36.751 44.0431 36.7012 44.0397 36.6504 44.0319L30.7168 43.1422C30.1709 43.0607 29.7939 42.5514 29.876 42.005C29.958 41.4596 30.4531 41.0778 31.0137 41.1647L36.9472 42.0543C37.4931 42.1359 37.8701 42.6452 37.7881 43.1915C37.7138 43.6871 37.2871 44.0431 36.7998 44.0431Z"
				fill="#2C4194"
			/>
			<path
				d="M154.039 61.6144C153.99 61.6144 153.94 61.611 153.89 61.6032L142.165 59.8464C141.619 59.7648 141.242 59.2555 141.324 58.7096C141.406 58.1637 141.904 57.7839 142.462 57.8688L154.187 59.6257C154.732 59.7072 155.109 60.2165 155.027 60.7624C154.953 61.2585 154.526 61.6144 154.039 61.6144ZM130.591 58.1003C130.542 58.1003 130.492 58.0968 130.441 58.089L118.718 56.3317C118.172 56.2502 117.795 55.7409 117.877 55.1945C117.959 54.6496 118.454 54.2721 119.015 54.3542L130.738 56.1115C131.284 56.193 131.661 56.7023 131.579 57.2487C131.505 57.7443 131.078 58.1003 130.591 58.1003ZM107.144 54.5861C107.095 54.5861 107.045 54.5827 106.994 54.5749L95.2695 52.8175C94.7236 52.736 94.3467 52.2267 94.4287 51.6803C94.5107 51.1349 95.0146 50.755 95.5664 50.84L107.291 52.5973C107.837 52.6789 108.214 53.1882 108.132 53.7345C108.058 54.2301 107.631 54.5861 107.144 54.5861ZM83.6953 51.0715C83.6465 51.0715 83.5967 51.068 83.5459 51.0602L71.8222 49.3029C71.2763 49.2214 70.8994 48.7121 70.9814 48.1657C71.0635 47.6208 71.5644 47.2433 72.1191 47.3254L83.8428 49.0827C84.3887 49.1642 84.7656 49.6735 84.6836 50.2199C84.6094 50.7155 84.1826 51.0715 83.6953 51.0715ZM60.2471 47.5573C60.1982 47.5573 60.1484 47.5539 60.0976 47.5461L48.374 45.7887C47.8281 45.7072 47.4512 45.1979 47.5332 44.6515C47.6152 44.1061 48.1133 43.7262 48.6709 43.8112L60.3945 45.5685C60.9404 45.6501 61.3174 46.1593 61.2353 46.7057C61.1611 47.2013 60.7344 47.5573 60.2471 47.5573Z"
				fill="#2C4194"
			/>
			<path
				d="M171.697 64.2608C171.648 64.2608 171.598 64.2574 171.548 64.2496L165.614 63.3609C165.068 63.2794 164.692 62.7701 164.773 62.2242C164.855 61.6788 165.355 61.3009 165.911 61.3834L171.844 62.2721C172.39 62.3536 172.766 62.8629 172.685 63.4088C172.611 63.9049 172.184 64.2608 171.697 64.2608Z"
				fill="#2C4194"
			/>
			<path
				d="M131.765 232.737C124.725 232.737 118.103 230.002 113.113 225.033C108.111 220.051 105.349 213.419 105.335 206.359L105.282 180.322C105.253 165.749 117.086 153.868 131.66 153.838L165.943 153.769C165.961 153.769 165.981 153.769 165.999 153.769C173.038 153.769 179.659 156.503 184.648 161.473C189.65 166.455 192.413 173.087 192.427 180.147L192.48 206.184C192.494 213.243 189.758 219.886 184.775 224.889C179.794 229.891 173.161 232.653 166.102 232.667L131.818 232.737C131.801 232.737 131.782 232.737 131.765 232.737ZM165.998 159.769C165.983 159.769 165.97 159.769 165.955 159.769L131.672 159.838C120.406 159.861 111.259 169.045 111.282 180.31L111.335 206.347C111.346 211.804 113.481 216.931 117.348 220.782C121.204 224.623 126.323 226.737 131.765 226.737C131.778 226.737 131.792 226.737 131.807 226.737L166.09 226.667C171.547 226.657 176.674 224.521 180.524 220.654C184.376 216.788 186.49 211.653 186.48 206.196L186.427 180.159C186.416 174.702 184.28 169.575 180.414 165.724C176.558 161.883 171.44 159.769 165.998 159.769Z"
				fill="#2C4194"
			/>
			<path
				d="M139.003 216.203L137.429 216.206C136.133 216.208 135.08 215.16 135.078 213.864L134.995 172.698C134.992 171.402 136.04 170.349 137.336 170.346L138.911 170.343C140.207 170.341 141.259 171.389 141.262 172.685L141.345 213.852C141.347 215.147 140.299 216.2 139.003 216.203Z"
				fill="#2C4194"
			/>
			<path
				d="M137.423 216.706C135.856 216.706 134.581 215.433 134.577 213.865L134.494 172.698C134.492 171.129 135.766 169.85 137.335 169.846L138.91 169.843H138.917C140.482 169.843 141.758 171.116 141.762 172.684L141.845 213.851C141.849 215.419 140.574 216.699 139.004 216.703L137.43 216.706H137.423ZM138.916 170.843H138.912L137.337 170.846C136.319 170.849 135.492 171.679 135.494 172.696L135.577 213.863C135.579 214.88 136.407 215.706 137.423 215.706H137.428L139.002 215.703C139.495 215.702 139.959 215.508 140.307 215.159C140.654 214.81 140.846 214.346 140.845 213.853L140.762 172.686C140.76 171.669 139.932 170.843 138.916 170.843Z"
				fill="#2C4194"
			/>
			<path
				d="M138.758 217.175L137.676 217.177C135.711 217.181 134.109 215.585 134.105 213.62L134.023 172.946C134.019 170.98 135.615 169.378 137.58 169.374L138.662 169.372C140.628 169.368 142.23 170.964 142.234 172.93L142.316 213.603C142.32 215.569 140.724 217.171 138.758 217.175ZM137.584 171.318C136.69 171.319 135.965 172.048 135.966 172.942L136.048 213.616C136.05 214.51 136.779 215.235 137.672 215.233L138.754 215.231C139.648 215.23 140.375 214.501 140.373 213.607L140.291 172.934C140.289 172.04 139.56 171.314 138.666 171.316L137.584 171.318Z"
				fill="#2C4194"
			/>
			<path
				d="M137.67 217.677C135.433 217.677 133.61 215.859 133.605 213.621L133.523 172.947C133.519 170.706 135.338 168.878 137.579 168.874L138.661 168.872C138.664 168.872 138.669 168.872 138.669 168.872C140.907 168.872 142.73 170.69 142.734 172.929L142.816 213.602C142.818 214.686 142.397 215.707 141.631 216.477C140.863 217.248 139.844 217.673 138.76 217.675L137.678 217.677C137.675 217.677 137.67 217.677 137.67 217.677ZM138.669 169.872H138.663L137.581 169.874C135.892 169.877 134.521 171.255 134.523 172.945L134.605 213.619C134.608 215.306 135.983 216.677 137.67 216.677H137.676L138.758 216.675C139.575 216.673 140.344 216.353 140.922 215.771C141.5 215.191 141.818 214.421 141.816 213.604L141.734 172.931C141.732 172.113 141.412 171.345 140.831 170.767C140.252 170.189 139.484 169.872 138.669 169.872ZM137.669 215.733C136.502 215.733 135.552 214.785 135.549 213.617L135.467 172.943C135.464 171.773 136.413 170.82 137.583 170.817L138.665 170.815H138.67C139.233 170.815 139.765 171.035 140.166 171.434C140.567 171.834 140.79 172.367 140.791 172.933L140.873 213.606C140.874 214.172 140.654 214.705 140.254 215.106C139.854 215.508 139.321 215.73 138.756 215.731L137.674 215.733H137.669ZM137.585 171.817C136.967 171.819 136.466 172.323 136.467 172.941L136.549 213.615C136.55 214.232 137.053 214.733 137.669 214.733H137.672L138.754 214.731C139.053 214.73 139.334 214.613 139.546 214.401C139.758 214.188 139.874 213.907 139.873 213.608L139.791 172.935C139.79 172.636 139.673 172.354 139.46 172.143C139.247 171.931 138.997 171.803 138.667 171.815L137.585 171.817Z"
				fill="#2C4194"
			/>
			<path
				d="M160.426 216.159L158.851 216.162C157.555 216.165 156.503 215.117 156.5 213.821L156.417 172.654C156.414 171.358 157.463 170.306 158.759 170.303L160.333 170.3C161.629 170.297 162.682 171.346 162.684 172.641L162.767 213.808C162.77 215.104 161.721 216.156 160.426 216.159Z"
				fill="#2C4194"
			/>
			<path
				d="M158.847 216.663C157.279 216.663 156.004 215.39 156 213.822L155.917 172.655C155.915 171.895 156.21 171.18 156.747 170.641C157.283 170.102 157.997 169.805 158.758 169.803L160.332 169.8H160.337C161.904 169.8 163.181 171.073 163.185 172.641L163.268 213.807C163.27 215.377 161.995 216.656 160.427 216.659L158.852 216.663H158.847ZM160.338 170.8H160.334L158.76 170.803C158.267 170.804 157.803 170.997 157.455 171.347C157.107 171.696 156.916 172.16 156.917 172.653L157 213.82C157.002 214.836 157.83 215.663 158.846 215.663H158.85L160.425 215.659C161.442 215.657 162.27 214.827 162.268 213.809L162.185 172.643C162.183 171.626 161.355 170.8 160.338 170.8Z"
				fill="#2C4194"
			/>
			<path
				d="M160.181 217.131L159.099 217.133C157.134 217.137 155.532 215.542 155.528 213.576L155.446 172.903C155.442 170.937 157.038 169.335 159.003 169.331L160.085 169.329C162.05 169.325 163.652 170.92 163.656 172.886L163.738 213.56C163.742 215.525 162.146 217.127 160.181 217.131ZM159.007 171.274C158.113 171.276 157.387 172.005 157.389 172.899L157.471 213.572C157.473 214.466 158.201 215.192 159.095 215.19L160.177 215.188C161.071 215.186 161.796 214.458 161.795 213.564L161.713 172.89C161.711 171.996 160.983 171.27 160.089 171.272L159.007 171.274Z"
				fill="#2C4194"
			/>
			<path
				d="M159.093 217.634C156.855 217.634 155.032 215.816 155.027 213.578L154.946 172.904C154.943 171.82 155.364 170.798 156.132 170.028C156.898 169.258 157.918 168.833 159.002 168.831L160.084 168.829C160.087 168.829 160.092 168.829 160.092 168.829C161.173 168.829 162.19 169.25 162.959 170.015C163.729 170.782 164.154 171.801 164.156 172.885L164.238 213.559C164.24 214.643 163.819 215.664 163.053 216.434C162.285 217.204 161.266 217.629 160.182 217.631L159.101 217.634C159.098 217.634 159.096 217.634 159.093 217.634ZM160.092 169.829H160.086L159.004 169.831C158.188 169.833 157.419 170.153 156.84 170.734C156.262 171.315 155.944 172.084 155.946 172.901L156.027 213.576C156.03 215.263 157.405 216.634 159.093 216.634H159.099L160.18 216.631C160.997 216.63 161.766 216.309 162.344 215.729C162.922 215.148 163.24 214.378 163.238 213.561L163.156 172.887C163.154 172.07 162.834 171.301 162.253 170.723C161.674 170.146 160.906 169.829 160.092 169.829ZM159.092 215.69C157.925 215.69 156.974 214.742 156.971 213.574L156.89 172.9C156.887 171.73 157.836 170.777 159.006 170.774L160.088 170.772H160.091C161.259 170.772 162.21 171.721 162.213 172.889L162.295 213.563C162.296 214.129 162.076 214.662 161.676 215.063C161.276 215.465 160.744 215.687 160.178 215.688L159.097 215.69H159.092ZM159.008 171.774C158.39 171.776 157.889 172.28 157.89 172.898L157.971 213.572C157.972 214.189 158.475 214.69 159.092 214.69H159.095L160.176 214.688C160.475 214.688 160.756 214.57 160.968 214.357C161.18 214.145 161.296 213.864 161.295 213.565L161.213 172.891C161.212 172.274 160.709 171.772 160.092 171.772H160.09L159.008 171.774Z"
				fill="#2C4194"
			/>
			<path
				d="M146.009 311.565H74.793V313.565H146.009V311.565Z"
				fill="#2C4194"
			/>
		</svg>
	);
};
