import { RxDocument } from 'rxdb';
import { defer } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import {
	loadPassengerBookingResult,
	LoadPassengerBookingsAction,
	PassengerBookingActionTypes,
	savePassengerBookingResult,
	SavePassengerBookingsAction,
} from '../actions/passengerBookingActions';
import { Effect } from '../business-logic';
import { PassengerBooking } from '../hooks/usePassengerBookings';
import { ofType } from '../operators/ofType';

export const savePassengerBookingEffect: Effect<SavePassengerBookingsAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(PassengerBookingActionTypes.SaveBookings),
		concatMap((action: SavePassengerBookingsAction) =>
			defer(async () => {
				try {
					const newBookings = action.payload;
					const prevBookings: RxDocument<PassengerBooking>[] =
						await dependencies.db['passengerBooking'].find().exec();

					const toRemove: string[] = [];
					prevBookings.forEach((prev) => {
						const exists = newBookings.find(
							(leg) => leg.bookingNumber === prev.bookingNumber
						);
						if (!exists) {
							toRemove.push(prev.bookingNumber as string);
						}
					});

					await dependencies.db['passengerBooking'].bulkUpsert(action.payload);
					if (toRemove.length > 0) {
						await dependencies.db['passengerBooking'].bulkRemove(toRemove);
					}

					return savePassengerBookingResult(newBookings);
				} catch (error: any) {
					dependencies.Sentry.captureException(error);
					return savePassengerBookingResult(undefined, error);
				}
			})
		)
	);

export const loadPassengerBookingEffect: Effect<LoadPassengerBookingsAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(PassengerBookingActionTypes.LoadBookings),
		concatMap((action: LoadPassengerBookingsAction) =>
			defer(async () => {
				try {
					const newBookings = await dependencies.api.get<PassengerBooking[]>(
						'/api/passengers/bookings'
					);
					const prevBookings: RxDocument<PassengerBooking>[] =
						await dependencies.db['passengerBooking'].find().exec();

					const toRemove: string[] = [];
					prevBookings.forEach((prev) => {
						const exists = newBookings.find(
							(leg) => leg.bookingNumber === prev.bookingNumber
						);
						if (!exists) {
							toRemove.push(prev.bookingNumber as string);
						}
					});

					await dependencies.db['passengerBooking'].bulkUpsert(newBookings);
					if (toRemove.length > 0) {
						await dependencies.db['passengerBooking'].bulkRemove(toRemove);
					}

					return loadPassengerBookingResult(newBookings);
				} catch (error: any) {
					dependencies.Sentry.captureException(error);
					return loadPassengerBookingResult(undefined, error);
				}
			})
		)
	);
