import React, { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { ISubscriptionOffer, TGermanyTicketType } from '@mopla/data-models';
import {
	CheckboxWithLink,
	FlexColumn,
	IconMessage,
	Text,
	WarnIcon,
} from '@mopla/ui';
import { getIsJobticketByType } from '@mopla/utils';

import { PaymentDetails } from '../../PaymentDetails/PaymentDetails';
import { IInvoiceRecipientFormValues, ISubscriptionFormValues } from '../types';
import { VoucherBlock } from '../Voucher';
import { VoucherForm } from '../VoucherForm';

import { ContentBlock } from './ContentBlock';

type TSummaryStepProps = {
	invoiceData: IInvoiceRecipientFormValues | null;
	showInvoiceRecipientBlock: boolean;
	isAcceptTariffs: boolean;
	setIsAcceptTariffs: Dispatch<SetStateAction<boolean>>;
	isPriceZero?: boolean;
	dTicketType: TGermanyTicketType;
	subscriptionOffer?: ISubscriptionOffer | null;
};

export const SummaryStep: React.FC<TSummaryStepProps> = (props) => {
	const {
		invoiceData,
		showInvoiceRecipientBlock,
		isAcceptTariffs,
		setIsAcceptTariffs,
		isPriceZero = false,
		dTicketType,
		subscriptionOffer,
	} = props;
	const { t } = useTranslation(['dticket']);

	const methods = useFormContext<ISubscriptionFormValues>();
	const { watch } = methods;
	const dticketData = watch();

	const toggleTariffsCheckbox = () => setIsAcceptTariffs((prev) => !prev);
	const isJobticket = getIsJobticketByType(dTicketType);

	return (
		<>
			<SubscriptionInformation
				dticketData={dticketData}
				dTicketType={dTicketType}
			/>
			<SubscriberData dticketData={dticketData} />
			{invoiceData && showInvoiceRecipientBlock && (
				<InvoiceRecipientBlock invoiceData={invoiceData} />
			)}
			{!isPriceZero && (
				<ContentBlock headerText={t('header.payment')}>
					<PaymentDetails />
					<IconMessage
						icon={<WarnIcon />}
						message={t('text.subscription_payment')}
					/>
				</ContentBlock>
			)}
			{subscriptionOffer?.voucher && (
				<VoucherBlock subscriptionOfferVoucher={subscriptionOffer.voucher} />
			)}
			{!isJobticket && !subscriptionOffer?.voucher && <VoucherForm />}
			<ContentBlock>
				<CheckboxWithLink
					url="https://www.mopla.solutions/de/tarifbestimmung-und-beforderungsbedingungen"
					linkText="dticket:text.accept_tariffs"
					checked={isAcceptTariffs}
					toggleCheckbox={toggleTariffsCheckbox}
				/>
			</ContentBlock>
		</>
	);
};

const SubscriptionInformation: React.FC<{
	dticketData: ISubscriptionFormValues;
	dTicketType: TGermanyTicketType;
}> = ({ dticketData, dTicketType }) => {
	const { t } = useTranslation(['dticket']);

	return (
		<ContentBlock
			headerText={t('header.dticket_subscription', { dTicketType })}
		>
			<IconMessage icon={<WarnIcon />} message={t('text.dticket_validation')} />
			<Text variant="body1_semibold">
				{t('dticket:text.subscription_start_date', {
					startDate: dayjs(dticketData.monthOfStart).format('1. MMMM YYYY'),
				})}
			</Text>
		</ContentBlock>
	);
};

const SubscriberData: React.FC<{
	dticketData: ISubscriptionFormValues;
}> = ({ dticketData }) => {
	const { t } = useTranslation(['dticket']);

	return (
		<ContentBlock headerText={t('header.subscriber')}>
			<FlexColumn className="space-between" style={{ gap: 4 }}>
				<Text variant="body1">
					{dticketData.firstName} {dticketData.surname}
				</Text>
				<Text variant="body3" color="mopla_dark_grey">
					{dticketData.phoneNumber}
				</Text>
			</FlexColumn>
		</ContentBlock>
	);
};

const InvoiceRecipientBlock: React.FC<{
	invoiceData: IInvoiceRecipientFormValues;
}> = ({ invoiceData }) => {
	const { t } = useTranslation(['dticket']);

	return (
		<ContentBlock headerText={t('header.invoice_recipient')}>
			<FlexColumn className="space-between" style={{ gap: 4 }}>
				<Text variant="body1">
					{invoiceData.firstName} {invoiceData.surname}
				</Text>
				<FlexColumn className="space-between">
					{invoiceData.company && (
						<Text variant="body3" color="mopla_dark_grey">
							{t('dticket:text.company', {
								value: invoiceData.company,
							})}
						</Text>
					)}
					<Text variant="body3" color="mopla_dark_grey">
						{invoiceData.street} {invoiceData.streetNumber}
					</Text>
					<Text variant="body3" color="mopla_dark_grey">
						{invoiceData.postCode} {invoiceData.location}
					</Text>
				</FlexColumn>
			</FlexColumn>
		</ContentBlock>
	);
};
