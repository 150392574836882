import React from 'react';

import { IWhoami } from '@mopla/data-models';

import { MainWithFooterTemplate } from '../../molecules/MainWithFooterTemplate';
import {
	PersonalDetailsForm,
	PersonalDetailsProps,
} from '../PersonalDetailsForm/PersonalDetailsForm';

import { EditUserWrapper } from './ProfileManager.styles';

type personalDetails = {
	firstName: string;
	lastName: string;
	phone: string;
	dateOfBirth: string | null;
	streetNumber: string | null;
	zipcode: string | null;
	city: string | null;
};

export interface EditUserScreenProps {
	onEdit: (data: personalDetails) => Promise<any>;
	onUserEdited: () => void;
	currentUser?: IWhoami;
	onAbort: (isDirty: boolean) => void;
}

const renderFn: PersonalDetailsProps['render'] = ({ content, footer }) => {
	return (
		<MainWithFooterTemplate
			content={<EditUserWrapper>{content}</EditUserWrapper>}
			footer={footer}
		/>
	);
};

export const EditUserScreen: React.FC<EditUserScreenProps> = ({
	onEdit,
	onUserEdited,
	currentUser,
	onAbort,
}) => {
	return (
		<PersonalDetailsForm
			onEdit={onEdit}
			onUserEdited={onUserEdited}
			isUserProfile={true}
			currentUser={currentUser}
			onAbort={onAbort}
			render={renderFn}
		/>
	);
};
