export const subscriptionsSchema = {
	title: 'subscription schema',
	version: 0,
	primaryKey: 'id',
	type: 'object',
	properties: {
		id: {
			type: 'string',
			maxLength: 300,
		},
		status: {
			type: 'string',
		},
		type: {
			type: 'string',
		},
		subscriptionStart: {
			type: 'string',
		},
		pauseStart: {
			type: 'string',
		},
		pauseEnd: {
			type: 'string',
		},
		canceledAt: {
			type: 'string',
		},
	},
};
