import {
	ESubscriptionStatus,
	ESubscriptionType,
	ISubscription,
} from '../lib/types';

export const activeSubscription: ISubscription = {
	id: '12a178ad-24e4-451b-9fba-6de15883e4c7',
	status: ESubscriptionStatus.ACTIVE,
	type: ESubscriptionType.GERMANYTICKET,
	canceledAt: null,
	pauseStart: null,
	pauseEnd: null,
	subscriptionStart: '2023-11-01',
};
