/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * used in distributor dashboard for better understanding of booking leg state based on assigned booking and scheduled legs
 */
export enum BookingLegStatus {
	NEW = 'NEW',
	PLANNED = 'PLANNED',
	PLANNED_IMMUTABLE = 'PLANNED_IMMUTABLE',
	EXECUTING = 'EXECUTING',
	FINISHED = 'FINISHED',
	CANCELLED_BY_DISTRIBUTOR = 'CANCELLED_BY_DISTRIBUTOR',
	CANCELLED_BY_PASSENGER = 'CANCELLED_BY_PASSENGER',
	CANCELLED_NO_SHOW = 'CANCELLED_NO_SHOW',
}
