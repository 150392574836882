import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'black' | 'secondary_dark' }>(({ theme, variant }) => ({
	position: 'fixed',
	top: 0,
	left: 0,
	zIndex: 99999,
	backgroundColor:
		(variant === 'black' && 'rgba(0,0,0,0.45)') ||
		(variant === 'secondary_dark' && 'rgba(14,28,91,0.8)') ||
		theme.colors.mopla_white,
	width: '100%',
	height: '100%',
}));

export const Content = styled('div', {
	shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
	display: 'flex',
	flexDirection: 'column',
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: backgroundColor ? backgroundColor : theme.colors.mopla_white,
	borderRadius: '8px',
	boxSizing: 'border-box',
	padding: '16px 16px 24px 16px',
	width: 'calc(100% - 16px)',
	maxWidth: 584,
	maxHeight: '100%',
	opacity: 1,
}));

export const Title = styled(Typography)(({ theme }) => ({
	...theme.typography.h3,
	marginBottom: '24px',
}));

export const CloseIcon = styled('div')(({ theme }) => ({
	display: 'grid',
	justifyItems: 'end',
	color: theme.colors.mopla_secondary_dark,
}));

export const AnimWrapper = styled('div')({
	width: '100%',
	borderRadius: 8,
	margin: '32px 0',
});

export const ScrollableModalContent = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	overflow: 'auto',
});
