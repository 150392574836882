import React from 'react';

interface CrossIconProps {
	width?: number;
	height?: number;
}

export const CrossIcon: React.FC<CrossIconProps> = ({
	width = 24,
	height = 24,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.7782 12.3639L18.7279 7.41421L17.3137 6L12.364 10.9497L7.41432 6.00003L6.00011 7.41424L10.9498 12.3639L6 17.3137L7.41421 18.7279L12.364 13.7781L17.3138 18.728L18.728 17.3137L13.7782 12.3639Z"
				fill="currentColor"
			/>
		</svg>
	);
};
