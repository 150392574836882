export const scheduledLegSchema = {
	title: 'scheduledLeg schema',
	version: 1,
	primaryKey: 'scheduledLegId',
	type: 'object',
	properties: {
		scheduledLegId: {
			type: 'string',
			maxLength: 70,
		},
		location: {
			type: 'object',
			properties: {
				name: {
					type: 'string',
				},
				lng: {
					type: 'string',
				},
				lat: {
					type: 'string',
				},
				type: {
					type: 'string',
				},
			},
		},
		from: {
			type: 'object',
			properties: {
				name: {
					type: 'string',
				},
				lng: {
					type: 'string',
				},
				lat: {
					type: 'string',
				},
			},
		},
		to: {
			type: 'object',
			properties: {
				name: {
					type: 'string',
				},
				lng: {
					type: 'string',
				},
				lat: {
					type: 'string',
				},
			},
		},
		start: {
			type: 'string',
		},
		end: {
			type: 'string',
		},
		immutableSince: {
			type: ['string', 'null'],
		},
		serviceTime: {
			type: 'string',
			description:
				'The time that a driver has to stay at the given location in seconds',
		},
		distance: {
			type: 'number',
			description: 'The total distance of the leg.',
		},
		manual: {
			type: 'boolean',
			description: 'Determines if the scheduled leg was created by a human.',
		},
		exitingBookings: {
			type: 'array',
			description:
				"A list of bookings (passengers) that'll exit the drive at the stop.",
			items: { type: 'string' },
		},
		enteringBookings: {
			type: 'array',
			description:
				"A list of bookings (passengers) that'll enter the drive at the start.",
			items: { type: 'string' },
		},
		passengers: {
			type: 'number',
			description:
				"The amount of passengers that'll be transported during this leg.",
		},
		state: {
			type: 'string',
			description:
				'legState is a TEST field not represented in the openapi yet',
			// enum:  NEW | EXECUTING | DONE
		},
		ref: {
			type: 'string',
		},
	},
	required: ['scheduledLegId'],
};
