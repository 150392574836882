import {
	ComponentType,
	ForwardedRef,
	forwardRef,
	useEffect,
	useRef,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useUser } from '@mopla/business-logic';

import {
	BookingFlowProps,
	BookingHandlers,
	BuyFor,
	TBookingFormState,
} from './types';

const BookingValidationSchema = yup.object().shape({
	name: yup.string().required('firstName.required'),
	surname: yup.string().required('surname.required'),
	phoneNumber: yup.string().required('phoneNumber.required'),
});

export const withBookingForm = (
	WrappedComponent: ComponentType<
		BookingFlowProps & { ref: ForwardedRef<BookingHandlers> }
	>
) => {
	return forwardRef<BookingHandlers, BookingFlowProps>(
		function WithBookingFormComponent(props, ref) {
			const { userData } = useUser();
			const onceRef = useRef(false);

			const methods = useForm<TBookingFormState>({
				defaultValues: {
					buyFor: BuyFor.me,
					name: '',
					surname: '',
					phoneNumber: '',
					email: '',
					attendees2: [],
					main: {
						amountToPay: null,
						currency: null,
						discountState: null,
					},
					bulkyLuggage: false,
					toddlerSeats: 0,
				},
				resolver: yupResolver(BookingValidationSchema),
				mode: 'onChange',
			});
			const { setValue } = methods;

			/**
			 * Initially the buyFor value is "me".
			 * When the userData value will be set, fill certain fields accordingly.
			 * */
			useEffect(() => {
				if (userData && !onceRef.current) {
					setValue('name', userData.firstName || '');
					setValue('surname', userData.lastName || '');
					setValue('email', userData.email || '');
					setValue('phoneNumber', userData.phone || '');
					onceRef.current = true;
				}
			}, [setValue, userData]);

			return (
				<FormProvider {...methods}>
					<WrappedComponent ref={ref} {...props} />
				</FormProvider>
			);
		}
	);
};
