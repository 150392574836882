/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coordinate } from '../models/Coordinate';
import type { DateTimeValue } from '../models/DateTimeValue';
import type { NamedLocation } from '../models/NamedLocation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssignmentsService {
	/**
	 * Return assignments.
	 * Returns a list of assignments for a specific day
	 * @param date date for which the assignments should be fetched (time component is ignored)
	 * @returns any A JSON array of assignments
	 * @throws ApiError
	 */
	public static getApiAssignments(date: DateTimeValue): CancelablePromise<
		Array<{
			/**
			 * unique identification
			 */
			uuid?: string;
			date?: string;
			/**
			 * flag that determines, whether given assignment may be change (deleted or updated)
			 */
			canBeChanged?: boolean;
			overwrittenEndTime?: string;
			overwrittenStartTime?: string;
			overwrittenStartLocation?: NamedLocation;
			overwrittenEndLocation?: NamedLocation;
			shift?: {
				/**
				 * unique identification
				 */
				uuid?: string;
				/**
				 * descriptive name of shift
				 */
				name?: string;
				startTime?: string;
				endTime?: string;
				endLocation?: NamedLocation;
				startLocation?: NamedLocation;
			};
			driver?: {
				/**
				 * unique identificator
				 */
				uuid?: string;
				firstName?: string;
				lastName?: string;
			};
			vehicle?: {
				/**
				 * unique identificator
				 */
				uuid?: string;
				/**
				 * description or external ID of vehicle
				 */
				name?: string;
			};
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/assignments',
			query: {
				date: date,
			},
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Return list of drivers.
	 * Returns a list of unassigned drivers for that day
	 * @param date date for which the drivers should be fetched (time component is ignored)
	 * @returns any A JSON array of drivers
	 * @throws ApiError
	 */
	public static getApiAssignmentsAvailableDrivers(
		date: DateTimeValue
	): CancelablePromise<
		Array<{
			/**
			 * unique identificator
			 */
			uuid?: string;
			firstName?: string;
			lastName?: string;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/assignments/availableDrivers',
			query: {
				date: date,
			},
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Return list of vehicles.
	 * Returns a list of unassigned vehicles for that day
	 * @param date date for which the drivers should be fetched (time component is ignored)
	 * @returns any A JSON array of vehicles
	 * @throws ApiError
	 */
	public static getApiAssignmentsAvailableVehicles(
		date: DateTimeValue
	): CancelablePromise<
		Array<{
			/**
			 * unique identificator
			 */
			uuid?: string;
			name?: string;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/assignments/availableVehicles',
			query: {
				date: date,
			},
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Calculate duration to get from given start to given end
	 * Returns calculated duration as number
	 * @param startLat latitude of start
	 * @param startLng longitude of start
	 * @param endLat latitude of end
	 * @param endLng longitude of end
	 * @returns any object containing the duration
	 * @throws ApiError
	 */
	public static getApiAssignmentsCalculateMinimumTime(
		startLat: Coordinate,
		startLng: Coordinate,
		endLat: Coordinate,
		endLng: Coordinate
	): CancelablePromise<{
		duration?: number;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/assignments/calculateMinimumTime',
			query: {
				startLat: startLat,
				startLng: startLng,
				endLat: endLat,
				endLng: endLng,
			},
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}
}
