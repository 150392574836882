import React from 'react';

export const ProfileCircleIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.83877 18.9797C5.4962 17.7575 6.3865 16.7163 7.43904 15.9371C7.75662 15.7019 8.18396 15.7194 8.53004 15.9102C9.55917 16.4773 10.7419 16.8 12 16.8C13.2582 16.8 14.4409 16.4773 15.47 15.9102C15.8161 15.7194 16.2435 15.7019 16.561 15.9371C17.6136 16.7163 18.5039 17.7574 19.1613 18.9797C20.9178 17.1777 22 14.7152 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.7153 3.08218 17.1778 4.83877 18.9797ZM12.0282 24C18.6426 23.9848 24 18.618 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C12.0094 24 12.0188 24 12.0282 24ZM16.8002 9.59985C16.8002 12.2508 14.6511 14.3998 12.0002 14.3998C9.34919 14.3998 7.20015 12.2508 7.20015 9.59985C7.20015 6.94888 9.34919 4.79985 12.0002 4.79985C14.6511 4.79985 16.8002 6.94888 16.8002 9.59985Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default ProfileCircleIcon;
