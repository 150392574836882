import React from 'react';

export const DTicketIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.39062 11.9191C3.45414 11.6716 3.55021 11.4385 3.79566 11.3237C3.9211 11.2649 4.06824 11.223 4.20549 11.223C9.40297 11.2179 14.6008 11.2188 19.7983 11.2188C20.2649 11.2188 20.6007 11.5351 20.608 11.9799C20.6154 12.4196 20.2879 12.7518 19.8251 12.7736C19.7881 12.7752 19.7507 12.7739 19.7134 12.7739C14.5689 12.7739 9.42468 12.7739 4.28017 12.7739C3.85407 12.7739 3.56234 12.5878 3.44393 12.2418C3.42542 12.1877 3.4085 12.1334 3.39062 12.079V11.9194V11.9191Z"
				fill="currentColor"
			/>
			<path
				d="M6.90227 23.0004C6.8129 22.971 6.7197 22.9499 6.6348 22.9109C6.29487 22.7548 6.12284 22.3938 6.20646 22.0238C6.28147 21.6916 6.59809 21.4387 6.95142 21.4383C9.77519 21.4371 12.599 21.4374 15.4227 21.4383C15.8115 21.4383 16.1552 21.7428 16.1907 22.1105C16.2325 22.5409 15.9797 22.8856 15.5616 22.9713C15.537 22.9764 15.514 22.9905 15.4904 23.0004H6.90227Z"
				fill="currentColor"
			/>
			<path
				d="M12.3435 8.6647C14.6148 8.6647 16.8857 8.66406 19.157 8.66502C19.5327 8.66502 19.8589 8.93267 19.9217 9.28538C19.9916 9.67646 19.7803 10.05 19.4101 10.1696C19.306 10.2031 19.1915 10.2178 19.0817 10.2178C14.5978 10.2201 10.114 10.2198 5.63024 10.2201C5.29 10.2201 5.01454 10.0998 4.85687 9.78518C4.71324 9.49803 4.74452 9.21631 4.9389 8.96177C5.09753 8.75424 5.31617 8.66374 5.57789 8.66406C7.8332 8.66534 10.0882 8.6647 12.3435 8.6647Z"
				fill="currentColor"
			/>
			<path
				d="M12.1661 6.10953C14.1022 6.10953 16.038 6.1089 17.9742 6.11017C18.3578 6.11017 18.6847 6.38422 18.7424 6.74588C18.804 7.13281 18.5953 7.49383 18.2276 7.61278C18.1188 7.64796 17.9987 7.66235 17.8835 7.66267C14.0754 7.66523 10.2673 7.66491 6.45889 7.66459C5.85245 7.66459 5.46305 7.18781 5.62775 6.65123C5.72861 6.3225 6.0264 6.10985 6.38962 6.10985C8.3149 6.10953 10.2405 6.10985 12.1658 6.10985L12.1661 6.10953Z"
				fill="currentColor"
			/>
			<path
				d="M12.0516 3.55533C13.8281 3.55533 15.605 3.55469 17.3815 3.55597C17.7575 3.55597 18.0828 3.82905 18.1389 4.18272C18.2028 4.58403 17.9975 4.94026 17.6231 5.06081C17.5239 5.09279 17.415 5.10846 17.3107 5.10846C13.8 5.1107 10.2894 5.11102 6.77844 5.10974C6.12062 5.10974 5.73026 4.49546 6.03923 3.94705C6.19052 3.67844 6.4299 3.55469 6.73791 3.55469C8.50934 3.55565 10.2805 3.55501 12.0519 3.55501L12.0516 3.55533Z"
				fill="currentColor"
			/>
			<path
				d="M10.4805 16.3271C12.2302 16.3271 13.9802 16.3265 15.73 16.3278C16.0964 16.3278 16.4022 16.573 16.481 16.9216C16.5633 17.2839 16.399 17.6468 16.0641 17.7952C15.9419 17.8492 15.798 17.8787 15.6639 17.8787C12.2015 17.8831 8.73902 17.8825 5.27627 17.8815C4.87378 17.8815 4.57918 17.675 4.4729 17.3277C4.37459 17.0066 4.48343 16.6466 4.76973 16.4733C4.90762 16.3898 5.08604 16.3338 5.24658 16.3329C6.99121 16.3233 8.73551 16.3268 10.4801 16.3268L10.4805 16.3271Z"
				fill="currentColor"
			/>
			<path
				d="M13.155 18.8834C14.8674 18.8834 16.5801 18.8834 18.2925 18.8834C18.7664 18.8834 19.1096 19.1994 19.1217 19.6461C19.1335 20.0784 18.7814 20.4241 18.3148 20.4381C18.2883 20.4391 18.2615 20.4381 18.235 20.4381C14.8473 20.4381 11.4595 20.4378 8.07144 20.4391C7.77333 20.4391 7.5116 20.362 7.33414 20.1072C7.15923 19.8558 7.1337 19.5821 7.2735 19.3084C7.41617 19.0296 7.65364 18.8841 7.96898 18.8837C9.69733 18.8821 11.426 18.8831 13.1543 18.8831L13.155 18.8834Z"
				fill="currentColor"
			/>
			<path
				d="M9.37176 13.7735C10.93 13.7735 12.4882 13.7735 14.0464 13.7735C14.522 13.7735 14.8674 14.092 14.875 14.5365C14.8827 14.9794 14.5293 15.3257 14.0522 15.3273C12.9727 15.3311 11.8929 15.3286 10.8135 15.3286C8.78192 15.3286 6.75035 15.3286 4.71879 15.3286C4.3163 15.3286 4.01915 15.137 3.90457 14.809C3.7271 14.2999 4.09352 13.7751 4.63293 13.7745C6.21254 13.7726 7.79183 13.7739 9.37144 13.7739L9.37176 13.7735Z"
				fill="currentColor"
			/>
			<path
				d="M10.3419 1C10.6822 1 11.0227 1 11.363 1C11.8351 1 12.181 1.31913 12.1928 1.76426C12.204 2.18667 11.8542 2.54642 11.3991 2.55249C10.6918 2.56209 9.98414 2.56209 9.27684 2.55249C8.8035 2.5461 8.47922 2.20522 8.48847 1.75242C8.49773 1.31018 8.83319 1.00064 9.30493 1C9.6506 1 9.99627 1 10.3419 1Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default DTicketIcon;
