import { Dispatch, SetStateAction } from 'react';
import { DeepReadonlyObject } from 'rxdb';

import { ProfileSteps } from './profile';

export enum ESubscriptionStatus {
	ACTIVE = 'ACTIVE',
	PREORDERED = 'PREORDERED',
	NEW = 'NEW',
	PAUSED = 'PAUSED',
	CANCELED = 'CANCELED',
	PENDING = 'PENDING',
	INVALID = 'INVALID',
}

export enum ESubscriptionType {
	SPENDIT = 'GERMANYTICKET_SPENDIT',
	MOPLA = 'GERMANYTICKET_JOBTICKET_MOPLA',
	GERMANYTICKET = 'GERMANYTICKET',
	SCHOOL = 'GERMANYTICKET_SCHOOL_STUDENT',
	UNIVERSITY = 'GERMANYTICKET_UNIVERSITY_STUDENT',
}

export type TGermanyTicketType =
	| 'Deutschlandticket'
	| 'Deutschland Jobticket'
	| 'Schüler-Deutschlandticket';

export interface ISubscription {
	id: string;
	pauseStart: string | null;
	pauseEnd: string | null;
	canceledAt: string | null;
	status: ESubscriptionStatus;
	type: ESubscriptionType;
	subscriptionStart: string;
}

export type TSubscription = ISubscription | null;

export interface DTicketProfileManagerProps {
	step: ProfileSteps;
	changeStep: Dispatch<SetStateAction<ProfileSteps>>;
	setInvoiceAddressChanged: Dispatch<SetStateAction<boolean>>;
	staticFirstStep?: boolean;
}

export interface ISubscriptionTicketMutable {
	id: string;
	ticketId: string;
	status: 'VALID' | 'INVALID';
	type: 'GERMANYTICKET';
	validFrom: string;
	validTo: string;
	firstName: string;
	lastName: string;
	aztecCode: string;
}

export type ISubscriptionTicket =
	DeepReadonlyObject<ISubscriptionTicketMutable>;

export type TSubscriptionTickets = ISubscriptionTicket[];

export interface ISubscriptionOffer {
	startDate: string;
	price: number;
	subscriptionType?: string;
	voucher?: ISubscriptionOfferVoucher;
}

export interface ISubscriptionOfferVoucher {
	voucherDeductionAmount: number;
	voucherCode: string;
	voucherShortDescription: string;
	voucherLongDescription: string;
}

export interface ISubscriptionOffering {
	id: string;
	price: number;
	fullPrice: number;
	isJobticket: boolean;
	surchargePercentage: number;
	subscriptionType: ESubscriptionType;
	businessSurchargePercentage: number;
	offers: ISubscriptionOffer[];
}
