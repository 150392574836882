import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { useUser } from '@mopla/business-logic';
import { CheckboxDropdown, Input, Radio, Text } from '@mopla/ui';

import { useBookingContext } from './bookingContext';
import {
	AttendeeCountersWrapper,
	BlockTitle,
	Bold,
	Description,
	FormRoot,
	InfoBox,
	TargetPersonBlock,
} from './BookingFlow.styles';
import { BuyFor, IPassengerPaymentInfo, TBookingFormState } from './types';
import {
	AdditionalAttendeeCounter,
	BulkyLuggageCheckbox,
	ChildrenCounter,
} from './ui';
import {
	ECountUpdateType,
	MAX_ATTENDEES,
	MAX_TODDLERS,
	validateSeatsAmount,
} from './utils';

interface FirstStepProps {
	phone: string;
	toddlersDefinition?: string;
	luggageDefinition?: string;
}

export const FirstBookingStep: FC<FirstStepProps> = ({
	phone,
	toddlersDefinition,
	luggageDefinition,
}) => {
	const { t } = useTranslation('booking');
	const formCtx = useFormContext<TBookingFormState>();
	const { formState, setValue, getValues, watch } = formCtx;
	const { buyFor } = getValues();
	const toddlerSeats = watch('toddlerSeats');
	const attendeesList = watch('attendees2');
	const [morePassengersOpened, setMorePassengersOpened] = useState(
		Boolean(attendeesList.length)
	);
	const { userData } = useUser();
	const {
		flowStepsMethods: { updateSteps },
	} = useBookingContext();

	const seatsIncrementAllowed = validateSeatsAmount(formCtx);

	const updateAttendeesList = (nextTicketCount: number) => {
		const lastAttendeesList = getValues('attendees2');

		const attendeesList: IPassengerPaymentInfo[] = [
			...Array(nextTicketCount),
		].map((_, index) => {
			if (lastAttendeesList[index]?.discountState != null) {
				return lastAttendeesList[index];
			}

			return {
				discountState: null,
				currency: null,
				amountToPay: null,
			};
		});

		setValue('attendees2', attendeesList);
		updateSteps();
	};

	const updateTicketsCount = (type: ECountUpdateType) => {
		const lastAttendees = getValues('attendees2');

		if (type === ECountUpdateType.increase && !seatsIncrementAllowed) {
			return;
		}

		const increment = type === ECountUpdateType.increase ? 1 : -1;
		const nextTicketsCount = Math.min(
			Math.max(lastAttendees.length + increment, 0),
			MAX_ATTENDEES
		);

		if (nextTicketsCount !== lastAttendees.length) {
			updateAttendeesList(nextTicketsCount);
		}
	};

	const updateToddlersCount = (type: ECountUpdateType) => {
		const prevToddlersCount = getValues('toddlerSeats');

		if (type === ECountUpdateType.increase && !seatsIncrementAllowed) {
			return;
		}

		const increment = type === ECountUpdateType.increase ? 1 : -1;
		const nextToddlersCount = Math.min(
			Math.max(prevToddlersCount + increment, 0),
			MAX_TODDLERS
		);

		setValue('toddlerSeats', nextToddlersCount);
	};

	const toggleAttendeesCounter = () => {
		setMorePassengersOpened((prevState) => {
			if (prevState) {
				updateAttendeesList(0);
			}

			return !prevState;
		});
	};

	const setBuyFor = (nextBuyFor: BuyFor) => {
		setValue('buyFor', nextBuyFor);

		if (userData && nextBuyFor === BuyFor.me) {
			setValue('name', userData.firstName || '');
			setValue('surname', userData.lastName || '');
			setValue('email', userData.email || '');
			setValue('phoneNumber', userData.phone || '');
		} else if (nextBuyFor === BuyFor.anotherPerson) {
			setValue('name', '');
			setValue('surname', '');
			setValue('phoneNumber', '');
			setValue('email', '');
		}
	};

	return (
		<>
			<TargetPersonBlock data-testid="booking-targetPerson-block">
				<BlockTitle>{t('text.title')}</BlockTitle>
				<Box my={1.25}>
					<Radio
						testId="booking-forMe-radioButton"
						checked={buyFor === BuyFor.me}
						onChange={() => setBuyFor(BuyFor.me)}
						label={t('text.to_me')}
						name="buyFor"
						value={BuyFor.me}
					/>
				</Box>
				{buyFor === BuyFor.me && (
					<Description
						marginBottom="16px"
						marginLeft="32px"
						data-testid="booking-forMe-passDataToCompany-notice"
					>
						<Trans
							i18nKey="booking:text.to_me_description"
							values={{ phoneNumber: phone }}
							components={{ bold: <Bold /> }}
						/>
					</Description>
				)}
				<Box my={1.25}>
					<Radio
						testId="booking-forAnotherPerson-radioButton"
						checked={buyFor === BuyFor.anotherPerson}
						onChange={() => setBuyFor(BuyFor.anotherPerson)}
						label={t('text.for_another_person')}
						name="buyFor"
						value={BuyFor.anotherPerson}
					/>
				</Box>
				{buyFor === BuyFor.anotherPerson && (
					<FormRoot>
						<Description marginBottom="8px">
							{t('text.for_another_person_description')}
						</Description>
						<Controller
							name="name"
							render={({ field }) => (
								<Input
									data-testid="booking-anotherPerson-personalData-name"
									{...field}
									label={`${t('label.first_name')}*`}
									ref={null}
									helperText={
										Boolean(formState.errors.name?.message) &&
										t(`validation:${formState.errors.name?.message}`)
									}
									error={Boolean(formState.errors.name?.message)}
								/>
							)}
						/>
						<Controller
							name="surname"
							render={({ field }) => (
								<Input
									data-testid="booking-anotherPerson-personalData-surname"
									{...field}
									label={`${t('label.surname')}*`}
									ref={null}
									helperText={
										Boolean(formState.errors.surname?.message) &&
										t(`validation:${formState.errors.surname?.message}`)
									}
									error={Boolean(formState.errors.surname?.message)}
								/>
							)}
						/>
						<Controller
							name="phoneNumber"
							render={({ field }) => (
								<Input
									data-testid="booking-anotherPerson-personalData-phoneNumber"
									{...field}
									label={`${t('label.phone_number')}*`}
									ref={null}
									helperText={
										Boolean(formState.errors.phoneNumber?.message) &&
										t(`validation:${formState.errors.phoneNumber?.message}`)
									}
									error={Boolean(formState.errors.phoneNumber?.message)}
								/>
							)}
						/>
						<Controller
							name="email"
							render={({ field }) => (
								<Input {...field} label={t('label.email')} ref={null} />
							)}
						/>
					</FormRoot>
				)}
			</TargetPersonBlock>
			<InfoBox data-testid="booking-targetPerson-amount-block">
				<CheckboxDropdown
					label={t('text.add_more_passengers')}
					open={morePassengersOpened}
					checkboxTestId="booking-addMorePassengers-checkboox"
					onChange={toggleAttendeesCounter}
				>
					<Text variant="body3" color="mopla_dark_grey">
						{t('text.max_6_passengers')}
					</Text>
					<AttendeeCountersWrapper>
						<AdditionalAttendeeCounter
							onDecrease={() => updateTicketsCount(ECountUpdateType.decrease)}
							onIncrease={() => updateTicketsCount(ECountUpdateType.increase)}
							value={attendeesList.length}
							increaseDisabled={!seatsIncrementAllowed}
							decreaseDisabled={attendeesList.length === 0}
						/>
						<ChildrenCounter
							onDecrease={() => updateToddlersCount(ECountUpdateType.decrease)}
							onIncrease={() => updateToddlersCount(ECountUpdateType.increase)}
							value={toddlerSeats}
							increaseDisabled={
								!seatsIncrementAllowed || toddlerSeats === MAX_TODDLERS
							}
							decreaseDisabled={toddlerSeats === 0}
							toddlersDefinition={toddlersDefinition}
						/>
					</AttendeeCountersWrapper>
				</CheckboxDropdown>
			</InfoBox>
			<InfoBox data-testid="booking-bulkyLuggage-block">
				<Controller
					control={formCtx.control}
					name="bulkyLuggage"
					render={({ field }) => (
						<BulkyLuggageCheckbox
							{...field}
							luggageDefinition={luggageDefinition}
							disabled={!seatsIncrementAllowed && !field.value}
						/>
					)}
				/>
			</InfoBox>
		</>
	);
};
