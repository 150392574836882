/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignmentMasterViewItem } from '../models/AssignmentMasterViewItem';
import type { BookingId } from '../models/BookingId';
import type { BookingLegStatus } from '../models/BookingLegStatus';
import type { DateTimeValue } from '../models/DateTimeValue';
import type { DistributorDetailItem } from '../models/DistributorDetailItem';
import type { DistributorListViewItem } from '../models/DistributorListViewItem';
import type { GtfsTripMasterViewItem } from '../models/GtfsTripMasterViewItem';
import type { NamedLocation } from '../models/NamedLocation';
import type { PassengerDetails } from '../models/PassengerDetails';
import type { ScheduledLegStatus } from '../models/ScheduledLegStatus';
import type { SpecialNeeds } from '../models/SpecialNeeds';
import type { TripType } from '../models/TripType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { IItineraryPayment } from '../models/PriceInformation';

export class DistributorsService {
	/**
	 * get all saved distributors
	 * request saved distributors for given user context
	 * @returns DistributorListViewItem OK
	 * @throws ApiError
	 */
	public static getApiDistributors(): CancelablePromise<
		Array<DistributorListViewItem>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/distributors',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}

	/**
	 * get all saved distributors
	 * request saved distributors for given user context
	 * @param distributorid
	 * @returns DistributorDetailItem OK
	 * @throws ApiError
	 */
	public static getApiDistributors1(
		distributorid: string
	): CancelablePromise<DistributorDetailItem> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/distributors/{distributorid}',
			path: {
				distributorid: distributorid,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}

	/**
	 * upload GTFS zip file for distributor
	 * request saving new GTFS zip file for distributor
	 * @param formData provide zip file
	 * @returns any Upload and cutting in progress
	 * @throws ApiError
	 */
	public static postApiDistributorsGtfsUpload(formData: {
		file?: Blob;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/distributors/gtfs/upload',
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				422: `Upload and cutting currently in progress or GTFS marked as READ_FOR_OTP, so GTFS upload is currently not possible`,
			},
		});
	}

	/**
	 * Load gtfs trip view diff json and respond with status.
	 * If GTFS cutting is in progress, status "PENDING" will be returned. If no gtfs trip diff is present, NO_DIFF will be returned as status
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDistributorsGtfsDiff(): CancelablePromise<{
		status?: 'NO_DIFF' | 'PENDING' | 'DONE';
		gtfsDiff?: {
			/**
			 * List of unchanged gtfs trips
			 */
			unchanged?: Array<GtfsTripMasterViewItem>;
			/**
			 * List of changed gtfs trips
			 */
			changed?: Array<GtfsTripMasterViewItem>;
			/**
			 * List of added gtfs trips
			 */
			added?: Array<GtfsTripMasterViewItem>;
			/**
			 * List of removed gtfs trips
			 */
			removed?: Array<GtfsTripMasterViewItem>;
		};
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/distributors/gtfs/diff',
		});
	}

	/**
	 * get all scheduled legs of the distributor
	 * request scheduled legs for given distributor user context
	 * @param date date for which the assignments should be fetched (time component is ignored)
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDistributorsScheduledTrips(
		date: DateTimeValue
	): CancelablePromise<
		Array<{
			/**
			 * unique identification
			 */
			uuid?: string;
			/**
			 * label of the shift
			 */
			shiftName?: string;
			/**
			 * Firstname + Lastname of driver
			 */
			driverName?: string;
			/**
			 * name of vehicle
			 */
			vehicleName?: string;
			driverRecentLocation?: NamedLocation;
			startTime?: string;
			endTime?: string;
			overwrittenEndTime?: string;
			overwrittenStartTime?: string;
			startLocation?: NamedLocation;
			endLocation?: NamedLocation;
			overwrittenStartLocation?: NamedLocation;
			overwrittenEndLocation?: NamedLocation;
			schedule?: Array<{
				scheduleType?: 'LEG' | 'BREAK';
				/**
				 * The id of the scheduled leg (only present, if scheduleType is LEG)
				 */
				uuid?: string;
				from?: NamedLocation;
				to?: NamedLocation;
				start?: DateTimeValue;
				end?: DateTimeValue;
				/**
				 * (not available atm)
				 */
				serviceType?: string;
				/**
				 * The amount of passengers that'll be transported during this leg.
				 */
				passengers?: number;
				manual?: boolean;
				scheduleState?: ScheduledLegStatus;
				immutable?: boolean;
			}>;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/distributors/scheduledTrips',
			query: {
				date: date,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}

	/**
	 * get details for a scheduled leg
	 * request scheduled leg for given uuid (scheduledLeg uuid)
	 * @param uuid
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDistributorsScheduledTrips1(
		uuid: string
	): CancelablePromise<{
		/**
		 * label of the shift
		 */
		shiftName?: string;
		/**
		 * Firstname + Lastname of driver
		 */
		driverName?: string;
		/**
		 * name of vehicle
		 */
		vehicleName?: string;
		from?: NamedLocation;
		to?: NamedLocation;
		start?: DateTimeValue;
		end?: DateTimeValue;
		/**
		 * (not available atm)
		 */
		serviceType?: string;
		/**
		 * The amount of passengers that'll be transported during this leg.
		 */
		passengers?: number;
		manual?: boolean;
		scheduleState?: ScheduledLegStatus;
		immutable?: boolean;
		/**
		 * flag, that determines, whether given scheduled leg can be ended manually by distributor
		 */
		canBeEndedManually?: boolean;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/distributors/scheduledTrips/{uuid}',
			path: {
				uuid: uuid,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}

	/**
	 * get passenger information for a scheduled leg
	 * request passenger details of scheduled leg for given uuid (scheduledLeg uuid)
	 * @param uuid
	 * @param entering get entering passengers (do not mix with 'exiting' param) <br> leaving both params means 'staying' passengers (not implimented yet)
	 * @param exiting get exiting passengers (do not mix with 'entering' param) <br> leaving both params means 'staying' passengers (not implimented yet)
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDistributorsScheduledTripsPassengers(
		uuid: string,
		entering?: boolean,
		exiting?: boolean
	): CancelablePromise<
		Array<{
			/**
			 * full name of the riding passenger (external passenger if present or booking passenger)
			 */
			passengerName?: string;
			from?: NamedLocation;
			to?: NamedLocation;
			start?: DateTimeValue;
			end?: DateTimeValue;
			serviceType?: string;
			/**
			 * list of passengers riding with that booking
			 */
			passengerList?: Array<PassengerDetails>;
			specialNeeds?: SpecialNeeds;
			passengerEmail?: string;
			/**
			 * phone number
			 */
			passengerPhone?: string;
			paymentInfo?: IItineraryPayment;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/distributors/scheduledTrips/{uuid}/passengers',
			path: {
				uuid: uuid,
			},
			query: {
				entering: entering,
				exiting: exiting,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}

	/**
	 * get all scheduled bookings of the distributor
	 * request scheduled bookings for given distributor user context
	 * @param date date for which the assignments should be fetched (time component is ignored)
	 * @param manualDisposition flag to set logic for view of manual disposition for booking legs
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDistributorsBookedTrips(
		date: DateTimeValue,
		manualDisposition?: boolean
	): CancelablePromise<
		Array<{
			/**
			 * full name of the riding passenger (external passenger if present or booking passenger)
			 */
			bookingPassengerName?: string;
			/**
			 * The id of the booking leg
			 */
			uuid?: string;
			from?: NamedLocation;
			to?: NamedLocation;
			start?: DateTimeValue;
			startTimewindow?: {
				start?: DateTimeValue;
				end?: DateTimeValue;
			};
			end?: DateTimeValue;
			endTimewindow?: {
				start?: DateTimeValue;
				end?: DateTimeValue;
			};
			serviceType?: TripType;
			/**
			 * The amount of passengers
			 */
			passengers?: number;
			bookingState?: BookingLegStatus;
			/**
			 * flag to indicate, if given booking for given booking leg may be canceled
			 */
			canBookingBeCanceled?: boolean;
			/**
			 * flag to indicate, if given booking trip can be set to be scheduled automatically
			 */
			canBeSetToScheduleAutomatically?: boolean;
			/**
			 * flag to confirm, that given booking leg will be carried out outside mo.pla
			 */
			isOutsideMopla?: boolean;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/distributors/bookedTrips',
			query: {
				date: date,
				manualDisposition: manualDisposition,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}

	/**
	 * get all scheduled bookings of the distributor
	 * request scheduled bookings for given distributor user context
	 * @param uuid
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDistributorsBookedTrips1(
		uuid: string
	): CancelablePromise<{
		/**
		 * The id of the booked trip (booking leg)
		 */
		uuid?: string;
		bookingId?: BookingId;
		/**
		 * full name of the riding passenger (external passenger if present or booking passenger)
		 */
		bookingPassengerName?: string;
		/**
		 * title of manually added booking leg
		 */
		title?: string;
		from?: NamedLocation;
		to?: NamedLocation;
		start?: DateTimeValue;
		startTimewindow?: {
			start?: DateTimeValue;
			end?: DateTimeValue;
		};
		end?: DateTimeValue;
		endTimewindow?: {
			start?: DateTimeValue;
			end?: DateTimeValue;
		};
		serviceType?: TripType;
		/**
		 * list of passengers riding with that booking
		 */
		passengerList?: Array<PassengerDetails>;
		bookingState?: BookingLegStatus;
		specialNeeds?: SpecialNeeds;
		passengerEmail?: string;
		/**
		 * in case of manually added booking trips, notice for driver can be added
		 */
		driverNotice?: string;
		/**
		 * phone number
		 */
		passengerPhone?: string;
		paymentInfo?: IItineraryPayment;
		/**
		 * flag to confirm, if given booking for given booking leg may be canceled
		 */
		canBookingBeCanceled?: boolean;
		/**
		 * flag to indicate, if given booking trip can be set to be scheduled automatically
		 */
		canBeSetToScheduleAutomatically?: boolean;
		/**
		 * flag to indicate, if given booking trip can be given to manual disposition again
		 */
		canBeSetToManualDisposition?: boolean;
		journey?: Array<{
			lineName?: string;
			/**
			 * in case of manually added booking trips, notice for driver can be added
			 */
			driverNotice?: string;
			/**
			 * The id of the booked trip (booking leg)
			 */
			uuid?: string;
			from?: NamedLocation;
			to?: NamedLocation;
			start?: DateTimeValue;
			startTimewindow?: {
				start?: DateTimeValue;
				end?: DateTimeValue;
			};
			end?: DateTimeValue;
			endTimewindow?: {
				start?: DateTimeValue;
				end?: DateTimeValue;
			};
			serviceType?: TripType;
		}>;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/distributors/bookedTrips/{uuid}',
			path: {
				uuid: uuid,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}

	/**
	 * Returns all possible assignments for a booking leg to scheduled manually.
	 * Searches for possible assignments that the given booking leg can be manually scheduled on.
	 * @param bookingLegId unique identificator for booking leg
	 * @returns AssignmentMasterViewItem OK
	 * @throws ApiError
	 */
	public static getApiDistributorsAssignmentsManualSearch(
		bookingLegId: string
	): CancelablePromise<Array<AssignmentMasterViewItem>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/distributors/assignments/manual/search',
			query: {
				bookingLegId: bookingLegId,
			},
		});
	}
}
