import React from 'react';

interface ILogoIconProps {
	variant?:
		| 'mopla_white'
		| 'mopla_secondary'
		| 'mopla_primary'
		| 'mopla_secondary_dark';
}

export const LogoIcon: React.FC<ILogoIconProps> = ({ variant }) => {
	const letterColor =
		(variant === 'mopla_white' && '#1C308B') ||
		(variant === 'mopla_secondary' && '#FFFFFF') ||
		(variant === 'mopla_primary' && '#1C308B') ||
		(variant === 'mopla_secondary_dark' && '#FFFFFF') ||
		'#6FDB89';

	const markerColor =
		(variant === 'mopla_white' && '#6FDB89') ||
		(variant === 'mopla_secondary' && '#6FDB89') ||
		(variant === 'mopla_primary' && '#FFFFFF') ||
		(variant === 'mopla_secondary_dark' && '#6FDB89') ||
		'#6FDB89';

	return (
		<svg
			width="99"
			height="26"
			viewBox="0 0 99 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.4492 6.50356C16.7012 6.07468 15.8335 5.86523 14.866 5.86523C13.7589 5.86523 12.7715 6.1046 11.9038 6.58335C11.2954 6.92245 10.7868 7.35133 10.3679 7.86996C9.98886 7.29148 9.50014 6.83269 8.92167 6.50356C8.17363 6.07468 7.30591 5.86523 6.33846 5.86523C5.371 5.86523 4.43346 6.1046 3.64554 6.58335C3.3164 6.78282 3.01719 7.03217 2.7479 7.29148C2.7479 6.91248 2.62821 6.60329 2.38884 6.3739C2.14947 6.13453 1.83031 6.01484 1.44133 6.01484C1.05235 6.01484 0.75314 6.13453 0.503795 6.3739C0.254451 6.61327 0.134766 6.93243 0.134766 7.32141V18.7115C0.134766 19.0905 0.254451 19.3997 0.503795 19.649C0.75314 19.8983 1.06233 20.018 1.44133 20.018C1.82033 20.018 2.14947 19.8983 2.38884 19.649C2.62821 19.3997 2.7479 19.0905 2.7479 18.7115V11.4904C2.7479 10.533 3.03714 9.74503 3.61561 9.13663C4.19409 8.51826 4.93215 8.21905 5.81982 8.21905C6.70749 8.21905 7.36576 8.47837 7.89437 8.997C8.42298 9.51564 8.69227 10.3135 8.69227 11.3907V18.7115C8.69227 19.0905 8.81195 19.3997 9.0613 19.649C9.31064 19.8983 9.61983 20.018 9.99883 20.018C10.3778 20.018 10.707 19.8983 10.9463 19.649C11.1857 19.3997 11.3054 19.0905 11.3054 18.7115V11.4904C11.3054 10.533 11.5946 9.74503 12.1631 9.13663C12.7316 8.51826 13.4697 8.21905 14.3574 8.21905C15.245 8.21905 15.9033 8.47837 16.4319 8.997C16.9605 9.51564 17.2298 10.3135 17.2298 11.3907V18.7115C17.2298 19.0905 17.3495 19.3997 17.5989 19.649C17.8482 19.8983 18.1574 20.018 18.5364 20.018C18.9154 20.018 19.2445 19.8983 19.4839 19.649C19.7233 19.3997 19.843 19.0905 19.843 18.7115V11.3907C19.843 10.2138 19.6335 9.20645 19.2146 8.37863C18.7957 7.5508 18.2073 6.92246 17.4592 6.49358L17.4492 6.50356Z"
				fill={letterColor}
			/>
			<path
				d="M47.5497 11.2207C47.071 11.2207 46.6621 11.4002 46.303 11.7493C45.9539 12.0984 45.7744 12.5173 45.7744 13.016C45.7744 13.5147 45.9539 13.9037 46.303 14.2627C46.6521 14.6118 47.071 14.7913 47.5497 14.7913C48.0285 14.7913 48.4673 14.6118 48.8064 14.2627C49.1456 13.9136 49.3151 13.4947 49.3151 13.016C49.3151 12.5372 49.1456 12.0984 48.8064 11.7493C48.4673 11.4002 48.0484 11.2207 47.5497 11.2207Z"
				fill={letterColor}
			/>
			<path
				d="M79.7554 17.4342H79.117C78.738 17.4342 78.4288 17.2247 78.1995 16.8158C77.9601 16.4069 77.8404 15.8882 77.8404 15.2499V1.27664C77.8404 0.89764 77.7207 0.598427 77.4813 0.359056C77.242 0.119685 76.9328 0 76.5638 0C76.1947 0 75.8756 0.119685 75.6462 0.359056C75.4068 0.598427 75.2871 0.907614 75.2871 1.27664V15.2599C75.2871 16.1775 75.4467 16.9953 75.7758 17.7035C76.095 18.4116 76.5538 18.9701 77.1323 19.3791C77.7107 19.788 78.379 19.9974 79.127 19.9974H79.1769C79.6855 19.9974 80.1044 19.8777 80.4336 19.6384C80.7627 19.399 80.9223 19.0898 80.9223 18.7208C80.9223 18.3518 80.8126 18.0426 80.6031 17.8032C80.3937 17.5638 80.1144 17.4441 79.7753 17.4441L79.7554 17.4342Z"
				fill={letterColor}
			/>
			<path
				d="M96.3111 7.52088C95.0744 6.49358 93.4886 5.86523 91.7531 5.86523C87.8234 5.86523 84.6318 9.05684 84.6318 12.9865C84.6318 16.9162 87.8234 20.1078 91.7531 20.1078C93.4886 20.1078 95.0744 19.4894 96.3111 18.4521V18.8212C96.3111 19.2002 96.4308 19.4994 96.6702 19.7388C96.9096 19.9781 97.2187 20.0978 97.5878 20.0978C97.9568 20.0978 98.276 19.9781 98.5054 19.7388C98.7447 19.4994 98.8644 19.1902 98.8644 18.8212V12.9865C98.8644 10.7923 97.867 8.82745 96.3012 7.52088H96.3111ZM91.7531 17.5346C89.2397 17.5346 87.2051 15.4999 87.2051 12.9865C87.2051 10.4731 89.2397 8.43847 91.7531 8.43847C94.2665 8.43847 96.3012 10.4731 96.3012 12.9865C96.3012 15.4999 94.2665 17.5346 91.7531 17.5346Z"
				fill={letterColor}
			/>
			<path
				d="M55.1799 12.9865V24.7157C55.1799 25.0947 55.2996 25.3939 55.539 25.6333C55.7783 25.8726 56.0875 25.9923 56.4565 25.9923C56.8256 25.9923 57.1348 25.8726 57.3741 25.6333C57.6135 25.3939 57.7332 25.0847 57.7332 24.7157V18.4521C58.9699 19.4794 60.5558 20.1078 62.2912 20.1078C66.2209 20.1078 69.4125 16.9162 69.4125 12.9865C69.4125 9.05684 66.2209 5.86523 62.2912 5.86523C60.5558 5.86523 58.9699 6.48361 57.7332 7.52088C56.1673 8.82745 55.1699 10.7923 55.1699 12.9865H55.1799ZM57.7531 12.9865C57.7531 10.4731 59.7878 8.43847 62.3012 8.43847C64.8146 8.43847 66.8492 10.4731 66.8492 12.9865C66.8492 15.4999 64.8146 17.5346 62.3012 17.5346C59.7878 17.5346 57.7531 15.4999 57.7531 12.9865Z"
				fill={letterColor}
			/>
			<path
				d="M39.9199 12.9863C39.9199 12.4078 39.8401 11.8493 39.7104 11.3107C39.0821 8.70755 37.0275 6.6729 34.4243 6.0645C33.9057 5.94482 33.3571 5.875 32.7986 5.875C32.2401 5.875 31.6815 5.94482 31.1529 6.07448C28.5597 6.69285 26.5151 8.7275 25.8868 11.3107C25.7571 11.8493 25.6773 12.4078 25.6773 12.9863C25.6773 13.0561 25.6773 13.116 25.6773 13.1858C25.6175 14.0336 25.6175 18.6414 31.6915 25.5134C32.27 26.1617 33.2973 26.1617 33.8758 25.5134C39.9498 18.6414 39.9498 14.0336 39.8899 13.1858C39.8899 13.116 39.8899 13.0561 39.8899 12.9863H39.9199ZM32.7986 8.43826C35.312 8.43826 37.3466 10.4729 37.3466 12.9863C37.3466 15.4997 35.312 17.5343 32.7986 17.5343C30.2852 17.5343 28.2505 15.4997 28.2505 12.9863C28.2505 10.4729 30.2852 8.43826 32.7986 8.43826Z"
				fill={markerColor}
			/>
		</svg>
	);
};
