import React from 'react';

export const CrossCircleIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.4-10.006l2.829 2.829-1.395 1.433-2.848-2.848-2.848 2.848-1.394-1.433 2.828-2.829-2.809-2.809 1.395-1.433 2.828 2.828 2.829-2.828 1.394 1.433-2.809 2.81z"
				clipRule="evenodd"
			/>
		</svg>
	);
};
