import React from 'react';
import { IconButton } from '@mui/material';

import { TrashIconSmall } from '../../icons/TrashSmall';

import { ImageWrapper, Root } from './UploadedImage.styles';

interface UploadedImageProps {
	onDelete: () => void;
	src: string | undefined;
}

export const UploadedImage: React.FC<UploadedImageProps> = ({
	onDelete,
	src,
}) => {
	return (
		<Root>
			<div>
				<IconButton
					data-testid="docsUpload-uploadPage-selectedPictures-deleteButton"
					onClick={onDelete}
				>
					<TrashIconSmall />
				</IconButton>
			</div>
			<ImageWrapper>
				<img src={src} alt="Driver uploaded document" />
			</ImageWrapper>
		</Root>
	);
};
