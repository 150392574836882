import { useCallback } from 'react';
import { useObservableEagerState } from 'observable-hooks';

import { fetchUser } from '../actions/userActions';
import { useBusinessLayer } from '../business-logic';
import { useDBObservableDataContext } from '../contexts/dataContext';

/** Extracts and return state from user$ (from the react context) */
export const useUser = () => {
	const { user$ } = useDBObservableDataContext();

	if (!user$) {
		throw new Error(
			'No user$ found in DBObservableDataContext. Refer to {withDBObservableDataContext}'
		);
	}

	return useObservableEagerState(user$);
};

export const useUserActions = () => {
	const { dispatch } = useBusinessLayer();

	const fetchUserFunc = useCallback(() => {
		dispatch(fetchUser());
	}, [dispatch]);

	return {
		fetchUser: fetchUserFunc,
	};
};
