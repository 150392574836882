import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { differenceInHours } from 'date-fns';

import { PassengerBooking, usePassengerBookings } from '@mopla/business-logic';
import { TicketBannerSize } from '@mopla/constants';
import { TransportType } from '@mopla/data-models';
import { TMoplaColors } from '@mopla/mui';
import {
	ArrivalIcon,
	ArrowRightIcon,
	DepartureIcon,
	ErrorModal,
	FlexContainer,
	StationIcon,
	TrashIcon,
	TravelTypeSymbols,
} from '@mopla/ui';
import {
	checkTimeMightChange,
	checkTimeOverwritten,
	formatDate,
	formatTime,
	getActualTime,
	useMediaQuery,
} from '@mopla/utils';

import passApi from '../../../api/passApi';

import { ConfirmationModal } from './ConfirmationModal';
import {
	CancelledRideChip,
	Dash,
	FirstTicket,
	MainWrapper,
	Person,
	Root,
	RouteDot,
	RouteLine,
	SecondTicket,
	Separator,
	ShowTicketButton,
	SideCircle,
	StationName,
	StationTime,
	StationWrapper,
	SubtitleTime,
	ThirdTicket,
	TimeWrapper,
	TitleTime,
	TitleWrapper,
	TrashWrapper,
	WalkWrapper,
} from './TicketsStack.styles';

export interface TicketsStackProps {
	// TODO this Partial looks strange. Investigate and improve
	tickets: PassengerBooking[] | Partial<PassengerBooking>[];
	ticketsBannerSize: TicketBannerSize;
	stack?: boolean;
	disposable?: boolean;
	isFutureTrip?: boolean;
	onDetailsClick: () => void;
	onTicketInfoClick?: () => void;
	disabled?: boolean;
}

export const TicketsStack: React.FC<TicketsStackProps> = ({
	tickets,
	ticketsBannerSize,
	stack,
	onDetailsClick,
	onTicketInfoClick,
	disabled,
	disposable = true,
}) => {
	const { loadBookings } = usePassengerBookings();
	const [showDisposeModal, setShowDisposeModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [isCancelling, setIsCancelling] = useState(false);
	const { t } = useTranslation(['searchResults', 'common', 'tickets']);
	const navigate = useNavigate();
	const mainTicket = tickets[0];
	const secondTicket = tickets[1];
	const thirdTicket = tickets[2];
	const smallScreen = useMediaQuery('(max-height: 750px)');
	const attendeesCount = (mainTicket?.passengers || 0) - 1;

	const [rightSwipeX, setRightSwipeX] = useState<number>(0);
	const [revealedActions, setRevealedActions] = useState(false);

	const [{ x }, api] = useSpring(() => ({ x: 0, y: 0 }));
	const bind = useDrag(
		({ down, last, first, tap, movement: [mx] }) => {
			if (tap) {
				onTicketInfoClick?.();

				return;
			}
			if (!disposable) return;
			if (last) {
				//we ended far enoug to keep it revealed?
				if (mx < -85) {
					setRightSwipeX(85);
					setRevealedActions(true);
				} else {
					setRightSwipeX(0);
					setRevealedActions(false);
				}
			}
			if (first) {
				setRightSwipeX(1);
			}
			api.start({ x: down ? Math.min(0, mx) : 0, immediate: down });
		},
		{ axis: 'x', filterTaps: true, threshold: 20, enabled: stack !== true }
	);

	if (tickets.length === 0) {
		return null;
	}

	const secondTicketRideDate = secondTicket
		? formatDate(
				new Date(secondTicket?.itinerary?.startDateTime || ''),
				'weekDayShort'
		  )
		: null;
	const thirdTicketRideDate = thirdTicket
		? formatDate(
				new Date(thirdTicket?.itinerary?.startDateTime || ''),
				'weekDayShort'
		  )
		: null;

	const rideDate = formatDate(
		new Date(mainTicket?.itinerary?.startDateTime || ''),
		'weekDayShort'
	);

	const mainTicketLegs = mainTicket?.itinerary?.legs || [];
	const transportTypes = mainTicketLegs
		.map((leg) => leg.mode)
		.filter((value, index, self) => self.indexOf(value) === index);

	const walkBefore = mainTicketLegs[0].mode === TransportType.WALK;
	const walkBeforeDistance = walkBefore
		? Math.round(Number(mainTicketLegs[0].distance || 0) / 100) / 10
		: 0;
	const walkBeforeDuration = walkBefore
		? Math.round(Number(mainTicketLegs[0].duration || 0) / 60)
		: 0;
	const walkBeforeString = `${t(
		'common:text.around'
	)} ${walkBeforeDuration} ${t('common:text.minutes')} ${t(
		'common:text.toWalk'
	)} (${walkBeforeDistance} ${t('common:text.km')})`;
	const walkBeforeStartTime = formatTime(
		new Date(mainTicketLegs[0].startDateTime || '')
	);

	const walkAfter =
		mainTicketLegs[mainTicketLegs.length - 1].mode === TransportType.WALK;
	const walkAfterDistance = walkAfter
		? Math.round(
				Number(mainTicketLegs[mainTicketLegs.length - 1].distance || 0) / 100
		  ) / 10
		: 0;
	const walkAfterDuration = walkAfter
		? Math.round(
				Number(mainTicketLegs[mainTicketLegs.length - 1].duration || 0) / 60
		  )
		: 0;
	const walkAfterString = `${t('common:text.around')} ${walkAfterDuration} ${t(
		'common:text.minutes'
	)} ${t('common:text.toWalk')} (${walkAfterDistance} ${t('common:text.km')})`;
	const walkAfterStartTime = formatTime(
		new Date(mainTicketLegs[mainTicketLegs.length - 1].startDateTime || '')
	);

	const legForStartTime = walkBefore
		? mainTicketLegs?.[1]
		: mainTicketLegs?.[0];
	const startTime = formatTime(new Date(legForStartTime?.startDateTime || ''));
	const curentStartTime = getActualTime(legForStartTime, 'start');

	const legForEndTime = walkAfter
		? mainTicketLegs?.[mainTicketLegs?.length - 2]
		: mainTicketLegs?.[mainTicketLegs?.length - 1];
	const endTime = formatTime(new Date(legForEndTime?.endDateTime || ''));
	const curentEndTime = getActualTime(legForEndTime, 'end');

	const timeMightChange = checkTimeMightChange(mainTicket?.itinerary);
	const isTimeOverwritten = checkTimeOverwritten(mainTicket?.itinerary);
	const getRideTimeColor = (): TMoplaColors => {
		if (!timeMightChange) return 'mopla_primary_dark';
		return isTimeOverwritten ? 'mopla_orange' : 'mopla_black';
	};

	const isBookedTicket =
		transportTypes.includes(TransportType.LBODT) ||
		transportTypes.includes(TransportType.LBODTFLEX);
	const firstStation = walkBefore ? mainTicketLegs[1] : mainTicketLegs[0];
	const firstStationDateTime = formatTime(
		new Date(firstStation?.startDateTime || '')
	);
	const firstStationName = firstStation?.from?.name;

	const lastStation = walkAfter
		? mainTicketLegs[mainTicketLegs.length - 2]
		: mainTicketLegs[mainTicketLegs.length - 1];
	const lastStationDateTime = formatTime(
		new Date(lastStation?.endDateTime || '')
	);
	const lastStationName = lastStation?.to?.name;

	const cancelBooking = async () => {
		setIsCancelling(true);
		try {
			await passApi.post('/api/command/cancelBooking', {
				bookingId: mainTicket.bookingNumber,
			});
			await loadBookings();
			navigate('/home/tickets', { replace: true });
		} catch (e) {
			setShowErrorModal(true);
			console.error('Error on cancel booking: ', e);
		} finally {
			setShowDisposeModal(false);
			setIsCancelling(false);
		}
	};

	const showDetailsHandler = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.stopPropagation();
		isBookedTicket
			? onDetailsClick()
			: onTicketInfoClick && onTicketInfoClick();
	};

	const onDeleteTicket = (e: any) => {
		e.stopPropagation();
		setShowDisposeModal && setShowDisposeModal(true);
	};

	const isCancelled =
		mainTicket.bookingStatus === 'CANCELLED_BY_PASSENGER' ||
		mainTicket.bookingStatus === 'CANCELLED_NO_SHOW' ||
		mainTicket.bookingStatus === 'CANCELLED_BY_DISTRIBUTOR';
	const timeDifference = differenceInHours(
		new Date() || 0,
		new Date(mainTicket.itinerary?.endDateTime || '') || 0
	);
	const isPastTrip = mainTicket.bookingStatus !== 'NEW' || timeDifference > 24;

	const attendeesText = isPastTrip
		? `${t('tickets:text.passengers')[0]}.`
		: t('tickets:text.passengers');
	const attendeesCountText =
		attendeesCount > 0 ? '+ ' + attendeesCount + ' ' + attendeesText : '';

	return (
		<MainWrapper stack={stack}>
			{showErrorModal && (
				<ErrorModal onSubmit={() => setShowErrorModal(false)} />
			)}
			<Root data-testid="ticketStack-root-block">
				{stack && (
					<>
						{thirdTicket && (
							<ThirdTicket
								showData={
									ticketsBannerSize === TicketBannerSize.lg && !smallScreen
								}
								data-testid="ticketStack-third-ticket"
							>
								<span>{thirdTicketRideDate}</span>
								<TravelTypeSymbols transportTypes={transportTypes} />
							</ThirdTicket>
						)}
						{secondTicket && (
							<SecondTicket
								showData={
									ticketsBannerSize === TicketBannerSize.lg && !smallScreen
								}
								data-testid="ticketStack-second-ticket"
							>
								<span>{secondTicketRideDate}</span>
								<TravelTypeSymbols transportTypes={transportTypes} />
							</SecondTicket>
						)}
					</>
				)}
				<animated.div
					style={{ x, touchAction: 'pan-y', userSelect: 'none', zIndex: 1 }}
				>
					<FirstTicket
						data-testid="ticketStack-first-ticket"
						rightSwipeX={rightSwipeX}
					>
						<div {...bind()} style={{ touchAction: 'pan-y' }}>
							{!stack && (
								<>
									<FlexContainer className="space-between">
										<Person data-testid="savedTicket-ticketTitle-text">
											{`${t(
												'tickets:title.your_ticket'
											)} ${attendeesCountText}`}
										</Person>
										{isCancelled && (
											<CancelledRideChip
												label={t('tickets:text.cancelled_ride')}
											/>
										)}
									</FlexContainer>
									<Separator>
										<SideCircle left={true} />
										<Dash />
										<SideCircle showDelete={Boolean(rightSwipeX)} />
									</Separator>
								</>
							)}
							<TitleWrapper
								withMargin={
									((ticketsBannerSize === TicketBannerSize.md ||
										ticketsBannerSize === TicketBannerSize.lg) &&
										smallScreen) ||
									(ticketsBannerSize === TicketBannerSize.lg && !smallScreen)
								}
							>
								<TimeWrapper>
									<TitleTime data-testid="route-routeDate-block">
										{rideDate}
									</TitleTime>
									<TitleTime
										data-testid="route-duration-timeInterval-block"
										color={getRideTimeColor()}
									>{`${curentStartTime} - ${curentEndTime}`}</TitleTime>
									{timeMightChange && isTimeOverwritten && (
										<SubtitleTime data-testid="route-duration-initialTimeInterval-block">{`${startTime} - ${endTime}`}</SubtitleTime>
									)}
								</TimeWrapper>
								<TravelTypeSymbols transportTypes={transportTypes} />
							</TitleWrapper>
							{ticketsBannerSize === TicketBannerSize.md && !smallScreen ? (
								<Separator>
									<SideCircle left={true} />
									<span />
									<SideCircle />
								</Separator>
							) : null}

							{walkBefore &&
							ticketsBannerSize === TicketBannerSize.lg &&
							!smallScreen ? (
								<WalkWrapper data-testid="search-results-walkBefore-block">
									<span>{walkBeforeStartTime}</span>
									<span>{walkBeforeString}</span>
								</WalkWrapper>
							) : null}
							{ticketsBannerSize === TicketBannerSize.md && !smallScreen ? (
								<>
									<StationWrapper data-testid="route-firstStation-block">
										<StationTime>{firstStationDateTime}</StationTime>
										<DepartureIcon />
										<StationName>{firstStationName}</StationName>
									</StationWrapper>
									<StationWrapper data-testid="route-lastStation-block">
										<StationTime>{lastStationDateTime}</StationTime>
										<ArrivalIcon />
										<StationName>{lastStationName}</StationName>
									</StationWrapper>
								</>
							) : null}
							{((ticketsBannerSize === TicketBannerSize.md ||
								ticketsBannerSize === TicketBannerSize.lg) &&
								smallScreen) ||
							(ticketsBannerSize === TicketBannerSize.lg && !smallScreen) ? (
								<>
									<StationWrapper data-testid="route-firstStation-block">
										<StationTime>{firstStationDateTime}</StationTime>
										<StationIcon />
										<StationName>{firstStationName}</StationName>
									</StationWrapper>
									<RouteLine>
										<RouteDot />
										<RouteDot />
										<RouteDot />
										<RouteDot />
									</RouteLine>
									<StationWrapper data-testid="route-lastStation-block">
										<StationTime>{lastStationDateTime}</StationTime>
										<StationIcon />
										<StationName>{lastStationName}</StationName>
									</StationWrapper>
								</>
							) : null}
							{walkAfter &&
							ticketsBannerSize === TicketBannerSize.lg &&
							!smallScreen ? (
								<WalkWrapper bottom={true}>
									<span>{walkAfterStartTime}</span>
									<span>{walkAfterString}</span>
								</WalkWrapper>
							) : null}
							{((ticketsBannerSize === TicketBannerSize.md ||
								ticketsBannerSize === TicketBannerSize.lg) &&
								smallScreen) ||
							(ticketsBannerSize === TicketBannerSize.lg && !smallScreen) ? (
								<Separator>
									<SideCircle left={true} />
									<Dash />
									<SideCircle showDelete={Boolean(rightSwipeX)} />
								</Separator>
							) : null}
						</div>

						{((ticketsBannerSize === TicketBannerSize.md ||
							ticketsBannerSize === TicketBannerSize.lg) &&
							smallScreen) ||
						(ticketsBannerSize === TicketBannerSize.lg && !smallScreen) ? (
							<ShowTicketButton
								type="button"
								disabled={disabled}
								data-testid="bookedTicket-openDetails-button"
								onClick={(e) => showDetailsHandler(e)}
							>
								<span>
									{isBookedTicket
										? t('common:text.show_ticket')
										: t('common:text.show_schedule')}
								</span>
								<ArrowRightIcon />
							</ShowTicketButton>
						) : null}
					</FirstTicket>
				</animated.div>
				{!stack && (
					<TrashWrapper
						revealed={revealedActions}
						onClick={onDeleteTicket}
						data-testid="savedTicket-cancelBooking-button"
					>
						<TrashIcon />
					</TrashWrapper>
				)}
			</Root>

			<ConfirmationModal
				open={showDisposeModal}
				ticket={mainTicket}
				onClose={() => {
					setShowDisposeModal?.(false);
				}}
				onConfirm={() => {
					cancelBooking();
				}}
				isCanceling={isCancelling}
			/>
		</MainWrapper>
	);
};
