import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { FlexColumn } from '../../atoms/FlexContainer/FlexColumn';
import { Text } from '../../atoms/Text/Text';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	background: disabled
		? theme.colors.driver_light_grey
		: theme.colors.mopla_white,
	padding: 16,
	boxSizing: 'border-box',
	borderRadius: 8,
}));

export const PassengerInfo = styled('div', {
	shouldForwardProp: (prop) => prop !== 'disabledText',
})<{ disabledText?: boolean }>(({ theme, disabledText }) => ({
	...theme.fonts.body2,
	fontWeight: 700,
	color: disabledText
		? theme.colors.mopla_grey
		: theme.colors.mopla_secondary_dark,
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	gridTemplateRows: 'max-content max-content',
	justifyContent: 'space-between',
	'#more-attendees': {
		...theme.fonts.driver_caption,
		color: disabledText
			? theme.colors.mopla_grey
			: theme.colors.mopla_secondary_dark,
		marginTop: 4,
	},
}));

export const ManualButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'red',
})<{ red?: boolean }>(({ theme, red = false }) => ({
	...theme.fonts.body1_semibold,
	border: `2px solid ${
		red ? theme.colors.mopla_red : theme.colors.mopla_secondary
	}`,
	color: red ? theme.colors.mopla_red : theme.colors.mopla_secondary,
	borderRadius: 8,
	marginTop: 16,
	height: 36,

	'&:hover, :active, :focus': {
		border: `2px solid ${
			red ? theme.colors.mopla_red : theme.colors.mopla_secondary
		}`,
		color: theme.colors.mopla_white,
		background: red ? theme.colors.mopla_red : theme.colors.mopla_secondary,
	},
}));

export const PaymentFormWrapper = styled(FlexColumn)(({ theme }) => ({
	marginTop: 24,
	gap: 4,

	span: {
		'&:first-of-type': {
			opacity: 0.7,
		},
		'&:last-of-type': {
			display: 'flex',
			alignItems: 'center',
			gap: 4,
		},
	},
	svg: {
		path: {
			fill: theme.palette.primary.main,
		},
	},
}));

export const ExpandIconWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	marginTop: 8,
}));

export const AdditionalNeedsWrapper = styled(FlexColumn)(() => ({
	marginTop: 24,
}));

export const AdditionalNeedText = styled(Text)(() => ({
	display: 'flex',
	alignItems: 'center',
	gap: 8,
}));
