import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	gridColumnEnd: 'span 2',
	display: 'grid',
	gridTemplateRows: 'max-content max-content max-content',
	gridGap: 16,
	padding: '24px 16px',
	boxSizing: 'border-box',
	borderRadius: 8,

	background: theme.colors.mopla_secondary,

	'.openButton': {
		width: 'max-content',
		border: '2px solid white',
	},
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	justifyContent: 'space-between',

	'#closeButton': {
		color: theme.colors.mopla_white,
	},
}));

export const Title = styled('span')(({ theme }) => ({
	...theme.fonts.H6,
	color: theme.colors.mopla_white,
}));

export const Subtitle = styled('span')(({ theme }) => ({
	...theme.fonts.body1,
	color: theme.colors.mopla_white,
}));
