import React from 'react';

export const TooltipBlueIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.5 16.5C12.5 17.0523 12.0523 17.5 11.5 17.5C10.9477 17.5 10.5 17.0523 10.5 16.5C10.5 15.9477 10.9477 15.5 11.5 15.5C12.0523 15.5 12.5 15.9477 12.5 16.5ZM12 6.5C11.325 6.5 10.7523 6.67162 10.2891 6.98045C9.83206 7.28511 9.53989 7.68416 9.35557 8.05279C9.17322 8.4175 9.08702 8.77011 9.04486 9.0231C9.02338 9.15197 9.01225 9.26154 9.00645 9.34282C9.00353 9.38362 9.00192 9.41778 9.00104 9.44422C9.0006 9.45746 9.00034 9.46881 9.00019 9.47814L9.00004 9.49061L9.00001 9.4957L9 9.49795L9 9.499C9 9.4995 9 9.5 10 9.5H9C9 10.0523 9.44772 10.5 10 10.5C10.5488 10.5 10.9943 10.0579 10.9999 9.51047C11.0001 9.50726 11.0004 9.49862 11.0014 9.48531C11.0034 9.45721 11.0079 9.41053 11.0176 9.3519C11.038 9.22989 11.0768 9.0825 11.1444 8.94721C11.2101 8.81584 11.2929 8.71489 11.3985 8.64455C11.4977 8.57838 11.675 8.5 12 8.5C12.325 8.5 12.5023 8.57838 12.6016 8.64455C12.7071 8.71489 12.7899 8.81584 12.8556 8.94721C12.9232 9.0825 12.962 9.22989 12.9824 9.3519C12.9921 9.41053 12.9966 9.45721 12.9986 9.48531C12.9994 9.49654 12.9998 9.50445 13 9.50857C12.9987 9.81618 12.928 9.95791 12.8441 10.0628C12.7308 10.2044 12.5121 10.3759 12.0528 10.6056C10.4946 11.3847 10.4986 12.9635 10.4999 13.4621L10.5 13.5C10.5 14.0523 10.9477 14.5 11.5 14.5C12.0523 14.5 12.5 14.0523 12.5 13.5C12.5 13.2422 12.5062 13.0188 12.5737 12.8162C12.6284 12.6522 12.7202 12.5079 12.9472 12.3944C13.4879 12.1241 14.0192 11.7956 14.4059 11.3122C14.8212 10.7931 15 10.1864 15 9.5H14C15 9.5 15 9.4995 15 9.499L15 9.49795L15 9.4957L15 9.49061L14.9998 9.47814C14.9997 9.46881 14.9994 9.45746 14.999 9.44422C14.9981 9.41778 14.9965 9.38362 14.9936 9.34282C14.9877 9.26154 14.9766 9.15197 14.9551 9.0231C14.913 8.77011 14.8268 8.4175 14.6444 8.05279C14.4601 7.68416 14.1679 7.28511 13.7109 6.98045C13.2477 6.67162 12.675 6.5 12 6.5Z"
				fill="#1C308B"
			/>
		</svg>
	);
};
