/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GtfsTripMasterViewItem } from '../models/GtfsTripMasterViewItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GtfsService {
	/**
	 * Return all GTFS trips of the distributor including scheduling flexibilities
	 * Returns list of all all GTFS trips of the distributor including scheduling flexibilities
	 * @returns any A JSON Array of gtfs trips
	 * @throws ApiError
	 */
	public static getApiScheduling(): CancelablePromise<
		Array<
			GtfsTripMasterViewItem & {
				/**
				 * in minutes
				 */
				schedulingFlexibility?: number;
				/**
				 * in minutes
				 */
				minimumPreorderTime?: number;
			}
		>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/scheduling',
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}
}
