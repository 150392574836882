import { EntityDescriptor } from '../business-logic';
import { scheduledLegSchema } from '../schemas/scheduledLeg';

export const scheduledLegEntity: EntityDescriptor = {
	scheduledLeg: {
		migrationStrategies: {
			1: function (oldDoc: any) {
				// migration to rxdb 12
				return oldDoc;
			},
		},
		schema: scheduledLegSchema,
	},
};
