import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(TextField)(({ theme }) => ({
	'.MuiFilledInput-root': {
		position: 'relative',
		backgroundColor: `${theme.colors.mopla_white} !important`,
		border: `1px solid ${theme.colors.mopla_grey}`,
		borderRadius: '8px',
		height: '56px',
		paddingRight: 0,
		'.Mui-focused, :hover:not(.Mui-error):not(.Mui-disabled), :active:not(.Mui-error):not(.Mui-disabled)':
			{
				borderColor: theme.colors.mopla_secondary,
			},

		'@media (hover: hover)': {
			'.MuiFilledInput-root: hover': {
				borderColor: theme.colors.mopla_secondary,
			},
		},
	},

	'.MuiFilledInput-input': {
		...theme.fonts.body1,
	},

	'.MuiInputLabel-shrink': {
		...theme.fonts.caption,
	},

	'.Mui-error': {
		color: theme.colors.mopla_red,
		borderColor: theme.colors.mopla_red,

		'.MuiFilledInput-input': {
			color: theme.colors.mopla_black,
		},
	},

	'.MuiFormHelperText-root': {
		width: '100%',
		marginLeft: '16px',
		...theme.fonts.caption,
		svg: {
			color: theme.colors.mopla_red,
			marginBottom: '-3px',
			marginRight: 8,
		},
		span: {
			color: theme.colors.mopla_black,
		},
	},

	'.MuiInputBase-input:-webkit-autofill': {
		borderTopLeftRadius: 'inherit',
		borderTopRightRadius: 'inherit',
		borderBottomLeftRadius: 'inherit',
		borderBottomRightRadius: 'inherit',
		height: '21px',
	},

	'.MuiInputAdornment-root': {
		position: 'absolute',
	},
}));
