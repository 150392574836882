import React from 'react';

export const ArrowUpIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask id="path-1-inside-1_3759_661" fill="white">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.889 11.3033L16.8387 16.253L18.2529 14.8388L13.3032 9.88909L12.5961 9.18198C12.2056 8.79146 11.5724 8.79145 11.1819 9.18197L10.4748 9.88908L5.52502 14.8388L6.93923 16.2531L11.889 11.3033Z"
				/>
			</mask>
			<path
				d="M16.8387 16.253L16.1316 16.9602L16.8387 17.6673L17.5458 16.9602L16.8387 16.253ZM11.889 11.3033L12.5961 10.5962L11.889 9.88909L11.1819 10.5962L11.889 11.3033ZM18.2529 14.8388L18.96 15.5459L19.6671 14.8388L18.96 14.1317L18.2529 14.8388ZM13.3032 9.88909L14.0103 9.18198L14.0103 9.18198L13.3032 9.88909ZM10.4748 9.88908L9.76765 9.18197L9.76765 9.18198L10.4748 9.88908ZM5.52502 14.8388L4.81791 14.1317L4.11081 14.8388L4.81791 15.5459L5.52502 14.8388ZM6.93923 16.2531L6.23212 16.9602L6.93923 17.6673L7.64634 16.9602L6.93923 16.2531ZM12.5961 9.18198L13.3032 8.47487L12.5961 9.18198ZM11.1819 9.18197L10.4748 8.47486L11.1819 9.18197ZM17.5458 15.5459L12.5961 10.5962L11.1819 12.0104L16.1316 16.9602L17.5458 15.5459ZM17.5458 14.1317L16.1316 15.5459L17.5458 16.9602L18.96 15.5459L17.5458 14.1317ZM12.5961 10.5962L17.5458 15.5459L18.96 14.1317L14.0103 9.18198L12.5961 10.5962ZM11.889 9.88909L12.5961 10.5962L14.0103 9.18198L13.3032 8.47487L11.889 9.88909ZM11.1819 10.5962L11.889 9.88909L10.4748 8.47486L9.76765 9.18197L11.1819 10.5962ZM6.23213 15.5459L11.1819 10.5962L9.76765 9.18198L4.81791 14.1317L6.23213 15.5459ZM7.64634 15.5459L6.23213 14.1317L4.81791 15.5459L6.23212 16.9602L7.64634 15.5459ZM11.1819 10.5962L6.23212 15.5459L7.64634 16.9602L12.5961 12.0104L11.1819 10.5962ZM13.3032 8.47487C12.5221 7.69383 11.2558 7.69382 10.4748 8.47486L11.889 9.88909L11.889 9.88909L13.3032 8.47487Z"
				fill="currentColor"
				mask="url(#path-1-inside-1_3759_661)"
			/>
		</svg>
	);
};
