import { styled } from '@mui/material/styles';

export const RootMobile = styled('div', {
	shouldForwardProp: (prop) => prop !== 'isNative' && prop !== 'variant',
})<{ isNative: boolean; variant: string }>(({ theme, isNative, variant }) => ({
	display: 'grid',
	gridTemplateRows: isNative ? '1fr max-content' : 'max-content 1fr',
	height: '100%',
	width: '100%',
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	background: theme.colors[variant],
}));

export const RootDesktop = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content 1fr',
	gridTemplateColumns: '1fr',

	height: '100%',
	width: '100%',
	background: '#F0F0F0',
}));

export const DesktopOverflowWrapper = styled('div')(() => ({
	display: 'grid',
	justifyItems: 'center',
	overflow: 'auto',
	padding: 24,
	boxSizing: 'border-box',

	width: '100%',
	height: '100%',
}));

export const DesktopContentContainer = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr 348px',
	gridGap: 24,
	width: '100%',
	maxWidth: 976,
}));

export const DesktopSearchContainer = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	gridGap: 32,
	height: '100%',
	width: '100%',
}));
