import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PassengerBooking } from '@mopla/business-logic';

import { FlexColumn, Text } from '../../../';

import {
	PassengerInfo,
	PassengersInfoWrapper,
	Title,
} from './RouteDetails.styles';

export interface PassengerInfoBlockProps {
	passengerInfo?: PassengerBooking;
	isLBTRide: boolean;
}

export const PassengerInfoBlock: React.FC<PassengerInfoBlockProps> = ({
	passengerInfo,
	isLBTRide,
}) => {
	const { t } = useTranslation('booking');

	const mainPassenger = useMemo(() => {
		return passengerInfo?.passengerList?.find((el) => el.isMainPassenger);
	}, [passengerInfo?.passengerList]);
	const attendees = (passengerInfo?.passengers || 1) - 1;

	/** TODO this block is currently rendered in desktop only. Would be nice to create another component */
	if (isLBTRide) {
		return (
			<PassengersInfoWrapper>
				<FlexColumn className="space-between" style={{ gap: 4 }}>
					<Text variant="subtitle1">{t('text.price_according_to_tariff')}</Text>
					<Text variant="body3" color="mopla_dark_grey">
						{t('text.buy_ticket_in_bus')}
					</Text>
				</FlexColumn>
			</PassengersInfoWrapper>
		);
	}

	return (
		<PassengersInfoWrapper>
			<Title>
				{t('text.ticketFor', {
					count: passengerInfo?.passengers,
				})}
			</Title>
			<PassengerInfo>
				<span>
					{`${mainPassenger?.firstName} ${mainPassenger?.lastName}`}
					{attendees
						? ` + ${t('booking:text.additional_passengers', {
								count: attendees,
						  })} `
						: ''}
				</span>
				<span>{`${mainPassenger?.phone}`}</span>
				<span>{`${mainPassenger?.email}`}</span>
			</PassengerInfo>
		</PassengersInfoWrapper>
	);
};
