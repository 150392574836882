import { ComponentType, memo } from 'react';

import { TransportType } from '@mopla/data-models';

import { BusWithPeopleIcon, RufbusIcon, WalkIcon } from '../../icons';
import { BusIcon } from '../../icons/Bus';

import { Root, SymbolCircle } from './TravelTypeSymbols.styles';

export interface TravelTypeSymbolsProps {
	transportTypes: TransportType | TransportType[];
	variant?: 'midGrey' | 'main' | 'disabled';
	large?: boolean;
}

interface IConfig {
	TransportIcon: ComponentType;
	testId: string;
}

const mapTransportTypeToConfig: Record<TransportType, IConfig> = {
	[TransportType.WALK]: {
		TransportIcon: WalkIcon,
		testId: 'travelType-symbols-walk',
	},
	[TransportType.LBT]: {
		TransportIcon: BusIcon,
		testId: 'travelType-symbols-lbt',
	},
	[TransportType.LBODT]: {
		TransportIcon: RufbusIcon,
		testId: 'travelType-symbols-lbodt',
	},
	[TransportType.LBODTFLEX]: {
		TransportIcon: BusWithPeopleIcon,
		testId: 'travelType-symbols-lbodtFlex',
	},
};

export const TravelTypeSymbols = memo<TravelTypeSymbolsProps>(
	({ transportTypes, variant = 'main', large = false }) => {
		const _transportTypes = Array.isArray(transportTypes)
			? transportTypes
			: [transportTypes];

		return (
			<Root>
				{_transportTypes.map((tt) => {
					const { TransportIcon, testId } = mapTransportTypeToConfig[tt];

					return (
						<SymbolCircle
							key={testId}
							variant={variant}
							large={large}
							data-testid={testId}
						>
							<TransportIcon />
						</SymbolCircle>
					);
				})}
			</Root>
		);
	}
);
