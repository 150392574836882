import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Button } from '../../atoms/Button/Button';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: string }>(({ theme, variant }) => ({
	backgroundColor:
		(variant === 'mid_grey' && theme.colors.mopla_mid_grey) ||
		(variant === 'white' && theme.colors.mopla_white) ||
		(variant === 'super_light_blue' && theme.colors.mopla_super_light_blue) ||
		(variant === 'secondary_dark' && theme.colors.mopla_secondary_dark) ||
		theme.colors.mopla_super_light_blue,
	display: 'grid',
	gridTemplateRows: 'max-content 1fr',
	height: '100%',
	overflowY: 'auto',
}));

export const InitialScreenRoot = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: '1fr max-content',
	paddingBottom: 'var(--mopla-safe-area-bottom)',
}));

export const ContentWrapper = styled('div')(() => ({
	padding: 24,
	boxSizing: 'border-box',
	height: '100%',
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gridGap: 16,
	position: 'relative',
}));

export const EditUserWrapper = styled('div')(() => ({
	padding: '24px 0',
}));

export const UserGreetingWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gridGap: 12,
	padding: '8px 0',
}));

export const TitleText = styled('span')(({ theme }) => ({
	...theme.fonts.subtitle1,
	color: theme.colors.mopla_black,
}));

export const MyAccountTitleWithButton = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	justifyContent: 'space-between',
	alignItems: 'center',

	[theme.breakpoints.up('tabletP')]: {
		justifyContent: 'start',
	},
}));

export const SignOutDesktopButton = styled(Button)(({ theme }) => ({
	...theme.fonts.button,
	width: '100%',
	maxWidth: 632,
	textTransform: 'capitalize',
	padding: 16,
}));

export const DeleteAccountButton = styled(Button)(({ theme }) => ({
	textTransform: 'capitalize',
	...theme.fonts.body1_semibold,
	color: theme.colors.mopla_red,
}));

export const MyAccountSubtitle = styled('span')(({ theme }) => ({
	...theme.fonts.body3,
	color: theme.colors.mopla_dark_grey,
}));

export const DataField = styled('span')(({ theme }) => ({
	...theme.fonts.body1,
	color: theme.colors.mopla_black,
	display: 'block',
}));

export const SeparatorLine = styled('div')(() => ({
	width: '100%',
	height: 0,
	border: '1px solid #E7E7E7',
}));

export const MyAccountWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr',

	gridTemplateRows: 'repeat(6, max-content) 1fr',
	gridGap: 24,

	[theme.breakpoints.down('tabletP')]: {
		padding: 24,
	},
}));

export const UserAvatar = styled(Avatar)(() => ({
	width: 50,
	height: 50,
	borderRadius: '50%',
	objectFit: 'cover',
}));

export const UserGreeting = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	gridGap: 4,

	'& > span:first-of-type': {
		...theme.fonts.body1,
		color: theme.colors.mopla_secondary_dark,
	},

	'& > span:last-of-type': {
		...theme.fonts.body1_semibold,
		color: theme.colors.mopla_secondary_dark,
	},
}));

export const LanguageAvatar = styled(Avatar)(({ theme }) => ({
	width: 24,
	height: 24,
	borderRadius: '50%',
	objectFit: 'cover',
	color: theme.colors.mopla_secondary_dark,
	...theme.fonts.body1_semibold,
	fontSize: 10,
	fontWeight: 'bold',
}));

export const StepTitle = styled('span')(({ theme }) => ({
	...theme.fonts.body1_semibold,
	color: theme.colors.mopla_black,
}));

export const SignOutButton = styled('button')(({ theme }) => ({
	outline: 'none',
	cursor: 'pointer',
	color: theme.colors.mopla_white,
	...theme.fonts.body1_semibold,
	background: 'transparent',
	padding: 16,
	boxSizing: 'border-box',
	border: 'none',
	borderRadius: 8,
	transition: 'all .2s ease',
	width: 'max-content',

	'&:hover': {
		background: theme.colors.mopla_secondary,
	},
}));

export const DesktopRoot = styled('div')(() => ({
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gridTemplateColumns: '1fr',
	justifyItems: 'center',
	gridGap: 24,

	padding: 24,
}));

export const DesktopDataBlock = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_white,
	borderRadius: 8,
	overflow: 'hidden',

	width: '100%',
	maxWidth: 632,

	padding: 24,
	boxSizing: 'border-box',
}));

export const DesktopDataTitle = styled('span')(({ theme }) => ({
	display: 'inline-block',
	...theme.fonts.H6,
	color: theme.colors.mopla_secondary,
	marginBottom: 40,
}));

export const ImprintButton = styled(Button)(() => ({
	width: 'fit-content',
	padding: 0,
	textTransform: 'initial',
	textDecoration: 'underline',
}));
