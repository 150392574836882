import { FC, ReactNode } from 'react';

import { ContentWrapper, Root } from './styled';

export interface ILayoutProps {
	content: ReactNode;
	footer?: ReactNode;
}

export const MainWithFooterTemplate: FC<ILayoutProps> = ({
	content,
	footer,
}) => {
	return (
		<Root>
			<ContentWrapper>{content}</ContentWrapper>
			{footer && <div>{footer}</div>}
		</Root>
	);
};
