import { styled } from '@mui/material/styles';

export const TicketContentBlock = styled('div')(() => ({
	display: 'grid',
	gap: 16,
}));
export const TicketSubmitWrapper = styled('div')(() => ({
	display: 'grid',
	justifyContent: 'flex-end',
	'& > button': {
		paddingTop: 0,
		fontSize: '18px',
	},
}));
