import React from 'react';

export const ArrowRightIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.2426 7.75732L18.7782 11.2929C19.1687 11.6834 19.1687 12.3165 18.7782 12.7071L15.2426 16.2426L13.8284 14.8284L15.6569 13H5V11H15.6569L13.8284 9.17154L15.2426 7.75732Z"
				fill="currentColor"
			/>
		</svg>
	);
};
