export const userSchema = {
	title: 'user schema',
	version: 1,
	primaryKey: 'userId',
	type: 'object',
	properties: {
		address: {
			type: 'object',
			properties: {
				city: { type: ['string', 'null'] },
				country: { type: ['string', 'null'] },
				streetNumber: { type: ['string', 'null'] },
				zipcode: { type: ['string', 'null'] },
			},
		},
		hasDefaultStripePaymentMethod: { type: 'boolean' },
		isDriverLicenceCheckNeeded: { type: 'boolean' },
		isStripeCustomerConnected: { type: 'boolean' },
		dateOfBirth: { type: ['string', 'null'] },
		email: { type: 'string' },
		isBlacklisted: { type: 'boolean' },
		name: { type: 'string' },
		phone: { type: 'string' },
		pleaseComplete: { type: 'string' },
		privileges: { type: 'array', items: { type: 'string' } },
		roles: { type: 'array', items: { type: 'string' } },
		userId: {
			type: 'string',
			maxLegnth: 200,
		},
	},
	required: ['userId'],
};
