import { SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(SwipeableDrawer)(() => ({
	'.MuiDrawer-paper': {
		display: 'grid',
		gridTemplateRows: 'max-content 1fr',

		height: '100%',
		width: '100vw',
	},
}));

export const Header = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	gridGap: 8,
	alignItems: 'center',
	justifyContent: 'start',
	width: '100%',

	background: theme.colors.mopla_white,

	padding: '16px 32px',
	boxSizing: 'border-box',

	button: {
		color: theme.colors.mopla_secondary,
	},
}));

export const Title = styled('span')(({ theme }) => ({
	...theme.fonts.subtitle1,
	color: theme.colors.mopla_secondary,
}));

export const Content = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_mid_grey,
	overflow: 'auto',
}));
