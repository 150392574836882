import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from '@mui/material';

import { Button } from '../../atoms/Button/Button';
import { FlexColumn } from '../../atoms/FlexContainer/FlexColumn';
import { Text } from '../../atoms/Text/Text';
import { StayInformedAnim } from '../../molecules/Animations';
import { Modal } from '../../molecules/Modal/Modal';

export interface IClosePersonalEditorModalProps {
	isOpen: boolean;
	onClose(): void;
	onDiscard(): void;
}

export const ClosePersonalEditorModal: FC<IClosePersonalEditorModalProps> = ({
	onClose,
	isOpen,
	onDiscard,
}) => {
	const [portalDom] = useState(document.getElementById('portal'));
	const { t } = useTranslation(['profile', 'common']);

	return (
		<Portal container={portalDom}>
			<Modal
				open={isOpen}
				animationElement={<StayInformedAnim />}
				onClose={onClose}
				mainContent={
					<FlexColumn gap={24}>
						<Text variant="H6">{t('title.modal_title')}</Text>
						<Text variant="body1">{t('title.modal_message')}</Text>
					</FlexColumn>
				}
				footerComp={
					<FlexColumn gap={16} sx={{ mt: 4 }}>
						<Button variant="text" onClick={onClose}>
							{t('button.return')}
						</Button>
						<Button onClick={onDiscard}>{t('button.discard')}</Button>
					</FlexColumn>
				}
			/>
		</Portal>
	);
};
