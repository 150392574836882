import { useState } from 'react';
import { intervalToDuration } from 'date-fns';
import dayjs from 'dayjs';

import { useThrottle } from './useThrottle';

interface ICountdownTimer {
	timerString: Duration;
	isFinished: boolean;
}
export const useCountdownTimer = (endDate: Date): ICountdownTimer => {
	const [startDate, setStartDate] = useState(dayjs().toDate());
	const [isFinished, setIsFinished] = useState(false);

	const timerString = intervalToDuration({
		start: startDate,
		end: endDate,
	});

	const incrementTimer = () => {
		if (isFinished) return;

		const now = dayjs();
		if (dayjs(now).isSameOrAfter(dayjs(endDate))) {
			setIsFinished(true);
			return;
		}
		setStartDate(now.toDate());
	};

	useThrottle(incrementTimer, 1000);

	return { timerString, isFinished };
};
