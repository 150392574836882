import { MobileStepper, Radio, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

import { RouteSuggestion } from '@mopla/ui';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: string }>(({ theme, variant }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content 1fr max-content',
	boxSizing: 'border-box',
	width: '100%',
	height: '100%',
	background:
		variant === 'secondary'
			? theme.colors.mopla_secondary
			: theme.colors.mopla_mid_grey,

	'.MuiMobileStepper-dot': {
		'&:not(:last-of-type)': {
			margin: '0 8px 0 0',
		},
		backgroundColor: theme.palette.secondary.light,
	},

	'.MuiMobileStepper-dotActive': {
		backgroundColor: theme.palette.secondary.main,
	},

	'.MuiPaper-elevation': {
		alignSelf: 'end',
		justifySelf: 'center',
		background: theme.colors.mopla_mid_grey,
	},
	[theme.breakpoints.up('tabletP')]: {
		gridTemplateRows: '1fr',
		gridTemplateColumns: '1fr 320px',
		gridGap: 24,
		maxWidth: 978,
		margin: 'auto',
		padding: 24,
	},
}));

export const ScrollWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	width: '100%',
	height: '100%',
	overflow: 'auto',

	[theme.breakpoints.up('tabletP')]: {
		overflow: 'unset',
	},
}));

export const Form = styled('form')(() => ({
	height: '100%',
}));

export const ContentGrid = styled('div', {
	shouldForwardProp: (prop) => prop !== 'showQR' && prop !== 'showLoader',
})<{ showQR: boolean; showLoader: boolean }>(
	({ showQR, showLoader, theme }) => ({
		display: 'grid',
		gridAutoRows: showLoader ? '1fr' : 'max-content',
		padding: showQR ? 0 : '16px 8px 0 8px',
		boxSizing: 'border-box',

		[theme.breakpoints.up('tabletP')]: {
			padding: 0,
		},
	})
);

export const StyledStepper = styled(MobileStepper)(() => ({
	padding: 16,
}));

export const ActionWrapper = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_secondary,
	borderRadius: '8px 8px 0 0',
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	gap: 16,
	boxSizing: 'border-box',
	padding: '16px 24px 50px 24px',
	width: '100%',
	height: '100%',
	button: {
		...theme.fonts.button,
		color: theme.colors.mopla_secondary,
		backgroundColor: theme.colors.mopla_white,

		':hover': {
			opacity: 0.7,
			backgroundColor: theme.colors.mopla_white,
			color: theme.palette.secondary.main,
		},
		':focus': {
			opacity: 1,
			backgroundColor: theme.colors.mopla_white,
		},
	},

	[theme.breakpoints.up('tabletP')]: {
		height: 'max-content',
		padding: 16,
		borderRadius: 8,
		position: 'sticky',
		top: 24,
	},
}));

export const StyledRadio = styled(Radio)(() => ({
	padding: 0,
}));

export const SectionTitle = styled('div')(({ theme }) => ({
	...theme.fonts.subtitle1,
	marginBottom: 16,
	padding: '0 16px',
}));

export const BlockTitle = styled('span', {
	shouldForwardProp: (prop) => prop !== 'marginBottom',
})<{ marginBottom?: string }>(({ theme, marginBottom }) => ({
	...theme.fonts.subtitle1,
	marginBottom: marginBottom || 0,
}));

export const TargetPersonBlock = styled('div', {
	shouldForwardProp: (prop) => prop !== 'marginBottom',
})<{ marginBottom?: string; disabled?: boolean }>(
	({ theme, marginBottom, disabled }) => ({
		width: '100%',
		background: theme.colors.mopla_white,
		borderRadius: 8,
		padding: '24px 16px',
		boxSizing: 'border-box',
		display: 'grid',
		marginBottom: marginBottom || 0,
		opacity: disabled ? 0.4 : 1,
		position: 'relative',

		'&:after': disabled
			? {
					content: '""',
					display: 'block',
					position: 'absolute',
					left: 0,
					right: 0,
					bottom: 0,
					top: 0,
					zIndex: 10,
			  }
			: undefined,
	})
);

export const ItemWrapper = styled('div', {
	shouldForwardProp: (prop) =>
		!['marginTop', 'marginBottom', 'disabled'].includes(prop as any),
})<{ marginTop?: string; marginBottom?: string; disabled?: boolean }>(
	({ marginTop, marginBottom, disabled }) => ({
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr',
		alignItems: 'center',
		gridGap: 10,
		marginTop: marginTop || 0,
		marginBottom: marginBottom || 0,

		label: {
			marginBottom: '1px',
			opacity: disabled ? 0.4 : 1,
		},
	})
);

export const InnerForm = styled('div')(() => ({
	marginTop: 18,
	marginLeft: 32,
}));

export const FormRoot = styled('div')(() => ({
	width: '100%',

	'& > div': {
		width: '100%',
		marginBottom: '24px',
	},
}));

export const Description = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginBottom' && prop !== 'marginLeft',
})<{ marginBottom: string; marginLeft?: string }>(
	({ theme, marginBottom, marginLeft }) => ({
		...theme.fonts.body3,
		color: theme.colors.mopla_dark_grey,
		marginLeft: marginLeft || 0,
		marginBottom: marginBottom || 0,
	})
);

export const InfoBox = styled('div')(({ theme }) => ({
	width: '100%',
	background: theme.colors.mopla_white,
	padding: '24px 16px',
	boxSizing: 'border-box',
	borderRadius: 8,
	marginTop: 8,
}));

export const InfoBoxColumn = styled(InfoBox)(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: 16,
}));

export const Bold = styled('span')(() => ({
	fontWeight: '600',
}));

export const AttentionWrapper = styled('div')(({ theme }) => ({
	...theme.fonts.caption,
	color: theme.colors.mopla_dark_grey,
	backgroundColor: theme.colors.mopla_white,
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gap: 16,
}));

export const SpecialNeedsTitle = styled('span')(({ theme }) => ({
	...theme.fonts.body1_semibold,
}));

export const EditTicketsWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content',

	button: {
		background: 'transparent',
		border: 'none',
		outline: 'none',
		cursor: 'pointer',
	},
}));

export const EditPaymentWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	alignItems: 'center',
	marginTop: 16,

	button: {
		justifySelf: 'end',
		background: 'transparent',
		border: 'none',
		outline: 'none',
		cursor: 'pointer',
	},
}));

export const PaymentDetailsButton = styled('button')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	gridGap: 12,
	alignItems: 'center',
	color: theme.colors.mopla_secondary,
	padding: 0,

	background: 'transparent',
	border: 'none',
	outline: 'none',
	cursor: 'pointer',
}));

export const PaymentDetails = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content max-content',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gap: '4px 16px',
	...theme.fonts.body3,
	color: theme.colors.mopla_dark_grey,
	marginTop: 8,
}));

export const PaymentTitle = styled('span')(({ theme }) => ({
	color: theme.colors.mopla_black,
	...theme.fonts.subtitle3,
}));

export const LastStepGrid = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	gridGap: 24,
	padding: '0 16px',

	'#openSettings': {
		...theme.fonts.body1_semibold,
		color: theme.colors.mopla_white,
		width: 'max-content',
		height: 'min-content',
		padding: 0,
		marginBottom: 22,
		span: {
			marginRight: 8,
		},
	},
}));

export const LastStepTitle = styled('span')(({ theme }) => ({
	...theme.fonts.body1,
	color: theme.colors.mopla_white,
}));

export const LastStepTicket = styled('div')(() => ({
	width: '100%',
	display: 'grid',
	justifyContent: 'center',
}));

export const LoaderWrapper = styled('div')(() => ({
	width: '100%',
	display: 'grid',
	justifyContent: 'center',
}));

export const ModalText = styled('span')(({ theme }) => ({
	...theme.fonts.body1,
	marginBottom: 24,
	color: theme.colors.mopla_black,
}));

export const ModalTitle = styled('span')(({ theme }) => ({
	...theme.fonts.H6,
	marginTop: 40,
	marginBottom: 24,
}));

export const RouteSuggestionStyled = styled(RouteSuggestion)(() => ({
	padding: 0,
}));

export const AttendeeCountersWrapper = styled('div')(() => ({
	marginTop: 16,
	display: 'flex',
	flexDirection: 'column',
	gap: 24,
}));
