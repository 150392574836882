import { Dispatch, SetStateAction, useContext } from 'react';

import { BusinessLayerContext } from '../business-logic';

export type Photos = { id: number; src?: string; format: string }[];
type FilesValue = {
	uploadAndSavePhotos: (
		photos: Photos,
		setNumber: Dispatch<SetStateAction<number>>
	) => Promise<void>;
};

export const useFiles = (
	onSuccess: VoidFunction,
	onError: VoidFunction
): FilesValue => {
	const businessLayer = useContext(BusinessLayerContext);

	const uploadLicencePhoto = async (photo: string, format: string) => {
		try {
			const blob = await fetch(photo).then((r) => r.blob());
			const splitFileName = photo.split('/');
			const file = new File(
				[blob],
				`${splitFileName[splitFileName.length - 1]}.${format}`,
				{
					type: `image/${format}`,
				}
			);
			const payload = new FormData();
			payload.append('file', file);

			const res = await businessLayer.api.post(
				'/api/files/driver-licence/upload',
				payload
			);
			return res.fileId;
		} catch (e) {
			console.error('Error on file upload ', e);
			onError();
			return null;
		}
	};

	const uploadAndSavePhotos = async (
		photos: Photos,
		setNumber: Dispatch<SetStateAction<number>>
	) => {
		if (!photos.length) return;
		const uploadedIds = [];

		for (const photo of photos) {
			if (!photo.src) return;

			const fileId = await uploadLicencePhoto(photo.src, photo.format);
			if (!fileId) return;

			setNumber((prevState) => prevState + 1);
			uploadedIds.push(fileId);
		}

		if (!uploadedIds.length) return;

		try {
			await businessLayer.api.post('/api/command/saveDriverFiles', {
				fileIds: uploadedIds,
				fileType: 'DRIVER_LICENSE',
			});
			onSuccess();
		} catch (err) {
			console.error('error on files save', err);
			onError();
		}
	};

	return {
		uploadAndSavePhotos,
	};
};
