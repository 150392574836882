import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PluginListenerHandle } from '@capacitor/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';

import {
	fetchSubscriptions,
	fetchTickets,
	loadPassengerBookings,
} from '@mopla/business-logic';
import { Action, BusinessLayerContext } from '@mopla/business-logic';
import { BookingNotificationType } from '@mopla/data-models';

interface Payload {
	type: BookingNotificationType;
	bookingNumber?: string;
	subscriptionId?: string;
}

export function NotificationListener() {
	const navigate = useNavigate();
	const businessLayer = useContext(BusinessLayerContext);

	useEffect(() => {
		const notificationReceivedListener: PluginListenerHandle =
			FirebaseMessaging.addListener('notificationReceived', (event) => {
				const notification = event.notification;
				const payload = notification.data as Payload;

				if (payload.bookingNumber) {
					businessLayer.dispatch(loadPassengerBookings() as Action);
				}
				if (payload.subscriptionId) {
					businessLayer.dispatch(fetchSubscriptions());
					businessLayer.dispatch(fetchTickets());
				}
				console.log('notificationReceived', { event });
			});

		const notificationActionPerformedListener: PluginListenerHandle =
			FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
				const notification = event.notification;
				const payload = notification.data as Payload;

				if (payload.bookingNumber) {
					// refetch bookings
					businessLayer.dispatch(loadPassengerBookings() as Action);
					// route to ticket details
					navigate('/home/tickets?ticket=' + payload.bookingNumber);
				}
				if (payload.subscriptionId) {
					businessLayer.dispatch(fetchSubscriptions());
					businessLayer.dispatch(fetchTickets());
					navigate('/home/dticket');
				}

				console.log('notificationActionPerformed', { event });
			});

		return () => {
			notificationReceivedListener.remove();
			notificationActionPerformedListener.remove();
		};
	}, []);

	return null;
}
