import React from 'react';

export const BusWithPeopleIcon = () => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask id="path-2-inside-1_5230_21810" fill="white">
				<path d="M19 22.75C19 22.3358 19.3358 22 19.75 22H20.25C20.6642 22 21 22.3358 21 22.75V25.25C21 25.6642 20.6642 26 20.25 26H19.75C19.3358 26 19 25.6642 19 25.25V22.75Z" />
			</mask>
			<path
				d="M19.75 24H20.25V20H19.75V24ZM19 22.75V25.25H23V22.75H19ZM20.25 24H19.75V28H20.25V24ZM21 25.25V22.75H17V25.25H21ZM19.75 24C20.4404 24 21 24.5596 21 25.25H17C17 26.7688 18.2312 28 19.75 28V24ZM19 25.25C19 24.5596 19.5596 24 20.25 24V28C21.7688 28 23 26.7688 23 25.25H19ZM20.25 24C19.5596 24 19 23.4404 19 22.75H23C23 21.2312 21.7688 20 20.25 20V24ZM19.75 20C18.2312 20 17 21.2312 17 22.75H21C21 23.4404 20.4404 24 19.75 24V20Z"
				fill="currentColor"
				mask="url(#path-2-inside-1_5230_21810)"
			/>
			<mask id="path-4-inside-2_5230_21810" fill="white">
				<path d="M11 22.75C11 22.3358 11.3358 22 11.75 22H12.25C12.6642 22 13 22.3358 13 22.75V25.25C13 25.6642 12.6642 26 12.25 26H11.75C11.3358 26 11 25.6642 11 25.25V22.75Z" />
			</mask>
			<path
				d="M11.75 24H12.25V20H11.75V24ZM11 22.75V25.25H15V22.75H11ZM12.25 24H11.75V28H12.25V24ZM13 25.25V22.75H9V25.25H13ZM11.75 24C12.4404 24 13 24.5596 13 25.25H9C9 26.7688 10.2312 28 11.75 28V24ZM11 25.25C11 24.5596 11.5596 24 12.25 24V28C13.7688 28 15 26.7688 15 25.25H11ZM12.25 24C11.5596 24 11 23.4404 11 22.75H15C15 21.2312 13.7688 20 12.25 20V24ZM11.75 20C10.2312 20 9 21.2312 9 22.75H13C13 23.4404 12.4404 24 11.75 24V20Z"
				fill="currentColor"
				mask="url(#path-4-inside-2_5230_21810)"
			/>
			<mask id="path-6-inside-3_5230_21810" fill="white">
				<path d="M11 20.9453C11 20.8537 11.0079 20.7623 11.0235 20.6721L11.6896 16.8292C11.7727 16.35 12.1885 16 12.6749 16H19.3251C19.8115 16 20.2273 16.35 20.3104 16.8292L20.9765 20.6721C20.9921 20.7623 21 20.8537 21 20.9453V23.4C21 24.2837 20.2837 25 19.4 25H16H12.6C11.7163 25 11 24.2837 11 23.4V20.9453Z" />
			</mask>
			<path
				d="M20.9765 20.6721L22.9471 20.3305L20.9765 20.6721ZM19 20.9453V23.4H23V20.9453H19ZM13 23.4V20.9453H9V23.4H13ZM19.4 23H16V27H19.4V23ZM16 23H12.6V27H16V23ZM12.9941 21.0136L13.6602 17.1708L9.71899 16.4876L9.05289 20.3305L12.9941 21.0136ZM18.3398 17.1708L19.0059 21.0136L22.9471 20.3305L22.281 16.4876L18.3398 17.1708ZM12.6749 18H19.3251V14H12.6749V18ZM22.281 16.4876C22.0318 15.0499 20.7843 14 19.3251 14V18C18.8387 18 18.4228 17.65 18.3398 17.1708L22.281 16.4876ZM9 23.4C9 25.3882 10.6118 27 12.6 27V23C12.8209 23 13 23.1791 13 23.4H9ZM19 23.4C19 23.1791 19.1791 23 19.4 23V27C21.3882 27 23 25.3882 23 23.4H19ZM23 20.9453C23 20.7393 22.9823 20.5336 22.9471 20.3305L19.0059 21.0136C19.002 20.9911 19 20.9682 19 20.9453H23ZM13.6602 17.1708C13.5772 17.65 13.1613 18 12.6749 18V14C11.2157 14 9.96819 15.0499 9.71899 16.4876L13.6602 17.1708ZM13 20.9453C13 20.9682 12.998 20.9911 12.9941 21.0136L9.05289 20.3305C9.0177 20.5336 9 20.7393 9 20.9453H13Z"
				fill="currentColor"
				mask="url(#path-6-inside-3_5230_21810)"
			/>
			<path d="M19 20.5H12.25" stroke="currentColor" />
			<mask id="path-9-inside-4_5230_21810" fill="white">
				<path d="M21.25 19H20V21H21.25C21.6642 21 22 20.6642 22 20.25V19.75C22 19.3358 21.6642 19 21.25 19Z" />
			</mask>
			<path
				d="M20 19V17H18V19H20ZM20 21H18V23H20V21ZM20 21H21.25V17H20V21ZM20 19.75V20.25H24V19.75H20ZM21.25 19H20V23H21.25V19ZM18 19V21H22V19H18ZM20 20.25C20 19.5596 20.5596 19 21.25 19V23C22.7688 23 24 21.7688 24 20.25H20ZM21.25 21C20.5596 21 20 20.4404 20 19.75H24C24 18.2312 22.7688 17 21.25 17V21Z"
				fill="currentColor"
				mask="url(#path-9-inside-4_5230_21810)"
			/>
			<mask id="path-11-inside-5_5230_21810" fill="white">
				<path d="M10.75 21L13 21L13 19L10.75 19C10.3358 19 10 19.3358 10 19.75L10 20.25C10 20.6642 10.3358 21 10.75 21Z" />
			</mask>
			<path
				d="M13 21L13 23L15 23L15 21L13 21ZM13 19L15 19L15 17L13 17L13 19ZM13 19L10.75 19L10.75 23L13 23L13 19ZM12 20.25L12 19.75L8 19.75L8 20.25L12 20.25ZM10.75 21L13 21L13 17L10.75 17L10.75 21ZM15 21L15 19L11 19L11 21L15 21ZM12 19.75C12 20.4404 11.4404 21 10.75 21L10.75 17C9.23122 17 8 18.2312 8 19.75L12 19.75ZM10.75 19C11.4404 19 12 19.5596 12 20.25L8 20.25C8 21.7688 9.23121 23 10.75 23L10.75 19Z"
				fill="currentColor"
				mask="url(#path-11-inside-5_5230_21810)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.3987 11.8197C25.6161 12.4147 25.1374 13 24.5039 13H20.4197C20.2191 12.3884 19.9694 11.8165 19.6736 11.3026C20.0259 10.5319 20.4857 9.91265 21.0144 9.51126C21.4296 9.8184 21.9434 10 22.4995 10C23.0238 10 23.5104 9.83862 23.9123 9.56283C24.5255 10.0549 25.0419 10.8429 25.3987 11.8197ZM17.7207 10.4574C18.3454 10.9829 18.8812 11.7875 19.2773 12.7807C19.5184 13.3851 19.0376 14 18.3868 14H13.5874C12.9619 14 12.4999 13.4093 12.7283 12.827C13.1238 11.8185 13.6624 11.0008 14.2919 10.4669C14.7766 10.803 15.3651 11 15.9995 11C16.6399 11 17.2335 10.7993 17.7207 10.4574ZM11.6042 13C11.7883 12.4386 12.0139 11.9106 12.2784 11.4303C11.9302 10.6252 11.4671 9.97467 10.9308 9.55C10.5253 9.83361 10.0319 10 9.49951 10C8.95122 10 8.44416 9.8235 8.03204 9.52421C7.39723 10.0131 6.86283 10.8169 6.4965 11.8196C6.27912 12.4146 6.75781 13 7.39127 13H11.6042ZM10.9649 7.5C10.9649 8.32843 10.2933 9 9.46489 9C8.63647 9 7.96489 8.32843 7.96489 7.5C7.96489 6.67157 8.63647 6 9.46489 6C10.2933 6 10.9649 6.67157 10.9649 7.5ZM23.9303 7.5C23.9303 8.32843 23.2587 9 22.4303 9C21.6019 9 20.9303 8.32843 20.9303 7.5C20.9303 6.67157 21.6019 6 22.4303 6C23.2587 6 23.9303 6.67157 23.9303 7.5ZM18.012 8C18.012 9.10457 17.1165 10 16.012 10C14.9074 10 14.012 9.10457 14.012 8C14.012 6.89543 14.9074 6 16.012 6C17.1165 6 18.012 6.89543 18.012 8Z"
				fill="currentColor"
			/>
		</svg>
	);
};
