import { CSSProperties } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Button)<{
	ownerState: { justify?: CSSProperties['justifyContent'] };
}>(({ theme, startIcon, endIcon, ownerState }) => ({
	...theme.fonts.button,
	padding: '16px',
	width: '100%',
	textTransform: 'none',
	borderRadius: '8px',
	display: 'flex',
	justifyContent: ownerState.justify
		? ownerState.justify
		: startIcon || endIcon
		? 'space-between'
		: 'center',
}));

export const Content = styled('div', {
	shouldForwardProp: (propName) => propName !== 'hide',
})<{ hide?: boolean }>(({ hide }) => ({
	opacity: hide ? 0 : 1,
}));

export const LoaderWrapper = styled('div')(() => ({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
