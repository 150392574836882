import { ILaunch } from '../hooks/useAppLaunch';

export interface LaunchAction {
	type: LaunchActionTypes;
}

export enum LaunchActionTypes {
	SetLaunch = '[Launch] Set Launch',
}

export interface SetLaunchAction extends LaunchAction {
	type: LaunchActionTypes.SetLaunch;
	payload: ILaunch;
}

export const setLaunch = (launch: ILaunch): SetLaunchAction => ({
	type: LaunchActionTypes.SetLaunch,
	payload: {
		...launch,
	},
});
