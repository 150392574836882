export const colors = {
	mopla_primary: '#6FDB89',
	mopla_primary_dark: '#33AB50',
	mopla_red: '#DD6060',
	mopla_error_red: '#DA1E28',
	mopla_secondary: '#1C308B',
	mopla_secondary_dark: '#0E1C5B',
	mopla_secondary_light: '#A4B1ED',
	mopla_black: '#000000',
	mopla_mid_grey: '#EDEDED',
	mopla_white: '#FFFFFF',
	mopla_dark_grey: '#6A6A6A',
	mopla_grey: '#B4B4B4',
	mopla_light_grey: '#F2F2F2',

	mopla_orange: '#F99810',
	mopla_super_light_grey: '#F6F6F8',
	mopla_cream: '#FAFBFD',
	mopla_light_blue: '#D6DCF7',
	mopla_super_light_blue: '#ECEEF8',

	driver_light_grey: '#F0F0F0',
	driver_light_blue: '#A4B1ED',
	driver_bright_yellow: '#FFE66D',
};
