/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FilesService {
	/**
	 * upload file for driver licence check purposes (allowed formats are only jpeg and png)
	 * request saving new file for driver (allowed formats are only jpeg and png)
	 * @param formData provide file
	 * @returns any OK
	 * @throws ApiError
	 */
	public static postApiFilesDriverLicenceUpload(formData: {
		file?: Blob;
	}): CancelablePromise<{
		/**
		 * the servers id for saved file
		 */
		fileId?: string;
		/**
		 * the servers name for saved file
		 */
		name?: string;
	}> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/files/driver-licence/upload',
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				422: `Uploaded file is faulty`,
				500: `File storage internal exception`,
			},
		});
	}

	/**
	 * download file for driver licence check purposes (distributor can see files of own drivers)
	 * download file for driver licence check purposes (distributor can see files of own drivers)
	 * @param fileuuid uuid of file to be downloaded
	 * @returns binary OK
	 * @throws ApiError
	 */
	public static getApiFilesDriverLicenceDownload(
		fileuuid: string
	): CancelablePromise<Blob> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/files/driver-licence/download/{fileuuid}',
			path: {
				fileuuid: fileuuid,
			},
			errors: {
				404: `File not belongs to distributor`,
				500: `File storage internal exception`,
			},
		});
	}
}
