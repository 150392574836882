import React from 'react';

export const InformationIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12 4a8 8 0 110 16 8 8 0 010-16zm0-2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm1 6.5a1 1 0 10-2 0 1 1 0 002 0zm0 8h-2V11h2v5.5z"
				clipRule="evenodd"
			/>
		</svg>
	);
};
