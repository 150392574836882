import React from 'react';

export const LocationStopIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask id="path-1-inside-1_7520_2085" fill="white">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM12 9V5H14V9V11V15H12V11H8V15H6V11V9L6 5H8V9H12Z"
				/>
			</mask>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM12 9V5H14V9V11V15H12V11H8V15H6V11V9L6 5H8V9H12Z"
				fill="black"
			/>
			<path
				d="M12 9V10H13V9H12ZM12 5V4H11V5H12ZM14 5H15V4H14V5ZM14 15V16H15V15H14ZM12 15H11V16H12V15ZM12 11H13V10H12V11ZM8 11V10H7V11H8ZM8 15V16H9V15H8ZM6 15H5V16H6V15ZM6 9H5H6ZM6 5V4H5V5H6ZM8 5H9V4H8V5ZM8 9H7V10H8V9ZM10 19C14.9706 19 19 14.9706 19 10H17C17 13.866 13.866 17 10 17V19ZM1 10C1 14.9706 5.02944 19 10 19V17C6.13401 17 3 13.866 3 10H1ZM10 1C5.02944 1 1 5.02944 1 10H3C3 6.13401 6.13401 3 10 3V1ZM19 10C19 5.02944 14.9706 1 10 1V3C13.866 3 17 6.13401 17 10H19ZM10 21C16.0751 21 21 16.0751 21 10H19C19 14.9706 14.9706 19 10 19V21ZM-1 10C-1 16.0751 3.92487 21 10 21V19C5.02944 19 1 14.9706 1 10H-1ZM10 -1C3.92487 -1 -1 3.92487 -1 10H1C1 5.02944 5.02944 1 10 1V-1ZM21 10C21 3.92487 16.0751 -1 10 -1V1C14.9706 1 19 5.02944 19 10H21ZM13 9V5H11V9H13ZM12 6H14V4H12V6ZM13 5V9H15V5H13ZM13 9V11H15V9H13ZM13 11V15H15V11H13ZM14 14H12V16H14V14ZM13 15V11H11V15H13ZM12 10H8V12H12V10ZM7 11V15H9V11H7ZM8 14H6V16H8V14ZM7 15V11H5V15H7ZM7 11V9H5V11H7ZM7 9L7 5H5L5 9H7ZM6 6H8V4H6V6ZM7 5V9H9V5H7ZM8 10H12V8H8V10Z"
				fill="black"
				mask="url(#path-1-inside-1_7520_2085)"
			/>
		</svg>
	);
};
