import { EntityDescriptor } from '../business-logic';
import { locationSchema } from '../schemas/location';

export const locationEntity: EntityDescriptor = {
	location: {
		migrationStrategies: {
			1: function (oldDoc) {
				oldDoc.id = oldDoc.id ?? '';
				return oldDoc;
			},
		},
		schema: locationSchema,
	},
};
