import { styled } from '@mui/material/styles';

export const Map = styled('div')(({ theme }) => ({
	width: '100%',
	height: 184,
	backgroundColor: theme.colors.mopla_super_light_blue,
	borderRadius: '8px 8px 0 0',
}));

export const MapWrapper = styled('div')(({ theme }) => ({
	boxShadow: '0px 4px 21px rgba(28, 48, 139, 0.1)',
	borderRadius: 8,
	overflow: 'hidden',
	height: 'fit-content',

	button: {
		border: 'none',
		outline: 'none',
		justifyContent: 'center',
		'&:hover': {
			border: 'none',
			outline: 'none',
			background: 'transparent',
			color: theme.colors.mopla_secondary_dark,
		},
	},
	'#departureIcon': {
		padding: 4,
		borderRadius: '50%',
		background: theme.colors.mopla_white,
		marginTop: '-24px',
		marginLeft: '-24px',
		color: theme.colors.mopla_secondary,
	},
	'#arrivalIcon': {
		padding: 4,
		borderRadius: '50%',
		background: theme.colors.mopla_primary,
		marginTop: '-24px',
		marginLeft: '-24px',
		color: theme.colors.mopla_white,
	},
}));
