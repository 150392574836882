import { ILocationSuggestStored } from '@mopla/data-models';

export interface LocationAction {
	type: LocationActionTypes;
}

export enum LocationActionTypes {
	AddLocation = '[Location] Add Location',
	AddLocationResult = '[Location] Add Location Result',
	SaveLocation = '[Locations] Save Location',
}

// ==================== Add Location ====================

export interface AddLocationAction extends LocationAction {
	type: LocationActionTypes.AddLocation;
	payload: ILocationSuggestStored;
}

export const addLocation = (
	location: ILocationSuggestStored
): AddLocationAction => ({
	type: LocationActionTypes.AddLocation,
	payload: {
		...location,
	},
});

export interface AddLocationResultAction extends LocationAction {
	type: LocationActionTypes.AddLocationResult;
	error?: Error;
	payload: {
		id?: string;
	};
}

export const addLocationResult = (
	id?: string,
	error?: Error
): AddLocationResultAction => ({
	type: LocationActionTypes.AddLocationResult,
	error,
	payload: {
		id: id,
	},
});
