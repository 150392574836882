import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { IBooking, TransportType } from '@mopla/data-models';
import { ArrivalIcon, DepartureIcon, SearchHeader } from '@mopla/ui';
import {
	formatDate,
	formatTime,
	prepareItineraryOperators,
} from '@mopla/utils';

import { PaymentSummary } from './PaymentSummary';
import {
	BottomInfo,
	Code,
	CodeWrapper,
	Dash,
	DateBlock,
	DateWrapper,
	DetailsWrapper,
	EllipseText,
	Root,
	Separator,
	SideCircle,
	Solid,
	StationWrapper,
	TicketGrid,
	TicketNumber,
} from './TicketQR.styles';

export interface TicketQRProps {
	ticket?: IBooking;
	onClose: () => void;
	showHeader?: boolean;
}

export const TicketQR: React.FC<TicketQRProps> = ({
	ticket,
	onClose,
	showHeader = true,
}) => {
	const { t } = useTranslation('tickets');

	const preparedOperators = prepareItineraryOperators<string>(
		ticket?.itinerary,
		(operators) => t('text.operator', { operator: operators.join(', ') })
	);

	useEffect(() => {
		if (!ticket) {
			onClose();
		}
	}, [ticket, onClose]);

	const ticketsLegs = ticket?.itinerary?.legs || [];
	const walkBefore = ticket ? ticketsLegs[0].mode === TransportType.WALK : null;
	const walkAfter = ticket
		? ticketsLegs[ticketsLegs.length - 1].mode === TransportType.WALK
		: null;

	const firstStation = ticket
		? walkBefore
			? ticketsLegs[1]
			: ticketsLegs[0]
		: null;
	const firstStationName = ticket ? firstStation?.from?.name : null;
	const lastStation = ticket
		? walkAfter
			? ticketsLegs[ticketsLegs.length - 2]
			: ticketsLegs[ticketsLegs.length - 1]
		: null;
	const lastStationName = ticket ? lastStation?.to?.name : null;

	const startDate = ticket
		? formatDate(
				new Date(ticket?.itinerary?.startDateTime || ''),
				'weekDayLong'
		  )
		: null;

	const startTime = formatTime(
		new Date(ticket?.itinerary?.startDateTime || '')
	);

	const endTime = formatTime(new Date(ticket?.itinerary?.endDateTime || ''));

	const QRCodeView = (
		<>
			<CodeWrapper>
				<Code data-testid="savedTicket-QRcode">
					{ticket && (
						<QRCode value={ticket.bookingNumber || ''} fgColor="#6FDB89" />
					)}
					<TicketNumber data-testid="savedTicket-bookingCode">
						{ticket?.bookingNumber || ''}
					</TicketNumber>
				</Code>
			</CodeWrapper>
			<Separator>
				<SideCircle left={true} />
				<Dash />
				<SideCircle />
			</Separator>
		</>
	);

	return (
		<Root>
			{showHeader && (
				<SearchHeader
					variant="secondary"
					title={t('title.my_booking_code')}
					open={true}
					showBack={false}
					showClose={true}
					onClose={onClose}
				/>
			)}
			<TicketGrid>
				{QRCodeView}
				<DetailsWrapper>
					<DateWrapper data-testid="route-routeDate-block">
						<DateBlock>{startDate}</DateBlock>
					</DateWrapper>
					<StationWrapper
						sx={{ mb: '8px' }}
						data-testid="route-firstStation-block"
					>
						<EllipseText variant="body1" color="mopla_black">
							{startTime}
						</EllipseText>
						<DepartureIcon />
						<EllipseText variant="body1" color="mopla_black">
							{firstStationName}
						</EllipseText>
					</StationWrapper>
					<StationWrapper data-testid="route-lastStation-block">
						<EllipseText variant="body1" color="mopla_black">
							{endTime}
						</EllipseText>
						<ArrivalIcon />
						<EllipseText variant="body1" color="mopla_black">
							{lastStationName}
						</EllipseText>
					</StationWrapper>
					<Separator solid>
						<Solid />
					</Separator>
					{ticket && (
						<>
							{/*TODO adapt for LBTs and non-mixed rides */}
							<PaymentSummary booking={ticket} />
							<Separator solid>
								<Solid />
							</Separator>
						</>
					)}
					<BottomInfo>
						<span data-testid="route-regulations-text">
							{t('text.ticket_description')}
						</span>
						{preparedOperators && (
							<span data-testid="route-operator-text">{preparedOperators}</span>
						)}
					</BottomInfo>
				</DetailsWrapper>
			</TicketGrid>
		</Root>
	);
};
