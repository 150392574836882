import { styled } from '@mui/material/styles';

import { Text } from '@mopla/ui';

export const Root = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content 1fr',
	boxSizing: 'border-box',
	width: '100%',
	height: '100%',
	background: theme.colors.mopla_secondary,
	overflowY: 'auto',
}));

export const TicketGrid = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content max-content',
	padding: '0 24px 34px 24px',
	boxSizing: 'border-box',
	width: '100%',
	height: '100%',
	overflow: 'auto',
	background: theme.colors.mopla_secondary,

	[theme.breakpoints.up('tabletP')]: {
		padding: 0,
		overflow: 'hidden',
		background: theme.colors.mopla_white,
		borderRadius: 8,
	},
}));

export const CodeWrapper = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_white,
	borderRadius: '8px 8px 0 0',
	padding: 32,
	boxSizing: 'border-box',
	width: '100%',
	height: 330,
}));

export const Code = styled('div')(() => ({
	borderRadius: 8,
	width: '100%',
	height: '100%',
	display: 'grid',
	gridTemplateRows: 'max-content 1fr',
	justifyContent: 'center',
}));

export const DetailsWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content max-content 1fr',
	background: theme.colors.mopla_white,
	borderRadius: '0 0 8px 8px',
	padding: '16px 22px',
	boxSizing: 'border-box',
	width: '100%',
}));

export const DateWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content',
	justifyContent: 'space-between',
	marginBottom: 16,
}));

export const DateBlock = styled('span')(({ theme }) => ({
	...theme.fonts.body1_semibold,
	color: theme.colors.mopla_black,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

export const TicketNumber = styled('span')(({ theme }) => ({
	...theme.fonts.body1,
	color: theme.colors.mopla_dark_grey,
	marginTop: 14,
	textAlign: 'right',
}));

export const StationWrapper = styled('div')(({ theme }) => ({
	color: theme.colors.mopla_secondary,
	display: 'grid',
	gridTemplateColumns: 'max-content max-content 1fr',
	justifyContent: 'start',
	alignItems: 'center',
	alignSelf: 'baseline',
}));

export const EllipseText = styled(Text)(() => ({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

export const BottomInfo = styled('span')(({ theme }) => ({
	...theme.fonts.body3,
	color: theme.colors.mopla_dark_grey,
	alignSelf: 'end',
	display: 'grid',
	gridTemplateRows: '1fr max-content',
}));

export const Separator = styled('div', {
	shouldForwardProp: (prop) => prop !== 'solid',
})<{ solid?: boolean }>(({ theme, solid }) => ({
	background: theme.colors.mopla_white,
	display: 'grid',
	gridTemplateColumns: solid ? '1fr' : 'max-content 1fr max-content',
	height: 32,
	width: '100%',
}));

export const Dash = styled('div')(({ theme }) => ({
	height: 0,
	width: '100%',
	border: `1px dashed ${theme.colors.mopla_secondary}`,
	alignSelf: 'center',

	[theme.breakpoints.up('tabletP')]: {
		border: `1px dashed ${theme.colors.driver_light_grey}`,
	},
}));

export const Solid = styled('div')(({ theme }) => ({
	height: 0,
	width: '100%',
	border: `1px solid ${theme.colors.mopla_light_grey}`,
	alignSelf: 'center',
}));

export const SideCircle = styled('div', {
	shouldForwardProp: (prop) => prop !== 'left',
})<{ left?: boolean }>(({ theme, left }) => ({
	background: theme.colors.mopla_secondary,
	height: 32,
	width: 32,
	borderRadius: '50%',
	margin: left ? '0 0 0 -16px' : '0 -16px 0 0',

	[theme.breakpoints.up('tabletP')]: {
		background: theme.colors.driver_light_grey,
	},
}));

export const PaymentInfo = styled('div')(({ theme }) => ({
	display: 'grid',
	gap: '16px',
	gridTemplateColumns: '1fr max-content',

	'& > span:first-of-type': {
		...theme.fonts.body1,
	},

	'& > span:last-of-type': {
		...theme.fonts.body1_semibold,
	},
}));
