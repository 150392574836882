export const localStorageManager = <T>(
	key: string,
	storage: Storage = localStorage
) => {
	const _storage = storage;

	const get = (): T | null => {
		const val = _storage.getItem(key);

		return val ? (JSON.parse(val) as T) : null;
	};

	const set = (val: T) => {
		_storage.setItem(key, JSON.stringify(val));
	};

	const clear = () => {
		_storage.removeItem(key);
	};

	return {
		get,
		set,
		clear,
	};
};
