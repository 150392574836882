export const dataChangesSchema = {
	title: 'data changes schema',
	version: 1,
	primaryKey: 'changeId',
	type: 'object',
	properties: {
		changeId: {
			type: 'string',
			maxLength: 70,
		},
		synced: {
			type: 'boolean',
		},
		checkDone: {
			type: 'boolean',
		},
		vehicleData: {
			type: 'string',
		},
		insertTime: {
			type: 'number',
		},
		scheduledLegId: {
			type: 'string',
		},
		bookingId: {
			type: 'string',
		},
		type: {
			type: 'string',
			// "START" or "DONE" leg | "EXECUTING" or "DONE" or "CANCELED_NO_SHOW" booking
		},
		attempts: {
			type: 'number',
		},
		ref: {
			type: 'string',
		},
	},
	required: ['changeId', 'type', 'insertTime'],
};
