import React, { ReactNode } from 'react';
import { IconButton } from '@mui/material';
import { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer/SwipeableDrawer';

import { CrossIcon } from '../../icons/Cross';
import { LeftChevronIcon } from '../../icons/LeftChevron';

import { Content, Header, Root, Title } from './SideOverlay.styles';

interface OverlayProps
	extends Omit<SwipeableDrawerProps, 'anchor' | 'onClose'> {
	children: ReactNode;
	onClose?: () => void;
	onBack?: () => void;
	title: string;
	widthPercentage?: number;
}

const SideOverlay: React.FC<OverlayProps> = ({
	children,
	onClose,
	onBack,
	title,
	widthPercentage,
	...props
}) => {
	return (
		<Root
			anchor="right"
			onClose={onClose || (() => undefined)}
			widthPercentage={widthPercentage}
			{...props}
		>
			<Header onClose={!!onClose} onBack={!!onBack}>
				{onBack ? (
					<IconButton onClick={onBack}>
						<LeftChevronIcon />
					</IconButton>
				) : null}
				<Title>{title}</Title>
				{onClose ? (
					<IconButton onClick={onClose}>
						<CrossIcon />
					</IconButton>
				) : null}
			</Header>
			<Content>{children}</Content>
		</Root>
	);
};

export default SideOverlay;
