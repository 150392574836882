import React, { ReactNode } from 'react';
import { IconButton, SwipeableDrawerProps } from '@mui/material';

import { LeftChevronIcon } from '../../icons/LeftChevron';

import { Content, Header, Root, Title } from './FullscreenOverlay.styles';

interface OverlayProps extends SwipeableDrawerProps {
	children: ReactNode;
	onBack: () => void;
	title: string;
}

const FullscreenOverlay: React.FC<OverlayProps> = ({
	children,
	onBack,
	title,
	...props
}) => {
	return (
		<Root {...props}>
			<Header>
				<IconButton onClick={onBack}>
					<LeftChevronIcon />
				</IconButton>
				<Title>{title}</Title>
			</Header>
			<Content>{props.open ? children : null}</Content>
		</Root>
	);
};

export default FullscreenOverlay;
