import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { CheckboxWithLink } from '@mopla/ui/molecules/CheckboxWithLink/CheckboxWithLink';

const linkByLang: Record<string, string> = {
	de: 'https://www.mopla.solutions/de/agb',
	en: 'https://www.mopla.solutions/de/agb',
	fr: 'https://www.mopla.solutions/de/agb',
	pl_PL: 'https://www.mopla.solutions/de/agb',
	tr: 'https://www.mopla.solutions/de/agb',
	cs_CZ: 'https://www.mopla.solutions/de/agb',
	uk_UA: 'https://www.mopla.solutions/de/agb',
};

interface IProps {
	checked: boolean;
	toggleCheckbox: VoidFunction;
}

export const TermsAndConditions: React.FC<IProps> = (props) => {
	const { checked, toggleCheckbox } = props;
	const { t } = useTranslation(['registration']);

	return (
		<CheckboxWithLink
			url={linkByLang[i18n.language]}
			linkText={t('text.check_terms_and_policy', {
				value: t('text.terms_of_use'),
			})}
			checked={checked}
			toggleCheckbox={toggleCheckbox}
		/>
	);
};
