import { actionGenerator } from '@mopla/utils';

export enum VoucherActionTypes {
	FetchVouchers = '[Voucher] Fetch Active Vouchers',
	FetchVouchersResult = '[Voucher] Fetch Active Vouchers Result',
	RedeemVoucher = '[Voucher] Redeem Voucher',
	RedeemVoucherResult = '[Voucher] Redeem Voucher Result',
	DeleteVoucher = '[Voucher] Delete Voucher',
	DeleteVoucherResult = '[Voucher] Delete Voucher Result',
}

export const fetchVouchers = actionGenerator<VoucherActionTypes.FetchVouchers>(
	VoucherActionTypes.FetchVouchers
);

export const fetchVouchersResult =
	actionGenerator<VoucherActionTypes.FetchVouchersResult>(
		VoucherActionTypes.FetchVouchersResult
	);

interface IRedeemVoucherPayload {
	voucherCode: string;
}

export const redeemVoucher = actionGenerator<
	VoucherActionTypes.RedeemVoucher,
	IRedeemVoucherPayload
>(VoucherActionTypes.RedeemVoucher);

export const redeemVoucherResult =
	actionGenerator<VoucherActionTypes.RedeemVoucherResult>(
		VoucherActionTypes.RedeemVoucherResult
	);

export const deleteVoucher = actionGenerator<
	VoucherActionTypes.DeleteVoucher,
	IRedeemVoucherPayload
>(VoucherActionTypes.DeleteVoucher);

export const deleteVoucherResult =
	actionGenerator<VoucherActionTypes.DeleteVoucherResult>(
		VoucherActionTypes.DeleteVoucherResult
	);

export type TFetchVouchersAction = ReturnType<typeof fetchVouchers>;
export type TRedeemVoucherAction = ReturnType<typeof redeemVoucher>;
export type TDeleteVoucherAction = ReturnType<typeof deleteVoucher>;
