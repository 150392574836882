import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import { FlexColumn, IconMessage, InfoBaseIcon } from '@mopla/ui';

import { BlockTitle, InfoBox, LoaderWrapper } from './BookingFlow.styles';

interface IPaymentMethodStepProps {
	isPreparing?: boolean;
}

export const PaymentMethodStep: React.FC<IPaymentMethodStepProps> = ({
	isPreparing = false,
}) => {
	const { t } = useTranslation('booking');

	return (
		<InfoBox>
			<BlockTitle>{t('payment.no_payment_method_subtitle')}</BlockTitle>
			<FlexColumn gap={16} sx={{ mt: 2 }}>
				{isPreparing ? (
					<LoaderWrapper>
						<CircularProgress />
					</LoaderWrapper>
				) : (
					<>
						<span data-testid="booking-purchaseSummary-paymentMethod-text">
							{t('payment.no_payment_method_body')}
						</span>
						<IconMessage
							icon={<InfoBaseIcon />}
							message={t('payment.no_payment_method_info')}
						/>
					</>
				)}
			</FlexColumn>
		</InfoBox>
	);
};
