import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconMessage, InfoBaseIcon, InfoModal } from '@mopla/ui';

interface IProps {
	onSubmit: VoidFunction;
	onCancel: VoidFunction;
}

export const PasswordlessLoginModal: FC<IProps> = (props) => {
	const { onSubmit, onCancel } = props;
	const { t } = useTranslation(['logIn']);

	return (
		<InfoModal
			title={t('passwordless.header.main')}
			infoText={t('passwordless.text.description')}
			additionalInfo={
				<IconMessage
					icon={<InfoBaseIcon />}
					message={t('passwordless.text.additional_text')}
				/>
			}
			submitTitle={t('passwordless.button.submit')}
			cancelTitle={t('passwordless.button.reject')}
			onSubmit={onSubmit}
			onCancel={onCancel}
		/>
	);
};
