import React from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceStatusBlock } from '../..//molecules/ServiceStatusBlock/ServiceStatusBlock';
import Button from '../../atoms/Button/Button';
import { Text } from '../../atoms/Text/Text';
import { ArrowRightIcon } from '../../icons/ArrowRight';
import { FailedAnim } from '../Animations';

import {
	Content,
	ErrorContent,
	FooterContainer,
	NoResultsWrapper,
} from './NoResultFound.styles';

interface NoResultFoundProps {
	onClick: () => void;
	buttonText: string;
	isInMoplaArea: boolean;
	rideDetailsBlock: React.ReactNode;
}

export const NoResultFound: React.FC<NoResultFoundProps> = ({
	onClick,
	buttonText,
	isInMoplaArea,
	rideDetailsBlock,
}) => {
	const { t } = useTranslation(['common', 'searchResults']);

	return (
		<NoResultsWrapper data-testid="search-noResults-card">
			{rideDetailsBlock}
			{isInMoplaArea && (
				<ErrorContent>
					<FailedAnim />
					<Text variant="subtitle1">
						{t('searchResults:noResults.in_mopla_area_message1')}
					</Text>
					<Text variant="body1">
						{t('searchResults:noResults.in_mopla_area_message2')}
					</Text>
				</ErrorContent>
			)}
			{!isInMoplaArea && (
				<>
					<Content>
						<ServiceStatusBlock
							title={t('common:mopla_area.title.deutschlandticket')}
							mainText={t('common:mopla_area.nationwide')}
							status={true}
						/>
					</Content>
					<Content>
						<ServiceStatusBlock
							title={t('common:mopla_area.title.timetable')}
							mainText={t('common:mopla_area.timetable_not_available')}
							additionalText={t('common:mopla_area.description')}
							status={false}
						/>
					</Content>
				</>
			)}
			<FooterContainer>
				<Button
					type="button"
					onClick={onClick}
					variant="primary"
					data-testid="search-noResults-submit-button"
					icon={<ArrowRightIcon />}
				>
					{buttonText}
				</Button>
			</FooterContainer>
		</NoResultsWrapper>
	);
};
