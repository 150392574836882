import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	backgroundColor: theme.colors.mopla_white,
	borderRadius: 8,
	padding: '16px 18px',
}));

export const HeaderWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateColumns: '1fr max-content',
	color: theme.colors.mopla_secondary_dark,

	'& > button': {
		padding: 0,
		color: theme.colors.mopla_secondary_dark,
	},
}));

export const Title = styled('div')(({ theme }) => ({
	...theme.fonts.body1_semibold,
}));

export const SeparatorSolid = styled('div')(({ theme }) => ({
	height: 0,
	width: '100%',
	marginTop: 18,
	marginBottom: 16,
	borderBottom: `1px solid ${theme.colors.mopla_light_blue}`,
	alignSelf: 'center',
}));

export const ConditionRoot = styled('div')(({ theme }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateColumns: 'max-content 1fr',
	alignItems: 'center',
	color: theme.palette.primary.dark,

	'& > span': {
		marginLeft: 8,
		color: theme.colors.mopla_secondary_dark,
	},
}));
