import { DateTimeValue } from './DateTimeValue';
import { SpecialNeeds } from './SpecialNeeds';

/** This payload is used in router APIs:
 * /plan
 * /cheaperTrips
 * The same stringified object is used in URLSearchParams when searching and booking an itinerary
 * */
export interface IItinerarySearchParams {
	fromPlaceLat: string;
	fromPlaceLng: string;
	fromPlaceLabel: string;
	fromPlaceZip: string;
	fromPlaceRegion: string;
	toPlaceLat: string;
	toPlaceLng: string;
	toPlaceLabel: string;
	toPlaceZip: string;
	toPlaceRegion: string;
	datetime: DateTimeValue;
	arriveBy?: boolean;
	specialNeeds: SpecialNeeds;
}
