import { styled } from '@mui/material/styles';

interface IRootProps {
	variant?: string;
	spacing: 'S' | 'M';
}

export const Root = styled('button', {
	shouldForwardProp: (prop) => !['variant', 'spacing'].includes(prop as string),
})<IRootProps>(({ theme, variant, spacing }) => ({
	outline: 'none',
	cursor: 'pointer',
	color: theme.colors.mopla_secondary,
	padding: spacing === 'M' ? 16 : '7.5px 16px',
	boxSizing: 'border-box',
	border: '1px solid transparent',
	borderRadius: 8,
	transition: 'border .2s ease',
	backgroundColor:
		(variant === 'grey' && theme.colors.mopla_light_grey) ||
		theme.colors.mopla_white,
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr max-content',
	alignItems: 'center',
	width: '100%',

	'&:hover': {
		borderColor: theme.colors.mopla_dark_grey,
	},

	'& .main-icon': {
		marginRight: 16,
		'& svg': {
			display: 'block',
		},
	},

	'& .label': {
		textAlign: 'left',
		gridColumnStart: 2,
	},

	'& .helper-icon': {
		gridColumnStart: -2,
		marginLeft: 16,

		'& svg': {
			display: 'block',
		},
	},

	'&:disabled': {
		'&, .label, .helper': {
			color: theme.colors.mopla_grey,
		},
	},
}));
