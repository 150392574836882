import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface MarginProp {
	marginTop?: string;
}

export const Root = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',

	'& > div:first-of-type': {
		marginBottom: '24px',
	},
}));

export const Title = styled(Typography)(({ theme }) => ({
	...theme.fonts.body1,

	'& > span:nth-of-type(2n)': {
		fontWeight: '600',
	},
}));

export const Description = styled(Typography)<MarginProp>(
	({ theme, marginTop }) => ({
		...theme.fonts.body3,
		color: theme.colors.mopla_dark_grey,
		marginTop: marginTop || 0,
	})
);

export const SubmitLevel = styled('div')(() => ({
	position: 'absolute',
	bottom: '26px',
	width: 'calc(100% - 48px)',
}));
