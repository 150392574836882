import { styled } from '@mui/material/styles';

export const SearchSuggestionsListWrapper = styled('div')(() => ({
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gridTemplateColumns: '1fr',
	gridGap: 24,
	width: '100%',
	boxSizing: 'border-box',
}));
