import { FC, useContext, useState } from 'react';
import { CapacitorPassToWallet } from '@atroo/capacitor-pass-to-wallet';
import * as Sentry from '@sentry/capacitor';

import { BusinessLayerContext } from '@mopla/business-logic';

import { Loader } from '../../atoms/loader/Loader';
import { AppleWallet } from '../../icons/AppleWallet';
import { ErrorModal } from '../../organisms/ErrorModal/ErrorModal';

import { WalletButton } from './AppleWalletButton.styles';

interface IProps {
	text: string;
	ticketId: string;
}
export const AppleWalletButton: FC<IProps> = (props) => {
	const { text, ticketId } = props;
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const businessLayer = useContext(BusinessLayerContext);

	const handleClick = async () => {
		setLoading(true);
		setError(false);
		try {
			const response = await businessLayer.api.makeRequest<ArrayBuffer>(
				'/api/passengers/appleWalletPass?ticketId=' + ticketId,
				{
					responseType: 'arraybuffer',
				}
			);
			const base64String = btoa(
				String.fromCharCode(...new Uint8Array(response.data))
			);
			await CapacitorPassToWallet.addToWallet({
				base64: base64String,
			});
		} catch (e) {
			Sentry.captureException(e);
			setError(true);
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <Loader />;

	return (
		<>
			{error && <ErrorModal onSubmit={() => setError(false)} />}
			<WalletButton
				iconPosition="left"
				icon={<AppleWallet />}
				onClick={handleClick}
			>
				{text}
			</WalletButton>
		</>
	);
};
