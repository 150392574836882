import { useEffect, useRef } from 'react';

export const useThrottle = (cb: VoidFunction, interval = 500) => {
	const lastExecuted = useRef<number>(Date.now());

	useEffect(() => {
		if (Date.now() >= lastExecuted.current + interval) {
			lastExecuted.current = Date.now();
			cb();
			return;
		} else {
			const timerId = setTimeout(() => {
				lastExecuted.current = Date.now();
				cb();
			}, interval);

			return () => clearTimeout(timerId);
		}
	}, [cb, interval]);
};
