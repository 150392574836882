import { RxDocument } from 'rxdb';
import { defer, exhaustMap } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { IActiveVoucher, TActiveVouchersList } from '@mopla/data-models';

import { fetchSubscriptionOffering } from '../actions/subscriptionActions';
import {
	deleteVoucherResult,
	fetchVouchers,
	fetchVouchersResult,
	redeemVoucherResult,
	TDeleteVoucherAction,
	TFetchVouchersAction,
	TRedeemVoucherAction,
	VoucherActionTypes,
} from '../actions/voucherActions';
import { Effect } from '../business-logic';
import { ofType } from '../operators/ofType';

export const fetchActiveVouchersEffect: Effect<TFetchVouchersAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(VoucherActionTypes.FetchVouchers),
		concatMap(() =>
			defer(async () => {
				const vouchersCol = dependencies.db['vouchers'];

				try {
					const activeVouchersList =
						await dependencies.api.get<TActiveVouchersList>(
							'/api/users/vouchers/active'
						);

					const existingActiveVouchersList: Array<RxDocument<IActiveVoucher>> =
						await vouchersCol.find().exec();

					const itemsToRemove: string[] = [];

					existingActiveVouchersList.forEach((doc) => {
						const shouldDocRemain = activeVouchersList.some(
							(voucher) => voucher.voucherCode === doc.voucherCode
						);

						if (!shouldDocRemain) {
							itemsToRemove.push(doc.voucherCode);
						}
					});

					await vouchersCol.bulkUpsert(activeVouchersList);

					if (itemsToRemove) {
						await vouchersCol.bulkRemove(itemsToRemove);
					}

					return fetchVouchersResult();
				} catch (error: any) {
					dependencies.Sentry.captureException(error);
					return fetchVouchersResult({ error });
				}
			})
		)
	);

export const redeemVoucherEffect: Effect = (actions$, dependencies) =>
	actions$.pipe(
		ofType(VoucherActionTypes.RedeemVoucher),
		exhaustMap((action: TRedeemVoucherAction) =>
			defer(async () => {
				const { voucherCode } = action.payload;

				try {
					await dependencies.api.post('/api/users/vouchers', {
						voucherCode,
					});

					actions$.next(fetchVouchers());
					actions$.next(fetchSubscriptionOffering());

					return redeemVoucherResult();
				} catch (error: any) {
					const errorCode =
						error?.response?.data?.code || 'internal_server_error';
					dependencies.Sentry.captureException(error);

					return redeemVoucherResult({ error: new Error(errorCode) });
				}
			})
		)
	);

export const deleteVoucherEffect: Effect = (actions$, dependencies) =>
	actions$.pipe(
		ofType(VoucherActionTypes.DeleteVoucher),
		exhaustMap((action: TDeleteVoucherAction) =>
			defer(async () => {
				const { voucherCode } = action.payload;

				try {
					await dependencies.api.delete(
						`/api/users/vouchers?voucherCode=${voucherCode}`
					);

					actions$.next(fetchVouchers());
					actions$.next(fetchSubscriptionOffering());

					return deleteVoucherResult();
				} catch (error: any) {
					const errorCode =
						error?.response?.data?.code || 'internal_server_error';
					dependencies.Sentry.captureException(error);

					return deleteVoucherResult({ error: new Error(errorCode) });
				}
			})
		)
	);
