import React from 'react';

export const PaymentIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 7H20V9H4V7ZM4 10V11H20V10H4ZM4 17V12H20V17H4ZM2 7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17V7ZM14 13C13.4477 13 13 13.4477 13 14V15C13 15.5523 13.4477 16 14 16H17C17.5523 16 18 15.5523 18 15V14C18 13.4477 17.5523 13 17 13H14Z"
				fill="currentColor"
			/>
		</svg>
	);
};
