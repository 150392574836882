import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
	CounterInput,
	ICounterInputProps,
	Text,
	TextWithTooltip,
} from '@mopla/ui';

export type TChildrenCounterProps = Pick<
	ICounterInputProps,
	| 'onDecrease'
	| 'onIncrease'
	| 'increaseDisabled'
	| 'decreaseDisabled'
	| 'value'
> & {
	toddlersDefinition?: string;
};

export const ChildrenCounter: FC<TChildrenCounterProps> = ({
	onDecrease,
	onIncrease,
	value,
	toddlersDefinition,
	increaseDisabled,
	decreaseDisabled,
}) => {
	const { t } = useTranslation('booking');

	return (
		<CounterInput
			onDecrease={onDecrease}
			onIncrease={onIncrease}
			value={value}
			description={t('text.max_4_children')}
			increaseDisabled={increaseDisabled}
			decreaseDisabled={decreaseDisabled}
			title={
				toddlersDefinition ? (
					<TextWithTooltip
						text={t('label.children')}
						tooltip={toddlersDefinition}
					/>
				) : (
					<Text variant="body1">{t('label.children')}</Text>
				)
			}
		/>
	);
};
