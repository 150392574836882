import { FC, ReactElement } from 'react';
import { styled } from '@mui/material/styles';

import { Text } from '../../../';

export interface IRouteDetailsHeaderProps {
	transportTypes: ReactElement;
	messages?: ReactElement;
	mainValue: ReactElement;
	title?: string;
}

const Root = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content',
	gridTemplateAreas: `
	  "title      title"
		"mainValue  transports"
		"messages   messages"
		`,
	boxSizing: 'border-box',

	'& .title': {
		marginBottom: 8,
		gridArea: 'title',
	},

	'& .transportIcons': {
		gridArea: 'transports',
	},

	'& .mainValue': {
		gridArea: 'mainValue',
		alignSelf: 'center',
	},

	'& .messages': {
		marginTop: 16,
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		gridArea: 'messages',
		maxWidth: 320,
	},
}));

export const RouteDetailsHeader: FC<IRouteDetailsHeaderProps> = ({
	title,
	transportTypes,
	messages,
	mainValue,
}) => {
	return (
		<Root>
			{title && (
				<div className="title">
					<Text variant="body2_semibold">{title}</Text>
				</div>
			)}
			<div className="mainValue">{mainValue}</div>
			<div className="transportIcons">{transportTypes}</div>
			{messages && <div className="messages">{messages}</div>}
		</Root>
	);
};
