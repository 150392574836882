import { FC, ReactNode } from 'react';

import { Text } from '../../atoms/Text/Text';
import { Minus, Plus } from '../../icons';

import {
	ButtonStyled,
	CounterDescription,
	CounterText,
	Root,
	SvgWrapper,
	TitleWrapper,
} from './styled';

export interface ICounterInputProps {
	onDecrease(): void;
	onIncrease(): void;
	value: number;
	title?: ReactNode;
	description?: string;
	increaseDisabled?: boolean;
	decreaseDisabled?: boolean;
	className?: string;
	increaseTestId?: string;
	decreaseTestId?: string;
	counterTestId?: string;
}

export const CounterInput: FC<ICounterInputProps> = ({
	onDecrease,
	onIncrease,
	value,
	title,
	description,
	increaseDisabled,
	decreaseDisabled,
	className,
	increaseTestId,
	decreaseTestId,
	counterTestId,
}) => {
	return (
		<Root className={className}>
			{title && <TitleWrapper>{title}</TitleWrapper>}
			<ButtonStyled
				variant="outlined"
				onClick={onDecrease}
				data-testid={decreaseTestId}
				style={{ gridArea: 'dec' }}
				disabled={decreaseDisabled}
			>
				<SvgWrapper>
					<Minus />
				</SvgWrapper>
			</ButtonStyled>
			<CounterText variant="H6" data-testid={counterTestId}>
				{value}
			</CounterText>
			<ButtonStyled
				data-testid={increaseTestId}
				variant="outlined"
				onClick={onIncrease}
				style={{ gridArea: 'inc' }}
				disabled={increaseDisabled}
			>
				<SvgWrapper>
					<Plus />
				</SvgWrapper>
			</ButtonStyled>
			<CounterDescription>
				<Text variant="body3" color="mopla_dark_grey">
					{description}
				</Text>
			</CounterDescription>
		</Root>
	);
};
