import React from 'react';

interface CrossIconProps {
	width?: number;
	height?: number;
}

export const ArrivalIcon: React.FC<CrossIconProps> = ({
	width = 24,
	height = 24,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 6H18V18H20V6ZM15.6782 11.2929L12.1427 7.75732L10.7285 9.17154L12.5569 11H4V13H12.5569L10.7285 14.8285L12.1427 16.2427L15.6782 12.7071C15.8864 12.4989 15.9836 12.2217 15.9698 11.949C15.9577 11.7103 15.8605 11.4752 15.6782 11.2929Z"
				fill="currentColor"
			/>
		</svg>
	);
};
