import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import { differenceInYears } from 'date-fns';
import dayjs from 'dayjs';

import { Input } from '../../atoms/input/input';

import {
	DateOfBirthError,
	DateOfBirthWrapper,
	Description,
	InputsWrapper,
	Root,
	SaveAddressWrapper,
	Title,
} from './PersonalDetailsForm.styles';

interface IFormFieldsProps {
	showAddressToggle?: boolean;
	showCompanyField?: boolean;
	showDates?: boolean;
	showMandatotyFieldsHint?: boolean;
	fullAddressRequired?: boolean;
	dateOfBirthRequired?: boolean;
	withPaddings?: boolean;
}

export const FormFields: React.FC<IFormFieldsProps> = (props) => {
	const { t } = useTranslation(['personalDetails', 'validation']);
	const {
		showDates = true,
		showAddressToggle = true,
		showCompanyField = false,
		showMandatotyFieldsHint = false,
		dateOfBirthRequired = false,
		withPaddings = true,
	} = props;
	const methods = useFormContext();
	const { formState, getValues } = methods;

	const [saveAddress, setSaveAddress] = useState<boolean>(false);
	const [dateOfBirthError, setDateOfBirthError] = useState<string>('');

	const checkDateOfBirth = () => {
		const { day, month, year } = getValues();
		const isValid = dayjs(
			`${year}-${month}-${day}`,
			'YYYY-MM-DD',
			true
		).isValid();
		const providedDate = new Date(Number(year), Number(month) - 1, Number(day));

		if (day !== '' && month !== '' && year !== '' && !isValid) {
			return setDateOfBirthError(t('validation:dateOfBirth.date_invalid'));
		}

		if (differenceInYears(new Date(), providedDate) < 6) {
			return setDateOfBirthError(t('validation:dateOfBirth.age_incorrect'));
		}
		return setDateOfBirthError('');
	};

	return (
		<Root withPaddings={withPaddings}>
			<Controller
				name="firstName"
				render={({ field }) => (
					<Input
						{...field}
						ref={null}
						label={`${t('label.firstName')} *`}
						helperText={
							Boolean(formState.errors.firstName?.message) &&
							t(`validation:${formState.errors.firstName?.message}`)
						}
						error={Boolean(formState.errors.firstName?.message)}
						inputProps={{
							'data-testid': 'personal-detailsForm-name-textField',
						}}
					/>
				)}
			/>
			<Controller
				name="surname"
				render={({ field }) => (
					<Input
						{...field}
						ref={null}
						label={`${t('label.surname')} *`}
						helperText={
							Boolean(formState.errors.surname?.message) &&
							t(`validation:${formState.errors.surname?.message}`)
						}
						error={Boolean(formState.errors.surname?.message)}
						inputProps={{
							'data-testid': 'personal-detailsForm-surname-textField',
						}}
					/>
				)}
			/>
			{showCompanyField ? (
				<Controller
					name="company"
					render={({ field }) => (
						<Input
							{...field}
							ref={null}
							label={`${t('label.company')}`}
							inputProps={{
								'data-testid': 'personal-detailsForm-company-textField',
							}}
						/>
					)}
				/>
			) : (
				<Controller
					name="phoneNumber"
					render={({ field }) => (
						<Input
							{...field}
							ref={null}
							label={`${t('label.phoneNumber')} *`}
							helperText={
								Boolean(formState.errors.phoneNumber?.message) &&
								t(`validation:${formState.errors.phoneNumber?.message}`)
							}
							error={Boolean(formState.errors.phoneNumber?.message)}
							inputProps={{
								'data-testid': 'personal-detailsForm-phone-textField',
							}}
						/>
					)}
				/>
			)}
			<InputsWrapper firstWidth="80%" secondWidth="20%">
				<Controller
					name="street"
					render={({ field }) => (
						<Input
							{...field}
							ref={null}
							label={`${t('label.street')} *`}
							inputProps={{
								'data-testid': 'personal-detailsForm-street-textField',
							}}
							helperText={
								Boolean(formState.errors.street?.message) &&
								t(`validation:${formState.errors.street?.message}`)
							}
							error={Boolean(formState.errors.street?.message)}
						/>
					)}
				/>
				<Controller
					name="streetNumber"
					render={({ field }) => (
						<Input
							{...field}
							ref={null}
							label={`${t('label.number')} *`}
							inputProps={{
								'data-testid': 'personal-detailsForm-streetNumber-textField',
							}}
							helperText={
								Boolean(formState.errors.streetNumber?.message) &&
								t(`validation:${formState.errors.streetNumber?.message}`)
							}
							error={Boolean(formState.errors.streetNumber?.message)}
						/>
					)}
				/>
			</InputsWrapper>
			<InputsWrapper firstWidth="25%" secondWidth="75%">
				<Controller
					name="postCode"
					render={({ field }) => (
						<Input
							{...field}
							ref={null}
							label={`${t('label.postCode')} *`}
							helperText={
								Boolean(formState.errors.postCode?.message) &&
								t(`validation:${formState.errors.postCode?.message}`)
							}
							error={Boolean(formState.errors.postCode?.message)}
							inputProps={{
								'data-testid': 'personal-detailsForm-postCode-textField',
							}}
						/>
					)}
				/>
				<Controller
					name="location"
					render={({ field }) => (
						<Input
							{...field}
							ref={null}
							label={`${t('label.location')} *`}
							inputProps={{
								'data-testid': 'personal-detailsForm-location-textField',
							}}
							helperText={
								Boolean(formState.errors.location?.message) &&
								t(`validation:${formState.errors.location?.message}`)
							}
							error={Boolean(formState.errors.location?.message)}
						/>
					)}
				/>
			</InputsWrapper>
			{showAddressToggle ? (
				<SaveAddressWrapper>
					<Switch
						checked={saveAddress}
						onChange={() => setSaveAddress((prev) => !prev)}
						color="secondary"
						data-testid="personal-detailsForm-saveAddress-switch"
					/>
					<span>{t('text.save_as_home_address')}</span>
				</SaveAddressWrapper>
			) : null}
			{showMandatotyFieldsHint && (
				<Description withPaddings={false}>{t('text.description')}</Description>
			)}
			{showDates && (
				<>
					<Title
						marginTop={showMandatotyFieldsHint ? '40px' : 0}
						marginBottom="12px"
					>
						{t('text.date_of_birth')} {dateOfBirthRequired ? '*' : ''}
					</Title>
					<DateOfBirthWrapper>
						<Controller
							name="day"
							render={({ field }) => (
								<Input
									{...field}
									ref={null}
									type="number"
									label={`${t('label.day')} *`}
									onBlur={() => {
										field.onBlur();
										checkDateOfBirth();
									}}
									helperText={
										Boolean(formState.errors.day?.message) &&
										t(`validation:${formState.errors.day?.message}`)
									}
									error={Boolean(formState.errors.day?.message)}
									inputProps={{
										'data-testid': 'personal-detailsForm-day-textField',
									}}
								/>
							)}
						/>
						<span>.</span>
						<Controller
							name="month"
							render={({ field }) => (
								<Input
									{...field}
									ref={null}
									type="number"
									label={`${t('label.month')} *`}
									onBlur={() => {
										field.onBlur();
										checkDateOfBirth();
									}}
									helperText={
										Boolean(formState.errors.month?.message) &&
										t(`validation:${formState.errors.month?.message}`)
									}
									error={Boolean(formState.errors.month?.message)}
									inputProps={{
										'data-testid': 'personal-detailsForm-month-textField',
									}}
								/>
							)}
						/>
						<span>.</span>
						<Controller
							name="year"
							render={({ field }) => (
								<Input
									{...field}
									ref={null}
									type="number"
									label={`${t('label.year')} *`}
									onBlur={() => {
										field.onBlur();
										checkDateOfBirth();
									}}
									helperText={
										Boolean(formState.errors.year?.message) &&
										t(`validation:${formState.errors.year?.message}`)
									}
									error={Boolean(formState.errors.year?.message)}
									inputProps={{
										'data-testid': 'personal-detailsForm-year-textField',
									}}
								/>
							)}
						/>
					</DateOfBirthWrapper>
					<DateOfBirthError
						error={Boolean(dateOfBirthError)}
						data-testid="personal-detailsForm-date-of-birth-error"
					>
						{dateOfBirthError}
					</DateOfBirthError>
				</>
			)}
		</Root>
	);
};
