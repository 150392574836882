import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getAuth } from 'firebase/auth';

import { EmailSentWarningMessage, VerficationAnim } from '@mopla/ui';

import {
	Bold,
	Description,
	LogoWrapper,
	SuccessRoot,
	TextWithLinkWrapper,
	Title,
} from './RegistrationForm.styles';

interface VerifyEmailSentViewProps {
	email: string;
	onResend: VoidFunction;
	showWarning?: boolean;
	passwordless?: boolean;
}

export const VerifyEmailSentView: React.FC<VerifyEmailSentViewProps> = (
	props
) => {
	const { email, onResend, showWarning = false, passwordless = false } = props;
	const auth = getAuth();
	const { t } = useTranslation(['registration', 'logIn']);
	const [showWarningMessage, setShowWarning] = useState<boolean>(showWarning);

	useEffect(() => {
		setShowWarning(showWarning);
	}, [showWarning]);

	const checkResend = () => {
		if (
			new Date().valueOf() - Number(localStorage.getItem('emailSendTime')) <=
			60000
		) {
			return setShowWarning(true);
		}
		return onResend();
	};

	const mainText = passwordless
		? 'logIn:passwordless.text.email_sent'
		: 'registration:complete.text.title';
	const additionalText = passwordless
		? 'logIn:passwordless.text.email_sent_description'
		: 'registration:complete.text.description';

	return (
		<SuccessRoot>
			<LogoWrapper>
				<VerficationAnim />
			</LogoWrapper>
			<Title data-testid="registrationForm-verify-email-screen">
				<Trans
					i18nKey={mainText}
					values={{ regEmail: email || auth?.currentUser?.email }}
					components={{ bold: <Bold /> }}
				/>
			</Title>
			<Description marginTop="24px">{t(additionalText)}</Description>
			<TextWithLinkWrapper marginTop="auto" marginBottom="30px">
				<Typography>{t('complete.text.email_not_received')}</Typography>
				<Typography
					onClick={checkResend}
					data-testid="registrationForm-verify-email-resend-button"
				>
					{t('complete.text.send_again')}
				</Typography>
			</TextWithLinkWrapper>
			<EmailSentWarningMessage
				open={showWarningMessage}
				onClose={() => setShowWarning(false)}
				email={email || auth?.currentUser?.email}
			/>
		</SuccessRoot>
	);
};
