import { useCallback, useEffect, useState } from 'react';
import { MangoQuery, RxDocument } from 'rxdb';

import { setLaunch as setLaunchAction } from '../actions/launchActions';
import { useBusinessLayer } from '../business-logic';

export interface ILaunch {
	wasLaunched: boolean;
}

const defaultLaunchData = { wasLaunched: false };
export const useAppLaunched = (query?: MangoQuery) => {
	const businessLayer = useBusinessLayer();
	const [launch, setLaunch] = useState<ILaunch>(defaultLaunchData);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		setLoading(true);
		const liveQuery = businessLayer.db['launch'].find(query).$;

		const subscription = liveQuery.subscribe((resultSet: RxDocument[]) => {
			setLaunch((resultSet[0]?.toJSON() as ILaunch) || defaultLaunchData);
			setLoading(false);
		});

		return () => subscription.unsubscribe();
	}, [query, businessLayer.db]);

	const set = useCallback(
		(item: ILaunch) => {
			businessLayer.dispatch(setLaunchAction(item));
		},
		[businessLayer]
	);

	if (window.location.search.includes('verifyEmail')) {
		set({ wasLaunched: true });
	}

	return {
		data: launch,
		set: set,
		checking: loading,
	};
};
