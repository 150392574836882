import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import { Button } from '../../atoms/Button/Button';
import { ArrowRightIcon, CrossIcon } from '../../icons';

import {
	Root,
	Subtitle,
	Title,
	TitleWrapper,
} from './BookingConfirmation.styles';

interface IBookingConfirmationProps {
	ticketId: string | null;
	onOpenBookedTicket: () => void;
	onCloseBookingConfirmation: () => void;
}

export const BookingConfirmation: React.FC<IBookingConfirmationProps> = ({
	ticketId,
	onOpenBookedTicket,
	onCloseBookingConfirmation,
}) => {
	const { t } = useTranslation('booking');

	if (!ticketId) {
		return null;
	}

	return (
		<Root>
			<TitleWrapper>
				<Title>{t('text.header.thank_you')}</Title>
				<IconButton id="closeButton" onClick={onCloseBookingConfirmation}>
					<CrossIcon />
				</IconButton>
			</TitleWrapper>
			<Subtitle>{t('booking_confirmation.text')}</Subtitle>
			<Button
				className="openButton"
				variant="primary"
				onClick={onOpenBookedTicket}
				icon={<ArrowRightIcon />}
			>
				{t('booking_confirmation.show_ticket')}
			</Button>
		</Root>
	);
};
