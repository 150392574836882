import { EntityDescriptor } from '../business-logic';
import { dataChangesSchema } from '../schemas/dataChanges';

export const dataChangesEntity: EntityDescriptor = {
	dataChanges: {
		migrationStrategies: {
			1: function (oldDoc: any) {
				// migration to rxdb 12
				return oldDoc;
			},
		},
		schema: dataChangesSchema,
	},
};
