import React from 'react';

import Button from '../../atoms/Button/Button';
import { ArrowRightIcon } from '../../icons/ArrowRight';

import { Root } from './CrashMessage.styles';

interface ICrash {
	onCLick?: (r: string) => void;
}

export const CrashMessage: React.FunctionComponent<ICrash> = ({ onCLick }) => {
	return (
		<Root>
			<h1>Something went wrong</h1>
			<Button
				id="openSettings"
				variant="primary"
				onClick={() => onCLick && onCLick('/home')}
				icon={<ArrowRightIcon />}
			>
				I'm good
			</Button>
		</Root>
	);
};

export default CrashMessage;
