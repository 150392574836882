import { defer } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { LaunchActionTypes, SetLaunchAction } from '../actions/launchActions';
import { Effect } from '../business-logic';
import { ofType } from '../operators/ofType';

export const setLaunchEffect: Effect<SetLaunchAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(LaunchActionTypes.SetLaunch),
		concatMap((action: SetLaunchAction) =>
			defer(async () => {
				await dependencies.db['launch'].upsert({ id: '0', ...action.payload });
			})
		)
	);
