import { createContext, useContext } from 'react';
import { Capacitor } from '@capacitor/core';
import {
	CapacitorSQLite,
	SQLiteConnection,
	SQLiteDBConnection,
} from '@capacitor-community/sqlite';
import * as Sentry from '@sentry/capacitor';
import {
	addRxPlugin,
	createRxDatabase,
	GraphQLSyncPullOptions,
	MigrationStrategies,
	RxDatabase,
	RxJsonSchema,
	RxStorage,
} from 'rxdb';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBLocalDocumentsPlugin } from 'rxdb/plugins/local-documents';
import { getRxStorageLoki } from 'rxdb/plugins/lokijs';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import {
	getRxStorageSQLite,
	getSQLiteBasicsCapacitor,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
} from 'rxdb-premium/plugins/sqlite';
import { map, Observable, Subject, take, takeWhile, tap } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Api } from '@mopla/data-models';
import { ITypedAction } from '@mopla/utils';

import { LocationActionTypes } from './actions/locationActions';
import { PassengerBookingActionTypes } from './actions/passengerBookingActions';
import { ScheduleActionTypes } from './actions/scheduleActions';
import { SubscriptionActionTypes } from './actions/subscriptionActions';
import { UserActionTypes } from './actions/userActions';
import { VoucherActionTypes } from './actions/voucherActions';
import { ofType } from './operators/ofType';

// in the browser, we want to persist data in IndexedDB, so we use the indexeddb adapter.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const LokiIncrementalIndexedDBAdapter = require('lokijs/src/incremental-indexeddb-adapter');

export interface Action<T = any> {
	type: T;
}

interface Options<D = Dependencies> {
	dependencies: D;
}

interface Dependencies {
	db: RxDatabase;
	api: Api;
	Sentry: typeof Sentry;
}

export declare interface Effect<
	Input extends Action = any,
	Output extends Action = any
> {
	(action$: Subject<Input>, dependencies: Dependencies): Observable<Output>;
}

export interface EntityDescriptor<T = any> {
	[name: string]: {
		schema: RxJsonSchema<T>;
		migrationStrategies?: MigrationStrategies;
		pullSync?: GraphQLSyncPullOptions<T, any>;
		subscription?: any;
		authentication?: boolean;
	};
}

export interface BusinessLayer {
	api: Api;
	db: RxDatabase;
	addEffect: (effect: Effect) => void;
	dispatch: (action: Action) => void;
	watchActions: TWatchActionsFn;
	watchActions$: TWatchActions$Fn;
	/** Used in test envs to tear down rxjs logic */
	__teardown(): void;
}

export const initDatabase = async () => {
	addRxPlugin(RxDBCleanupPlugin);
	addRxPlugin(RxDBUpdatePlugin);
	addRxPlugin(RxDBMigrationPlugin);
	addRxPlugin(RxDBLeaderElectionPlugin);
	addRxPlugin(RxDBLocalDocumentsPlugin);
	let storage: RxStorage<any, any>;
	if (Capacitor.getPlatform() === 'web') {
		storage = getRxStorageLoki({
			adapter: new LokiIncrementalIndexedDBAdapter(),
		});
	} else {
		const sqlite = new SQLiteConnection(CapacitorSQLite);
		const basics = getSQLiteBasicsCapacitor(sqlite, Capacitor);

		//basics.journalMode = Capacitor.getPlatform() === 'android' ? '' : 'WAL2'
		basics.journalMode = '';
		basics.open = async (dbName: string) => {
			const ret = await sqlite.checkConnectionsConsistency();
			const isConn = (await sqlite.isConnection(dbName, false)).result;
			let db: SQLiteDBConnection;
			if (ret.result && isConn) {
				db = await sqlite.retrieveConnection(dbName, false);
			} else {
				db = await sqlite.createConnection(
					dbName,
					false,
					'no-encryption',
					1,
					false
				);
			}

			await db.open();
			return db;
		};

		storage = getRxStorageSQLite({
			/**
			 * Different runtimes have different interfaces to SQLite.
			 * For example in node.js we have a callback API,
			 * while in capacitor sqlite we have Promises.
			 * So we need a helper object that is capable of doing the basic
			 * sqlite operations.
			 */
			sqliteBasics: basics,
		});
	}

	const db = await createRxDatabase({
		name: 'mopla',
		eventReduce: true, // <- queryChangeDetection (optional, default: false)
		multiInstance: !Capacitor.isNativePlatform(),
		localDocuments: true,
		storage,
		cleanupPolicy: {
			minimumDeletedTime: 1000 * 60 * 60, // hour
			minimumCollectionAge: 1000 * 60, // 1 min
			runEach: 1000 * 60 * 5, // 5 minutes
			awaitReplicationsInSync: true,
			waitForLeadership: !Capacitor.isNativePlatform(),
		},
	});
	return db;
};

let epic$: Subject<Effect<Action, Action>>;
let actionSubject$: Subject<Action>;
let initialized = false;

const dispatch = (action: Action) => {
	actionSubject$.next(action);
};

export const bootstrapBusinessLogic = async (
	api: Api,
	collections: EntityDescriptor[],
	db: RxDatabase
): Promise<BusinessLayer> => {
	const collectionsObject = collections.reduce((obj, item) => {
		return {
			...obj,
			...item,
		};
	}, {});

	await db.addCollections(collectionsObject);

	// TODO generate RxDB Replication from EntityDescriptor
	const opts = {
		dependencies: {
			db: db,
			api: api,
			Sentry,
		},
	};

	const __teardown = initActionStream(opts);

	return {
		api,
		db,
		dispatch,
		addEffect,
		watchActions,
		watchActions$,
		__teardown,
	};
};

const initActionStream = (options: Options) => {
	epic$ = new Subject<Effect<Action, Action>>();
	actionSubject$ = new Subject<Action>();

	const result$ = epic$.pipe(
		mergeMap((effect) => {
			const output$ = effect(actionSubject$, options.dependencies);

			if (!output$) {
				throw new TypeError(
					`Your root Effect "${
						effect.name || '<anonymous>'
					}" does not return a stream. Double check you're not missing a return statement!`
				);
			}

			return output$;
		})
	);

	const sub = result$.subscribe((action?: Action) => {
		if (action) {
			dispatch(action);
		}
	});

	initialized = true;

	return () => {
		sub.unsubscribe();
		initialized = false;
	};
};

export const addMultipleEffects = (effects: Effect[]) => {
	effects.forEach(addEffect);
};

export const addEffect = (effect: Effect) => {
	if (!initialized) {
		throw new Error('before adding effects, initActionStream must be called');
	}
	epic$.next(effect);
};

/** TODO improve return types */
const watchActions$ = <T extends TPossibleAction>(
	types: T | T[],
	count?: number | (() => boolean)
) => {
	const _types = Array.isArray(types) ? types : [types];

	return actionSubject$.pipe(
		ofType(..._types),
		typeof count === 'function'
			? takeWhile(count)
			: count
			? take(count)
			: takeWhile(() => true)
	);
};

const watchActions = <T extends TPossibleAction>(p: {
	types: T | T[];
	callback: (action: ITypedAction<T>) => void;
	count?: number | (() => boolean);
}) => {
	const { types, count, callback } = p;

	addEffect(() =>
		watchActions$(types, count).pipe(
			tap((action) => callback(action as ITypedAction<T>)),
			map(() => null)
		)
	);
};

export const BusinessLayerContext = createContext<BusinessLayer>(
	{} as BusinessLayer
);

export const useBusinessLayer = () => useContext(BusinessLayerContext);

type TWatchActionsFn = typeof watchActions;
type TWatchActions$Fn = typeof watchActions$;

type TPossibleAction =
	| UserActionTypes
	| LocationActionTypes
	| PassengerBookingActionTypes
	| ScheduleActionTypes
	| SubscriptionActionTypes
	| VoucherActionTypes;
