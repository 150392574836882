import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Geolocation } from '@capacitor/geolocation';

import { useConvertLatlong } from '@mopla/business-logic';
import { ILocationSuggest } from '@mopla/data-models';

import { CurrentlocationIcon } from '../../icons/Currentlocation';
import PermissionsModal from '../../organisms/PermissionsModal/PermissionsModal';
import { MapAnim } from '../Animations';

import { Root } from './CurrentLocationButton.styles';

export interface CurrentLocationButtonProps {
	onLoad: (loc: ILocationSuggest) => void;
}

// TODO: improve styles after clarifications. Provide location service converting lotlang to address
export const CurrentLocationButton: React.FC<CurrentLocationButtonProps> = ({
	onLoad,
}) => {
	const [currLatlong, setCurrLatlong] = useState('');
	const { t } = useTranslation(['common', 'permissions']);
	const currentLocation = useConvertLatlong(currLatlong);
	const [showPermissionsModal, setShowPermissionsModal] = useState(false);

	useEffect(() => {
		if (currentLocation) {
			onLoad({
				...currentLocation,
				type: 'address',
			});
		}
	}, [currentLocation, onLoad]);

	return (
		<>
			<Root
				type="button"
				onClick={() => setShowPermissionsModal(true)}
				data-testid="search-locationForm-currentLocation-button"
			>
				<CurrentlocationIcon />
				{t('fields.currentLocation')}
			</Root>
			{showPermissionsModal ? (
				<PermissionsModal
					title={t('permissions:location.title')}
					firstMessage={t('permissions:location.first_message')}
					secondMessage={t('permissions:location.second_message')}
					deniedFallbackMessage1={t('permissions:location.first_message_err')}
					deniedFallbackMessage2={t('permissions:location.second_message_err')}
					deniedFallbackSkip={t('permissions:location.skip_title_err')}
					skipTitle={t('permissions:location.skip_title')}
					acceptTitle={t('permissions:location.accept_title')}
					animationElement={<MapAnim />}
					checkPermissionsCallback={Geolocation.checkPermissions}
					askPermissionsCallback={Geolocation.getCurrentPosition}
					onPermissionsGranted={(r) =>
						setCurrLatlong(`${r.coords.latitude},${r.coords.longitude}`)
					}
					onCancel={() => setShowPermissionsModal(false)}
				/>
			) : null}
		</>
	);
};
