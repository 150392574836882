import React, { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import { SuccessStep } from '@mopla/constants';
import { ArrowRightIcon, Button } from '@mopla/ui';

import passApi from '../../../api/passApi';

import { Bold, Root, Title } from './VerifyFailureScreen.styles';

interface IVerifyFailureScreenProps {
	setSuccessType: Dispatch<SetStateAction<string>>;
}

export const VerifyFailureScreen: React.FC<IVerifyFailureScreenProps> = ({
	setSuccessType,
}) => {
	const { t } = useTranslation('verify');
	const navigate = useNavigate();

	const auth = getAuth();

	const resendEmail = () => {
		return passApi
			.post('/api/command/verifyEmail', { app: 'PASSENGER' })
			.then(() => {
				localStorage.setItem('emailSendTime', String(new Date().valueOf()));
				setSuccessType(SuccessStep.registrationComplete);
				return navigate({ pathname: '/welcome', search: 'registration' });
			});
	};

	return (
		<Root>
			<Title
				marginBottom="24px"
				data-testid="verify-email-failure-screen-title"
			>
				{t('text.title.link_expired')}
			</Title>
			<Title>
				<Trans
					i18nKey="verify:text.title.nothing_worry"
					values={{ regEmail: auth?.currentUser?.email }}
					components={{ bold: <Bold /> }}
				/>
			</Title>
			<Button
				variant="primary"
				icon={<ArrowRightIcon />}
				onClick={resendEmail}
				type="button"
				data-testid="verify-email-failure-screen-resend-button"
			>
				{t('button.resend_email')}
			</Button>
		</Root>
	);
};
