/* istanbul ignore file */
/* tslint:disable */

/* eslint-disable */

export enum PaymentState {
	TO_PAY = 'TO_PAY',
	INVOICE_SEND = 'INVOICE_SEND',
	PAID = 'PAID',
	FAILED = 'FAILED',
}
