import React, { PropsWithChildren } from 'react';

import { Text } from '@mopla/ui';

import { ContentWrapper } from '../DTicketSubscription.styles';

interface IContentBlockProps {
	headerText?: string;
}
export const ContentBlock: React.FC<PropsWithChildren<IContentBlockProps>> = ({
	headerText,
	children,
}) => {
	return (
		<ContentWrapper>
			{headerText && <Text variant="subtitle1">{headerText}</Text>}
			{children}
		</ContentWrapper>
	);
};
