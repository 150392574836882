import { FC } from 'react';

import { FlexColumn } from '../../atoms/FlexContainer/FlexColumn';
import { Text } from '../../atoms/Text/Text';
import { ChevronRightIcon } from '../../icons';

import { Root } from './ProfileGenericButton.styles';
import { IContentProps, IProfileGenericButtonProps } from './types';

const Content: FC<IContentProps> = ({ value, label }) => {
	const labelComponent = (
		<Text
			variant={value ? 'caption_semibold' : 'body1'}
			color="mopla_secondary_dark"
			className="label"
			ellipsis
		>
			{label}
		</Text>
	);

	if (!value) {
		return labelComponent;
	}

	return (
		<FlexColumn gap={4} className="flex-start">
			{label}
			<Text variant="body1" color="mopla_dark_grey" className="helper">
				{value}
			</Text>
		</FlexColumn>
	);
};

export const ProfileGenericButton: FC<IProfileGenericButtonProps> = ({
	mainIcon,
	label,
	helperText,
	helperIcon = <ChevronRightIcon />,
	showChevron = true,
	variant,
	onClick,
	disabled = false,
}) => {
	return (
		<Root
			type="button"
			onClick={onClick}
			variant={variant}
			disabled={disabled}
			spacing={helperText ? 'S' : 'M'}
		>
			{mainIcon && <div className="main-icon">{mainIcon}</div>}
			<Content label={label} value={helperText} />
			{showChevron && <div className="helper-icon">{helperIcon}</div>}
		</Root>
	);
};
