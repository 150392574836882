import React from 'react';

import { MicrosoftIcon } from '../../icons/Microsoft';

import { SocialButton } from './SocialButton';

interface IProps {
	onClick: VoidFunction;
}
export const MicrosoftButton: React.FC<IProps> = (props) => {
	const { onClick } = props;

	const handleClick = () => {
		onClick();
	};

	return (
		<SocialButton
			name="microsoft"
			onClick={handleClick}
			icon={<MicrosoftIcon />}
		/>
	);
};
