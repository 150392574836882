import React, { ReactElement } from 'react';
import { ClickAwayListener } from '@mui/material';

import { CrossIcon } from '../../icons/Cross';

import {
	AnimWrapper,
	CloseIcon,
	Content,
	Root,
	ScrollableModalContent,
	Title,
} from './Modal.styles';

export interface ModalProps {
	open: boolean;
	onClose: () => void;
	title?: string;
	titleComp?: ReactElement | null;
	animationElement?: ReactElement | null;
	mainContent: ReactElement | null;
	footerComp: ReactElement | null;
	showClose?: boolean;
	variant?: 'black' | 'secondary_dark';
	backgroundColor?: string;
	dataTestid?: string;
}

export const Modal: React.FC<ModalProps> = ({
	title,
	titleComp,
	mainContent,
	footerComp,
	animationElement = null,
	open,
	onClose,
	showClose = false,
	variant = 'black',
	backgroundColor = '',
	dataTestid = 'modal',
}) => {
	if (open) {
		return (
			<Root variant={variant} className={`DASISTEINTEST ${open}`}>
				<ClickAwayListener onClickAway={onClose}>
					<Content data-testid={dataTestid} backgroundColor={backgroundColor}>
						{showClose && (
							<CloseIcon data-testid="modal-close-button" onClick={onClose}>
								<CrossIcon />
							</CloseIcon>
						)}
						<ScrollableModalContent>
							{title && <Title>{title}</Title>}
							{animationElement && (
								<AnimWrapper>{animationElement}</AnimWrapper>
							)}
							{titleComp}
							{mainContent}
							{footerComp}
						</ScrollableModalContent>
					</Content>
				</ClickAwayListener>
			</Root>
		);
	}
	return null;
};

export default Modal;
