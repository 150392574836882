/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DateValue } from './DateValue';
import type { Province } from './Province';

export type CalendarEvent = {
	from: DateValue;
	to: DateValue;
	dayType?: CalendarEvent.dayType;
	note?: string;
	provinces: Array<Province>;
};

export namespace CalendarEvent {
	export enum dayType {
		PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY',
		SCHOOL_HOLIDAY = 'SCHOOL_HOLIDAY',
		SPECIAL_DAY = 'SPECIAL_DAY',
	}
}
