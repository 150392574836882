import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

import { NotificationsProvider } from '@mopla/business-logic';
import { theme } from '@mopla/mui';
import { CrashMessage, ErrorBoundary, useOverlayingWebView } from '@mopla/ui';
import { NetworkStatusProvider } from '@mopla/utils';

import AppRoutes from './routes/AppRouter';

const ViewEntrypoint: React.FunctionComponent = () => {
	useOverlayingWebView();

	return (
		<NotificationsProvider>
			<NetworkStatusProvider>
				<ThemeProvider theme={theme}>
					<ErrorBoundary errorScreen={<CrashMessage />}>
						<AppRoutes />
					</ErrorBoundary>
				</ThemeProvider>
			</NetworkStatusProvider>
		</NotificationsProvider>
	);
};

export default ViewEntrypoint;
