import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	fetchVouchers,
	useActiveVouchers,
	useBusinessLayer,
	useDTicketSubscription,
} from '@mopla/business-logic';
import { IActiveVoucher } from '@mopla/data-models';
import { useBreakpoints } from '@mopla/utils';

import { Button, Loader, Text, Ticket } from '../../';

import { SeparatorLine, TitleText } from './ProfileManager.styles';
import { RedeemVoucherForm } from './RedeemVoucherForm';
import { RedeemVoucherSuccessModal } from './RedeemVoucherSuccessModal';
import {
	ActiveVouchers,
	TicketContent,
	VouchersWrapper,
} from './Vouchers.styles';

interface IVoucherTicketProps {
	voucherData: IActiveVoucher;
	hasSubscription: boolean;
	onCreateSubscription: VoidFunction;
}

const VoucherTicket: FC<IVoucherTicketProps> = ({
	voucherData,
	hasSubscription,
	onCreateSubscription,
}) => {
	const { t } = useTranslation('profile');
	const { voucherCode, longDescription } = voucherData;
	const { isAboveMobile } = useBreakpoints();

	let footer = null;

	if (!hasSubscription) {
		footer = (
			<div>
				<Button variant="outlined" onClick={onCreateSubscription}>
					{t('vouchers.modal.create_subscription_btn')}
				</Button>
			</div>
		);
	}

	return (
		<Ticket
			testId={voucherCode}
			header={
				<Text
					variant="body1_semibold"
					color={isAboveMobile ? 'mopla_secondary_dark' : 'mopla_black'}
				>
					{voucherCode}
				</Text>
			}
			content={
				<TicketContent>
					<Text
						variant="body3"
						color={isAboveMobile ? 'mopla_secondary_dark' : 'mopla_black'}
					>
						{longDescription}
					</Text>
				</TicketContent>
			}
			footer={footer}
			separatorBg={isAboveMobile ? 'mopla_white' : 'mopla_mid_grey'}
			circleSize={32}
			ticketBg={isAboveMobile ? 'mopla_super_light_blue' : undefined}
		/>
	);
};

export const VouchersScreen: FC = () => {
	const { t } = useTranslation('profile');
	const { subscription } = useDTicketSubscription();
	const [successModalOpened, setSuccessModalOpened] = useState(false);
	const navigate = useNavigate();
	const { activeVouchers, isLoading: isActiveVouchersLoading } =
		useActiveVouchers();
	const bl = useBusinessLayer();

	useEffect(() => {
		bl.dispatch(fetchVouchers());
	}, [bl]);

	const redeemSuccessHandler = useCallback(() => {
		setSuccessModalOpened(true);
	}, []);

	const doneHandler = useCallback(() => {
		setSuccessModalOpened(false);
	}, []);

	const toSubscriptionCreationHandler = useCallback(() => {
		setSuccessModalOpened(false);

		/** Redirect to subscription creation flow */
		navigate({ pathname: '/home/dticket', search: 'subscribe' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<VouchersWrapper>
			<RedeemVoucherForm onRedeemSuccess={redeemSuccessHandler} />
			<SeparatorLine />
			<ActiveVouchers>
				<TitleText>{t('vouchers.activeVouchers')}</TitleText>
				{isActiveVouchersLoading && <Loader />}
				{!isActiveVouchersLoading && !activeVouchers?.length && (
					<Text variant="body3">{t('vouchers.noVouchers')}</Text>
				)}
				{!isActiveVouchersLoading &&
					activeVouchers?.length &&
					activeVouchers.map((el) => (
						<VoucherTicket
							key={el.voucherCode}
							voucherData={el}
							hasSubscription={Boolean(subscription)}
							onCreateSubscription={toSubscriptionCreationHandler}
						/>
					))}
			</ActiveVouchers>
			<RedeemVoucherSuccessModal
				hasSubscription={Boolean(subscription)}
				isOpened={successModalOpened}
				onDone={doneHandler}
				onCreateSubscription={toSubscriptionCreationHandler}
			/>
		</VouchersWrapper>
	);
};
