import { ComponentType } from 'react';

export function getDisplayName(WrappedComponent: ComponentType) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function wrapDisplayName(
	WrappedComponent: ComponentType,
	wrapping: string
) {
	const wrappedDisplayName = getDisplayName(WrappedComponent);
	return `${wrapping}(${wrappedDisplayName})`;
}
