export enum PersonalDetailsFields {
	firstName = 'firstName',
	surname = 'surname',
	phoneNumber = 'phoneNumber',
	street = 'street',
	streetNumber = 'streetNumber',
	postCode = 'postCode',
	location = 'location',
	day = 'day',
	month = 'month',
	year = 'year',
}
