import { styled } from '@mui/material/styles';

export const Label = styled('label')(({ theme }) => ({
	...theme.fonts.body1,
	color: theme.colors.mopla_black,

	a: {
		color: theme.colors.mopla_black,
	},
}));
