import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { Browser } from '@capacitor/browser';

import { LinkText } from './styled';

interface IProps {
	className?: string;
	url: string;
}

export const BrowserLink: FC<PropsWithChildren<IProps>> = ({
	url,
	className,
	children,
}) => {
	const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		Browser.open({ url });
	};

	return (
		<LinkText className={className} onClick={handleClick}>
			{children}
		</LinkText>
	);
};
