import React from 'react';

import { AppleIcon } from '../../icons/Apple';

import { SocialButton } from './SocialButton';

interface IProps {
	onClick: VoidFunction;
}
export const AppleButton: React.FC<IProps> = (props) => {
	const { onClick } = props;

	const handleClick = () => {
		onClick();
	};

	return (
		<SocialButton name="apple" onClick={handleClick} icon={<AppleIcon />} />
	);
};
