import React from 'react';

export const CheckboxPositive = () => {
	return (
		<svg
			width="45"
			height="45"
			viewBox="0 0 45 45"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.5 41.1581C12.2119 41.1581 3.84189 32.788 3.84189 22.4999C3.84189 12.2118 12.2119 3.8418 22.5 3.8418C32.7881 3.8418 41.1581 12.2118 41.1581 22.4999C41.1581 32.788 32.7881 41.1581 22.5 41.1581Z"
				fill="#6FDB89"
			/>
			<path
				d="M19.7428 31.221L11.7685 21.7281C11.3539 21.2344 11.4179 20.4982 11.9115 20.0835C12.4053 19.6689 13.1415 19.7329 13.5561 20.2265L19.8925 27.7697L32.0716 15.5907C32.5275 15.1349 33.2665 15.1348 33.7224 15.5907C34.1783 16.0465 34.1783 16.7856 33.7224 17.2414L19.7428 31.221Z"
				fill="white"
			/>
		</svg>
	);
};
