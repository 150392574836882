import React from 'react';

export const EditIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.21147 15.0018L7.87813 12.9209L16.2635 4.53557C16.654 4.14504 17.2871 4.14504 17.6777 4.53557C18.0682 4.92609 18.0682 5.55926 17.6777 5.94978L9.29235 14.3351L7.21147 15.0018ZM19.0919 7.364L10.364 16.0919L5.9715 17.4992C5.19697 17.7473 4.46594 17.0163 4.71407 16.2417L6.12132 11.8493L14.8492 3.12135C16.0208 1.94978 17.9203 1.94978 19.0919 3.12135C20.2635 4.29293 20.2635 6.19242 19.0919 7.364ZM20 20H4V22H20V20Z"
				fill="#1C308B"
			/>
		</svg>
	);
};
