import { styled } from '@mui/material/styles';

interface RootProps {
	open: boolean;
	variant?: 'primary' | 'secondary';
}
export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'open' && prop !== 'withHeader',
})<RootProps & { withHeader: boolean }>(({ open, theme, withHeader }) => ({
	display: 'grid',
	gridTemplateRows: withHeader ? 'max-content 1fr' : '1fr',
	background: theme.colors.mopla_white,
	position: 'absolute',
	borderRadius: '8px 8px 0 0',
	width: '100%',
	bottom: 0,
	left: 0,
	overflow: 'hidden',
	height: open ? 'calc(100% - var(--mopla-safe-area-top) - 15px)' : 0,
	transition: 'height .2s, width .2s, left .2s',
	zIndex: open ? 10 : 20,
}));

export const BackBlock = styled('div')<RootProps>(
	({ theme, open, variant }) => ({
		position: 'absolute',
		background:
			variant === 'primary'
				? theme.colors.mopla_primary
				: theme.colors.mopla_secondary,
		borderRadius: '8px 8px 0 0',
		width: 'calc(100% - 30px)',
		bottom: 0,
		left: '50%',
		transform: 'translateX(-50%)',
		height: open ? 'calc(100vh - var(--mopla-safe-area-top) - 10px)' : 0,
		transition: open ? 'height .2s' : '',
		overflow: 'hidden',
		zIndex: 5,
	})
);

export const BaseSurfaceBlock = styled('div')<Omit<RootProps, 'variant'>>(
	({ theme, open }) => ({
		position: 'absolute',
		background: theme.colors.mopla_white,
		width: '100%',
		bottom: 0,
		left: 0,
		height: open ? '100%' : 0,
		transition: open ? 'height .2s' : '',
		overflow: 'hidden',
		zIndex: 4,
	})
);

export const ChildrenWrapper = styled('div')(() => ({
	overflowY: 'auto',
}));
