import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content 1fr max-content',
	height: 'calc(100% - var(--mopla-safe-area-top))',
	paddingTop: 'var(--mopla-safe-area-top)',
	paddingBottom: 50,
	boxSizing: 'border-box',
	overflow: 'auto',
	maxWidth: 600,
	margin: 'auto',
	boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
	'#skipOnboarding': {
		...theme.fonts.body1_semibold,
		width: 'max-content',
		justifySelf: 'end',
		marginBottom: 8,
		span: {
			marginRight: 8,
		},
	},

	'.carousel-slider': {
		height: '100%',
		'.slider-wrapper': {
			height: '100%',
			'.slider': {
				height: '100%',
			},
		},
	},

	'.MuiMobileStepper-dot': {
		'&:not(:last-of-type)': {
			marginRight: '8px',
		},
		backgroundColor: theme.palette.secondary.light,
	},

	'.MuiMobileStepper-dotActive': {
		backgroundColor: theme.palette.secondary.main,
	},

	'.MuiPaper-elevation': {
		alignSelf: 'center',
		justifySelf: 'center',
	},
}));

export const Slide = styled('div')(() => ({
	cursor: 'default',
	display: 'grid',
	gridAutoRows: 'max-content',
	justifyItems: 'start',
	width: '100%',
	height: '100%',
	padding: 24,
	boxSizing: 'border-box',
	wordBreak: 'break-word',
}));

export const Footer = styled('div')(() => ({
	height: '100%',
	width: '100%',
	display: 'grid',
	gridTemplateColumns: '1fr',
	justifyContent: 'center',
	padding: '0 24px',
	boxSizing: 'border-box',
}));

export const Title = styled('span')(({ theme }) => ({
	...theme.fonts.H6,
	color: theme.colors.mopla_black,
	marginBottom: 24,
	textAlign: 'start',
}));

export const Subtitle = styled('span')(({ theme }) => ({
	...theme.fonts.body1,
	color: theme.colors.mopla_black,
	whiteSpace: 'break-spaces',
	textAlign: 'start',
}));

export const Image = styled('div')(({ theme }) => ({
	width: '100%',
	borderRadius: 8,
	background: theme.colors.mopla_grey,
	marginBottom: 40,
}));
