import { styled } from '@mui/material/styles';

import { Text } from '../../atoms/Text/Text';

export const NoWrap = styled('span')(() => ({
	whiteSpace: 'nowrap',
}));

export const PreviousValue = styled(Text)(() => ({
	marginRight: 16,
	textDecoration: 'line-through',
}));
