import { useCallback, useEffect } from 'react';
import { AndroidNotch } from '@awesome-cordova-plugins/android-notch';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

export const useOverlayingWebView = () => {
	const setStyles = useCallback(() => {
		const style = document.documentElement.style;
		if (Capacitor.getPlatform() !== 'web') {
			if (Capacitor.getPlatform() === 'android') {
				// Apply insets as css variables
				AndroidNotch.getInsetTop().then(
					(px: number) => {
						if (px === 0) {
							//if we are not having cutouts then we need space for the statusbar anyway as android does not report these reliably
							style.setProperty('--mopla-safe-area-top', 20 + 'px');
						} else {
							//if we have a notch the status bar aligns around it and we need to take into account the height of the cutout
							style.setProperty('--mopla-safe-area-top', px + 'px');
						}
					},
					(err: Error) => console.error('Failed to get insets top:', err)
				);
				AndroidNotch.getInsetBottom().then(
					(px: number) => {
						style.setProperty('--mopla-safe-area-bottom', px + 'px');
					},
					(err: Error) => console.error('Failed to get insets bottom:', err)
				);
				StatusBar?.setOverlaysWebView({ overlay: true });
			}
			StatusBar?.setStyle({ style: Style.Light });
		}
		style.setProperty('--mopla-safe-area-top', 'env(safe-area-inset-top)');
		style.setProperty(
			'--mopla-safe-area-bottom',
			'env(safe-area-inset-bottom)'
		);
		style.setProperty('--doc-height', `${window.innerHeight}px`);
	}, []);

	useEffect(() => {
		setStyles();
	}, [setStyles]);

	useEffect(() => {
		window.addEventListener('resize', setStyles);
		return () => {
			window.removeEventListener('resize', setStyles);
		};
	}, [setStyles]);
};
