import { useCallback } from 'react';
import { useObservableEagerState } from 'observable-hooks';

import { fetchSubscriptions as fetchSubscriptionsAction } from '../actions/subscriptionActions';
import { useBusinessLayer } from '../business-logic';
import { useDBObservableDataContext } from '../contexts/dataContext';

export const useSubscription = () => {
	const { subscription$ } = useDBObservableDataContext();

	if (!subscription$) {
		throw new Error(
			'No subscription$ found in DBObservableDataContext. Refer to {withDBObservableDataContext}'
		);
	}

	return useObservableEagerState(subscription$);
};

export const useSubscriptionActions = () => {
	const { dispatch } = useBusinessLayer();

	const fetchSubscriptions = useCallback(() => {
		dispatch(fetchSubscriptionsAction());
	}, [dispatch]);

	return { fetchSubscriptions };
};
