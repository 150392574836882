import React from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@mui/material';

import Button from '../../atoms/Button/Button';
import { FlexContainer } from '../../atoms/FlexContainer/FlexContainer';
import { Text } from '../../atoms/Text/Text';

import { ButtonWrapper, Form, IconContainer, Root } from './InfoModal.styles';

export interface InfoModalProps {
	title: string;
	submitTitle: string;
	infoText: string | React.ReactNode;
	onSubmit: VoidFunction;
	onCancel?: VoidFunction;
	cancelTitle?: string;
	additionalInfo?: React.ReactNode | string;
	icon?: React.ReactNode;
	submitIcon?: React.ReactElement;
	isLoading?: boolean;
	textAlign?: 'left' | 'right' | 'center';
	isButtonReverse?: boolean;
	outlinedCancelButton?: boolean;
}

export const InfoModal: React.FC<InfoModalProps> = ({
	title,
	submitTitle,
	infoText,
	onSubmit,
	cancelTitle,
	onCancel,
	additionalInfo,
	icon,
	submitIcon,
	isLoading = false,
	textAlign = 'left',
	isButtonReverse = false,
	outlinedCancelButton = true,
}) => {
	const [rootNode] = React.useState(document.getElementById('portal'));

	if (rootNode === null) {
		return null;
	}

	return ReactDOM.createPortal(
		<Root textAlign={textAlign}>
			<Form>
				{icon && <IconContainer className="center">{icon}</IconContainer>}
				<Text variant="H6" color="mopla_black">
					{title}
				</Text>
				{isLoading && (
					<FlexContainer className="center">
						<CircularProgress />
					</FlexContainer>
				)}
				{!isLoading && (
					<>
						<Text variant="body1" color="mopla_black">
							{infoText}
						</Text>
						{additionalInfo && (
							<Text variant="body1" color="mopla_black">
								{additionalInfo}
							</Text>
						)}
					</>
				)}
				<ButtonWrapper
					className="space-between"
					isButtonReverse={isButtonReverse}
				>
					<Button
						variant="primary"
						onClick={onSubmit}
						icon={submitIcon}
						disabled={isLoading}
					>
						{submitTitle}
					</Button>
					{cancelTitle && (
						<Button
							variant={outlinedCancelButton ? 'outlined' : 'text'}
							onClick={onCancel}
							color="secondary"
							disabled={isLoading}
						>
							{cancelTitle}
						</Button>
					)}
				</ButtonWrapper>
			</Form>
		</Root>,
		rootNode
	);
};
