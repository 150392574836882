import React from 'react';

export const LeftChevronIcon: React.FC<{ id?: string }> = ({ id }) => {
	return (
		<svg
			id={id}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.8284 12.3639L15.7782 7.41421L14.364 6L9.41422 10.9497L8.70711 11.6568C8.31659 12.0474 8.31659 12.6805 8.70711 13.0711L9.41421 13.7782L14.364 18.7279L15.7782 17.3137L10.8284 12.3639Z"
				fill="currentColor"
			/>
		</svg>
	);
};
