import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, UnpackNestedValue, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { yupResolver } from '@hookform/resolvers/yup';

import { PersonalDetailsFields } from '@mopla/constants';
import { IWhoami } from '@mopla/data-models';

import { FormFields } from './FormFields';
import { FormFooter } from './FormFooter';
import { JobticketDetailsForm } from './JobticketDetailsForm';
import {
	Description,
	Form,
	FormWrapper,
	Title,
} from './PersonalDetailsForm.styles';
import PersonalDetailsValidationSchema from './PersonalDetailsValidation';

export type UserPersonalDetails = {
	firstName: string;
	lastName: string;
	phone: string;
	dateOfBirth: string | null;
	street: string | null;
	streetNumber: string | null;
	zipcode: string | null;
	city: string | null;
};

type TRenderFn = (parts: {
	content: ReactElement;
	footer: ReactElement;
}) => ReactElement;

export interface PersonalDetailsProps {
	onEdit: (data: UserPersonalDetails) => Promise<any>;
	onUserEdited?: () => void;
	currentUser?: IWhoami;
	isUserProfile?: boolean;
	onAbort?: (isDirty: boolean) => void;
	render?: TRenderFn;
}

interface IFormInput {
	[PersonalDetailsFields.firstName]: string;
	[PersonalDetailsFields.surname]: string;
	[PersonalDetailsFields.phoneNumber]: string;
	[PersonalDetailsFields.street]: string;
	[PersonalDetailsFields.streetNumber]: string;
	[PersonalDetailsFields.postCode]: string;
	[PersonalDetailsFields.location]: string;
	[PersonalDetailsFields.day]: string;
	[PersonalDetailsFields.month]: string;
	[PersonalDetailsFields.year]: string;
}

const defaultRenderFn: TRenderFn = ({ content, footer }) => {
	return (
		<>
			{content}
			{footer}
		</>
	);
};

export const PersonalDetailsForm: React.FC<PersonalDetailsProps> = ({
	onEdit,
	onUserEdited,
	currentUser,
	isUserProfile = false,
	onAbort,
	render = defaultRenderFn,
}) => {
	const { t } = useTranslation(['personalDetails', 'validation', 'profile']);
	const navigate = useNavigate();
	const location = useLocation();

	const [titleType, setTitleType] = useState<string>('notSameDevice');

	useEffect(() => {
		const { title = '' } = (location.state as Record<string, string>) || {};
		setTitleType(title);
	}, [location.state]);

	const methods = useForm<IFormInput>({
		defaultValues: {
			[PersonalDetailsFields.firstName]: currentUser?.firstName || '',
			[PersonalDetailsFields.surname]: currentUser?.lastName || '',
			[PersonalDetailsFields.phoneNumber]: currentUser?.phone || '',
			[PersonalDetailsFields.street]: currentUser?.address?.street || '',
			[PersonalDetailsFields.streetNumber]:
				currentUser?.address?.streetNumber || '',
			[PersonalDetailsFields.postCode]: currentUser?.address?.zipCode || '',
			[PersonalDetailsFields.location]: currentUser?.address?.city || '',
			[PersonalDetailsFields.day]: currentUser?.dateOfBirth
				? new Date(currentUser.dateOfBirth).getDate().toLocaleString('en-US', {
						minimumIntegerDigits: 2,
						useGrouping: false,
				  })
				: '',
			[PersonalDetailsFields.month]: currentUser?.dateOfBirth
				? (new Date(currentUser.dateOfBirth).getMonth() + 1).toLocaleString(
						'en-US',
						{
							minimumIntegerDigits: 2,
							useGrouping: false,
						}
				  )
				: '',
			[PersonalDetailsFields.year]: currentUser?.dateOfBirth
				? new Date(currentUser.dateOfBirth).getFullYear().toString()
				: '',
		},
		resolver: yupResolver(PersonalDetailsValidationSchema),
		mode: 'onBlur',
	});
	const { formState, handleSubmit } = methods;
	const { isDirty, isSubmitting, errors } = formState;

	const onSubmit = (data: UnpackNestedValue<IFormInput>) => {
		const {
			firstName,
			surname,
			phoneNumber,
			street,
			streetNumber,
			postCode,
			location,
			day,
			month,
			year,
		} = data;
		const personalDetails = {
			firstName: firstName.trim(),
			lastName: surname.trim(),
			phone: phoneNumber.trim(),
			dateOfBirth:
				year && month && day
					? new Date(`${year}-${month}-${day}`).toISOString()
					: null,
			street: street,
			streetNumber: streetNumber,
			zipcode: postCode.trim() || null,
			city: location.trim() || null,
		};
		onEdit(personalDetails).then(() => {
			if (isUserProfile && onUserEdited) {
				return onUserEdited();
			}
			localStorage.setItem('personalDetails', 'completed');

			return !Capacitor.isNativePlatform() &&
				titleType !== 'notSameDevice' &&
				!isUserProfile
				? navigate({ pathname: '/welcome', search: 'tryOurApp' })
				: navigate('/home');
		});
	};

	const getDisabled = () =>
		!isDirty || Object.keys(errors).length > 0 || isSubmitting;

	const content = (
		<>
			{titleType === 'alreadyVerified' && (
				<Title
					data-testid="personal-details-title-alreadyVerified"
					marginBottom="24px"
				>
					{t('text.already_verified')}
				</Title>
			)}
			{(titleType === 'notSameDevice' ||
				titleType === 'alreadyVerified' ||
				titleType === 'sameDeviceTitle1') && (
				<Title
					data-testid="personal-details-title-moreInfo"
					marginBottom="32px"
				>
					{t('text.title1')}
				</Title>
			)}
			{titleType === 'sameDevice' && (
				<Title
					data-testid="personal-details-title-accountCreated"
					marginBottom="32px"
				>
					{t('text.title2')}
				</Title>
			)}

			<FormWrapper>
				{!isUserProfile && (
					<Description withPaddings={isUserProfile} marginBottom="8px">
						{t('text.description')}
					</Description>
				)}
				{isUserProfile && <JobticketDetailsForm userData={currentUser} />}
				<FormFields
					withPaddings={isUserProfile}
					showAddressToggle={!isUserProfile}
					showMandatotyFieldsHint={isUserProfile}
				/>
				<Description
					withPaddings={isUserProfile}
					marginBottom={isUserProfile ? '0' : '40px'}
				>
					{t('text.description2')}
				</Description>
			</FormWrapper>
		</>
	);

	const footer = (
		<FormFooter
			isSubmitDisabled={getDisabled()}
			isUserProfile={isUserProfile}
			onCancel={() => {
				onAbort?.(isDirty);
			}}
		/>
	);

	return (
		<FormProvider {...methods}>
			<Form onSubmit={handleSubmit((data) => onSubmit(data))}>
				{render({ content, footer })}
			</Form>
		</FormProvider>
	);
};
