import { SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(SwipeableDrawer, {
	shouldForwardProp: (prop) => prop !== 'widthPercentage',
})<{ widthPercentage?: number }>(({ widthPercentage }) => ({
	'.MuiDrawer-paper': {
		display: 'grid',
		gridTemplateRows: 'max-content 1fr',
		overflow: 'hidden',

		borderRadius: '8px 0 0 8px',

		height: '100%',
		width: widthPercentage ? `${widthPercentage}%` : '380px',
	},
}));

export const Header = styled('div', {
	shouldForwardProp: (prop) => prop !== 'onBack' && prop !== 'onClose',
})<{ onBack?: boolean; onClose?: boolean }>(({ theme, onBack, onClose }) => ({
	display: 'grid',
	gridTemplateColumns:
		(onBack && onClose && 'max-content 1fr max-content') ||
		(onBack && !onClose && 'max-content 1fr') ||
		(!onBack && onClose && '1fr max-content') ||
		'max-content max-content',
	gridGap: 8,
	alignItems: 'center',
	width: '100%',

	background: theme.colors.mopla_white,

	padding: '16px 32px',
	boxSizing: 'border-box',

	button: {
		color: theme.colors.mopla_secondary,
	},
}));

export const Title = styled('span')(({ theme }) => ({
	...theme.fonts.subtitle1,
	color: theme.colors.mopla_black,
}));

export const Content = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_white,
	overflow: 'auto',
}));
