import React, { ReactElement } from 'react';
import {
	Controller,
	FormProvider,
	UnpackNestedValue,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoginFields } from '@mopla/constants';

import { Input } from '../../atoms/input/input';
import { PasswordInput } from '../../atoms/PasswordInput/PasswordInput';
import { ArrowRightIcon } from '../../icons/ArrowRight';
import { AttentionIcon } from '../../icons/Attention';

import {
	ForgotPassword,
	Root,
	SubmitButton,
	SubmitLevel,
} from './LoginForm.styles';
import LoginValidationSchema from './LoginValidation';

export interface IFormInput {
	[LoginFields.email]: string;
	[LoginFields.password]: string;
}

interface ILoginFormProps {
	onLogin: (data: UnpackNestedValue<IFormInput>) => void;
	authError: boolean;
	titleComp?: ReactElement | null;
	'data-testid'?: string;
	driverApp?: boolean;
	browser?: boolean;
}

export const LoginForm: React.FC<ILoginFormProps> = (props) => {
	const { onLogin, authError, titleComp = null, driverApp, browser } = props;
	const navigate = useNavigate();
	const { t } = useTranslation(['logIn', 'validation']);

	const methods = useForm<IFormInput>({
		defaultValues: {
			[LoginFields.email]: '',
			[LoginFields.password]: '',
		},
		resolver: yupResolver(LoginValidationSchema),
		mode: 'all',
	});
	const { formState, handleSubmit } = methods;

	const getHelperText = (key: 'password') => {
		if (formState.errors[key])
			return (
				<>
					<AttentionIcon width={15} height={15} />
					<span>{t(`validation:${formState.errors[key]?.message}`)}</span>
				</>
			);
		if (authError)
			return (
				<>
					<AttentionIcon width={15} height={15} />
					<span>{t('validation:auth.error')}</span>
				</>
			);
		return '';
	};

	return (
		<FormProvider {...methods}>
			<form
				data-testid={props['data-testid']}
				style={!browser ? { height: '100%' } : {}}
				onSubmit={handleSubmit((data) => onLogin(data))}
			>
				<Root>
					{titleComp}
					<Controller
						name="email"
						render={({ field }) => (
							<Input
								{...field}
								onChange={(e) => {
									e.target.value = e.target.value.trim();
									field.onChange(e);
								}}
								label={t('label.email')}
								ref={null}
								helperText={
									Boolean(formState.errors.email?.message) && (
										<>
											<AttentionIcon width={15} height={15} />
											<span>
												{t(`validation:${formState.errors.email?.message}`)}
											</span>
										</>
									)
								}
								error={Boolean(formState.errors.email?.message) || authError}
								inputProps={{
									'data-testid': 'loginForm-email-textField',
								}}
							/>
						)}
					/>
					<Controller
						name="password"
						render={({ field }) => (
							<PasswordInput
								{...field}
								onChange={(e) => {
									e.target.value = e.target.value.trim();
									field.onChange(e);
								}}
								label={t('label.password')}
								ref={null}
								helperText={getHelperText('password')}
								error={Boolean(formState.errors.password?.message || authError)}
								inputProps={{
									'data-testid': 'loginForm-password-textField',
								}}
							/>
						)}
					/>
					<ForgotPassword
						driverApp
						onClick={() =>
							navigate({ pathname: '/welcome', search: 'forgotPassword' })
						}
					>
						{t('text.forgot')}
					</ForgotPassword>
					<SubmitLevel browser={browser}>
						<SubmitButton
							variant={driverApp ? 'secondary' : 'primary'}
							icon={<ArrowRightIcon />}
							data-testid="loginForm-login-button"
							disabled={!formState.isValid}
							type="submit"
						>
							{t('button.logIn')}
						</SubmitButton>
					</SubmitLevel>
				</Root>
			</form>
		</FormProvider>
	);
};
