import { ComponentProps, FC } from 'react';

export const TrashIcon: FC<ComponentProps<'svg'>> = (props) => {
	return (
		<svg
			width="49"
			height="49"
			viewBox="0 0 49 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.3334 18.375L14.2917 18.375V38.7917H34.7084V18.375L32.6667 18.375H16.3334ZM32.6667 14.2917H34.7084H36.75H38.7917H40.8334V18.375H38.7917V38.7917C38.7917 39.8746 38.3615 40.9132 37.5957 41.679C36.8299 42.4448 35.7913 42.875 34.7084 42.875H14.2917C13.2087 42.875 12.1701 42.4448 11.4043 41.679C10.6386 40.9132 10.2084 39.8746 10.2084 38.7917V18.375H8.16669V14.2917H10.2084H11.7963H12.25H14.2917H16.3334V10.2083C16.3334 9.12537 16.7636 8.08675 17.5293 7.32098C18.2951 6.55521 19.3337 6.125 20.4167 6.125H28.5834C29.6663 6.125 30.7049 6.55521 31.4707 7.32098C32.2365 8.08675 32.6667 9.12537 32.6667 10.2083V14.2917ZM20.4167 14.2917V10.2083H28.5834V14.2917H20.4167Z"
				fill="currentColor"
			/>
		</svg>
	);
};
