import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Button } from '../../atoms/Button/Button';

export const Root = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',

	'.MuiFormControl-root': {
		marginBottom: '21px',
	},
}));

export const ForgotPassword = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'driverApp',
})<{ driverApp?: boolean }>(({ theme, driverApp }) => ({
	...theme.fonts.body1_semibold,
	color: theme.colors.mopla_secondary,
	marginBottom: '24px',
	marginTop: driverApp ? 3 : 0,
}));

export const SubmitLevel = styled('div', {
	shouldForwardProp: (prop) => prop !== 'browser',
})<{ browser?: boolean }>(({ theme, browser }) => ({
	position: browser ? 'static' : 'relative',
	left: -24,
	bottom: -24,
	width: '100%',
	padding: browser ? '' : '16px 24px',
	marginTop: browser ? '24px' : 'auto',
	backgroundColor: browser ? 'unset' : theme.colors.mopla_white,
	borderRadius: browser ? 'unset' : '8px 8px 0 0',
}));
export const SubmitButton = styled(Button)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}));
