import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
	ArrowRightIcon,
	ErrorModal,
	IconMessage,
	InfoBaseIcon,
} from '@mopla/ui';

interface IProps {
	open: boolean;
	closeModal: VoidFunction;
}

export const BookingErrorModal: FC<IProps> = (props) => {
	const { t } = useTranslation('booking');
	const { open, closeModal } = props;

	if (!open) return null;

	return (
		<ErrorModal
			title={t('modal.header.booking_failed')}
			infoText={t('modal.booking_failed_description')}
			additionalInfo={
				<IconMessage
					icon={<InfoBaseIcon />}
					message={t('modal.booking_failed_additional_text')}
				/>
			}
			submitTitle={t('modal.button.back_to_search')}
			submitIcon={<ArrowRightIcon />}
			onSubmit={closeModal}
		/>
	);
};
