/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEvent } from '../models/CalendarEvent';
import type { DateTimeValue } from '../models/DateTimeValue';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CalendarService {
	/**
	 * Return all calendar events
	 * Returns list of all all calendar events of the distributor
	 * @returns any A JSON Array calendar events
	 * @throws ApiError
	 */
	public static getApiCalendar(): CancelablePromise<
		Array<
			{
				eventId: string;
			} & CalendarEvent
		>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/calendar',
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Return all calendar events for given day
	 * Returns list of all all calendar events of the distributor for given date (day)
	 * @param date date for which we want to see all events
	 * @returns any A JSON Array calendar events
	 * @throws ApiError
	 */
	public static getApiCalendarInfo(date: DateTimeValue): CancelablePromise<
		Array<
			{
				eventId: string;
			} & CalendarEvent
		>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/calendar/info',
			query: {
				date: date,
			},
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Return specific calendar event
	 * Returns detail view for a specific calendar event. Is correctly returned only if belongs to given distributor (user)
	 * @param eventId eventId of object
	 * @returns CalendarEvent A JSON object for a calendar event
	 * @throws ApiError
	 */
	public static getApiCalendar1(
		eventId: string
	): CancelablePromise<CalendarEvent> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/calendar/{eventId}',
			path: {
				eventId: eventId,
			},
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}
}
