import {
	ForwardedRef,
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
	PassengerBooking,
	paymentFinishSub$,
	useDefaultPaymentMethod,
	usePassengerBookings,
	useUser,
} from '@mopla/business-logic';
import { DiscountState, Itinerary } from '@mopla/data-models';
import {
	FlexContainer,
	Loader,
	PriceString,
	Responsive,
	SearchHeader,
	StatusPayment,
} from '@mopla/ui';
import { localStorageManager, useBreakpoints } from '@mopla/utils';

import passApi from '../../../api/passApi';
import { PaymentPortal } from '../PaymentPortal/PaymentPortal';

import { useBookingContext } from './bookingContext';
import { BookingErrorModal } from './BookingErrorModal';
import {
	ActionWrapper,
	ContentGrid,
	Form,
	Root,
	ScrollWrapper,
	StyledStepper,
} from './BookingFlow.styles';
import { CompanionSuggest } from './CompanionSuggest';
import { FirstBookingStep } from './FirstStep';
import { ImpairedCardReminder } from './ImpairedCardReminder';
import { ImpairedPersonSuggest } from './ImpairedPersonSuggest';
import LastBookingStep from './LastStep';
import { PaymentMethodStep } from './PaymentMethod';
import { PriceStringSeparator } from './PriceStringSeparator';
import { ProceedButton } from './ProceedButton';
import SecondBookingStep from './SecondStep';
import {
	BookingFlowProps,
	BookingHandlers,
	BuyFor,
	EFlowSteps,
	IPersistStateLS,
	TBookingFormState,
} from './types';
import { toggleTariffsCheckbox as toggleTariffsCheckboxAction } from './useBookingFlowStore';
import { withBookingContext } from './withBookingContext';
import { withBookingForm } from './withBookingForm';

const persistStateManager = localStorageManager<IPersistStateLS>(
	'BookingFlow__persist_state'
);

export const BookingFlowComponent = forwardRef(function BookingFlow(
	{ onClose, askPermissions, ...props }: BookingFlowProps,
	ref: ForwardedRef<BookingHandlers>
) {
	const {
		defaultPaymentMethod,
		isPaymentMethodLoading,
		isPaymentMethodSaving,
	} = useDefaultPaymentMethod();
	const { loadBookings } = usePassengerBookings();
	const { t } = useTranslation('booking');
	const navigate = useNavigate();
	const { isAboveMobile } = useBreakpoints();
	const [query, setQuery] = useSearchParams();
	const location = useLocation();
	const [isPaymentRedirect] = useState(query.has('isPaymentRedirect'));

	const wrapperRef = useRef<null | HTMLDivElement>(null);
	const { userData } = useUser();

	const formCtx = useFormContext<TBookingFormState>();
	const { handleSubmit, formState, getValues, watch, setValue } = formCtx;
	const { buyFor: selectedOption } = getValues();
	const { isSubmitting } = formState;
	const formValues = watch();

	const {
		state,
		setState,
		dispatch,
		isLBTFlow,
		isLBODTFlex,
		flowStepsMethods,
		paymentAmount,
	} = useBookingContext();

	const {
		persistenceId,
		activeRide,
		searchData,
		isAcceptTariffs,
		forceInitiatePayment,
		showQR,
		showErrorModal,
		loadingAddPaymentMethodScreen,
	} = state;

	const {
		currentStep,
		goToNextStep,
		goToPreviousStep,
		setCurrentStep,
		stepsCount,
	} = flowStepsMethods;

	/** When payment method is being saved or fetched, change the loader state accordingly */
	useEffect(() => {
		setState(
			'loadingAddPaymentMethodScreen',
			isPaymentMethodLoading || isPaymentMethodSaving
		);
	}, [isPaymentMethodLoading, isPaymentMethodSaving, setState]);

	useEffect(() => {
		const isWeb = Capacitor.getPlatform() === 'web';
		let subscription: Subscription;
		const redirectStatus = query.get('redirect_status');

		if (isWeb) {
			if (isPaymentRedirect) {
				if (redirectStatus !== 'succeeded') {
					setState('forceInitiatePayment', true);
				} else {
					query.delete('isPaymentRedirect');
					query.delete('redirect_status');
				}
			}
		} else {
			subscription = paymentFinishSub$
				.pipe(filter(Boolean))
				.subscribe((result) => {
					if (result === 'paymentClosed') {
						setState('forceInitiatePayment', false);
					}
					paymentFinishSub$.next(null);
				});
		}

		return () => {
			subscription?.unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (!activeRide && !query.has('restoreState')) {
			navigate(-1);
		} else {
			checkPermissions();
		}
	}, [activeRide]);

	useEffect(() => {
		wrapperRef.current && wrapperRef.current.scrollTo(0, 0);
	}, [currentStep.step]);

	useEffect(() => {
		const shouldRestoreState = query.has('restoreState');
		const isWeb = Capacitor.getPlatform() === 'web';

		if (shouldRestoreState || !isWeb || !isLBODTFlex) {
			return;
		}

		persistStateManager.set({
			activeRide,
			buyFor: selectedOption,
			step: currentStep.step,
			form: formValues,
			persistenceId,
			steps: flowStepsMethods.stepsRef.current,
		});
	}, [
		activeRide,
		selectedOption,
		formValues,
		currentStep,
		query,
		persistenceId,
		isLBODTFlex,
	]);

	useEffect(() => {
		const restoreStatePersistenceId = query.get('restoreState');
		const restoredState = persistStateManager.get();

		/** Restoring the persisted state of the component, if needed */
		if (
			restoreStatePersistenceId &&
			restoredState &&
			restoreStatePersistenceId === restoredState.persistenceId
		) {
			persistStateManager.clear();
			query.delete('restoreState');
			setQuery(query);
			try {
				setState('activeRide', restoredState.activeRide);
				Object.entries(restoredState.form).forEach(([name, value]: any) => {
					setValue(name, value, {
						shouldDirty: true,
						shouldValidate: true,
						shouldTouch: true,
					});
				});

				/** Form values are set asynchronously. Trigger building steps in a next loop cycle therefore */
				setTimeout(() => {
					/**
					 * When restoring the state, set saved steps manually.
					 * They will be rewritten later, but initially they will be correct. That's what we need.
					 * */
					flowStepsMethods.stepsRef.current = restoredState.steps;
					setCurrentStep(restoredState.step);
				});

				/** https://github.com/react-hook-form/react-hook-form/issues/2755#issuecomment-1371650635 */
				void formState.isValid;
			} catch (e) {
				console.log(e);
			}
		} else {
			persistStateManager.clear();
			/** Perhaps an error should be raised here */
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/** Advance the payment step when a payment method is added */
	useEffect(() => {
		if (
			currentStep.step === EFlowSteps.AddPaymentMethod &&
			defaultPaymentMethod
		) {
			goToNextStep();
		}
	}, [defaultPaymentMethod, currentStep]);

	const checkPermissions = async () => {
		const permissions = await FirebaseMessaging.checkPermissions();
		setState('permissionsType', permissions.receive);
	};

	const onSubmit: SubmitHandler<TBookingFormState> = async (data) => {
		const passengerList = [data.main, ...data.attendees2].map(
			(passenger, i) => {
				if (i === 0) {
					return {
						isMainPassenger: true,
						email:
							selectedOption === BuyFor.anotherPerson
								? data.email
								: userData?.email,
						firstName:
							selectedOption === BuyFor.anotherPerson
								? data.name
								: userData?.firstName || '',
						lastName:
							selectedOption === BuyFor.anotherPerson
								? data.surname
								: userData?.lastName || '',
						phone:
							selectedOption === BuyFor.anotherPerson
								? data.phoneNumber
								: userData?.phone,
						specialNeeds: searchData.specialNeeds,
						discountState: passenger.discountState || DiscountState.NO_DISCOUNT,
					};
				}

				return {
					isMainPassenger: false,
					discountState: passenger.discountState || DiscountState.NO_DISCOUNT,
					specialNeeds: searchData.specialNeeds,
				};
			}
		);

		try {
			const createdBooking = await passApi.post('/api/command/bookJourney', {
				passengerList,
				itineraryParams: {
					fromPlace: {
						name: searchData.fromPlaceLabel,
						lng: searchData.fromPlaceLng,
						lat: searchData.fromPlaceLat,
						zipcode: searchData.fromPlaceZip,
						province: searchData.fromPlaceRegion,
					},
					toPlace: {
						name: searchData.toPlaceLabel,
						lng: searchData.toPlaceLng,
						lat: searchData.toPlaceLat,
						zipcode: searchData.toPlaceZip,
						province: searchData.toPlaceRegion,
					},
					datetime: searchData.datetime,
					arriveBy: searchData.arriveBy,
					specialNeeds: searchData.specialNeeds,
				},
				itinerary: {
					...activeRide,
				},
				paymentAmount: paymentAmount.digitalPayment,
				toddlerSeats: data.toddlerSeats,
				bulkyLuggage: data.bulkyLuggage,
			});
			const bookings = await loadBookings();

			const bookedTicket = bookings.find(
				(booking: PassengerBooking) =>
					booking.bookingNumber === createdBooking.id
			);
			setState('booking', bookedTicket as PassengerBooking);
			if (isAboveMobile) {
				navigate(
					{
						pathname: '/home',
						search: `bookedTicketId=${bookedTicket?.bookingNumber}`,
					},
					{ replace: true }
				);
			} else {
				goToNextStep();
			}
		} catch (err) {
			console.log('Error on booking the journey', err);
			setState('showErrorModal', true);
		}
	};

	const previousStepHandler = () => {
		if (showQR) {
			navigate('/home', { replace: true });
			return;
		}

		const hadPrevStep = goToPreviousStep();

		/** In case there was no previous step, navigate back to previous URL */
		if (!hadPrevStep) {
			if (!isPaymentRedirect) {
				navigate(-1);
			} else {
				/**
				 * If it is a bank redirect (user was redirected from a bank page to here and continue booking flow)
				 * navigate(-1) will lead the user to that bank page, which is wrong
				 * navigate({any_mopla_url}) otherwise keeps the user in the mopla.
				 * Potentially can be improved: previous url+search might be saved and used here
				 * */
				navigate('/home', { replace: true });
			}
		}
	};

	const toggleTariffsCheckbox = useCallback(
		() => dispatch(toggleTariffsCheckboxAction()),
		[dispatch]
	);

	const renderStep = () => {
		if (currentStep.isAttendee) {
			return currentStep.layout;
		}

		switch (currentStep.step) {
			case EFlowSteps.PriceCalcPassengerMain:
				return currentStep.layout;
			case EFlowSteps.AddPaymentMethod:
				return (
					<PaymentMethodStep isPreparing={loadingAddPaymentMethodScreen} />
				);
			case EFlowSteps.Summary:
				if (isSubmitting)
					return (
						<FlexContainer className="center">
							<Loader />
						</FlexContainer>
					);

				return (
					<SecondBookingStep
						ticket={activeRide as Itinerary}
						onEdit={() => {
							setCurrentStep(EFlowSteps.SelectPassengers);
						}}
						onPriceEdit={() => {
							setCurrentStep(EFlowSteps.PriceCalcPassengerMain);
						}}
						specialNeeds={searchData.specialNeeds}
						isAcceptTariffs={isAcceptTariffs}
						toggleTariffsCheckbox={toggleTariffsCheckbox}
					/>
				);
			case EFlowSteps.Thanks:
				return <LastBookingStep />;
			case EFlowSteps.SelectPassengers:
			default:
				return (
					<FirstBookingStep
						phone={userData?.phone || ''}
						toddlersDefinition={activeRide?.toddlersDefinition}
						luggageDefinition={activeRide?.luggageDefinition}
					/>
				);
		}
	};

	const getHeader = () => {
		if (currentStep.isAttendee) {
			return t('text.header.price_calculation');
		}

		switch (currentStep.step) {
			case EFlowSteps.PriceCalcPassengerMain:
				return t('text.header.price_calculation');
			case EFlowSteps.AddPaymentMethod:
				return t('text.header.payment');
			case EFlowSteps.Summary:
				return t('text.header.text2');
			case EFlowSteps.Thanks:
				return t('text.header.thank_you');
			case EFlowSteps.SelectPassengers:
			default:
				return t('text.header.text');
		}
	};

	const getStripeRedirectUrlWeb = () => {
		/** Be aware: If URL is long, Stripe might throw:
		 * Invalid URL: URL must be 2048 characters or less.
		 * */
		const q = new URLSearchParams(location.search);
		q.set('restoreState', persistenceId);

		return `/home/search?${q.toString()}`;
	};

	useImperativeHandle(
		ref,
		() => ({
			goBack: previousStepHandler,
			title: `${currentStep.index + 1}/${stepsCount} ${getHeader()}`,
		}),
		[stepsCount, currentStep]
	);

	const startLoader = useCallback(
		() => setState('loadingAddPaymentMethodScreen', true),
		[]
	);
	const stopLoader = useCallback(
		() => setState('loadingAddPaymentMethodScreen', false),
		[]
	);
	const closeBookingErrorModal = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const onClosePayment = useCallback(() => {
		setState('forceInitiatePayment', false);
	}, []);

	const showBookingPrice =
		(isLBTFlow && currentStep.step === EFlowSteps.Summary) ||
		(isLBODTFlex &&
			([EFlowSteps.PriceCalcPassengerMain, EFlowSteps.Summary].includes(
				currentStep.step
			) ||
				currentStep.isAttendee));

	return (
		<>
			{forceInitiatePayment && (
				<PaymentPortal
					onUserHasDefaultPaymentMethod={() => null}
					onClose={onClosePayment}
					startLoader={startLoader}
					stopLoader={stopLoader}
					stripeRedirectUrlWeb={getStripeRedirectUrlWeb()}
				/>
			)}
			<BookingErrorModal
				open={showErrorModal}
				closeModal={closeBookingErrorModal}
			/>
			<Form
				onSubmit={handleSubmit(onSubmit)}
				data-testid="booking-firstStep-screen"
			>
				<Root
					variant={
						currentStep.step === EFlowSteps.Thanks ? 'secondary' : undefined
					}
				>
					<Responsive mobile>
						<SearchHeader
							open={true}
							showBack={currentStep.step !== EFlowSteps.Thanks}
							showClose={currentStep.step === EFlowSteps.Thanks}
							onBack={previousStepHandler}
							onClose={onClose}
							title={getHeader()}
							variant="secondary"
						/>
					</Responsive>
					<ScrollWrapper ref={wrapperRef}>
						<ContentGrid
							showQR={showQR}
							showLoader={isSubmitting}
							data-testid="booking-secondStep-screen"
						>
							{renderStep()}
						</ContentGrid>
						{isAboveMobile ||
						currentStep.step === EFlowSteps.Thanks ||
						isLBTFlow ? null : (
							<StyledStepper
								variant="dots"
								steps={stepsCount}
								position="static"
								activeStep={currentStep.index}
								nextButton={null}
								backButton={null}
							/>
						)}
					</ScrollWrapper>
					<ActionWrapper>
						{showBookingPrice && (
							<StatusPayment
								status={t('text.total_price')}
								payment={
									<PriceString
										price={isLBODTFlex ? paymentAmount.digitalPayment : null}
										hasPriceTariff={paymentAmount.hasPriceAccordingTarif}
										separator={<PriceStringSeparator />}
									/>
								}
								testId="booking-totalPrice-text"
							/>
						)}
						<ProceedButton askPermissions={askPermissions} onClose={onClose} />
					</ActionWrapper>
				</Root>
			</Form>
			<ImpairedCardReminder />
			<CompanionSuggest />
			<ImpairedPersonSuggest />
		</>
	);
});

export const BookingFlow = withBookingForm(
	withBookingContext(BookingFlowComponent)
);
