import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import Button from '../Button/Button';

import { Content, Root, Text, Title } from './UploadLoader.styles';

export interface UploadLoaderProps {
	name: string;
	uploadedCount: number;
	totalCount: number;
	onCancel: () => void;
}

export const UploadLoader: React.FC<UploadLoaderProps> = ({
	name,
	totalCount,
	uploadedCount,
	onCancel,
}) => {
	const { t } = useTranslation('docsUpload');
	return (
		<Root>
			<Content data-testid="docsUpload-uploadPage-loading-block">
				<Title>{t('text.your_photos_will_now_be_uploaded')}</Title>
				<CircularProgress thickness={7} size={80} />
				<Text>
					<Trans i18nKey="docsUpload:text.well_done" values={{ name: name }} />
				</Text>
				<Text data-testid="docsUpload-uploadPage-uploadedPhotos-count">
					<Trans
						i18nKey="docsUpload:text.uploaded_count"
						values={{ uploadedCount: uploadedCount, totalCount: totalCount }}
					/>
				</Text>
				<Button
					variant="outlined"
					onClick={onCancel}
					data-testid="docsUpload-uploadPage-loading-abort-button"
				>
					{t('button.cancel_upload')}
				</Button>
			</Content>
		</Root>
	);
};

export default UploadLoader;
