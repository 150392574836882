import React, { useState } from 'react';

import { FlexColumn } from '@mopla/ui';
import { DTicketExist } from '@mopla/ui/icons/DTicketExist';
import { PauseDTicket } from '@mopla/ui/icons/PauseDTicket';
import { Wallet } from '@mopla/ui/icons/Wallet';
import { Carousel } from '@mopla/ui/molecules/Carousel/Carousel';

import { marketingItems } from './constants';
import { BlockRoot, CustomStepper } from './DTicketMarketingBlock.styles';
import { DTicketMarketingItem } from './DTicketMarketingItem';

const initialIndex = 1;
const slideImageByIndex = [<Wallet />, <PauseDTicket />, <DTicketExist />];
export const DTicketMarketingBlock: React.FC = () => {
	const [activeIndex, setActiveIndex] = useState(initialIndex);

	const setIndex = (slideIndex: number) => setActiveIndex(slideIndex);

	const slidesList = marketingItems.map((item, index) => (
		<DTicketMarketingItem {...item} image={slideImageByIndex[index]} />
	));

	return (
		<FlexColumn className="space-between">
			<BlockRoot>
				<Carousel slideList={slidesList} onActiveIndexChange={setIndex} />
			</BlockRoot>
			<CustomStepper
				variant="dots"
				steps={marketingItems.length}
				position="static"
				activeStep={activeIndex - 1}
				nextButton={null}
				backButton={null}
			/>
		</FlexColumn>
	);
};
