import React from 'react';

export const HomeIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask id="path-1-inside-1_2053_9512" fill="white">
				<path d="M5 10.4599C5 10.168 5.12756 9.89066 5.34921 9.70068L11.3492 4.55782C11.7237 4.23683 12.2763 4.23683 12.6508 4.55782L18.6508 9.70068C18.8724 9.89066 19 10.168 19 10.4599V20H13V16H11V20H5V10.4599Z" />
			</mask>
			<path
				d="M19 20V22H21V20H19ZM5 20H3V22H5V20ZM11 20V22H13V20H11ZM13 20H11V22H13V20ZM11 16V14H9V16H11ZM13 16H15V14H13V16ZM12.6508 4.55782L11.3492 6.07633L12.6508 4.55782ZM17 10.4599V20H21V10.4599H17ZM7 20V10.4599H3V20H7ZM6.65079 11.2192L12.6508 6.07633L10.0476 3.03931L4.04763 8.18217L6.65079 11.2192ZM11.3492 6.07633L17.3492 11.2192L19.9524 8.18216L13.9524 3.03931L11.3492 6.07633ZM11 18H5V22H11V18ZM19 18H13V22H19V18ZM9 16V20H13V16H9ZM15 20V16H11V20H15ZM13 14H11V18H13V14ZM12.6508 6.07633C12.2763 6.39733 11.7237 6.39732 11.3492 6.07633L13.9524 3.03931C12.8289 2.07634 11.1711 2.07633 10.0476 3.03931L12.6508 6.07633ZM21 10.4599C21 9.58416 20.6173 8.75211 19.9524 8.18216L17.3492 11.2192C17.1276 11.0292 17 10.7519 17 10.4599H21ZM7 10.4599C7 10.7519 6.87244 11.0292 6.65079 11.2192L4.04763 8.18217C3.38269 8.75211 3 9.58416 3 10.4599H7Z"
				fill="currentColor"
				mask="url(#path-1-inside-1_2053_9512)"
			/>
		</svg>
	);
};
