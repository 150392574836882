import {
	ComponentType,
	ForwardedRef,
	forwardRef,
	useCallback,
	useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { parseItinerarySearchParams, useUser } from '@mopla/business-logic';
import { CurrencyCode, DiscountState } from '@mopla/data-models';
import {
	calcPriceByDiscountType,
	isLBODTFlexItinerary,
	isLBTItinerary,
	isMixedItinerary,
} from '@mopla/utils';

import { BookingContextProvider, IBookingContext } from './bookingContext';
import {
	BookingFlowProps,
	BookingHandlers,
	BuyFor,
	TBookingFormState,
	TPassengersFormPath,
} from './types';
import { useFlowSteps } from './useBookingFlowSteps';
import { useBookingFlowStore } from './useBookingFlowStore';
import { calculateBookingPrice, hasOnlyDisabledPassengers } from './utils';

export const withBookingContext = (
	WrappedComponent: ComponentType<
		BookingFlowProps & { ref: ForwardedRef<BookingHandlers> }
	>
) => {
	return forwardRef<BookingHandlers, BookingFlowProps>(function WithContext1(
		props,
		ref
	) {
		const location = useLocation();
		const [searchParams] = useSearchParams();

		/**
		 * {activeRide: Itinerary} initial value can be restored from history.state, if page is reloaded
		 * or it can be taken from props directly
		 * */
		const { activeRide: activeRideInitialVal } = location.state || props;

		const { state, setState, dispatch } = useBookingFlowStore({
			searchData: parseItinerarySearchParams(searchParams),
			activeRide: activeRideInitialVal,
		});
		const { userData } = useUser();
		const formCtx = useFormContext<TBookingFormState>();
		const { setValue, watch } = formCtx;
		const passengersData = watch(['main', 'attendees2']);
		const buyFor = watch('buyFor');

		const { activeRide } = state;

		const hasValidDisabledStatus = useMemo(() => {
			return Boolean(
				userData?.isDisabled &&
					dayjs().isBefore(userData.disabilityStatusValidTo)
			);
		}, [userData]);

		const canBookForDisabled = useMemo(() => {
			return Boolean(
				userData?.privileges.includes('CAN_BOOK_FOR_SEVERELY_DISABLED') &&
					buyFor === BuyFor.anotherPerson
			);
		}, [userData, buyFor]);

		const isLBODTFlex = isLBODTFlexItinerary(activeRide);
		const isLBTFlow = isLBTItinerary(activeRide);
		const isMixedRoute = isMixedItinerary(activeRide);

		const { hasImpaired, hasImpairedCompanion } = useMemo(() => {
			const [mainPassenger, attendees] = passengersData;
			const result = {
				hasImpaired: false,
				hasImpairedCompanion: false,
			};

			[mainPassenger, ...attendees].forEach(({ discountState }) => {
				if (discountState === DiscountState.IMPAIRED) {
					result.hasImpaired = true;
				}

				if (discountState === DiscountState.IMPAIRED_COMPANION) {
					result.hasImpairedCompanion = true;
				}
			});

			return result;
		}, [passengersData]);

		const paymentAmount = useMemo<IBookingContext['paymentAmount']>(() => {
			if (!activeRide) {
				return {
					digitalPayment: 0,
				};
			}

			const passengersDataArr = passengersData.flat();

			if (hasOnlyDisabledPassengers(passengersDataArr)) {
				return {
					digitalPayment: 0,
					notNeeded: true,
				};
			}

			const { digitalPayment, hasLegWithoutPaymentInfo } =
				calculateBookingPrice(activeRide, passengersDataArr);

			return {
				digitalPayment,
				hasPriceAccordingTarif: hasLegWithoutPaymentInfo,
				/**
				 * If the user has this privilege (thus is a Distributor),
				 * We suppose these users book a ride for other people, not for themselves,
				 * therefore a payment isn't needed
				 *  */
				notNeeded: userData?.privileges.includes('CAN_PAY_BOOKING_IN_VEHICLE'),
			};
		}, [activeRide, passengersData, userData]);

		const setPriceInfo = useCallback(
			(discountState: DiscountState | null, formType: TPassengersFormPath) => {
				if (!activeRide) {
					throw new Error('setPriceInfo: no ticket');
				}

				let nextAmountToPay: number | null = null;
				let nextCurrency: CurrencyCode | null = null;

				if (discountState) {
					const { totalAmount, currency } = calcPriceByDiscountType(
						activeRide,
						discountState
					);
					nextAmountToPay = totalAmount;
					nextCurrency = currency;
				}

				setValue(formType, {
					discountState: discountState,
					amountToPay: nextAmountToPay,
					currency: nextCurrency,
				});
			},
			[setValue, activeRide]
		);

		const flowStepsMethods = useFlowSteps({
			isLBTFlow,
			isLBODTFlex,
		});

		const contextValue = useMemo<IBookingContext>(
			() => ({
				state,
				setState,
				isLBTFlow,
				isLBODTFlex,
				isMixedRoute,
				flowStepsMethods,
				hasImpaired,
				hasImpairedCompanion,
				paymentAmount,
				setPriceInfo,
				dispatch,
				hasValidDisabledStatus,
				canBookForDisabled,
			}),
			[
				flowStepsMethods,
				hasImpaired,
				hasImpairedCompanion,
				hasValidDisabledStatus,
				isLBODTFlex,
				isLBTFlow,
				paymentAmount,
				setPriceInfo,
				state,
				setState,
				dispatch,
				canBookForDisabled,
				isMixedRoute,
			]
		);

		return (
			<BookingContextProvider value={contextValue}>
				<WrappedComponent ref={ref} {...props} />
			</BookingContextProvider>
		);
	});
};
