import { FC, useEffect } from 'react';
import bwipjs from 'bwip-js';

import { BarCodeCanvas } from './BarCode.styles';

// Symbologies Reference https://github.com/bwipp/postscriptbarcode/wiki/Symbologies-Reference
// all supported types https://github.com/metafloor/bwip-js/wiki/BWIPP-Barcode-Types
enum BarcodeType {
	AZTEC = 'azteccode',
}

interface IProps {
	type: BarcodeType | string;
	textToEncode: string;
	id: string;
}
// Documentation of 'bwip-js' lib: https://github.com/bwipp/postscriptbarcode/wiki

export const BarCode: FC<IProps> = (props) => {
	const { type, textToEncode, id } = props;

	useEffect(() => {
		try {
			bwipjs.toCanvas(`barcode-canvas-${id}`, {
				bcid: type, // Barcode type
				text: textToEncode, // Text to encode
			});
		} catch (e) {
			console.error('Error on rendering QR code: ', e);
		}
	}, [textToEncode, type, id]);

	return <BarCodeCanvas id={`barcode-canvas-${id}`} />;
};
