import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AttentionIcon, Text } from '@mopla/ui';

import { PaymentDetails as StripePaymentDetail } from '../../../PaymentDetails/PaymentDetails';
import { useBookingContext } from '../../bookingContext';
import {
	AttentionWrapper,
	BlockTitle,
	InfoBoxColumn,
} from '../../BookingFlow.styles';

import {
	Header,
	PaperStyled,
	TooltipIconStyled,
	TooltipStyled,
} from './styled';

export const PaymentMethodSummary: FC = () => {
	const { t } = useTranslation('booking');
	const { isMixedRoute, paymentAmount } = useBookingContext();

	return (
		<InfoBoxColumn>
			<Header>
				<BlockTitle>{t('text.pay')}</BlockTitle>
				{isMixedRoute && (
					<TooltipStyled
						placement="left-start"
						enterTouchDelay={0}
						leaveTouchDelay={3000}
						title={
							<PaperStyled>
								<Text variant="caption" color="mopla_secondary">
									{t('text.mixed_ride_payment_reminder')}
								</Text>
							</PaperStyled>
						}
					>
						<div>
							<TooltipIconStyled />
						</div>
					</TooltipStyled>
				)}
			</Header>
			{paymentAmount.notNeeded && (
				<Text
					variant="body1"
					data-testid="booking-purchaseSummary-paymentMethod-text"
				>
					{t('text.payment_not_needed')}
				</Text>
			)}
			{paymentAmount.digitalPayment > 0 && (
				<>
					<StripePaymentDetail />
					<AttentionWrapper>
						<AttentionIcon />
						<span>{t('text.charge_on_checkin')}</span>
					</AttentionWrapper>
				</>
			)}
			{paymentAmount.hasPriceAccordingTarif && (
				<>
					<Text
						variant="body1"
						data-testid="booking-purchaseSummary-paymentMethod-text"
					>
						{t('text.cash_on_the_bus')}
					</Text>
					<Text
						variant="body3"
						color="mopla_dark_grey"
						data-testid="booking-purchaseSummary-notification-OPNVticket-text"
					>
						{t('text.pay_description')}
					</Text>
				</>
			)}
		</InfoBoxColumn>
	);
};
