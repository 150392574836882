import { FC } from 'react';

import { ArrowDownIcon, ArrowUpIcon } from '../../icons';

export interface IDropdownIconProps {
	isOpened: boolean;
}

export const DropdownIcon: FC<IDropdownIconProps> = ({ isOpened }) => {
	return isOpened ? <ArrowUpIcon /> : <ArrowDownIcon />;
};
