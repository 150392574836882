import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { RxDatabase, RxLocalDocument } from 'rxdb';
import { RxLocalDocumentData } from 'rxdb/dist/types/types/plugins/local-documents';

import { ISubscriptionOffering } from '@mopla/data-models';

import { fetchSubscriptionOffering } from '../actions/subscriptionActions';
import { useBusinessLayer } from '../business-logic';

export const useDTicketSubscriptionOffering = (monthOfStart?: string) => {
	const bl = useBusinessLayer();
	const [subscriptionOffering, setSubscriptionOffering] =
		useState<OfferingData>(null);

	useEffect(() => {
		const subscriptionOfferingLiveQuery = bl.db.getLocal$<OfferingData>(
			'subscriptionOffering'
		);

		const sub = subscriptionOfferingLiveQuery.subscribe(
			(val: RxLocalDocument<RxDatabase, OfferingData> | null) => {
				if (val) {
					const data: RxLocalDocumentData<OfferingData> = val.toMutableJSON();
					setSubscriptionOffering(data.data);
				} else {
					setSubscriptionOffering(null);
				}
			}
		);

		return () => {
			sub.unsubscribe();
		};
	}, [bl.db]);

	const subscriptionOfferByMonth = useMemo(() => {
		if (!subscriptionOffering || !monthOfStart) {
			return null;
		}

		const { offers } = subscriptionOffering;

		const found = offers.find((offer) =>
			dayjs(offer.startDate).isSame(dayjs(monthOfStart), 'month')
		);

		return found || null;
	}, [subscriptionOffering, monthOfStart]);

	useEffect(() => {
		bl.dispatch(fetchSubscriptionOffering());
	}, [bl]);

	return {
		subscriptionOffering,
		subscriptionOfferByMonth,
		refetch: () => {
			bl.dispatch(fetchSubscriptionOffering());
		},
	};
};

type OfferingData = ISubscriptionOffering | null;
