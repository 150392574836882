/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BookingStatus {
	NEW = 'NEW',
	EXECUTING = 'EXECUTING',
	FINISHED = 'FINISHED',
	CANCELLED_BY_DISTRIBUTOR = 'CANCELLED_BY_DISTRIBUTOR',
	CANCELLED_BY_PASSENGER = 'CANCELLED_BY_PASSENGER',
	CANCELLED_NO_SHOW = 'CANCELLED_NO_SHOW',
}
