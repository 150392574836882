import { FC, ReactNode } from 'react';

import { ILeg } from '@mopla/data-models';
import {
	calculateItineraryPricingsRange,
	checkHasLegWithoutPaymentInfo,
} from '@mopla/utils';

import { defaultRenderer } from './defaultRenderer';
import { PriceString } from './PriceString';

export interface IPriceBlockProps {
	legs: ILeg | ILeg[];
	render?: (children: ReactNode) => ReactNode;
}

export const PriceBlock: FC<IPriceBlockProps> = ({
	legs,
	render = defaultRenderer,
}) => {
	const _legs = Array.isArray(legs) ? legs : [legs];
	const priceRange = calculateItineraryPricingsRange(_legs);
	const hasPriceTariff = checkHasLegWithoutPaymentInfo(_legs);

	return (
		<>
			{render(
				<PriceString
					priceRangePerPerson={priceRange}
					hasPriceTariff={hasPriceTariff}
					single={_legs.length === 1}
				/>
			)}
		</>
	);
};
