import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',

	button: {
		marginTop: 'auto',
	},
}));

export const Title = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginBottom',
})<{ marginBottom?: string }>(({ theme, marginBottom }) => ({
	...theme.fonts.body1,
	color: theme.colors.mopla_black,
	marginBottom: marginBottom || 0,
}));

export const Bold = styled('span')(({ theme }) => ({
	fontWeight: '600',
}));
