import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_white,
	borderRadius: 8,

	[theme.breakpoints.up('tabletP')]: {
		width: 550,
		margin: 'auto',
	},
}));

export const Header = styled('div')(({ theme }) => ({
	background: theme.colors.mopla_secondary,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	borderRadius: 8,
	padding: '16px 26px 16px',
}));

export const Content = styled('div')(() => ({
	padding: '16px 16px 16px 26px',
}));
export const AdditionalContent = styled('div')(() => ({
	padding: '0px 16px 0px 0px',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	flexShrink: 200,
}));
export const SeeMoreButton = styled(Button)(() => ({
	width: 'fit-content',
	padding: 0,
	textTransform: 'initial',
	textDecoration: 'underline',
	marginTop: 8,
}));
export const AdditionalContentText = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-end',
	textAlign: 'end',
	flexShrink: 200,
}));
