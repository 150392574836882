import React from 'react';

export const BusHomeIcon = () => {
	return (
		<svg
			width="213"
			height="94"
			viewBox="0 0 213 94"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M59.1211 1.15625H248.074V4.13711H67.8534L67.0217 3.6403L59.9527 4.63392L59.1211 1.15625Z"
				fill="#6FDB89"
			/>
			<rect
				x="69.7578"
				y="43.3789"
				width="28.7237"
				height="2.93099"
				fill="#6FDB89"
			/>
			<path
				d="M132.165 70.3149C132.007 70.3149 131.855 70.252 131.743 70.1401C131.631 70.0282 131.568 69.8763 131.568 69.718V69.718C131.568 69.5597 131.631 69.4079 131.743 69.2959C131.855 69.184 132.007 69.1211 132.165 69.1211H218.45C218.608 69.1211 218.76 69.184 218.872 69.2959C218.984 69.4079 219.047 69.5597 219.047 69.718V69.718C219.047 69.8763 218.984 70.0282 218.872 70.1401C218.76 70.252 218.608 70.3149 218.45 70.3149H132.165Z"
				fill="white"
			/>
			<path
				d="M246.354 70.3183C246.196 70.3183 246.044 70.2554 245.932 70.1435C245.82 70.0315 245.757 69.8797 245.757 69.7214V69.7214C245.757 69.563 245.82 69.4112 245.932 69.2993C246.044 69.1873 246.196 69.1244 246.354 69.1244H281.755L291.994 65.3333V1.18929H59.987V4.83461C59.987 4.913 59.9715 4.99062 59.9415 5.06304C59.9115 5.13546 59.8676 5.20127 59.8121 5.2567C59.7567 5.31213 59.6909 5.3561 59.6185 5.38609C59.5461 5.41609 59.4684 5.43153 59.39 5.43153V5.43153C59.3094 5.4334 59.2291 5.41889 59.1542 5.38886C59.0793 5.35884 59.0112 5.31393 58.9541 5.25686C58.8971 5.19978 58.8522 5.13172 58.8221 5.0568C58.7921 4.98187 58.7776 4.90164 58.7795 4.82094V4.82094V0.596922C58.7791 0.517972 58.7947 0.43976 58.8252 0.366952C58.8557 0.294145 58.9006 0.228234 58.9572 0.173153V0.173153C59.069 0.0629412 59.2194 0.000800608 59.3764 0L292.59 0C292.747 0.000800608 292.898 0.0629412 293.01 0.173153V0.173153C293.065 0.22877 293.109 0.294881 293.139 0.367635C293.169 0.440389 293.184 0.518332 293.183 0.596922V0.596922V65.7571C293.184 65.879 293.147 65.9983 293.078 66.0986C293.009 66.1989 292.91 66.2753 292.796 66.3175L282.056 70.2773L281.85 70.3183H246.354Z"
				fill="white"
			/>
			<path
				d="M67.7331 70.3136C67.6448 70.3124 67.5578 70.2922 67.4779 70.2543V70.2543L59.1347 66.2809C59.0328 66.2327 58.9466 66.1566 58.8861 66.0615C58.8256 65.9663 58.7933 65.856 58.793 65.7432V65.7432V8.75317C58.793 8.59486 58.8559 8.44303 58.9678 8.33108C59.0798 8.21914 59.2316 8.15625 59.3899 8.15625C59.5482 8.15625 59.7 8.21914 59.812 8.33108C59.9239 8.44303 59.9868 8.59486 59.9868 8.75317V8.75317V65.365L67.8926 69.1197H104.282C104.44 69.1197 104.592 69.1826 104.704 69.2946C104.816 69.4065 104.879 69.5583 104.879 69.7166V69.7166C104.879 69.875 104.816 70.0268 104.704 70.1387C104.592 70.2507 104.44 70.3136 104.282 70.3136H67.7331Z"
				fill="white"
			/>
			<path
				d="M74.4862 38.2041C73.7819 38.2041 73.0845 38.0654 72.4338 37.7959C71.7831 37.5264 71.1919 37.1313 70.6939 36.6333C70.1959 36.1353 69.8008 35.544 69.5313 34.8934C69.2618 34.2427 69.123 33.5453 69.123 32.841V13.3749C69.123 12.6706 69.2618 11.9732 69.5313 11.3225C69.8008 10.6718 70.1959 10.0806 70.6939 9.58259C71.1919 9.08457 71.7831 8.68952 72.4338 8.42C73.0845 8.15047 73.7819 8.01175 74.4862 8.01175H93.9523C94.6581 8.00935 95.3575 8.1463 96.0103 8.41475C96.6631 8.68321 97.2564 9.07787 97.7564 9.57613C98.2563 10.0744 98.653 10.6664 98.9237 11.3183C99.1943 11.9702 99.3337 12.6691 99.3337 13.3749V13.3749V32.841C99.3337 34.2634 98.7686 35.6275 97.7628 36.6333C96.757 37.6391 95.3929 38.2041 93.9705 38.2041H74.4862V38.2041ZM70.3169 13.3749V32.841C70.3181 33.9468 70.7576 35.007 71.5391 35.7894C72.3206 36.5718 73.3804 37.0124 74.4862 37.0149H93.9523C95.0589 37.0136 96.1198 36.5735 96.9023 35.791C97.6848 35.0085 98.125 33.9476 98.1262 32.841V32.841V13.3749C98.1237 12.2691 97.6831 11.2093 96.9007 10.4278C96.1183 9.64631 95.0581 9.2068 93.9523 9.20559H74.4862C73.3812 9.208 72.3221 9.64804 71.5407 10.4294C70.7593 11.2108 70.3193 12.2699 70.3169 13.3749V13.3749Z"
				fill="white"
			/>
			<path
				d="M69.7161 33.0435C69.6378 33.0437 69.5603 33.0281 69.4882 32.9976C69.4162 32.967 69.3511 32.9222 69.2969 32.8658V32.8658C69.2404 32.8115 69.1956 32.7464 69.165 32.6744C69.1345 32.6023 69.1189 32.5248 69.1191 32.4465V32.4465V13.7643C69.1199 13.6073 69.1821 13.4568 69.2923 13.3451C69.3479 13.2895 69.414 13.2456 69.4868 13.2159C69.5595 13.1862 69.6375 13.1712 69.7161 13.1719H98.7146C98.7932 13.1712 98.8711 13.1862 98.9439 13.2159C99.0167 13.2456 99.0828 13.2895 99.1384 13.3451V13.3451C99.1942 13.3995 99.2383 13.4648 99.2681 13.5368C99.2979 13.6089 99.3127 13.6863 99.3115 13.7643V13.7643V32.4465C99.3127 32.5245 99.2979 32.6019 99.2681 32.674C99.2383 32.746 99.1942 32.8113 99.1384 32.8658V32.8658C99.083 32.9219 99.017 32.9665 98.9443 32.997C98.8715 33.0275 98.7935 33.0433 98.7146 33.0435H69.7161ZM70.313 31.8496H98.1086V14.3703H70.313V31.8496Z"
				fill="white"
			/>
			<path
				d="M83.623 32.4465V13.7642C83.6242 13.6067 83.6877 13.4561 83.7995 13.3451C83.9113 13.2341 84.0624 13.1719 84.22 13.1719V13.1719C84.3775 13.1719 84.5286 13.2341 84.6405 13.3451C84.7523 13.4561 84.8157 13.6067 84.8169 13.7642V13.7642V32.4465C84.8169 32.6048 84.754 32.7567 84.6421 32.8686C84.5301 32.9806 84.3783 33.0434 84.22 33.0434V33.0434C84.0617 33.0434 83.9098 32.9806 83.7979 32.8686C83.6859 32.7567 83.623 32.6048 83.623 32.4465V32.4465Z"
				fill="white"
			/>
			<path
				d="M113.008 38.2041C111.585 38.2041 110.221 37.6391 109.215 36.6333C108.21 35.6275 107.645 34.2633 107.645 32.8409V32.8409V13.3749C107.645 11.9525 108.21 10.5884 109.215 9.58256C110.221 8.57677 111.585 8.01172 113.008 8.01172H138.83C140.253 8.01172 141.617 8.57677 142.623 9.58256C143.628 10.5884 144.193 11.9525 144.193 13.3749V32.8409C144.193 34.2633 143.628 35.6275 142.623 36.6333C141.617 37.6391 140.253 38.2041 138.83 38.2041H113.008V38.2041ZM108.838 13.3749V32.8409C108.84 33.9468 109.279 35.007 110.061 35.7894C110.842 36.5717 111.902 37.0124 113.008 37.0148H138.83C139.937 37.0136 140.998 36.5735 141.78 35.791C142.563 35.0085 143.003 33.9475 143.004 32.8409V13.3749C143.002 12.2691 142.561 11.2093 141.779 10.4278C140.996 9.64627 139.936 9.20677 138.83 9.20556H113.008C111.903 9.20797 110.844 9.64801 110.062 10.4294C109.281 11.2108 108.841 12.2699 108.838 13.3749V13.3749Z"
				fill="white"
			/>
			<path
				d="M108.259 24.9605C108.101 24.9599 107.949 24.8978 107.835 24.7873V24.7873C107.725 24.6739 107.663 24.5219 107.662 24.3636V24.3636V13.1086C107.663 12.9503 107.725 12.7983 107.835 12.6849V12.6849C107.949 12.5744 108.101 12.5123 108.259 12.5117H143.614C143.773 12.5113 143.925 12.5736 144.038 12.6849V12.6849C144.148 12.7983 144.21 12.9503 144.211 13.1086V13.1086V24.3636C144.212 24.4422 144.197 24.5201 144.167 24.5929C144.137 24.6656 144.093 24.7317 144.038 24.7873V24.7873C143.924 24.8978 143.772 24.9599 143.614 24.9605H108.259ZM108.856 23.7667H143.031V13.7056H108.856V23.7667V23.7667Z"
				fill="white"
			/>
			<path
				d="M125.34 24.3636V13.1086C125.34 12.9503 125.403 12.7985 125.515 12.6866C125.627 12.5746 125.778 12.5117 125.937 12.5117V12.5117C126.094 12.5129 126.245 12.5763 126.356 12.6882C126.467 12.8 126.529 12.9511 126.529 13.1086V13.1086V24.3636C126.53 24.4418 126.515 24.5193 126.485 24.5917C126.456 24.6641 126.412 24.7299 126.357 24.7854C126.302 24.8409 126.237 24.8849 126.165 24.915C126.092 24.945 126.015 24.9605 125.937 24.9605V24.9605C125.858 24.9611 125.78 24.9461 125.708 24.9163C125.635 24.8865 125.569 24.8426 125.513 24.787C125.458 24.7315 125.414 24.6654 125.384 24.5927C125.354 24.52 125.339 24.4421 125.34 24.3636Z"
				fill="white"
			/>
			<path
				d="M188.309 32.8409V13.3749C188.31 11.9552 188.873 10.5938 189.876 9.58871C190.878 8.58357 192.238 8.01654 193.658 8.01172H219.494C220.917 8.01172 222.281 8.57677 223.287 9.58256C224.292 10.5884 224.858 11.9525 224.858 13.3749V13.3749V32.8409C224.858 34.2633 224.292 35.6275 223.287 36.6333C222.281 37.6391 220.917 38.2041 219.494 38.2041H193.658C192.954 38.2047 192.256 38.0664 191.605 37.7971C190.954 37.5278 190.363 37.1327 189.864 36.6346C189.366 36.1365 188.971 35.545 188.702 34.894C188.433 34.2431 188.294 33.5454 188.295 32.8409H188.309ZM193.658 9.20556C192.552 9.20677 191.492 9.64628 190.71 10.4278C189.927 11.2093 189.487 12.2691 189.484 13.3749V32.8409C189.485 33.9475 189.926 35.0085 190.708 35.791C191.491 36.5735 192.551 37.0136 193.658 37.0148H219.494C220.6 37.0124 221.66 36.5717 222.441 35.7894C223.223 35.007 223.662 33.9468 223.664 32.8409V13.3749C223.661 12.2699 223.221 11.2108 222.44 10.4294C221.658 9.64801 220.599 9.20797 219.494 9.20556H193.658V9.20556Z"
				fill="white"
			/>
			<path
				d="M188.9 24.9605C188.743 24.9597 188.592 24.8976 188.48 24.7873V24.7873C188.424 24.732 188.38 24.666 188.349 24.5932C188.319 24.5205 188.303 24.4425 188.303 24.3636V24.3636V13.1086C188.303 13.0298 188.319 12.9517 188.349 12.879C188.38 12.8062 188.424 12.7403 188.48 12.6849V12.6849C188.592 12.5747 188.743 12.5125 188.9 12.5117H224.259C224.416 12.5125 224.567 12.5747 224.679 12.6849V12.6849C224.735 12.7403 224.779 12.8062 224.81 12.879C224.84 12.9517 224.856 13.0298 224.856 13.1086V13.1086V24.3636C224.856 24.4425 224.84 24.5205 224.81 24.5932C224.779 24.666 224.735 24.732 224.679 24.7873V24.7873C224.567 24.8976 224.416 24.9597 224.259 24.9605H188.9V24.9605ZM189.497 23.7667H223.671V13.7056H189.497V23.7667Z"
				fill="white"
			/>
			<path
				d="M205.988 24.3636V13.1086C205.988 12.9511 206.051 12.8 206.162 12.6882C206.272 12.5763 206.423 12.5129 206.581 12.5117V12.5117C206.739 12.5117 206.891 12.5746 207.003 12.6866C207.115 12.7985 207.178 12.9503 207.178 13.1086V13.1086V24.3636C207.178 24.5219 207.115 24.6737 207.003 24.7857C206.891 24.8976 206.739 24.9605 206.581 24.9605V24.9605C206.502 24.9605 206.425 24.945 206.353 24.915C206.281 24.8849 206.215 24.8409 206.16 24.7854C206.105 24.7299 206.062 24.6641 206.032 24.5917C206.003 24.5193 205.988 24.4418 205.988 24.3636V24.3636Z"
				fill="white"
			/>
			<path
				d="M153.344 38.2041C151.922 38.2029 150.558 37.6375 149.553 36.632C148.547 35.6264 147.982 34.263 147.98 32.8409V32.8409V13.3749C147.982 11.9529 148.547 10.5894 149.553 9.58391C150.558 8.57838 151.922 8.01294 153.344 8.01174H179.166C179.872 8.00994 180.571 8.14733 181.223 8.41605C181.875 8.68477 182.468 9.07954 182.967 9.57773C183.467 10.0759 183.863 10.6678 184.134 11.3194C184.404 11.9709 184.543 12.6695 184.543 13.3749V13.3749V32.8409C184.541 34.265 183.973 35.6298 182.965 36.6355C181.956 37.6411 180.59 38.2053 179.166 38.2041H153.344ZM149.174 13.3749V32.8409C149.176 33.9468 149.615 35.007 150.397 35.7894C151.178 36.5718 152.238 37.0124 153.344 37.0148H179.166C180.273 37.0136 181.334 36.5735 182.116 35.791C182.899 35.0085 183.339 33.9476 183.34 32.8409V32.8409V13.3749C183.338 12.2691 182.897 11.2093 182.115 10.4278C181.332 9.64629 180.272 9.20678 179.166 9.20558H153.344C152.238 9.20679 151.178 9.64644 150.397 10.4281C149.615 11.2097 149.176 12.2695 149.174 13.3749Z"
				fill="white"
			/>
			<path
				d="M59.3899 38.6025C59.312 38.6031 59.2348 38.5881 59.1628 38.5584C59.0908 38.5287 59.0254 38.4848 58.9707 38.4294C58.9147 38.3749 58.8701 38.3097 58.8396 38.2377C58.8091 38.1657 58.7932 38.0884 58.793 38.0102V9.00708C58.7932 8.9289 58.8091 8.85155 58.8396 8.77957C58.8701 8.70758 58.9147 8.64241 58.9707 8.58787C59.0252 8.53185 59.0904 8.48729 59.1624 8.45677C59.2344 8.42626 59.3117 8.41041 59.3899 8.41016H60.602C62.024 8.41136 63.3874 8.9768 64.393 9.98233C65.3985 10.9879 65.9639 12.3513 65.9651 13.7733V33.2394C65.9651 34.6618 65.4001 36.0259 64.3943 37.0317C63.3885 38.0375 62.0244 38.6025 60.602 38.6025H59.4081H59.3899ZM59.9868 37.4133H60.602C61.7078 37.4109 62.7676 36.9702 63.5491 36.1878C64.3306 35.4055 64.7701 34.3452 64.7713 33.2394V33.2394V13.7733C64.7689 12.6683 64.3288 11.6092 63.5475 10.8278C62.7661 10.0464 61.707 9.60641 60.602 9.604H60.005V37.3996L59.9868 37.4133Z"
				fill="white"
			/>
			<path
				d="M59.3899 63.0973C59.3116 63.0975 59.2341 63.0819 59.1621 63.0514C59.09 63.0208 59.0249 62.976 58.9707 62.9196C58.9143 62.8653 58.8694 62.8002 58.8389 62.7282C58.8083 62.6561 58.7927 62.5786 58.793 62.5004V62.5004V51.7056C58.7932 51.6275 58.8091 51.5501 58.8396 51.4781C58.8701 51.4062 58.9147 51.341 58.9707 51.2864C59.0825 51.1762 59.2329 51.1141 59.3899 51.1133H60.2101C61.2365 51.1133 62.221 51.5207 62.9472 52.2461C63.6734 52.9715 64.082 53.9554 64.0832 54.9819V54.9819V59.2332C64.0808 60.2597 63.672 61.2435 62.9462 61.9693C62.2203 62.6952 61.2366 63.104 60.2101 63.1064H59.3899V63.0973ZM59.9868 61.9034H60.2101C60.9175 61.901 61.5953 61.6189 62.0956 61.1187C62.5958 60.6185 62.8779 59.9407 62.8803 59.2332V59.2332V54.991C62.8791 54.2808 62.5964 53.6 62.0942 53.0978C61.592 52.5956 60.9112 52.3129 60.201 52.3117H59.9777V61.9126L59.9868 61.9034Z"
				fill="white"
			/>
			<path
				d="M59.3899 49.258C59.3121 49.2581 59.235 49.2429 59.1631 49.2132C59.0911 49.1835 59.0258 49.1399 58.9707 49.0849V49.0849C58.9145 49.0295 58.8699 48.9635 58.8394 48.8908C58.8089 48.818 58.7931 48.74 58.793 48.6611V48.6611V42.136C58.7931 42.0571 58.8089 41.9791 58.8394 41.9063C58.8699 41.8336 58.9145 41.7676 58.9707 41.7122V41.7122C59.0825 41.602 59.2329 41.5399 59.3899 41.5391H60.0278C60.8597 41.5403 61.6571 41.8716 62.2449 42.4602C62.8327 43.0489 63.1628 43.8467 63.1628 44.6786V44.6786V46.1185C63.1628 46.9504 62.8327 47.7482 62.2449 48.3369C61.6571 48.9255 60.8597 49.2568 60.0278 49.258H59.3899ZM59.9868 48.0642H60.0278C60.5435 48.063 61.0377 47.8576 61.4023 47.493C61.7669 47.1284 61.9723 46.6342 61.9735 46.1185V44.6786C61.9723 44.1629 61.7669 43.6687 61.4023 43.3041C61.0377 42.9395 60.5435 42.7341 60.0278 42.7329H59.9868V48.0642V48.0642Z"
				fill="white"
			/>
			<path
				d="M49.0615 32.7731C48.9396 32.7735 48.8207 32.736 48.7211 32.6658C48.6215 32.5956 48.5462 32.4961 48.5056 32.3813L46.5189 27.0181L46.4824 26.813V17.4764C46.4824 17.4016 46.4963 17.3274 46.5234 17.2577L48.5101 12.3001C48.5538 12.1892 48.6296 12.0939 48.7279 12.0265C48.8262 11.9591 48.9423 11.9227 49.0615 11.9219H52.8572C52.9353 11.9224 53.0126 11.9383 53.0845 11.9688C53.1565 11.9993 53.2217 12.0438 53.2764 12.0996V12.0996C53.3318 12.1544 53.3756 12.2197 53.4054 12.2917C53.4351 12.3637 53.4501 12.4409 53.4495 12.5188V12.5188V32.1762C53.4507 32.2542 53.4359 32.3316 53.4061 32.4036C53.3763 32.4757 53.3322 32.541 53.2764 32.5954C53.2217 32.6512 53.1565 32.6957 53.0845 32.7262C53.0126 32.7567 52.9353 32.7726 52.8572 32.7731H49.0797H49.0615ZM47.6899 17.5949V26.7082L49.5126 31.5793H52.2785V13.1066H49.5035L47.7082 17.5904L47.6899 17.5949Z"
				fill="white"
			/>
			<path
				d="M49.0611 13.1111C48.9042 13.1103 48.7537 13.0482 48.6419 12.938V12.938C48.5864 12.8823 48.5425 12.8162 48.5128 12.7435C48.483 12.6707 48.4681 12.5928 48.4688 12.5142V12.5142V8.14437C48.4679 8.04163 48.4939 7.94045 48.5442 7.8509C48.5946 7.76136 48.6676 7.68658 48.7558 7.63402V7.63402C48.8451 7.58455 48.9454 7.55859 49.0475 7.55859C49.1495 7.55859 49.2499 7.58455 49.3391 7.63402V7.63402L53.112 9.62072L52.8386 10.1493L53.112 9.62072C53.208 9.67178 53.2883 9.74789 53.3445 9.84096C53.4006 9.93404 53.4305 10.0406 53.431 10.1493V10.1493V12.5324C53.4317 12.611 53.4167 12.689 53.387 12.7617C53.3573 12.8345 53.3133 12.9006 53.2578 12.9562V12.9562C53.1455 13.0656 52.9954 13.1276 52.8386 13.1293H49.0611V13.1111ZM49.6672 11.9218H52.2508V10.4819L49.6672 9.11493V11.9036V11.9218Z"
				fill="white"
			/>
			<path
				d="M52.5605 10.6561L48.783 8.65573C48.6804 8.59984 48.5961 8.51549 48.5403 8.41281C48.4845 8.31014 48.4596 8.19353 48.4686 8.07703C48.4784 7.96027 48.5225 7.84903 48.5955 7.75737C48.6685 7.66572 48.7671 7.59777 48.8787 7.56213L64.9682 2.39488C65.0577 2.36675 65.1525 2.35993 65.2451 2.37496C65.3376 2.39 65.4254 2.42647 65.5014 2.48146V2.48146C65.5776 2.53684 65.6396 2.60947 65.6824 2.69342C65.7251 2.77737 65.7474 2.87025 65.7474 2.96447V2.96447V7.54846C65.7488 7.6824 65.7043 7.81279 65.6215 7.91803C65.5386 8.02327 65.4223 8.09705 65.2917 8.12715V8.12715L52.9888 10.7108C52.9494 10.7155 52.9097 10.7155 52.8703 10.7108V10.7108C52.7735 10.7109 52.6781 10.6874 52.5924 10.6424L52.5605 10.6561ZM50.5783 8.27297L52.9113 9.50326L64.5445 7.0609V3.78011L50.5783 8.27297Z"
				fill="white"
			/>
			<path
				d="M103.892 46.0802C103.585 46.0802 103.29 45.9584 103.072 45.7415C102.855 45.5246 102.732 45.2302 102.73 44.9228V44.9228V43.6652C102.732 43.3578 102.855 43.0634 103.072 42.8465C103.29 42.6296 103.585 42.5078 103.892 42.5078H108.135C108.442 42.5078 108.737 42.6296 108.955 42.8465C109.172 43.0634 109.295 43.3578 109.297 43.6652V43.6652V44.9228C109.295 45.2302 109.172 45.5246 108.955 45.7415C108.737 45.9584 108.442 46.0802 108.135 46.0802H103.892V46.0802ZM108.107 44.8909V43.6971H103.924V44.8909H108.107Z"
				fill="white"
			/>
			<path
				d="M69.675 57.203C69.5971 57.2042 69.5197 57.1894 69.4476 57.1596C69.3755 57.1298 69.3103 57.0857 69.2558 57.0299V57.0299C69.1994 56.9757 69.1546 56.9105 69.124 56.8385C69.0935 56.7664 69.0779 56.6889 69.0781 56.6107V56.6107V43.1002C69.0806 42.9427 69.1444 42.7923 69.2558 42.681V42.681C69.3676 42.5708 69.5181 42.5086 69.675 42.5078H98.6782C98.756 42.5072 98.8333 42.5222 98.9053 42.552C98.9773 42.5817 99.0426 42.6256 99.0974 42.681V42.681C99.1532 42.7357 99.1976 42.8009 99.2281 42.8728C99.2586 42.9448 99.2746 43.022 99.2751 43.1002V43.1002V56.6107C99.2753 56.6889 99.2597 56.7664 99.2292 56.8385C99.1986 56.9105 99.1538 56.9757 99.0974 57.0299C99.0426 57.0853 98.9773 57.1291 98.9053 57.1589C98.8333 57.1886 98.756 57.2036 98.6782 57.203H69.675ZM70.272 56.0138H98.0676V43.7108H70.272V56.0138Z"
				fill="white"
			/>
			<path
				d="M69.675 46.8735C69.5167 46.8735 69.3649 46.8106 69.253 46.6987C69.141 46.5868 69.0781 46.4349 69.0781 46.2766V46.2766C69.0781 46.1183 69.141 45.9665 69.253 45.8545C69.3649 45.7426 69.5167 45.6797 69.675 45.6797H98.6781C98.8357 45.6809 98.9863 45.7443 99.0973 45.8561C99.2083 45.9679 99.2705 46.1191 99.2705 46.2766V46.2766C99.2705 46.4341 99.2083 46.5853 99.0973 46.6971C98.9863 46.8089 98.8357 46.8723 98.6781 46.8735H69.675Z"
				fill="white"
			/>
			<path
				d="M82.9309 49.787C82.8524 49.7876 82.7745 49.7725 82.7018 49.7428C82.6291 49.713 82.563 49.669 82.5075 49.6135C82.4519 49.5579 82.408 49.4919 82.3782 49.4192C82.3484 49.3465 82.3334 49.2686 82.334 49.19V49.19C82.334 49.1119 82.3495 49.0345 82.3795 48.9623C82.4096 48.8901 82.4536 48.8246 82.5091 48.7696C82.5646 48.7145 82.6304 48.6709 82.7028 48.6414C82.7752 48.6119 82.8527 48.5971 82.9309 48.5977H85.4462C85.6033 48.5977 85.754 48.6601 85.8651 48.7712C85.9762 48.8823 86.0386 49.0329 86.0386 49.19V49.19C86.0392 49.2682 86.0243 49.3457 85.9948 49.4181C85.9653 49.4905 85.9217 49.5564 85.8667 49.6119C85.8116 49.6673 85.7461 49.7114 85.6739 49.7414C85.6018 49.7715 85.5244 49.787 85.4462 49.787H82.9309Z"
				fill="white"
			/>
			<path
				d="M138.661 70.317C138.583 70.3168 138.504 70.301 138.432 70.2705C138.359 70.24 138.293 70.1954 138.238 70.1392V70.1392C138.126 70.0263 138.064 69.874 138.064 69.7155V56.2141C138.065 56.0557 138.127 55.9038 138.238 55.7903C138.351 55.679 138.503 55.6168 138.661 55.6172H158.966C159.123 55.618 159.273 55.6801 159.385 55.7903V55.7903C159.441 55.8457 159.486 55.9117 159.516 55.9844C159.547 56.0572 159.563 56.1352 159.563 56.2141V56.2141V69.7155C159.563 69.7937 159.547 69.8712 159.517 69.9433C159.486 70.0154 159.441 70.0805 159.385 70.1347V70.1347C159.331 70.1911 159.266 70.236 159.194 70.2665C159.122 70.2971 159.044 70.3127 158.966 70.3124H138.661V70.317ZM139.258 69.1186H158.369V56.8156H139.258V69.1186Z"
				fill="white"
			/>
			<path
				d="M138.661 59.9862C138.503 59.9862 138.351 59.9233 138.239 59.8113C138.127 59.6994 138.064 59.5476 138.064 59.3893V59.3893C138.064 59.3111 138.08 59.2337 138.11 59.1615C138.14 59.0893 138.184 59.0238 138.24 58.9688C138.295 58.9137 138.361 58.8702 138.433 58.8407C138.506 58.8112 138.583 58.7963 138.661 58.7969H158.966C159.123 58.7969 159.274 58.8592 159.386 58.9701C159.498 59.0811 159.562 59.2317 159.563 59.3893V59.3893C159.563 59.5476 159.5 59.6994 159.388 59.8113C159.276 59.9233 159.124 59.9862 158.966 59.9862H138.661Z"
				fill="white"
			/>
			<path
				d="M147.558 62.8807C147.479 62.8813 147.401 62.8663 147.329 62.8365C147.256 62.8067 147.19 62.7628 147.134 62.7072C147.079 62.6517 147.035 62.5856 147.005 62.5129C146.975 62.4402 146.96 62.3623 146.961 62.2838V62.2838C146.961 62.2056 146.976 62.1282 147.006 62.056C147.037 61.9839 147.081 61.9184 147.136 61.8633C147.192 61.8082 147.257 61.7647 147.33 61.7352C147.402 61.7057 147.48 61.6908 147.558 61.6914H150.073C150.231 61.6914 150.382 61.7537 150.494 61.8646C150.605 61.9756 150.669 62.1263 150.67 62.2838V62.2838C150.67 62.4421 150.607 62.5939 150.495 62.7059C150.383 62.8178 150.231 62.8807 150.073 62.8807H147.558Z"
				fill="white"
			/>
			<path
				d="M165.674 70.317C165.596 70.3167 165.519 70.3009 165.447 70.2703C165.375 70.2398 165.31 70.1953 165.255 70.1392V70.1392C165.145 70.0275 165.083 69.877 165.082 69.72V69.72V56.2141C165.083 56.0557 165.145 55.9038 165.255 55.7903V55.7903C165.367 55.6801 165.517 55.618 165.674 55.6172H185.979C186.137 55.6168 186.29 55.679 186.403 55.7903V55.7903C186.513 55.9038 186.575 56.0557 186.576 56.2141V56.2141V69.7155C186.577 69.7935 186.562 69.8708 186.532 69.9429C186.503 70.015 186.458 70.0802 186.403 70.1347V70.1347C186.347 70.1908 186.281 70.2355 186.209 70.266C186.136 70.2965 186.058 70.3122 185.979 70.3124H165.674V70.317ZM166.271 69.1231H185.409V56.8201H166.271V69.1231Z"
				fill="white"
			/>
			<path
				d="M165.674 59.9862C165.517 59.985 165.366 59.9216 165.255 59.8097C165.144 59.6979 165.082 59.5468 165.082 59.3893V59.3893C165.082 59.2322 165.144 59.0815 165.256 58.9704C165.367 58.8593 165.517 58.7969 165.674 58.7969H185.979C186.057 58.7963 186.135 58.8112 186.207 58.8407C186.279 58.8702 186.345 58.9137 186.401 58.9688C186.456 59.0238 186.5 59.0893 186.53 59.1615C186.56 59.2337 186.576 59.3111 186.576 59.3893V59.3893C186.576 59.5476 186.513 59.6994 186.401 59.8113C186.289 59.9233 186.137 59.9862 185.979 59.9862H165.674Z"
				fill="white"
			/>
			<path
				d="M174.569 62.8807C174.491 62.8807 174.413 62.8652 174.341 62.8352C174.269 62.8051 174.204 62.7611 174.148 62.7056C174.093 62.6501 174.05 62.5843 174.02 62.5119C173.991 62.4395 173.976 62.3619 173.977 62.2838C173.977 62.206 173.992 62.1289 174.022 62.0571C174.051 61.9852 174.095 61.9199 174.15 61.8649C174.205 61.8099 174.27 61.7663 174.342 61.7365C174.414 61.7067 174.491 61.6914 174.569 61.6914H177.089C177.167 61.6914 177.244 61.7067 177.315 61.7365C177.387 61.7663 177.453 61.8099 177.508 61.8649C177.563 61.9199 177.606 61.9852 177.636 62.0571C177.666 62.1289 177.681 62.206 177.681 62.2838V62.2838C177.682 62.3619 177.667 62.4395 177.637 62.5119C177.608 62.5843 177.564 62.6501 177.509 62.7056C177.454 62.7611 177.389 62.8051 177.317 62.8352C177.244 62.8652 177.167 62.8807 177.089 62.8807H174.569Z"
				fill="white"
			/>
			<path
				d="M192.691 70.317C192.612 70.3168 192.534 70.301 192.461 70.2705C192.388 70.24 192.322 70.1954 192.267 70.1392V70.1392C192.157 70.0275 192.095 69.877 192.094 69.72V69.72V56.2141C192.094 56.0557 192.156 55.9038 192.267 55.7903V55.7903C192.38 55.6799 192.532 55.6178 192.691 55.6172H212.995C213.153 55.6187 213.305 55.6807 213.419 55.7903V55.7903C213.529 55.9038 213.591 56.0557 213.592 56.2141V56.2141V69.7155C213.592 69.7933 213.577 69.8704 213.547 69.9423C213.517 70.0142 213.474 70.0796 213.419 70.1347V70.1347C213.363 70.1908 213.298 70.2355 213.225 70.266C213.152 70.2965 213.074 70.3122 212.995 70.3124H192.691V70.317ZM193.288 69.1186H212.398V56.8156H193.288V69.1186Z"
				fill="white"
			/>
			<path
				d="M192.691 59.9868C192.532 59.9868 192.381 59.9239 192.269 59.812C192.157 59.7 192.094 59.5482 192.094 59.3899V59.3899C192.094 59.2316 192.157 59.0798 192.269 58.9678C192.381 58.8559 192.532 58.793 192.691 58.793H212.995C213.074 58.793 213.151 58.8084 213.224 58.8384C213.296 58.8684 213.362 58.9124 213.417 58.9678C213.473 59.0232 213.517 59.089 213.547 59.1615C213.577 59.2339 213.592 59.3115 213.592 59.3899V59.3899C213.592 59.4683 213.577 59.5459 213.547 59.6183C213.517 59.6907 213.473 59.7566 213.417 59.812C213.362 59.8674 213.296 59.9114 213.224 59.9414C213.151 59.9714 213.074 59.9868 212.995 59.9868H192.691Z"
				fill="white"
			/>
			<path
				d="M201.585 62.8807C201.427 62.8807 201.275 62.8178 201.163 62.7059C201.051 62.5939 200.988 62.4421 200.988 62.2838V62.2838C200.989 62.1263 201.053 61.9756 201.165 61.8646C201.277 61.7537 201.428 61.6914 201.585 61.6914H204.1C204.258 61.6914 204.409 61.7537 204.521 61.8646C204.633 61.9756 204.696 62.1263 204.697 62.2838V62.2838C204.697 62.4421 204.635 62.5939 204.523 62.7059C204.411 62.8178 204.259 62.8807 204.1 62.8807H201.585Z"
				fill="white"
			/>
			<path
				d="M131.57 69.7169V65.8118C131.57 58.6396 125.601 52.8026 118.215 52.8026C110.828 52.8026 104.873 58.6396 104.859 65.8118V69.7169C104.859 69.8752 104.796 70.027 104.684 70.139C104.572 70.2509 104.421 70.3138 104.262 70.3138V70.3138C104.105 70.3126 103.954 70.2492 103.843 70.1374C103.732 70.0255 103.67 69.8744 103.67 69.7169V69.7169V65.8118C103.67 57.9561 110.19 51.627 118.215 51.6133C126.239 51.6133 132.76 57.9561 132.76 65.8118V69.7169C132.76 69.8744 132.697 70.0255 132.586 70.1374C132.475 70.2492 132.325 70.3126 132.167 70.3138V70.3138C132.009 70.3138 131.857 70.2509 131.745 70.139C131.633 70.027 131.57 69.8752 131.57 69.7169V69.7169Z"
				fill="white"
			/>
			<path
				d="M105.715 66.411C105.714 64.7677 106.037 63.1403 106.666 61.622C107.295 60.1036 108.216 58.724 109.378 57.562C110.54 56.3999 111.92 55.4783 113.438 54.8497C114.957 54.2211 116.584 53.8978 118.227 53.8984C119.871 53.8978 121.498 54.2211 123.016 54.8497C124.535 55.4783 125.914 56.3999 127.076 57.562C128.239 58.724 129.16 60.1036 129.789 61.622C130.417 63.1403 130.741 64.7677 130.74 66.411C130.741 68.0546 130.417 69.6821 129.789 71.2008C129.16 72.7194 128.239 74.0993 127.077 75.2617C125.915 76.4241 124.535 77.3461 123.017 77.9752C121.498 78.6043 119.871 78.9281 118.227 78.9281V78.9281C116.584 78.9281 114.956 78.6043 113.438 77.9752C111.92 77.3461 110.54 76.4241 109.378 75.2617C108.216 74.0993 107.295 72.7194 106.666 71.2008C106.037 69.6821 105.714 68.0546 105.715 66.411V66.411ZM106.904 66.411C106.909 69.4127 108.103 72.29 110.226 74.4125C112.348 76.5349 115.226 77.7295 118.227 77.7343V77.7343C121.229 77.7295 124.106 76.5349 126.229 74.4125C128.351 72.29 129.546 69.4127 129.551 66.411V66.411C129.546 63.4098 128.351 60.5329 126.229 58.4112C124.106 56.2894 121.229 55.0959 118.227 55.0923V55.0923C115.226 55.0959 112.349 56.2894 110.226 58.4112C108.104 60.5329 106.909 63.4098 106.904 66.411V66.411Z"
				fill="white"
			/>
			<path
				d="M106.693 66.4138C106.693 63.3587 107.907 60.4287 110.067 58.2684C112.228 56.1082 115.157 54.8945 118.213 54.8945V54.8945C121.268 54.8945 124.198 56.1082 126.358 58.2684C128.518 60.4287 129.732 63.3587 129.732 66.4138V66.4138C129.729 69.4673 128.515 72.3949 126.355 74.5532C124.195 76.7115 121.266 77.9239 118.213 77.9239V77.9239C116.699 77.9239 115.201 77.6258 113.803 77.0466C112.405 76.4674 111.135 75.6185 110.066 74.5483C108.996 73.4782 108.148 72.2078 107.569 70.8097C106.99 69.4116 106.693 67.9132 106.693 66.4001V66.4138ZM107.883 66.4138C107.886 69.1523 108.976 71.7777 110.912 73.7141C112.849 75.6506 115.474 76.7401 118.213 76.7437V76.7437C120.951 76.7401 123.577 75.6506 125.513 73.7141C127.449 71.7777 128.539 69.1523 128.543 66.4138V66.4138C128.539 63.6752 127.449 61.0498 125.513 59.1134C123.577 57.1769 120.951 56.0874 118.213 56.0838V56.0838C115.474 56.0886 112.85 57.1785 110.914 59.1147C108.977 61.0509 107.887 63.6756 107.883 66.4138V66.4138Z"
				fill="white"
			/>
			<path
				d="M111.641 66.4079C111.641 65.5471 111.81 64.6946 112.14 63.8994C112.469 63.1041 112.952 62.3815 113.561 61.773C114.17 61.1645 114.893 60.682 115.689 60.3529C116.484 60.0239 117.337 59.8549 118.198 59.8555V59.8555C119.936 59.8567 121.602 60.5473 122.831 61.7757C124.061 63.0041 124.752 64.6701 124.755 66.4079V66.4079C124.753 68.1466 124.062 69.8137 122.833 71.0431C121.603 72.2725 119.936 72.9638 118.198 72.965V72.965C116.459 72.965 114.791 72.2741 113.561 71.0445C112.331 69.8148 111.641 68.147 111.641 66.4079ZM112.834 66.4079C112.836 67.83 113.401 69.1934 114.407 70.199C115.412 71.2045 116.776 71.7699 118.198 71.7711C119.62 71.7699 120.983 71.2045 121.989 70.199C122.994 69.1934 123.56 67.83 123.561 66.4079V66.4079C123.556 64.9914 122.992 63.6342 121.99 62.6322C120.989 61.6301 119.632 61.0644 118.216 61.0584V61.0584C116.794 61.0596 115.43 61.6251 114.425 62.6306C113.419 63.6361 112.854 64.9996 112.853 66.4216L112.834 66.4079Z"
				fill="white"
			/>
			<path
				d="M117.02 66.408C117.022 66.0926 117.149 65.7908 117.372 65.5681C117.596 65.3455 117.898 65.2199 118.213 65.2188V65.2188C118.37 65.2188 118.524 65.2495 118.668 65.3093C118.813 65.369 118.944 65.4566 119.054 65.5671C119.165 65.6775 119.252 65.8086 119.312 65.9529C119.372 66.0972 119.403 66.2519 119.403 66.408V66.408C119.403 66.5646 119.373 66.7197 119.313 66.8646C119.254 67.0094 119.166 67.141 119.056 67.2519C118.945 67.3629 118.814 67.4509 118.67 67.5109C118.525 67.571 118.37 67.6019 118.213 67.6019C117.897 67.6007 117.594 67.4745 117.371 67.2509C117.147 67.0273 117.021 66.7243 117.02 66.408V66.408Z"
				fill="white"
			/>
			<path
				d="M117.42 63.0858C117.42 62.8756 117.503 62.6739 117.652 62.5252C117.801 62.3765 118.002 62.293 118.213 62.293V62.293C118.423 62.293 118.625 62.3765 118.773 62.5252C118.922 62.6739 119.006 62.8756 119.006 63.0858V63.0858C119.006 63.2965 118.922 63.4987 118.774 63.6481C118.625 63.7975 118.423 63.882 118.213 63.8832C118.002 63.882 117.8 63.7975 117.652 63.6481C117.503 63.4987 117.42 63.2965 117.42 63.0858Z"
				fill="white"
			/>
			<path
				d="M117.42 69.7186C117.42 69.6145 117.44 69.5114 117.48 69.4152C117.52 69.319 117.579 69.2316 117.652 69.158C117.726 69.0844 117.813 69.026 117.909 68.9861C118.006 68.9463 118.109 68.9258 118.213 68.9258V68.9258C118.317 68.9258 118.42 68.9463 118.516 68.9861C118.612 69.026 118.7 69.0844 118.773 69.158C118.847 69.2316 118.905 69.319 118.945 69.4152C118.985 69.5114 119.006 69.6145 119.006 69.7186V69.7186C119.006 69.8231 118.986 69.9267 118.947 70.0235C118.907 70.1202 118.849 70.2081 118.775 70.2822C118.701 70.3563 118.614 70.4151 118.517 70.4553C118.421 70.4954 118.317 70.5161 118.213 70.5161V70.5161C118.108 70.5161 118.005 70.4954 117.908 70.4553C117.812 70.4151 117.724 70.3563 117.651 70.2822C117.577 70.2081 117.519 70.1202 117.479 70.0235C117.439 69.9267 117.419 69.8231 117.42 69.7186V69.7186Z"
				fill="white"
			/>
			<path
				d="M114.094 66.4062C114.093 66.3019 114.113 66.1985 114.153 66.102C114.193 66.0056 114.251 65.9179 114.325 65.8442C114.398 65.7704 114.486 65.712 114.582 65.6724C114.679 65.6328 114.782 65.6127 114.887 65.6133V65.6133C115.097 65.6133 115.299 65.6968 115.447 65.8455C115.596 65.9942 115.679 66.1959 115.679 66.4062V66.4062C115.68 66.5107 115.66 66.6142 115.62 66.711C115.581 66.8077 115.523 66.8956 115.449 66.9697C115.375 67.0438 115.288 67.1027 115.191 67.1428C115.095 67.1829 114.991 67.2036 114.887 67.2036V67.2036C114.782 67.2036 114.679 67.1829 114.582 67.1428C114.486 67.1027 114.398 67.0438 114.324 66.9697C114.251 66.8956 114.192 66.8077 114.153 66.711C114.113 66.6142 114.093 66.5107 114.094 66.4062V66.4062ZM115.283 66.4062H114.887H115.283Z"
				fill="white"
			/>
			<path
				d="M120.75 66.4062C120.75 66.1959 120.834 65.9942 120.982 65.8455C121.131 65.6968 121.333 65.6133 121.543 65.6133C121.647 65.6127 121.751 65.6328 121.848 65.6723C121.944 65.7119 122.032 65.7702 122.106 65.8439C122.181 65.9176 122.239 66.0052 122.279 66.1017C122.32 66.1982 122.34 66.3017 122.34 66.4062V66.4062C122.34 66.6176 122.256 66.8205 122.107 66.97C121.957 67.1196 121.754 67.2036 121.543 67.2036C121.438 67.2036 121.335 67.1829 121.238 67.1428C121.142 67.1027 121.054 67.0438 120.981 66.9697C120.907 66.8956 120.849 66.8077 120.809 66.711C120.769 66.6142 120.749 66.5107 120.75 66.4062V66.4062ZM121.543 66.8071V66.4062V66.8071Z"
				fill="white"
			/>
			<path
				d="M115.862 69.5558C115.757 69.5561 115.653 69.5357 115.556 69.4958C115.459 69.456 115.371 69.3974 115.297 69.3234V69.3234C115.149 69.1739 115.066 68.9729 115.064 68.7629V68.7629C115.065 68.656 115.086 68.5502 115.128 68.4516C115.169 68.353 115.23 68.2637 115.306 68.1888V68.1888C115.454 68.0424 115.654 67.9605 115.862 67.9609V67.9609C115.966 67.9616 116.07 67.9828 116.166 68.0235C116.262 68.0642 116.349 68.1234 116.422 68.1979V68.1979C116.497 68.271 116.555 68.3583 116.595 68.4546C116.635 68.5508 116.655 68.6541 116.655 68.7584C116.652 68.9681 116.569 69.1688 116.422 69.3188V69.3188C116.274 69.4675 116.072 69.5511 115.862 69.5512V69.5558ZM115.579 69.0409L115.857 68.7629L115.579 69.0409ZM115.862 68.7629L115.721 68.6262L115.794 68.7037L115.57 68.4941L115.853 68.7629H115.862ZM115.798 68.6991L115.866 68.7584L115.807 68.6991H115.798Z"
				fill="white"
			/>
			<path
				d="M120.564 64.8475C120.354 64.8475 120.152 64.7639 120.004 64.6152V64.6152C119.855 64.4665 119.772 64.2649 119.771 64.0547C119.771 63.9505 119.792 63.8474 119.832 63.7512C119.871 63.655 119.93 63.5677 120.004 63.4942V63.4942C120.077 63.4202 120.164 63.3615 120.261 63.3216C120.357 63.2817 120.46 63.2614 120.564 63.2618V63.2618C120.669 63.2601 120.772 63.2798 120.869 63.3197C120.965 63.3597 121.052 63.4191 121.125 63.4942V63.4942C121.199 63.5674 121.259 63.6546 121.299 63.7508C121.34 63.847 121.361 63.9503 121.362 64.0547V64.0547C121.359 64.2667 121.274 64.4693 121.125 64.6197C120.977 64.7641 120.78 64.8458 120.573 64.8475V64.8475H120.564ZM120.961 64.0547H120.569L120.842 63.7676L120.564 64.0501L120.432 63.918L120.496 63.9909L120.295 63.7904L120.569 64.0547H120.97H120.961ZM120.56 64.0547L120.501 63.9955L120.569 64.0547H120.56Z"
				fill="white"
			/>
			<path
				d="M120.564 69.5551C120.46 69.5553 120.357 69.5349 120.261 69.495C120.165 69.4551 120.077 69.3966 120.004 69.3227V69.3227L120.286 69.0402L120.004 69.3227C119.855 69.1741 119.772 68.9725 119.771 68.7623V68.7623C119.771 68.6574 119.792 68.5536 119.831 68.4566C119.871 68.3597 119.93 68.2715 120.004 68.1972V68.1972C120.153 68.0489 120.354 67.9654 120.564 67.9648V67.9648C120.776 67.9657 120.979 68.0491 121.129 68.1972C121.277 68.3484 121.36 68.5509 121.362 68.7623C121.359 68.972 121.276 69.1727 121.129 69.3227V69.3227C120.982 69.4703 120.782 69.5538 120.573 69.5551V69.5551H120.564ZM120.564 68.7623L120.847 68.4797L120.564 68.7623ZM120.564 68.7623L120.282 68.4889L120.564 68.7623ZM120.564 68.7623L120.847 68.4843L120.564 68.7623Z"
				fill="white"
			/>
			<path
				d="M115.862 64.8475C115.758 64.8482 115.654 64.828 115.558 64.7881C115.462 64.7482 115.375 64.6894 115.301 64.6151V64.6151L115.579 64.3372L115.297 64.6151C115.148 64.4665 115.065 64.2649 115.064 64.0547V64.0547C115.064 63.9505 115.085 63.8474 115.125 63.7512C115.164 63.655 115.223 63.5676 115.297 63.4942V63.4942C115.371 63.4194 115.459 63.3602 115.556 63.3203C115.653 63.2804 115.757 63.2605 115.862 63.2618V63.2618C115.966 63.2603 116.07 63.2801 116.166 63.3201C116.262 63.3601 116.35 63.4193 116.422 63.4942V63.4942C116.571 63.6428 116.655 63.8444 116.655 64.0547C116.652 64.2658 116.569 64.468 116.422 64.6197V64.6197C116.272 64.7659 116.071 64.8477 115.862 64.8475V64.8475ZM115.862 64.0547L116.14 63.7721L115.862 64.0547ZM115.862 64.0547L115.57 63.7813L115.853 64.0547H115.862Z"
				fill="white"
			/>
			<path
				d="M62.0279 9.20556C61.8704 9.20436 61.7197 9.14094 61.6088 9.02912C61.4978 8.91731 61.4355 8.76617 61.4355 8.60864V8.60864C61.4355 8.45111 61.4978 8.29997 61.6088 8.18816C61.7197 8.07634 61.8704 8.01292 62.0279 8.01172H292.595C292.753 8.01172 292.905 8.07461 293.017 8.18655C293.129 8.2985 293.192 8.45033 293.192 8.60864C293.192 8.76695 293.129 8.91878 293.017 9.03073C292.905 9.14267 292.753 9.20556 292.595 9.20556H62.0279Z"
				fill="white"
			/>
			<path
				d="M69.7161 17.4823C69.5577 17.4823 69.4059 17.4194 69.294 17.3074C69.182 17.1955 69.1191 17.0436 69.1191 16.8853V16.8853C69.1203 16.7278 69.1838 16.5771 69.2956 16.4662C69.4074 16.3552 69.5585 16.293 69.7161 16.293H73.0925C73.2501 16.293 73.4012 16.3552 73.513 16.4662C73.6248 16.5771 73.6883 16.7278 73.6895 16.8853C73.6895 17.0436 73.6266 17.1955 73.5146 17.3074C73.4027 17.4194 73.2509 17.4823 73.0925 17.4823H69.7161Z"
				fill="white"
			/>
			<path
				d="M69.7161 19.7596C69.5585 19.7596 69.4074 19.6973 69.2956 19.5864C69.1838 19.4754 69.1203 19.3248 69.1191 19.1672V19.1672C69.1191 19.0089 69.182 18.8571 69.294 18.7451C69.4059 18.6332 69.5577 18.5703 69.7161 18.5703H76.469C76.6273 18.5703 76.7792 18.6332 76.8911 18.7451C77.0031 18.8571 77.0659 19.0089 77.0659 19.1672V19.1672C77.0647 19.3248 77.0013 19.4754 76.8895 19.5864C76.7777 19.6973 76.6266 19.7596 76.469 19.7596H69.7161Z"
				fill="white"
			/>
			<path
				d="M79.0559 19.7596C78.8984 19.7596 78.7472 19.6974 78.6354 19.5864C78.5236 19.4754 78.4602 19.3248 78.459 19.1673V19.1673C78.459 19.0089 78.5219 18.8571 78.6338 18.7452C78.7458 18.6332 78.8976 18.5703 79.0559 18.5703H80.0493C80.128 18.5697 80.2061 18.5847 80.2791 18.6145C80.352 18.6442 80.4184 18.6881 80.4743 18.7435C80.5302 18.799 80.5746 18.865 80.6049 18.9378C80.6351 19.0105 80.6507 19.0885 80.6507 19.1673V19.1673C80.6495 19.3248 80.5861 19.4754 80.4743 19.5864C80.3625 19.6974 80.2113 19.7596 80.0538 19.7596H79.0559Z"
				fill="white"
			/>
			<path
				d="M206.579 18.0018C206.422 18.0018 206.271 17.9395 206.159 17.8286C206.047 17.7176 205.984 17.5669 205.982 17.4094V17.4094C205.982 17.2511 206.045 17.0993 206.157 16.9873C206.269 16.8754 206.421 16.8125 206.579 16.8125H209.956C210.114 16.8125 210.266 16.8754 210.378 16.9873C210.49 17.0993 210.553 17.2511 210.553 17.4094V17.4094C210.552 17.5669 210.488 17.7176 210.376 17.8286C210.264 17.9395 210.113 18.0018 209.956 18.0018H206.579Z"
				fill="white"
			/>
			<path
				d="M206.581 20.2993C206.423 20.2981 206.272 20.2347 206.162 20.1229C206.051 20.0111 205.988 19.8599 205.988 19.7024V19.7024C205.988 19.5449 206.051 19.3937 206.162 19.2819C206.272 19.1701 206.423 19.1067 206.581 19.1055H213.334C213.492 19.1055 213.644 19.1684 213.756 19.2803C213.868 19.3922 213.931 19.5441 213.931 19.7024V19.7024C213.931 19.7808 213.915 19.8584 213.885 19.9308C213.855 20.0032 213.811 20.069 213.756 20.1245C213.7 20.1799 213.634 20.2239 213.562 20.2539C213.49 20.2839 213.412 20.2993 213.334 20.2993H206.581Z"
				fill="white"
			/>
			<path
				d="M162.673 27.9126C162.515 27.9126 162.363 27.8497 162.251 27.7378C162.139 27.6258 162.076 27.474 162.076 27.3157V27.3157C162.076 27.1574 162.139 27.0055 162.251 26.8936C162.363 26.7816 162.515 26.7188 162.673 26.7188H167.685C167.844 26.7188 167.996 26.7816 168.107 26.8936C168.219 27.0055 168.282 27.1574 168.282 27.3157V27.3157C168.282 27.474 168.219 27.6258 168.107 27.7378C167.996 27.8497 167.844 27.9126 167.685 27.9126H162.673Z"
				fill="white"
			/>
			<path
				d="M162.672 30.1906C162.519 30.1884 162.373 30.1276 162.264 30.0208C162.154 29.9139 162.09 29.7692 162.084 29.6164V29.6164C162.081 29.5369 162.094 29.4576 162.122 29.3831C162.15 29.3087 162.193 29.2405 162.248 29.1828C162.303 29.1251 162.368 29.0789 162.441 29.0469C162.514 29.015 162.593 28.9979 162.672 28.9967H172.697C172.779 28.9929 172.862 29.0062 172.939 29.036C173.016 29.0657 173.087 29.1111 173.145 29.1694C173.204 29.2277 173.25 29.2975 173.28 29.3744C173.31 29.4514 173.324 29.5338 173.321 29.6164V29.6164C173.321 29.7748 173.258 29.9266 173.146 30.0385C173.034 30.1505 172.883 30.2134 172.724 30.2134H162.672V30.1906Z"
				fill="white"
			/>
			<path
				d="M176.566 30.1938C176.411 30.194 176.263 30.1342 176.151 30.0271C176.04 29.9201 175.975 29.774 175.969 29.6197V29.6197C175.97 29.4622 176.033 29.3115 176.145 29.2006C176.257 29.0896 176.408 29.0273 176.566 29.0273H178.042C178.197 29.0309 178.344 29.0949 178.452 29.2056C178.56 29.3163 178.621 29.4649 178.621 29.6197V29.6197C178.621 29.778 178.558 29.9299 178.446 30.0418C178.334 30.1537 178.182 30.2166 178.024 30.2166H176.547L176.566 30.1938Z"
				fill="white"
			/>
			<path
				d="M108.259 29.3026C108.102 29.3026 107.95 29.2403 107.839 29.1293C107.727 29.0184 107.663 28.8677 107.662 28.7102V28.7102C107.662 28.5519 107.725 28.4001 107.837 28.2881C107.949 28.1762 108.101 28.1133 108.259 28.1133H113.973C114.131 28.1133 114.283 28.1762 114.395 28.2881C114.507 28.4001 114.57 28.5519 114.57 28.7102V28.7102C114.57 28.8685 114.507 29.0203 114.395 29.1323C114.283 29.2442 114.131 29.3071 113.973 29.3071H108.259V29.3026Z"
				fill="white"
			/>
			<path
				d="M108.259 31.5801C108.101 31.5801 107.949 31.5172 107.837 31.4052C107.725 31.2933 107.662 31.1415 107.662 30.9831V30.9831C107.663 30.8256 107.727 30.675 107.839 30.564C107.95 30.453 108.102 30.3908 108.259 30.3908H119.687C119.766 30.389 119.845 30.4029 119.919 30.4318C119.992 30.4607 120.06 30.504 120.116 30.5591C120.173 30.6142 120.219 30.6801 120.25 30.7529C120.281 30.8257 120.297 30.904 120.298 30.9831C120.298 31.1415 120.235 31.2933 120.123 31.4052C120.011 31.5172 119.859 31.5801 119.701 31.5801H108.259Z"
				fill="white"
			/>
			<path
				d="M124.054 31.5799C123.896 31.5799 123.744 31.517 123.632 31.4051C123.52 31.2931 123.457 31.1413 123.457 30.983C123.458 30.8255 123.522 30.6748 123.633 30.5638C123.745 30.4529 123.896 30.3906 124.054 30.3906H125.735C125.893 30.3906 126.044 30.4529 126.156 30.5638C126.268 30.6748 126.331 30.8255 126.332 30.983V30.983C126.332 31.1413 126.269 31.2931 126.157 31.4051C126.045 31.517 125.894 31.5799 125.735 31.5799H124.054Z"
				fill="white"
			/>
			<path
				d="M148.577 20.2993C148.499 20.2993 148.421 20.2839 148.349 20.2539C148.277 20.2239 148.211 20.1799 148.155 20.1245C148.1 20.069 148.056 20.0032 148.026 19.9308C147.996 19.8584 147.98 19.7808 147.98 19.7024V19.7024C147.98 19.5441 148.043 19.3922 148.155 19.2803C148.267 19.1684 148.419 19.1055 148.577 19.1055H154.291C154.45 19.1055 154.602 19.1684 154.714 19.2803C154.825 19.3922 154.888 19.5441 154.888 19.7024V19.7024C154.888 19.7808 154.873 19.8584 154.843 19.9308C154.813 20.0032 154.769 20.069 154.714 20.1245C154.658 20.1799 154.592 20.2239 154.52 20.2539C154.447 20.2839 154.37 20.2993 154.291 20.2993H148.577Z"
				fill="white"
			/>
			<path
				d="M148.577 18.0018C148.42 18.0018 148.269 17.9395 148.157 17.8286C148.045 17.7176 147.982 17.567 147.98 17.4094V17.4094C147.98 17.2511 148.043 17.0993 148.155 16.9874C148.267 16.8754 148.419 16.8125 148.577 16.8125H160.005C160.084 16.8119 160.162 16.8269 160.235 16.8567C160.307 16.8865 160.373 16.9304 160.429 16.986C160.484 17.0416 160.528 17.1076 160.558 17.1803C160.588 17.253 160.603 17.3309 160.602 17.4094V17.4094C160.602 17.4876 160.587 17.565 160.557 17.6372C160.527 17.7094 160.483 17.7749 160.427 17.8299C160.372 17.885 160.306 17.9285 160.234 17.958C160.161 17.9875 160.084 18.0024 160.005 18.0018H148.577Z"
				fill="white"
			/>
			<path
				d="M164.379 18.0018C164.222 18.0018 164.072 17.9394 163.961 17.8283C163.85 17.7172 163.787 17.5665 163.787 17.4094V17.4094C163.787 17.3313 163.801 17.2537 163.831 17.1814C163.86 17.109 163.904 17.0431 163.959 16.9876C164.014 16.9321 164.08 16.8881 164.152 16.858C164.224 16.828 164.301 16.8125 164.379 16.8125H166.061C166.139 16.8119 166.217 16.8269 166.29 16.8567C166.363 16.8865 166.429 16.9304 166.484 16.986C166.54 17.0416 166.584 17.1076 166.614 17.1803C166.643 17.253 166.658 17.3309 166.658 17.4094C166.658 17.4876 166.642 17.565 166.612 17.6372C166.582 17.7094 166.538 17.7749 166.483 17.8299C166.427 17.885 166.361 17.9285 166.289 17.958C166.217 17.9875 166.139 18.0024 166.061 18.0018H164.379Z"
				fill="white"
			/>
			<path
				d="M188.901 31.5799C188.823 31.5799 188.745 31.5644 188.673 31.5344C188.601 31.5043 188.536 31.4603 188.48 31.4048C188.425 31.3493 188.382 31.2835 188.352 31.2111C188.323 31.1387 188.308 31.0612 188.309 30.983C188.309 30.8259 188.371 30.6752 188.482 30.5641C188.593 30.453 188.744 30.3906 188.901 30.3906H194.62C194.697 30.3906 194.774 30.4059 194.846 30.4357C194.918 30.4655 194.983 30.5091 195.038 30.5641C195.093 30.6191 195.137 30.6844 195.167 30.7563C195.197 30.8282 195.212 30.9052 195.212 30.983C195.213 31.0612 195.198 31.1387 195.168 31.2111C195.139 31.2835 195.095 31.3493 195.04 31.4048C194.985 31.4603 194.919 31.5043 194.847 31.5344C194.775 31.5644 194.698 31.5799 194.62 31.5799H188.901Z"
				fill="white"
			/>
			<path
				d="M188.901 29.2997C188.743 29.2985 188.593 29.2351 188.482 29.1233C188.371 29.0115 188.309 28.8604 188.309 28.7028V28.7028C188.309 28.5453 188.371 28.3942 188.482 28.2823C188.593 28.1705 188.743 28.1071 188.901 28.1059H200.329C200.41 28.1028 200.49 28.1161 200.566 28.145C200.641 28.1739 200.71 28.2178 200.768 28.2741C200.826 28.3303 200.871 28.3977 200.903 28.4722C200.934 28.5466 200.949 28.6267 200.949 28.7074V28.7074C200.949 28.8657 200.886 29.0175 200.774 29.1295C200.662 29.2414 200.51 29.3043 200.352 29.3043H188.901V29.2997Z"
				fill="white"
			/>
			<path
				d="M204.702 29.3026C204.545 29.3026 204.394 29.2403 204.282 29.1293C204.17 29.0184 204.107 28.8677 204.105 28.7102V28.7102C204.105 28.5519 204.168 28.4001 204.28 28.2881C204.392 28.1762 204.544 28.1133 204.702 28.1133H206.384C206.541 28.1145 206.692 28.1779 206.803 28.2897C206.914 28.4015 206.976 28.5527 206.976 28.7102V28.7102C206.976 28.8677 206.914 29.0189 206.803 29.1307C206.692 29.2425 206.541 29.3059 206.384 29.3071H204.702V29.3026Z"
				fill="white"
			/>
			<path
				d="M84.22 29.2017C84.0617 29.2017 83.9098 29.1388 83.7979 29.0268C83.6859 28.9149 83.623 28.763 83.623 28.6047V28.6047C83.623 28.4464 83.6859 28.2946 83.7979 28.1826C83.9098 28.0707 84.0617 28.0078 84.22 28.0078H87.5965C87.7548 28.0078 87.9066 28.0707 88.0185 28.1826C88.1305 28.2946 88.1934 28.4464 88.1934 28.6047V28.6047C88.1934 28.763 88.1305 28.9149 88.0185 29.0268C87.9066 29.1388 87.7548 29.2017 87.5965 29.2017H84.22Z"
				fill="white"
			/>
			<path
				d="M84.22 26.9207C84.0617 26.9207 83.9098 26.8578 83.7979 26.7458C83.6859 26.6339 83.623 26.4821 83.623 26.3238V26.3238C83.623 26.1654 83.6859 26.0136 83.7979 25.9017C83.9098 25.7897 84.0617 25.7268 84.22 25.7268H90.9729C91.0528 25.7244 91.1324 25.738 91.207 25.7669C91.2815 25.7958 91.3495 25.8394 91.4068 25.8951C91.4642 25.9508 91.5098 26.0174 91.541 26.091C91.5721 26.1647 91.5881 26.2438 91.5881 26.3238V26.3238C91.5881 26.4821 91.5252 26.6339 91.4132 26.7458C91.3013 26.8578 91.1495 26.9207 90.9912 26.9207H84.22Z"
				fill="white"
			/>
			<path
				d="M93.5566 26.9204C93.4778 26.921 93.3997 26.906 93.3267 26.8763C93.2538 26.8466 93.1874 26.8027 93.1315 26.7472C93.0756 26.6917 93.0312 26.6257 93.0009 26.553C92.9707 26.4803 92.9551 26.4023 92.9551 26.3235V26.3235C92.9551 26.1652 93.018 26.0133 93.1299 25.9014C93.2419 25.7895 93.3937 25.7266 93.552 25.7266H94.5454C94.7037 25.7266 94.8555 25.7895 94.9674 25.9014C95.0794 26.0133 95.1423 26.1652 95.1423 26.3235V26.3235C95.1423 26.4818 95.0794 26.6336 94.9674 26.7456C94.8555 26.8575 94.7037 26.9204 94.5454 26.9204H93.552H93.5566Z"
				fill="white"
			/>
			<path
				d="M125.937 20.2993C125.858 20.2993 125.781 20.2839 125.708 20.2539C125.636 20.2239 125.57 20.1799 125.515 20.1245C125.459 20.069 125.415 20.0032 125.385 19.9308C125.355 19.8584 125.34 19.7808 125.34 19.7024V19.7024C125.34 19.5441 125.403 19.3922 125.515 19.2803C125.627 19.1684 125.778 19.1055 125.937 19.1055H129.313C129.471 19.1067 129.621 19.1701 129.732 19.2819C129.843 19.3937 129.906 19.5449 129.906 19.7024V19.7024C129.906 19.8599 129.843 20.0111 129.732 20.1229C129.621 20.2347 129.471 20.2981 129.313 20.2993H125.937Z"
				fill="white"
			/>
			<path
				d="M125.937 18.0018C125.859 18.0024 125.781 17.9875 125.709 17.958C125.636 17.9285 125.57 17.885 125.515 17.8299C125.459 17.7749 125.415 17.7094 125.385 17.6372C125.355 17.565 125.34 17.4876 125.34 17.4094V17.4094C125.339 17.3309 125.354 17.253 125.384 17.1803C125.414 17.1076 125.458 17.0416 125.513 16.986C125.569 16.9304 125.635 16.8865 125.708 16.8567C125.78 16.8269 125.858 16.8119 125.937 16.8125H132.69C132.848 16.8125 133 16.8754 133.112 16.9874C133.224 17.0993 133.287 17.2511 133.287 17.4094V17.4094C133.285 17.567 133.222 17.7176 133.11 17.8286C132.998 17.9395 132.847 18.0018 132.69 18.0018H125.937Z"
				fill="white"
			/>
			<path
				d="M135.268 18.0018C135.111 18.0018 134.96 17.9394 134.849 17.8283C134.738 17.7172 134.676 17.5665 134.676 17.4094V17.4094C134.675 17.3313 134.69 17.2537 134.72 17.1814C134.749 17.109 134.793 17.0431 134.848 16.9876C134.903 16.9321 134.968 16.8881 135.04 16.858C135.113 16.828 135.19 16.8125 135.268 16.8125H136.243C136.322 16.8119 136.4 16.8269 136.472 16.8567C136.545 16.8865 136.611 16.9304 136.667 16.986C136.722 17.0416 136.766 17.1076 136.796 17.1803C136.826 17.253 136.841 17.3309 136.84 17.4094V17.4094C136.84 17.4876 136.825 17.565 136.795 17.6372C136.765 17.7094 136.721 17.7749 136.665 17.8299C136.61 17.885 136.544 17.9285 136.471 17.958C136.399 17.9875 136.321 18.0024 136.243 18.0018H135.25H135.268Z"
				fill="white"
			/>
			<path
				d="M60.386 78.9282C60.2277 78.9282 60.0758 78.8653 59.9639 78.7534C59.8519 78.6414 59.7891 78.4896 59.7891 78.3313V78.3313C59.7891 78.173 59.8519 78.0212 59.9639 77.9092C60.0758 77.7973 60.2277 77.7344 60.386 77.7344H74.2747C74.4322 77.7356 74.5828 77.799 74.6938 77.9108C74.8048 78.0226 74.867 78.1738 74.867 78.3313V78.3313C74.867 78.4888 74.8048 78.64 74.6938 78.7518C74.5828 78.8636 74.4322 78.927 74.2747 78.9282H60.386Z"
				fill="white"
			/>
			<path
				d="M77.7571 78.9282C77.5988 78.9282 77.4469 78.8653 77.335 78.7534C77.223 78.6414 77.1602 78.4896 77.1602 78.3313V78.3313C77.1602 78.173 77.223 78.0212 77.335 77.9092C77.4469 77.7973 77.5988 77.7344 77.7571 77.7344H80.3635C80.5218 77.7344 80.6736 77.7973 80.7856 77.9092C80.8975 78.0212 80.9604 78.173 80.9604 78.3313V78.3313C80.9604 78.4896 80.8975 78.6414 80.7856 78.7534C80.6736 78.8653 80.5218 78.9282 80.3635 78.9282H77.7571Z"
				fill="white"
			/>
			<path
				d="M210.128 91.6157C209.97 91.6157 209.818 91.5528 209.706 91.4409C209.594 91.3289 209.531 91.1771 209.531 91.0188V91.0188C209.531 90.8605 209.594 90.7087 209.706 90.5967C209.818 90.4848 209.97 90.4219 210.128 90.4219H224.035C224.193 90.4219 224.345 90.4848 224.457 90.5967C224.569 90.7087 224.632 90.8605 224.632 91.0188V91.0188C224.632 91.1771 224.569 91.3289 224.457 91.4409C224.345 91.5528 224.193 91.6157 224.035 91.6157H210.128Z"
				fill="white"
			/>
			<path
				d="M178.972 78.2596C178.814 78.2596 178.663 78.1973 178.551 78.0864C178.44 77.9754 178.376 77.8248 178.375 77.6672V77.6672C178.375 77.5089 178.438 77.3571 178.55 77.2452C178.662 77.1332 178.814 77.0703 178.972 77.0703H190.491C190.649 77.0703 190.801 77.1332 190.913 77.2452C191.025 77.3571 191.088 77.5089 191.088 77.6672V77.6672C191.087 77.8248 191.023 77.9754 190.912 78.0864C190.8 78.1973 190.649 78.2596 190.491 78.2596H178.972Z"
				fill="white"
			/>
			<path
				d="M173.805 81.0454C173.648 81.0442 173.497 80.9808 173.386 80.869C173.275 80.7572 173.213 80.606 173.213 80.4485C173.213 80.291 173.275 80.1398 173.386 80.028C173.497 79.9162 173.648 79.8528 173.805 79.8516H182.946C183.103 79.8528 183.254 79.9162 183.365 80.028C183.476 80.1398 183.538 80.291 183.538 80.4485V80.4485C183.538 80.606 183.476 80.7572 183.365 80.869C183.254 80.9808 183.103 81.0442 182.946 81.0454H173.805Z"
				fill="white"
			/>
			<path
				d="M22.4452 90.2212C22.2909 90.2153 22.1449 90.1498 22.0378 90.0385C21.9307 89.9272 21.871 89.7787 21.8711 89.6243V89.6243C21.8711 89.466 21.934 89.3141 22.0459 89.2022C22.1579 89.0902 22.3097 89.0273 22.468 89.0273H33.9645C34.1228 89.0273 34.2746 89.0902 34.3865 89.2022C34.4985 89.3141 34.5614 89.466 34.5614 89.6243V89.6243C34.5614 89.7826 34.4985 89.9344 34.3865 90.0463C34.2746 90.1583 34.1228 90.2212 33.9645 90.2212H22.4452Z"
				fill="white"
			/>
			<path
				d="M17.2779 93.0024C17.1204 93.0012 16.9697 92.9378 16.8588 92.826C16.7478 92.7142 16.6855 92.563 16.6855 92.4055V92.4055C16.6855 92.248 16.7478 92.0968 16.8588 91.985C16.9697 91.8732 17.1204 91.8098 17.2779 91.8086H26.4277C26.586 91.8086 26.7378 91.8715 26.8498 91.9834C26.9617 92.0954 27.0246 92.2472 27.0246 92.4055V92.4055C27.0246 92.5638 26.9617 92.7157 26.8498 92.8276C26.7378 92.9395 26.586 93.0024 26.4277 93.0024H17.2779Z"
				fill="white"
			/>
			<path
				d="M72.6575 91.6157C72.4992 91.6157 72.3473 91.5528 72.2354 91.4409C72.1234 91.3289 72.0605 91.1771 72.0605 91.0188V91.0188C72.0605 90.8605 72.1234 90.7087 72.2354 90.5967C72.3473 90.4848 72.4992 90.4219 72.6575 90.4219H106.45C106.608 90.4219 106.76 90.4848 106.872 90.5967C106.984 90.7087 107.047 90.8605 107.047 91.0188V91.0188C107.047 91.1771 106.984 91.3289 106.872 91.4409C106.76 91.5528 106.608 91.6157 106.45 91.6157H72.6575Z"
				fill="white"
			/>
			<path
				d="M158.047 91.6157C157.89 91.6145 157.739 91.5511 157.628 91.4393C157.517 91.3275 157.455 91.1763 157.455 91.0188V91.0188C157.455 90.8613 157.517 90.7101 157.628 90.5983C157.739 90.4865 157.89 90.4231 158.047 90.4219H171.439C171.598 90.4219 171.75 90.4848 171.862 90.5967C171.973 90.7087 172.036 90.8605 172.036 91.0188V91.0188C172.036 91.1771 171.973 91.3289 171.862 91.4409C171.75 91.5528 171.598 91.6157 171.439 91.6157H158.047Z"
				fill="white"
			/>
			<path
				d="M145.138 91.6157C144.98 91.6157 144.828 91.5528 144.716 91.4409C144.604 91.3289 144.541 91.1771 144.541 91.0188V91.0188C144.541 90.8605 144.604 90.7087 144.716 90.5967C144.828 90.4848 144.98 90.4219 145.138 90.4219H152.57C152.648 90.4219 152.726 90.4373 152.798 90.4673C152.871 90.4973 152.937 90.5413 152.992 90.5967C153.047 90.6521 153.091 90.7179 153.121 90.7904C153.151 90.8628 153.167 90.9404 153.167 91.0188V91.0188C153.167 91.0972 153.151 91.1748 153.121 91.2472C153.091 91.3197 153.047 91.3855 152.992 91.4409C152.937 91.4963 152.871 91.5403 152.798 91.5703C152.726 91.6003 152.648 91.6157 152.57 91.6157H145.138Z"
				fill="white"
			/>
			<path
				d="M0.596922 71.1157C0.438608 71.1157 0.286779 71.0528 0.174834 70.9409C0.0628898 70.8289 0 70.6771 0 70.5188H0C0 70.3605 0.0628898 70.2087 0.174834 70.0967C0.286779 69.9848 0.438608 69.9219 0.596922 69.9219H47.8084C47.9667 69.9219 48.1186 69.9848 48.2305 70.0967C48.3425 70.2087 48.4053 70.3605 48.4053 70.5188V70.5188C48.4053 70.6771 48.3425 70.8289 48.2305 70.9409C48.1186 71.0528 47.9667 71.1157 47.8084 71.1157H0.596922Z"
				fill="white"
			/>
			<path
				d="M118.214 78.9282C118.056 78.9282 117.904 78.8653 117.792 78.7534C117.68 78.6414 117.617 78.4896 117.617 78.3313V78.3313C117.617 78.173 117.68 78.0212 117.792 77.9092C117.904 77.7973 118.056 77.7344 118.214 77.7344H134.878C135.036 77.7344 135.188 77.7973 135.3 77.9092C135.412 78.0212 135.475 78.173 135.475 78.3313V78.3313C135.475 78.4896 135.412 78.6414 135.3 78.7534C135.188 78.8653 135.036 78.9282 134.878 78.9282H118.214Z"
				fill="white"
			/>
			<path
				d="M139.251 78.9282C139.093 78.9282 138.941 78.8653 138.829 78.7534C138.717 78.6414 138.654 78.4896 138.654 78.3313V78.3313C138.654 78.173 138.717 78.0212 138.829 77.9092C138.941 77.7973 139.093 77.7344 139.251 77.7344H142.527C142.686 77.7344 142.838 77.7973 142.95 77.9092C143.061 78.0212 143.124 78.173 143.124 78.3313V78.3313C143.124 78.4896 143.061 78.6414 142.95 78.7534C142.838 78.8653 142.686 78.9282 142.527 78.9282H139.251Z"
				fill="white"
			/>
			<path
				d="M125.362 76.1971C125.284 76.1971 125.207 76.1818 125.135 76.152C125.063 76.1222 124.998 76.0786 124.943 76.0236C124.888 75.9686 124.844 75.9033 124.815 75.8314C124.785 75.7596 124.77 75.6825 124.77 75.6047V75.6047C124.769 75.5266 124.784 75.449 124.813 75.3766C124.843 75.3042 124.886 75.2384 124.941 75.1829C124.996 75.1274 125.062 75.0834 125.134 75.0533C125.206 75.0233 125.284 75.0078 125.362 75.0078H131.399C131.478 75.0078 131.555 75.0233 131.627 75.0533C131.699 75.0834 131.765 75.1274 131.82 75.1829C131.875 75.2384 131.919 75.3042 131.948 75.3766C131.978 75.449 131.992 75.5266 131.992 75.6047V75.6047C131.992 75.6825 131.977 75.7596 131.947 75.8314C131.917 75.9033 131.873 75.9686 131.818 76.0236C131.763 76.0786 131.698 76.1222 131.626 76.152C131.554 76.1818 131.477 76.1971 131.399 76.1971H125.362Z"
				fill="white"
			/>
		</svg>
	);
};
