import { IItinerarySearchParams } from '@mopla/data-models';

const ensureLeadingZero = (str = ''): string => {
	if (str.split('.')[0].length === 1) {
		str = `0${str}`;
	}
	return str || '';
};

const getLatLngFromStr = (str: string): [string, string] => {
	let [lat, lng] = str.split(',');
	lat = ensureLeadingZero(lat);
	lng = ensureLeadingZero(lng);

	return [lat, lng];
};

export const parseItinerarySearchParams = (
	searchParams: URLSearchParams
): IItinerarySearchParams => {
	const fromPlaceLatLng = getLatLngFromStr(searchParams.get('dep') || '');
	const fromPlaceLabel = searchParams.get('fromPlaceLabel');
	const fromPlaceZip = searchParams.get('fromPlaceZip');
	const fromPlaceRegion = searchParams.get('fromPlaceRegion');

	const toPlaceLatLng = getLatLngFromStr(searchParams.get('arr') || '');
	const toPlaceLabel = searchParams.get('toPlaceLabel');
	const toPlaceZip = searchParams.get('toPlaceZip');
	const toPlaceRegion = searchParams.get('toPlaceRegion');

	const datetime = searchParams.get('dateTime');
	const arriveBy = searchParams.get('dateType') === 'arrival';
	const specialNeeds = searchParams.get('special') || 'NONE';

	/** Nice to have some validation here */

	return {
		fromPlaceLat: fromPlaceLatLng[0],
		fromPlaceLng: fromPlaceLatLng[1],
		toPlaceLat: toPlaceLatLng[0],
		toPlaceLng: toPlaceLatLng[1],
		fromPlaceLabel,
		fromPlaceZip,
		fromPlaceRegion,
		toPlaceLabel,
		toPlaceZip,
		toPlaceRegion,
		datetime,
		arriveBy,
		specialNeeds,
	} as IItinerarySearchParams;
};
