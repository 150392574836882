import { styled } from '@mui/material/styles';

interface IconProps {
	variant: 'midGrey' | 'main' | 'disabled';
	large: boolean;
}

export const Root = styled('div')(() => ({
	justifySelf: 'end',
	alignSelf: 'start',
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateRows: 'max-content',
	gridAutoColumns: 'max-content',
}));

export const SymbolCircle = styled('div', {
	shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'large',
})<IconProps>(({ theme, variant, large }) => ({
	display: 'grid',
	placeContent: 'center',
	width: large ? 48 : 32,
	height: large ? 48 : 32,
	borderRadius: '50%',
	border: `1px solid ${theme.colors.mopla_white}`,
	color: theme.colors.mopla_secondary,

	...(variant === 'main' && {
		background: theme.colors.mopla_primary,
	}),

	...(variant === 'midGrey' && {
		background: theme.colors.mopla_mid_grey,
	}),

	...(variant === 'disabled' && {
		background: theme.colors.mopla_white,
		color: theme.colors.mopla_dark_grey,
	}),

	'&:not(:first-child)': {
		marginLeft: '-4px',
	},
}));
