import React, { useEffect, useState } from 'react';

import styles from './Loader.module.scss';

export const Loader: React.FC<{
	delay?: number;
	style?: React.CSSProperties;
}> = ({ delay = 0, style }) => {
	const [showLoader, setShowLoader] = useState(delay === 0);

	useEffect(() => {
		const tId = setTimeout(() => {
			setShowLoader(true);
		}, delay);

		return () => {
			if (tId) {
				clearTimeout(tId);
			}
		};
	}, [delay]);

	if (!showLoader) {
		return null;
	}

	return (
		<div data-testid="loader" className={styles.fullscreenLoader} style={style}>
			<div className={`${styles.dot} ${styles.firstDot}`} />
			<div className={`${styles.dot} ${styles.secondDot}`} />
			<div className={`${styles.dot} ${styles.thirdDot}`} />
		</div>
	);
};
