import { useMemo } from 'react';
import { useSubscription } from 'observable-hooks';
import { DeepReadonlyObject } from 'rxdb';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { IWhoami } from '@mopla/data-models';

import { UserActionTypes } from '../actions/userActions';
import { useBusinessLayer } from '../business-logic';

import { TUser$ } from './dataContext';

export type TUserDBValue = {
	userData: DeepReadonlyObject<IWhoami> | null;
	isLoading: boolean;
};

export const useUser$ = () => {
	const { db, watchActions$ } = useBusinessLayer();

	const user$ = useMemo(
		() =>
			new BehaviorSubject<TUserDBValue>({
				userData: null,
				isLoading: false,
			}),
		[]
	);

	const result$ = useMemo<TUser$>(() => {
		/**
		 * Observe the stream of 2 actions.
		 * When the last is {FetchUser} - the data is fetching.
		 * When the last is {FetchUserResult} - the result is ready.
		 * */
		const userIsLoading$ = watchActions$([
			UserActionTypes.FetchUser,
			UserActionTypes.FetchUserResult,
		]).pipe(
			map(({ type }) => type === UserActionTypes.FetchUser),
			startWith(false),
			distinctUntilChanged()
		);

		return combineLatest([db.getLocal$<IWhoami>('user'), userIsLoading$]).pipe(
			map(([doc, isLoading]) => ({
				isLoading,
				userData: doc && doc.toJSON().data,
			}))
		);
	}, [db, watchActions$]);

	useSubscription(result$, (v) => user$.next(v));

	return user$;
};
