import { styled } from '@mui/material/styles';

export const Root = styled('button')(({ theme }) => ({
	...theme.fonts.body1_semibold,
	background: 'transparent',
	border: 'none',
	outline: 'none',
	height: '36px',
	cursor: 'pointer',
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	gridTemplateRows: '1fr',
	gridGap: 8,
	alignItems: 'center',
	color: theme.colors.mopla_secondary,
	width: 'max-content',
	marginBottom: 24,
	padding: '0px 4px 0px 0px',
	position: 'relative',
	span: {
		...theme.fonts.caption,
		position: 'absolute',
		right: 0,
		bottom: '-10px',
		color: theme.colors.mopla_red,
	},
	'&:focus-visible': {
		outline: `2px solid ${theme.colors.mopla_secondary}`,
	},
}));
