import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { CheckmarkIcon } from '../icons/Checkmark';

import {
	DriverCheckMarkWrapper,
	PasswordDescription,
	Root,
	Separator,
} from './PasswordValidationConditions.styles';

interface ICheckmarks {
	specialCharacter: boolean;
	capital: boolean;
	lowercase: boolean;
	length: boolean;
}

const defaultConditions: ICheckmarks = {
	specialCharacter: false,
	capital: false,
	lowercase: false,
	length: false,
};

interface PasswordValidationConditionsProps {
	password: string;
	checkPasswordErrors: boolean;
	driverApp?: boolean;
	setCheckPasswordErrors: Dispatch<SetStateAction<boolean>>;
	setPasswordIsValid: Dispatch<SetStateAction<boolean>>;
}

export const PasswordValidationConditions: React.FC<
	PasswordValidationConditionsProps
> = ({
	password,
	checkPasswordErrors,
	driverApp,
	setCheckPasswordErrors,
	setPasswordIsValid,
}) => {
	const { t } = useTranslation(['registration', 'validation']);

	const [checkmarkObj, setCheckmarkObj] =
		useState<ICheckmarks>(defaultConditions);
	const [conditionErrorsObj, setConditionErrorsObj] =
		useState<ICheckmarks>(defaultConditions);

	const checkMarksConditions = useCallback(() => {
		setCheckmarkObj({
			specialCharacter: Boolean(
				password.match(/^(?=.*[!@#$%^&*?(){}[\]/|=~\-+.,_><])/)
			),
			capital: Boolean(password.match(/^(?=.*[A-ZÄÖÜẞ])/)),
			lowercase: Boolean(password.match(/^(?=.*[a-zäöüß])/)),
			length: Boolean(password.match(/.{8,128}$/)),
		});
	}, [password]);

	const checkErrorsConditions = useCallback(() => {
		setConditionErrorsObj({
			specialCharacter: !checkmarkObj.specialCharacter,
			capital: !checkmarkObj.capital,
			lowercase: !checkmarkObj.lowercase,
			length: !checkmarkObj.length,
		});
	}, [checkmarkObj]);

	useEffect(() => {
		checkMarksConditions();
	}, [password, checkMarksConditions]);

	useEffect(() => {
		if (checkPasswordErrors) {
			checkErrorsConditions();
			setCheckPasswordErrors(false);
		}
	}, [checkPasswordErrors, checkErrorsConditions, setCheckPasswordErrors]);

	useEffect(() => {
		setPasswordIsValid(Object.values(checkmarkObj).every((value) => value));
	}, [checkmarkObj, setPasswordIsValid]);

	return (
		<Root marginTop={driverApp ? '24px' : ''} driverApp={driverApp}>
			<PasswordDescription
				bold
				width="70%"
				driverApp={driverApp}
				marginBottom={driverApp ? '16px' : ''}
			>
				{t(`text.${driverApp ? 'password_rules' : 'password_conditions'}`)}
			</PasswordDescription>
			{driverApp && <Separator />}
			<PasswordDescription
				error={
					conditionErrorsObj.specialCharacter && !checkmarkObj.specialCharacter
				}
				data-testid="registrationForm-password-validation-rule-specialCharacter"
				driverApp={driverApp}
				marginBottom={driverApp ? '10px' : ''}
			>
				{driverApp && (
					<DriverCheckMarkWrapper
						error={
							conditionErrorsObj.specialCharacter &&
							!checkmarkObj.specialCharacter
						}
						success={checkmarkObj.specialCharacter}
					>
						<CheckmarkIcon />
					</DriverCheckMarkWrapper>
				)}
				{t('validation:password.special_character')}
				{checkmarkObj.specialCharacter && !driverApp && <CheckmarkIcon />}
			</PasswordDescription>
			<PasswordDescription
				error={conditionErrorsObj.capital && !checkmarkObj.capital}
				data-testid="registrationForm-password-validation-rule-capital"
				driverApp={driverApp}
				marginBottom={driverApp ? '10px' : ''}
			>
				{driverApp && (
					<DriverCheckMarkWrapper
						error={conditionErrorsObj.capital && !checkmarkObj.capital}
						success={checkmarkObj.capital}
					>
						<CheckmarkIcon />
					</DriverCheckMarkWrapper>
				)}
				{t('validation:password.capital')}
				{checkmarkObj.capital && !driverApp && <CheckmarkIcon />}
			</PasswordDescription>
			<PasswordDescription
				error={conditionErrorsObj.lowercase && !checkmarkObj.lowercase}
				data-testid="registrationForm-password-validation-rule-lowercase"
				driverApp={driverApp}
				marginBottom={driverApp ? '10px' : ''}
			>
				{driverApp && (
					<DriverCheckMarkWrapper
						error={conditionErrorsObj.lowercase && !checkmarkObj.lowercase}
						success={checkmarkObj.lowercase}
					>
						<CheckmarkIcon />
					</DriverCheckMarkWrapper>
				)}
				{t('validation:password.lowercase')}
				{checkmarkObj.lowercase && !driverApp && <CheckmarkIcon />}
			</PasswordDescription>
			<PasswordDescription
				error={conditionErrorsObj.length && !checkmarkObj.length}
				data-testid="registrationForm-password-validation-rule-length"
				driverApp={driverApp}
				marginBottom={driverApp ? '10px' : ''}
			>
				{driverApp && (
					<DriverCheckMarkWrapper
						error={conditionErrorsObj.length && !checkmarkObj.length}
						success={checkmarkObj.length}
					>
						<CheckmarkIcon />
					</DriverCheckMarkWrapper>
				)}
				{t('validation:password.length')}
				{checkmarkObj.length && !driverApp && <CheckmarkIcon />}
			</PasswordDescription>
		</Root>
	);
};

export default PasswordValidationConditions;
