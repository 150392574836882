/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingId } from './BookingId';
import type { DateTimeValue } from './DateTimeValue';
import type { NamedLocation } from './NamedLocation';

/**
 * A ScheduledLeg is a part of a trip. It has a starting location & time.
 * It contains all bookings (passengers) entering at the start, and all bookings (passengers) exiting at the stop.
 *
 */
export type ScheduledLeg = {
	/**
	 * The id of the scheduled leg that the passenger entered on
	 */
	scheduledLegId?: string;
	from?: NamedLocation;
	to?: NamedLocation;
	start?: DateTimeValue;
	end?: DateTimeValue;
	immutableSince?: DateTimeValue;
	/**
	 * The total distance of the leg in m.
	 */
	distance?: number;
	/**
	 * A list of bookings (passengers) that'll exit the drive at the stop.
	 */
	exitingBookings?: Array<BookingId>;
	/**
	 * A list of bookings (passengers) that'll enter the drive at the start.
	 */
	enteringBookings?: Array<BookingId>;
	/**
	 * The amount of passengers that'll be transported during this leg.
	 */
	passengers?: number;
	/**
	 * State of the scheduled leg
	 */
	state?: ScheduledLeg.state;
	/**
	 * A note for the driver. (only set if it's a manually created trip)
	 */
	note?: string;
};

export namespace ScheduledLeg {
	/**
	 * State of the scheduled leg
	 */
	export enum state {
		NEW = 'NEW',
		EXECUTING = 'EXECUTING',
		DONE = 'DONE',
	}
}
