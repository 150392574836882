import { useContext, useEffect, useState } from 'react';
import { RxDocument } from 'rxdb';
import { BehaviorSubject, combineLatest } from 'rxjs';

import {
	Booking,
	DataChange,
	NormalizedScheduledLeg,
} from '@mopla/data-models';

import {
	editBooking as editBookingAction,
	editLeg as editLegAction,
	initEventStream as initEventStreamAction,
	initPushProcessor as initPushProcessorAction,
	resetScheduleDiff,
	updateSchedule as updateScheduleAction,
} from '../actions/scheduleActions';
import { BusinessLayerContext } from '../business-logic';
import { ScheduleDiffEl } from '../entities/scheduleDiff';

type ScheduleValue = {
	legs: NormalizedScheduledLeg[] | null;
	bookings: Booking[] | null;
	editLeg: (leg: NormalizedScheduledLeg) => void;
	editBooking: (book: Booking, legId: string) => void;
	initEventStream: () => void;
	initPushProcessor: () => void;
	updateSchedule: () => void;
	markChangesAsSeen: () => void;
	unseenChanges: ScheduleDiffEl[] | null;
	initialized: boolean;
};

export const useSchedule = (mockedSchedules = false): ScheduleValue => {
	const businessLayer = useContext(BusinessLayerContext);

	const [legs, setLegs] = useState<NormalizedScheduledLeg[] | null>(null);
	const [initialized, setInitialized] = useState(false);
	const [unseenChanges, setUnseenChanges] = useState<ScheduleDiffEl[] | null>(
		null
	);
	const [bookings, setBookings] = useState<Booking[] | null>(null);

	const initEventStream = () => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(initEventStreamAction());
		}
	};

	const initPushProcessor = () => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(initPushProcessorAction());
		}
	};

	const editLeg = (leg: NormalizedScheduledLeg) => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(editLegAction(leg));
		}
	};

	const editBooking = (booking: Booking, legId: string) => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(editBookingAction(booking, legId));
		}
	};

	const updateSchedule = () => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(updateScheduleAction());
		}
	};

	const markChangesAsSeen = () => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(resetScheduleDiff());
		}
	};

	useEffect(() => {
		const legsLiveQuery = businessLayer.db['scheduledLeg'].find().$;
		const bookingsLiveQuery = businessLayer.db['booking'].find().$;
		const dataChangesQuery = businessLayer.db['dataChanges'].find().$;
		const unseenChanges = businessLayer.db['scheduleDiff'].find().$;

		const rxdbSubscr1 = unseenChanges.subscribe(
			(val: RxDocument<ScheduleDiffEl>[]) => {
				setUnseenChanges(val.map((l) => l.toMutableJSON()));
			}
		);

		const changesMergeSource = combineLatest([
			legsLiveQuery as BehaviorSubject<RxDocument<NormalizedScheduledLeg>[]>,
			bookingsLiveQuery as BehaviorSubject<RxDocument<Booking>[]>,
			dataChangesQuery as BehaviorSubject<RxDocument<DataChange>[]>,
		]);

		const rxdbSubscr2 = changesMergeSource.subscribe((val) => {
			setInitialized(true);
			const originalLegs = val[0].map((l) => l.toJSON());
			const originalBookings = val[1].map((l) => l.toJSON());

			const legsChanges = val[2]
				.map((ch) => ch.toJSON())
				.filter((ch) => !!ch.scheduledLegId && !ch.bookingId);
			const bookingsChanges = val[2]
				.map((ch) => ch.toJSON())
				.filter((ch) => !!ch.bookingId);

			const updatedLegs = originalLegs
				.map((l) => {
					const updatedLeg = legsChanges
						.filter((ch) => {
							return ch.scheduledLegId === l.scheduledLegId;
						})
						.sort((a, b) => b.insertTime - a.insertTime)[0];

					const vehicleUpdates = updatedLeg?.vehicleData
						? {
								checkDone: updatedLeg.checkDone,
								vehicleData: updatedLeg.vehicleData,
						  }
						: {};
					return {
						...vehicleUpdates,
						...l,
						state: updatedLeg ? updatedLeg?.type : l.state,
					};
				})
				.filter((l) => l.state !== 'DONE' && l.state !== 'DONE_BREAK')
				.sort(
					(a, b) =>
						new Date(a.start || '').getTime() -
						new Date(b.start || '').getTime()
				) as NormalizedScheduledLeg[];

			const updatedBookings = originalBookings.map((b) => {
				const currentBookingChange = bookingsChanges
					.filter((ch) => ch.bookingId === b.bookingId)
					.sort((a, b) => b.insertTime - a.insertTime)[0];
				const newBookingStatus = currentBookingChange?.type;

				return {
					...b,
					bookingState: newBookingStatus ? newBookingStatus : b.bookingState,
				};
			}) as Booking[];

			setBookings(updatedBookings);
			setLegs(updatedLegs);
		});

		return () => {
			rxdbSubscr1.unsubscribe();
			rxdbSubscr2.unsubscribe();
		};
	}, [businessLayer.db]);

	return {
		legs: legs,
		bookings: bookings,
		editLeg: editLeg,
		editBooking: editBooking,
		initEventStream: initEventStream,
		updateSchedule: updateSchedule,
		initPushProcessor,
		unseenChanges,
		markChangesAsSeen,
		initialized,
	};
};
