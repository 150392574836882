import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { useUser } from '@mopla/business-logic';
import {
	ESubscriptionStatus,
	TGermanyTicketType,
	TPaymentType,
	TSubscription,
} from '@mopla/data-models';

import {
	DTicketIcon,
	FlexContainer,
	GlobeIcon,
	PaymentIcon,
	ProfileCircleIcon,
	ProfileGenericButton,
	Text,
	VoucherIcon,
} from '../../';

import {
	ContentWrapper,
	ImprintButton,
	InitialScreenRoot,
	SignOutButton,
	UserAvatar,
	UserGreeting,
	UserGreetingWrapper,
} from './ProfileManager.styles';

const stringAvatar = (name: string) => {
	return {
		sx: {
			bgcolor: '#6FDB89',
		},
		children: `${name.split(' ')[0]?.[0] || ''}${
			name.split(' ')[1]?.[0] || ''
		}`,
	};
};

export interface InitialScreenProps {
	onLogout: VoidFunction;
	onProfile: VoidFunction;
	onPayment: VoidFunction;
	onLanguage: VoidFunction;
	onGermanyTicket: VoidFunction;
	onVoucher?: VoidFunction;
	onImprint?: MouseEventHandler<HTMLButtonElement>;
	onCookieSettings?: MouseEventHandler<HTMLButtonElement>;
	onFAQ?: MouseEventHandler<HTMLButtonElement>;
	showGreeting?: boolean;
	app: 'DRIVER' | 'PASSENGER';
	subscription?: TSubscription;
	defaultPaymentMethodType?: TPaymentType;
	dTicketType?: TGermanyTicketType;
}

export const InitialProfileScreen: React.FC<InitialScreenProps> = ({
	onLogout,
	onProfile,
	onPayment,
	onLanguage,
	onGermanyTicket,
	onVoucher,
	onImprint,
	onCookieSettings,
	onFAQ,
	showGreeting,
	app,
	subscription,
	defaultPaymentMethodType,
	dTicketType,
}) => {
	const { t } = useTranslation(['profile', 'common']);
	const { userData } = useUser();

	const isDriver = app === 'DRIVER';
	const isPendingSubscription =
		subscription?.status === ESubscriptionStatus.PENDING;

	const hasDefaultPaymentMethod = !!userData?.hasDefaultStripePaymentMethod;

	const getPaymentHelperText = () => {
		if (!hasDefaultPaymentMethod || !defaultPaymentMethodType) return '';

		return t(`profile:subheader.payment_method.${defaultPaymentMethodType}`);
	};

	const getDTicketHelperText = () => {
		if (!subscription?.status) return '';

		return t(
			`profile:subheader.dticket_status.${subscription.status.toLowerCase()}`
		);
	};

	const windowRef = window as any;

	return (
		<InitialScreenRoot>
			<ContentWrapper>
				{showGreeting ? (
					<UserGreetingWrapper>
						<UserAvatar
							{...stringAvatar(
								`${userData?.firstName} ${userData?.lastName}` || ''
							)}
						/>
						<UserGreeting>
							<span>{t('common:text.hello')},</span>
							<span>
								{userData?.firstName} {userData?.lastName}
							</span>
						</UserGreeting>
					</UserGreetingWrapper>
				) : null}
				{!isDriver ? (
					<ProfileGenericButton
						onClick={onProfile}
						mainIcon={<ProfileCircleIcon />}
						label={t('profile:button.my_details')}
						variant="grey"
					/>
				) : null}
				{!isDriver ? (
					<ProfileGenericButton
						onClick={onGermanyTicket}
						mainIcon={<DTicketIcon />}
						label={t('profile:button.germany_ticket', { dTicketType })}
						helperText={getDTicketHelperText()}
						variant="grey"
					/>
				) : null}
				{!isDriver ? (
					<ProfileGenericButton
						onClick={onVoucher!}
						mainIcon={<VoucherIcon />}
						label={t('profile:button.vouchers')}
						variant="grey"
					/>
				) : null}
				{!isDriver ? (
					<ProfileGenericButton
						onClick={onPayment}
						mainIcon={<PaymentIcon />}
						label={t('profile:button.payment')}
						variant="grey"
						helperText={getPaymentHelperText()}
						disabled={isPendingSubscription}
					/>
				) : null}
				<ProfileGenericButton
					onClick={onLanguage}
					mainIcon={<GlobeIcon />}
					label={t('profile:button.language')}
					helperText={t(`profile:button.${i18n.language}`)}
					variant={isDriver ? 'white' : 'grey'}
				/>
			</ContentWrapper>
			{!isDriver && (
				<>
					<FlexContainer className="center" style={{ paddingBottom: 12 }}>
						<ImprintButton variant="text" onClick={onFAQ}>
							<Text variant="body3" color="mopla_white">
								{t('profile:button.faq')}
							</Text>
						</ImprintButton>
					</FlexContainer>
					<FlexContainer className="center" style={{ paddingBottom: 12 }}>
						<ImprintButton variant="text" onClick={onImprint}>
							<Text variant="body3" color="mopla_white">
								{t('profile:button.imprint')}
							</Text>
						</ImprintButton>
					</FlexContainer>
					{windowRef.CookieScript && (
						<FlexContainer className="center" style={{ paddingBottom: 12 }}>
							<ImprintButton variant="text" onClick={onCookieSettings}>
								<Text variant="body3" color="mopla_white">
									{t('profile:button.cookie_settings')}
								</Text>
							</ImprintButton>
						</FlexContainer>
					)}
				</>
			)}
			<FlexContainer className="center" style={{ paddingBottom: 24 }}>
				<SignOutButton type="button" onClick={onLogout}>
					{t('profile:button.logout')}
				</SignOutButton>
			</FlexContainer>
		</InitialScreenRoot>
	);
};
