import { styled } from '@mui/material/styles';

export const ContentWrapper = styled('div')(() => ({
	boxSizing: 'border-box',
	padding: 24,
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: 16,
}));

export const PaperBox = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: 24,
	backgroundColor: theme.colors.driver_light_grey,
	color: theme.colors.mopla_secondary_dark,
	display: 'flex',
	flexDirection: 'column',
	gap: 16,
	marginTop: 35,
}));

export const MailLink = styled('a')(({ theme }) => ({
	...theme.fonts.body1,
	textDecoration: 'underline',
	color: theme.colors.mopla_secondary_dark,
}));

export const DescriptionLink = styled('button')(({ theme }) => ({
	...theme.fonts.body1,
	background: 'none',
	border: 'none',
	padding: 0,
	textDecoration: 'underline',
	color: theme.colors.mopla_black,
	cursor: 'pointer',
}));

export const ActionsBox = styled('div')(() => ({
	marginTop: 'auto',
}));
