import React, { FC } from 'react';

import { Text } from '@mopla/ui';

export interface IPriceCalcSummaryItemProps {
	name: string;
	value: string;
	discount?: string;
}

export const PriceCalcSummaryItem: FC<IPriceCalcSummaryItemProps> = ({
	name,
	value,
	discount,
}) => {
	return (
		<>
			<Text
				data-testid="priceCalculationSection-passenger-name"
				variant="body3_semibold"
				color="mopla_dark_grey"
				ellipsis
			>
				{name}
			</Text>
			<span data-testid="booking-ticketPrice-text">{value}</span>
			{discount && (
				<span data-testid="priceCalculationSection-passenger-additionalInfo">
					{discount}
				</span>
			)}
		</>
	);
};
