export const passengerBookingSchema = {
	title: 'passenger booking schema',
	version: 2,
	primaryKey: 'bookingNumber',
	type: 'object',
	properties: {
		passengers: { type: 'number' },
		bookingNumber: {
			type: 'string',
			maxLength: 100,
			//example: 20228Z5F3J6S
		},
		specialNeeds: {
			type: 'string',
			// NONE, WHEELCHAIR_TYPE_1, WHEELCHAIR_TYPE_2
		},
		itinerary: {
			type: 'object',
			properties: {
				startDateTime: { type: 'string' },
				endDateTime: { type: 'string' },
				duration: { type: 'number' },
				walkTime: { type: 'number' },
				walkDistance: { type: 'number' },
				price: {
					type: 'object',
					properties: {
						tarif: { type: 'boolean' },
						mopla: { type: 'number' },
					},
				},
				legs: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							legId: { type: 'string' },
							startDateTime: { type: 'string' },
							startDateTimeFix: { type: 'boolean' },
							endDateTime: { type: 'string' },
							endDateTimeFix: { type: 'boolean' },
							duration: { type: 'number' },
							distance: { type: 'number' },
							mode: {
								type: 'string',
								// enum:  WALK, LBT, LBODT, LBODTFLEX, OTHERS
							},
							modeOrigin: { type: 'string' },
							route: { type: 'string' },
							headsign: { type: 'string' },
							routeId: { type: 'string' },
							agencyId: { type: 'string' },
							agencyName: { type: 'string' },
							tripShortName: { type: 'string' },
							tripId: { type: 'string' },
							from: {
								type: 'object',
								properties: {
									name: {
										type: 'string',
									},
									lng: {
										type: 'string',
									},
									lat: {
										type: 'string',
									},
									type: {
										type: 'string',
									},
									stopId: {
										type: 'string',
									},
									arrival: {
										type: 'string',
									},
									departure: {
										type: 'string',
									},
								},
							},
							to: {
								type: 'object',
								properties: {
									name: {
										type: 'string',
									},
									lng: {
										type: 'string',
									},
									lat: {
										type: 'string',
									},
									type: {
										type: 'string',
									},
									stopId: {
										type: 'string',
									},
									arrival: {
										type: 'string',
									},
									departure: {
										type: 'string',
									},
								},
							},
							legGeometry: {
								type: 'object',
								properties: {
									points: {
										type: 'string',
									},
									lng: {
										type: 'number',
									},
								},
							},
							intermediateStops: {
								type: 'array',
								items: {
									type: 'object',
									properties: {
										arrival: {
											type: 'string',
										},
										departure: {
											type: 'string',
										},
										name: {
											type: 'string',
										},
									},
								},
							},
						},
					},
				},
			},
		},
		paymentState: {
			type: 'string',
			// enum TO_PAY, INVOICE_SEND, PAID, FAILED
		},
		bookingStatus: {
			type: 'string',
			// enum:  NEW, EXECUTING, FINISHED, CANCELLED_BY_DISTRIBUTOR, CANCELLED_BY_PASSENGER, CANCELLED_NO_SHOW
		},
	},
	required: ['bookingNumber'],
};
