import React from 'react';

export const ArrowDownIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask id="path-1-inside-1_3759_913" fill="white">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.8889 13.4246L6.93912 8.47487L5.5249 9.88909L10.4746 14.8388L11.1818 15.5459C11.5723 15.9365 12.2054 15.9365 12.596 15.5459L13.3031 14.8388L18.2528 9.88908L16.8386 8.47486L11.8889 13.4246Z"
				/>
			</mask>
			<path
				d="M6.93912 8.47487L7.64622 7.76777L6.93912 7.06066L6.23201 7.76777L6.93912 8.47487ZM11.8889 13.4246L11.1817 14.1317L11.8889 14.8388L12.596 14.1317L11.8889 13.4246ZM5.5249 9.88909L4.8178 9.18198L4.11069 9.88909L4.8178 10.5962L5.5249 9.88909ZM10.4746 14.8388L9.76753 15.5459L9.76753 15.5459L10.4746 14.8388ZM13.3031 14.8388L14.0102 15.5459L14.0102 15.5459L13.3031 14.8388ZM18.2528 9.88908L18.9599 10.5962L19.667 9.88908L18.9599 9.18197L18.2528 9.88908ZM16.8386 8.47486L17.5457 7.76776L16.8386 7.06065L16.1315 7.76776L16.8386 8.47486ZM11.1818 15.5459L10.4746 16.253L11.1818 15.5459ZM12.596 15.5459L13.3031 16.2531L12.596 15.5459ZM6.23201 9.18198L11.1817 14.1317L12.596 12.7175L7.64622 7.76777L6.23201 9.18198ZM6.23201 10.5962L7.64622 9.18198L6.23201 7.76777L4.8178 9.18198L6.23201 10.5962ZM11.1817 14.1317L6.23201 9.18198L4.8178 10.5962L9.76753 15.5459L11.1817 14.1317ZM11.8889 14.8388L11.1817 14.1317L9.76753 15.5459L10.4746 16.253L11.8889 14.8388ZM12.596 14.1317L11.8889 14.8388L13.3031 16.2531L14.0102 15.5459L12.596 14.1317ZM17.5457 9.18197L12.596 14.1317L14.0102 15.5459L18.9599 10.5962L17.5457 9.18197ZM16.1315 9.18197L17.5457 10.5962L18.9599 9.18197L17.5457 7.76776L16.1315 9.18197ZM12.596 14.1317L17.5457 9.18197L16.1315 7.76776L11.1817 12.7175L12.596 14.1317ZM10.4746 16.253C11.2557 17.0341 12.522 17.0341 13.3031 16.2531L11.8889 14.8388L11.8889 14.8388L10.4746 16.253Z"
				fill="currentColor"
				mask="url(#path-1-inside-1_3759_913)"
			/>
		</svg>
	);
};
