import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	width: '100%',
	borderRadius: 8,
	background: theme.colors.mopla_white,
	padding: 16,
	boxSizing: 'border-box',
}));

export const TitleWrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'collapsed',
})<{ collapsed: boolean }>(({ collapsed }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content',
	gridTemplateRows: 'max-content',
	gridTemplateAreas: `
		"date      icon"
		"time      icon"
		"duration  price"
		`,
	marginBottom: collapsed ? 0 : 18,
}));

export const TitleTime = styled('div', {
	shouldForwardProp: (prop) => prop !== 'area',
})<{ area: string }>(({ theme, area }) => ({
	...theme.fonts.subtitle1,
	color: theme.colors.mopla_black,
	gridArea: area,
}));

export const SubtitleTime = styled('div')(({ theme }) => ({
	...theme.fonts.body2,
	color: theme.colors.mopla_dark_grey,
	gridArea: 'duration',
}));

export const AttentionWrapper = styled('div')(({ theme }) => ({
	...theme.fonts.body2,
	color: theme.colors.mopla_dark_grey,
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gridGap: 10,
	marginBottom: 16,
}));

export const WalkWrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'bottom',
})<{ bottom?: boolean }>(({ theme, bottom }) => ({
	...theme.fonts.body2,
	color: theme.colors.mopla_dark_grey,
	display: 'grid',
	gridTemplateColumns: '60px 1fr',
	justifyContent: 'start',
	margin: bottom ? '5px 0 0 0' : '0 0 5px 0',
}));

export const StationWrapper = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'intermediateStop' && prop !== 'marginTop',
})<{ intermediateStop?: boolean; marginTop?: string }>(
	({ theme, intermediateStop, marginTop }) => ({
		display: 'grid',
		gridTemplateColumns: '60px max-content 1fr',
		justifyContent: 'start',
		color: intermediateStop
			? theme.colors.mopla_dark_grey
			: theme.colors.mopla_black,
		marginTop: marginTop || 0,
	})
);

export const StationTime = styled('span')(({ theme }) => ({
	...theme.fonts.body1_semibold,
}));

export const StationName = styled('span')(({ theme }) => ({
	...theme.fonts.body1,
	marginLeft: 10,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

export const RouteLine = styled('div')(() => ({
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gridTemplateColumns: 'max-content',
	gridGap: 2,
	width: 'max-content',
	margin: '5px 0 5px 71px',
}));

export const RouteDot = styled('div')(({ theme }) => ({
	height: 4,
	width: 2,
	borderRadius: 2,
	background: theme.colors.mopla_grey,
}));

export const Tariff = styled('div')(() => ({
	marginTop: 16,
	justifySelf: 'end',
	textAlign: 'right',
}));

export const IconWrapper = styled('div')(() => ({
	gridArea: 'icon',
	placeSelf: 'start end',
}));

export const PriceWrapper = styled('div')(() => ({
	gridArea: 'price',
}));

export const CollapsibleButton = styled('button', {
	shouldForwardProp: (prop) => prop !== 'collapsed' && prop !== 'collapsed',
})<{ collapsed: boolean }>(({ collapsed }) => ({
	background: 'none',
	border: 'none',
	cursor: 'pointer',
	width: 24,
	height: 24,
	padding: 0,
	display: 'block',
	transform: `rotate(${collapsed ? 180 : 0}deg)`,
	'& svg': {
		display: 'block',
	},
}));
