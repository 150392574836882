/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * wheelchair accessible type 1, customer is sitting on normal vehicle seat, wheelchair transported in trunk <br>
 * wheelchair accessible type 2, customer remains sitting on wheelchair and needs low floor bus in most cases
 *
 */
export enum SpecialNeeds {
	NONE = 'NONE',
	WHEELCHAIR_TYPE_1 = 'WHEELCHAIR_TYPE_1',
	WHEELCHAIR_TYPE_2 = 'WHEELCHAIR_TYPE_2',
}
