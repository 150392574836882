import React, { useState } from 'react';
import { IconButton } from '@mui/material';

import { CheckmarkIcon } from '../../icons';
import { DropdownIcon } from '../DropdownIcon/DropdownIcon';

import {
	ConditionRoot,
	HeaderWrapper,
	Root,
	SeparatorSolid,
	Title,
} from './ConditionsDropdown.styles';

export interface ConditionsDropdownProps {
	title: string;
	conditions: Array<string>;
}

export const ConditionsDropdown: React.FC<ConditionsDropdownProps> = ({
	title,
	conditions,
}) => {
	const [open, setOpen] = useState<boolean>(true);

	return (
		<Root>
			<HeaderWrapper>
				<Title>{title}</Title>
				<IconButton
					aria-label="toggle conditions dropdown"
					onClick={() => setOpen((prev) => !prev)}
					onMouseDown={() => setOpen((prev) => !prev)}
				>
					<DropdownIcon isOpened={open} />
				</IconButton>
			</HeaderWrapper>
			{open && (
				<>
					<SeparatorSolid />
					{conditions.map((condition) => (
						<ConditionRoot key={condition}>
							<CheckmarkIcon />
							<span>{condition}</span>
						</ConditionRoot>
					))}
				</>
			)}
		</Root>
	);
};

export default ConditionsDropdown;
