import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { defer } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import {
	addPushToken,
	AddPushTokenAction,
	InitPassengerPushesAction,
	PushActionTypes,
} from '../actions/pushNotificationActions';
import { Effect } from '../business-logic';
import { ofType } from '../operators/ofType';

/*
- "type" -> TRIP_IMMUTABLE
- "bookingId" -> uuid
- "scheduledLegId" -> uuid

- "type" -> BOOKING_CANCELED_BY_DISTRIBUTOR
- "bookingId" -> uuid

- "type" -> BOOKING_CANCELED_BY_PASSENGER
- "bookingId" -> uuid

- "type" -> CHECK_IN_PASSENGER_NO_SHOW
- "bookingId" -> uuid
- "feeCharged -> boolean

- "type" -> CHECK_IN_PASSENGER
- "bookingId" -> uuid

- "type" -> CHECK_IN_PASSENGER_PAYMENT_SUCCEEDED
- "bookingId" -> uuid

- "type" -> CHECK_IN_PASSENGER_PAYMENT_FAILED
- "bookingId" -> uuid

- BOOKING_CANCELED_BY_DISTRIBUTOR


- "type" -> TICKET_RECEIVED
- "ticketId" -> uuid
- "subscriptionType" -> "germany_ticket"
- "subscriptionId" -> uuid

(triggered by stripe webhook -> see cancellation reason, only send if cancellation reason is pause)
- "type" -> SUBSCRIPTION_PAUSE_STARTED
- "subscriptionType" -> "germany_ticket"
- "subscriptionId" -> uuid

(cron job already present that we can use?)
- "type" -> SUBSCRIPTION_STILL_PAUSED
- "subscriptionType" -> "germany_ticket"
- "subscriptionId" -> uuid

(cron job already present that we can use?)
- "type" -> SUBSCRIPTION_WILL_RESUME
- "subscriptionType" -> "germany_ticket"
- "subscriptionId" -> uuid

(triggered by stripe webhook)
- "type" -> SUBSCRIPTION_PAYMENT_FAILED
- "subscriptionType" -> "germany_ticket"
- "subscriptionId" -> uuid

*/

let pushInitialized = false;
export const initPassengerPushesEffect: Effect = (actions$, dependencies) =>
	actions$.pipe(
		ofType(PushActionTypes.InitPassengerPushes),
		concatMap((action: InitPassengerPushesAction) =>
			defer(async () => {
				if (pushInitialized) {
					return;
				}
				pushInitialized = true;

				await FirebaseMessaging.addListener('tokenReceived', (event) => {
					console.log('token received', event);
					// send the token to the mopla backend
					actions$.next(addPushToken(event.token));
				});
			})
		)
	);

export const addPushTokenEffect: Effect<AddPushTokenAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(PushActionTypes.AddPushToken),
		concatMap((action: AddPushTokenAction) =>
			defer(async () => {
				try {
					await dependencies.api.post('/api/command/createUserPushToken', {
						pushToken: action.payload.token,
						appContext: 'PASSENGER',
					});
				} catch (err) {
					dependencies.Sentry.captureException(err);
				}
			})
		)
	);
