import * as React from 'react';

const H1_CAPS: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '93px',
	lineHeight: '104px',
	textTransform: 'uppercase',
};

const H1: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '93px',
	lineHeight: '104px',
};

const H2_CAPS: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '58px',
	lineHeight: '70px',
	textTransform: 'uppercase',
};

const H2: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '58px',
	lineHeight: '70px',
};

const H3_CAPS: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '46px',
	lineHeight: '50px',
	textTransform: 'uppercase',
};

const H3: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '46px',
	lineHeight: '50px',
};

const H4_CAPS: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '38px',
	lineHeight: '44px',
	textTransform: 'uppercase',
};

const H4: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '38px',
	lineHeight: '44px',
};

const H5: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '32px',
	lineHeight: '40px',
};

const H6: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '24px',
	lineHeight: '30px',
};

const subtitle1: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '20px',
	lineHeight: '24px',
};

const subtitle2: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '15px',
	lineHeight: '18px',
};

const subtitle3: React.CSSProperties = {
	fontFamily: '"Poppins", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '13px',
	lineHeight: '17px',
};

const body1: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '18px',
	lineHeight: '22px',
};

const body1_semibold: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '18px',
	lineHeight: '22px',
};

const body1_bold: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '18px',
	lineHeight: '22px',
};

const body2: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '22px',
};

const body2_semibold: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '22px',
};

const body2_bold: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '16px',
	lineHeight: '22px',
};

const body3: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '18px',
};

const body3_semibold: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '14px',
	lineHeight: '18px',
};

const caption: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '12px',
	lineHeight: '15px',
};

const caption_semibold: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '12px',
	lineHeight: '15px',
};

const driver_caption: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '18px',
};

const button: React.CSSProperties = {
	fontFamily: '"Palanquin", sans-serif',
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '20px',
	lineHeight: '24px',
};

export const fonts = {
	H1_CAPS,
	H1,
	H2_CAPS,
	H2,
	H3_CAPS,
	H3,
	H4_CAPS,
	H4,
	H5,
	H6,
	subtitle1,
	subtitle2,
	subtitle3,
	body1,
	body1_semibold,
	body1_bold,
	body2,
	body2_semibold,
	body2_bold,
	body3,
	body3_semibold,
	caption,
	caption_semibold,
	driver_caption,
	button,
};
