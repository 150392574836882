import React from 'react';

interface CrossIconProps {
	width?: number;
	height?: number;
}

export const DepartureIcon: React.FC<CrossIconProps> = ({
	width = 24,
	height = 24,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 6H4V18H6V6ZM19.6782 11.2929L16.1427 7.75732L14.7285 9.17154L16.5569 11H8V13H16.5569L14.7285 14.8285L16.1427 16.2427L19.6782 12.7071C19.8864 12.4989 19.9836 12.2217 19.9698 11.949C19.9577 11.7103 19.8605 11.4752 19.6782 11.2929Z"
				fill="currentColor"
			/>
		</svg>
	);
};
