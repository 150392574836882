import { CSSProperties, FC, ReactNode } from 'react';

import { Text } from '../../atoms/Text/Text';
import { TTextColor } from '../../atoms/Text/types';

import { Root } from './styled';

export interface IStatusPaymentProps {
	status: ReactNode;
	payment: ReactNode;
	color?: TTextColor | CSSProperties['color'];
	testId?: string;
	className?: string;
}

export const StatusPayment: FC<IStatusPaymentProps> = ({
	status,
	payment,
	color = 'mopla_white',
	testId,
	className,
}) => {
	return (
		<Root className={className}>
			<Text className="status" variant="body1_semibold" color={color}>
				{status}
			</Text>
			<Text
				className="payment"
				variant="body1_semibold"
				color={color}
				data-testid={testId}
			>
				{payment}
			</Text>
		</Root>
	);
};
