import { IActiveVoucher } from '../lib/types';

export const activeVoucher: IActiveVoucher = {
	voucherCode: 'first active voucher',
	longDescription: 'longDescription',
	shortDescription: 'shortDescription',
};

export const redeemVoucher404NotFound = {
	status: 404,
	error: 'Not Found',
	message: 'There is no vouchers for code sfs in Stripe!',
	path: '/api/users/vouchers',
	code: 'not_found',
};

export const redeemVoucher400Unexpected = {
	status: 400,
};
