import { styled } from '@mui/material/styles';

export const BadgesWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginBottom: '38px',
	gap: 36,

	img: {
		cursor: 'pointer',
		width: 140,
		height: 60,
	},
	svg: {
		cursor: 'pointer',
	},
}));
