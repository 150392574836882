import React, { ReactNode } from 'react';

import SearchHeader from '../../molecules/SearchHeader/SearchHeader';

import {
	BackBlock,
	BaseSurfaceBlock,
	ChildrenWrapper,
	Root,
} from './homeBanner.styles';

interface BottomSliderProps {
	open: boolean;
	children: ReactNode;
	variant: 'primary' | 'secondary';
	title?: string;
	onBack?: () => void;
	onClose?: () => void;
	withHeader?: boolean;
}

export const BottomSlider: React.FC<BottomSliderProps> = ({
	children,
	open,
	variant,
	onBack,
	onClose,
	title,
	withHeader = true,
}) => {
	return (
		<>
			{/* TODO: Do we need these components on our view */}
			{/* The next 2 components - "fake" background (white-blue) above the opened drawer*/}
			<BaseSurfaceBlock open={open} />
			<BackBlock variant={variant} open={open} />
			<Root open={open} withHeader={withHeader}>
				{withHeader ? (
					<SearchHeader
						showBack={!!onBack}
						showClose={!!onClose}
						onClose={onClose}
						onBack={onBack}
						title={title}
						open={true}
					/>
				) : null}
				{open ? <ChildrenWrapper>{children}</ChildrenWrapper> : null}
			</Root>
		</>
	);
};
