interface IErrorPayload {
	error: Error;
}

export interface ITypedAction<
	Type,
	Payload = undefined | Partial<IErrorPayload>
> {
	type: Type;
	payload: Payload;
}

export function actionGenerator<T>(
	type: T
): (payload?: IErrorPayload) => ITypedAction<T>;
export function actionGenerator<T, P>(
	type: T
): (payload: P | IErrorPayload) => ITypedAction<T, P & Partial<IErrorPayload>>;
export function actionGenerator<T, P = void>(type: T) {
	return (payload?: P): ITypedAction<T, P | void> => ({
		type,
		payload,
	});
}
