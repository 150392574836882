import { FC } from 'react';

export const Wallet: FC = () => {
	return (
		<svg
			width="649"
			height="828"
			viewBox="0 0 649 828"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M579.296 1H69.7037C31.7597 1 1 31.7847 1 69.7594V758.241C1 796.215 31.7597 827 69.7037 827H579.296C617.24 827 648 796.215 648 758.241V69.7594C648 31.7847 617.24 1 579.296 1Z"
				fill="#6FDB89"
				stroke="#6FDB89"
				stroke-width="1.33333"
				stroke-miterlimit="10"
			/>
			<path
				d="M117.013 70L41.7903 87.57C37.4878 88.575 34.8147 92.8775 35.8196 97.18L53.3897 172.403"
				stroke="black"
				stroke-width="8"
				stroke-linecap="round"
			/>
			<path
				d="M538.597 746.513L612.826 725.131C617.071 723.908 619.522 719.475 618.299 715.229L596.917 641"
				stroke="black"
				stroke-width="8"
				stroke-linecap="round"
			/>
			<path
				d="M443.632 192.22L427.568 113.005C423.651 93.6899 405.484 81.4563 387.028 85.7293C327.013 99.6241 201.666 128.566 141.285 141.927C122.682 146.044 110.839 165.268 114.928 184.664L227.669 719.431C231.168 736.026 245.211 747.307 260.837 747.274C263.208 747.269 265.615 747.003 268.027 746.456L510.017 691.588C528.434 687.412 540.198 668.417 536.293 649.162L443.632 192.22Z"
				fill="white"
			/>
			<path
				d="M510.259 694.135L268.268 749.004C265.575 749.614 262.823 749.927 260.09 749.933C242.089 749.972 226.209 736.642 222.328 718.24L109.587 183.473C107.333 172.785 109.225 161.824 114.915 152.608C120.559 143.464 129.249 137.17 139.398 134.869L139.595 134.824C198.778 121.729 318.937 93.9992 385.293 78.636L385.464 78.5972C395.564 76.307 405.89 78.2525 414.562 84.0826C423.29 89.9503 429.286 99.0434 431.444 109.684L540.168 645.841C544.606 667.725 531.189 689.389 510.259 694.135ZM141.355 144.513C133.674 146.255 127.1 151.016 122.827 157.937C118.522 164.913 117.089 173.212 118.795 181.304L231.537 716.071C234.475 730.003 246.492 740.093 260.113 740.064C262.183 740.06 264.269 739.822 266.313 739.359L508.303 684.49C524.147 680.898 534.303 664.498 530.944 647.932L422.22 111.776C420.586 103.719 416.047 96.8348 409.439 92.3927C402.841 87.9555 395.022 86.4553 387.289 88.2714C320.915 103.638 200.718 131.372 141.505 144.479L141.355 144.513Z"
				fill="black"
			/>
			<path
				d="M515.208 620.573L235.293 684.04L136.223 217.125L420.331 152.706L515.208 620.573ZM238.885 678.182L509.618 616.796L416.697 158.574L141.855 220.892L238.885 678.182Z"
				fill="black"
			/>
			<path
				d="M297.325 144.246L241.531 156.897C239.62 157.33 237.744 156.062 237.338 154.064C236.933 152.067 238.153 150.097 240.064 149.663L295.858 137.013C297.769 136.58 299.645 137.847 300.05 139.845C300.455 141.843 299.235 143.813 297.325 144.246Z"
				fill="black"
			/>
			<path
				d="M354.962 136.696C350.272 137.759 345.648 134.635 344.654 129.732C343.66 124.828 346.666 119.973 351.355 118.91C356.045 117.847 360.67 120.971 361.665 125.875C362.659 130.778 359.652 135.632 354.962 136.696ZM352.333 123.732C350.187 124.219 348.811 126.441 349.266 128.686C349.721 130.93 351.838 132.36 353.984 131.874C356.131 131.387 357.508 129.165 357.053 126.921C356.597 124.675 354.481 123.245 352.333 123.732Z"
				fill="black"
			/>
			<path
				d="M386.334 135.515C378.653 137.257 371.079 132.14 369.451 124.109C367.822 116.079 372.746 108.127 380.427 106.386C388.107 104.645 395.682 109.762 397.31 117.792C398.939 125.823 394.014 133.774 386.334 135.515ZM381.405 111.208C376.267 112.373 372.973 117.691 374.063 123.063C375.152 128.435 380.218 131.858 385.356 130.693C390.494 129.528 393.788 124.21 392.698 118.838C391.609 113.466 386.543 110.043 381.405 111.208Z"
				fill="black"
			/>
			<path
				d="M405.382 659.31L352.05 671.403C346.02 672.77 342.169 678.988 343.447 685.292L345.883 697.303C347.161 703.607 353.085 707.609 359.114 706.242L412.447 694.15C418.477 692.783 422.328 686.564 421.05 680.26L418.614 668.249C417.336 661.945 411.412 657.943 405.382 659.31Z"
				fill="black"
			/>
			<path
				d="M508.687 217.53L81.8514 319.334L142.448 581.537L570.774 479.779L556.836 423.403C524.243 434.009 511.528 422.938 504.462 355.406C486.86 300.92 494.244 287.059 523.256 277.654L508.687 217.53Z"
				fill="white"
			/>
			<path
				d="M576.627 487.436L136.494 586.291L74.8608 311.879L514.994 213.024L530.827 283.519L515.202 287.028C504.009 289.542 496.948 300.694 499.462 311.887L520.31 404.711C522.825 415.905 533.976 422.966 545.17 420.452L560.794 416.943L576.627 487.436ZM144.639 573.428L563.764 479.292L552.649 429.806L547.529 430.956C530.543 434.771 513.621 424.056 509.806 407.07L488.958 314.246C485.143 297.261 495.858 280.339 512.843 276.524L517.963 275.374L506.849 225.888L87.7243 320.024L144.639 573.428Z"
				fill="black"
			/>
			<path
				d="M342.161 333.213L267.038 350.846C264.299 351.489 261.559 349.791 260.916 347.052C260.273 344.313 261.972 341.574 264.71 340.931L339.834 323.297C342.573 322.655 345.312 324.353 345.955 327.092C346.598 329.83 344.9 332.57 342.161 333.213Z"
				fill="#1B1B19"
			/>
			<path
				d="M334.405 317.597L265.369 333.801C262.63 334.444 259.89 332.746 259.248 330.007C258.605 327.269 260.303 324.529 263.042 323.886L332.078 307.681C334.816 307.039 337.556 308.737 338.199 311.476C338.842 314.214 337.144 316.954 334.405 317.597Z"
				fill="#1B1B19"
			/>
			<path
				d="M291.927 310.131L277.982 313.404C275.243 314.047 272.503 312.349 271.861 309.61C271.218 306.871 272.916 304.132 275.655 303.489L289.6 300.215C292.339 299.573 295.079 301.271 295.722 304.009C296.364 306.748 294.666 309.488 291.927 310.131Z"
				fill="#1B1B19"
			/>
			<path
				d="M348.902 436.25L294.153 449.1C291.414 449.743 288.674 448.045 288.031 445.306C287.389 442.567 289.087 439.828 291.825 439.185L346.574 426.334C349.313 425.691 352.053 427.389 352.696 430.128C353.338 432.867 351.64 435.607 348.902 436.25Z"
				fill="#EAC827"
			/>
			<path
				d="M363.927 415.286L296.764 431.051C294.026 431.694 291.286 429.996 290.643 427.257C290 424.518 291.698 421.778 294.437 421.135L361.599 405.371C364.338 404.728 367.078 406.426 367.721 409.165C368.364 411.904 366.666 414.644 363.927 415.286Z"
				fill="#EAC827"
			/>
			<path
				d="M343.126 402.732L275.074 418.705C272.336 419.348 269.596 417.65 268.953 414.911C268.31 412.173 270.008 409.433 272.747 408.79L340.799 392.816C343.537 392.174 346.277 393.872 346.92 396.611C347.563 399.349 345.865 402.089 343.126 402.732Z"
				fill="#EAC827"
			/>
			<path
				d="M328.698 388.682L267.471 403.054C264.732 403.696 261.992 401.998 261.35 399.259C260.707 396.521 262.405 393.781 265.144 393.138L326.37 378.767C329.109 378.124 331.849 379.822 332.492 382.561C333.135 385.299 331.436 388.039 328.698 388.682Z"
				fill="#C53127"
			/>
			<path
				d="M361.95 363.44L260.626 387.224C257.887 387.867 255.147 386.168 254.504 383.43C253.861 380.691 255.56 377.951 258.298 377.308L359.623 353.525C362.362 352.882 365.102 354.58 365.744 357.319C366.387 360.058 364.689 362.798 361.95 363.44Z"
				fill="#C53127"
			/>
			<path
				d="M353.698 347.941L265.615 368.616C262.876 369.259 260.136 367.561 259.493 364.822C258.85 362.084 260.549 359.344 263.287 358.701L351.37 338.026C354.109 337.383 356.849 339.081 357.492 341.82C358.135 344.559 356.436 347.299 353.698 347.941Z"
				fill="#C53127"
			/>
			<path
				d="M242.446 521.473C240.956 521.823 240.021 521.192 239.643 519.579L233.312 492.607C233.155 491.938 233.223 491.345 233.519 490.825C233.814 490.306 234.311 489.965 235.012 489.8L242.451 488.054C244.642 487.54 246.694 487.395 248.608 487.62C250.523 487.844 252.247 488.452 253.778 489.441C255.311 490.429 256.64 491.819 257.766 493.609C258.892 495.398 259.767 497.618 260.388 500.263C261.002 502.881 261.21 505.272 261.012 507.437C260.814 509.601 260.259 511.506 259.343 513.149C258.429 514.792 257.172 516.17 255.57 517.284C253.971 518.399 252.076 519.213 249.885 519.727L242.446 521.473ZM244.749 515.492L249.04 514.484C251.533 513.899 253.289 512.557 254.306 510.455C255.321 508.356 255.386 505.42 254.5 501.645C253.643 497.995 252.313 495.539 250.509 494.276C248.702 493.015 246.552 492.678 244.059 493.263L239.768 494.27L244.749 515.492Z"
				fill="#1B1B19"
			/>
			<path
				d="M269.987 503.94C268.671 504.218 267.796 503.428 267.36 501.572C267.16 500.72 267.137 499.971 267.291 499.324C267.443 498.68 267.901 498.267 268.662 498.089L281.976 494.964C282.735 494.785 283.328 494.951 283.752 495.46C284.177 495.971 284.49 496.652 284.69 497.504C285.132 499.39 284.655 500.497 283.254 500.825L269.987 503.94Z"
				fill="#1B1B19"
			/>
			<path
				d="M303.176 504.859C303.34 505.559 303.276 506.207 302.982 506.806C302.69 507.405 302.011 507.829 300.947 508.079C298.998 508.536 297.826 507.913 297.426 506.209L291.738 481.975L284.8 483.603C284.008 483.789 283.387 483.67 282.935 483.246C282.482 482.823 282.171 482.247 281.999 481.516C281.828 480.787 281.858 480.129 282.09 479.545C282.322 478.961 282.787 478.586 283.488 478.422L303.614 473.698C304.313 473.534 304.895 473.655 305.361 474.059C305.824 474.463 306.142 475.031 306.313 475.762C306.485 476.491 306.471 477.154 306.273 477.745C306.075 478.338 305.565 478.729 304.743 478.922L297.487 480.625L303.176 504.859Z"
				fill="#1B1B19"
			/>
			<path
				d="M312.17 474.048C311.956 473.136 312.068 472.42 312.507 471.9C312.947 471.379 313.607 471.015 314.491 470.807C315.313 470.614 316.035 470.654 316.661 470.925C317.285 471.196 317.705 471.786 317.919 472.698L324.272 499.763C324.436 500.462 324.385 501.131 324.118 501.772C323.851 502.413 323.17 502.862 322.075 503.119C321.041 503.362 320.245 503.324 319.688 503.005C319.132 502.686 318.744 502.055 318.523 501.112L312.17 474.048Z"
				fill="#1B1B19"
			/>
			<path
				d="M345.502 498.101C343.038 498.68 340.869 498.707 338.996 498.185C337.124 497.66 335.513 496.747 334.164 495.442C332.815 494.138 331.702 492.546 330.828 490.664C329.952 488.782 329.269 486.793 328.776 484.694C328.254 482.472 328.008 480.292 328.035 478.149C328.062 476.008 328.434 474.035 329.149 472.231C329.865 470.425 330.976 468.864 332.481 467.549C333.984 466.232 335.954 465.288 338.389 464.717C340.305 464.267 342.099 464.215 343.768 464.562C345.439 464.908 346.931 465.488 348.246 466.303C348.847 466.677 349.425 467.118 349.978 467.63C350.532 468.142 350.88 468.703 351.023 469.311C351.23 470.193 351.086 470.918 350.594 471.483C350.101 472.048 349.518 472.409 348.849 472.566C348.331 472.688 347.854 472.63 347.413 472.397C346.974 472.163 346.491 471.884 345.965 471.558C345.551 471.301 345.121 471.042 344.674 470.778C344.226 470.514 343.743 470.297 343.223 470.131C342.702 469.963 342.131 469.858 341.509 469.811C340.888 469.764 340.214 469.826 339.483 469.998C338.114 470.319 337.037 470.892 336.252 471.719C335.466 472.545 334.9 473.545 334.548 474.719C334.198 475.893 334.054 477.194 334.117 478.625C334.179 480.054 334.386 481.514 334.736 483.005C335.1 484.557 335.575 486.003 336.163 487.341C336.75 488.68 337.454 489.799 338.275 490.697C339.095 491.597 340.026 492.244 341.067 492.642C342.106 493.04 343.25 493.093 344.498 492.801C345.229 492.629 345.848 492.395 346.357 492.099C346.865 491.804 347.303 491.451 347.673 491.042C348.042 490.636 348.374 490.197 348.664 489.727C348.956 489.257 349.23 488.752 349.488 488.21C349.683 487.811 349.931 487.359 350.23 486.856C350.528 486.352 350.982 486.029 351.59 485.886C352.291 485.722 352.979 485.818 353.656 486.172C354.333 486.527 354.766 487.099 354.951 487.891C355.223 489.047 354.927 490.385 354.062 491.904C353.674 492.571 353.232 493.222 352.739 493.851C352.243 494.482 351.66 495.068 350.984 495.611C350.31 496.154 349.523 496.636 348.627 497.056C347.729 497.474 346.689 497.823 345.502 498.101Z"
				fill="#1B1B19"
			/>
			<path
				d="M356.392 463.669C356.178 462.756 356.29 462.041 356.729 461.52C357.169 461 357.829 460.635 358.713 460.428C359.535 460.235 360.257 460.275 360.883 460.545C361.507 460.816 361.927 461.407 362.141 462.319L364.745 473.409L372.242 458.986C372.506 458.474 372.806 458.003 373.138 457.572C373.469 457.142 373.924 456.857 374.503 456.721C374.928 456.621 375.302 456.606 375.623 456.675C375.945 456.745 376.271 456.868 376.603 457.047C376.94 457.257 377.229 457.502 377.473 457.781C377.715 458.063 377.886 458.415 377.986 458.841C378.065 459.177 378.059 459.564 377.97 460C377.88 460.439 377.721 460.862 377.496 461.268L372.2 471.322L384.89 483.945C385.263 484.307 385.511 484.747 385.632 485.263C385.81 486.025 385.706 486.738 385.317 487.408C384.928 488.078 384.248 488.526 383.274 488.754C382.635 488.904 382.064 488.863 381.559 488.627C381.055 488.393 380.566 488.025 380.097 487.527L368.977 476.46L366.544 481.077L368.494 489.383C368.658 490.083 368.607 490.752 368.34 491.393C368.073 492.034 367.392 492.482 366.297 492.739C365.263 492.982 364.468 492.944 363.91 492.626C363.354 492.307 362.966 491.675 362.745 490.733L356.392 463.669Z"
				fill="#1B1B19"
			/>
			<path
				d="M394.516 485.778C393.026 486.128 392.091 485.497 391.713 483.885L385.382 456.912C385.225 456.243 385.293 455.65 385.589 455.13C385.884 454.611 386.381 454.27 387.082 454.106L402.325 450.528C403.846 450.171 404.814 450.876 405.228 452.64C405.414 453.431 405.403 454.107 405.198 454.669C404.993 455.231 404.496 455.605 403.704 455.791L391.838 458.576L393.659 466.334L404.384 463.816C405.845 463.474 406.772 464.14 407.165 465.811C407.351 466.603 407.344 467.295 407.146 467.887C406.948 468.48 406.438 468.871 405.616 469.064L394.891 471.581L396.766 479.568L408.861 476.729C409.499 476.579 410.035 476.712 410.469 477.123C410.902 477.534 411.216 478.151 411.409 478.973C411.595 479.764 411.586 480.447 411.384 481.024C411.183 481.601 410.671 481.986 409.85 482.179L394.516 485.778Z"
				fill="#1B1B19"
			/>
			<path
				d="M430.227 475.037C430.391 475.737 430.327 476.385 430.034 476.984C429.742 477.583 429.063 478.007 427.998 478.257C426.05 478.714 424.878 478.091 424.478 476.387L418.789 452.153L411.851 453.781C411.06 453.967 410.438 453.848 409.987 453.424C409.534 453.001 409.222 452.425 409.051 451.694C408.88 450.965 408.91 450.307 409.142 449.723C409.373 449.138 409.839 448.764 410.54 448.6L430.666 443.876C431.365 443.712 431.947 443.833 432.412 444.237C432.876 444.641 433.194 445.209 433.365 445.94C433.536 446.669 433.523 447.331 433.324 447.923C433.127 448.516 432.616 448.907 431.795 449.1L424.539 450.803L430.227 475.037Z"
				fill="#1B1B19"
			/>
			<path
				d="M479.395 469.046C478.43 469.273 477.464 468.675 477.237 467.711L472.082 445.812C471.855 444.848 472.453 443.881 473.418 443.654C474.382 443.427 475.349 444.025 475.576 444.99L480.73 466.889C480.957 467.853 480.359 468.819 479.395 469.046ZM469.086 425.25C468.121 425.477 467.155 424.879 466.928 423.914L461.774 402.016C461.547 401.051 462.145 400.085 463.109 399.858C464.074 399.631 465.04 400.229 465.267 401.193L470.421 423.092C470.648 424.057 470.05 425.023 469.086 425.25ZM458.777 381.453C457.812 381.68 456.846 381.082 456.619 380.118L451.465 358.219C451.238 357.254 451.836 356.288 452.8 356.061C453.765 355.834 454.731 356.432 454.958 357.397L460.112 379.295C460.339 380.26 459.742 381.226 458.777 381.453ZM448.468 337.657C447.504 337.884 446.538 337.286 446.31 336.321L441.156 314.422C440.929 313.458 441.527 312.492 442.491 312.265C443.456 312.038 444.422 312.636 444.649 313.6L449.804 335.499C450.031 336.463 449.433 337.43 448.468 337.657ZM438.159 293.86C437.195 294.087 436.229 293.489 436.002 292.525L430.847 270.626C430.62 269.661 431.218 268.695 432.183 268.468C433.147 268.241 434.113 268.839 434.34 269.804L439.495 291.702C439.722 292.667 439.124 293.633 438.159 293.86Z"
				fill="black"
			/>
			<g clip-path="url(#clip0_2471_3782)">
				<path
					d="M370.876 633.45C353.084 637.691 335.159 626.667 330.919 608.875C326.678 591.084 337.702 573.159 355.494 568.918C373.285 564.677 391.21 575.702 395.451 593.493C399.691 611.285 388.667 629.21 370.876 633.45Z"
					fill="#6FDB89"
				/>
				<path
					d="M362.012 617.403L344.308 604.273C343.388 603.591 343.195 602.291 343.878 601.37C344.561 600.45 345.86 600.257 346.781 600.94L360.848 611.372L376.889 585.291C377.49 584.314 378.768 584.01 379.744 584.61C380.72 585.21 381.025 586.489 380.424 587.465L362.012 617.403Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2471_3782">
					<rect
						width="80"
						height="80"
						fill="white"
						transform="translate(315 571.549) rotate(-13.407)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
