import { useMediaQuery } from '@mui/material';

export { useMediaQuery };

export const useBreakpoints = () => {
	const isMobile = useMediaQuery((t) => t.breakpoints.only('mobile'));
	const isAboveMobile = useMediaQuery((t) => t.breakpoints.up('tabletP'));

	return { isMobile, isAboveMobile };
};
