import { styled } from '@mui/material/styles';

export const Title = styled('div')(({ theme }) => ({
	display: 'grid',
	width: '80%',
	...theme.fonts.subtitle1,
	color: theme.colors.mopla_secondary_dark,
	marginTop: 14,
	marginBottom: 24,
}));

export const Text = styled('div')(({ theme }) => ({
	...theme.fonts.body1_semibold,
	color: theme.colors.mopla_secondary_dark,
	marginTop: 12,
	marginBottom: 16,
}));

export const TripCardRoot = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: string }>(({ theme, variant }) => ({
	backgroundColor: theme.colors.mopla_white,
	borderRadius: 8,
	marginBottom: 12,
	padding: variant === 'blue' ? '23px 8px' : '13px 12px',
	borderLeft: '12px solid',
	borderColor:
		(variant === 'secondary_dark' && theme.colors.mopla_secondary_dark) ||
		(variant === 'yellow' && theme.colors.driver_bright_yellow) ||
		(variant === 'grey' && theme.colors.mopla_grey) ||
		(variant === 'blue' && theme.colors.driver_light_blue) ||
		theme.colors.mopla_white,

	'&:last-of-type': {
		marginBottom: 32,
	},
}));

export const Row = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: string }>(({ theme, variant }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateColumns: 'max-content max-content',
	...theme.fonts.body2_bold,
	color:
		variant === 'grey' ? theme.colors.mopla_grey : theme.colors.mopla_black,

	'&:not(:last-of-type)': {
		marginBottom: 6,
	},

	'& > span:nth-of-type(2)': {
		marginLeft: 16,
	},

	'#city': {
		...theme.fonts.body2,
	},
}));
