import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISubscriptionOfferVoucher } from '@mopla/data-models';
import { FlexContainer, Text } from '@mopla/ui';
import { formatPrice } from '@mopla/utils';

import { BASIC_TICKET_COST } from './constants';
import { PriceSummaryContainer } from './DTicketSubscription.styles';

interface IProps {
	regularPrice?: number;
	voucher?: ISubscriptionOfferVoucher;
	isJobticket: boolean;
}

export const PriceInfoBlock: FC<IProps> = (props) => {
	const { regularPrice, voucher, isJobticket } = props;
	const { t } = useTranslation(['dticket']);

	const ticketPrice = regularPrice ?? BASIC_TICKET_COST;
	const priceFontStyle = isJobticket ? 'body1_semibold' : 'body3_semibold';

	return (
		<PriceSummaryContainer>
			{voucher && !isJobticket && (
				<FlexContainer className="space-between">
					<Text variant="body1_semibold" color="mopla_white">
						{t('label.price_per_month_with_discount', {
							discount: voucher.voucherDeductionAmount,
						})}
					</Text>
					<Text variant="body1_semibold" color="mopla_white">
						{t('text.price_per_month_with_discount', {
							price: formatPrice(ticketPrice - voucher.voucherDeductionAmount),
						})}
					</Text>
				</FlexContainer>
			)}
			<FlexContainer className="space-between">
				<Text variant={priceFontStyle} color="mopla_white">
					{t('label.price_per_month')}
				</Text>
				<Text variant={priceFontStyle} color="mopla_white">
					{t('text.price_per_month', {
						price: formatPrice(ticketPrice),
					})}
				</Text>
			</FlexContainer>
		</PriceSummaryContainer>
	);
};
