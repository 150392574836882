import React from 'react';
export const GoogleIcon = () => {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.64 9.75015C17.64 9.11197 17.5827 8.49833 17.4764 7.90924H9V11.3906H13.8436C13.635 12.5156 13.0009 13.4688 12.0477 14.107V16.3652H14.9564C16.6582 14.7983 17.64 12.4911 17.64 9.75015Z"
				fill="#4285F4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 18.5456C11.43 18.5456 13.4673 17.7397 14.9564 16.3651L12.0477 14.1069C11.2418 14.6469 10.2109 14.966 9 14.966C6.65591 14.966 4.67182 13.3829 3.96409 11.2556H0.957275V13.5874C2.43818 16.5288 5.48182 18.5456 9 18.5456Z"
				fill="#34A853"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.96409 11.2556C3.78409 10.7156 3.68182 10.1388 3.68182 9.5456C3.68182 8.95242 3.78409 8.3756 3.96409 7.8356V5.50378H0.957273C0.347727 6.71878 0 8.09333 0 9.5456C0 10.9979 0.347727 12.3724 0.957273 13.5874L3.96409 11.2556Z"
				fill="#FBBC05"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 4.12514C10.3214 4.12514 11.5077 4.57923 12.4405 5.47105L15.0218 2.88968C13.4632 1.43741 11.4259 0.545593 9 0.545593C5.48182 0.545593 2.43818 2.56241 0.957275 5.50378L3.96409 7.83559C4.67182 5.70832 6.65591 4.12514 9 4.12514Z"
				fill="#EA4335"
			/>
		</svg>
	);
};
