import React from 'react';

export const AddImagePlus = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13 7V6H11V7V11H7H6V13H7H11V17V18H13V17V13H17H18V11H17H13V7Z"
				fill="#1C308B"
			/>
		</svg>
	);
};
