import React from 'react';

export const MailIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<g clipPath="url(#a)">
				<path
					fill="currentColor"
					d="M22 20a1 1 0 01-1 1H3a1 1 0 01-1-1 1 1 0 011-1h16a1 1 0 001-1V7.3l-7.33 6.6a1 1 0 01-1.34 0l-9-8.1A1 1 0 012 5.05V4a1 1 0 011-1h18a1 1 0 011 1v16zM4.43 5L12 11.81 19.57 5H4.43zM0 16a1 1 0 011-1h6a1 1 0 110 2H1a1 1 0 01-1-1zm0-5a1 1 0 011-1h3a1 1 0 110 2H1a1 1 0 01-1-1z"
				></path>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h24v24H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
};
