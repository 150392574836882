/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GtfsTripStopView } from './GtfsTripStopView';
import type { GtfsTripValidityView } from './GtfsTripValidityView';

/**
 * MasterViewItem for Gtfs, used in distributor gtfs file upload
 */
export type GtfsTripMasterViewItem = {
	/**
	 * Agency Name
	 */
	agencyName?: string;
	/**
	 * Route Name
	 */
	routeName?: string;
	/**
	 * Trip Name
	 */
	tripName?: string;
	start?: GtfsTripStopView;
	destination?: GtfsTripStopView;
	validity?: GtfsTripValidityView;
	/**
	 * Timezone
	 */
	timeZone?: string;
	type?: GtfsTripMasterViewItem.type;
};

export namespace GtfsTripMasterViewItem {
	export enum type {
		WALK = 'WALK',
		LBT = 'LBT',
		LBODT = 'LBODT',
		LBODTFLEX = 'LBODTFLEX',
		OTHERS = 'OTHERS',
	}
}
