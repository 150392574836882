/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingId } from '../models/BookingId';
import type { BookingStatus } from '../models/BookingStatus';
import type { Itinerary } from '../models/Itinerary';
import type { NamedLocation } from '../models/NamedLocation';
import type { PassengerDetails } from '../models/PassengerDetails';
import type { PaymentAmount } from '../models/PaymentAmount';
import type { PaymentForm } from '../models/PaymentForm';
import type { SpecialNeeds } from '../models/SpecialNeeds';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PassengersService {
	/**
	 * get all saved locations
	 * request saved locations for given user context
	 * @returns NamedLocation OK
	 * @throws ApiError
	 */
	public static getApiPassengersLocations(): CancelablePromise<
		Array<NamedLocation>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/passengers/locations',
		});
	}

	/**
	 * get saved location
	 * request saved location
	 * @param uuid uuid of location to load
	 * @returns NamedLocation OK
	 * @throws ApiError
	 */
	public static getApiPassengersLocations1(
		uuid: string
	): CancelablePromise<NamedLocation> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/passengers/locations/{uuid}',
			path: {
				uuid: uuid,
			},
			errors: {
				404: `Not Found`,
			},
		});
	}

	/**
	 * Create Passenger
	 * Bearer token is set, but is checked outside of our normal auth context, as there is still no user created in our DB
	 * @param requestBody provide id from user account of google authentication provider to create passenger account
	 * @returns any passenger was verified against firebase and create on server
	 * @throws ApiError
	 */
	public static postApiPassengersCreate(requestBody: {
		email: string;
	}): CancelablePromise<{
		/**
		 * the servers id for the created passenger
		 */
		createdUser?: string;
	}> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/passengers/create',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad request, e.g. email missing`,
				403: `Access token was not successfully confirmed by authentication provider`,
				404: `User with given email was not found at identity provider`,
				409: `Passenger already exists, can't create twice`,
				422: `UID/Mail combination was not confirmed by authentication provider`,
				500: `firebase throws an exception, no connection possible etc`,
			},
		});
	}

	/**
	 * Delete Passenger
	 * Bearer token is used, to delete assigned (logged-in) passenger (user). There is no request body.
	 * @returns void
	 * @throws ApiError
	 */
	public static postApiPassengersDelete(): CancelablePromise<void> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/passengers/delete',
			errors: {
				400: `Bad request`,
				403: `Access token was not successfully confirmed by authentication provider`,
				404: `User with given email was not found at identity provider`,
				409: `Passenger has wrong configuration or planned bookings are still open`,
				500: `firebase throws an exception, no connection possible etc`,
			},
		});
	}

	/**
	 * passengers upcoming / booked journeys
	 * list of all upcoming journeys of passenger
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiPassengersBookings(): CancelablePromise<
		Array<{
			passengerList?: PassengerDetails;
			/**
			 * number of passengers for the journey
			 */
			passengers?: number;
			bookingNumber?: BookingId;
			specialNeeds?: SpecialNeeds;
			itinerary?: Itinerary;
			paymentForm?: PaymentForm;
			paymentAmount?: PaymentAmount;
			bookingStatus?: BookingStatus;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/passengers/bookings',
		});
	}

	/**
	 * get new Stripe intent ID for payment purposes
	 * Stripe intent ID is needed to process adding payment methods by passenger
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiPassengersIntent(): CancelablePromise<{
		/**
		 * Stripe customer ID
		 */
		stripeCustomerId?: string;
		/**
		 * Stripe ephemeral key secret
		 */
		stripeEphemeralKeySecret?: string;
		/**
		 * Stripe setup intent client secret
		 */
		stripeSetupIntentClientSecret?: string;
		/**
		 * Stripe public key for our system
		 */
		pkKey?: string;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/passengers/intent',
		});
	}

	/**
	 * get default payment method
	 * get default payment method of a passenger
	 * (check https://stripe.com/docs/api/payment_methods/retrieve for documentation about the response schema
	 * (can't reference stripe openapi specs because it's too large))
	 *
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiPassengersDefaultPaymentMethod(): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/passengers/defaultPaymentMethod',
			errors: {
				403: `forbidden, no access`,
				404: `no default payment method found or passenger has no stripe customer id`,
			},
		});
	}
}
