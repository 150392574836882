import { styled } from '@mui/material/styles';

export const RootMobile = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{
	variant:
		| 'mopla_white'
		| 'mopla_secondary'
		| 'mopla_primary'
		| 'mopla_secondary_dark';
}>(({ theme, variant }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	placeContent: 'center',
	placeItems: 'center',
	width: '100%',
	height: '100%',
	boxShadow: '0px 3px 40px rgba(0, 0, 0, 0.11)',
	zIndex: 1,
	padding: '26px 20px',
	boxSizing: 'border-box',

	button: {
		color:
			((variant === 'mopla_white' || variant === 'mopla_primary') &&
				theme.colors.mopla_secondary) ||
			theme.colors.mopla_white,
	},
}));

export const RootDesktop = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',

	background: theme.colors.mopla_white,

	padding: '16px 24px',
	boxSizing: 'border-box',

	button: {
		color: theme.colors.mopla_secondary,
	},
}));
