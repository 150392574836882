import * as yup from 'yup';

const PersonalDetailsValidationSchema = yup.object().shape({
	firstName: yup
		.string()
		.required('firstName.required')
		.min(2, 'firstName.min'),
	surname: yup.string().required('surname.required').min(2, 'surname.min'),
	phoneNumber: yup
		.string()
		.required('phoneNumber.required')
		.max(20, 'phoneNumber.max'),
	street: yup.string().required('street.required'),
	streetNumber: yup.string().required('streetNumber.required'),
	postCode: yup
		.string()
		.required('postCode.required')
		.matches(/[0-9]{4,5}/i, { message: 'postCode.incorrect' })
		.min(4, 'postCode.incorrect')
		.max(5, 'postCode.incorrect'),
	location: yup.string().required('location.required'),
	day: yup.string().required('day.required'),
	month: yup.string().required('month.required'),
	year: yup.string().required('year.required'),
});

export default PersonalDetailsValidationSchema;
