import { FC, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Button, CompleteAnim, Modal, Text } from '../../';

import {
	ModalContent,
	ModalFooter,
	ModalTitle,
	RedeemSuccessAnimWrapper,
} from './Vouchers.styles';

export interface IRedeemVoucherSuccessModalProps {
	isOpened: boolean;
	hasSubscription?: boolean;
	onDone: VoidFunction;
	onCreateSubscription: VoidFunction;
}

export const RedeemVoucherSuccessModal: FC<IRedeemVoucherSuccessModalProps> = ({
	hasSubscription,
	isOpened,
	onCreateSubscription,
	onDone,
}) => {
	const { t } = useTranslation('profile');
	const [rootNode] = useState(document.getElementById('portal'));

	const { mainContent, actionsBlock } = useMemo(() => {
		let mainContent = t('vouchers.modal.success_message_no_subscr');
		let actionsBlock = (
			<>
				<Button onClick={onCreateSubscription}>
					{t('vouchers.modal.create_subscription_btn')}
				</Button>
				<Button variant="text" onClick={onDone}>
					{t('vouchers.modal.later_btn')}
				</Button>
			</>
		);

		if (hasSubscription) {
			mainContent = t('vouchers.modal.success_message_has_subscr');
			actionsBlock = (
				<Button onClick={onDone}>{t('vouchers.modal.done_btn')}</Button>
			);
		}

		return { mainContent, actionsBlock };
	}, [hasSubscription, onCreateSubscription, onDone, t]);

	if (rootNode === null) {
		return null;
	}

	return ReactDOM.createPortal(
		<Modal
			backgroundColor="white"
			open={isOpened}
			animationElement={
				<RedeemSuccessAnimWrapper>
					<CompleteAnim />
				</RedeemSuccessAnimWrapper>
			}
			onClose={() => null}
			titleComp={
				<ModalTitle>
					<Text variant="H6">{t('vouchers.modal.success_title')}</Text>
				</ModalTitle>
			}
			mainContent={
				<ModalContent data-testid="voucher_form_success_modal">
					<Text variant="body1">{mainContent}</Text>
				</ModalContent>
			}
			footerComp={<ModalFooter>{actionsBlock}</ModalFooter>}
		/>,
		rootNode
	);
};
