/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * used in distributor dashboard for better understanding of scheduled leg state and being immutable or not
 */
export enum ScheduledLegStatus {
	PLANNED = 'PLANNED',
	PLANNED_IMMUTABLE = 'PLANNED_IMMUTABLE',
	EXECUTING = 'EXECUTING',
	FINISHED = 'FINISHED',
}
