import { UseFormReturn } from 'react-hook-form';

import { DiscountState, Itinerary } from '@mopla/data-models';

export interface BookingHandlers {
	goBack: () => void;
	title: string;
}

export interface BookingFlowProps {
	onClose: () => void;
	activeRide?: Itinerary | null;
	askPermissions: () => void;
}

export interface IPassengerPaymentInfo {
	amountToPay: number | null;
	currency: string | null;
	discountState: DiscountState | null;
}

export interface IBookingFormInput {
	buyFor: BuyFor;
	name: string;
	surname: string;
	phoneNumber: string;
	email: string;
	main: IPassengerPaymentInfo;
	attendees2: IPassengerPaymentInfo[];
	bulkyLuggage: boolean;
	toddlerSeats: number;
}

export enum BuyFor {
	me = 'me',
	anotherPerson = 'anotherPerson',
}

export interface IPersistStateLS {
	persistenceId: string;
	activeRide: BookingFlowProps['activeRide'];
	buyFor: string;
	step: EFlowSteps;
	form: TBookingFormState;
	steps: IFlowStep[];
}

export type TBookingFormState = IBookingFormInput;

/** TODO {attendees2} name was set during the development, when {attendees} existed.
 * Number 2 doesn't mean anything. It can be changed */
export type TAttendeeFormPath = `attendees2.${number}`;
type TMainPassengerFormPath = 'main';
export type TPassengersFormPath = TAttendeeFormPath | TMainPassengerFormPath;

export enum EFlowSteps {
	SelectPassengers = 'SelectPassengers',
	PriceCalcPassengerMain = 'PriceCalcPassengerMain',
	PriceCalcPassenger1 = 'PriceCalcPassenger1',
	PriceCalcPassenger2 = 'PriceCalcPassenger2',
	PriceCalcPassenger3 = 'PriceCalcPassenger3',
	PriceCalcPassenger4 = 'PriceCalcPassenger4',
	PriceCalcPassenger5 = 'PriceCalcPassenger5',
	PriceCalcPassenger6 = 'PriceCalcPassenger6',
	AddPaymentMethod = 'AddPaymentMethod',
	Summary = 'Summary',
	Thanks = 'Thanks',
}

interface IBaseFlowStep {
	step: EFlowSteps;
	index: number;
	layout?: JSX.Element;
}

interface IAttendeeFlowStep extends IBaseFlowStep {
	isAttendee: true;
	attendeeIndex: number;
}

interface IRegularFlowStep extends IBaseFlowStep {
	isAttendee?: false;
	attendeeIndex?: undefined;
}

export type IFlowStep = IAttendeeFlowStep | IRegularFlowStep;

export type TFormContext = UseFormReturn<IBookingFormInput>;
