import { styled } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';

export const SwiperWrapper = styled(Swiper)(({ theme }) => ({
	width: '100%',
	height: '100%',

	// activate scroll functionality for swiper container
	// to avoid overlap with vertical scroll on container on mobile devices
	overflow: 'auto',
	// hide scrollbar
	'-ms-overflow-style': 'none' /* IE and Edge */,
	'scrollbar-width': 'none' /* Firefox */,
	'&::-webkit-scrollbar': {
		display: 'none', //rest browsers
	},

	[theme.breakpoints.up('tabletP')]: {
		overflow: 'hidden',
	},
}));

export const Slide = styled(SwiperSlide)(() => ({
	width: '330px !important',
	'@media (min-width: 601px)': {
		width: '600px !important',
	},
	cursor: 'grab',
}));
