import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	gap: 16,
	alignItems: 'baseline',

	'& .status': {
		whiteSpace: 'nowrap',
	},

	'& .payment': {
		textAlign: 'right',
	},
}));
