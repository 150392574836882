import React from 'react';

export const ChangeIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.2427 8V13C18.2427 13.5523 17.795 14 17.2427 14H12.2427V12H14.5858L13.4142 10.8284C12.6332 10.0474 11.3668 10.0474 10.5858 10.8284L7.70711 13.7071L7 14.4142L5.58579 13L6.29289 12.2929L9.17157 9.41421C10.7337 7.85212 13.2663 7.85211 14.8284 9.41421L16.2427 10.8285V8H18.2427ZM4 18C5.10457 18 6 17.1046 6 16C6 14.8954 5.10457 14 4 14C2.89543 14 2 14.8954 2 16C2 17.1046 2.89543 18 4 18ZM22 16C22 17.1046 21.1046 18 20 18C18.8954 18 18 17.1046 18 16C18 14.8954 18.8954 14 20 14C21.1046 14 22 14.8954 22 16Z"
				fill="#1C308B"
			/>
		</svg>
	);
};
