import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';

import { useUser } from '@mopla/business-logic';
import { TGermanyTicketType } from '@mopla/data-models';
import { ArrowRightIcon, Button, FlexColumn, Text, Ticket } from '@mopla/ui';
import { getIsJobticketByType } from '@mopla/utils';

import {
	TicketContentBlock,
	TicketSubmitWrapper,
} from './DTicketTicketView.styles';

interface IProps {
	onSubmit: VoidFunction;
	dTicketType: TGermanyTicketType;
	monthOfStart: string;
}

export const DTicketTicketView: React.FC<IProps> = (props) => {
	const { onSubmit, dTicketType, monthOfStart } = props;

	const { t } = useTranslation(['dticket']);
	const { userData: user } = useUser();

	const isJobticket = getIsJobticketByType(dTicketType);

	const TicketHeader = (
		<Text variant="body1_semibold">{t('subscription_ticket.header')}</Text>
	);

	const TicketContent = (
		<TicketContentBlock>
			{user === null ? (
				<CircularProgress />
			) : (
				<FlexColumn className="space-between">
					<Text variant="subtitle1">{dTicketType}</Text>
					<Text variant="body1">
						{t('subscription_ticket.from_date', {
							startDate: monthOfStart
								? dayjs(monthOfStart).format('01.MM.YYYY')
								: '',
						})}
					</Text>
					<Text variant="body1">
						{t('subscription_ticket.for_subscriber', {
							subscriber: user
								? isJobticket
									? `${user.metaData?.jobticketFirstName} ${user.metaData?.jobticketLastName}`
									: `${user.firstName} ${user.lastName}`
								: '',
						})}
					</Text>
				</FlexColumn>
			)}
			<Text variant="body3" color="mopla_dark_grey">
				{t('subscription_ticket.validation')}
			</Text>
			<Text variant="body3" color="mopla_dark_grey">
				{t('subscription_ticket.source_of_purchase')}
			</Text>
		</TicketContentBlock>
	);

	const TicketFooter = (
		<TicketSubmitWrapper>
			<Button
				icon={<ArrowRightIcon />}
				variant="text"
				color="secondary"
				onClick={onSubmit}
			>
				{t('subscription_ticket.submit_button')}
			</Button>
		</TicketSubmitWrapper>
	);

	return (
		<Ticket
			header={TicketHeader}
			content={TicketContent}
			footer={TicketFooter}
		/>
	);
};
