import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atoms/Button/Button';
import { ArrowRightIcon } from '../../icons';

import { ClockMoneyIconStyled, ContentStyled } from './styled';

export interface ICheaperRidesLoaderProps {
	onClick(): void;
	isLoading?: boolean;
}

export const CheaperRidesButton: FC<ICheaperRidesLoaderProps> = (props) => {
	const { onClick, isLoading } = props;
	const { t } = useTranslation('booking');

	return (
		<Button
			onClick={onClick}
			icon={<ArrowRightIcon />}
			iconPosition="right"
			showLoader={isLoading}
		>
			<ContentStyled>
				<ClockMoneyIconStyled />
				{t('button.cheaper_ride_result')}
			</ContentStyled>
		</Button>
	);
};
