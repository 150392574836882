import React, { FC } from 'react';

import { Booking } from '@mopla/data-models';

import {
	PassCount,
	SpecialNeedsInfo,
	WithSpecialNeeds,
} from './DriverTripDescription.styles';

export interface ILegPassCountInfoProps {
	bookings: Booking[];
	isEntering?: boolean;
}

export const LegPassCountInfo: FC<ILegPassCountInfoProps> = ({
	bookings,
	isEntering,
}) => {
	const specialNeedsCount = bookings.filter(
		(booking: Booking) => booking.specialNeeds !== 'NONE'
	).length;

	const withoutSpecialNeedsCount = bookings.length - specialNeedsCount;

	return (
		<>
			{!!specialNeedsCount && (
				<WithSpecialNeeds data-testid="trip-description-specialNeeds-count">
					<PassCount entering={isEntering}>
						<span>{`${isEntering ? '+' : '-'}${specialNeedsCount}`}</span>
					</PassCount>
					<SpecialNeedsInfo>Besondere Bedürfnisse</SpecialNeedsInfo>
				</WithSpecialNeeds>
			)}
			{!!withoutSpecialNeedsCount && (
				<PassCount
					entering={isEntering}
					data-testid="trip-description-without-specialNeeds-count"
				>
					<span>{`${isEntering ? '+' : '-'}${withoutSpecialNeedsCount}`}</span>
				</PassCount>
			)}
		</>
	);
};
