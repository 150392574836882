import React from 'react';

export const TicketIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 10L4.82843 9.17157C5.57857 9.92172 6 10.9391 6 12C6 13.0609 5.57857 14.0783 4.82843 14.8284C4.57908 15.0778 4.30019 15.2908 4 15.4641L4 17H20V15.4641C19.6998 15.2908 19.4209 15.0778 19.1716 14.8284C18.4214 14.0783 18 13.0609 18 12C18 10.9391 18.4214 9.92172 19.1716 9.17157L20 10L19.1716 9.17157C19.4209 8.92222 19.6998 8.70919 20 8.53589V7L4 7V8.53589C4.30019 8.70919 4.57908 8.92222 4.82843 9.17157L4 10ZM2 10C2.53043 10 3.03914 10.2107 3.41421 10.5858C3.78929 10.9609 4 11.4696 4 12C4 12.5304 3.78929 13.0391 3.41421 13.4142C3.03914 13.7893 2.53043 14 2 14V16V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V16V14C21.4696 14 20.9609 13.7893 20.5858 13.4142C20.2107 13.0391 20 12.5304 20 12C20 11.4696 20.2107 10.9609 20.5858 10.5858C20.9609 10.2107 21.4696 10 22 10V8V7C22 5.89543 21.1046 5 20 5H4C2.89543 5 2 5.89543 2 7V8V10Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default TicketIcon;
