import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TMoplaColors } from '@mopla/mui';

export const MainWrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'stack',
})<{ stack: boolean | undefined }>(({ theme, stack }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	backgroundColor: !stack ? theme.palette.error.main : 'transparent',
	borderRadius: 8,
	[theme.breakpoints.up('tabletP')]: {
		width: '100%',
		maxWidth: 400,
		justifySelf: 'center',
		height: 'max-content',
	},
}));

export const Root = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content max-content',
	width: '100%',
	height: 'max-content',
	boxSizing: 'border-box',
	maxWidth: 450,
	alignSelf: 'center',
	marginTop: 'auto',
	position: 'relative',
	justifySelf: 'center',
}));

export const Title = styled('div')(() => ({
	display: 'grid',
	width: '100%',
	height: '50px',
	boxSizing: 'border-box',
}));

export const FirstTicket = styled('div', {
	shouldForwardProp: (prop) => prop !== 'rightSwipeX',
})<{ rightSwipeX: number }>(({ theme, rightSwipeX }) => ({
	display: 'grid',
	flexDirection: 'column',
	alignItems: 'flex-end',
	background: theme.colors.mopla_white,
	width: '100%',
	height: '100%',
	padding: 16,
	boxSizing: 'border-box',
	borderRadius: 8,
	position: 'relative',
	right: `${rightSwipeX}px`,
}));

export const SecondTicket = styled('div', {
	shouldForwardProp: (prop) => prop !== 'showData',
})<{ showData: boolean }>(({ theme, showData }) => ({
	...theme.fonts.subtitle1,
	fontFamily: '"Palanquin", sans-serif',
	fontSize: 18,
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	justifyContent: 'space-between',
	background: theme.colors.mopla_white,
	width: 'calc(100% - 32px)',
	height: showData ? 38 : 12,
	boxSizing: 'border-box',
	opacity: '.7',
	borderRadius: '8px 8px 0 0',
	justifySelf: 'center',
	padding: '13px 13px 0 13px',
	overflow: 'hidden',
}));

export const ThirdTicket = styled('div', {
	shouldForwardProp: (prop) => prop !== 'showData',
})<{ showData: boolean }>(({ theme, showData }) => ({
	...theme.fonts.subtitle1,
	fontFamily: '"Palanquin", sans-serif',
	fontSize: 16,
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	justifyContent: 'space-between',
	background: theme.colors.mopla_white,
	width: 'calc(100% - 64px)',
	height: showData ? 32 : 12,
	boxSizing: 'border-box',
	opacity: '.3',
	borderRadius: '8px 8px 0 0',
	justifySelf: 'center',
	padding: '12px 12px 0 12px',
	overflow: 'hidden',
}));

export const TitleWrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'withMargin',
})<{ withMargin: boolean }>(({ withMargin }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content',
	gridTemplateRows: 'max-content',
	marginBottom: withMargin ? 18 : 0,
}));

export const TimeWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
}));

export const TitleTime = styled('div', {
	shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: TMoplaColors }>(({ theme, color = 'mopla_black' }) => ({
	...theme.fonts.subtitle1,
	color: theme.colors[color],
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

export const SubtitleTime = styled('div')(({ theme }) => ({
	...theme.fonts.body3,
	color: theme.colors.mopla_dark_grey,
	textDecoration: 'line-through',
}));

export const WalkWrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'bottom',
})<{ bottom?: boolean }>(({ theme, bottom }) => ({
	...theme.fonts.body2,
	color: theme.colors.mopla_dark_grey,
	display: 'grid',
	gridTemplateColumns: '60px 1fr',
	justifyContent: 'start',
	margin: bottom ? '5px 0 0 0' : '0 0 5px 0',
}));

export const StationWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '80px max-content 1fr',
	justifyContent: 'start',
}));

export const StationTime = styled('span')(({ theme }) => ({
	...theme.fonts.body1_semibold,
}));

export const StationName = styled('span')(({ theme }) => ({
	...theme.fonts.body1_semibold,
	marginLeft: 10,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

export const RouteLine = styled('div')(() => ({
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gridTemplateColumns: 'max-content',
	gridGap: 2,
	width: 'max-content',
	margin: '5px 0 5px 91px',
}));

export const RouteDot = styled('div')(({ theme }) => ({
	height: 4,
	width: 2,
	borderRadius: 2,
	background: theme.colors.mopla_grey,
}));

export const Separator = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr max-content',
	height: 20,
	width: 'calc(100% + 32px)',
	margin: '8px 0px 4px -16px',
}));

export const Dash = styled('div')(({ theme }) => ({
	height: 0,
	width: '100%',
	border: `1px dashed ${theme.colors.mopla_secondary}`,
	alignSelf: 'center',

	[theme.breakpoints.up('tabletP')]: {
		border: `1px dashed ${theme.colors.driver_light_grey}`,
	},
}));

export const SideCircle = styled('div', {
	shouldForwardProp: (prop) => prop !== 'left' && prop !== 'showDelete',
})<{ left?: boolean; showDelete?: boolean }>(({ theme, left, showDelete }) => ({
	background: !left ? 'transparent' : theme.colors.mopla_secondary,
	height: '20px',
	boxSizing: 'border-box',
	width: '20px',
	borderRadius: '100%',
	borderLeftColor:
		!left && showDelete
			? theme.palette.error.main
			: theme.colors.mopla_secondary,
	borderWidth: '10px',
	borderStyle: 'solid',
	borderTopWidth: '0px',
	borderBottomWidth: 0,
	borderRightWidth: 0,
	margin: left ? '0 0 0 -10px' : '0 -10px 0 0',

	[theme.breakpoints.up('tabletP')]: {
		background: !left ? 'transparent' : theme.colors.driver_light_grey,
		borderLeftColor:
			!left && showDelete
				? theme.palette.error.main
				: theme.colors.driver_light_grey,
	},
}));

export const ShowTicketButton = styled('button')(({ theme }) => ({
	background: 'transparent',
	cursor: 'pointer',
	color: theme.colors.mopla_secondary,
	outline: 'none',
	border: 'none',
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	justifySelf: 'end',
	padding: 0,
	width: 'max-content',
	span: {
		...theme.fonts.body1_semibold,
		marginRight: 12,
	},

	'&:disabled': {
		color: theme.colors.mopla_dark_grey,
	},
}));

export const TrashWrapper = styled('div')<{ revealed: boolean }>(
	({ revealed }) => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '85px',
		height: '100%',
		position: 'absolute',
		right: 0,
		transition: 'all .3s ease',
		zIndex: revealed ? 2 : 0,
		color: 'white',
	})
);

export const ButtonsWrapper = styled('div')(() => ({
	'button:first-of-type': {
		marginBottom: 16,
		marginTop: 38,
	},
}));

export const ModalTitle = styled('span')(({ theme }) => ({
	...theme.fonts.H6,
	marginTop: 40,
	marginBottom: 24,
}));

export const Person = styled('span')(({ theme }) => ({
	...theme.fonts.body1_semibold,
}));

export const CancelledRideChip = styled(Chip)(({ theme }) => ({
	...theme.fonts.caption_semibold,
	color: theme.colors.mopla_white,
	fontWeight: 700,
	background: '#DD6060',
	textTransform: 'uppercase',
}));
