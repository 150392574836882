import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Portal } from '@mui/material';
import * as Sentry from '@sentry/capacitor';
import { Transaction } from '@sentry/types';

import { BusinessLayerContext } from '@mopla/business-logic';
import {
	setDefaultPaymentMethod,
	SetDefaultPaymentMethodResultAction,
	useActionNotification,
	UserActionTypes,
} from '@mopla/business-logic';
import { StripeSetupIntentData } from '@mopla/data-models';
import { PaymentWrapper } from '@mopla/ui/layouts/Payment/Payment';
import { appendSearchParams } from '@mopla/utils';

import { environment } from '../../../environments/environment';

interface IProps {
	onUserHasDefaultPaymentMethod: VoidFunction;
	startLoader: VoidFunction;
	stopLoader: VoidFunction;
	onClose: VoidFunction;
	stripeRedirectUrlWeb: string;
}

export const PaymentPortal: React.FC<IProps> = (props) => {
	const {
		onUserHasDefaultPaymentMethod,
		startLoader,
		stopLoader,
		onClose,
		stripeRedirectUrlWeb,
	} = props;
	const [clientSecret, setClientSecret] = useState<string>();
	const [error, setError] = useState<string>();
	const [query, setQuery] = useSearchParams();
	const [hasInitiated, setHasInitiated] = useState(false);
	const waitForPaymentMethod = useActionNotification(
		UserActionTypes.SetDefaultPaymentMethodResult
	);
	const businessLayer = useContext(BusinessLayerContext);
	const passApi = businessLayer.api;

	const storeDefaultPaymentMethod = useCallback(
		(paymentMethodId: string | undefined) => {
			businessLayer.dispatch(setDefaultPaymentMethod({ paymentMethodId }));
		},
		[businessLayer]
	);

	useEffect(() => {
		if (!query.has('isPaymentRedirect')) {
			return;
		}

		const redirectStatus = query.get('redirect_status');

		if (redirectStatus === 'failed') {
			setError('generic');
		}

		query.delete('isPaymentRedirect');
		query.delete('redirect_status');
		setQuery(query);
	}, []);

	useEffect(() => {
		const initiatePayment = async (transaction: Transaction) => {
			startLoader();
			try {
				const span = transaction.startChild({
					op: 'getSetupIntent',
				});
				const response = await passApi.get<StripeSetupIntentData>(
					'/api/passengers/intent'
				);
				span.finish();
				const isWeb = Capacitor.getPlatform() === 'web';
				if (isWeb) {
					setClientSecret(response.stripeSetupIntentClientSecret);
				} else {
					const callback = environment.stripeCustomCloseCallback;
					const url = appendSearchParams(environment.paymentBaseUrl, {
						clientSecret: response.stripeSetupIntentClientSecret,
						closeCallback: callback,
					});

					await Browser.open({ url });
				}
			} catch (err) {
				Sentry.captureException(err);
				console.error(err);
			}
			stopLoader();
		};

		if (!hasInitiated) {
			const transaction = Sentry.startTransaction(
				{
					name: 'addPaymentMethod',
				},
				{
					isPayment: true,
				}
			);
			Sentry.getCurrentHub().configureScope((scope) =>
				scope.setSpan(transaction)
			);
			initiatePayment(transaction);
			setHasInitiated(true);
		}
	}, [
		startLoader,
		stopLoader,
		hasInitiated,
		waitForPaymentMethod,
		passApi,
		onClose,
		onUserHasDefaultPaymentMethod,
	]);

	useEffect(() => {
		let isActive = true;
		waitForPaymentMethod(
			(action: SetDefaultPaymentMethodResultAction) => {
				if (action.payload?.error) {
					onClose();
				} else {
					onUserHasDefaultPaymentMethod();
				}
				const transaction = Sentry.getCurrentHub().getScope()?.getTransaction();
				transaction?.finish();
			},
			() => isActive
		);
		return () => {
			isActive = false;
		};
	}, [onClose, onUserHasDefaultPaymentMethod, waitForPaymentMethod]);

	/** This redirect_url is used for WEB only */
	const stripeRedirectUrl = useMemo(() => {
		const url = new URL(
			`${environment.stripeWebCallback}${stripeRedirectUrlWeb}`
		);

		url.searchParams.append('isPaymentRedirect', 'true');

		return url.toString();
	}, [stripeRedirectUrlWeb]);

	// we return null in case of native environment as it will
	// open the inapp browser
	if (!clientSecret) return null;

	return (
		<Portal>
			<PaymentWrapper
				_error={error}
				clientSecret={clientSecret}
				onClose={(paymentMethodId) => {
					Sentry.addBreadcrumb({
						category: 'payment',
						message: 'process payment method on web',
						data: {
							paymentMethodId,
						},
					});

					// for web we can still distinguish between between having a paymentMethodId an undefined
					// because we do not leave the browser context and are not prone to errors here
					if (paymentMethodId) {
						storeDefaultPaymentMethod(paymentMethodId);
					} else {
						onClose();
					}
					setClientSecret(undefined);
				}}
				stripePulicKey={environment.stripePublicKey}
				stripeRedirectUrl={stripeRedirectUrl}
			/>
		</Portal>
	);
};
