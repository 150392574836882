import { useCallback, useEffect, useState } from 'react';

import { useBusinessLayer } from '../business-logic';

export interface IInvoiceDate {
	subscriptionId: string;
	year: number;
	month: number;
}

export type TInvoiceDatesByYear = Record<number, IInvoiceDate[]>;

export type TInvoicesDatesList = TInvoiceDatesByYear | null;

export const useInvoicesDates = () => {
	const businessLayer = useBusinessLayer();
	const [invoicesDatesList, setInvoicesDatesList] =
		useState<TInvoicesDatesList>(null);
	const [isLoading, setIsLoading] = useState(false);

	const fetchInvoicesData = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await businessLayer.api.get<IInvoiceDate[]>(
				`/api/passengers/invoices`
			);

			setInvoicesDatesList(prepareInvoiceDatesByYear(response));
		} catch (e) {
			console.error('Error on getting invoices dates', e);
		} finally {
			setIsLoading(false);
		}
	}, [businessLayer]);

	useEffect(() => {
		fetchInvoicesData();
	}, [fetchInvoicesData]);

	return {
		invoicesDatesList,
		isLoading,
		hasInvoices: Boolean(
			invoicesDatesList && Object.keys(invoicesDatesList).length
		),
	};
};

function prepareInvoiceDatesByYear(
	invoicesList: IInvoiceDate[]
): TInvoicesDatesList {
	if (!invoicesList) {
		return null;
	}

	return invoicesList.reduce<TInvoiceDatesByYear>((acc, invoice) => {
		if (acc[invoice.year]) {
			acc[invoice.year].push(invoice);
		} else {
			acc[invoice.year] = [invoice];
		}
		return acc;
	}, {});
}
