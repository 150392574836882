import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import { IWhoami } from '@mopla/data-models';
import { useBreakpoints } from '@mopla/utils';

import { EditIcon } from '../../icons/Edit';
import { TrashIconSmall } from '../../icons/TrashSmall';
import {
	Footer,
	MainWithFooterTemplate,
} from '../../molecules/MainWithFooterTemplate';

import {
	DataField,
	DeleteAccountButton,
	MyAccountSubtitle,
	MyAccountTitleWithButton,
	MyAccountWrapper,
	SeparatorLine,
	TitleText,
} from './ProfileManager.styles';

export interface PersonalScreenProps {
	onEdit: () => void;
	onDeleteAccount: () => void;
	app: 'DRIVER' | 'PASSENGER';
	currentUser?: IWhoami;
}

export const PersonalScreen: React.FC<PersonalScreenProps> = ({
	onEdit,
	onDeleteAccount,
	currentUser,
}) => {
	const { t } = useTranslation('profile');
	const { isAboveMobile } = useBreakpoints();

	const content = (
		<MyAccountWrapper>
			<div>
				<TitleText>
					{currentUser?.firstName} {currentUser?.lastName}
				</TitleText>
				<DataField>{currentUser?.email}</DataField>
			</div>
			<SeparatorLine />
			<MyAccountTitleWithButton>
				<TitleText>{t('header.personal_data')}</TitleText>
				<IconButton onClick={onEdit}>
					<EditIcon />
				</IconButton>
			</MyAccountTitleWithButton>
			<div>
				<MyAccountSubtitle>{t('title.adress')}</MyAccountSubtitle>
				<DataField>
					{`${currentUser?.address?.street} ${currentUser?.address?.streetNumber}`}{' '}
					<br />
					{`${currentUser?.address?.zipCode} ${currentUser?.address?.city}`}
				</DataField>
			</div>
			<div>
				<MyAccountSubtitle>{t('title.cell_phone')}</MyAccountSubtitle>
				<DataField>{currentUser?.phone}</DataField>
			</div>
			<div>
				<MyAccountSubtitle>{t('title.birth')}</MyAccountSubtitle>
				<DataField>{currentUser?.dateOfBirth}</DataField>
			</div>
		</MyAccountWrapper>
	);

	if (isAboveMobile) {
		return content;
	}

	return (
		<MainWithFooterTemplate
			content={content}
			footer={
				<Footer>
					<DeleteAccountButton
						variant="text"
						onClick={onDeleteAccount}
						icon={<TrashIconSmall />}
						justify="center"
					>
						{t('button.delete_account')}
					</DeleteAccountButton>
				</Footer>
			}
		/>
	);
};
