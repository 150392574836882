import { useMemo } from 'react';
import { useSubscription } from 'observable-hooks';
import { DeepReadonlyObject, RxCollection } from 'rxdb';
import { BehaviorSubject, combineLatest, distinctUntilChanged } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ISubscription } from '@mopla/data-models';

import { SubscriptionActionTypes } from '../actions/subscriptionActions';
import { useBusinessLayer } from '../business-logic';

import { TSubscription$ } from './dataContext';

export interface ISubscriptionDBValue {
	subscriptionData: DeepReadonlyObject<ISubscription> | null;
	isLoading: boolean;
	isSubscriptionPauseLoading: boolean;
	isSubscriptionResumeLoading: boolean;
	isSubscriptionCancelLoading: boolean;
}

export const useSubscription$ = () => {
	const { db, watchActions$ } = useBusinessLayer();

	const subscription$ = useMemo(
		() =>
			new BehaviorSubject<ISubscriptionDBValue>({
				subscriptionData: null,
				isLoading: false,
				isSubscriptionPauseLoading: false,
				isSubscriptionResumeLoading: false,
				isSubscriptionCancelLoading: false,
			}),
		[]
	);

	const result$ = useMemo<TSubscription$>(() => {
		const isLoading$ = watchActions$([
			SubscriptionActionTypes.FetchSubscriptions,
			SubscriptionActionTypes.FetchSubscriptionsResult,
		]).pipe(
			map(({ type }) => type === SubscriptionActionTypes.FetchSubscriptions),
			startWith(false),
			distinctUntilChanged()
		);
		const isSubscriptionPauseLoading$ = watchActions$([
			SubscriptionActionTypes.PauseSubscription,
			SubscriptionActionTypes.PauseSubscriptionResult,
		]).pipe(
			map(({ type }) => type === SubscriptionActionTypes.PauseSubscription),
			startWith(false),
			distinctUntilChanged()
		);

		const isSubscriptionResumeLoading$ = watchActions$([
			SubscriptionActionTypes.ResumeSubscription,
			SubscriptionActionTypes.ResumeSubscriptionResult,
		]).pipe(
			map(({ type }) => type === SubscriptionActionTypes.ResumeSubscription),
			startWith(false),
			distinctUntilChanged()
		);

		const isSubscriptionCancelLoading$ = watchActions$([
			SubscriptionActionTypes.CancelSubscription,
			SubscriptionActionTypes.CancelSubscriptionResult,
		]).pipe(
			map(
				({ type }) => type === SubscriptionActionTypes.CancelSubscriptionResult
			),
			startWith(false),
			distinctUntilChanged()
		);

		return combineLatest([
			(db['subscriptions'] as RxCollection<ISubscription>).find().$,
			isLoading$,
			isSubscriptionPauseLoading$,
			isSubscriptionResumeLoading$,
			isSubscriptionCancelLoading$,
		]).pipe(
			map(([docs, ...flags]) => ({
				subscriptionData: docs.length ? docs[0].toJSON() : null,
				isLoading: flags[0],
				isSubscriptionPauseLoading: flags[1],
				isSubscriptionResumeLoading: flags[2],
				isSubscriptionCancelLoading: flags[3],
			}))
		);
	}, [db, watchActions$]);

	useSubscription(result$, (v) => {
		subscription$.next(v);
	});

	return subscription$;
};
