/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * an email will be sent to user that asks for email verification
 */
export type commandPayload__verifyEmail = {
	/**
	 * determines, what email link will be generated to redirect to app
	 */
	app: commandPayload__verifyEmail.app;
};

export namespace commandPayload__verifyEmail {
	/**
	 * determines, what email link will be generated to redirect to app
	 */
	export enum app {
		PASSENGER = 'PASSENGER',
	}
}
