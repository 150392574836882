import React, {
	Dispatch,
	FC,
	FormEvent,
	ReactElement,
	SetStateAction,
	useState,
} from 'react';
import {
	Controller,
	FormProvider,
	UnpackNestedValue,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	EmailAuthProvider,
	fetchSignInMethodsForEmail,
	getAuth,
} from 'firebase/auth';
import * as yup from 'yup';

import { LoginFields, SuccessStep } from '@mopla/constants';
import {
	ArrowRightIcon,
	AttentionIcon,
	IFormInput,
	Input,
	PasswordInput,
} from '@mopla/ui';
import {
	ForgotPassword,
	Root,
	SubmitButton,
} from '@mopla/ui/organisms/LoginForm/LoginForm.styles';

import { VerifyEmailSentView } from '../RegistrationForm/VerifyEmailSentView';
import { ResendEmail } from '../ResendEmailScreen/ResendEmail';

interface IProps {
	onLogin: (data: UnpackNestedValue<IFormInput>) => void;
	onPasswordless: (email: string) => void;
	authError: boolean;
	titleComp?: ReactElement | null;
	'data-testid'?: string;
	successStep?: string;
	changeSuccessType: Dispatch<SetStateAction<string>>;
}
type loginType = 'PW' | 'NORMAL';

export const LoginScreen: FC<IProps> = (props) => {
	const {
		onLogin,
		onPasswordless,
		authError,
		titleComp = null,
		successStep,
		changeSuccessType,
	} = props;
	const [type, setType] = useState<loginType>('PW');
	const isPWlessStep = type === 'PW';

	const navigate = useNavigate();
	const { t } = useTranslation(['logIn', 'validation']);

	const methods = useForm<IFormInput>({
		defaultValues: {
			[LoginFields.email]: '',
			[LoginFields.password]: '',
		},
		resolver: (data, context, options) => {
			const schema = {
				email: yup.string().email('email.valid').required('email.required'),
				password: yup.string(),
			};
			if (!isPWlessStep)
				schema.password = yup.string().required('password.required');

			return yupResolver(yup.object().shape(schema))(data, context, options);
		},
		mode: 'all',
	});
	const { formState, handleSubmit, watch } = methods;
	const { isSubmitting, isValid, errors } = formState;
	const email = watch('email');

	const handleSubmitPWStep = () => {
		const auth = getAuth();
		fetchSignInMethodsForEmail(auth, email)
			.then((signInMethods) => {
				const isLoginWithPassword = signInMethods.includes(
					EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
				);
				const isPasswordlessLogin = signInMethods.includes(
					EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
				);

				if (!signInMethods.length || isLoginWithPassword)
					return goToPasswordFlow();
				if (isPasswordlessLogin) return onPasswordless(email);
			})
			.catch((error) => {
				console.error('Error on getting login type: ', error);
			});
	};
	const goToPasswordFlow = () => setType('NORMAL');
	const getHelperText = (key: 'password') => {
		if (errors[key])
			return (
				<>
					<AttentionIcon width={15} height={15} />
					<span>{t(`validation:${errors[key]?.message}`)}</span>
				</>
			);
		if (authError)
			return (
				<>
					<AttentionIcon width={15} height={15} />
					<span>{t('validation:auth.error')}</span>
				</>
			);
		return '';
	};

	if (successStep === SuccessStep.pwlessLoginEmailSent) {
		const emailForSignIn = localStorage.getItem('emailForSignIn') || '';
		return (
			<VerifyEmailSentView
				email={emailForSignIn}
				onResend={() => changeSuccessType(SuccessStep.resendPwlessLoginEmail)}
				passwordless
			/>
		);
	}
	if (successStep === SuccessStep.resendPwlessLoginEmail) {
		const emailForSignIn = localStorage.getItem('emailForSignIn') || '';
		return <ResendEmail onSubmit={handleSubmitPWStep} email={emailForSignIn} />;
	}

	const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (isPWlessStep) {
			return handleSubmitPWStep();
		}
		handleSubmit((data) => onLogin(data))();
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmitForm}>
				<Root>
					{titleComp}
					<Controller
						name="email"
						render={({ field }) => (
							<Input
								{...field}
								onChange={(e) => {
									e.target.value = e.target.value.trim();
									field.onChange(e);
								}}
								label={t('label.email')}
								ref={null}
								helperText={
									Boolean(errors.email?.message) && (
										<>
											<AttentionIcon width={15} height={15} />
											<span>{t(`validation:${errors.email?.message}`)}</span>
										</>
									)
								}
								error={Boolean(errors.email?.message) || authError}
								inputProps={{
									'data-testid': 'loginForm-email-textField',
								}}
							/>
						)}
					/>
					{!isPWlessStep && (
						<>
							<Controller
								name="password"
								render={({ field }) => (
									<PasswordInput
										{...field}
										onChange={(e) => {
											e.target.value = e.target.value.trim();
											field.onChange(e);
										}}
										label={t('label.password')}
										ref={null}
										helperText={getHelperText('password')}
										error={Boolean(errors.password?.message || authError)}
										inputProps={{
											'data-testid': 'loginForm-password-textField',
										}}
									/>
								)}
							/>
							<ForgotPassword
								driverApp
								onClick={() =>
									navigate({ pathname: '/welcome', search: 'forgotPassword' })
								}
							>
								{t('text.forgot')}
							</ForgotPassword>
						</>
					)}
					<SubmitButton
						variant="primary"
						icon={<ArrowRightIcon />}
						data-testid="loginForm-login-button"
						disabled={!isValid || isSubmitting}
						type="submit"
					>
						{t(`button.${isPWlessStep ? 'continue' : 'logIn'}`)}
					</SubmitButton>
				</Root>
			</form>
		</FormProvider>
	);
};
