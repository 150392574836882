import { useContext } from 'react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { createRemoteJWKSet, jwtVerify } from 'jose';

import { BusinessLayerContext } from '../business-logic';

dayjs.extend(isSameOrAfter);

interface ISpenditTokenTools {
	validateToken: (token: string | null, keySetUrl: string) => Promise<boolean>;
	connectTokenToUser: () => Promise<void>;
	deleteSpenditInfo: () => Promise<void>;
}

export const useSpenditToken = (): ISpenditTokenTools => {
	const businessLayer = useContext(BusinessLayerContext);

	const validateToken = async (token: string | null, keySetUrl: string) => {
		let isValidToken = false;
		if (!token) return isValidToken;

		try {
			const JWKS = createRemoteJWKSet(new URL(keySetUrl));
			const { payload } = await jwtVerify(token, JWKS);
			const expiresDate = dayjs((payload.exp || 0) * 1000);
			const isExpired = dayjs().isSameOrAfter(expiresDate);
			isValidToken = !isExpired;
		} catch (e) {
			console.error('Error on token validation: ', e);
			isValidToken = false;
		}
		return isValidToken;
	};

	const connectTokenToUser = async () => {
		const token = localStorage.getItem('spenditToken');
		if (!token) return;

		try {
			await businessLayer.api.post('/api/users/spenditToken', token);
			localStorage.removeItem('spenditToken');
		} catch (e) {
			console.error('Error on saving Spendit Token: ', e);
		}
	};

	const deleteSpenditInfo = async () => {
		try {
			await businessLayer.api.delete('/api/users/jobticketMetaData');
		} catch (e) {
			console.error('Error on removing Spendit data: ', e);
			throw Error('Error on removing Spendit data');
		}
	};

	return {
		validateToken,
		connectTokenToUser,
		deleteSpenditInfo,
	};
};
