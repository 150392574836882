import React from 'react';
import moment from 'moment/moment';

import { NormalizedScheduledLeg } from '@mopla/data-models';

import { Row, TripCardRoot } from './DistributorNotificationModal.styles';

interface TripCardProps {
	leg: NormalizedScheduledLeg & { updateState: string };
}

export const TripCard: React.FC<TripCardProps> = ({ leg }) => {
	const getVariant = () => {
		if (leg.updateState === 'DELETED') return 'grey';
		if (leg.state === 'BREAK') return 'blue';
		if (leg.passengers) return 'secondary_dark';
		return 'yellow';
	};

	return (
		<TripCardRoot variant={getVariant()}>
			{leg.state === 'BREAK' ? (
				<Row>
					<span>{`${moment(leg.start).format('HH:mm')} — ${moment(
						leg.end
					).format('HH:mm')}`}</span>
				</Row>
			) : (
				<>
					<Row variant={getVariant()}>
						<span>{moment(leg.start).format('HH:mm')}</span>
						<span>{leg?.from?.name}</span>
					</Row>
					<Row variant={getVariant()}>
						<span>{moment(leg.end).format('HH:mm')}</span>
						<span>{leg?.to?.name}</span>
					</Row>
				</>
			)}
		</TripCardRoot>
	);
};

export default TripCard;
