import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';

import { PassengerBooking } from '@mopla/business-logic';
import { TicketBannerSize } from '@mopla/constants';
import { ArrowRightIcon, Button } from '@mopla/ui';

import { TicketQR } from '../TicketQR/TicketQR';
import { TicketsStack } from '../TicketsStack/TicketsStack';

import { useBookingContext } from './bookingContext';
import {
	LastStepGrid,
	LastStepTicket,
	LastStepTitle,
} from './BookingFlow.styles';

const LastBookingStep: React.FC = () => {
	const { t } = useTranslation('booking');
	const navigate = useNavigate();
	const goToSettings = () => {
		NativeSettings.open({
			optionAndroid: AndroidSettings.ApplicationDetails,
			optionIOS: IOSSettings.App,
		});
	};

	const {
		isLBTFlow,
		setState,
		state: { activeRide, permissionsType, showQR, booking },
	} = useBookingContext();

	const prePermissionsTitle = (
		<LastStepTitle>{t('text.look_forward')}</LastStepTitle>
	);

	const successTitle = <LastStepTitle>{t('text.will_notify')}</LastStepTitle>;

	const settingsTitle = (
		<>
			<LastStepTitle>{t('text.allow_start')}</LastStepTitle>
			<Button
				id="openSettings"
				onClick={goToSettings}
				icon={<ArrowRightIcon />}
			>
				{t('button.system_settings')}
			</Button>
			<LastStepTitle>{t('text.allow_finish')}</LastStepTitle>
		</>
	);

	const chooseTitle = () => {
		switch (permissionsType) {
			case 'prompt-with-rationale':
			case 'prompt': {
				return prePermissionsTitle;
			}
			case 'granted': {
				return successTitle;
			}
			case 'denied': {
				return settingsTitle;
			}
			default: {
				return prePermissionsTitle;
			}
		}
	};

	const handleTicketInfoClick = () => {
		if (booking?.bookingNumber) {
			navigate(
				{
					pathname: '/home/tickets',
					search: `ticket=${booking.bookingNumber}`,
				},
				{ replace: true }
			);
		}
	};

	if (showQR) {
		return (
			<TicketQR
				ticket={booking as PassengerBooking}
				onClose={() => navigate('/home', { replace: true })}
				showHeader={false}
			/>
		);
	}

	return (
		<LastStepGrid data-testid="booking-flow-last-step">
			{isLBTFlow ? (
				<LastStepTitle>{t('text.remind')}</LastStepTitle>
			) : (
				chooseTitle()
			)}

			{activeRide ? (
				<LastStepTicket>
					<TicketsStack
						tickets={[{ itinerary: activeRide }]}
						ticketsBannerSize={TicketBannerSize.lg}
						onDetailsClick={() => setState('showQR', true)}
						onTicketInfoClick={handleTicketInfoClick}
						disabled={!booking}
					/>
				</LastStepTicket>
			) : (
				<LastStepTicket />
			)}
		</LastStepGrid>
	);
};

export default LastBookingStep;
