import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUser } from '@mopla/business-logic';
import { DiscountState } from '@mopla/data-models';
import { ArrowRightIcon, Button, CheckmarkIcon } from '@mopla/ui';

import { useBookingContext } from './bookingContext';
import {
	BuyFor,
	EFlowSteps,
	TBookingFormState,
	TPassengersFormPath,
} from './types';
import { getCurrentPassengerInfo, isDiscountType } from './utils';

export interface IProceedButton {
	askPermissions: VoidFunction;
	onClose: VoidFunction;
}

export const ProceedButton: FC<IProceedButton> = ({
	askPermissions,
	onClose,
}) => {
	const { t } = useTranslation('booking');
	const { userData } = useUser();
	const {
		flowStepsMethods: { currentStep, goToNextStep, updateSteps },
		hasImpaired,
		hasImpairedCompanion,
		canBookForDisabled,
		hasValidDisabledStatus,
		setState,
		state,
		isLBTFlow,
		setPriceInfo,
	} = useBookingContext();
	const formCtx = useFormContext<TBookingFormState>();
	const { getValues, formState } = formCtx;

	const { isSubmitting } = formState;
	const { permissionsType, isAcceptTariffs } = state;

	const nextStepHandler = () => {
		/**
		 * If a Dispatcher is adding a companion person, then we make next attendee as an impaired automatically.
		 * The next attendee after the main passenger has 0 index in the list.
		 * The next attendee after a nth attendee has (n+1) index.
		 * */
		if (
			(currentStep.step === EFlowSteps.PriceCalcPassengerMain ||
				currentStep.isAttendee) &&
			canBookForDisabled &&
			hasImpairedCompanion &&
			!hasImpaired
		) {
			const passengerFormPath: TPassengersFormPath = `attendees2.${
				(currentStep.isAttendee ? currentStep.attendeeIndex : -1) + 1
			}`;

			setPriceInfo(DiscountState.IMPAIRED, passengerFormPath);
			updateSteps();
			goToNextStep();
			return;
		}

		/**
		 * If an impaired/companion person is booking a ride for themselves, offer them to add a companion/impaired accordingly
		 * */
		if (
			hasValidDisabledStatus &&
			currentStep.step === EFlowSteps.PriceCalcPassengerMain
		) {
			const { values } = getCurrentPassengerInfo(currentStep, formCtx);

			if (
				isDiscountType([DiscountState.IMPAIRED], values.discountState) &&
				!hasImpairedCompanion
			) {
				return setState('isCompanionSuggest', true);
			} else if (
				isDiscountType(
					[DiscountState.IMPAIRED_COMPANION],
					values.discountState
				) &&
				!hasImpaired
			) {
				return setState('isImpairedPersonRequirement', true);
			}
		}

		if (
			currentStep.step === EFlowSteps.AddPaymentMethod &&
			!userData?.hasDefaultStripePaymentMethod
		) {
			return setState('forceInitiatePayment', true);
		}

		if (currentStep.step === EFlowSteps.Thanks) {
			if (permissionsType !== 'granted') {
				askPermissions();
			}
			return onClose();
		}

		if (currentStep.step === EFlowSteps.Summary) {
			return;
		}

		goToNextStep();
	};

	const getDisabled = () => {
		const { buyFor } = getValues();

		if (currentStep.step === EFlowSteps.PriceCalcPassengerMain) {
			return !getValues().main?.discountState;
		}

		if (currentStep.isAttendee) {
			const { discountState } = getValues(
				`attendees2.${currentStep.attendeeIndex}`
			);
			return !discountState;
		}

		if (currentStep.step === EFlowSteps.Summary) {
			return isSubmitting || !(isLBTFlow || (!isLBTFlow && isAcceptTariffs));
		}

		return buyFor === BuyFor.anotherPerson && !formState.isValid;
	};

	const getButtonName = () => {
		switch (currentStep.step) {
			case EFlowSteps.AddPaymentMethod:
				return t('button.add_payment_method');
			case EFlowSteps.Summary:
				return isLBTFlow ? t('button.save_ride') : t('button.book_binding');
			case EFlowSteps.Thanks:
				return t('button.conclude');
			default:
				return t('button.continue');
		}
	};

	return (
		<Button
			key={currentStep.step}
			type={currentStep.step === EFlowSteps.Summary ? 'submit' : 'button'}
			icon={
				currentStep.step === EFlowSteps.Summary ? (
					<CheckmarkIcon />
				) : (
					<ArrowRightIcon />
				)
			}
			onClick={nextStepHandler}
			variant="outlined"
			disabled={getDisabled()}
			color="info"
			data-testid="booking-nextStep-button"
		>
			{getButtonName()}
		</Button>
	);
};
