/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NamedLocation } from '../models/NamedLocation';
import type { Province } from '../models/Province';
import type { ServiceDays } from '../models/ServiceDays';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShiftsService {
	/**
	 * Returns a list of shifts.
	 * Returns all shifts assigned to distributor, requires distributor role.
	 * @returns any A JSON array of shifts
	 * @throws ApiError
	 */
	public static getApiShifts(): CancelablePromise<
		Array<{
			/**
			 * unique identificator
			 */
			uuid?: string;
			/**
			 * descriptive name of shift
			 */
			name?: string;
			validFrom?: string;
			validTo?: string;
			province?: Province;
			startTime?: string;
			endTime?: string;
			/**
			 * determines, if shift ends at +1 day
			 */
			isOvernight?: boolean;
			serviceDays?: ServiceDays;
			/**
			 * is this shift active on public holidays
			 */
			onPublicHolidays?: boolean;
			/**
			 * is this shift active on special days
			 */
			onSpecialDays?: boolean;
			/**
			 * is this shift active on school days
			 */
			onSchoolDays?: boolean;
			/**
			 * is this shift active on school holidays
			 */
			onNonSchoolDays?: boolean;
			/**
			 * allow automatic disposition
			 */
			automaticDisposition?: boolean;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/shifts',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}

	/**
	 * Return a shift.
	 * Returns detail view for a specific shift. Is correctly returned only if belongs to given distributor (user)
	 * @param uuid uuid of object
	 * @returns any A JSON object for a specific shift
	 * @throws ApiError
	 */
	public static getApiShifts1(uuid: string): CancelablePromise<{
		/**
		 * unique identificator
		 */
		uuid?: string;
		/**
		 * descriptive name of shift
		 */
		name?: string;
		validFrom?: string;
		validTo?: string;
		province?: Province;
		startTime?: string;
		endTime?: string;
		/**
		 * determines, if shift ends at +1 day
		 */
		isOvernight?: boolean;
		/**
		 * break length in minutes
		 */
		breakLength?: number;
		serviceDays?: ServiceDays;
		/**
		 * is this shift active on public holidays
		 */
		onPublicHolidays?: boolean;
		/**
		 * is this shift active on special days
		 */
		onSpecialDays?: boolean;
		/**
		 * is this shift active on school days
		 */
		onSchoolDays?: boolean;
		/**
		 * is this shift active on school holidays
		 */
		onNonSchoolDays?: boolean;
		/**
		 * allow automatic disposition
		 */
		automaticDisposition?: boolean;
		startLocation?: NamedLocation;
		endLocation?: NamedLocation;
		/**
		 * radius in km
		 */
		serviceRadius?: number;
		/**
		 * flag that determines, if given shift may be deleted
		 */
		canBeDeleted?: boolean;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/shifts/{uuid}',
			path: {
				uuid: uuid,
			},
			errors: {
				400: `Bad request, e.g. uuid missing`,
				401: `Unauthorized`,
				403: `Forbidden`,
			},
		});
	}
}
