import { ISubscriptionTicket } from '../lib/types';

export const validTicket: ISubscriptionTicket = {
	id: '00d75eff-9f3e-4694-99e7-2038d11a58ee',
	ticketId: 'DT-7SRHCZ69D6PWOS02A',
	status: 'VALID',
	type: 'GERMANYTICKET',
	validFrom: '2023-10-31T23:00:00Z',
	validTo: '2023-12-01T02:00:00Z',
	firstName: 'aa',
	lastName: 'aa',
	aztecCode:
		'I1VUMDE1MjExMDAwMDEwLAIUO8zlQRxuRDy1HOOBK5l8Ti32NAkCFC0ZH1r57MDGzlzU4a2SokloPFXzAAAAADAyNTN4nE2Qz07CQBDGX2WPmij5ZrfdpdwainIwSgqN0QtZ6UYaoCRt9em8+WLObGPgtJlfvj8zW22Xi7wAAalJNVGxuXfrcjl/t1lhV68va+gcjkhDGyLKUIc6VNvNU/7GJmOonG80QAkAzUReJJIEw+M/EDurpgwyAdmD33cH3w0BJJQcQ3JF+Br63f7o23podocw8FqSmoon9V55DxLC+7Bek1qFrj+3N6G9hY19oxQ04Q0s3IVgxo+9ihONZjK9EBM1dkwaG0RPxvJg3OPvz3FoPtX3+aRiw0TuUh9NH0cdR/4KdpD4iZ79KcxUXJxM7B7PT7I7QC2q8g9qkVr8',
};
