/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { commandPayload__addPassengerDetails } from '../models/commandPayload__addPassengerDetails';
import type { commandPayload__adjustAssignment } from '../models/commandPayload__adjustAssignment';
import type { commandPayload__bookJourney } from '../models/commandPayload__bookJourney';
import type { commandPayload__bulkCreateAssignments } from '../models/commandPayload__bulkCreateAssignments';
import type { commandPayload__cancelBooking } from '../models/commandPayload__cancelBooking';
import type { commandPayload__cancelBookingForBookingLeg } from '../models/commandPayload__cancelBookingForBookingLeg';
import type { commandPayload__canDeleteDefaultPaymentMethod } from '../models/commandPayload__canDeleteDefaultPaymentMethod';
import type { commandPayload__carryOutOutsideMopla } from '../models/commandPayload__carryOutOutsideMopla';
import type { commandPayload__checkInPassenger } from '../models/commandPayload__checkInPassenger';
import type { commandPayload__checkVehicle } from '../models/commandPayload__checkVehicle';
import type { commandPayload__createAssignment } from '../models/commandPayload__createAssignment';
import type { commandPayload__createBookingLegManually } from '../models/commandPayload__createBookingLegManually';
import type { commandPayload__createCalendarEvent } from '../models/commandPayload__createCalendarEvent';
import type { commandPayload__createDispatcher } from '../models/commandPayload__createDispatcher';
import type { commandPayload__createDistributor } from '../models/commandPayload__createDistributor';
import type { commandPayload__createDriver } from '../models/commandPayload__createDriver';
import type { commandPayload__createGtfsTripFlexibility } from '../models/commandPayload__createGtfsTripFlexibility';
import type { commandPayload__createRecurringManualBookingLeg } from '../models/commandPayload__createRecurringManualBookingLeg';
import type { commandPayload__createShift } from '../models/commandPayload__createShift';
import type { commandPayload__createTimeframe } from '../models/commandPayload__createTimeframe';
import type { commandPayload__createUserPushToken } from '../models/commandPayload__createUserPushToken';
import type { commandPayload__createVehicle } from '../models/commandPayload__createVehicle';
import type { commandPayload__createZone } from '../models/commandPayload__createZone';
import type { commandPayload__deleteAssignment } from '../models/commandPayload__deleteAssignment';
import type { commandPayload__deleteCalendarEvent } from '../models/commandPayload__deleteCalendarEvent';
import type { commandPayload__deleteDispatcher } from '../models/commandPayload__deleteDispatcher';
import type { commandPayload__deleteDriver } from '../models/commandPayload__deleteDriver';
import type { commandPayload__deleteRecurringManualBookingLeg } from '../models/commandPayload__deleteRecurringManualBookingLeg';
import type { commandPayload__deleteShift } from '../models/commandPayload__deleteShift';
import type { commandPayload__deleteTimeframe } from '../models/commandPayload__deleteTimeframe';
import type { commandPayload__deleteVehicle } from '../models/commandPayload__deleteVehicle';
import type { commandPayload__deleteZone } from '../models/commandPayload__deleteZone';
import type { commandPayload__endDrive } from '../models/commandPayload__endDrive';
import type { commandPayload__endDriveManually } from '../models/commandPayload__endDriveManually';
import type { commandPayload__manuallyAssignToBookingLeg } from '../models/commandPayload__manuallyAssignToBookingLeg';
import type { commandPayload__saveDriverFiles } from '../models/commandPayload__saveDriverFiles';
import type { commandPayload__saveLocation } from '../models/commandPayload__saveLocation';
import type { commandPayload__setBookingTripsToScheduleAutomatically } from '../models/commandPayload__setBookingTripsToScheduleAutomatically';
import type { commandPayload__setBookingTripToManualDisposition } from '../models/commandPayload__setBookingTripToManualDisposition';
import type { commandPayload__setBookingTripToScheduleAutomatically } from '../models/commandPayload__setBookingTripToScheduleAutomatically';
import type { commandPayload__setDefaultPaymentMethod } from '../models/commandPayload__setDefaultPaymentMethod';
import type { commandPayload__startDrive } from '../models/commandPayload__startDrive';
import type { commandPayload__updateAssignment } from '../models/commandPayload__updateAssignment';
import type { commandPayload__updateCalendarEvent } from '../models/commandPayload__updateCalendarEvent';
import type { commandPayload__updateDispatcher } from '../models/commandPayload__updateDispatcher';
import type { commandPayload__updateDistributor } from '../models/commandPayload__updateDistributor';
import type { commandPayload__updateDriver } from '../models/commandPayload__updateDriver';
import type { commandPayload__updateRecurringManualBookingLeg } from '../models/commandPayload__updateRecurringManualBookingLeg';
import type { commandPayload__updateShift } from '../models/commandPayload__updateShift';
import type { commandPayload__updateTimeframe } from '../models/commandPayload__updateTimeframe';
import type { commandPayload__updateVehicle } from '../models/commandPayload__updateVehicle';
import type { commandPayload__updateZone } from '../models/commandPayload__updateZone';
import type { commandPayload__verifyEmail } from '../models/commandPayload__verifyEmail';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CqrsService {
	/**
	 * process command given by path command name
	 * process command by name, command details/payload is given by request body
	 * @param cmd name of command to be processed
	 * @param requestBody command specific payload as JSON Object
	 * @returns string Successfully handled command, response depends on command type
	 * @returns any Created
	 * @throws ApiError
	 */
	public static postApiCommand(
		cmd:
			| 'addPassengerDetails'
			| 'setDefaultPaymentMethod'
			| 'createDistributor'
			| 'updateDistributor'
			| 'createDispatcher'
			| 'updateDispatcher'
			| 'deleteDispatcher'
			| 'saveLocation'
			| 'bookJourney'
			| 'createDriver'
			| 'updateDriver'
			| 'deleteDriver'
			| 'saveDriverFiles'
			| 'createVehicle'
			| 'updateVehicle'
			| 'deleteVehicle'
			| 'checkVehicle'
			| 'createCalendarEvent'
			| 'updateCalendarEvent'
			| 'deleteCalendarEvent'
			| 'createShift'
			| 'updateShift'
			| 'deleteShift'
			| 'createRecurringManualBookingLeg'
			| 'updateRecurringManualBookingLeg'
			| 'deleteRecurringManualBookingLeg'
			| 'verifyEmail'
			| 'createGtfsTripFlexibility'
			| 'createUserPushToken'
			| 'createBookingLegManually'
			| 'cancelBooking'
			| 'canDeleteDefaultPaymentMethod'
			| 'createAssignment'
			| 'updateAssignment'
			| 'adjustAssignment'
			| 'deleteAssignment'
			| 'bulkCreateAssignments'
			| 'startDrive'
			| 'endDrive'
			| 'endDriveManually'
			| 'checkInPassenger'
			| 'carryOutOutsideMopla'
			| 'setBookingTripToScheduleAutomatically'
			| 'setBookingTripsToScheduleAutomatically'
			| 'setBookingTripToManualDisposition'
			| 'manuallyAssignToBookingLeg'
			| 'cancelBookingForBookingLeg'
			| 'createZone'
			| 'updateZone'
			| 'deleteZone'
			| 'createTimeframe'
			| 'updateTimeframe'
			| 'deleteTimeframe',
		requestBody:
			| commandPayload__addPassengerDetails
			| commandPayload__setDefaultPaymentMethod
			| commandPayload__createDistributor
			| commandPayload__updateDistributor
			| commandPayload__createDispatcher
			| commandPayload__updateDispatcher
			| commandPayload__deleteDispatcher
			| commandPayload__saveLocation
			| commandPayload__bookJourney
			| commandPayload__createDriver
			| commandPayload__updateDriver
			| commandPayload__deleteDriver
			| commandPayload__saveDriverFiles
			| commandPayload__createVehicle
			| commandPayload__updateVehicle
			| commandPayload__deleteVehicle
			| commandPayload__checkVehicle
			| commandPayload__createShift
			| commandPayload__updateShift
			| commandPayload__deleteShift
			| commandPayload__createRecurringManualBookingLeg
			| commandPayload__updateRecurringManualBookingLeg
			| commandPayload__deleteRecurringManualBookingLeg
			| commandPayload__createCalendarEvent
			| commandPayload__updateCalendarEvent
			| commandPayload__deleteCalendarEvent
			| commandPayload__verifyEmail
			| commandPayload__createGtfsTripFlexibility
			| commandPayload__createUserPushToken
			| commandPayload__createBookingLegManually
			| commandPayload__cancelBooking
			| commandPayload__canDeleteDefaultPaymentMethod
			| commandPayload__createAssignment
			| commandPayload__updateAssignment
			| commandPayload__adjustAssignment
			| commandPayload__deleteAssignment
			| commandPayload__bulkCreateAssignments
			| commandPayload__startDrive
			| commandPayload__endDrive
			| commandPayload__endDriveManually
			| commandPayload__checkInPassenger
			| commandPayload__carryOutOutsideMopla
			| commandPayload__setBookingTripToScheduleAutomatically
			| commandPayload__setBookingTripsToScheduleAutomatically
			| commandPayload__setBookingTripToManualDisposition
			| commandPayload__manuallyAssignToBookingLeg
			| commandPayload__cancelBookingForBookingLeg
			| commandPayload__createZone
			| commandPayload__updateZone
			| commandPayload__deleteZone
			| commandPayload__createTimeframe
			| commandPayload__updateTimeframe
			| commandPayload__deleteTimeframe
	): CancelablePromise<string | any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/command/{cmd}',
			path: {
				cmd: cmd,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
				422: `Command not processable exception`,
			},
		});
	}
}
