import React from 'react';

export const LocationFlagIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="currentColor"
				d="M12.11 20.14l5.01-5.02a7.09 7.09 0 10-10.02 0l5.01 5.02zm0 2.86l-6.44-6.44a9.11 9.11 0 1112.89 0L12.1 23zm0-10.86a2.02 2.02 0 100-4.05 2.02 2.02 0 000 4.05zm0 2.02a4.05 4.05 0 110-8.1 4.05 4.05 0 010 8.1z"
			></path>
		</svg>
	);
};
