import { FC, Fragment, ReactNode, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { formatPrice } from '@mopla/utils';

import { NoWrap, PreviousValue } from './styled';

export interface IPriceStringProps {
	price?: number | null;
	priceRangePerPerson?: [number, number] | null;
	hasPriceTariff?: boolean | null;
	/** if set, then either the price|priceRangePerPerson or hasPriceTariff will be shown */
	single?: boolean;
	renderItem?(value: string): ReactNode;
	separator?: ReactNode;
	previousPrice?: number;
}

/** TODO important: cover with tests and fix current behavior */
export const PriceString: FC<IPriceStringProps> = ({
	priceRangePerPerson,
	price,
	hasPriceTariff,
	single,
	separator = ' + ',
	renderItem,
	previousPrice,
}) => {
	const { t } = useTranslation();

	const items = useMemo(() => {
		if (!priceRangePerPerson && price == null && hasPriceTariff == null) {
			return null;
		}

		const priceAccordingToTariff =
			hasPriceTariff && t('booking:button.price_according_to_tariff');

		const formattedPrice = (
			priceRangePerPerson
				? formatPriceRange(...priceRangePerPerson, t)
				: price != null
				? formatPrice(price / 100)
				: null
		) as string;

		/** If single, pick either formattedPrice (1st priority, if the value is meaningful) or priceAccordingToTariff or null */
		if (single) {
			return priceAccordingToTariff
				? [priceAccordingToTariff]
				: formattedPrice
				? [formattedPrice]
				: null;
		}

		return [formattedPrice, priceAccordingToTariff].filter(
			(v): v is string => !!v
		);
	}, [hasPriceTariff, price, priceRangePerPerson, single, t]);

	if (!items) {
		return null;
	}

	return (
		<>
			{items.map((item, i, s) => (
				<Fragment key={i}>
					{previousPrice && (
						<PreviousValue variant="body1_semibold" color="mopla_dark_grey">
							{formatPrice(previousPrice / 100)}
						</PreviousValue>
					)}
					{renderItem ? renderItem(item) : <NoWrap>{item}</NoWrap>}
					{!!s[i + 1] && separator}
				</Fragment>
			))}
		</>
	);
};

function formatPriceRange(min: number, max: number, t: TFunction) {
	return t('booking:button.price_range', {
		range: `${formatPrice(min / 100, false)} - ${formatPrice(max / 100)}`,
	});
}
