import React from 'react';

import { FacebookIcon } from '../../icons/Facebook';

import { SocialButton } from './SocialButton';

interface IProps {
	onClick: VoidFunction;
}
export const FacebookButton: React.FC<IProps> = (props) => {
	const { onClick } = props;

	const handleClick = () => {
		onClick();
	};

	return (
		<SocialButton
			name="facebook"
			onClick={handleClick}
			icon={<FacebookIcon />}
		/>
	);
};
