import { CSSProperties } from 'react';
import { styled } from '@mui/material/styles';

import { TBoldParams, TTextColor, TTextVariant } from './types';

export const Root = styled('span', {
	shouldForwardProp: (prop) =>
		!['variant', 'color', 'bold', 'ellipsis', 'sx'].includes(prop as string),
})<{
	variant: TTextVariant;
	color: TTextColor | CSSProperties['color'];
	bold?: TBoldParams;
	ellipsis?: boolean;
}>(({ theme, variant, color, bold, ellipsis }) => {
	const styles: CSSProperties = {
		...theme.fonts[variant],
		color: theme.colors[color as keyof typeof theme.colors] || color,
		whiteSpace: 'pre-wrap',
	};
	if (bold) {
		const isBold = bold === 'bold';
		const isSemiBold = bold === 'semibold';
		styles.fontWeight = isBold ? 700 : isSemiBold ? 600 : 400;
	}

	if (ellipsis) {
		Object.assign(styles, {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		});
	}

	return {
		...styles,
	};
});
