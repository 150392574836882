import {
	BookingStatus,
	CancelablePromise,
	Itinerary,
	ItineraryRequestedParams,
	NamedLocation,
	PaymentState,
	Province,
	SpecialNeeds,
	TransportType,
} from '@mopla/data-models';

import { PassengerBooking } from './usePassengerBookings';

export const getRoutes = (): CancelablePromise<{
	requestedParams?: ItineraryRequestedParams;
	itineraries: Itinerary[];
}> => {
	return new CancelablePromise((resolve) => {
		setTimeout(() => {
			resolve({
				requestedParams: {
					fromPlace: {
						name: 'Bitterfeld-Wolfen, Sachsen-Anhalt, Deutschland',
						lat: '51.623',
						lng: '12.32809',
						type: NamedLocation.type.STOP,
						zipcode: '01-474',
						province: Province.BB,
					},
					toPlace: {
						name: 'Gräfenhainichen, Sachsen-Anhalt, Deutschland',
						lat: '51.72868',
						lng: '12.45462',
						type: NamedLocation.type.STOP,
						zipcode: '01-474',
						province: Province.BB,
					},
					datetime: '2022-09-29T14:27:04.303Z',
					arriveBy: false,
					specialNeeds: SpecialNeeds.NONE,
				},
				itineraries: [
					{
						startDateTime: '2022-09-29T14:53:17Z',
						endDateTime: '2022-09-29T16:00:55Z',
						duration: 4058,
						walkTime: 638,
						walkDistance: 805.9350000000001,
						legs: [
							{
								legId: 'e2fff4d2-c979-4593-8cf6-94e584e84b94',
								startDateTime: '2022-09-29T14:53:17Z',
								startDateTimeFix: false,
								endDateTime: '2022-09-29T14:55:00Z',
								endDateTimeFix: false,
								duration: 103,
								distance: 125.2,
								mode: TransportType.WALK,
								modeOrigin: 'WALK',
								route: '',
								headsign: undefined,
								routeId: undefined,
								agencyId: undefined,
								agencyName: undefined,
								tripShortName: undefined,
								tripId: undefined,
								from: {
									name: 'Bitterfeld-Wolfen, Sachsen-Anhalt, Deutschland',
									stopId: undefined,
									lng: '12.32809',
									lat: '51.623',
									arrival: undefined,
									departure: '2022-09-29T14:53:17Z',
								},
								to: {
									name: 'Bitterfeld, Stadtverwaltung',
									stopId: '1:000004689',
									lng: '12.328592',
									lat: '51.62375',
									arrival: '2022-09-29T14:55:00Z',
									departure: '2022-09-29T14:55:00Z',
								},
								legGeometry: {
									points: 'urazHqyfjA_@gBAEAEI_@IWaBz@@J',
									length: 8,
								},
								intermediateStops: [],
								pricings: [],
								payment: null,
							},
							{
								legId: 'ce4e2989-dd68-4ef8-af45-24d9e0f11439',
								startDateTime: '2022-09-29T14:55:00Z',
								startDateTimeFix: true,
								endDateTime: '2022-09-29T14:56:00Z',
								endDateTimeFix: false,
								duration: 60,
								distance: 360.1746308328708,
								mode: TransportType.LBT,
								modeOrigin: 'BUS',
								route: 'Bus 405',
								headsign: 'Bitterfeld, Bahnhof (Bus)',
								routeId: '1:VETTAB405',
								agencyId: '1:00852',
								agencyName: 'Vetter Verkehrsbetriebe',
								tripShortName: '4050023',
								tripId: '1:107766',
								from: {
									name: 'Bitterfeld, Stadtverwaltung',
									stopId: '1:000004689',
									lng: '12.328592',
									lat: '51.62375',
									arrival: '2022-09-29T14:55:00Z',
									departure: '2022-09-29T14:55:00Z',
								},
								to: {
									name: 'Bitterfeld, Fisch Eck',
									stopId: '1:000004737',
									lng: '12.323842',
									lat: '51.62241',
									arrival: '2022-09-29T14:56:00Z',
									departure: '2022-09-29T15:04:00Z',
								},
								legGeometry: {
									points: 'mwazHu|fjAjGt',
									length: 2,
								},
								intermediateStops: [],
								pricings: [],
								payment: null,
							},
							{
								legId: '36e86392-077a-4e54-8382-525c02ea3970',
								startDateTime: '2022-09-29T15:04:00Z',
								startDateTimeFix: false,
								endDateTime: '2022-09-29T15:10:00Z',
								endDateTimeFix: false,
								duration: 360,
								distance: 7111.820869981328,
								mode: TransportType.LBT,
								modeOrigin: 'BUS',
								route: 'Bus 423',
								headsign: 'Muldenstein, S-Bahnhof (Bus)',
								routeId: '1:VETTAB423',
								agencyId: '1:00852',
								agencyName: 'Vetter Verkehrsbetriebe',
								tripShortName: '4230426',
								tripId: '1:110123',
								from: {
									name: 'Bitterfeld, Fisch Eck',
									stopId: '1:000004737',
									lng: '12.323842',
									lat: '51.62241',
									arrival: '2022-09-29T14:56:00Z',
									departure: '2022-09-29T15:04:00Z',
								},
								to: {
									name: 'Pouch, Schiffmühlenweg',
									stopId: '1:000004500',
									lng: '12.411102',
									lat: '51.620821',
									arrival: '2022-09-29T15:10:00Z',
									departure: '2022-09-29T15:22:00Z',
								},
								legGeometry: {
									points:
										'aoazH__fjAkc@{k@??uYwE??`Om\\??uHkk@??}EsU??dCsj@??jdAu`J',
									length: 14,
								},
								intermediateStops: [
									{
										arrival: '2022-09-29T15:06:00Z',
										departure: '2022-09-29T15:06:00Z',
										name: 'Bitterfeld, Stadt Wien',
									},
									{
										arrival: '2022-09-29T15:06:00Z',
										departure: '2022-09-29T15:06:00Z',
										name: 'Bitterfeld, Krankenhaus',
									},
									{
										arrival: '2022-09-29T15:08:00Z',
										departure: '2022-09-29T15:08:00Z',
										name: 'Bitterfeld, Friedhof',
									},
									{
										arrival: '2022-09-29T15:09:00Z',
										departure: '2022-09-29T15:09:00Z',
										name: 'Bitterfeld, Vor dem Muldedamm',
									},
									{
										arrival: '2022-09-29T15:10:00Z',
										departure: '2022-09-29T15:10:00Z',
										name: 'Bitterfeld, Bernsteinring',
									},
									{
										arrival: '2022-09-29T15:10:00Z',
										departure: '2022-09-29T15:10:00Z',
										name: 'Bitterfeld, Bernsteinvilla',
									},
								],
								pricings: [],
								payment: null,
							},
							{
								legId: '92a96762-0e70-4094-853d-50329a3cbbdd',
								startDateTime: '2022-09-29T15:22:00Z',
								startDateTimeFix: false,
								endDateTime: '2022-09-29T15:32:00Z',
								endDateTimeFix: false,
								duration: 600,
								distance: 9935.695568214138,
								mode: TransportType.LBT,
								modeOrigin: 'BUS',
								route: 'Bus 420',
								headsign: 'Krina, Ortsmitte',
								routeId: '1:VETTAB420',
								agencyId: '1:00852',
								agencyName: 'Vetter Verkehrsbetriebe',
								tripShortName: '4200428',
								tripId: '1:109530',
								from: {
									name: 'Pouch, Schiffmühlenweg',
									stopId: '1:000004500',
									lng: '12.411102',
									lat: '51.620821',
									arrival: '2022-09-29T15:10:00Z',
									departure: '2022-09-29T15:22:00Z',
								},
								to: {
									name: 'Gossa, Ortsmitte',
									stopId: '1:000090494',
									lng: '12.444994',
									lat: '51.670004',
									arrival: '2022-09-29T15:32:00Z',
									departure: '2022-09-29T15:36:00Z',
								},
								legGeometry: {
									points:
										'ceazHk`wjA}hCkgB??{nAaxB??fe@hxD??wo@{_@??gPuY??d@wW??_~@kvA',
									length: 14,
								},
								intermediateStops: [
									{
										arrival: '2022-09-29T15:25:00Z',
										departure: '2022-09-29T15:25:00Z',
										name: 'Schlaitz, Schachtbaude',
									},
									{
										arrival: '2022-09-29T15:27:00Z',
										departure: '2022-09-29T15:27:00Z',
										name: 'Plodda, Gemeindeamt',
									},
									{
										arrival: '2022-09-29T15:28:00Z',
										departure: '2022-09-29T15:28:00Z',
										name: 'Schlaitz, Haus am See',
									},
									{
										arrival: '2022-09-29T15:29:00Z',
										departure: '2022-09-29T15:29:00Z',
										name: 'Schlaitz, Freiheitsstr./Altenheim',
									},
									{
										arrival: '2022-09-29T15:30:00Z',
										departure: '2022-09-29T15:30:00Z',
										name: 'Schlaitz, August-Bebel-Str.',
									},
									{
										arrival: '2022-09-29T15:30:00Z',
										departure: '2022-09-29T15:30:00Z',
										name: 'Schlaitz, Freiheitsstr.',
									},
								],
								pricings: [],
								payment: null,
							},
							{
								legId: 'c244de74-a2df-49da-bac5-c6d03ac0693e',
								startDateTime: '2022-09-29T15:36:00Z',
								startDateTimeFix: false,
								endDateTime: '2022-09-29T15:52:00Z',
								endDateTimeFix: true,
								duration: 960,
								distance: 8279.495183488592,
								mode: TransportType.LBT,
								modeOrigin: 'BUS',
								route: 'Bus 420',
								headsign: 'Gräfenhainichen, S-Bahnhof (Bus)',
								routeId: '1:VETTAB420',
								agencyId: '1:00852',
								agencyName: 'Vetter Verkehrsbetriebe',
								tripShortName: '4200478',
								tripId: '1:109539',
								from: {
									name: 'Gossa, Ortsmitte',
									stopId: '1:000090494',
									lng: '12.444994',
									lat: '51.670004',
									arrival: '2022-09-29T15:32:00Z',
									departure: '2022-09-29T15:36:00Z',
								},
								to: {
									name: 'Gräfenhainichen, S-Bahnhof (Bus)',
									stopId: '1:000010389',
									lng: '12.45954',
									lat: '51.724946',
									arrival: '2022-09-29T15:52:00Z',
									departure: '2022-09-29T15:52:00Z',
								},
								legGeometry: {
									points: 'oxjzHet}jA{aCgV??otE~uA??|@ufA??yTk~@??zSsR',
									length: 10,
								},
								intermediateStops: [
									{
										arrival: '2022-09-29T15:40:00Z',
										departure: '2022-09-29T15:40:00Z',
										name: 'Gröbern (Muldestausee)',
									},
									{
										arrival: '2022-09-29T15:48:00Z',
										departure: '2022-09-29T15:48:00Z',
										name: 'Gräfenhainichen, Industriegelände',
									},
									{
										arrival: '2022-09-29T15:49:00Z',
										departure: '2022-09-29T15:49:00Z',
										name: 'Gräfenhainichen, Ambau',
									},
									{
										arrival: '2022-09-29T15:50:00Z',
										departure: '2022-09-29T15:50:00Z',
										name: 'Gräfenhainichen, Bahnhofstr.',
									},
								],
								pricings: [],
								payment: null,
							},
							{
								legId: '259519a6-5957-400b-abec-7ac99b8e9aab',
								startDateTime: '2022-09-29T15:52:00Z',
								startDateTimeFix: false,
								endDateTime: '2022-09-29T16:00:55Z',
								endDateTimeFix: false,
								duration: 535,
								distance: 680.735,
								mode: TransportType.WALK,
								modeOrigin: 'WALK',
								route: '',
								headsign: undefined,
								routeId: undefined,
								agencyId: undefined,
								agencyName: undefined,
								tripShortName: undefined,
								tripId: undefined,
								from: {
									name: 'Gräfenhainichen, S-Bahnhof (Bus)',
									stopId: '1:000010389',
									lng: '12.45954',
									lat: '51.724946',
									arrival: '2022-09-29T15:52:00Z',
									departure: '2022-09-29T15:52:00Z',
								},
								to: {
									name: 'Gräfenhainichen, Sachsen-Anhalt, Deutschland',
									stopId: undefined,
									lng: '12.45462',
									lat: '51.72868',
									arrival: '2022-09-29T16:00:55Z',
									departure: undefined,
								},
								legGeometry: {
									points:
										'{ouzHco`kAHQs@mAKEKBIFOZo@`A_AtACBgD`EaCnCSVa@`@Y\\k@p@i@l@y@`A[\\m@r@]\\IFMHHDDFHPbAfBJTDL',
									length: 29,
								},
								intermediateStops: [],
								pricings: [],
								payment: null,
							},
						],
					},
					{
						startDateTime: '2022-09-29T14:59:52Z',
						endDateTime: '2022-09-29T16:00:55Z',
						duration: 3663,
						walkTime: 783,
						walkDistance: 988.513,
						legs: [
							{
								legId: '34497817-d52c-4c80-95c2-734ee8d522a4',
								startDateTime: '2022-09-29T14:59:52Z',
								startDateTimeFix: false,
								endDateTime: '2022-09-29T15:04:00Z',
								endDateTimeFix: false,
								duration: 248,
								distance: 307.778,
								mode: TransportType.WALK,
								modeOrigin: 'WALK',
								route: '',
								headsign: undefined,
								routeId: undefined,
								agencyId: undefined,
								agencyName: undefined,
								tripShortName: undefined,
								tripId: undefined,
								from: {
									name: 'Bitterfeld-Wolfen, Sachsen-Anhalt, Deutschland',
									stopId: undefined,
									lng: '12.32809',
									lat: '51.623',
									arrival: undefined,
									departure: '2022-09-29T14:59:52Z',
								},
								to: {
									name: 'Bitterfeld, Fisch Eck',
									stopId: '1:000004737',
									lng: '12.323842',
									lat: '51.62241',
									arrival: '2022-09-29T15:04:00Z',
									departure: '2022-09-29T15:04:00Z',
								},
								legGeometry: {
									points: 'urazHqyfjANn@H|@DZDb@@HHV`@dG@l@XbDHrAHbAIBE@?J',
									length: 15,
								},
								intermediateStops: [],
								pricings: [],
								payment: null,
							},
							{
								legId: 'f275722f-3f44-4aed-8ad5-15d3c248eba5',
								startDateTime: '2022-09-29T15:04:00Z',
								startDateTimeFix: true,
								endDateTime: '2022-09-29T15:10:00Z',
								endDateTimeFix: false,
								duration: 360,
								distance: 7111.820869981328,
								mode: TransportType.LBT,
								modeOrigin: 'BUS',
								route: 'Bus 423',
								headsign: 'Muldenstein, S-Bahnhof (Bus)',
								routeId: '1:VETTAB423',
								agencyId: '1:00852',
								agencyName: 'Vetter Verkehrsbetriebe',
								tripShortName: '4230426',
								tripId: '1:110123',
								from: {
									name: 'Bitterfeld, Fisch Eck',
									stopId: '1:000004737',
									lng: '12.323842',
									lat: '51.62241',
									arrival: '2022-09-29T15:04:00Z',
									departure: '2022-09-29T15:04:00Z',
								},
								to: {
									name: 'Pouch, Schiffmühlenweg',
									stopId: '1:000004500',
									lng: '12.411102',
									lat: '51.620821',
									arrival: '2022-09-29T15:10:00Z',
									departure: '2022-09-29T15:22:00Z',
								},
								legGeometry: {
									points:
										'aoazH__fjAkc@{k@??uYwE??`Om\\??uHkk@??}EsU??dCsj@??jdAu`J',
									length: 14,
								},
								intermediateStops: [
									{
										arrival: '2022-09-29T15:06:00Z',
										departure: '2022-09-29T15:06:00Z',
										name: 'Bitterfeld, Stadt Wien',
									},
									{
										arrival: '2022-09-29T15:06:00Z',
										departure: '2022-09-29T15:06:00Z',
										name: 'Bitterfeld, Krankenhaus',
									},
									{
										arrival: '2022-09-29T15:08:00Z',
										departure: '2022-09-29T15:08:00Z',
										name: 'Bitterfeld, Friedhof',
									},
									{
										arrival: '2022-09-29T15:09:00Z',
										departure: '2022-09-29T15:09:00Z',
										name: 'Bitterfeld, Vor dem Muldedamm',
									},
									{
										arrival: '2022-09-29T15:10:00Z',
										departure: '2022-09-29T15:10:00Z',
										name: 'Bitterfeld, Bernsteinring',
									},
									{
										arrival: '2022-09-29T15:10:00Z',
										departure: '2022-09-29T15:10:00Z',
										name: 'Bitterfeld, Bernsteinvilla',
									},
								],
								pricings: [],
								payment: null,
							},
							{
								legId: '4c55f6c6-41c6-4a1c-80d6-3e6a6945a501',
								startDateTime: '2022-09-29T15:22:00Z',
								startDateTimeFix: false,
								endDateTime: '2022-09-29T15:32:00Z',
								endDateTimeFix: false,
								duration: 600,
								distance: 9935.695568214138,
								mode: TransportType.LBT,
								modeOrigin: 'BUS',
								route: 'Bus 420',
								headsign: 'Krina, Ortsmitte',
								routeId: '1:VETTAB420',
								agencyId: '1:00852',
								agencyName: 'Vetter Verkehrsbetriebe',
								tripShortName: '4200428',
								tripId: '1:109530',
								from: {
									name: 'Pouch, Schiffmühlenweg',
									stopId: '1:000004500',
									lng: '12.411102',
									lat: '51.620821',
									arrival: '2022-09-29T15:10:00Z',
									departure: '2022-09-29T15:22:00Z',
								},
								to: {
									name: 'Gossa, Ortsmitte',
									stopId: '1:000090494',
									lng: '12.444994',
									lat: '51.670004',
									arrival: '2022-09-29T15:32:00Z',
									departure: '2022-09-29T15:36:00Z',
								},
								legGeometry: {
									points:
										'ceazHk`wjA}hCkgB??{nAaxB??fe@hxD??wo@{_@??gPuY??d@wW??_~@kvA',
									length: 14,
								},
								intermediateStops: [
									{
										arrival: '2022-09-29T15:25:00Z',
										departure: '2022-09-29T15:25:00Z',
										name: 'Schlaitz, Schachtbaude',
									},
									{
										arrival: '2022-09-29T15:27:00Z',
										departure: '2022-09-29T15:27:00Z',
										name: 'Plodda, Gemeindeamt',
									},
									{
										arrival: '2022-09-29T15:28:00Z',
										departure: '2022-09-29T15:28:00Z',
										name: 'Schlaitz, Haus am See',
									},
									{
										arrival: '2022-09-29T15:29:00Z',
										departure: '2022-09-29T15:29:00Z',
										name: 'Schlaitz, Freiheitsstr./Altenheim',
									},
									{
										arrival: '2022-09-29T15:30:00Z',
										departure: '2022-09-29T15:30:00Z',
										name: 'Schlaitz, August-Bebel-Str.',
									},
									{
										arrival: '2022-09-29T15:30:00Z',
										departure: '2022-09-29T15:30:00Z',
										name: 'Schlaitz, Freiheitsstr.',
									},
								],
								pricings: [],
								payment: null,
							},
							{
								legId: '19e9babc-3bf1-407d-951b-7f9f7dc3f9d9',
								startDateTime: '2022-09-29T15:36:00Z',
								startDateTimeFix: false,
								endDateTime: '2022-09-29T15:52:00Z',
								endDateTimeFix: true,
								duration: 960,
								distance: 8279.495183488592,
								mode: TransportType.LBT,
								modeOrigin: 'BUS',
								route: 'Bus 420',
								headsign: 'Gräfenhainichen, S-Bahnhof (Bus)',
								routeId: '1:VETTAB420',
								agencyId: '1:00852',
								agencyName: 'Vetter Verkehrsbetriebe',
								tripShortName: '4200478',
								tripId: '1:109539',
								from: {
									name: 'Gossa, Ortsmitte',
									stopId: '1:000090494',
									lng: '12.444994',
									lat: '51.670004',
									arrival: '2022-09-29T15:32:00Z',
									departure: '2022-09-29T15:36:00Z',
								},
								to: {
									name: 'Gräfenhainichen, S-Bahnhof (Bus)',
									stopId: '1:000010389',
									lng: '12.45954',
									lat: '51.724946',
									arrival: '2022-09-29T15:52:00Z',
									departure: '2022-09-29T15:52:00Z',
								},
								legGeometry: {
									points: 'oxjzHet}jA{aCgV??otE~uA??|@ufA??yTk~@??zSsR',
									length: 10,
								},
								intermediateStops: [
									{
										arrival: '2022-09-29T15:40:00Z',
										departure: '2022-09-29T15:40:00Z',
										name: 'Gröbern (Muldestausee)',
									},
									{
										arrival: '2022-09-29T15:48:00Z',
										departure: '2022-09-29T15:48:00Z',
										name: 'Gräfenhainichen, Industriegelände',
									},
									{
										arrival: '2022-09-29T15:49:00Z',
										departure: '2022-09-29T15:49:00Z',
										name: 'Gräfenhainichen, Ambau',
									},
									{
										arrival: '2022-09-29T15:50:00Z',
										departure: '2022-09-29T15:50:00Z',
										name: 'Gräfenhainichen, Bahnhofstr.',
									},
								],
								pricings: [],
								payment: null,
							},
							{
								legId: '924793d9-172d-4e42-b3ec-4b2178652d87',
								startDateTime: '2022-09-29T15:52:00Z',
								startDateTimeFix: false,
								endDateTime: '2022-09-29T16:00:55Z',
								endDateTimeFix: false,
								duration: 535,
								distance: 680.735,
								mode: TransportType.WALK,
								modeOrigin: 'WALK',
								route: '',
								headsign: undefined,
								routeId: undefined,
								agencyId: undefined,
								agencyName: undefined,
								tripShortName: undefined,
								tripId: undefined,
								from: {
									name: 'Gräfenhainichen, S-Bahnhof (Bus)',
									stopId: '1:000010389',
									lng: '12.45954',
									lat: '51.724946',
									arrival: '2022-09-29T15:52:00Z',
									departure: '2022-09-29T15:52:00Z',
								},
								to: {
									name: 'Gräfenhainichen, Sachsen-Anhalt, Deutschland',
									stopId: undefined,
									lng: '12.45462',
									lat: '51.72868',
									arrival: '2022-09-29T16:00:55Z',
									departure: undefined,
								},
								legGeometry: {
									points:
										'{ouzHco`kAHQs@mAKEKBIFOZo@`A_AtACBgD`EaCnCSVa@`@Y\\k@p@i@l@y@`A[\\m@r@]\\IFMHHDDFHPbAfBJTDL',
									length: 29,
								},
								intermediateStops: [],
								pricings: [],
								payment: null,
							},
						],
					},
				],
			});
		}, 2000);
	});
};

export const getBookings = (): CancelablePromise<Array<PassengerBooking>> => {
	return new CancelablePromise((resolve) => {
		setTimeout(() => {
			resolve([
				{
					passengers: 0,
					id: 'id_20228Z5F3J6S',
					deleted: false,
					bookingNumber: '20228Z5F3J6S',
					specialNeeds: 'NONE',
					itinerary: {
						startDateTime: '2023-07-21T17:32:28Z',
						endDateTime: '2023-07-21T17:33:28Z',
						duration: 0,
						walkTime: 0,
						walkDistance: 0,
						legs: [
							{
								legId: '34497817-d52c-4c80-95c2-734ee8d522a4',
								startDateTime: '2022-07-21T17:32:28Z',
								startDateTimeFix: true,
								endDateTime: '2022-07-21T17:33:28Z',
								endDateTimeFix: true,
								duration: 0,
								distance: 0,
								mode: TransportType.LBT,
								modeOrigin: 'string',
								route: 'A310',
								headsign: 'Bitterfeld, Bahnhof (Bus)',
								routeId: '4:VETTABR408',
								agencyId: '4:00844',
								tripId: '4:125902',
								from: {
									name: 'Bitterfeld, Erich-Kästner-Schule',
									lng: '51.626733',
									lat: '51.626733',
									stopId: '3:000004712',
									arrival: '2022-07-21T17:32:28Z',
									departure: '2022-07-21T17:32:28Z',
								},
								to: {
									name: 'Bitterfeld, Erich-Kästner-Schule',
									lng: '51.626733',
									lat: '51.626733',
									stopId: '3:000004712',
									arrival: '2022-07-21T17:32:28Z',
									departure: '2022-07-21T17:32:28Z',
								},
								legGeometry: {
									points: 'string',
									length: 0,
								},
								intermediateStops: [
									{
										arrival: '2022-07-21T17:32:28Z',
										departure: '2022-07-21T17:32:28Z',
										name: 'Müllerstr. Nebendorf',
									},
								],
								pricings: [],
								payment: null,
							},
						],
					},

					bookingStatus: BookingStatus.NEW,
					payment: {
						paymentAmount: 0,
						overwrittenPaymentAmount: 0,
						paymentInformationAvailable: false,
						paymentState: PaymentState.PAID,
						currency: null,
						paymentForm: null,
					},
				},
				{
					passengers: 0,
					id: 'id_3KZSNG',
					deleted: false,
					bookingNumber: '3KZSNG',
					specialNeeds: 'NONE',
					itinerary: {
						startDateTime: '2022-07-21T17:32:28Z',
						endDateTime: '2022-07-21T17:34:28Z',
						duration: 0,
						walkTime: 0,
						walkDistance: 0,
						legs: [
							{
								legId: '34497817-d52c-4c80-95c2-734ee8d522a4',
								startDateTime: '2022-07-21T17:32:28Z',
								startDateTimeFix: true,
								endDateTime: '2022-07-21T17:34:28Z',
								endDateTimeFix: true,
								duration: 0,
								distance: 0,
								mode: TransportType.LBT,
								modeOrigin: 'string',
								route: 'A310',
								headsign: 'Bitterfeld, Bahnhof (Bus)',
								routeId: '4:VETTABR408',
								agencyId: '4:00844',
								tripId: '4:125902',
								from: {
									name: 'Bitterfeld, Erich-Kästner-Schule',
									lng: '51.626733',
									lat: '51.626733',
									stopId: '3:000004712',
									arrival: '2022-07-21T17:32:28Z',
									departure: '2022-07-21T17:32:28Z',
								},
								to: {
									name: 'Bitterfeld, Erich-Kästner-Schule',
									lng: '51.626733',
									lat: '51.626733',
									stopId: '3:000004712',
									arrival: '2022-07-21T17:32:28Z',
									departure: '2022-07-21T17:32:28Z',
								},
								legGeometry: {
									points: 'string',
									length: 0,
								},
								intermediateStops: [
									{
										arrival: '2022-07-21T17:32:28Z',
										departure: '2022-07-21T17:32:28Z',
										name: 'Müllerstr. Nebendorf',
									},
								],
								pricings: [],
								payment: null,
							},
						],
					},

					bookingStatus: BookingStatus.NEW,
					payment: {
						paymentAmount: 0,
						overwrittenPaymentAmount: 0,
						paymentInformationAvailable: false,
						paymentState: PaymentState.PAID,
						currency: null,
						paymentForm: null,
					},
				},
				{
					passengers: 0,
					id: 'id_4KZSNG',
					deleted: false,
					bookingNumber: '4KZSNG',
					specialNeeds: 'NONE',
					itinerary: {
						startDateTime: '2022-07-21T17:32:28Z',
						endDateTime: '2022-07-21T17:35:28Z',
						duration: 0,
						walkTime: 0,
						walkDistance: 0,
						legs: [
							{
								legId: '34497817-d52c-4c80-95c2-734ee8d522a4',
								startDateTime: '2022-07-21T17:32:28Z',
								startDateTimeFix: true,
								endDateTime: '2022-07-21T17:35:28Z',
								endDateTimeFix: true,
								duration: 0,
								distance: 0,
								mode: TransportType.LBT,
								modeOrigin: 'string',
								route: 'A310',
								headsign: 'Bitterfeld, Bahnhof (Bus)',
								routeId: '4:VETTABR408',
								agencyId: '4:00844',
								tripId: '4:125902',
								from: {
									name: 'Bitterfeld, Erich-Kästner-Schule',
									lng: '51.626733',
									lat: '51.626733',
									stopId: '3:000004712',
									arrival: '2022-07-21T17:32:28Z',
									departure: '2022-07-21T17:32:28Z',
								},
								to: {
									name: 'Bitterfeld, Erich-Kästner-Schule',
									lng: '51.626733',
									lat: '51.626733',
									stopId: '3:000004712',
									arrival: '2022-07-21T17:32:28Z',
									departure: '2022-07-21T17:32:28Z',
								},
								legGeometry: {
									points: 'string',
									length: 0,
								},
								intermediateStops: [
									{
										arrival: '2022-07-21T17:32:28Z',
										departure: '2022-07-21T17:32:28Z',
										name: 'Müllerstr. Nebendorf',
									},
								],
								pricings: [],
								payment: null,
							},
						],
					},

					bookingStatus: BookingStatus.NEW,
					payment: {
						paymentAmount: 0,
						overwrittenPaymentAmount: 0,
						paymentInformationAvailable: false,
						paymentState: PaymentState.PAID,
						currency: null,
						paymentForm: null,
					},
				},
				{
					passengers: 0,
					id: 'id_5KZSNG',
					deleted: false,
					bookingNumber: '5KZSNG',
					specialNeeds: 'NONE',
					itinerary: {
						startDateTime: '2022-07-21T17:32:28Z',
						endDateTime: '2022-07-21T17:36:28Z',
						duration: 0,
						walkTime: 0,
						walkDistance: 0,
						legs: [
							{
								legId: '34497817-d52c-4c80-95c2-734ee8d522a4',
								startDateTime: '2022-07-21T17:32:28Z',
								startDateTimeFix: true,
								endDateTime: '2022-07-21T17:36:28Z',
								endDateTimeFix: true,
								duration: 0,
								distance: 0,
								mode: TransportType.LBT,
								modeOrigin: 'string',
								route: 'A310',
								headsign: 'Bitterfeld, Bahnhof (Bus)',
								routeId: '4:VETTABR408',
								agencyId: '4:00844',
								tripId: '4:125902',
								from: {
									name: 'Bitterfeld, Erich-Kästner-Schule',
									lng: '51.626733',
									lat: '51.626733',
									stopId: '3:000004712',
									arrival: '2022-07-21T17:32:28Z',
									departure: '2022-07-21T17:32:28Z',
								},
								to: {
									name: 'Bitterfeld, Erich-Kästner-Schule',
									lng: '51.626733',
									lat: '51.626733',
									stopId: '3:000004712',
									arrival: '2022-07-21T17:32:28Z',
									departure: '2022-07-21T17:32:28Z',
								},
								legGeometry: {
									points: 'string',
									length: 0,
								},
								intermediateStops: [
									{
										arrival: '2022-07-21T17:32:28Z',
										departure: '2022-07-21T17:32:28Z',
										name: 'Müllerstr. Nebendorf',
									},
								],
								pricings: [],
								payment: null,
							},
						],
					},

					bookingStatus: BookingStatus.FINISHED,
					payment: {
						paymentAmount: 0,
						overwrittenPaymentAmount: 0,
						paymentInformationAvailable: false,
						paymentState: PaymentState.PAID,
						currency: null,
						paymentForm: null,
					},
				},
			]);
		}, 2000);
	});
};
