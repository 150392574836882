import i18n from 'i18next';

const dateFormatPresets: Record<TPreset, Intl.DateTimeFormatOptions> = {
	weekDayShort: {
		weekday: 'short',
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
	},
	weekDayLong: {
		weekday: 'long',
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
	},
};

type TPreset = 'weekDayShort' | 'weekDayLong';
type TIntlCfg = Intl.DateTimeFormatOptions;

export function formatDate(date: Date, cfg: TPreset): string;
export function formatDate(date: Date, cfg: TIntlCfg): string;
export function formatDate(date: Date): string;
export function formatDate(
	date: Date,
	cfg: TIntlCfg | TPreset = 'weekDayShort'
) {
	const locale = i18n.language.replace('_', '-');

	let preparedOptions: Intl.DateTimeFormatOptions;

	if (typeof cfg === 'string') {
		preparedOptions = dateFormatPresets[cfg];
	} else {
		preparedOptions = {
			...dateFormatPresets.weekDayShort,
			...cfg,
		};
	}

	return date.toLocaleDateString(locale, preparedOptions);
}

export const formatTime = (date: Date, options: TIntlCfg = {}) => {
	const locale = i18n.language.replace('_', '-');

	const preparedOptions: TIntlCfg = {
		hour: '2-digit',
		minute: '2-digit',
		...options,
	};

	return date.toLocaleTimeString(locale, preparedOptions);
};
