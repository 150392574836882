/* istanbul ignore file */
/* tslint:disable */

/* eslint-disable */

export enum DiscountState {
	NO_DISCOUNT = 'NO_DISCOUNT',
	DISCOUNTED_TICKET_PRESENT = 'DISCOUNTED_TICKET_PRESENT',
	NON_DISCOUNTED_TICKET_PRESENT = 'NON_DISCOUNTED_TICKET_PRESENT',
	PERSONAL_DISCOUNT_AVAILABLE = 'PERSONAL_DISCOUNT_AVAILABLE',
	IMPAIRED = 'SEVERELY_DISABLED',
	IMPAIRED_COMPANION = 'COMPANION_OF_SEVERELY_DISABLED',
}
