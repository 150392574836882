import { FC, ReactElement } from 'react';

import { TMoplaColors } from '@mopla/mui';

import { Text, TTextVariant } from '../../atoms/Text';

import { Root } from './IconMessage.styles';

interface IInfoMessageProps {
	className?: string;
	icon?: ReactElement;
	message: string;
	color?: TMoplaColors;
	variant?: TTextVariant;
}

export const IconMessage: FC<IInfoMessageProps> = ({
	className,
	icon,
	message,
	color = 'mopla_dark_grey',
	variant = 'caption',
}) => {
	return (
		<Root className={className} sx={{ color: (theme) => theme.colors[color] }}>
			{icon}
			<Text className="label" variant={variant} color="inherit">
				{message}
			</Text>
		</Root>
	);
};
