import React from 'react';

export const WarnIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.37753 4.66221C10.5205 2.60478 13.4795 2.60479 14.6225 4.66221L21.2234 16.544C22.3343 18.5436 20.8884 21.0009 18.601 21.0009H5.39903C3.11157 21.0009 1.66567 18.5435 2.77656 16.544L9.37753 4.66221ZM12.8742 5.6335C12.4932 4.94769 11.5068 4.94769 11.1258 5.6335L4.52487 17.5152C4.15458 18.1818 4.63655 19.0009 5.39903 19.0009H18.601C19.3635 19.0009 19.8454 18.1818 19.4751 17.5152L12.8742 5.6335ZM12.9999 16.5009C12.9999 17.0532 12.5522 17.5009 11.9999 17.5009C11.4477 17.5009 10.9999 17.0532 10.9999 16.5009C10.9999 15.9486 11.4477 15.5009 11.9999 15.5009C12.5522 15.5009 12.9999 15.9486 12.9999 16.5009ZM12.9999 8.50089H10.9999V14.0009H12.9999V8.50089Z"
				fill="currentColor"
			/>
		</svg>
	);
};
