export const marketingItems: { header: string; adText: string }[] = [
	{
		header: 'intro_slides.slide1_title',
		adText: 'intro_slides.slide1_text',
	},
	{
		header: 'intro_slides.slide2_title',
		adText: 'intro_slides.slide2_text',
	},
	{
		header: 'intro_slides.slide3_title',
		adText: 'intro_slides.slide3_text',
	},
];
