import { defer, of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';

import {
	AddLocationAction,
	addLocationResult,
	AddLocationResultAction,
	LocationActionTypes,
} from '../actions/locationActions';
import { Effect } from '../business-logic';
import { ofType } from '../operators/ofType';

export const addLocationEffect: Effect<
	AddLocationAction,
	AddLocationResultAction
> = (actions$, dependencies) =>
	actions$.pipe(
		ofType(LocationActionTypes.AddLocation),
		concatMap((action: AddLocationAction) =>
			defer(async () => {
				await dependencies.db.location.upsert(action.payload);

				return addLocationResult('1');
			}).pipe(
				catchError((error) => {
					// handle error
					return of(addLocationResult(undefined, error));
				})
			)
		)
	);
