import { EntityDescriptor } from '../business-logic';
import { passengerBookingSchema } from '../schemas/passengerBooking';

export const passengerBookingEntity: EntityDescriptor = {
	passengerBooking: {
		migrationStrategies: {
			1: function (oldDoc: any) {
				// migration to rxdb 12
				return oldDoc;
			},
			2: function (oldDoc: any) {
				// remove isPaid and add paymentState instead
				return null;
			},
		},
		schema: passengerBookingSchema,
	},
};
