import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexColumn, Text } from '@mopla/ui';

import { MarketingItem } from './DTicketMarketingBlock.styles';

interface IProps {
	header: string;
	adText: string;
	image: React.ReactNode;
}
export const DTicketMarketingItem: React.FC<IProps> = (props) => {
	const { header, adText, image } = props;
	const { t } = useTranslation(['dticket']);

	return (
		<MarketingItem>
			{image}
			<FlexColumn className="space-between" style={{ gap: 4 }}>
				<Text variant="subtitle1">{t(header)}</Text>
				<Text variant="body3">{t(adText)}</Text>
			</FlexColumn>
		</MarketingItem>
	);
};
