import React from 'react';

export const RufbusIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask id="path-1-inside-1_71_1449" fill="white">
				<path d="M16 18.75C16 18.3358 16.3358 18 16.75 18H17.25C17.6642 18 18 18.3358 18 18.75V21.25C18 21.6642 17.6642 22 17.25 22H16.75C16.3358 22 16 21.6642 16 21.25V18.75Z" />
			</mask>
			<path
				d="M16.75 20H17.25V16H16.75V20ZM16 18.75V21.25H20V18.75H16ZM17.25 20H16.75V24H17.25V20ZM18 21.25V18.75H14V21.25H18ZM16.75 20C17.4404 20 18 20.5596 18 21.25H14C14 22.7688 15.2312 24 16.75 24V20ZM16 21.25C16 20.5596 16.5596 20 17.25 20V24C18.7688 24 20 22.7688 20 21.25H16ZM17.25 20C16.5596 20 16 19.4404 16 18.75H20C20 17.2312 18.7688 16 17.25 16V20ZM16.75 16C15.2312 16 14 17.2312 14 18.75H18C18 19.4404 17.4404 20 16.75 20V16Z"
				fill="currentColor"
				mask="url(#path-1-inside-1_71_1449)"
			/>
			<mask id="path-3-inside-2_71_1449" fill="white">
				<path d="M6 18.75C6 18.3358 6.33579 18 6.75 18H7.25C7.66421 18 8 18.3358 8 18.75V21.25C8 21.6642 7.66421 22 7.25 22H6.75C6.33579 22 6 21.6642 6 21.25V18.75Z" />
			</mask>
			<path
				d="M6.75 20H7.25V16H6.75V20ZM6 18.75V21.25H10V18.75H6ZM7.25 20H6.75V24H7.25V20ZM8 21.25V18.75H4V21.25H8ZM6.75 20C7.44036 20 8 20.5596 8 21.25H4C4 22.7688 5.23122 24 6.75 24V20ZM6 21.25C6 20.5596 6.55964 20 7.25 20V24C8.76878 24 10 22.7688 10 21.25H6ZM7.25 20C6.55964 20 6 19.4404 6 18.75H10C10 17.2312 8.76878 16 7.25 16V20ZM6.75 16C5.23122 16 4 17.2312 4 18.75H8C8 19.4404 7.44036 20 6.75 20V16Z"
				fill="currentColor"
				mask="url(#path-3-inside-2_71_1449)"
			/>
			<mask id="path-5-inside-3_71_1449" fill="white">
				<path d="M6 15.0112C6 14.9213 6.00759 14.8315 6.02268 14.7428L6.85837 9.83223C6.94017 9.35159 7.35665 9 7.8442 9H16.1558C16.6434 9 17.0598 9.35159 17.1416 9.83223L17.9773 14.7428C17.9924 14.8315 18 14.9213 18 15.0112V18.4C18 19.2837 17.2837 20 16.4 20H12H7.6C6.71634 20 6 19.2837 6 18.4V15.0112Z" />
			</mask>
			<path
				d="M17.1416 9.83223L15.17 10.1678L17.1416 9.83223ZM17.9773 14.7428L16.0057 15.0783L17.9773 14.7428ZM16 15.0112V18.4H20V15.0112H16ZM8 18.4V15.0112H4V18.4H8ZM16.4 18H12V22H16.4V18ZM12 18H7.6V22H12V18ZM7.99433 15.0783L8.83002 10.1678L4.88672 9.49669L4.05103 14.4073L7.99433 15.0783ZM15.17 10.1678L16.0057 15.0783L19.949 14.4073L19.1133 9.49669L15.17 10.1678ZM7.8442 11H16.1558V7H7.8442V11ZM19.1133 9.49669C18.8679 8.05478 17.6184 7 16.1558 7V11C15.6683 11 15.2518 10.6484 15.17 10.1678L19.1133 9.49669ZM4 18.4C4 20.3882 5.61178 22 7.6 22V18C7.82091 18 8 18.1791 8 18.4H4ZM16 18.4C16 18.1791 16.1791 18 16.4 18V22C18.3882 22 20 20.3882 20 18.4H16ZM20 15.0112C20 14.8088 19.9829 14.6068 19.949 14.4073L16.0057 15.0783C16.0019 15.0562 16 15.0337 16 15.0112H20ZM8.83002 10.1678C8.74823 10.6484 8.33174 11 7.8442 11V7C6.38156 7 5.1321 8.05478 4.88672 9.49669L8.83002 10.1678ZM8 15.0112C8 15.0337 7.9981 15.0562 7.99433 15.0783L4.05103 14.4073C4.01707 14.6068 4 14.8088 4 15.0112H8Z"
				fill="currentColor"
				mask="url(#path-5-inside-3_71_1449)"
			/>
			<path d="M16 14.5L7 14.5" stroke="currentColor" />
			<mask id="path-8-inside-4_71_1449" fill="white">
				<path d="M19.25 13H17V15H19.25C19.6642 15 20 14.6642 20 14.25V13.75C20 13.3358 19.6642 13 19.25 13Z" />
			</mask>
			<path
				d="M17 13V11H15V13H17ZM17 15H15V17H17V15ZM17 15H19.25V11H17V15ZM18 13.75V14.25H22V13.75H18ZM19.25 13H17V17H19.25V13ZM15 13V15H19V13H15ZM18 14.25C18 13.5596 18.5596 13 19.25 13V17C20.7688 17 22 15.7688 22 14.25H18ZM19.25 15C18.5596 15 18 14.4404 18 13.75H22C22 12.2312 20.7688 11 19.25 11V15Z"
				fill="currentColor"
				mask="url(#path-8-inside-4_71_1449)"
			/>
			<mask id="path-10-inside-5_71_1449" fill="white">
				<path d="M4.75 15L7 15L7 13L4.75 13C4.33579 13 4 13.3358 4 13.75L4 14.25C4 14.6642 4.33579 15 4.75 15Z" />
			</mask>
			<path
				d="M7 15L7 17L9 17L9 15L7 15ZM7 13L9 13L9 11L7 11L7 13ZM7 13L4.75 13L4.75 17L7 17L7 13ZM6 14.25L6 13.75L2 13.75L2 14.25L6 14.25ZM4.75 15L7 15L7 11L4.75 11L4.75 15ZM9 15L9 13L5 13L5 15L9 15ZM6 13.75C6 14.4404 5.44036 15 4.75 15L4.75 11C3.23122 11 2 12.2312 2 13.75L6 13.75ZM4.75 13C5.44036 13 6 13.5596 6 14.25L2 14.25C2 15.7688 3.23121 17 4.75 17L4.75 13Z"
				fill="currentColor"
				mask="url(#path-10-inside-5_71_1449)"
			/>
			<path
				d="M15.7071 6.70711L15.2929 6.29289C15.1054 6.10536 15.0024 5.84967 14.9957 5.58454C14.9556 4 14.5909 4 12 4C9.40911 4 9.04437 4 9.00435 5.58454C8.99765 5.84967 8.89464 6.10536 8.70711 6.29289L8.29289 6.70711C8.10536 6.89464 7.851 7 7.58579 7L5 7C4.44772 7 4 6.55228 4 6C4 6 4 2 12 2C20 2 20 6 20 6C20 6.55228 19.5523 7 19 7L16.4142 7C16.149 7 15.8946 6.89464 15.7071 6.70711Z"
				fill="currentColor"
			/>
		</svg>
	);
};
