import { Paper, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TooltipIcon } from '@mopla/ui';

export const Header = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content',
	alignItems: 'center',
}));

export const TooltipIconStyled = styled(TooltipIcon)(({ theme }) => ({
	color: theme.colors.mopla_secondary,
	display: 'block',
}));

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		margin: 0,
		marginRight: '4px !important',
		padding: 0,
		background: 'none',
		maxWidth: 220,
	},
}));

export const PaperStyled = styled(Paper)(() => ({
	padding: 8,
}));
