import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ArrowRightIcon, BadgeComponent, Button } from '@mopla/ui';

import { Root, Title } from './PushToApp.styles';

export const PushToApp = () => {
	const { t } = useTranslation('pushToApp');
	const navigate = useNavigate();

	return (
		<Root>
			<Title data-testid="welcomeScreen-appUpgrade-text">
				{t('text.title1')}
			</Title>
			<Title marginBottom="16px">{t('text.title2')}</Title>
			<Title marginBottom="24px">{t('text.title3')}</Title>
			<BadgeComponent />
			<Button
				variant="outlined"
				icon={<ArrowRightIcon />}
				type="button"
				data-testid="app-upgrade-skip-button"
				onClick={() => navigate('/home')}
			>
				{t('button.to_home_page')}
			</Button>
		</Root>
	);
};
