/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DispatcherAccessRights } from '../models/DispatcherAccessRights';
import type { PresenceStateEnum } from '../models/PresenceStateEnum';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DispatchersService {
	/**
	 * get all saved dispatchers for my distributor
	 * get all saved dispatchers for my distributor
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDispatchers(): CancelablePromise<
		Array<{
			/**
			 * the servers id for the dispatcher
			 */
			uuid?: string;
			/**
			 * first name of dispatcher (user)
			 */
			firstName?: string;
			/**
			 * first name of dispatcher (user)
			 */
			lastName?: string;
			hasDisposition?: PresenceStateEnum;
			hasShifts?: PresenceStateEnum;
			hasAdministration?: PresenceStateEnum;
			hasService?: PresenceStateEnum;
		}>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/dispatchers',
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}

	/**
	 * get details of dispatcher
	 * get details of dispatcher
	 * @param dispatcherid
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiDispatchers1(dispatcherid: string): CancelablePromise<{
		/**
		 * the servers id for the dispatcher
		 */
		uuid?: string;
		/**
		 * first name of dispatcher (user)
		 */
		firstName?: string;
		/**
		 * first name of dispatcher (user)
		 */
		lastName?: string;
		/**
		 * email of dispatcher (user)
		 */
		email?: string;
		accessRights?: DispatcherAccessRights;
	}> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/dispatchers/{dispatcherid}',
			path: {
				dispatcherid: dispatcherid,
			},
			errors: {
				401: `Unauthorized`,
				403: `Forbidden`,
				404: `Not Found`,
			},
		});
	}
}
