import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateRows: '1fr max-content',
	...theme.fonts.body3,
	color: theme.colors.mopla_dark_grey,

	'& > span:last-of-type': {
		marginTop: 8,
	},
}));

export const TextWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	...theme.fonts.body1,
	color: theme.colors.mopla_black,
	height: 'fit-content',

	'& > div': {
		marginLeft: 8,
		display: 'grid',
		alignItems: 'center',
	},
}));
