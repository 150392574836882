import React, { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';

import { Text } from '../../atoms/Text/Text';
import { CheckboxNegative } from '../../icons/CheckboxNegative';
import { CheckboxPositive } from '../../icons/CheckboxPositive';
import { GermanyMapNew } from '../../icons/GermanyMapNew';

import {
	AdditionalContent,
	AdditionalContentText,
	Content,
	Header,
	Root,
	SeeMoreButton,
} from './ServiceStatusBlock.styles';

interface IProps {
	title: string;
	mainText: string;
	additionalText?: string;
	status: boolean;
}

export const ServiceStatusBlock: FC<IProps> = (props) => {
	const { title, additionalText, mainText, status } = props;
	const { t } = useTranslation(['common']);

	const handleSeeMore: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		Browser.open({ url: 'https://mopla.solutions' });
	};

	return (
		<Root>
			<Header>
				<Text variant="subtitle1" color="mopla_white">
					{title}
				</Text>
				{status ? <CheckboxPositive /> : <CheckboxNegative />}
			</Header>
			<Content>
				<Text variant="body1">{mainText}</Text>
			</Content>
			{additionalText && (
				<AdditionalContent>
					<GermanyMapNew />
					<AdditionalContentText>
						<Text variant="body3">{additionalText}</Text>
						<SeeMoreButton variant="text" onClick={handleSeeMore}>
							<Text variant="body3_semibold" color="mopla_secondary">
								{t('common:mopla_area.link')}
							</Text>
						</SeeMoreButton>
					</AdditionalContentText>
				</AdditionalContent>
			)}
		</Root>
	);
};
