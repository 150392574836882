/* istanbul ignore file */
/* tslint:disable */

/* eslint-disable */

export enum TransportType {
	WALK = 'WALK',
	LBT = 'LBT',
	LBODT = 'LBODT',
	LBODTFLEX = 'LBODTFLEX',
}
