import React from 'react';

interface AttentionIconProps {
	width?: number;
	height?: number;
}

export const AttentionIcon: React.FC<AttentionIconProps> = ({
	width = 24,
	height = 24,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.37753 4.6613C10.5205 2.60386 13.4795 2.60387 14.6225 4.6613L21.2234 16.543C22.3343 18.5426 20.8884 21 18.601 21H5.39903C3.11157 21 1.66567 18.5426 2.77656 16.543L9.37753 4.6613ZM12.8742 5.63258C12.4932 4.94677 11.5068 4.94677 11.1258 5.63258L4.52487 17.5143C4.15458 18.1809 4.63655 19 5.39903 19H18.601C19.3635 19 19.8454 18.1809 19.4751 17.5143L12.8742 5.63258ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16ZM12 7.99997C11.4477 7.99997 11 8.44769 11 8.99997V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V8.99997C13 8.44769 12.5523 7.99997 12 7.99997Z"
				fill="currentColor"
			/>
		</svg>
	);
};
