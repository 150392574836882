import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { noop } from 'rxjs';

import { useUser } from '@mopla/business-logic';
import { DiscountState, Itinerary, SpecialNeeds } from '@mopla/data-models';
import {
	AttentionIcon,
	DropdownIcon,
	EditIcon,
	FlexColumn,
	Text,
} from '@mopla/ui';
import { CheckboxWithLink } from '@mopla/ui/molecules/CheckboxWithLink/CheckboxWithLink';
import { formatPrice } from '@mopla/utils';

import { useBookingContext } from './bookingContext';
import {
	AttentionWrapper,
	BlockTitle,
	EditPaymentWrapper,
	EditTicketsWrapper,
	InfoBox,
	InfoBoxColumn,
	PaymentDetails,
	PaymentDetailsButton,
	PaymentTitle,
	RouteSuggestionStyled,
	SpecialNeedsTitle,
} from './BookingFlow.styles';
import { TBookingFormState } from './types';
import { PaymentMethodSummary, PriceCalcSummaryItem } from './ui';

interface SecondStepProps {
	ticket: Itinerary;
	onEdit: VoidFunction;
	onPriceEdit: VoidFunction;
	specialNeeds: SpecialNeeds;
	isAcceptTariffs: boolean;
	toggleTariffsCheckbox: VoidFunction;
}

export const SecondBookingStep: React.FC<SecondStepProps> = ({
	ticket,
	onEdit,
	onPriceEdit,
	specialNeeds,
	isAcceptTariffs,
	toggleTariffsCheckbox,
}) => {
	const { userData } = useUser();
	const { getValues } = useFormContext<TBookingFormState>();
	const currentValues = getValues();
	const { t } = useTranslation(['booking', 'specialNeeds']);
	const [showPaymentDetails, setShowPaymentDetails] = useState(true);
	const { isLBODTFlex, isLBTFlow, isMixedRoute } = useBookingContext();

	const publicTransOrReducedFeeCount = useMemo(() => {
		const attendees = getValues('attendees2');
		const main = getValues('main');

		return [...attendees, main].reduce((cnt, passenger) => {
			if (
				passenger.discountState &&
				[
					DiscountState.NON_DISCOUNTED_TICKET_PRESENT,
					DiscountState.DISCOUNTED_TICKET_PRESENT,
					DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
				].includes(passenger.discountState)
			) {
				return cnt + 1;
			}

			return cnt;
		}, 0);
	}, [getValues]);

	return (
		<>
			<InfoBoxColumn>
				<RouteSuggestionStyled
					details={ticket}
					onDetailsClick={noop}
					showAttention={false}
					showPrice={false}
					showOperators
				/>
				<AttentionWrapper>
					{isMixedRoute && (
						<>
							<AttentionIcon />
							<span>{t('common:text.attentionMixedRoutes')}</span>
						</>
					)}
					{!isLBTFlow && (
						<>
							<AttentionIcon />
							<span data-testid="routeDetails-departureTimeCanBeChanged-notice">
								{t('text.time_can_change')}
							</span>
						</>
					)}
				</AttentionWrapper>
				{!isLBTFlow && (
					<SpecialNeedsTitle>
						{t(`specialNeeds:text.${specialNeeds}`)}
					</SpecialNeedsTitle>
				)}
			</InfoBoxColumn>
			{!isLBTFlow && (
				<InfoBoxColumn>
					<EditTicketsWrapper>
						<BlockTitle data-testid="booking-ticketOwner-ticketCount-text">
							{t('text.ticketFor', {
								count: currentValues.attendees2.length + 1,
							})}
						</BlockTitle>
						<button
							type="button"
							onClick={onEdit}
							data-testid="booking-ticketOwner-edit-icon"
						>
							<EditIcon />
						</button>
					</EditTicketsWrapper>
					{publicTransOrReducedFeeCount > 0 && (
						<AttentionWrapper>
							<AttentionIcon />
							<span>
								{t('text.valiationRquired', {
									count: publicTransOrReducedFeeCount,
								})}
							</span>
						</AttentionWrapper>
					)}
					<FlexColumn data-testid="booking-ticketOwner-personalData-block">
						<Text variant="body1" sx={{ mb: 0.5 }}>
							{currentValues.name && currentValues.surname
								? `${currentValues.name} ${currentValues.surname}`
								: userData?.name}
							{currentValues.attendees2.length
								? ` + ${t('booking:text.additional_passengers', {
										count: currentValues.attendees2.length,
								  })} `
								: ''}
						</Text>
						<Text variant="body3" color="mopla_dark_grey" ellipsis>
							{currentValues.phoneNumber || userData?.phone}
						</Text>
						<Text variant="body3" color="mopla_dark_grey" ellipsis>
							{currentValues.email || userData?.email}
						</Text>
					</FlexColumn>
					{isLBODTFlex && (
						<div>
							<EditPaymentWrapper>
								<PaymentDetailsButton
									type="button"
									onClick={() => setShowPaymentDetails((p) => !p)}
								>
									<PaymentTitle data-testid="booking-payment-title-text">
										{t('text.header.price_calculation')}
									</PaymentTitle>
									<DropdownIcon isOpened={showPaymentDetails} />
								</PaymentDetailsButton>
								<button
									type="button"
									onClick={onPriceEdit}
									data-testid="booking-payment-edit-icon"
								>
									<EditIcon />
								</button>
							</EditPaymentWrapper>
							{showPaymentDetails && (
								<PaymentDetails>
									<PriceCalcSummaryItem
										name={`${currentValues.name} ${currentValues.surname}:`}
										value={formatPrice(
											(currentValues.main?.amountToPay as number) / 100
										)}
										discount={t(`text.${currentValues.main?.discountState}`)}
									/>
									{currentValues.attendees2.map((attendee, index) => (
										<PriceCalcSummaryItem
											key={`attendees-${index}`}
											name={t('text.attendee', {
												count: index + 1,
												context: 'order',
											})}
											value={formatPrice((attendee.amountToPay || 0) / 100)}
											discount={t(`text.${attendee.discountState}`)}
										/>
									))}
									{[...Array(currentValues.toddlerSeats)].map((_, index) => (
										<PriceCalcSummaryItem
											key={`toddlers-${index}`}
											name={t('text.attendee', {
												count: currentValues.attendees2.length + index + 1,
												context: 'order',
											})}
											value={formatPrice(0)}
											discount={t('text.toddlers_tariff')}
										/>
									))}
									{currentValues.bulkyLuggage && (
										<PriceCalcSummaryItem
											key="bulkyLuggage"
											name={t('text.bulkyLuggage', {
												count: 1,
												context: 'count',
											})}
											value={formatPrice(0)}
										/>
									)}
								</PaymentDetails>
							)}
						</div>
					)}
				</InfoBoxColumn>
			)}
			<PaymentMethodSummary />
			{!isLBTFlow && (
				<InfoBox>
					<CheckboxWithLink
						url="https://mopla-solutions.webflow.io/de/tarifbestimmung-und-beforderungsbedingungen"
						linkText="booking:text.booking_tariffs"
						checked={isAcceptTariffs}
						toggleCheckbox={toggleTariffsCheckbox}
					/>
				</InfoBox>
			)}
		</>
	);
};

export default SecondBookingStep;
