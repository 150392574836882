import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { RadioGroup } from '@mui/material';
import dayjs from 'dayjs';

import {
	ESubscriptionType,
	ISubscriptionOffering,
	TSubscription,
} from '@mopla/data-models';
import { FlexColumn, Text } from '@mopla/ui';
import { getDTicketType, getPausedMonthsList } from '@mopla/utils';
import { formatPrice } from '@mopla/utils';

import { BASIC_TICKET_COST } from '../constants';
import {
	StyledFormControlLabel,
	StyledRadio,
} from '../DTicketSubscription.styles';
import { ISubscriptionFormValues } from '../types';

import { ContentBlock } from './ContentBlock';

interface IProps {
	type?: 'resume' | 'subscribe';
	subscription?: TSubscription;
	subscriptionOffering?: ISubscriptionOffering | null;
}

export const FirstStep: React.FC<IProps> = ({
	type = 'subscribe',
	subscription = {} as TSubscription,
	subscriptionOffering,
}) => {
	const { t } = useTranslation(['dticket', 'validation']);
	const methods = useFormContext<ISubscriptionFormValues>();
	const {
		control,
		formState: { errors },
	} = methods;
	const isResumeSubscription = type === 'resume';
	const isPausedSubscription = subscription?.status === 'PAUSED';

	const {
		isJobticket,
		price,
		fullPrice,
		subscriptionType,
		offers = [],
	} = subscriptionOffering || {};

	const isSpenditType = subscriptionType === ESubscriptionType.SPENDIT;
	const isMoplaType = subscriptionType === ESubscriptionType.MOPLA;
	const dTicketType = getDTicketType(subscriptionOffering?.subscriptionType);

	const monthsList = useMemo(() => {
		if (isPausedSubscription) {
			const { pauseStart, pauseEnd } = subscription;
			return getPausedMonthsList(pauseStart as string, pauseEnd as string);
		}

		return offers.map(({ startDate }) => dayjs(startDate));
	}, [isPausedSubscription, offers, subscription]);

	const getDescriptionTextKey = () => {
		if (!isJobticket) {
			if (isResumeSubscription)
				return 'dticket:text.resume_subscription_description';
			return 'dticket:text.dticket_description';
		}
		if (isSpenditType) return 'dticket:text.d_jobticket_description_spendit';
		if (isMoplaType) return 'dticket:text.d_jobticket_description_mopla';
		return 'dticket:text.d_jobticket_description_discount';
	};

	const descriptionValue = {
		dTicketType,
		jobticketCostPerMonth: t('dticket:text.d_jobticket_cost_per_month', {
			fullPrice: formatPrice(fullPrice || BASIC_TICKET_COST),
		}),
		jobticketEmployerCredit: isSpenditType
			? t('dticket:text.d_jobticket_employer_credit_spendit', {
					price: formatPrice(price ?? BASIC_TICKET_COST),
			  })
			: t('dticket:text.d_jobticket_employer_credit_mopla', {
					price: formatPrice(price ?? BASIC_TICKET_COST),
					discount: formatPrice(
						(fullPrice || BASIC_TICKET_COST) - (price ?? 0)
					),
			  }),
	};

	return (
		<ContentBlock headerText={t('text.choose_start_date')}>
			<Text variant="body1">
				<Trans
					i18nKey={getDescriptionTextKey()}
					values={descriptionValue}
					components={{ bold: <Text variant="body1_semibold" /> }}
				/>
			</Text>
			<FlexColumn className="space-between" style={{ marginTop: 8 }}>
				<Controller
					name="monthOfStart"
					control={control}
					render={({ field }) => (
						<>
							<RadioGroup {...field}>
								{monthsList.map((month) => (
									<StyledFormControlLabel
										key={month.toString()}
										value={month.toString()}
										control={<StyledRadio />}
										label={month.format('1. MMMM YYYY')}
									/>
								))}
							</RadioGroup>
							{errors.monthOfStart?.message && (
								<Text variant="caption" color="mopla_red">
									{t(`validation:${errors.monthOfStart.message}`)}
								</Text>
							)}
						</>
					)}
				/>
			</FlexColumn>
		</ContentBlock>
	);
};
