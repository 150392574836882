import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSpenditToken } from '@mopla/business-logic';
import { Loader } from '@mopla/ui';

import { environment } from '../../../environments/environment';

export const SpenditPage: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { validateToken } = useSpenditToken();

	const query = useMemo(
		() => new URLSearchParams(location.search),
		[location.search]
	);

	const checkSpenditToken = useCallback(async () => {
		const spenditToken = query.get('spenditToken');

		const isTokenValid = await validateToken(
			spenditToken,
			environment.spenditValidationSetSource
		);
		if (isTokenValid && spenditToken) {
			localStorage.setItem('spenditToken', spenditToken);
		}
		navigate('/welcome', { replace: true });
	}, [navigate, query, validateToken]);

	useEffect(() => {
		checkSpenditToken();
	}, [checkSpenditToken]);

	return <Loader />;
};
