/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * ISO4217 currency codes in use (https://en.wikipedia.org/wiki/Currency)
 */
export enum CurrencyCode {
	EUR = 'EUR',
}
