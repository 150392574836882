/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Coordinate } from './Coordinate';
import type { Province } from './Province';

export type NamedLocation = {
	name: string;
	lng: Coordinate;
	lat: Coordinate;
	type?: NamedLocation.type;
	/**
	 * The zipcode of the from location
	 */
	zipcode?: string;
	province?: Province;
};

export namespace NamedLocation {
	export enum type {
		STOP = 'STOP',
		ADDRESS = 'ADDRESS',
	}
}
