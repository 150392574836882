import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorModal } from '../ErrorModal/ErrorModal';

export const useDiscardChangesPrompt = (
	confirmedCallback: () => void,
	hasChanges: boolean
) => {
	const { t } = useTranslation(['discardChangesPrompt']);
	const [confirmDiscardChanges, setConfirmDiscardChanges] = useState(false);

	const guardedTrigger = useCallback(() => {
		if (hasChanges) {
			setConfirmDiscardChanges(true);
		} else {
			confirmedCallback();
		}
	}, [confirmedCallback, hasChanges]);

	const confirmClose = useCallback(() => {
		setConfirmDiscardChanges(false);
		confirmedCallback();
	}, [confirmedCallback]);

	return {
		confirmDiscardChanges: guardedTrigger,
		DiscardChangesConfirmationPrompt: confirmDiscardChanges ? (
			<ErrorModal
				title={t('header')}
				infoText={t('content')}
				submitTitle={t('button.submit')}
				cancelTitle={t('button.cancel')}
				onSubmit={confirmClose}
				onCancel={() => setConfirmDiscardChanges(false)}
			/>
		) : null,
	};
};
