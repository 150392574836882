import { styled } from '@mui/material/styles';

import { TMoplaColors } from '@mopla/mui';

export const Footer = styled('div', {
	shouldForwardProp: (prop) => prop !== 'background',
})<{
	background?: TMoplaColors;
}>(({ theme, background }) => ({
	position: 'absolute',
	bottom: 0,
	left: 0,
	width: '100%',
	padding: '16px 24px 50px',
	boxSizing: 'border-box',

	...(background && { background: theme.colors[background] }),

	[theme.breakpoints.down('tabletP')]: {
		borderRadius: '8px 8px 0 0',
	},

	[theme.breakpoints.up('tabletP')]: {
		padding: '16px 24px',
		borderTop: `1px solid ${theme.colors.mopla_light_grey}`,
	},
}));
