import { useEffect, useState } from 'react';

import { ILocationDecoded } from '@mopla/data-models';
import { getLocationCategory } from '@mopla/utils';

import { hereSearch } from '../services/here';

/** The interface is not complete */
interface ILookupResult {
	title: string;
	id: string;
	language: string;
	resultType: string;
	localityType: string;
	address: {
		label: string;
		countryCode: string;
		countryName: string;
		stateCode: string;
		state: string;
		countyCode: string;
		county: string;
		city: string;
		postalCode: string;
		district?: string;
		street?: string;
		houseNumber?: string;
	};
	access?: Array<{
		lat: number;
		lng: number;
	}>;
	position: {
		lat: number;
		lng: number;
	};
	mapView: {
		west: number;
		south: number;
		east: number;
		north: number;
	};
	categories?: Array<{
		id: string;
		name?: string;
		primary?: true;
	}>;
}

const lookupById = (locationId: string) => {
	return new Promise<ILocationDecoded>((resolve, reject) => {
		hereSearch.lookup(
			{ id: locationId },
			(_result: unknown) => {
				const result = _result as ILookupResult;

				resolve({
					id: result.id,
					title: result.title,
					position: result.access?.length ? result.access[0] : result.position,
					address: {
						country: result.address.countryName,
						postCode: result.address.postalCode,
						city: result.address.city,
						stateCode: result.address.stateCode,
						street: result.address.street,
						houseNumber: result.address.houseNumber,
					},
					type: getLocationCategory(result, 'address'),
				});
			},
			reject
		);
	});
};

export const useDecodeLocationById = (locationId?: string) => {
	const [location, setLocation] = useState<ILocationDecoded | null>(null);

	useEffect(() => {
		if (!locationId) {
			return setLocation(null);
		}

		lookupById(locationId)
			.then(setLocation)
			.catch(() => setLocation(null));
	}, [locationId]);

	return location;
};
