import { useCallback, useContext } from 'react';
import { first, map, Subject, take, takeWhile, tap } from 'rxjs';

import { ITypedAction } from '@mopla/utils';

import { LocationActionTypes } from '../actions/locationActions';
import { PassengerBookingActionTypes } from '../actions/passengerBookingActions';
import { ScheduleActionTypes } from '../actions/scheduleActions';
import { SubscriptionActionTypes } from '../actions/subscriptionActions';
import { UserActionTypes } from '../actions/userActions';
import { BusinessLayerContext } from '../business-logic';
import { ofType } from '../operators/ofType';

/**
 * This hook is intended to allow to subscribe to ephemeral state, i.e. a response data of an http
 * request, that will not be stored in the local database
 *
 * An example is the orderId or list of warnings, the user receives after submitting a cart
 *
 * Based on the use case, a second count parameter can be passed to control how many emissions of this
 * action we want to receive. For adding a callback permanently, pass -1 as count. If count is positive integer,
 * the callback will be called on <count> number of emissions. If count is not being passed, only the first emission
 * will be considered. The subscription will unsubscribe automatically, once the intended number of emissions is reached
 *
 * @param type union of all defined actionTypes  throughout the app
 */
type TPossibleAction =
	| UserActionTypes
	| LocationActionTypes
	| PassengerBookingActionTypes
	| ScheduleActionTypes
	| SubscriptionActionTypes;

/** TODO watchActions from businessLayer can be used instead */
export const useActionNotification = <T extends TPossibleAction>(
	...types: T[]
) => {
	const businessLayer = useContext(BusinessLayerContext);
	const func = useCallback(
		(
			callback: (action: ITypedAction<T>) => void,
			count?: number | (() => boolean)
		) => {
			let obs;
			businessLayer.addEffect((action$: Subject<ITypedAction<T>>) => {
				obs = action$.pipe(
					ofType(...types),
					typeof count === 'function'
						? takeWhile(count)
						: count
						? take(count)
						: first(),
					tap((action: ITypedAction<T>) => callback(action)),
					map(() => null)
				);

				return obs;
			});
		},
		[businessLayer, types]
	);

	return func;
};
