import { ComponentProps, CSSProperties, FC, PropsWithChildren } from 'react';

import { Root } from './Text.styles';
import { TBoldParams, TTextColor, TTextVariant } from './types';

interface IProps {
	variant: TTextVariant;
	color?: TTextColor | CSSProperties['color'];
	bold?: TBoldParams;
	testId?: string;
	className?: string;
	sx?: ComponentProps<typeof Root>['sx'];
	ellipsis?: boolean;
}

export const Text: FC<PropsWithChildren<IProps>> = (props) => {
	const {
		children,
		variant,
		color = 'mopla_black',
		bold,
		testId,
		className,
		sx,
		ellipsis,
	} = props;

	return (
		<Root
			variant={variant}
			color={color}
			bold={bold}
			data-testid={testId}
			className={className}
			sx={sx}
			ellipsis={ellipsis}
		>
			{children}
		</Root>
	);
};
