export function isMoreThanOneTrue(arg: unknown[]) {
	let trueCount = 0;

	arg.forEach((v) => {
		if (v) {
			trueCount += 1;
		}
	});

	return trueCount > 1;
}

export const uniqueFilter = <T>(value: T, index: number, self: T[]): boolean =>
	self.indexOf(value) === index;
