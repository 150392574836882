import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';

import Button from '../../atoms/Button/Button';
import { CheckmarkIcon } from '../../icons/Checkmark';
import { CompleteAnim } from '../Animations';
import { Modal } from '../Modal/Modal';

import {
	ModalTitle,
	SuccessModalContent,
	Text,
	UploadedDate,
} from './SuccessModalWithDate.styles';

export interface UploadSuccessModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	label: string;
	description: string;
	btnName: string;
	dataTestid?: string;
}

export const SuccessModalWithDate: React.FC<UploadSuccessModalProps> = ({
	isOpen,
	onClose,
	title,
	label,
	description,
	btnName,
	dataTestid = 'success-modal',
}) => {
	const [rootNode] = React.useState(document.getElementById('portal'));

	if (rootNode === null) {
		return null;
	}

	return ReactDOM.createPortal(
		<Modal
			dataTestid={dataTestid}
			showClose
			backgroundColor="#ECEEF8"
			open={isOpen}
			animationElement={<CompleteAnim />}
			onClose={onClose}
			titleComp={
				<ModalTitle>
					<span>{title}</span>
				</ModalTitle>
			}
			mainContent={
				<SuccessModalContent>
					<span>{label}:</span>
					<UploadedDate>
						<CheckmarkIcon />
						<span>{dayjs().format('DD.MM.YYYY')}</span>
					</UploadedDate>
					<Text>{description}</Text>
				</SuccessModalContent>
			}
			footerComp={
				<Button variant="secondary" onClick={onClose}>
					{btnName}
				</Button>
			}
		/>,
		rootNode
	);
};

export default SuccessModalWithDate;
