import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DiscountState } from '@mopla/data-models';
import { Button, TextWithTooltip } from '@mopla/ui';

import { useBookingContext } from './bookingContext';
import {
	BlockTitle,
	InnerForm,
	ItemWrapper,
	SectionTitle,
	StyledRadio,
	TargetPersonBlock,
} from './BookingFlow.styles';
import { impairedIDReminderManager } from './ImpairedCardReminder';
import { BuyFor, TBookingFormState } from './types';
import {
	findPassengerByDiscountType,
	getCurrentPassengerInfo,
	isDiscountType,
} from './utils';

export const PriceForm: React.FC = () => {
	const { t } = useTranslation('booking');
	const formCtx = useFormContext<TBookingFormState>();
	const { setValue, getValues } = formCtx;
	const attendees = getValues('attendees2');
	const buyFor = getValues('buyFor');

	const {
		hasImpairedCompanion,
		hasImpaired,
		hasValidDisabledStatus,
		canBookForDisabled,
		setState,
		setPriceInfo,
		flowStepsMethods: { currentStep },
	} = useBookingContext();

	const { formType, values } = getCurrentPassengerInfo(currentStep, formCtx);

	const checkTicketPresent = () => {
		if (
			values.discountState === DiscountState.DISCOUNTED_TICKET_PRESENT ||
			values.discountState === DiscountState.NON_DISCOUNTED_TICKET_PRESENT
		) {
			return true;
		}
		if (
			values.discountState === DiscountState.PERSONAL_DISCOUNT_AVAILABLE ||
			values.discountState === DiscountState.NO_DISCOUNT
		) {
			return false;
		}
		return null;
	};

	const [ticketPresented, setTicketPresented] = useState<boolean | null>(
		checkTicketPresent()
	);

	const openImpairedIdReminder = () => {
		/**
		 * Check if the checkbox "do not show again" was ticked before
		 * Check if the user is a Dispatcher (they doesn't need any reminders)
		 * */
		const shouldNotShow = impairedIDReminderManager.get() || canBookForDisabled;

		if (!shouldNotShow) {
			setState('isImpairedCardReminder', true);
		}
	};

	const applyToAll = () => {
		const attendees = getValues('attendees2');
		const passengerData = getValues(formType);

		/** If current passenger is an impaired/companion, this feature isn't relevant */
		if (
			isDiscountType(
				[DiscountState.IMPAIRED, DiscountState.IMPAIRED_COMPANION],
				passengerData.discountState
			)
		) {
			return;
		}

		attendees.forEach(({ discountState }, i) => {
			/** If an attendee is an impaired/companion, don't modify their's settings */
			if (
				isDiscountType(
					[DiscountState.IMPAIRED, DiscountState.IMPAIRED_COMPANION],
					discountState
				) ||
				currentStep.attendeeIndex === i
			) {
				return;
			}

			setValue(`attendees2.${i}`, {
				discountState: passengerData.discountState,
				amountToPay: passengerData.amountToPay,
				currency: passengerData.currency,
			});
		});
	};

	const resetImpairedIfExists = () => {
		const impairedPath = findPassengerByDiscountType(
			DiscountState.IMPAIRED,
			formCtx
		);

		if (hasValidDisabledStatus && impairedPath && formType === impairedPath) {
			setPriceInfo(null, impairedPath);
		}
	};

	useEffect(() => {
		setTicketPresented(checkTicketPresent());
	}, [values]);

	const isImpaired = isDiscountType(
		[DiscountState.IMPAIRED],
		values.discountState
	);

	const isImpairedOrCompanion = isDiscountType(
		[DiscountState.IMPAIRED, DiscountState.IMPAIRED_COMPANION],
		values.discountState
	);

	const isAnotherPassengerImpaired =
		hasImpaired && values.discountState !== DiscountState.IMPAIRED;
	const isAnotherPassengerCompanion =
		hasImpairedCompanion &&
		values.discountState !== DiscountState.IMPAIRED_COMPANION;

	const showImpairedBlock = useMemo(() => {
		/** For Dispatchers show the block in case:
		 *  * if the user has special privilege AND is booking for another person AND there's a place for an impaired/companion
		 *  */
		const showImpairedBlockForDispatcher =
			canBookForDisabled && (!hasImpaired || !hasImpairedCompanion);

		/** For regular users (not a Dispatcher) show the block in case:
		 *   * the user has proven disability status and valid date
		 *   * only for the main passenger's step
		 * */
		const showImpairedBlockForRegular =
			hasValidDisabledStatus && buyFor === BuyFor.me && formType === 'main';

		return (
			isImpairedOrCompanion ||
			showImpairedBlockForDispatcher ||
			showImpairedBlockForRegular
		);
	}, [
		canBookForDisabled,
		formType,
		hasImpaired,
		hasImpairedCompanion,
		hasValidDisabledStatus,
		isImpairedOrCompanion,
		buyFor,
	]);

	const shouldShowApplyToAll = useMemo(() => {
		/**
		 * Show the button in case:
		 *  * There's an attendee, to whom we can copy a discount type from the current passenger's step.
		 * We cannot override attendees who are an impaired/companion
		 * We can copy settings only to an attendee, not to the main passenger.
		 * */
		let _hasRegularAttendee = false;

		for (let i = 0; i < attendees.length; i++) {
			if (currentStep.attendeeIndex === i) {
				continue;
			}

			if (
				!isDiscountType(
					[DiscountState.IMPAIRED, DiscountState.IMPAIRED_COMPANION],
					attendees[i].discountState
				)
			) {
				_hasRegularAttendee = true;
				break;
			}
		}

		return _hasRegularAttendee;
	}, [attendees, currentStep]);

	const title = useMemo(() => {
		if (currentStep.isAttendee) {
			return t('steps.attendee', {
				count: currentStep.attendeeIndex + 1,
			});
		}

		const name = getValues(['name', 'surname']).join(' ');

		return buyFor === BuyFor.me
			? t('steps.main_passenger')
			: t('steps.for_another_passenger', { name });
	}, [buyFor, currentStep.attendeeIndex, currentStep.isAttendee]);

	return (
		<>
			<SectionTitle>{title}</SectionTitle>
			<TargetPersonBlock marginBottom="8px" disabled={isImpaired}>
				<BlockTitle marginBottom="16px">{t('text.discount')}</BlockTitle>
				<TextWithTooltip
					text={t('text.public_transport_ticket')}
					tooltip={t('text.public_transport_ticket_tooltip')}
				/>
				<ItemWrapper marginTop="8px" marginBottom="10px">
					<StyledRadio
						color="secondary"
						checked={ticketPresented === true}
						id={`${formType}_public_transport_ticket_ja"`}
						onChange={() => {
							resetImpairedIfExists();
							setTicketPresented(true);
							setValue(`${formType}.discountState`, null);
							setValue(`${formType}.amountToPay`, null);
						}}
					/>
					<label htmlFor={`${formType}_public_transport_ticket_ja"`}>
						{t('text.yes')}
					</label>
				</ItemWrapper>
				{ticketPresented === true && (
					<InnerForm>
						<TextWithTooltip
							text={t('text.season_ticket_discount')}
							tooltip={t('text.season_ticket_discount_tooltip')}
						/>
						<ItemWrapper marginTop="8px" marginBottom="10px">
							<StyledRadio
								color="secondary"
								checked={
									values.discountState ===
									DiscountState.DISCOUNTED_TICKET_PRESENT
								}
								id={`${formType}_season_ticket_discount_ja`}
								onChange={() =>
									setPriceInfo(
										DiscountState.DISCOUNTED_TICKET_PRESENT,
										formType
									)
								}
							/>
							<label htmlFor={`${formType}_season_ticket_discount_ja`}>
								{t('text.yes')}
							</label>
						</ItemWrapper>
						<ItemWrapper marginBottom="18px">
							<StyledRadio
								color="secondary"
								checked={
									values.discountState ===
									DiscountState.NON_DISCOUNTED_TICKET_PRESENT
								}
								id={`${formType}_season_ticket_discount_nein`}
								onChange={() =>
									setPriceInfo(
										DiscountState.NON_DISCOUNTED_TICKET_PRESENT,
										formType
									)
								}
							/>
							<label htmlFor={`${formType}_season_ticket_discount_nein`}>
								{t('text.no')}
							</label>
						</ItemWrapper>
					</InnerForm>
				)}
				<ItemWrapper>
					<StyledRadio
						color="secondary"
						checked={ticketPresented === false}
						id={`${formType}_public_transport_ticket_nein"`}
						onChange={() => {
							resetImpairedIfExists();
							setTicketPresented(false);
							setValue(`${formType}.discountState`, null);
							setValue(`${formType}.amountToPay`, null);
						}}
					/>
					<label htmlFor={`${formType}_public_transport_ticket_nein"`}>
						{t('text.no')}
					</label>
				</ItemWrapper>
				{ticketPresented === false && (
					<InnerForm>
						<TextWithTooltip
							text={t('text.eligible_for_discount')}
							tooltip={t('text.season_ticket_nodiscount_tooltip')}
						/>
						<ItemWrapper marginTop="8px" marginBottom="10px">
							<StyledRadio
								color="secondary"
								checked={
									values.discountState ===
									DiscountState.PERSONAL_DISCOUNT_AVAILABLE
								}
								id={`${formType}_eligible_for_discount_ja`}
								onChange={() =>
									setPriceInfo(
										DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
										formType
									)
								}
							/>
							<label htmlFor={`${formType}_eligible_for_discount_ja`}>
								{t('text.yes')}
							</label>
						</ItemWrapper>
						<ItemWrapper>
							<StyledRadio
								color="secondary"
								checked={values.discountState === DiscountState.NO_DISCOUNT}
								id={`${formType}_eligible_for_discount_nein`}
								onChange={() =>
									setPriceInfo(DiscountState.NO_DISCOUNT, formType)
								}
							/>
							<label htmlFor={`${formType}_eligible_for_discount_nein`}>
								{t('text.no')}
							</label>
						</ItemWrapper>
					</InnerForm>
				)}
			</TargetPersonBlock>
			{showImpairedBlock && (
				<TargetPersonBlock marginBottom="16px">
					<BlockTitle marginBottom="16px">
						{t('text.free_transport')}
					</BlockTitle>
					<ItemWrapper
						marginTop="8px"
						marginBottom="10px"
						disabled={isAnotherPassengerImpaired}
					>
						<StyledRadio
							color="secondary"
							checked={values.discountState === DiscountState.IMPAIRED}
							id="impaired"
							onChange={() => {
								setPriceInfo(DiscountState.IMPAIRED, formType);
								openImpairedIdReminder();
							}}
							disabled={isAnotherPassengerImpaired}
						/>
						<label htmlFor="impaired">{t('text.impaired_passenger')}</label>
					</ItemWrapper>
					<ItemWrapper
						marginTop="8px"
						marginBottom="10px"
						disabled={isAnotherPassengerCompanion}
					>
						<StyledRadio
							color="secondary"
							checked={
								values.discountState === DiscountState.IMPAIRED_COMPANION
							}
							id="impaired_companion"
							onChange={() => {
								setPriceInfo(DiscountState.IMPAIRED_COMPANION, formType);
							}}
							disabled={isAnotherPassengerCompanion}
						/>
						<label htmlFor="impaired_companion">
							{t('text.companion_passenger')}
						</label>
					</ItemWrapper>
				</TargetPersonBlock>
			)}
			{shouldShowApplyToAll && (
				<Button
					id="for_all_passengers_button"
					variant="outlined"
					onClick={applyToAll}
					disabled={values.discountState === null || isImpairedOrCompanion}
				>
					{t('button.take_over_for_all_passengers')}
				</Button>
			)}
		</>
	);
};
