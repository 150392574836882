import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Portal } from '@mui/material';
import dayjs from 'dayjs';

import { PassengerBooking } from '@mopla/business-logic';
import { ILeg, TransportType } from '@mopla/data-models';
import {
	Button,
	DelteTicketAnim,
	FlexContainer,
	InfoIcon,
	Modal,
} from '@mopla/ui';
import {
	formatPrice,
	isLBODTFlexItinerary,
	isLBODTItinerary,
} from '@mopla/utils';

import {
	InfoBlock,
	ModalText,
	Note,
	NoteText,
	NoteTitle,
} from './ConfirmationModal.styles';
import { ButtonsWrapper, ModalTitle } from './TicketsStack.styles';

export interface ConfirmationModalProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	isCanceling: boolean;
	ticket: Partial<PassengerBooking>;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
	const { open, onClose, onConfirm, ticket, isCanceling } = props;
	const { t } = useTranslation('tickets');

	const isLbodt = isLBODTItinerary(ticket.itinerary);
	const isFlex = isLBODTFlexItinerary(ticket.itinerary);

	const { bodyText, submitButtonText, isRefundWarn } = useMemo(() => {
		let bodyText = t('text.remove_lbt_text');
		let submitButtonText = t('button.remove');
		let isRefundWarn = false;

		if (isLbodt) {
			bodyText = t('text.cancel_lbodt_text');
			submitButtonText = t('button.cancel');
		}
		if (isFlex) {
			submitButtonText = t('button.cancel');
			const cancellationType = getRefundType(ticket);

			if (cancellationType) {
				if (cancellationType.type === 'full') {
					bodyText = t('text.cancel_lbodtflex_full_refund');
				} else if (cancellationType.type === 'partial') {
					bodyText = t('text.cancel_lbodtflex_partial_refund', {
						fee: formatPrice(cancellationType.cost / 100),
					});
					isRefundWarn = true;
				} else if (cancellationType.type === 'none') {
					bodyText = t('text.cancel_lbodtflex_no_refund');
					isRefundWarn = true;
				}
			} else {
				bodyText = '';
			}
		}

		return { bodyText, submitButtonText, isRefundWarn };
	}, [isFlex, isLbodt, t, ticket]);

	return (
		<Portal key={'test'} container={document.getElementById('portal')}>
			<Modal
				key={'test'}
				open={open}
				onClose={onClose}
				/** TODO why for isFlex there's no animation? */
				animationElement={!isFlex ? <DelteTicketAnim /> : null}
				mainContent={
					<>
						<ModalTitle data-testid="savedTicket-removingConfirmation-title">
							{isLbodt || isFlex
								? t('text.cancel_ride')
								: t('text.remove_ride')}
						</ModalTitle>
						{bodyText && (
							<ModalText isWarn={!!isFlex && isRefundWarn}>
								{bodyText}
							</ModalText>
						)}
						{isFlex && (
							<Note>
								<NoteTitle>{t('text.note_title')}</NoteTitle>
								<NoteText>{t('text.note_message')}</NoteText>
							</Note>
						)}
						<InfoBlock>
							<div>
								<InfoIcon />
							</div>
							<span>{t('text.info_message')}</span>
						</InfoBlock>
					</>
				}
				footerComp={
					<>
						{isCanceling && (
							<FlexContainer className="center">
								<CircularProgress />
							</FlexContainer>
						)}
						{!isCanceling && (
							<ButtonsWrapper>
								<Button
									variant="outlined"
									type="button"
									onClick={onClose}
									data-testid="savedTicket-removingConfirmation-abort-button"
								>
									{t('button.abort')}
								</Button>
								<Button
									variant="primary"
									type="button"
									onClick={onConfirm}
									data-testid="savedTicket-removingConfirmation-cancelBooking-button"
								>
									{submitButtonText}
								</Button>
							</ButtonsWrapper>
						)}
					</>
				}
			/>
		</Portal>
	);
};

interface IRefundType {
	type: 'full' | 'partial' | 'none';
	cost: number;
}

/**
 * Derives the refund type of the booking based on cancellationFreeUntil + costOfCancellation fields.
 * Refund can be full, partial or none
 * null can be returned in cases there's no need to show any type of refund
 * */
function getRefundType(booking: Partial<PassengerBooking>): IRefundType | null {
	const legs = booking.itinerary?.legs.filter(
		(leg) => leg.mode === TransportType.LBODTFLEX
	);

	const bookingTotalPaymentAmount = booking?.payment?.paymentAmount;

	if (!bookingTotalPaymentAmount || !legs?.length) {
		return null;
	}

	/** Check, if leg's cancellationFreeUntil is in the future */
	const checkLegFreeCancellation = (leg: ILeg) =>
		leg.cancellationFreeUntil &&
		dayjs().isBefore(dayjs(leg.cancellationFreeUntil), 'minute');

	/** If every leg is 0 payment and 0 cancel cost (ex. for impaired persons) */
	if (
		legs.every(
			(leg) => leg.payment?.paymentAmount === 0 && leg.costOfCancellation === 0
		)
	) {
		return null;
	}

	/** If every leg is free to cancel */
	if (legs.every(checkLegFreeCancellation)) {
		return {
			type: 'full',
			cost: 0,
		};
	}

	/** Sum of cancellation costs of legs, excluding those which are free to cancel */
	const totalCostOfCancellation = legs.reduce(
		(sum, leg) =>
			checkLegFreeCancellation(leg) ? sum : sum + (leg.costOfCancellation || 0),
		0
	);

	/** In case total cost equal to the whole booking price - the cancellation will be 100% paid */
	if (
		totalCostOfCancellation > 0 &&
		totalCostOfCancellation === bookingTotalPaymentAmount
	) {
		return {
			type: 'none',
			cost: bookingTotalPaymentAmount,
		};
	}

	if (totalCostOfCancellation > 0) {
		return {
			type: 'partial',
			cost: totalCostOfCancellation,
		};
	}

	/** Fallback */
	return {
		type: 'full',
		cost: 0,
	};
}
