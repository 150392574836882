import { styled } from '@mui/material';

export const AnimRoot = styled('div')(({ theme }) => ({
	width: '100%',
	paddingTop: '61%',
	position: 'relative',
	backgroundColor: theme.colors.mopla_white,
	borderRadius: '8px',
}));
export const AnimWrap = styled('div')({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
});
