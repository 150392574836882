export enum ProfileSteps {
	INITIAL = 'initial',
	DRIVER_PERSONAL_DATA = 'driver data',
	PASSENGER_PERSONAL_DATA = 'passenger data',
	PASSENGER_EDIT_USER = 'passnger edit user',
	LANGUAGE_SELECT = 'language select',
	PAYMENT_DETAILS = 'payment details',
	DTICKET = 'germany ticket',
	DTICKET_PAUSE = 'germany ticket pause',
	DTICKET_CANCEL = 'germany ticket cancelation',
	DTICKET_INVOICE_ADDRESS = 'germany ticket invoice address',
	DTICKET_INVOICE_INQUIRY = 'germany ticket invoice inquiry',
	DTICKET_INVOICE_INQUIRY_SELECT = 'germany ticket invoice inquiry select invoices',
	DTICKET_PREORDERED_CANCEL = 'germany ticket preorder cancel',
	REDEEM_VOUCHER = 'redeem voucher',
	FAQ = 'FAQ',
}
