import memoizeOne from 'memoize-one';

import {
	CurrencyCode,
	DiscountState,
	IItineraryPayment,
	ILeg,
	ILegPricing,
	Itinerary,
	TransportType,
} from '@mopla/data-models';

import { isMoreThanOneTrue, uniqueFilter } from './array';
import { formatTime } from './formatDateTime';

export const checkIsLBODTFlexLeg = (leg: ILeg): boolean =>
	leg.mode === TransportType.LBODTFLEX;

export const isLBTItinerary = memoizeOne((itinerary?: Itinerary | null) => {
	return !!itinerary?.legs.every(
		(leg) => leg.mode === TransportType.LBT || leg.mode === TransportType.WALK
	);
});

export const isLBODTFlexItinerary = memoizeOne(
	(itinerary?: Itinerary | null) => {
		return !!itinerary?.legs.some(
			(leg) => leg.mode === TransportType.LBODTFLEX
		);
	}
);

export const isLBODTItinerary = memoizeOne((itinerary?: Itinerary | null) => {
	return !!itinerary?.legs.some((leg) => leg.mode === TransportType.LBODT);
});

export const isMixedItinerary = memoizeOne((itinerary?: Itinerary | null) => {
	if (!itinerary) {
		return false;
	}

	const hasLBODTFlexLegs = itinerary.legs.some(
		(leg) => leg.mode === TransportType.LBODTFLEX
	);

	const hasLBTLegs = itinerary.legs.some(
		(leg) => leg.mode === TransportType.LBT
	);

	const hasLBODTLegs = itinerary.legs.some(
		(leg) => leg.mode === TransportType.LBODT
	);

	return isMoreThanOneTrue([hasLBODTFlexLegs, hasLBTLegs, hasLBODTLegs]);
});

/** Returns unique Array of TransportTypes */
export const getItineraryTransportTypes = memoizeOne(
	(itinerary?: Itinerary | null, exclude?: TransportType[]) => {
		const _exclude = exclude || [];
		return itinerary
			? itinerary.legs
					.map((leg: ILeg) => leg.mode)
					.filter((v, i, l) => !_exclude.includes(v) && uniqueFilter(v, i, l))
			: [];
	}
);

/** Checks if an itinerary has any leg with a start or end datetime, which is not fixed (thus can be changed by a transport provider) */
export const checkTimeMightChange = memoizeOne(
	(itinerary?: Itinerary | null) => {
		if (!itinerary) {
			return false;
		}

		return itinerary.legs
			.map((leg: ILeg) => leg.startDateTimeFix || leg.endDateTimeFix)
			.includes(false);
	}
);

/** Checks if an itinerary has any leg with an overwritten time */
export const checkTimeOverwritten = memoizeOne(
	(itinerary?: Itinerary | null) => {
		if (!itinerary) {
			return false;
		}

		return itinerary.legs.some(
			(leg: ILeg) =>
				!!leg.overwrittenStartTimeWindowStart ||
				!!leg.overwrittenEndTimeWindowEnd
		);
	}
);

/**
 * For the given discountType and the itinerary,
 * calculates the price value and the flag, if there's any leg with unavailable price info (thus the price according to the tarif)
 * */
export const calcPriceByDiscountType = memoizeOne(
	(itinerary: Itinerary, discountType: DiscountState) => {
		let totalAmount: number | null = null;
		let currency: CurrencyCode | null = null;
		let hasLegsWithPriceAccordingToTarif = false;

		itinerary.legs.forEach((leg) => {
			if (leg.pricings && leg.pricings.length > 0) {
				const suitablePricing = leg.pricings.find(
					(pricing) =>
						pricing.discount === discountType &&
						pricing.paymentInformationAvailable
				);

				if (!suitablePricing) {
					return;
				}

				if (suitablePricing.paymentInformationAvailable) {
					totalAmount = (totalAmount || 0) + (suitablePricing.amount || 0);
					currency = suitablePricing.currency;
				} else {
					/** If "paymentInformationAvailable" is "false" -> "Price according to tariff" */
					hasLegsWithPriceAccordingToTarif = true;
				}
			}
		});

		return {
			totalAmount,
			currency,
			hasLegsWithPriceAccordingToTarif,
		};
	}
);

export function checkHasLegWithoutPaymentInfo(legs: ILeg[]) {
	const legsPrices = getLegsPrices(legs);

	return legsPrices.some((legPrices) =>
		legPrices.some(
			({ paymentInformationAvailable }) => !paymentInformationAvailable
		)
	);
}

export function getLegsPrices(legs: ILeg[]): ILegPricing[][] {
	return legs.map(({ pricings }) => pricings);
}

/** Types are not ideal here */
export function prepareItineraryOperators<T>(
	itinerary?: Itinerary | null,
	mapper?: (operators: string[]) => unknown
): null | T {
	if (!itinerary) {
		return null;
	}

	const operators = itinerary.legs
		.map((leg) => leg.agencyName)
		.filter((v, i, s): v is string => !!v && uniqueFilter(v, i, s));

	if (!operators.length) {
		return null;
	}

	return (mapper ? mapper(operators) : operators) as T;
}

export function calculateItineraryPricingsRange(
	legs: ILeg[] | null
): [number, number] | null {
	if (!legs) {
		return null;
	}

	let totalMin: number | null = null;
	let totalMax: number | null = null;

	const legsPrices = getLegsPrices(legs);

	legsPrices.forEach((el) => {
		const prices = el
			.filter((p) => p.amount != null)
			.map<number>((p) => p.amount as number)
			.sort((a, b) => a - b);

		if (prices.length) {
			totalMin = (totalMin || 0) + prices[0] || 0;
			totalMax = (totalMax || 0) + prices[prices.length - 1] || 0;
		}
	});

	if (totalMin == null || totalMax == null) {
		return null;
	}

	return [totalMin, totalMax];
}

/** Calculates payment for IBooking (overall) or IBooking Itinerary Leg */
export function getEntityPaymentInfo(payment: IItineraryPayment | null) {
	if (!payment) {
		return null;
	}

	return {
		paymentState: payment.paymentState,
		digitalPayment: payment.paymentAmount,
		hasPriceAccordingTariff: !payment.paymentInformationAvailable,
	};
}

export function getActualPrice(payment: IItineraryPayment) {
	const newPrice = payment.overwrittenPaymentAmount;
	const initialPrice = payment.paymentAmount;
	const currentPrice = newPrice || initialPrice;

	return currentPrice;
}

export function getActualTime(leg: ILeg, type: 'start' | 'end') {
	const newTime =
		type === 'start'
			? leg.overwrittenStartTimeWindowStart
			: leg.overwrittenEndTimeWindowEnd;
	const initialTime = type === 'start' ? leg.startDateTime : leg.endDateTime;
	const currentTime = formatTime(new Date(newTime || initialTime));

	return currentTime;
}
